import styled from 'styled-components'
import Style from 'shared-js/style'

type CompanyNameElementProps = {
  children: React.ReactNode,
}

export const CompanyName = styled.div<CompanyNameElementProps>`
  display: flex;
  align-items: center;
  height: 54px;
  fontsize: 14px;
  line-height: 1.43;
  color: ${Style.deprecatedColors.blackTwo};
`
