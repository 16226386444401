import React from 'react'

const RETAILER = 'RETAILER'
const styles = {
  header: {
    marginTop: 20,
  },
}

function WarningMessage() {
  return (
    <div className="ui negative message">
      <div className="header">
        You have selected a Retailer. This tool currently only supports Brands.
      </div>
    </div>
  )
}

export function CompanyReportDetails(props) {
  const { report } = props
  if (!report) return <div>No existing reports</div>
  return (
    <div>
      <h2 style={styles.header}>{report.company && report.company.name}</h2>
      <div>
        {report.company.companyType === RETAILER && <WarningMessage company={report.company} />}
        <h3>
          <u>Existing Reports</u>
        </h3>
        <p>
          <b>
            Report ID:
            {' '}
            {report.id}
            {' '}
            <br />
          </b>
          <b>
            Company Type:
            {report.company.companyType}
          </b>
          <br />
          <b>
            Region:
            {' '}
            {report.company.regionOfResponsibility}
            <br />
          </b>
          <b>CSV Inputted:</b>
          <a href={report.inputFile} target="_blank">
            {` ${report.inputFile}`}
          </a>
        </p>
        <b>Results CSV (Companies connected):</b>
        {report.outputFile && (
          <a href={report.outputFile} target="_blank">
            {` ${report.outputFile}`}
          </a>
        )}
      </div>
    </div>
  )
}
