import React from 'react'
import styled from 'styled-components'
import { useWindowWidth } from 'shared-js/utilities/hooks'
import { isMobileWidth } from 'shared-js/utilities/browser'
import { getPaginationProps } from 'shared-js/state/graphql'
import { ContainedList } from 'components/common/virtualized-components'
import { ListRowProps } from 'react-virtualized'
import { ConnectedCompaniesQuery } from './gql-types/ConnectedCompaniesQuery'
import { CompanyRow, LoadingCompanyRow } from './company-row'

export const LOADING_SIZE = 30
export const MAX_ITEMS_SHOWN_DESKTOP = 6
export const MAX_ITEMS_SHOWN_MOBILE = 4
export const ROW_HEIGHT = 40

export type FetchedConnectedCompaniesApolloProps = {
  loading: boolean
  data: ConnectedCompaniesQuery
  [key: string]: any
}

const CompaniesList = ({ apolloProps }: { apolloProps: FetchedConnectedCompaniesApolloProps }) => {
  const paginationProps = getPaginationProps({
    apolloProps,
    listFieldName: 'PublicCompany__List',
  })

  const renderCompanyRow = ({ index, key, style }: ListRowProps) => {
    if (apolloProps.data?.PublicCompany__List?.edges[index]?.node) {
      return (
        <CompanyRow
          key={key}
          style={style}
          company={apolloProps.data.PublicCompany__List.edges[index]!.node!}
        />
      )
    }
    return <LoadingCompanyRow key={key} style={style} />
  }

  const windowWidth = useWindowWidth()
  const maxItemsToShow = isMobileWidth(windowWidth)
    ? MAX_ITEMS_SHOWN_MOBILE
    : MAX_ITEMS_SHOWN_DESKTOP

  return apolloProps.data.PublicCompany__List ? (
    <ListContainer>
      <ContainedList
        items={apolloProps.data.PublicCompany__List.edges}
        totalCount={apolloProps.data.PublicCompany__List.totalCount || 0}
        loadingSize={LOADING_SIZE}
        loadMoreData={paginationProps.loadMoreData}
        dataIsLoading={paginationProps.dataIsLoading}
        maxNumRowsShownAtATime={maxItemsToShow}
        fetchMoreThreshold={LOADING_SIZE}
        rowRenderer={renderCompanyRow}
        rowHeight={ROW_HEIGHT}
      />
    </ListContainer>
  ) : null
}

const ListContainer = styled.div`
  width: 100%;
`

export default CompaniesList
