import Datetime from 'react-datetime'
import { EmailInputMixin } from 'components/common/form-deprecated/mixins/email-input'
import { InputMixin } from 'components/common/form-deprecated/mixins/input'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Style from 'style/index.js'
import _ from 'lodash'
import createReactClass from 'create-react-class'
import cx from 'classnames'
import { humanFileSize } from 'utilities/generic'
import { t } from 'i18n'
import { validateURL } from 'utilities/validators'

const PROTOCOLS = ['http://', 'https://', 'ftp://']

const DEFAULT_PROTOCOL = 'http://'

const textInputStyle = {
  textInput: {
    height: '2.6em',
    fontSize: '14px',
  },
  initValue: {
    color: Style.vars.deprecatedColors.darkGrey,
  },
  label: {
    fontWeight: 'normal',
  },
  container: {
    marginBottom: 20,
  },
  errorMsg: {
    color: Style.vars.deprecatedColors.errorRed,
  },
}

const TextInputMixin = {
  /*
    Allows functionality to be shared between
    the `TextInput` and `EmailInput` components.
  */
  mixins: [InputMixin],
  propTypes: {
    // Specify an optional Semantic UI icon class
    // to include.
    icon: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    // Should input go red when there is an error?
    showError: PropTypes.bool,
    // Should initial value be greyed out?
    fadeInitial: PropTypes.bool,
  },
  getDefaultProps() {
    return {
      fadeInitial: true,
      showError: true,
    }
  },

  focus() {
    if (!this.refs.input) console.error('No input ref found')
    ReactDOM.findDOMNode(this.refs.input).focus()
  },

  reset() {
    this.setState({
      value: this.props.initialValue,
    })
  },

  render() {
    let iconEl = this.props.icon ? (
      <i className={`${this.props.icon} icon`} style={this.props.iconStyle} />
    ) : null
    const errorMsgStyle = this.props.errorMsgStyle
      ? this.props.errorMsgStyle
      : textInputStyle.errorMsg
    // Add icon el if loading is true, that way loading icon still shows.
    if (!iconEl && this.props.loading) {
      iconEl = <i className="notanicon icon" />
    }
    const style = _.extend({}, textInputStyle.textInput, this.props.style)
    const userHasChanged = this.userHasChangedValue()
    if (!userHasChanged && this.props.fadeInitial && !this.props.initialIsAcceptable) {
      _.extend(style, textInputStyle.initValue)
    }
    // Show error if field on blur OR if user has submitted
    // DON'T show error yet if the user is still changing input
    const error = !this.isValid() && (this.state.onBlur || this.props.hasSubmitted)
    let errorMessage
    if (error) {
      errorMessage = this.state.error && !this.props.hideErrorMessage ? t(this.state.error) : null
    }
    const actionEl = this.props.actionComponent || null
    // placeholderColor also needs to be added as a class in style.css (frontend/src/css)
    // because react doesn't have a nice way of dealing with pseudo classes.
    const placeholderColor = this.props.placeholderColor
    const classes = cx(
      'ui',
      { icon: iconEl },
      { input: iconEl !== undefined },
      'field',
      { error: error && this.props.showError },
      { loading: this.props.loading },
      { action: Boolean(actionEl) },
      { [`placeholder-${placeholderColor}`]: placeholderColor }
    )
    const cStyle = Style.funcs.merge(textInputStyle.container, this.props.style.container)
    let inputClassName = this.props.name ? `${_.kebabCase(this.props.name)}-input` : null

    if (this.props.className) {
      inputClassName = `${inputClassName} ${this.props.className}`
    }

    const placeholder = this.props.placeholder ? this.props.placeholder : ''
    return (
      <div style={cStyle}>
        <div className={classes} style={{ width: '100%', ...this.props.style.innerContainer }}>
          {this.getLabelEl({
            ...textInputStyle.label,
            ...this.props.labelStyle,
          })}
          <input
            name={this.props.name}
            id={this.props.id || this.props.nativeID}
            type={this.props.type}
            placeholder={placeholder}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onKeyDown={this.onKeyDown}
            value={this.state.value}
            style={style}
            max={this.props.max}
            min={this.props.min}
            maxLength={this.props.maxLength}
            className={inputClassName}
            ref="input"
            autoComplete={this.props.autoComplete}
            autoFocus={this.props.autoFocus}
            disabled={this.props.disabled}
          />
          {actionEl}
          {iconEl}
        </div>
        {errorMessage ? (
          <div style={errorMsgStyle}>
            <p>{errorMessage}</p>
          </div>
        ) : null}
      </div>
    )
  },
}

export const TextInput = createReactClass({
  displayName: 'TextInput',

  /*
    Simple text input. See comments for
    `TextInputMixin` for information about
    props and usage.
  */
  mixins: [TextInputMixin],

  getDefaultProps() {
    return {
      type: 'text',
    }
  },
})

export const EmailInput = createReactClass({
  displayName: 'EmailInput',

  /*
    Exactly the same as `TextInput` component
    except it only allows a valid email to
    be entered and submitted.
  */
  mixins: [TextInputMixin, EmailInputMixin],

  getDefaultProps() {
    return {
      type: 'email',
    }
  },
})

export const URLInput = createReactClass({
  displayName: 'URLInput',
  mixins: [TextInputMixin],

  getDefaultProps() {
    return {
      type: 'text',
    }
  },

  baseIsValid(val) {
    if (!this.userHasChangedValue() || (this.props.required === false && !val)) {
      return true
    }
    if (!validateURL(val)) {
      return t('please_enter_a_valid_url')
    }
    return true
  },

  baseClean(val) {
    // Add default protocol if none is supplied by user
    if (!val) return null
    let hasProtocol = false
    PROTOCOLS.forEach((protocol) => {
      if (_.startsWith(val, protocol)) {
        hasProtocol = true
      }
    })
    if (!hasProtocol) return DEFAULT_PROTOCOL + val
    return val
  },
})

const textAreaStyle = {
  textarea: {},
}

export const TextArea = createReactClass({
  displayName: 'TextArea',

  /*
    A simple text area component. See
    documentation for included mixins for more
    information.
  */
  mixins: [InputMixin],

  propTypes: {
    style: PropTypes.object,
    height: PropTypes.string,
    showError: PropTypes.bool,
    onChange: PropTypes.func,
  },

  focus() {
    if (!this.refs.input) console.error('No input ref found')
    ReactDOM.findDOMNode(this.refs.input).focus()
  },

  getDefaultProps() {
    return {
      showError: false,
      onChange: (evt) => {},
    }
  },

  render() {
    const taStyle = _.extend(
      {},
      textAreaStyle.textarea,
      this.props.style.textarea || this.props.style
    )
    const labelStyle = _.extend({}, textAreaStyle.label, this.props.style.label)
    if (this.props.height) taStyle.height = this.props.height
    const error = !this.isValid() && this.userHasChangedValue()
    const classNames = cx('field', { error: error && this.props.showError })
    let inputClassName = this.props.name ? `${this.props.name}-input` : null
    if (this.props.className) {
      inputClassName = `${inputClassName} ${this.props.className}`
    }
    return (
      <div className={classNames}>
        {this.getLabelEl(labelStyle)}
        <textarea
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.onChange}
          style={taStyle}
          value={this.state.value}
          className={inputClassName}
          placeholder={this.props.placeholder}
          ref="input"
        />
      </div>
    )
  },
})

export class HiddenTextInput extends React.Component {
  /*
    Browsers often focus on first input in a form automatically.
    Sometimes, this auto focus is not desirable. This component
    can be placed at the top of a Form to prevent that behaviour.
  */
  render() {
    return (
      <input
        type="text"
        value={this.props.value}
        style={{ opacity: 0, height: 0, position: 'absolute' }}
      />
    )
  }
}

const fiStyle = {
  container: {
    display: 'relative',
    overflow: 'hidden',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  text: {
    display: 'inline-block',
    position: 'relative',
    maxWidth: 300,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: 10,
    top: 3,
  },
  invalidText: {
    color: Style.vars.deprecatedColors.errorRed,
  },
}

export class DatetimeInput extends React.Component {
  state = {
    file: null,
  }

  onChange = (val) => {
    this.props.onChange(val)
  }

  render() {
    const inputProps = {}
    if (this.props.placeholder) inputProps.placeholder = this.props.placeholder
    return (
      <div style={this.props.style}>
        <Datetime
          ref="innerComponent"
          defaultValue={this.props.initialValue}
          value={this.props.value}
          onChange={this.props.onChange}
          open={this.props.open}
          input={this.props.input}
          isValidDate={this.props.isValidDate}
          timeFormat={this.props.timeFormat}
          inputProps={inputProps}
        >
          {this.props.children}
        </Datetime>
      </div>
    )
  }
}

export class FileInput extends React.Component {
  static contextTypes = {
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  static defaultProps = {
    icon: 'file',
  }

  /*
    Stylised file selector
  */
  state = {
    file: null,
  }

  onChange = (e) => {
    if (!e.target.files.length) return
    const file = e.target.files[0]
    this.setState({ file })
    // Call onChange next tick because that is when state will be
    // updated
    if (this.props.onChange) _.defer(() => this.props.onChange(file))
  }

  getValue = () => this.state.file

  getNameAndValue = () => ({
    [this.props.name]: this.getValue(),
  })

  selectFile = (evt) => {
    evt.preventDefault()
    evt.stopPropagation()

    ReactDOM.findDOMNode(this.refs.hiddenInput).click()
  }

  isValid = () => {
    if (this.props.required && !this.state.file) return false
    if (!this.isValidType()) return false
    if (!this.isValidSize()) return false
    return true
  }

  isValidSize = () => {
    if (this.state.file && this.state.file.size > this.props.maxFileSize) {
      this.context.displayTempNegativeMessage({
        heading: `${t('notice')}:`,
        body: t('maximum_file_size_is', { size: this.humanFileSize() }),
      })
      return false
    }
    return true
  }

  isValidType = () => {
    if (
      this.state.file &&
      this.props.allowedTypes &&
      !_.includes(this.props.allowedTypes, this.state.file.type)
    ) {
      return false
    }
    return true
  }

  renderName = () => {
    if (!this.state.file) return null
    if (!this.isValidType()) {
      return (
        <p style={Style.funcs.merge(fiStyle.text, fiStyle.invalidText)}>
          Please select a file of the correct type
        </p>
      )
    }
    return <p style={fiStyle.text}>{this.state.file.name}</p>
  }

  humanFileSize = () => humanFileSize(this.props.maxFileSize)

  maxFileSize = () => {
    if (this.props.maxFileSize) {
      return (
        <p>
          <small>
            (max file size:
            {this.humanFileSize()}
)
</small>
        </p>
      )
    }
    return null
  }

  render() {
    const inputClassName = this.props.name ? `${_.kebabCase(this.props.name)}-input` : null
    return (
      <div>
        <div style={fiStyle.container}>
          <label htmlFor="file" className="ui icon button" onClick={this.selectFile}>
            <i className={`${this.props.icon} icon`} />
            {this.props.btnText || 'Select File'}
          </label>
          <input
            type="file"
            id="file"
            onChange={this.onChange}
            style={{ display: 'none' }}
            className={inputClassName}
            accept={this.props.allowedTypes}
            ref="hiddenInput"
          />
          {this.renderName()}
        </div>
        {this.maxFileSize()}
      </div>
    )
  }
}

export const PasswordInput = createReactClass({
  displayName: 'PasswordInput',
  mixins: [TextInputMixin],

  getDefaultProps() {
    return {
      type: 'password',
      minLength: 6,
    }
  },
})

export const NumberInput = createReactClass({
  displayName: 'NumberInput',
  mixins: [TextInputMixin],

  getDefaultProps() {
    return {
      type: 'number',
    }
  },
})

export const RangeInput = createReactClass({
  displayName: 'RangeInput',
  mixins: [TextInputMixin],

  getDefaultProps() {
    return {
      type: 'range',
    }
  },
})

export const KeyInput = (props) => {
  const keyInputStyle = {
    container: {
      marginBottom: 20,
    },
    label: {
      display: 'inline-flex',
      marginTop: 2,
      alignItems: 'center',
    },
    checkBox: {
      marginRight: 4,
    },
    innerContainer: {
      position: 'relative',
    },
    copyButton: {
      position: 'absolute',
      right: 0,
      cursor: 'pointer',
      height: '100%',
    },
  }

  const buttonPrompt = t('copy')
  const buttonAfterPrompt = t('copied')

  const keyValue = props.value
  const [isKeyVisible, setKeyVisible] = useState(false)
  const [buttonText, setButtonText] = useState(buttonPrompt)

  const copyAPIKey = () => {
    navigator.clipboard.writeText(keyValue)
    setButtonText(buttonAfterPrompt)
  }

  const toggleKeyVisibility = () => setKeyVisible(!isKeyVisible)

  let inputClassName = props.name ? `${_.kebabCase(props.name)}-input` : null

  if (props.className) {
    inputClassName = `${inputClassName} ${props.className}`
  }

  const keyPrompt = t('api_key_prompt')

  return (
    <div style={keyInputStyle.container}>
      <div style={keyInputStyle.innerContainer}>
        <input
          name={props.name}
          id={props.id || props.nativeID}
          type={isKeyVisible ? 'text' : 'password'}
          placeholder={props.placeholder || ''}
          value={keyValue}
          className={inputClassName}
          disabled={isKeyVisible}
        />
        <button type="button" onClick={copyAPIKey} style={keyInputStyle.copyButton}>
          {buttonText}
        </button>
      </div>
      {props.value ? (
        <label style={keyInputStyle.label}>
          <input
            type="checkbox"
            checked={isKeyVisible}
            onChange={toggleKeyVisibility}
            style={keyInputStyle.checkBox}
          />
          <span>{props.prompt || keyPrompt}</span>
        </label>
      ) : null}
    </div>
  )
}
