import { Panel } from 'components/common/box'
import _ from 'lodash'
import { ChannelAbout } from 'components/common/channel-about'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react'
import { useCurrentUser, useFeatureFlag } from 'shared-js/utilities/hooks'
import { CommonRouterContext } from 'shared-js/context'
import Im from 'shared-js/immutable'
import Style from 'style'
import StyleSheet from 'shared-js/style/style-sheet'
import { TrainingPlanCard, TrainingPlansCollection } from 'components/common/plan-card-deprecated'
import { ViewTrainingPlanModal } from 'components/training/plans/plan-modal'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import { getPaginationProps } from 'shared-js/state/graphql'
import { ApolloLoadingContainer } from 'shared-js/components/common/loading'
import { SearchInput } from 'components/common/form-deprecated/search'
import { ANALYTICS_EVENTS } from 'core/constants'
import { getAnalytics } from 'core/state-configuration'
import CoverImage, { COVER_IMAGE_DATA } from './cover-image'

const analytics = getAnalytics()

const LEARNING_CHANNEL_PAGE_SUBSTRING = 'channel-content'
const PENDO_FIRST_PLAN_CLASS_NAME = 'pendo-first-plan'

const ROOT_QUERY = gql`
  query Channel($orderBy: [String]!, $channelID: ID!, $currentUserID: ID!, $searchTerm: String, $cursor: String, $excludeHasNewLessons: Boolean!) {
    TrainingUnit__Item(id: $channelID) {
      ...CoverImage_channel
      ...ChannelAbout_channel
      ...TrainingPlanCard_channel
      ...TrainingPlansCollection_channel
    }
    TrainingPlanTrainingUnit__List(orderBy: $orderBy, first: 24, after: $cursor, trainingUnit: $channelID, trainingPlanDeactivated: false, hasEnrollmentsForUser: $currentUserID, trainingPlanIsPublished: true, trainingPlanHasContent: true, planSearch: $searchTerm) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          trainingPlan {
            ...TrainingPlanCard_plan
          }
        }
      }
    }
    ${COVER_IMAGE_DATA.trainingUnit.fragment}
    ${ChannelAbout.data.channel.fragment}
    ${TrainingPlanCard.data.channel.fragment}
    ${TrainingPlanCard.data.trainingPlan.fragment}
    ${TrainingPlansCollection.data.channel.fragment}
  }
`

export const Page = (props) => {
  const [planSearchTerm, setPlanSearchTerm] = useState('')
  const { routeParams } = useContext(CommonRouterContext)
  const currentUser = useCurrentUser()
  const channelID = Number(routeParams.channelId)
  const orderBy = planSearchTerm ? ['-search_rank'] : ['order', 'training_plan__name']

  const disableNewContentIndicator = useFeatureFlag('disable_new_content_indicator')

  const variables = {
    orderBy,
    channelID,
    currentUserID: currentUser && currentUser.id,
    searchTerm: planSearchTerm,
    excludeHasNewLessons: disableNewContentIndicator,
  }

  const onSearchChange = (val) => {
    setPlanSearchTerm(val)
  }

  return (
    <Query query={ROOT_QUERY} variables={variables}>
      {(apolloProps) => (
        <ApolloLoadingContainer {...apolloProps}>
          {(data) => {
            const paginationProps = data.TrainingPlanTrainingUnit__List
              ? getPaginationProps({
                  apolloProps,
                  listFieldName: 'TrainingPlanTrainingUnit__List',
                })
              : null
            return data.TrainingUnit__Item ? (
              <ChannelContentPageInner
                onSearchChange={onSearchChange}
                channel={data.TrainingUnit__Item}
                trainingPlanTrainingUnits={
                  data.TrainingPlanTrainingUnit__List && data.TrainingPlanTrainingUnit__List.edges
                }
                paginationProps={paginationProps}
                {...props}
              />
            ) : null
          }}
        </ApolloLoadingContainer>
      )}
    </Query>
  )
}

class ChannelContentPageInner extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedPlan: null,
    }
  }

  openTrainingPlanModal = (plan) => {
    this.setState({ selectedPlan: plan })
    this.refs.viewTrainingPlanModal.show()
  }

  onPlanClick = (plan) => {
    if (this.context.location.pathname.includes(LEARNING_CHANNEL_PAGE_SUBSTRING)) {
      analytics.track(ANALYTICS_EVENTS.CLICKED_PLAN_CARD_VIA_LEARNING, {
        'Plan has new content': plan.hasNewLessonsForCurrentUser,
      })
    }
  }

  firstPlanId = () => _.head(this.props.trainingPlanTrainingUnits).node.trainingPlan.id

  createTrainingPlanCard = (plan, shouldLock) => (
    <TrainingPlanCard
      key={plan.id}
      className={plan.id === this.firstPlanId() && PENDO_FIRST_PLAN_CLASS_NAME}
      trainingPlan={plan}
      currentUser={this.context.currentUser}
      openTrainingPlanModal={this.openTrainingPlanModal}
      isPublicPage={this.props.isPublicPage}
      ownerChannel={this.props.channel.id}
      onPlanCardClick={() => {
        this.onPlanClick(plan)
      }}
      channelConnected={this.props.channel.isSubscribedToByCurrentCompany}
      channelRequested={this.props.channel.existingRequestForCompany}
      lock={shouldLock}
      goToChannelOnCompletion
    />
  )

  showVideoModal = () => {
    this.refs.modal.show()
  }

  updateParentModal = () => {
    // Refresh parent modal so it's scrollable once video is closed. This is
    // a hack that's needed because if there's a modal on top of another modal,
    // the 1st modal will lose ability to scroll after the 2nd modal is closed.
    // Bumping the modal's key fixes this problem.
    if (this.props.isPublicPage && this.props.updateParentModal) {
      this.props.updateParentModal()
    }
  }

  render() {
    const { channel, isPublicPage, trainingPlanTrainingUnits, paginationProps } = this.props

    let coverImage, channelName, channelId

    if (channel) {
      coverImage = (
        <CoverImage
          channel={channel}
          currentUser={this.context.currentUser}
          isPublicPage={isPublicPage}
        />
      )
      channelName = channel.name
      channelId = channel.id
    }

    const noPlansText = isPublicPage ? t('no_plans_available_connect') : t('no_plans_available')

    return (
      <div style={styles.background}>
        {coverImage}
        {channel && <ChannelAbout channel={channel} />}
        <Panel>
          {!this.props.isPublicPage && (
            <SearchInput
              styles={styles.searchInput}
              onChange={(event) => {
                this.props.onSearchChange(event.target.value)
              }}
            />
          )}
          <div style={Style.common.clearBoth} />
          {trainingPlanTrainingUnits && (
            <div>
              <TrainingPlansCollection
                trainingPlans={trainingPlanTrainingUnits.map((tptu) =>
                  Im.freeze(tptu.node.trainingPlan)
                )}
                loadMore={paginationProps.loadMoreData}
                moreAvailable={paginationProps.moreDataAvailable}
                isLoading={paginationProps.dataIsLoading}
                createCard={this.createTrainingPlanCard}
                requireSequentialCompletion={channel && channel.requireSequentialCompletion}
              />
            </div>
          )}
          <ViewTrainingPlanModal
            ref="viewTrainingPlanModal"
            trainingPlan={this.state.selectedPlan}
            currentUser={this.context.currentUser}
            goToChannelOnCompletion={channelId}
            editable
          />
        </Panel>
      </div>
    )
  }
}

const styles = StyleSheet.create({
  background: {
    backgroundColor: Style.vars.deprecatedColors.white,
  },
  overlay: {
    backgroundColor: Style.vars.deprecatedColors.overlay,
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  searchInput: {
    container: {
      marginTop: 40,
      marginLeft: 40,
      marginBottom: 20,
      float: 'left',
      width: 260,
      paddingLeft: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: Style.vars.deprecatedColors.grey,
      borderRadius: 5,
      [Style.vars.media.mobile]: {
        marginLeft: 20,
      },
      height: '2.6em',
      fontSize: 14,
      color: Style.vars.deprecatedColors.darkGrey,
    },
  },
})
