import ReadOnlyTable from './readonly-table'
import SelectableTable, {
  COMPLETE,
  MATCH_RATIO,
  PERFECT_MATCH_RATIO,
  SELECTED_KEY_PREFIX,
} from './matching-table'

const AWAITING_USER_RESPONSE = 'AWAITING_USER_RESPONSE'

export {
  SelectableTable,
  ReadOnlyTable,
  AWAITING_USER_RESPONSE,
  COMPLETE,
  MATCH_RATIO,
  PERFECT_MATCH_RATIO,
  SELECTED_KEY_PREFIX,
}
