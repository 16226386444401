import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Style from 'shared-js/style'
import { getDueDetails } from 'shared-js/utilities/time'

export const DueDate = ({ date }: { date: string | null }) => {
  if (!date) return null
  const { dueText, overdue } = getDueDetails(date)
  return <Date overdue={overdue}>{dueText}</Date>
}

const Date = styled.div<{ overdue: boolean }>`
  font-weight: 600;
  font-size: 1.25rem;
  margin: 5px 0;
  color: ${({ overdue }) => (overdue ? Style.colors.amber800 : 'white')};
`
