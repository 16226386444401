import { SubmitButton } from 'components/common/form-deprecated'
import { ANALYTICS_EVENTS } from 'core/constants'
import { t } from 'i18n'
import PLACEHOLDER_IMAGE from 'img/placeholder.svg'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import InvitationsState from 'state/invitations.js'
import Style from 'style/index'
import { formatUrl } from '../../common'

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
    width: '100%',
    padding: '5px 10px',
    cursor: 'pointer',
    ':hover': {
      boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.3)',
    },
  },
  containerWithBorder: {
    boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.5)',
    borderRadius: 5,
    padding: 10,
  },
  companyInfoContainer: {
    display: 'flex',
    width: '80%',
    maxWidth: 450,
  },
  logo: {
    height: 30,
    width: 60,
    marginRight: 15,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [Style.vars.media.mobile]: {
      display: 'none',
    },
  },
  button: {
    borderRadius: 4,
    padding: '8px 25px',
    textAlign: 'center',
    backgroundColor: Style.vars.deprecatedColors.green,
    color: Style.vars.deprecatedColors.white,
    height: 'auto',
    width: 'auto',
    margin: 0,
    ':hover': {
      textDecoration: 'underline',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: Style.vars.deprecatedColors.textBlack,
    color: Style.vars.deprecatedColors.white,
    borderRadius: 25,
    padding: '2px 3px',
    cursor: 'pointer',
  },
}

@Radium
class SelectCompanyButton extends React.Component {
  constructor() {
    super()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  render() {
    return (
      <SubmitButton
        style={styles.button}
        onClick={this.props.onCompanyItemClick}
        className="selectButton"
        text={t('select')}
        loading={this.props.loading}
      />
    )
  }
}

@Radium
export class CompanyItem extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  checkIfRequestApprovalRequired = (company) => {
    const requestRequired =
      company.user_count !== 0 &&
      (!company.subdomain ||
        company.company_type === 'brand' ||
        !company.companysettings.public_access)
    return requestRequired
  }

  renderCloseIcon = () => (
    <span
      style={styles.closeIcon}
      onClick={this.props.backToCompanyList}
      className="companyItemCloseIcon"
    >
      <i className="close icon" style={{ margin: 0 }} />
    </span>
  )

  renderSelectButton = () => (
    <SelectCompanyButton
      company={this.props.company}
      onCompanyItemClick={this.onCompanyItemClick}
      loading={this.state.loading}
    />
  )

  onCompanyItemClick = () => {
    this.setState({ loading: true })
    analytics.track(ANALYTICS_EVENTS.SELECTED_A_COMPANY_ON_SIGNUP, {
      companyName: this.props.company.name,
      companyID: this.props.company.id,
    })
    const ssoUrl = this.props.company.force_sso_via_url
    if (ssoUrl) {
      window.location.href = ssoUrl
    } else if (this.props.pendingInvitation) {
      this.acceptInvite()
    } else if (this.props.selectCompany) {
      this.props.selectCompany(this.props.company)
    }
  }

  acceptInvite = () => {
    this.setState({ loading: true })
    const invite = this.props.pendingInvitation
    /*
     * TODO: HANDLE CLICK "processing" + success / error state
     * + ensure response fits INVITATION_RESPONSE requirements
     */

    InvitationsState.ActionCreators.doListAction('accept_invite', {
      invite_id: invite.invite_id,
      invite_type: invite.invite_type,
    }).then((res) => {
      window.location.href = '/views/home/'
    })
  }

  render() {
    const company = this.props.company

    const requiresRequest = this.checkIfRequestApprovalRequired(company)

    let containerStyling = styles.container
    if (this.props.pickedCompany) {
      containerStyling = { ...styles.container, ...styles.containerWithBorder }
      styles.companyInfoContainer.width = '85%'
    }

    const companyUrl = formatUrl(this.props.company.company_url)
    return (
      <div className="content" style={containerStyling} onClick={this.onCompanyItemClick}>
        <div style={styles.companyInfoContainer}>
          <div
            style={{
              ...styles.logo,
              backgroundImage: `url(${company.company_logo || PLACEHOLDER_IMAGE})`,
            }}
          />
          <div className="title">
            {company.name}
            <p style={{ fontStyle: 'italic', marginTop: 5 }}>
              {company.region_of_responsibility}
              {' '}
              {companyUrl && ` - ${companyUrl}`}
            </p>
          </div>
        </div>
        {this.props.pickedCompany ? this.renderCloseIcon() : this.renderSelectButton()}
      </div>
    )
  }
}
