import { useMutation } from '@apollo/react-hooks'
import { SUPPLIER, DEALER } from 'core/constants'
import { updateCurrentUserCache } from 'shared-js/utilities/hooks'
import { UPDATE_BRAND_DETAILS, UPDATE_RETAILER_DETAILS } from './update-company-mutations'
import {
  UpdateCompanyDetails as Mutation,
  UpdateCompanyDetailsVariables as Variables,
} from './gql-types/UpdateCompanyDetails'

const BRAND = 'BRAND'

const getNewCacheData = (data: any, mutationResult: any) => {
  const productSegmentType =
    mutationResult.data.Company__Update.result.companyType === BRAND ? SUPPLIER : DEALER

  return {
    currentUser: {
      ...data.currentUser,
      company: {
        ...data.currentUser.company,
        hasUnlockedNetworkFeature: true,
        productSegmentType,
      },
    },
    flagItems: data.flagItems,
  }
}

export const useUpdateCompanyMutation = (isABrand: boolean, onCompleted: (props: any) => void) => {
  const updateCurrentUser = (cache: any, mutationResult: any) => {
    if (cache && mutationResult) {
      updateCurrentUserCache({
        cache,
        mutationResult,
        getNewCacheData,
      })
    } else {
      window.location.reload(false)
    }
  }

  const [updateBrand] = useMutation<Mutation, Variables>(UPDATE_BRAND_DETAILS, {
    onCompleted,
    update: (cache, result) => {
      updateCurrentUser(cache, result)
    },
  })

  const [updateRetailer] = useMutation<Mutation, Variables>(UPDATE_RETAILER_DETAILS, {
    onCompleted,
    update: (cache, result) => {
      updateCurrentUser(cache, result)
    },
  })

  if (isABrand) {
    return updateBrand
  }
  return updateRetailer
}
