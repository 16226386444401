import { StaticModal } from 'components/common/modals/static'
import { t } from 'i18n'
import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import TrainingPlansState from 'state/training-plans'
import containerUtils from 'utilities/containers'
import { resolve } from 'utilities/deprecated-named-routes'
import $y from 'utilities/yaler'
import { TrainingPlanCardDetails } from 'components/common/plan-card-deprecated/card'
import { CreateNewPostForm } from '.'

function PlanAnnouncementModalInner(props, context) {
  const onSubmit = () => {
    context.displayTempPositiveMessage({
      heading: 'announcement_posted',
    })
    context.router.goBack()
  }
  const backPath = `${resolve('plan-management', {
    planId: context.routeParams.planId,
  })}?prevChannel=${context.location.query.prevChannel}`

  return (
    <StaticModal
      header={t('announce_to_users')}
      {...props}
      location={context.location}
      backPath={backPath}
    >
      <CreateNewPostForm {...props} onSubmit={onSubmit} />
    </StaticModal>
  )
}
PlanAnnouncementModalInner.contextTypes = {
  routeParams: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  displayTempPositiveMessage: PropTypes.func.isRequired,
}

export const PlanAnnouncementModal = Marty.createContainer(PlanAnnouncementModalInner, {
  contextTypes: {
    routeParams: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [TrainingPlansState.Store],

  fetch: {
    plan() {
      return TrainingPlansState.Store.getItem(this.context.routeParams.planId, {
        fields: [
          'id',
          'name',
          'needs_announcement',
          'last_announcement_date',
          'num_enrolled_users',
          'num_enrolled_users_in_own_company',
          $y.getFields(TrainingPlanCardDetails, 'trainingPlan'),
        ],
      })
    },
  },
  pending() {
    return containerUtils.defaultPending(this, PlanAnnouncementModalInner)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, PlanAnnouncementModalInner, errors)
  },
})
