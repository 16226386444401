import React from 'react'
import styled from 'styled-components'
import { colors } from 'shared-js/style/colors'
import { t } from 'shared-js/i18n'
import SPACE_INVADER from 'img/space-invader.png'

declare global {
  interface Window {
    Intercom: any
  }
}

type ErrorMessageProps = {
  /** Used within the intercom message to determine the user's location in the app */
  location: string
  /** Longhand CSS value for margins which is passed to the component's container */
  margin?: string
}

type ContainerProps = {
  margin?: string
}

export const ErrorMessage = ({ location, margin }: ErrorMessageProps) => {
  const createIntercomMessage = () => {
    if (window.Intercom) {
      window.Intercom('showNewMessage', `Help! I'm seeing an error message in ${location}`)
    }
  }
  return (
    <Container margin={margin}>
      <Image />
      <Text>{t('uh_oh_something_went_wrong')}</Text>
      <ReloadPage onClick={() => window.location.reload(false)}>{t('reload_page')}</ReloadPage>
      <NotifyMyagiLink onClick={createIntercomMessage}>{t('notify_myagi')}</NotifyMyagiLink>
    </Container>
  )
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${({ margin }) => margin || 0};
`

const Text = styled.div`
  margin-top: 15px;
  font-weight: bold;
  color: ${colors.grey900};
`

const ReloadPage = styled.button`
  background-color: ${colors.indigo700};
  cursor: pointer;
  padding: 10px 25px;
  color: ${colors.white};
  border-radius: 20px;
  margin-top: 25px;
  font-weight: 900;
  border: none;
`

const NotifyMyagiLink = styled.div`
  cursor: pointer;
  color: ${colors.indigo800};
  margin-top: 15px;
  font-size: 12px;
  font-weight: bold;
`

const Image = styled.div`
  height: 70px;
  width: 70px;
  background: no-repeat center/contain url(${SPACE_INVADER});
`
