import { RouterTabs } from 'components/common/router-tabs'
import Marty from 'marty'
import React from 'react'
import PropTypes from 'prop-types'
import Im from 'immutable'

import containerUtils from 'utilities/containers'
import { Box } from 'components/common/box'

import CompaniesState from 'state/companies'
import JoinCompanyRequestsState from 'state/join-company-requests'

class Company extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
  }

  routerTabs = () => {
    const base = `/views/myagi-staff/company/${this.props.params.companyId}`

    const numberOfRequests = this.props.requests ? this.props.requests.length : ''

    return [
      { name: 'Admin', to: `${base}/admin/` },
      { name: 'Settings', to: `${base}/settings/` },
      { name: 'Scraper', to: `${base}/scraper/` },
      { name: `Join Requests (${numberOfRequests})`, to: `${base}/requests/` },
    ]
  }

  render() {
    return (
      <Box>
        <RouterTabs tabs={this.routerTabs()} />
        {this.props.children}
      </Box>
    )
  }
}

export const Page = Marty.createContainer(Company, {
  contextTypes: {
    currentUser: PropTypes.instanceOf(Im.Map).isRequired,
    routeParams: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  },

  listenTo: [CompaniesState.Store, JoinCompanyRequestsState.Store],

  fetch: {
    company() {
      return CompaniesState.Store.getItem(this.props.params.companyId, {
        fields: ['name'],
      })
    },

    requests() {
      return JoinCompanyRequestsState.Store.getItems({
        closed_date__isnull: true,
        outcome__isnull: true,
        requester__company__isnull: true,
        company: this.props.params.companyId,
        fields: ['requester'],
      })
    },
  },

  pending() {
    return containerUtils.defaultPending(this, Company)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, Company, errors)
  },
})
