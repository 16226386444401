import { AvatarImage } from 'components/common/avatar-images'
import { Dropdown } from 'components/common/dropdown'
import { SlideToggle } from 'components/common/form-deprecated'
import { GatedFeatureModal, GROUPS_AND_AREAS } from 'components/common/gated-feature'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import { Info } from 'components/common/info'
import { LoadingContainer } from 'components/common/loading'
import { Modal } from 'components/common/modals'
import { EditUserModal } from 'components/common/modals/edit-user-modal'
import { remoteSearchMixinFactory } from 'components/common/search'
import { DeprecatedScrollableDataTable } from 'components/common/table'
import { t } from 'i18n'
import _ from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'
import Im from 'shared-js/immutable'
import createPaginatedStateContainer from 'state/pagination'
import TeamsState from 'state/teams'
import UsersState from 'state/users'
import Style from 'style'
import containerUtils from 'utilities/containers'
import { resolve } from 'utilities/deprecated-named-routes'
import $y from 'utilities/yaler'
import PageState from './state'
import { withRedirectToFrontend2ByFeatureFlag } from '../../common/redirect-to-frontend2'

const TABLET_WIDTH = 768
const ALL_USERS = 'all_users'
const TEAM_MANAGERS = 'team_managers'
const COMPANY_ADMINS = 'company_admins'

const styles = {
  searchFilterContainer: {
    marginTop: -12,
    marginBottom: 25,
    padding: '5px 0px',
    color: Style.vars.deprecatedColors.xxDarkGrey,
    clear: 'both',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  sorting: {
    width: 165,
    border: 'none',
    cursor: 'pointer',
    height: 20,
    container: {
      display: 'inline-block',
    },
  },
  heading: {
    display: 'none',
  },
  avatarImage: {
    marginRight: 15,
    cursor: 'pointer',
  },
  noLgName: {
    color: Style.vars.deprecatedColors.darkGrey,
    fontStyle: 'italic',
  },
  dropdownMenu: {
    width: '125px',
  },
  dropItem: {},
  settingsIcon: {
    color: Style.vars.deprecatedColors.darkGrey,
    cursor: 'pointer',
    fontSize: 18,
  },
  removeUserIcon: {
    color: Style.vars.deprecatedColors.darkGrey,
    fontSize: 16,
    float: 'right',
  },
}

export class UsersCollection extends React.Component {
  static data = {
    users: {
      many: true,
      fields: [
        'id',
        'url',
        'first_name',
        'last_name',
        'email',
        'date_joined',
        'is_active',
        'learner.id',
        'learner.learnergroup_name',
        'learner.is_learner_group_admin',
        'learner.is_company_admin',
        'profile_photo',
        $y.getFields(EditUserModal, 'user'),
      ],
    },

    teams: $y.getData(EditUserModal, 'teams'),
  }

  static propTypes = $y.propTypesFromData(UsersCollection)

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  static tableDataMapping = {
    name: (user, cxt) => (
      <div
        onClick={_.partial(cxt.userDetail, user)}
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        key={user.first_name}
      >
        <AvatarImage size="2.5em" style={styles.avatarImage} user={user} />
        <div>
          {user.first_name} 
{' '}
{user.last_name}
        </div>
      </div>
    ),
    team: (user, cxt) => {
      let lgName = user.learner.learnergroup_name
      const lgNameStyle = lgName ? styles.lgName : styles.noLgName
      lgName = lgName || 'No Team'
      return (
        <div className="ui grid" key={lgName}>
          <div className="twelve wide column" style={lgNameStyle}>
            {lgName}
          </div>
        </div>
      )
    },
    date_joined: (user) => (
      <div key={moment(user.date_joined).unix()}>{`${moment(user.date_joined).fromNow()}`}</div>
    ),
    team_manager: (user, cxt) => {
      const onChange = () => {
        cxt.toggleTeamManagerStatus(user)
      }
      const isAdmin = user.learner.is_learner_group_admin
      const groupsAndAreasEnabled =
        cxt.context.currentUser.company.subscription.user_permissions_enabled
      // Disable toggle when groups and areas are disabled
      const currentUserHasNoPermission = !(
        cxt.context.currentUser.learner.is_company_admin ||
        cxt.context.currentUser.learner.is_parent_company_admin ||
        cxt.context.currentUser.learner.is_area_manager
      )
      const onDisabledClick = currentUserHasNoPermission ? _.noop : cxt.onDisabledOptionClick
      const slideToggle = cxt.createSlideToggle(
        'Team manager',
        isAdmin,
        onChange,
        !groupsAndAreasEnabled || currentUserHasNoPermission,
        onDisabledClick
      )
      return slideToggle
    },
    company_admin: (user, cxt) => {
      const onChange = () => {
        cxt.toggleCompanyAdminStatus(user)
      }
      const isAdmin = user.learner.is_company_admin
      const groupsAndAreasEnabled =
        cxt.context.currentUser.company.subscription.user_permissions_enabled
      // Disable toggle when groups and areas are disabled
      const userIsSelf = user.id === cxt.context.currentUser.id
      const currentUserHasNoPermission = !(
        cxt.context.currentUser.learner.is_company_admin ||
        cxt.context.currentUser.learner.is_parent_company_admin
      )
      const disabled =
        userIsSelf || !groupsAndAreasEnabled || !cxt.context.currentUser.learner.is_company_admin
      const onDisabledClick =
        userIsSelf || currentUserHasNoPermission ? _.noop : cxt.onDisabledOptionClick
      const slideToggle = cxt.createSlideToggle(
        'Company admin',
        isAdmin,
        onChange,
        disabled,
        onDisabledClick
      )
      return slideToggle
    },
    actions: (user, cxt) => {
      const removeUser = _.partial(cxt.showRemoveUserModal, user)
      const editUser = _.partial(cxt.showEditUserModal, user)
      return (
        <Dropdown
          className="ui top left pointing dropdown"
          disabled={!cxt.context.currentUser.learner.is_company_admin}
        >
          <i className="setting icon" style={styles.settingsIcon} />
          <div className="menu" style={styles.dropdownMenu}>
            <div className="ui item" style={styles.dropItem} onClick={editUser}>
              {t('edit')}
              <i className="ui edit outline icon" style={styles.removeUserIcon} />
            </div>
            <div className="ui item" style={styles.dropItem} onClick={removeUser}>
              {t('remove')}
              <i className="remove icon" style={styles.removeUserIcon} />
            </div>
          </div>
        </Dropdown>
      )
    },
  }

  constructor() {
    super()
    this.state = {
      removedUsers: Im.freeze({}),
      editUser: null,
    }

    this.editUserModalRef = React.createRef()
    this.removeUserModalRef = React.createRef()
  }

  onDisabledOptionClick = () => {
    this.teamGatedModal.show()
  }

  getDataMapping() {
    let mapping = UsersCollection.tableDataMapping
    if (!this.context.currentUser.learner.is_company_admin) {
      mapping = _.extend({}, mapping)
      delete mapping.company_admin
    }
    return mapping
  }

  getHeaders() {
    const headings = _.keys(this.getDataMapping())
    // Alter headings to add tooltips
    const teamManagerIndex = _.indexOf(headings, 'team_manager')
    const companyAdminIndex = _.indexOf(headings, 'company_admin')
    const teamManagerInfo = <Info content={t('team_manager_info')} />
    const companyAdminInfo = <Info content={t('company_admin_info')} />
    headings[teamManagerIndex] = (
      <span key="team_manager">
        {t('team_manager')}
        {teamManagerInfo}
      </span>
    )
    if (companyAdminIndex >= 0) {
      headings[companyAdminIndex] = (
        <span key="company_admin">
          {t('company_admin')}
          {companyAdminInfo}
        </span>
      )
    }
    return Im.freeze(headings)
  }

  createSlideToggle = (label, initial, onChange, disabled = false, onDisabledClick = _.noop) => (
    <SlideToggle
      key={initial}
      initialValue={initial}
      onChange={onChange}
      disabled={disabled}
      onDisabledClick={onDisabledClick}
    />
  )

  getRows() {
    const funcs = _.values(this.getDataMapping())
    return this.props.users
      .filter((user) => !this.state.removedUsers[user.id])
      .map((user) => Im.freeze(funcs.map((func) => func(user, this))))
  }

  toggleGroupMembership(user, isMember, groupName) {
    const method = isMember ? 'remove_from_group' : 'add_to_group'
    UsersState.ActionCreators.doDetailAction(
      user.id,
      method,
      { name: groupName },
      { query: { fields: getUserFetchFields() } }
    ).catch((err) => {
      const message = err.response.body.message
      this.context.displayTempNegativeMessage({
        heading: 'Error',
        body: `<b>${message}</b>`,
      })
    })
  }

  toggleTeamManagerStatus(user) {
    this.toggleGroupMembership(user, user.learner.is_learner_group_admin, 'team_managers')
  }

  toggleCompanyAdminStatus(user) {
    this.toggleGroupMembership(user, user.learner.is_company_admin, 'company_admins')
  }

  removeUserFromCompany = (user) => {
    UsersState.ActionCreators.doDetailAction(user.id, 'remove_user_from_current_company', {})
      .then(() => {
        this.setState(
          (prevState) => ({
            removedUsers: Im.set(prevState.removedUsers, user.id, user),
          }),
          () => {
            this.context.displayTempPositiveMessage({
              heading: 'Success',
              body: `${user.first_name} ${user.last_name} has been removed.`,
            })
          }
        )
      })
      .catch((err) => {
        const message = err.response.body.message
        this.context.displayTempNegativeMessage({
          heading: 'Error',
          body: `<b>${message}</b>`,
        })
      })
      .then(() => {
        this.removeUserModalRef.current.hide()
      })
  }

  showRemoveUserModal = (user) => {
    this.setState({ editUser: user }, () => {
      this.removeUserModalRef.current.show()
    })
  }

  showEditUserModal = (user) => {
    this.setState({ editUser: user }, () => {
      this.editUserModalRef.current.show()
    })
  }

  userDetail = (user) => {
    this.context.router.push(
      resolve('profile', {
        userId: user.id,
      })
    )
  }

  render() {
    const headers = this.getHeaders()
    const rows = this.getRows()
    const removeFunc = _.partial(this.removeUserFromCompany, this.state.editUser)
    const removeUserName = this.state.editUser ? this.state.editUser.full_name : ''

    return (
      <React.Fragment>
        <InfiniteScroll
          loadMore={this.props.loadMore}
          moreDataAvailable={this.props.moreDataAvailable}
          dataIsLoading={this.props.dataIsLoading}
        >
          <DeprecatedScrollableDataTable
            headers={headers}
            rows={rows}
            bodyHeight={null}
            // Disabled sort when user is searching, because table will be sorted according to
            // search rank
            sortDisabled={Boolean(this.props.search)}
          />
        </InfiniteScroll>

        <Modal
          ref={this.removeUserModalRef}
          onConfirm={removeFunc}
          header={t('are_you_sure_remove_user', { removeUserName })}
          basic
        />

        {this.state.editUser && (
          <EditUserModal ref={this.editUserModalRef} user={this.state.editUser} />
        )}

        <GatedFeatureModal
          ref={(teamGatedModal) => (this.teamGatedModal = teamGatedModal)}
          headerText={t('upgrade_to_pro')}
          descriptionText={t('all_user_admin_access')}
          featureType={GROUPS_AND_AREAS}
        />
      </React.Fragment>
    )
  }
}

@reactMixin.decorate(remoteSearchMixinFactory(PageState.ActionCreators.setSearch))
export class UsersPage extends React.Component {
  static data = {
    users: $y.getData(UsersCollection, 'users', { required: false }),
    teams: $y.getData(UsersCollection, 'teams', { required: false }),
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  static propTypes = $y.propTypesFromData(UsersPage)

  setFilter = (newFilter) => {
    PageState.ActionCreators.setFilter(newFilter)
  }

  handleFilterSelect = (option) => {
    PageState.ActionCreators.setCurrentFilterQuery(option.value)
  }

  render() {
    const FILTER_OPTIONS = [
      { value: ALL_USERS, label: t(ALL_USERS) },
      { value: { is_team_manager: true }, label: t(TEAM_MANAGERS) },
      { value: { is_company_admin: true }, label: t(COMPANY_ADMINS) },
    ]
    return (
      <div>
        <LoadingContainer
          loadingProps={[this.props.users, this.props.teams]}
          createComponent={() => (
            <UsersCollection
              users={this.props.users.filter((user) => user.learner)}
              teams={this.props.teams}
              loadMore={this.props.loadMore}
              moreDataAvailable={this.props.moreDataAvailable}
              dataIsLoading={this.props.dataIsLoading}
              search={this.props.search}
            />
          )}
          noDataText={t('there_are_no_users_in_this_company')}
        />
      </div>
    )
  }
}

function getUserFetchFields() {
  // Added this function to ensure that fetch fields are the same
  // for getting users and for when detail actions are used to update
  // users
  return $y.getFields(UsersPage, 'users')
}

export const getViewableUsersQuery = (query, currentUser) => {
  const { learner, company } = currentUser
  if (learner.is_area_manager && !learner.is_company_admin) {
    return {
      ...query,
      company: company.id,
      users_with_area_manager: currentUser.id,
    }
  }
  return {
    ...query,
    company: company.id,
  }
}

const InnerPage = createPaginatedStateContainer(UsersPage, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  },

  listenTo: [PageState.Store, UsersState.Store, TeamsState.Store],

  paginate: {
    store: UsersState.Store,
    propName: 'users',
    limit: 50,
    getQuery() {
      const learner = this.context.currentUser.learner
      const curFilterQuery = PageState.Store.getCurrentFilterQuery(
        this.context.currentUser,
        this.context.location.query.filter
      )
      let query = _.extend(
        {
          ordering: 'first_name',
          fields: getUserFetchFields(),
        },
        {
          [curFilterQuery]: true,
        }
      )
      // If not admin, only show active users
      if (!learner.is_company_admin) {
        query.is_active = true
      }
      query = getViewableUsersQuery(query, this.context.currentUser)
      const search = PageState.Store.getSearch()
      if (search) {
        query.search = search
        query.ordering = '-search_rank'
      }
      return query
    },
  },

  fetch: {
    usersCount() {
      const query = getViewableUsersQuery({}, this.context.currentUser)
      return UsersState.Store.getKnownCountForQuery(query)
    },
    search() {
      return PageState.Store.getSearch()
    },
    teams() {
      const query = {
        ordering: 'name',
        limit: 0,
        fields: $y.getFields(UsersPage, 'teams'),
      }
      return TeamsState.Store.getItems(query)
    },
  },

  UNSAFE_componentWillMount() {
    PageState.Store.resetState()
  },

  pending() {
    return containerUtils.defaultPending(this, UsersPage)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, UsersPage, errors)
  },
})

export const Page = withRedirectToFrontend2ByFeatureFlag('frontend2_people')(InnerPage)
