import React, { lazy, createContext } from 'react'
import { Route, Redirect, IndexRedirect } from 'react-router'
import { lazyLoadComponent } from 'core/lazy-load-component'

import { Page as ChannelDiscoveryDirectoryPage } from 'components/discover/channel-discovery/channel-directory/page'

import { Page as ContentManagementPage } from 'components/content-management/page'
import { FeedbackPage } from 'components/content-management/feedback-tab/feedback-page'
import { Page as ChannelConnectionsContainerTab } from './channels-tab/channel/connections/page'
import { TrainingPlansTab as ChannelPlansTab } from './channels-tab/channel/training-plans'
import { AddPlanModal } from './channels-tab/channel/training-plans/add-plans'
import { PlanAnnouncementModal } from './plans-tab/plan/announcement/modal'
import { DetailsTab as ChannelDetailsTab } from './channels-tab/channel/details'
import { BadgeModal } from './channels-tab/channel/details/badges/modal'

import { Page as PlansTabContent } from './plans-tab/page'
import { Page as ContentChannelsTabContent } from './channels-tab/page'
import { Page as ChannelSharingPage } from './connections-tab/page'
import { Page as PlanManagementPage } from './plans-tab/plan/page'
import { Page as ChannelPage } from './channels-tab/channel/page'
import { Page as DiscoverMoreContentPage } from './discover-content'

const LessonManagementPage = lazy(() =>
  import(/* webpackChunkName: "content" */ './lesson/lesson-page').then((result) => ({
    default: result.Page,
  }))
)

export const editContentRoutes = (
  <React.Fragment>
    <Route path="announce/:planId/" component={PlanAnnouncementModal} />
    <Route name="plan-management" path="plan/:planId/" component={PlanManagementPage}>
      <Route path="announce/" name="plan-announcement" component={PlanAnnouncementModal} />
    </Route>
    <Route
      name="lesson-management"
      path="lesson/:lessonId/"
      component={lazyLoadComponent(LessonManagementPage)}
    />
    <Route name="channel" path="channel/:channelId/" component={ChannelPage}>
      <IndexRedirect to="plans/" />
      <Route path="plans/" component={ChannelPlansTab}>
        <Route path="add/" component={AddPlanModal} />
      </Route>
      <Route path="connections/" component={ChannelConnectionsContainerTab} />
      <Route name="channel-details" path="details/" component={ChannelDetailsTab}>
        <Route path="badge-modal/" component={BadgeModal} />
        <Route path="badge-modal/:badgeId/" component={BadgeModal} />
      </Route>
    </Route>
  </React.Fragment>
)

export const contentRoutes = (
  <Route component={ContentManagementPage}>
    <IndexRedirect to="plans/" />
    <Route path="plans/" component={PlansTabContent} />
    <Redirect from="channels/your-channels/" to="channels/" />
    <Redirect from="channels/connections/" to="connections/" />
    <Redirect from="channels/requests/" to="connections/" />
    <Redirect from="channels/sharelinks/" to="connections/" />
    <Redirect from="plans/:planId/" to="/views/edit-content/plan/:planId/" />
    <Redirect from="lessons/:lessonId/" to="/views/edit-content/lesson/:lessonId/" />
    <Route name="content-channels" path="channels/" component={ContentChannelsTabContent} />
    <Route path="connections/" component={ChannelSharingPage} />
    <Route
      path="discover/"
      name="channel-directory"
      component={(props) => <ChannelDiscoveryDirectoryPage {...props} contentManagement />}
    >
      <Route
        path="*"
        component={(props) => <ChannelDiscoveryDirectoryPage {...props} contentManagement />}
      />
    </Route>

    <Route path="discover-more/" name="discover-content" component={DiscoverMoreContentPage} />

    <Route
      path="feedback/"
      name="feedback"
      component={(props) => <FeedbackPage {...props} contentManagement />}
    />
    <Redirect from="*" to="plans/" />
  </Route>
)
