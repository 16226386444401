import React, { useState } from 'react'
import { t } from 'shared-js/i18n'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { MultiSelect } from 'ui-kit'
import {
  Regions as Query,
  RegionsVariables as Variables,
  Regions_Region__List_edges as Region,
} from './gql-types/Regions'

const MAX_REGIONS = 12

type RegionOption = {
  value: string
  label: string
}

type RegionOfResponsibilityFieldProps = {
  isABrand: boolean
  value: any[]
  error: undefined | string
  touched: boolean
  onBlur(props: any): void
  onChange(props: any): void
  [key: string]: any
}

const REGIONS = gql`
  query Regions($maxToShow: Int!, $searchTerm: String, $orderBy: [String]!) {
    Region__List(first: $maxToShow, search: $searchTerm, orderBy: $orderBy) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const RegionOfResponsibilityField = ({
  isABrand,
  ...formikProps
}: RegionOfResponsibilityFieldProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const variables = {
    maxToShow: MAX_REGIONS,
    orderBy: ['name'],
    searchTerm,
  }

  const apolloProps = useQuery<Query, Variables>(REGIONS, { variables })

  const options =
    !apolloProps.loading && apolloProps?.data?.Region__List?.edges
      ? apolloProps.data.Region__List.edges.map((region: Region | null) => {
          if (region?.node) {
            return {
              value: region.node.id,
              label: region.node.name,
            }
          }
          return null
        })
      : []

  const onInputChange = (event: object, value: string, reason: string) => {
    if (reason === 'input') {
      setSearchTerm(value)
    }
  }

  return (
    <MultiSelect
      loading={apolloProps.loading}
      placeholder={t('regions_of_operation_titlecase')}
      options={options}
      getOptionLabel={(option: RegionOption) => option.label}
      optionKey="value"
      onInputChange={onInputChange}
      helperText={t('type_to_search_for_the_countries_regions_you_operate_in')}
      {...formikProps}
    />
  )
}

export default RegionOfResponsibilityField
