/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { CSSProperties } from 'react'
import CONFIG from 'core/config'

interface EmbedlyCardProps {
  /** The url of the content */
  url: string
  /** You Embedly API Key. If you are a paid user this will remove Embedly's branding from the
   * cards. Default: `CONFIG.EMBEDLY_API_TOKEN`. */
  apiKey?: string
  /** Specifies the via content in the card. It's a great way to do attribution.  */
  via?: string
  /** Display the left hand colored border. Default: `true`. */
  chromeBorder?: boolean
  /** For dark backgrounds it's better to specify the dark theme. Default: `'light'`. */
  theme?: 'light' | 'dark'
  /** Specify which image to use in article cards. */
  articleImage?: string
  /** Instead of using the static page content, the card will embed the video or rich media.
   * Default: `false`. */
  richMedia?: string
  /** Enable Share Icons. Default: `false`. */
  shareIcons?: boolean
  /** Sets the width of the card. Specifically the max-width, so card is still responsive. */
  width?: string
  /** Align the card, Default: `'center'` */
  align?: 'left' | 'center' | 'right'
  /** Enable Embedly recommendations on video and rich cards. Default: `false`. */
  recommendations?: boolean
  className?: string
  style?: CSSProperties
}

type EmbedlyCustomizationProps = {
  'data-card-key'?: string
  'data-card-via'?: string
  'data-card-chrome'?: string
  'data-card-theme'?: string
  'data-card-image'?: string
  'data-card-embed'?: string
  'data-card-controls'?: string
  'data-card-width'?: string
  'data-card-align'?: string
  'data-card-recommend'?: string
}

/**
 * Display embedded content using an Embedly Card (configured for Myagi)
 *
 * @see https://docs.embed.ly/docs/cards
 */
export default function EmbedlyCard(props: EmbedlyCardProps) {
  const cardDataProps: EmbedlyCustomizationProps = {
    'data-card-key': props.apiKey ?? CONFIG.EMBEDLY_API_TOKEN,
    'data-card-controls': props.shareIcons ? '1' : '0',
    'data-card-recommend': props.recommendations ? '1' : '0',
  }
  if (props.via) cardDataProps['data-card-via'] = props.via
  if (props.chromeBorder !== undefined)
    cardDataProps['data-card-chrome'] = props.chromeBorder ? '1' : '0'
  if (props.theme) cardDataProps['data-card-theme'] = props.theme
  if (props.articleImage) cardDataProps['data-card-image'] = props.articleImage
  if (props.richMedia) cardDataProps['data-card-embed'] = props.richMedia
  if (props.width) cardDataProps['data-card-width'] = props.width
  if (props.align) cardDataProps['data-card-align'] = props.align

  return (
    <a
      href={props.url}
      {...cardDataProps}
      className={`embedly-card ${props.className}`}
      style={props.style}
    />
  )
}
