import { LoadingSpinner } from 'components/common/loading'
import InfiniteListView from 'shared-js/components/common/list/infinite'
import React from 'react'
import _ from 'lodash'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import StyleSheet from 'shared-js/style/style-sheet'
import { FeedbackCard } from './feedback-card'

const NoFeedback = () => (
  <div style={styles.noFeedback}>
    <span style={{ color: '#aaaaaa', fontSize: '1em' }}>{t('no_feedback')}</span>
  </div>
)

export class FeedbackList extends React.Component {
  renderFeedbackCard = ({ item }) => (
    <FeedbackCard
      feedback={item.node}
      key={item.node.id}
      handleReply={this.props.handleReply}
      isReply={this.props.isReply}
      currentUser={this.props.currentUser}
    />
  )

  render() {
    const discardEmptyNodes = (item) => Boolean(item.node)
    const { feedback, loadMore, moreDataAvailable, dataIsLoading } = this.props
    const actualDataIsLoading = !Object.keys(feedback) || dataIsLoading
    const data = feedback && feedback.edges ? feedback.edges.filter(discardEmptyNodes) : null
    const feedbackIsEmpty = actualDataIsLoading ? false : _.size(data) === 0

    return feedbackIsEmpty ? (
      <NoFeedback />
    ) : !data && actualDataIsLoading ? (
      <LoadingSpinner />
    ) : (
      <InfiniteListView
        ref={(flatList) => {
          this.flatList = flatList
        }}
        contentContainerStyle={
          this.props.style ? { ...styles.container, ...this.props.style } : styles.container
        }
        data={data}
        renderItem={this.renderFeedbackCard}
        loadMoreData={loadMore}
        moreDataAvailable={moreDataAvailable}
        dataIsLoading={dataIsLoading}
      />
    )
  }

  static propTypes = {
    feedback: PropTypes.object,
    loadMore: PropTypes.func,
    moreDataAvailable: PropTypes.bool,
    dataIsLoading: PropTypes.bool,
    currentUser: PropTypes.object,
    handleReply: PropTypes.func,
    isReply: PropTypes.bool,
    style: PropTypes.object,
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirction: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    minHeight: '100%',
    minWidth: '40%',
  },
  noFeedback: {
    alignSelf: 'center',
    padding: '50px',
  },
})
