import React from 'react'
import Style from 'shared-js/style'
import styled from 'styled-components'
import Icon from 'react-native-vector-icons/MaterialIcons'

export const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${Style.colors.grey900};
`

export const SectionTitleIcon = ({ iconName }: { iconName: string }) => (
  <Icon name={iconName} size={24} style={{ color: Style.colors.grey900, marginRight: 8 }} />
)

export const SectionTitleContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`
