import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import containerUtils from 'utilities/containers'
import createPaginatedStateContainer from 'state/pagination'
import { SelectObjects } from 'components/common/select-objects'
import EnrollmentGroupsState from 'state/enrollment-groups'
import { getDisplayNameFunction, GROUP_TAB } from '../constants'
import PageState from '../state'

{
  /*
    THIS COMPONENT IS COSMETIC ONLY!!!! (for the sephora demo)

    The request gets sent out normally, but the backend does nothing!

    This was because creating module attempts is time consuming, any
    more than 10 users is quite painful.
*/
}

class SelectGroupsInner extends React.Component {
  renderObject = (obj) => (
    <div>
      <b>{getDisplayNameFunction(GROUP_TAB)(obj)}</b>
      <span style={{ color: '#888' }}>
        {' '}
        - 
{' '}
{obj.num_real_users} 
{' '}
{t('users')}
      </span>
    </div>
  )

  render() {
    return (
      <SelectObjects
        loadMore={this.props.loadMore}
        moreDataAvailable={this.props.moreDataAvailable}
        dataIsLoading={this.props.dataIsLoading}
        objects={this.props.users}
        renderObject={this.renderObject}
        isSelected={this.props.isSelected}
        isDisabled={this.props.isSelected} // disabled if it has already been completed
        onChange={this.props.onChange}
        value={this.props.value}
      />
    )
  }
}

export const GroupSelector = createPaginatedStateContainer(SelectGroupsInner, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [PageState.Store, EnrollmentGroupsState.Store],

  paginate: {
    store: EnrollmentGroupsState.Store,
    propName: 'users',
    dependantOn: [PageState.Store, EnrollmentGroupsState.Store],
    limit: 20,
    getQuery() {
      const learner = this.context.currentUser.learner
      const query = {
        ordering: 'name',
        fields: ['id', 'name', 'has_completed_lesson', 'num_real_users'],
        is_active: true,
        has_completed_lesson: this.props.lesson.id,
        // Note: this was added for a sephora demo
        // where it was desirable that team managers be able to
        // mark off for every user in the company. This probably
        // isn't the best UX imho.
      }

      const search = PageState.Store.getSearch()
      if (search) {
        query.search = search
        query.ordering = '-search_rank'
      }

      return query
    },
  },

  UNSAFE_componentWillMount() {
    PageState.Store.resetState()
  },

  pending() {
    return containerUtils.defaultPending(this, SelectGroupsInner)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, SelectGroupsInner, errors)
  },
})
