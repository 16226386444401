import React from 'react'
import styled from 'styled-components'
import { ConnectedBrandsQuery_PublicCompany__List_edges_node as Connection_node } from './gql-types/ConnectedBrandsQuery'
import { StyledCheckboxIcon } from '../common'
import { useBrandsFilter } from '../../context'

const ITEM_LINE_HEIGHT = 20
const ITEM_PADDING_BOTTOM = 15
export const ITEM_HEIGHT = ITEM_LINE_HEIGHT + ITEM_PADDING_BOTTOM

type ContainerProps = {
  hasDiscoverableContent: boolean
}

const Brand = ({ style, brand }: { style: React.CSSProperties; brand: Connection_node }) => {
  const { brandsFiltered, addOrRemoveBrandFilter } = useBrandsFilter()
  // must use some and not includes as the objects seems to change per search term
  const filterIsActive = brand?.id && brandsFiltered.includes(brand?.id)
  const hasDiscoverableContent = Boolean(brand?.discoverableChannelsCount)

  return (
    <Container
      style={style}
      hasDiscoverableContent={hasDiscoverableContent}
      onClick={() => {
        if (hasDiscoverableContent || filterIsActive) addOrRemoveBrandFilter(brand)
      }}
    >
      <StyledCheckboxIcon
        name={filterIsActive ? 'check-box' : 'check-box-outline-blank'}
        size={20}
        isActive={filterIsActive}
      />
      {`${brand?.name} (${brand?.discoverableChannelsCount})`}
    </Container>
  )
}

const Container = styled.div<ContainerProps>`
  padding-bottom: ${ITEM_PADDING_BOTTOM}px;
  line-height: ${ITEM_LINE_HEIGHT}px;
  display: flex;
  cursor: ${({ hasDiscoverableContent }) => (hasDiscoverableContent ? 'pointer' : 'default')};
  color: ${({ hasDiscoverableContent }) => (hasDiscoverableContent ? '#3a454e' : '#939faa')};
`

export default Brand
