import React from 'react'
import PropTypes from 'prop-types'
import containerUtils from 'utilities/containers'
import createPaginatedStateContainer from 'state/pagination'
import { SelectObjects } from 'components/common/select-objects'
import UsersState from 'state/users'
import { getViewableUsersQuery } from 'components/people/users/page'
import { getDisplayNameFunction, USER_TAB } from '../constants'
import PageState from '../state'

class SelectUsersInner extends React.Component {
  renderObject = (obj) => (
    <div>
      <b>{getDisplayNameFunction(USER_TAB)(obj)}</b>
      <span style={{ color: '#888' }}>
{' '}
-
{obj.learner.learnergroup_name || 'No team'}
</span>
    </div>
  )

  render() {
    return (
      <SelectObjects
        loadMore={this.props.loadMore}
        moreDataAvailable={this.props.moreDataAvailable}
        dataIsLoading={this.props.dataIsLoading}
        objects={this.props.users}
        renderObject={this.renderObject}
        isSelected={this.props.isSelected}
        isDisabled={this.props.isSelected} // disabled if it has already been completed
        onChange={this.props.onChange}
        value={this.props.value}
      />
    )
  }
}

export const UserSelector = createPaginatedStateContainer(SelectUsersInner, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [PageState.Store, UsersState.Store],

  paginate: {
    store: UsersState.Store,
    propName: 'users',
    dependantOn: [PageState.Store, UsersState.Store],
    limit: 20,
    getQuery() {
      const learner = this.context.currentUser.learner
      const query = {
        ordering: 'first_name,last_name',
        fields: [
          'id',
          'first_name',
          'last_name',
          'learner.learnergroup_name',
          'has_completed_lesson',
        ],
        is_active: true,
        has_completed_lesson: this.props.lesson.id,
        // Note: this was added for a sephora demo
        // where it was desirable that team managers be able to
        // mark off for every user in the company. This probably
        // isn't the best UX imho.
      }

      const search = PageState.Store.getSearch()
      if (search) {
        query.search = search
        query.ordering = '-search_rank'
      }

      return getViewableUsersQuery(query, this.context.currentUser)
    },
  },

  UNSAFE_componentWillMount() {
    PageState.Store.resetState()
  },

  pending() {
    return containerUtils.defaultPending(this, SelectUsersInner)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, SelectUsersInner, errors)
  },
})
