import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import { resolve } from 'utilities/deprecated-named-routes'
import containerUtils from 'utilities/containers'
import { getIdFromApiUrl } from 'utilities/generic'

import { LoadingContainer } from 'components/common/loading'
import { Box, BoxHeader, BoxContent } from 'components/common/box'
import CompaniesState from 'state/companies'
import CompanySettingsState from 'state/companysettings'
import UsersState from 'state/users'

import { CompanySearchableSelect } from 'components/common/company-searchable-select'
import { CompanyInfoPage } from './company-info'

class CompanyManagementTool extends React.Component {
  static contextTypes = {
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      companyId: null,
    }
  }

  UNSAFE_componentWillMount() {
    const { company } = this.context.location.query
    if (company) {
      this.setState({
        companyId: company,
      })
    }
  }

  onCompanySelect = (val) => {
    let id
    if (val) {
      id = getIdFromApiUrl(val)
    }
    this.setState({
      companyId: id,
    })
    this.context.router.push(
      resolve({
        pathname: '/views/myagi-staff/sales-dashboard/',
        query: { company: id },
      })
    )
  }

  render() {
    return (
      <Box>
        <BoxHeader>
          <h1>SALES DASHBOARD</h1>
        </BoxHeader>

        <BoxContent>
          <div>
            <CompanySearchableSelect
              ref="coSelect"
              name="coURL"
              onChange={this.onCompanySelect}
              internalUse
            />
          </div>
        </BoxContent>
        <BoxContent>
          <LoadingContainer
            loadingProps={[this.props.currentUser]}
            createComponent={() => {
              if (this.state.companyId) {
                return (
                  <div>
                    <CompanyInfoPage
                      data={this.state.companyId}
                      currentUser={this.props.currentUser}
                    />
                  </div>
                )
              }
              return null
            }}
          />
        </BoxContent>
      </Box>
    )
  }
}

export const Page = Marty.createContainer(CompanyManagementTool, {
  listenTo: [CompanySettingsState.Store, CompaniesState.Store, UsersState.Store],

  fetch: {
    currentUser() {
      const fetch = UsersState.Store.getCurrent()
      return fetch
    },
  },

  pending() {
    return containerUtils.defaultPending(this, CompanyManagementTool)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, CompanyManagementTool, errors)
  },
})
