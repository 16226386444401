import { Panel } from 'components/common/box'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { useContext } from 'react'
import ActionBar from 'components/common/action-bar'
import DiscoverPageState from 'components/discover/channel-discovery/channel-directory/page-state'
import { DEALER } from 'core/constants'
import { CreateChannelButton, CreatePlanButton } from './common/modal-buttons'

import ChannelsPageState from './channels-tab/page-state'
import PlansPageState from './plans-tab/page-state'

const PLANS_ROUTE = '/views/content/plans/'
const CHANNELS_ROUTE = '/views/content/channels/'
const CONNECTIONS_ROUTE = '/views/content/connections/'
const DISCOVER_ROUTE = '/views/content/discover/'
const NEW_DISCOVER_ROUTE = '/views/content/discover-more/'

function ContentPage({ currentUser, children }, context) {
  const learner = currentUser.learner
  const isAdmin = Boolean(currentUser.learner.is_company_admin)

  ChannelsPageState.Store.setupFiltersForUser(currentUser)
  PlansPageState.Store.setupFiltersForUser(currentUser)

  const navItemsPostNetworkTabRelease = [
    {
      name: t('channels'),
      to: CHANNELS_ROUTE,
      condition: learner.can_manage_training_content,
      buttons: [
        isAdmin && (
          <CreateChannelButton key={1} currentUser={context.currentUser} color="primary" />
        ),
      ],
      filters: ChannelsPageState.Store.getFilterNames(),
      onFilterChange: (filter) => {
        ChannelsPageState.ActionCreators.onSetFilter(filter)
      },
      onSearchChange: (term) => {
        ChannelsPageState.ActionCreators.onSetChannelSearch(term)
      },
      indicator:
        !context.featureFlags.navigation_changes &&
        learner.can_manage_training_content &&
        currentUser.company.open_connection_request_count.incoming,
    },
    {
      name: t('plans'),
      to: PLANS_ROUTE,
      condition: learner.can_manage_training_content,
      buttons: [
        isAdmin && <CreatePlanButton key={1} currentUser={context.currentUser} color="primary" />,
      ],
      filters: PlansPageState.Store.getFilterNames(),
      onFilterChange: (filter) => PlansPageState.ActionCreators.setFilter(filter),
      onSearchChange: (term) => PlansPageState.ActionCreators.setTrainingPlanSearch(term),
    },
    {
      name: t('channel_sharing'),
      to: CONNECTIONS_ROUTE,
      condition: learner.can_make_new_channel_connections,
      indicator:
        !context.featureFlags.navigation_changes &&
        currentUser.company.subscription.shared_content_enabled &&
        currentUser.company.open_connection_request_count.outgoing,
    },
    {
      name: t('discover_content'),
      to: NEW_DISCOVER_ROUTE,
      condition: currentUser.company.product_segment_type === DEALER,
    },
    {
      name: t('feedback'),
      to: '/views/content/feedback/',
      filters: [t('all_feedback'), t('previously_responded'), t('without_reply')],
      condition: learner.can_manage_training_content && context.featureFlags.feedback_reply,
      onFilterChange: (term) => term,
    },
  ]

  const actionBarTabs = navItemsPostNetworkTabRelease
  return (
    <Panel>
      {learner.can_manage_training_content && (
        <div style={styles.headerContainer}>
          <ActionBar tabs={actionBarTabs} style={styles.actionBar} />
        </div>
      )}
      <div style={styles.contentContainer}>{children}</div>
    </Panel>
  )
}
ContentPage.contextTypes = {
  currentUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  featureFlags: PropTypes.objectOf(PropTypes.bool),
}

export const Page = Radium(ContentPage)

const styles = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    zIndex: 8,
  },
  actionBar: {
    marginBottom: 30,
  },
  contentContainer: {
    zIndex: 7,
  },
}
