import React, { forwardRef, useState, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import { ViewTrainingPlanModal } from 'components/training/plans/plan-modal'

const PageModals = forwardRef(({ channel, selectedPlan }, viewPlanModalRef) => {
  const { currentUser } = useCurrentUser()

  return (
    <React.Fragment>
      <ViewTrainingPlanModal
        ref={viewPlanModalRef}
        trainingPlan={selectedPlan}
        currentUser={currentUser}
        goToChannelOnCompletion={channel && channel.id}
        editable
      />
    </React.Fragment>
  )
})

PageModals.propTypes = {
  channel: PropTypes.object.isRequired,
  selectedPlan: PropTypes.object,
}

export default PageModals
