import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import Card from 'shared-js/components/common/card'
import { RouterTabs } from 'components/common/router-tabs'
import Style from 'style'
import { SearchInput } from 'components/common/form-deprecated/search'
import { DropdownFilterSet } from 'components/common/filter-set'
import { Dropdown } from 'components/common/dropdown.jsx'
import _ from 'lodash'

// ActionBar for Web
class ActionBar extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    tabs: PropTypes.array.isRequired,
    hideDropdown: PropTypes.bool,
  }

  static defaultProps = {
    style: {},
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  }

  setFilter = (filterName = '') => {
    const router = this.context.router
    router.push(`${router.location.pathname}?filter=${filterName}`)
  }

  isActive = (path) => {
    const currentPath = String(this.context.router.location.pathname)
    return currentPath.startsWith(path)
  }

  parseTabs = (tabsArray = this.props.tabs) => {
    const actionBar = { tabs: [] }
    _.forEach(tabsArray, (tab) => {
      if (tab.condition === undefined || tab.condition) {
        actionBar.tabs.push({
          name: tab.name,
          to: tab.to,
          indicator: tab.indicator,
        })
        if (this.isActive(tab.to)) {
          actionBar.buttons = tab.buttons || []
          actionBar.filters = tab.filters || []
          actionBar.onFilterChange = tab.onFilterChange
          actionBar.showSearch = Boolean(tab.onSearchChange || tab.onSearchSubmit)
          actionBar.onSearchChange = tab.onSearchChange || (() => undefined)
          actionBar.onSearchSubmit = tab.onSearchSubmit || (() => undefined)
        }
      }
    })
    return actionBar
  }

  renderButtons = (buttons) => {
    if (buttons) return buttons
  }

  renderButtonsDropdown = (buttons) => {
    if (!buttons || !buttons[0]) return null // sometimes buttons[0] === false 🤷

    return (
      <Dropdown style={styles.dropdown.container} className="ui dropdown">
        <i className="ellipsis vertical icon" style={styles.dropdown.icon} />
        <div className="menu" style={styles.dropdown.menu}>
          {_.map(buttons, (button, index) => (
            <div className="item" key={index}>
              {React.cloneElement(
                button,
                {
                  key: index,
                  className: 'item',
                  style: styles.dropdown.button,
                  color: 'transparent',
                  size: 'small',
                  textStyle: { color: '#000' },
                },
                null
              )}
            </div>
          ))}
        </div>
      </Dropdown>
    )
  }

  render() {
    const {
      tabs,
      buttons,
      filters,
      onFilterChange,
      showSearch,
      onSearchChange,
      onSearchSubmit,
    } = this.parseTabs()
    return (
      <Card style={{ ...styles.defaultContainer, ...this.props.style }}>
        <div style={styles.container}>
          <RouterTabs
            style={styles.tabs}
            itemStyle={styles.tab}
            itemActiveStyle={styles.tabActive}
            tabs={tabs}
          />
          {this.props.hideDropdown && buttons && buttons.length > 0 && (
            <div style={{ ...styles.actionButtonsRows, ...styles.mobileOnly }}>
              {this.renderButtons(buttons)}
            </div>
          )}
          {((filters && filters.length > 0) || (buttons && buttons.length > 0) || showSearch) && (
            <div style={styles.subContainer}>
              <div style={{ ...styles.actionButtons, ...styles.nonMobile }}>
                {this.renderButtons(buttons)}
              </div>
              {!this.props.hideDropdown && (
                <div style={{ ...styles.actionButtons, ...styles.mobileOnly }}>
                  {this.renderButtonsDropdown(buttons)}
                </div>
              )}
              {filters.length > 0 && onFilterChange && (
                <DropdownFilterSet
                  filterNames={filters}
                  initial={this.context.router.location.query.filter}
                  onFilterChange={(filter) => {
                    this.setFilter(filter)
                    onFilterChange(filter)
                  }}
                  styles={styles.dropdown}
                />
              )}
              {showSearch && (
                <SearchInput
                  styles={styles.searchInput}
                  onChange={(event) => onSearchChange(event.target.value)}
                  onDone={(event) => onSearchSubmit(event.target.value)}
                />
              )}
            </div>
          )}
        </div>
      </Card>
    )
  }
}
export default Radium(ActionBar)

const styles = {
  defaultContainer: {
    padding: 5,
    width: '100%',
    borderRadius: 0,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      alignItems: 'strech',
      justifyContent: 'flex-end',
    },
  },
  tabs: {
    flex: 1,
    display: 'flex',
    minHeight: 50,
    maxWidth: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: 0,
    padding: '10px 0',
    '@media (max-width: 800px)': {
      width: '100%',
      justifyContent: 'space-between',
      padding: '10px',
    },
    '@media (max-width: 430px)': {
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  tab: {
    flex: '1 1 0',
    marginBottom: 0,
    padding: '0 14px',
    textAlign: 'center',
    transition: 'all 0.3s ease',
    borderWidth: 0, // To remove default border
    ':hover': {
      color: '#000',
    },
    '@media (max-width: 800px)': {
      padding: '0 10px',
    },
    '@media (max-width: 430px)': {
      padding: '15px 14px',
    },
  },
  tabActive: {
    color: Style.vars.deprecatedColors.primary,
    fontWeight: '500',
    ':hover': {
      color: Style.vars.deprecatedColors.primary,
    },
  },
  subContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 50,
    '@media (max-width: 800px)': {
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
  },
  actionButtons: {
    flex: '0 0 auto',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    display: 'flex',
    paddingRight: '6px',
  },
  actionButtonsRows: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0 10px',
    width: '100%',
  },

  mobileOnly: {
    '@media (min-width: 800px)': {
      display: 'none',
    },
  },
  nonMobile: {
    '@media (max-width: 800px)': {
      display: 'none',
    },
  },
  dropdown: {
    container: {
      flex: '0 2 auto',
      padding: 8,
      maxWidth: 180,
      '@media (max-width: 1000px)': {
        maxWidth: 150,
      },
    },
    menu: {
      flex: 1,
      right: 0,
      left: 'inherit',
    },
    button: {},
    icon: {
      width: 24,
      fontSize: 16,
      color: Style.vars.deprecatedColors.grey,
    },
    text: {
      '@media (max-width: 800px)': {
        display: 'none',
      },
    },
  },
  textInputContainer: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 'auto',
    minWidth: 100,
    borderLeftWidth: 1,
    borderColor: Style.vars.deprecatedColors.lightGrey,
  },
  textInput: {
    borderWidth: 0,
    paddingLeft: 16,
    boxSizing: 'border-box',
    '@media (max-width: 800px)': {
      flexGrow: 1,
      flexBasis: 180,
      borderLeftWidth: 0,
    },
  },
  searchInput: {
    container: {
      flex: '0 1 180px',
      minWidth: '100px',
      borderLeft: `solid 1px ${Style.vars.deprecatedColors.lightGrey}`,
      paddingLeft: '16px',
      boxSizing: 'border-box',
      '@media (max-width: 800px)': {
        flex: '1 1 180px',
        borderLeft: 'none',
      },
    },
    icon: {
      color: Style.vars.deprecatedColors.grey,
    },
  },
}
