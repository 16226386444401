import React, { useState } from 'react'
import { t } from 'shared-js/i18n'
import { useQuery } from '@apollo/react-hooks'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import gql from 'graphql-tag'
import { Button, TextButton } from 'ui-kit'
import { LoadingSpinner } from 'shared-js/components/common/loading'
import IndustryList from './industries-list'
import { Container, Title, Info, ButtonContainer, CancelButtonContainer } from './common'
import { GetCurrentLevelOneIndustries_Tag__List_edges as ActiveIndustryEdge } from './gql-types/GetCurrentLevelOneIndustries'
import { GetLevelOneIndustries_Tag__List_edges as IndustryEdge } from './gql-types/GetLevelOneIndustries'

const MAX_FETCH = 100

type IndustriesStepProps = {
  setFirstLevelIndustries(val: string[]): void
  resetForm(): void
}

type IndustriesInnerProps = {
  activeIndustries: string[]
  levelOneIndustries: (IndustryEdge | null)[]
  setFirstLevelIndustries(val: string[]): void
  resetForm(): void
}

const GET_CURRENT_LEVEL_ONE_INDUSTRIES = gql`
  query GetCurrentLevelOneIndustries($companyID: ID!, $loadingSize: Int!) {
    Tag__List(
      first: $loadingSize
      orderBy: ["id"]
      company: $companyID
      category: "general"
      firstLevelTags: true
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`

const GET_LEVEL_ONE_INDUSTRIES = gql`
  query GetLevelOneIndustries($loadingSize: Int!) {
    Tag__List(firstLevelTags: true, first: $loadingSize, orderBy: ["name"]) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const FirstLevelIndustries = (props: IndustriesStepProps) => {
  const levelOneIndustries = useQuery(GET_LEVEL_ONE_INDUSTRIES, {
    variables: {
      loadingSize: MAX_FETCH,
    },
  })

  const currentUser = useCurrentUser()
  const currentLevelOneIndustries = useQuery(GET_CURRENT_LEVEL_ONE_INDUSTRIES, {
    variables: {
      loadingSize: MAX_FETCH,
      companyID: currentUser.company.id,
    },
  })

  const hasFetchedData =
    !levelOneIndustries.loading &&
    levelOneIndustries.data?.Tag__List &&
    !currentLevelOneIndustries.loading &&
    currentLevelOneIndustries.data?.Tag__List

  const activeIndustries =
    hasFetchedData &&
    currentLevelOneIndustries.data.Tag__List.edges
      .filter((industry: ActiveIndustryEdge) => industry?.node?.id)
      .map((industry: ActiveIndustryEdge) => industry!.node!.id)

  return (
    <>
      <Container>
        <Title>{t('lets_start_by_confirming_your_industries')}</Title>
        <Info>{t('your_industry_is_needed_to_access_the_network_info')}</Info>
      </Container>
      {hasFetchedData ? (
        <FirstLevelIndustriesInner
          activeIndustries={activeIndustries}
          levelOneIndustries={levelOneIndustries.data.Tag__List.edges}
          {...props}
        />
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

const FirstLevelIndustriesInner = ({
  activeIndustries,
  levelOneIndustries,
  setFirstLevelIndustries,
  resetForm,
}: IndustriesInnerProps) => {
  const [activeIndustriesIDs, setActiveIndustriesIDs] = useState<string[]>(activeIndustries)

  const toggleIndustry = (industryID: string, isActive: boolean) => {
    setActiveIndustriesIDs((prevIDs) =>
      isActive ? prevIDs.filter((id) => id !== industryID) : [...prevIDs, industryID]
    )
  }

  const setIndustries = () => setFirstLevelIndustries(activeIndustriesIDs)

  return (
    <>
      <Container>
        <IndustryList
          industries={levelOneIndustries}
          activeIndustriesIDs={activeIndustriesIDs}
          toggleIndustry={toggleIndustry}
        />
      </Container>
      <ButtonContainer>
        <CancelButtonContainer>
          <TextButton onClick={resetForm}>{t('cancel')}</TextButton>
        </CancelButtonContainer>
        <Button onClick={setIndustries} disabled={!activeIndustriesIDs.length}>
          {t('next')}
        </Button>
      </ButtonContainer>
    </>
  )
}

export default FirstLevelIndustries
