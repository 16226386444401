import { PrimaryButton } from 'components/common/buttons'
import { LoadingSpinner } from 'components/common/loading'
import { Modal } from 'components/common/modals'
import { StaticModal } from 'components/common/modals/static'
import { PlainTabs } from 'components/common/router-tabs'
import { remoteSearchMixinFactory } from 'components/common/search'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'
import ModuleAttemptsState from 'state/module-attempts'
import AreasState from 'state/areas'
import EnrollmentGroupsState from 'state/enrollment-groups'
import TeamsState from 'state/teams'
import UsersState from 'state/users'
import PageState from './state'
import { USER_TAB, TEAM_TAB, GROUP_TAB, AREA_TAB, getDisplayNameFunction } from './constants'
import { UserSelector } from './selectors/user'
import { TeamSelector } from './selectors/team'
import { GroupSelector } from './selectors/group'
import { AreaSelector } from './selectors/area'

const TAB_DETAILS = {
  [USER_TAB]: {
    fieldName: 'user_ids',
    Selector: UserSelector,
    state: UsersState,
  },
  [TEAM_TAB]: {
    fieldName: 'team_ids',
    Selector: TeamSelector,
    state: TeamsState,
  },
  [GROUP_TAB]: {
    fieldName: 'group_ids',
    Selector: GroupSelector,
    state: EnrollmentGroupsState,
  },
  [AREA_TAB]: {
    fieldName: 'area_ids',
    Selector: AreaSelector,
    state: AreasState,
  },
}

@reactMixin.decorate(remoteSearchMixinFactory(PageState.ActionCreators.setSearch))
class Search extends React.Component {
  render() {
    return this.getSearchInput({ borderless: true })
  }
}

class CompleteForModalInner extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  static emptySelectionState = {
    newSelected: {},
    newDeselected: {},
  }

  state = {
    currentTab: USER_TAB,
    value: CompleteForModalInner.emptySelectionState,
    saving: false,
  }

  onChange = (newValue, user) => {
    if (this.isSelected(user)) {
      // Choosing to disable being able to remove attempts
      // as deleting data could make people sad
      this.markIncompleteDisabledModal.show()
      return
    }
    this.setState({
      ...this.state,
      value: newValue,
    })
  }

  isSelected = (obj) => obj.has_completed_lesson

  save = () => {
    this.setState({ ...this.state, saving: true })
    const fieldName = TAB_DETAILS[this.state.currentTab].fieldName
    ModuleAttemptsState.ActionCreators.doListAction('mark_complete_for_user', {
      [fieldName]: Object.keys(this.state.value.newSelected),
      lesson_id: this.props.lesson.id,
    })
      .then((res) => {
        this.context.displayTempPositiveMessage({
          heading: t('lessons_marked_complete'),
        })
      })
      .catch((res) => {
        this.context.displayTempNegativeMessage({
          heading: t('request_failed'),
        })
      })
      .finally((res) => {
        this.setState({
          ...this.state,
          saving: false,
          value: CompleteForModalInner.emptySelectionState,
        })

        PageState.Store.resetState()
        TAB_DETAILS[this.state.currentTab].state.ActionCreators.resetLocalData()

        this.props.hide()
      })
  }

  changeTab = (tab) => {
    if (this.state.currentTab !== tab) {
      this.setState({
        ...this.state,
        currentTab: tab,
        value: CompleteForModalInner.emptySelectionState,
      })
    }
  }

  render() {
    const { Selector } = TAB_DETAILS[this.state.currentTab]
    return (
      <React.Fragment>
        <div style={styles.tabContainer}>
          <PlainTabs
            tabs={[USER_TAB, TEAM_TAB, GROUP_TAB, AREA_TAB]}
            current={this.state.currentTab}
            onClick={this.changeTab}
            style={{ marginBottom: 0 }}
          />
          <Search />
        </div>
        <Selector
          lesson={this.props.lesson}
          onChange={this.onChange}
          value={this.state.value}
          isSelected={this.isSelected}
        />

        <br />
        {this.state.saving ? (
          <LoadingSpinner />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <PrimaryButton
              onClick={() => this.confirmSaveModal.show()}
              disabled={this.state.saving}
              style={{ display: 'inline-block' }}
            >
              {t('save')}
            </PrimaryButton>
          </div>
        )}

        <Modal
          ref={(c) => (this.markIncompleteDisabledModal = c)}
          header={t('unable_to_mark_incomplete_info')}
          basic
          noConfirm
        />
        <Modal
          ref={(c) => (this.confirmSaveModal = c)}
          header={(
            <div>
              {t('mark_complete_confirm')}
              <ul>
                {Object.keys(this.state.value.newSelected).map((id) => {
                  const obj = this.state.value.newSelected[id]
                  return <li key={obj.id}>{getDisplayNameFunction(this.state.currentTab)(obj)}</li>
                })}
              </ul>
            </div>
          )}
          basic
          onConfirm={this.save}
        />
      </React.Fragment>
    )
  }
}

export class CompleteForModal extends React.Component {
  // NOTE: This extra component wraps the PaginatedStateContainer
  // to stop it fetching when each lesson card is mounted,
  // it should wait until the modal is shown.
  state = {
    show: false,
  }

  show = () => {
    this.setState({ ...this.state, show: true })
  }

  hide = () => {
    this.setState({ ...this.state, show: false })
  }

  render() {
    if (!this.state.show) {
      return null
    }

    return (
      <StaticModal header={t('mark_complete_for_users')} onClose={this.hide}>
        <CompleteForModalInner {...this.props} hide={this.hide} />
      </StaticModal>
    )
  }
}

const styles = {
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}
