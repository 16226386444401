import Marty from 'marty'
import React from 'react'
import AppState from './state'

export class TitleControllerInner extends React.Component {
  componentDidMount() {
    this.setTitle(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setTitle(nextProps)
  }

  setTitle(props) {
    document.title = props.title
  }

  render() {
    return null
  }
}

export const TitleController = Marty.createContainer(TitleControllerInner, {
  listenTo: [AppState.Store],

  getTitle() {
    return AppState.Store.getTitle()
  },

  done(results) {
    return <TitleControllerInner {...this.props} title={this.getTitle()} />
  },

  pending() {
    return <TitleControllerInner {...this.props} title={this.getTitle()} />
  },

  failed(errors) {
    return this.pending()
  },
})
