import {
  ButtonToggle,
  FieldHeader,
  Form,
  DeprecatedImageCropper,
  SubmitButton,
  TextArea,
  TextInput,
} from 'components/common/form-deprecated'
import { MultiSelect } from 'components/common/form-deprecated/select'
import { Info } from 'components/common/info'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import TrainingPlanTrainingUnitsState from 'state/training-plan-training-units'
import TrainingPlansState from 'state/training-plans'
import $y from 'utilities/yaler.js'

const PUBLISH_YES = 'Published'
const PUBLISH_NO = 'Unpublished'

export class TrainingPlanDetailsForm extends React.Component {
  static data = {
    trainingPlan: {
      required: false,
      fields: ['name', 'is_published', 'description', 'training_units', 'custom_thumbnail'],
    },
    channels: {
      required: true,
      many: true,
      fields: ['name', 'url', 'id'],
    },
  }

  static propTypes = $y.propTypesFromData(TrainingPlanDetailsForm, {
    onSubmit: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
  })

  constructor(props) {
    super()
    let trainingUnits = props.trainingPlan ? props.trainingPlan.training_units : []
    let trainingUnit
    if (trainingUnits && trainingUnits.length) {
      trainingUnit = trainingUnits[0].url
      trainingUnits = trainingUnits.map((channel) => ({
        label: channel.name,
        value: channel.url,
      }))
    }
    if (props.initialChannels) trainingUnits = props.initialChannels
    this.state = {
      loading: false,
      selectedChannels: trainingUnits && trainingUnits.length ? trainingUnits : [],
    }
  }

  cleanIsPublished = (val) => val === PUBLISH_YES

  addPlanToSelectedChannels = (id) => {
    const selectedChannels = this.state.selectedChannels
    if (id && selectedChannels && selectedChannels.length) {
      TrainingPlansState.ActionCreators.update(id, {
        training_units: selectedChannels,
        training_unit: selectedChannels[0],
      })
    }
  }

  onFormSubmit = (data) => {
    const { currentUser, trainingPlan } = this.props
    data.owner = currentUser.company.url
    this.setState({ selectedChannels: data.training_units })
    data.custom_thumbnail = this.image.getValue()
    this.setState({ loading: true })

    // Get added and removed training units
    const submittedTrainingUnits = data.training_units
    delete data.training_units
    const planTrainingUnits = trainingPlan ? trainingPlan.training_units.map((tp) => tp.url) : []
    const addedTrainingUnits = _.filter(
      submittedTrainingUnits,
      (tp) => !_.includes(planTrainingUnits, tp)
    )
    const removedTrainingUnits = _.filter(
      planTrainingUnits,
      (tp) => !_.includes(submittedTrainingUnits, tp)
    )

    function syncTrainingUnits(plan) {
      addedTrainingUnits.forEach((tuURL) => {
        TrainingPlanTrainingUnitsState.ActionCreators.create({
          training_plan: plan.url,
          training_unit: tuURL,
          order: 0,
        })
      })

      removedTrainingUnits.forEach((tuURL) => {
        TrainingPlanTrainingUnitsState.ActionCreators.doListAction(
          'delete_for_plan_and_training_unit',
          {
            training_plan: plan.url,
            training_unit: tuURL,
          }
        )
      })
    }

    if (trainingPlan) {
      TrainingPlansState.ActionCreators.update(trainingPlan.id, data, {
        clearMatchesFilters: false,
      }).then((res) => {
        // must be done after update
        syncTrainingUnits(Im.freeze(res.body))
        this.props.onSubmit(res)
      })
    } else {
      TrainingPlansState.ActionCreators.create(data).then((res) => {
        // must be done after create
        syncTrainingUnits(Im.freeze(res.body))
        this.props.onSubmit(res)
      })
    }
  }

  onChannelChange = (channel) => {
    this.setState({
      selectedChannel: channel,
    })
    _.defer(() => {
      this.props.refresh()
    })
  }

  render() {
    let isPublished = PUBLISH_NO
    let name,
      description,
      channels,
      customThumbnail,
      searchSelectable

    if (this.props.trainingPlan) {
      const plan = this.props.trainingPlan
      name = plan.name
      description = plan.description
      // TODO: Remove this
      channels = plan.training_units
      if (channels && channels.length) channel = channels[0]
      isPublished = plan.is_published ? PUBLISH_YES : PUBLISH_NO
      customThumbnail = plan.custom_thumbnail
    }

    if (this.props.channels) {
      const channelOpts = this.props.channels.map((c) => ({
        label: c.name,
        value: c.url,
      }))

      searchSelectable = (
        <div>
          <FieldHeader>
            {t('channels')}
            <Info
              content="This plan will be available to all subscribers of the selected channel.
                If you do not select a channel, the plan cannot be shared
                with users from other companies."
            />
          </FieldHeader>
          <MultiSelect
            ref="trainingUnitSelect"
            initialSelection={this.state.selectedChannels}
            name="training_units"
            options={channelOpts}
            placeholder="Select channels..."
            noResultsText="No channels found."
          />
        </div>
      )
    }

    return (
      <Form onSubmitAndValid={this.onFormSubmit} ref="form">
        <FieldHeader required>Name your plan</FieldHeader>
        <TextInput name="name" ref="name" initialValue={name} initialIsAcceptable required />
        <FieldHeader>Describe your plan</FieldHeader>
        <TextArea
          name="description"
          ref="description"
          initialValue={description}
          initialIsAcceptable
        />
        {searchSelectable}
        <FieldHeader
          explanation="If the plan is not published then only company admins will be able to view it"
          required
          style={{ marginTop: 20 }}
        >
          Publish
        </FieldHeader>
        <ButtonToggle
          name="is_published"
          leftLabel={PUBLISH_YES}
          rightLabel={PUBLISH_NO}
          initialValue={isPublished}
          clean={this.cleanIsPublished}
          style={{
            button: { width: '8em' },
          }}
          initialIsAcceptable
          required
        />
        <FieldHeader style={{ marginTop: 20 }}>Custom thumbnail</FieldHeader>
        <DeprecatedImageCropper
          ref={(i) => (this.image = i)}
          aspectRatio={16 / 9}
          height={180}
          width={(180 * 16) / 9}
          initialValue={customThumbnail}
        />
        <SubmitButton loading={this.state.loading} />
      </Form>
    )
  }
}
