import Marty from 'marty'
import React from 'react'
import Radium from 'radium'
import _ from 'lodash'
import Style from 'style'
import { t, getCurrentLocale } from 'i18n'
import PropTypes from 'prop-types'
import Im from 'shared-js/immutable'
import containerUtils from 'utilities/containers'
import { nowInISO } from 'utilities/time'

import JoinCompanyRequestsState from 'state/join-company-requests'
import UsersState from 'state/users'

import { Modal } from 'components/common/modals'
import { LoadingContainer } from 'components/common/loading'
import { BasicButton } from 'components/common/buttons'

const styles = {
  modalContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentText: {
    marginBottom: 15,
  },
  companyItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '60%',
    margin: '10px 0',
  },
  button: {
    textAlign: 'center',
    color: Style.vars.deprecatedColors.white,
    padding: '5px 10px',
  },
  acceptButton: {
    backgroundColor: Style.vars.deprecatedColors.primary,
  },
  denyButton: {
    backgroundColor: Style.vars.deprecatedColors.grey,
  },
}

class AcceptButton extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  acceptRequest = () => {
    JoinCompanyRequestsState.ActionCreators.doDetailAction(this.props.request.id, 'accept')
      .then(() => {
        JoinCompanyRequestsState.Store.resetState()
        UsersState.ActionCreators.clearRecentFetches()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <BasicButton
        style={{ ...styles.button, ...styles.acceptButton }}
        onClick={this.acceptRequest}
      >
        {t('accept')}
      </BasicButton>
    )
  }
}

class DenyButton extends React.Component {
  denyRequest = () => {
    JoinCompanyRequestsState.ActionCreators.doDetailAction(this.props.request.id, 'deny')
      .then(() => {
        JoinCompanyRequestsState.Store.resetState()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <BasicButton style={{ ...styles.button, ...styles.denyButton }} onClick={this.denyRequest}>
        {t('deny')}
      </BasicButton>
    )
  }
}

class RequestItem extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  renderTeamName = () => (
    <span>
      <span>: </span>
      <span style={{ fontStyle: 'italic' }}>{this.props.request.team.name}</span>
    </span>
  )

  render() {
    const isAdmin = this.context.currentUser.learner.is_company_admin
    const requester = this.props.request.requester
    return (
      <div style={styles.companyItem}>
        <div>
          {requester.first_name}
          {' '}
          {requester.last_name}
          {isAdmin && this.renderTeamName()}
        </div>
        <div>
          <AcceptButton request={this.props.request} />
          <DenyButton request={this.props.request} />
        </div>
      </div>
    )
  }
}

@Radium
class RequestsReceivedModalInner extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  renderRequestItems = (requests) => requests.map((request, i) => <RequestItem key={i} request={request} />)

  render() {
    const requestCount = this.props.requests && _.size(this.props.requests)
    const requestInfo = this.context.currentUser.learner.is_company_admin
      ? 'requests_received_info_admin'
      : 'requests_received_info'
    return (
      <Modal
        ref={(x) => (this.requestedReceivedModal = x)}
        header={t('requests_received')}
        showOnInit={this.props.showOnInit}
      >
        <LoadingContainer
          loadingProps={[this.props.requests]}
          createComponent={() => (
            <div style={styles.modalContent}>
              <div style={styles.contentText}>
                {t(requestInfo, { requestCount }, { pluralize: requestCount })}
              </div>
              {this.renderRequestItems(this.props.requests)}
            </div>
          )}
          noDataText={t('no_requests_left')}
        />
      </Modal>
    )
  }
}

export const RequestsReceivedModal = Marty.createContainer(RequestsReceivedModalInner, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [JoinCompanyRequestsState.Store, UsersState.Store],
  fetch: {
    requests() {
      const learner = this.context.currentUser.learner
      const query = {
        closed_date__isnull: true,
        outcome__isnull: true,
        requester__company__isnull: true,
        company: this.context.currentUser.company.id,
        fields: [
          'requester',
          'requester.first_name',
          'requester.last_name',
          'company',
          'team',
          'team.name',
        ],
      }
      if (!learner.is_company_admin && learner.is_learner_group_admin && learner.learnergroups) {
        _.extend(query, {
          team__in: learner.learnergroups,
        })
      }
      return JoinCompanyRequestsState.Store.getItems(query)
    },
  },
  pending() {
    return containerUtils.defaultPending(this, RequestsReceivedModalInner, {
      isFetching: true,
    })
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, RequestsReceivedModalInner, errors)
  },
})
