import React from 'react'
import MUITextField, { TextFieldProps } from '@material-ui/core/TextField/'
import InputAdornment from '@material-ui/core/InputAdornment'
import styled from '@xstyled/styled-components'
import { colors } from 'shared-js/style/colors'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { grey } from '@material-ui/core/colors'

const LABEL_PADDING = 28
const ICON_COLOR = 'rgba(97,97,97,1)'
const FILLED_BG_COLOR = 'rgb(33, 33, 33, 0.04)'
/*
 * These styles will not work if we use theme nesting
 * https://material-ui.com/styles/advanced/#with-material-ui-core
 * If we do it won't generate classnames which we need to override.
 * Why... TextFields use Cyan700, we have wrapped the App with Indigo800
 */

const StyledMUITextField = styled(MUITextField)<TextFieldProps & StartIconInterface>`
  .MuiFilledInput-input {
    padding-left: ${(props) => (props.startIcon ? '2px' : '18px')};
  }

  .MuiFilledInput-root {
    background-color: ${FILLED_BG_COLOR};
    &.Mui-disabled {
      background-color: ${FILLED_BG_COLOR};
    }
    &:hover {
      background-color: ${fade(grey[900], 0.09)};
    }
  }
  .MuiFilledInput-underline {
    &:after {
      border-bottom: 2px solid ${colors.cyan700};
    }
    &.Mui-disabled:before {
      border-bottom-style: solid;
      border-bottom-color: #eeeeee;
    }
  }

  .MuiFormLabel-root.Mui-focused {
    // TODO: Use the MUI Theme provider
    color: ${(props) => (props.error ? colors.red700 : colors.cyan700)};
  }

  .MuiFilledInput-adornedStart {
    padding-left: 16px;
  }

  .MuiFilledInput-adornedEnd {
    padding-right: 24px;
  }
`

type StartIconInterface = {
  startIcon?: boolean
}

/**
 * Given that Material UI doesn't fully adher to the Material Design Guidelines
 * we have wrapped the textfield with our own wrapper to give us the behaviour
 * we are after. Refer below to examples on the API we use.
 *
 * -- Helper Text
 * <TextField helperText="Text here">
 *
 * -- Start and end Icon
 * <TextField startIcon={<IconHere / >} />
 * <TextField endIcon={<IconHere / >} />
 */

export type BaseTextFieldProps = Omit<TextFieldProps, 'variant'> & {
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  error?: string
  touched?: boolean
}

export const TextField = (props: BaseTextFieldProps) => {
  const {
    classes,
    endIcon,
    autoComplete,
    startIcon,
    disabled,
    touched,
    error,
    helperText,
    defaultValue,
    ...other
  } = props
  const [shrinked, setShrinked] = React.useState(!!defaultValue)
  const labelPadding: number = startIcon ? -LABEL_PADDING : 0

  const unShrinkLabel: TextFieldProps['onBlur'] = (event: any) => {
    const { onBlur } = props
    if (event.target.value.length === 0) {
      setShrinked(false)
    }
    onBlur && onBlur(event)
  }
  const shrinkLabel = (event: any) => {
    const { onFocus } = props
    setShrinked(true)
    onFocus && onFocus(event)
  }

  return (
    <StyledMUITextField
      {...other}
      defaultValue={defaultValue}
      error={Boolean(touched && error)}
      helperText={touched && error ? error : helperText}
      variant="filled"
      disabled={disabled}
      onFocus={shrinkLabel}
      style={{ marginLeft: -labelPadding, marginRight: labelPadding }}
      onBlur={unShrinkLabel}
      InputLabelProps={{ style: { paddingLeft: 8 }, shrink: shrinked, classes }}
      fullWidth
      InputProps={{
        style: { left: labelPadding },
        autoComplete,
        endAdornment: endIcon && (
          <InputAdornment style={{ width: 24, height: 24, color: ICON_COLOR }} position="end">
            {endIcon}
          </InputAdornment>
        ),
        startAdornment: startIcon && (
          <InputAdornment
            style={{ width: 24, height: 24, marginTop: 0, color: ICON_COLOR }}
            position="start"
          >
            {startIcon}
          </InputAdornment>
        ),
      }}
      FormHelperTextProps={{
        style: { marginLeft: startIcon ? -10 : 20 },
      }}
      startIcon={!!startIcon}
    />
  )
}
