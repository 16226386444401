import React, { useContext } from 'react'
import { isIE11 } from 'shared-js/utilities/browser'
import Style from 'shared-js/style'
import { t } from 'shared-js/i18n'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import { useCommonRouter } from 'shared-js/routing'
import styled from 'styled-components'
import { Modal } from 'components/common/modals'
import { defaultLogo } from 'core/style-configuration'
import Icon from 'react-native-vector-icons/MaterialIcons'
import Button from 'shared-js/components/common/buttons'
import { useSubscribeToChannel, UpdateChannelContext } from '../../../common'
import PlansList from './plans-list'
import { DiscoverableContentQuery_PublicTrainingUnit__List_edges_node as Channel_node } from '../../gql-types/DiscoverableContentQuery'

const COVER_PHOTO_HEIGHT = 152
const ADD_CONTENT_CONTAINER_HEIGHT = 115
const SEMANTIC_UI_PADDING_DESKTOP = 21
const SEMANTIC_UI_PADDING_MOBILE = '1rem'

type ChannelInfoModalProps = {
  innerRef: React.RefObject<Modal>
  channel: Channel_node
}

type PhotoProps = {
  src: string
}

type CompanyInfoContainerProps = {
  coverPhoto: any
}

const ChannelInfoModal = ({ innerRef, channel }: ChannelInfoModalProps) => {
  const { handlers } = useCommonRouter()
  const currentUser = useCurrentUser()
  const subscribeToChannel = useSubscribeToChannel()
  const updateChannel = useContext(UpdateChannelContext)

  const connectToChannel = () => {
    subscribeToChannel({
      variables: {
        company: currentUser.company.id,
        channel: channel.id,
      },
      update: (store, result) => {
        updateChannel(store, result)
      },
    })
  }

  const modalStyle = isIE11() ? { 
    marginTop: '0px !important', 
    marginLeft: 'auto',
    marginRight: 'auto' 
  } : {}

  return (
    <Modal
      ref={innerRef}
      closeOnDimmerClick
      // these are for IE 11
      contentStyle={{ position: 'relative' }}
      style={modalStyle}
    >
      <CoverPhoto src={channel.coverImageThumbnail}>
        <CompanyInfoContainer coverPhoto={channel.coverImageThumbnail}>
          <CompanyLogo src={channel.company?.companyLogo} />
          <CompanyName>{channel.name}</CompanyName>
        </CompanyInfoContainer>
      </CoverPhoto>

      <PlansListContainer>
        <PlansList channelID={channel.id} />
      </PlansListContainer>

      <AddContentContainer>
        <AddButtonContainer>
          {channel.isSubscribedToByCurrentCompany ? (
            <ContentAddedText>
              <StyledTickIcon name="check" size={20} />
              {t('content_added_titlecase')}
            </ContentAddedText>
          ) : (
            <Button color="primary" onPress={() => connectToChannel()}>
              {t('add_to_my_content')}
            </Button>
          )}
        </AddButtonContainer>
        {channel.isSubscribedToByCurrentCompany ? (
          <ViewLibraryText onClick={() => handlers.goToContentManagementChannels()}>
            {t('view_your_library_of_content')}
          </ViewLibraryText>
        ) : (
          t('you_will_be_able_to_review_before_enrolling')
        )}
      </AddContentContainer>
    </Modal>
  )
}

const CoverPhoto = styled.div<PhotoProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${COVER_PHOTO_HEIGHT}px;
  width: 100%;
  background-image: ${({ src }) => `url("${src}")`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${Style.colors.grey700};
  display: flex;
  align-items: flex-end;
`

const CompanyInfoContainer = styled.div<CompanyInfoContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 24px;
  padding-bottom: 16px;
  height: 100%;
  width: 100%;
  background-image: ${({ coverPhoto }) => coverPhoto ? `linear-gradient(
    to bottom,
    rgba(69, 69, 69, 0) 30%,
    rgba(0, 0, 0, 0.4) 65%,
    rgba(0, 0, 0, 0.84) 100%
  )` : 'none'};
  @media (max-width: 768px) {
    padding-left: 18px;
  }
`

const CompanyLogo = styled.div<PhotoProps>`
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 4px;
  background-color: ${({ src }) => (src ? 'none' : Style.colors.grey200)};
  background-image: ${({ src }) => (src && src !== '' ? `url("${src}")` : `url("${defaultLogo}")`)};
  background-repeat: no-repeat;
  background-size: ${({ src }) => (src ? `cover` : '40px 40px')};
  background-position: center;
  border-radius: 4px;
  margin-right: 8px;
  @media (max-width: 768px) {
    width: 70px;
    min-width: 70px;
    height: 70px;
  }
`

const CompanyName = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: ${Style.colors.white};
  margin-left: 24px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 22px;
    margin-left: 8px;
  }
`

const PlansListContainer = styled.div`
  margin-top: ${COVER_PHOTO_HEIGHT - SEMANTIC_UI_PADDING_DESKTOP}px;
  margin-bottom: ${ADD_CONTENT_CONTAINER_HEIGHT - SEMANTIC_UI_PADDING_DESKTOP}px;

  @media (max-width: 768px) {
    margin-top: calc(${COVER_PHOTO_HEIGHT}px - ${SEMANTIC_UI_PADDING_MOBILE});
    margin-bottom: calc(${ADD_CONTENT_CONTAINER_HEIGHT}px - ${SEMANTIC_UI_PADDING_MOBILE});
  }
`

const AddButtonContainer = styled.div`
  margin: 0 auto 14px auto;
`

const AddContentContainer = styled.div`
  box-shadow: 0 0 4px 0 rgba(117, 117, 117, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${ADD_CONTENT_CONTAINER_HEIGHT}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
  background-color: ${Style.colors.white};
`

const StyledTickIcon = styled(Icon)`
  margin-right: 6px;
`

const ContentAddedText = styled.div`
  display: flex;
  color: ${Style.colors.green900};
  font-weight: 600;
  margin-bottom: 8px;
`

const ViewLibraryText = styled.div`
  font-weight: 600;
  cursor: pointer;
`

export default ChannelInfoModal
