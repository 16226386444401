import { t } from 'i18n'
import React from 'react'
import { PrimaryButton } from 'components/common/buttons'
import { MetabaseDashboardModal } from 'components/common/analytics'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  btn: {
    width: '100%',
  },
}

export class Analytics extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        <PrimaryButton onClick={() => this.modal.show()} style={styles.btn}>
          {t('view_analytics')}
        </PrimaryButton>
        <MetabaseDashboardModal
          ref={(e) => (this.modal = e)}
          dashboardURL={this.props.plan.analytics_dashboard_url}
          noResultsText={t('no_plan_analytics_data')}
        />
      </div>
    )
  }
}
