import { slugify } from 'utilities/generic'
import { map } from 'lodash'

export const convertDataToReadableFormat = (entities, fromNode = true) => {
  if (!entities) return []

  return map(entities, (edge) => {
    const entity = fromNode ? edge.node : edge
    return entity ? { id: entity.id, label: entity.name, value: slugify(entity.name) } : null
  })
}

export const getIds = (array) => map(array, 'id')
