import { LessonCard as LessonCardCommon } from 'components/common/lesson-card'
import { Modal } from 'components/common/modals'
import { SortableElement } from 'components/common/ordering'
import { MetabaseDashboardModal } from 'components/common/analytics'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import Im from 'shared-js/immutable'
import ModulesState from 'state/modules'
import { resolve } from 'utilities/deprecated-named-routes'
import $y from 'utilities/yaler'
import { CompleteForModal } from './complete-for-user-modal'

@Radium
export class LessonCard extends React.Component {
  /*
    A decent amount of this component has been copied from the training page component,
    but it differs enough to warrant a new component. TODO: get rid of the admin logic
    in the equivalent training page component.
  */

  static contextTypes = {
    router: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    featureFlags: PropTypes.objectOf(PropTypes.bool),
  }

  static data = {
    lesson: {
      required: true,
      fields: [
        'name',
        'id',
        'thumbnail_url',
        'description',
        'url',
        'deactivated',
        'is_attemptable',
        'analytics_dashboard_url',
        'successfully_completed_by_current_user',
        'personal_best_score_for_user',
        'training_plans.id',
        'training_plans.name',
        'training_plans.owner',
        'company.id',
      ],
    },
    trainingPlan: {
      fields: ['owner'],
    },
  }

  static propTypes = $y.propTypesFromData(LessonCard, {
    onTransition: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
  })

  getPlan = () => {
    let plan = this.props.plan
    if (!plan) plan = Im.freeze(this.props.lesson.training_plans[0])
    return plan
  }

  attemptLesson = () => {
    this.props.onTransition()
    const params = this.props.goToChannelOnCompletion
      ? `?goToChannelOnCompletion=${this.props.goToChannelOnCompletion}`
      : ''
    this.context.router.push(
      resolve('new-module-attempt', {
        moduleId: this.props.lesson.id,
        trainingPlanId: this.getPlan().id,
      }) + params
    )
  }

  showArchiveModal = () => {
    this.refs.archiveLessonModal.show()
  }

  showRemoveModal = () => {
    this.refs.removeLessonModal.show()
  }

  showRestoreLessonModal = () => {
    this.refs.restoreLessonModal.show()
  }

  restoreLessonConfirmClick = () => {
    ModulesState.ActionCreators.update(this.props.lesson.id, {
      deactivated: null,
    })
    this.refs.restoreLessonModal.hide()
    this.context.displayTempPositiveMessage({
      heading: 'changes_saved',
    })
    this.props.showArchive()
  }

  showAnalytics = () => {
    this.analyticsModal.show()
  }

  getDropdownItems = (userCompanyOwnsLesson) => {
    const { learner, company } = this.props.currentUser
    const plan = this.getPlan()
    const items = []
    const deactivated = Boolean(this.props.lesson.deactivated)
    if (userCompanyOwnsLesson && deactivated) {
      items.push({
        label: 'Restore from archive',
        action: this.showRestoreLessonModal,
      })
      return items
    }
    items.push({
      label: t('attempt'),
      action: this.attemptLesson,
    })
    if (learner.is_company_admin || learner.is_learner_group_admin || learner.is_area_manager) {
      items.push({
        label: t('view_analytics'),
        action: this.showAnalytics,
      })
      if (this.context.featureFlags['mark-lesson-complete']) {
        items.push({
          label: t('mark_complete_for_users'),
          action: () => this.completeForUserModal.show(),
        })
      }
    }
    if (userCompanyOwnsLesson && learner.can_manage_training_content && this.props.editable) {
      items.push({
        label: t('archive'),
        action: this.showArchiveModal,
      })
    }
    if (
      this.props.lesson &&
      this.props.plan &&
      learner.can_manage_training_content &&
      company.url === plan.owner.url &&
      this.props.editable &&
      this.props.lesson.training_plans &&
      this.props.lesson.training_plans.length >= 1
    ) {
      items.push({
        label: t('remove_from_this_plan'),
        action: this.showRemoveModal,
      })
    }
    return items
  }

  editLesson = () => {
    this.context.router.push(
      resolve('lesson-management', {
        lessonId: this.props.lesson.id,
      })
    )
  }

  archiveConfirmClick = () => {
    this.refs.archiveLessonModal.hide()
    this.props.archiveConfirmClick(this.props.lesson)
  }

  removeConfirmClick = () => {
    this.refs.removeLessonModal.hide()
    this.props.removeConfirmClick(this.props.lesson)
  }

  render() {
    if (!this.getPlan().owner) {
      // The only training plan has been deactivated
      return null
    }
    const userCanManageTrainingContent = this.props.currentUser.learner.can_manage_training_content
    const { company } = this.props.lesson
    const userCompanyOwnsLesson = company
      ? this.props.currentUser.company.id === this.props.lesson.company.id
      : false
    return (
      <div>
        <LessonCardCommon
          module={this.props.lesson}
          hideCompletionPercentage
          clickable={
            userCanManageTrainingContent && userCompanyOwnsLesson && !this.props.lesson.deactivated
          }
          icon="edit outline"
          onClick={this.editLesson}
          dropdownItems={this.getDropdownItems(userCompanyOwnsLesson)}
          highlight={this.props.highlight}
        />
        <Modal
          ref="restoreLessonModal"
          onConfirm={this.restoreLessonConfirmClick}
          header={t('want_to_restore_lesson_from_archive')}
          basic
        />
        <Modal
          ref="archiveLessonModal"
          onConfirm={this.archiveConfirmClick}
          header={t('are_you_sure_archive_lesson')}
          basic
        />

        <Modal
          ref="removeLessonModal"
          onConfirm={this.removeConfirmClick}
          header={`Are you sure you want to remove the "${
            this.props.lesson ? this.props.lesson.name : ''
          }" lesson from this plan?`}
          basic
        />

        <CompleteForModal ref={(c) => (this.completeForUserModal = c)} lesson={this.props.lesson} />

        <MetabaseDashboardModal
          ref={(e) => (this.analyticsModal = e)}
          dashboardURL={this.props.lesson.analytics_dashboard_url}
          noResultsText={t('no_lesson_analytics_data')}
        />
      </div>
    )
  }
}

export const SortableLessonCard = SortableElement(LessonCard)
SortableLessonCard.data = LessonCard.data
