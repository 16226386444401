import React from 'react'
import styled from 'styled-components'
import { RelevantIndustryTagsQuery_Tag__List_edges as Tag_edges } from './gql-types/RelevantIndustryTagsQuery'
import { StyledCheckboxIcon } from '../common'
import { useIndustryFilter } from '../../context'

const ITEM_LINE_HEIGHT = 20
const ITEM_PADDING_BOTTOM = 15
export const ITEM_HEIGHT = ITEM_LINE_HEIGHT + ITEM_PADDING_BOTTOM

const IndustryItem = ({ style, tag }: { style: React.CSSProperties; tag: Tag_edges }) => {
  const { industriesFiltered, addOrRemoveIndustryFilter } = useIndustryFilter()
  const filterIsActive = tag.node?.id && industriesFiltered.includes(tag.node?.id)
  return (
    <Container
      style={style}
      onClick={() => {
        if (tag.node) addOrRemoveIndustryFilter(tag.node)
      }}
    >
      <StyledCheckboxIcon
        name={filterIsActive ? 'check-box' : 'check-box-outline-blank'}
        size={20}
        isActive={filterIsActive}
      />
      {tag?.node?.name}
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: ${ITEM_PADDING_BOTTOM}px;
  line-height: ${ITEM_LINE_HEIGHT}px;
  display: flex;
  cursor: pointer;
  color: #3a454e;
`

export default IndustryItem
