import React, { useContext, FunctionComponent } from 'react'
import t from 'shared-js/i18n'
import { RouterBackButton } from 'components/common/box'
import { ManagedHistoryContext } from 'shared-js/context/context'
import { ManagedHistoryContextType, TrackedTopicsType } from 'shared-js/context/types'

type ManagedHistoryReducer = (
  locationState: TrackedTopicsType
) => { name: string; route: string | null }

export const editPlanParentLocationReducer: ManagedHistoryReducer = (locationState) => {
  const defaultResult = {
    name: t('plans'),
    route: '/views/content/plans/',
  }
  const { editPlanParentLocation } = locationState
  if (!editPlanParentLocation) {
    return defaultResult
  }
  const type = editPlanParentLocation.type
  const params = editPlanParentLocation.parameters
  if (!params.length) {
    return defaultResult
  }
  switch (type) {
    case 'channel':
      return { name: t('channel'), route: `/views/edit-content/channel/${params[0]}/plans` }
    case 'plans_search_results':
      return {
        name: t('search_results'),
        route: `/views/search/plans/${params[0]}`,
      }
    default:
      return defaultResult
  }
}

export const editLessonParentLocationReducer: ManagedHistoryReducer = (locationState) => {
  const defaultResult = {
    name: t('back'),
    route: null,
  }
  const { editLessonParentLocation } = locationState
  if (!editLessonParentLocation) {
    return defaultResult
  }
  const type = editLessonParentLocation.type
  const params = editLessonParentLocation.parameters
  if (!params.length) {
    return defaultResult
  }
  switch (type) {
    case 'plan':
      return { name: t('plan'), route: `/views/content/plans/${params[0]}/` }
    default:
      return {
        name: t('back'),
        route: null,
      }
  }
}

const ContextAwareBackButton: FunctionComponent<{ reducer: ManagedHistoryReducer }> = ({
  reducer,
}: {
  reducer: ManagedHistoryReducer
}) => {
  const { trackedTopics } = useContext<ManagedHistoryContextType>(ManagedHistoryContext)

  const { name, route } = reducer(trackedTopics)

  return <RouterBackButton text={name} route={route} />
}

export default ContextAwareBackButton
