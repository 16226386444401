import React, { useRef } from 'react'
import { useHover } from 'shared-js/utilities/hooks'
import { t } from 'shared-js/i18n'
import Style from 'shared-js/style'
import styled from 'styled-components'
import { defaultLogo } from 'core/style-configuration'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { Placeholder } from 'shared-js/components/common/placeholder'
import { Modal } from 'components/common/modals'
import Button from 'shared-js/components/common/buttons'
import ChannelInfoModal from './channel-info-modal'
import { DiscoverableContentQuery_PublicTrainingUnit__List_edges_node as Channel_node } from '../gql-types/DiscoverableContentQuery'

export const CHANNEL_TITLE_HEIGHT = 40
export const BOTTOM_MARGIN = 15
export const LEFT_MARGIN = 20
const CARD_BADGE_SPACING = 10
const NO_COVER_PHOTO = ''
const COMPANY_LOGO_DIMENSIONS = 30

type ContainerProps = {
  width: number
  height: number
}

type ChannelCardProps = {
  channel: Channel_node
} & ContainerProps

type ChannelCardImageBaseProps = {
  width: number
}

type ChannelCardImageProps = {
  src: string
}

type ButtonProps = {
  hasAddedChannel: boolean
}

type CompanyInfoProps = {
  companyName: string
  companyLogoSrc: string
}

type CompanyLogoProps = {
  src: string
}

type OverlayProps = {
  isHovered: boolean
}

type LoadingChannelCardProps = {
  width: number
  height: number
  style: React.CSSProperties
}

export const ChannelCard = ({ channel, width, height }: ChannelCardProps) => {
  const [isHovered, bindHover] = useHover()
  const channelInfoModal = useRef<Modal>(null)

  const showChannelModal = () => {
    bindHover.onMouseLeave()
    if (channelInfoModal.current) channelInfoModal.current.show()
  }

  return (
    <ChannelCardContainer width={width} height={height}>
      <div onClick={showChannelModal} {...bindHover}>
        <CompanyInfo
          companyName={channel.company?.name || ''}
          companyLogoSrc={channel.company?.companyLogo}
        />
        <ChannelCardImage width={width} src={channel.coverImageThumbnail}>
          {channel.isSubscribedToByCurrentCompany && (
            <AddedIconContainer>
              <StyledTickIcon />
            </AddedIconContainer>
          )}
          {isHovered && (
            <ButtonContainer>
              <Button>
                <ButtonText>{t('view')}</ButtonText>
              </Button>
            </ButtonContainer>
          )}

          {channel.coverImageThumbnail === NO_COVER_PHOTO && (
            <CoverPhotoPlaceholderContainer>
              <StyledSchoolIcon />
            </CoverPhotoPlaceholderContainer>
          )}
          <Overlay isHovered={isHovered as boolean} />
        </ChannelCardImage>
        <ChannelName onClick={showChannelModal}>{channel.name}</ChannelName>
        <ChannelInfoModal innerRef={channelInfoModal} channel={channel} />
      </div>
    </ChannelCardContainer>
  )
}

const CompanyInfo = ({ companyName, companyLogoSrc }: CompanyInfoProps) => (
  <CompanyInfoContainer>
    <CompanyLogo src={companyLogoSrc} />
    <CompanyName>{companyName}</CompanyName>
  </CompanyInfoContainer>
)

export const LoadingChannelCard = ({ width, height, style }: LoadingChannelCardProps) => (
  <ChannelCardContainer height={height} width={width} style={style}>
    <LoadingChannelCardImage width={width}>
      <CompanyInfoContainer>
        <LoadingCompanyLogoContainer>
          <Placeholder.Box
            height={COMPANY_LOGO_DIMENSIONS}
            width={COMPANY_LOGO_DIMENSIONS}
            radius={4}
            animate="fade"
            color={Style.colors.grey400}
          />
        </LoadingCompanyLogoContainer>
        <Placeholder.Box
          height={16}
          width={45}
          radius={4}
          animate="fade"
          color={Style.colors.grey400}
        />
      </CompanyInfoContainer>
    </LoadingChannelCardImage>
  </ChannelCardContainer>
)

const ChannelCardContainer = styled.div<ContainerProps>`
  margin-left: ${LEFT_MARGIN - CARD_BADGE_SPACING}px;
  margin-bottom: ${BOTTOM_MARGIN - CARD_BADGE_SPACING}px;
  margin-right: ${CARD_BADGE_SPACING}px;
  margin-top: ${CARD_BADGE_SPACING}px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  @media (max-width: 768px) {
    margin-left: ${LEFT_MARGIN / 2}px;
    margin-right: ${LEFT_MARGIN / 2}px;
  }
`

const CoverPhotoPlaceholderContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 4px;
  background-color: ${Style.colors.indigo400};
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const LoadingCompanyLogoContainer = styled.div`
  margin-right: 8px;
`

const ChannelCardImageBase = styled.div<ChannelCardImageBaseProps>`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  border-radius: 4px;
`

const ChannelCardImage = styled(ChannelCardImageBase)<ChannelCardImageProps>`
  background-image: ${({ src }) => `url("${src}")`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${Style.colors.indigo500};
  cursor: pointer;
`

const LoadingChannelCardImage = styled(ChannelCardImageBase)<ChannelCardImageBaseProps>`
  background-color: ${Style.colors.grey200};
`

const CompanyInfoContainer = styled.div`
  position: absolute;
  padding: 8px 30px 8px 8px;
  z-index: 3;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Style.colors.grey900};
  opacity: ${({ isHovered }) => (isHovered ? 0.9 : 0.3)};
  border-radius: 4px;
`

const CompanyName = styled.div`
  color: ${Style.colors.white};
  font-weight: 600;
  font-size: 10px;
  line-height: 1.6;
`

const CompanyLogo = styled.div<CompanyLogoProps>`
  width: ${COMPANY_LOGO_DIMENSIONS}px;
  min-width: ${COMPANY_LOGO_DIMENSIONS}px;
  height: ${COMPANY_LOGO_DIMENSIONS}px;
  background-color: ${({ src }) => (src ? 'none' : Style.colors.grey200)};
  background-image: ${({ src }) => `url("${src || defaultLogo}")`};
  background-repeat: no-repeat;
  background-size: ${({ src }) => (src ? `cover` : '15px 15px')};
  background-position: center;
  border-radius: 4px;
  margin-right: 8px;
`

const ChannelName = styled.div`
  cursor: pointer;
  color: ${Style.colors.grey900};
  font-size: 14px;
  font-weight: bold;
  max-height: ${CHANNEL_TITLE_HEIGHT}px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AddedIconContainer = styled.div`
  position: absolute;
  top: -${CARD_BADGE_SPACING}px;
  right: -${CARD_BADGE_SPACING}px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Style.colors.green100};
  border: solid 2px ${Style.colors.white};
`

const StyledSchoolIcon = styled(Icon).attrs({ name: 'school', size: 22 })`
  color: ${Style.colors.white};
`

const StyledTickIcon = styled(Icon).attrs({ name: 'check', size: 16 })`
  color: ${Style.colors.green900};
`

const ButtonText = styled.div`
  text-align: center;
`

const ButtonContainer = styled.div`
  z-index: 3;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`
