import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'
import CompaniesState from 'state/companies'

const BRAND = 'brand'
const RETAILER = 'retailer'
const CONTENT_SELLER = 'content_seller'

export class Company extends React.Component {
  static data = {
    company: {
      required: true,
      fields: ['companysettings.id', 'companysettings.disable_all_notifications'],
    },
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super()
    this.state = {
      companyType: props.company.subscription.company.company_type,
    }
  }

  onChangeCompanyType = (data) => {
    const companyId = this.props.company.id
    this.setState({ ...this.state, companyType: data.value })
    CompaniesState.ActionCreators.update(companyId, {
      company_type: data.value,
    }).then(() => {
      this.context.displayTempPositiveMessage({
        heading: 'Great Success!',
        body: 'Company details saved',
      })
    })
  }

  render() {
    return (
      <React.Fragment>
        <h3>
          Company type:
          {this.state.companyType}
        </h3>
        <Select
          name="company_type"
          value={this.state.companyType}
          clearable={false}
          searchable={false}
          options={[
            { value: BRAND, label: 'Brand' },
            { value: RETAILER, label: 'Retailer' },
            { value: CONTENT_SELLER, label: 'Content seller' },
          ]}
          valueRenderer={(o) => (
            <div>
              <b style={{ color: '#000' }}>{o.label}</b>
            </div>
          )}
          onChange={this.onChangeCompanyType}
        />
      </React.Fragment>
    )
  }
}
