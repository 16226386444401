import { FieldHeader } from 'components/common/form-deprecated'
import { LoadingContainer } from 'components/common/loading'
import { PublicStyling } from 'components/common/public-styling'
import { remoteSearchMixinFactory } from 'components/common/search'
import { t } from 'i18n'
import Marty from 'marty'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import reactMixin from 'react-mixin'
import Im from 'shared-js/immutable'
import PageState from 'shared-js/state/company-signup'
import PublicCompaniesState from 'shared-js/state/public-companies'
import UsersState from 'shared-js/state/users'
import InvitationsState from 'state/invitations.js'
import Style from 'style/index'
import containerUtils from 'utilities/containers'
import { resolve } from 'utilities/deprecated-named-routes'
import { momentToISO } from 'utilities/time'
import $y from 'utilities/yaler'
import { qs } from 'utilities/http'
import { connectToSharelink, goToSharelinkChannelPage, enterMyagi, PrimaryLink } from '../../common'
import { renderWelcomeMessage } from '../common'
import { CompanyItem } from './company-item'
import { InvitesPending } from './my-invites/page'
import { JoinTeamForm } from './pick-team-form'

const CLEARBIT_LOGO_URL = 'http://logo.clearbit.com/'
const MIN_SEARCH_LENGTH = 3
const SKIP_CREATE_TEAM = 'skip create team'
const CREATE_COMPANY = 'create a company'
const CREATE_TEAM = 'create a team'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  searchInputContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputLabel: {
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    fontWeight: 'normal',
    fontSize: 16,
    marginBottom: 10,
  },
  dropdown: {
    container: {
      marginTop: 40,
    },
  },
  companyListContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  companyListTitle: {
    textAlign: 'center',
    fontSize: 18,
    margin: '20px 0 20px 0',
  },
  messageContainer: {
    margin: '16px 0',
    textAlign: 'center',
    color: Style.colors.grey500,
    link: {
      color: Style.colors.primary,
      hover: {
        color: Style.colors.darkPrimary,
      },
    },
  },
}

@Radium
@reactMixin.decorate(
  remoteSearchMixinFactory(PageState.ActionCreators.setSearch.bind(PageState.ActionCreators))
)
export class CompanySignupPage extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
  }

  static propTypes = $y.propTypesFromData(CompanySignupPage, {
    isFetching: PropTypes.bool,
  })

  constructor() {
    super()
    this.state = {
      createError: null,
      loading: false,
      selectedCompany: null,
    }
  }

  redirectIfNecessary() {
    if (this.context.currentUser && this.context.currentUser.pending_sent_request) {
      this.context.router.push(resolve('request-sent'))
    }
    if (this.context.currentUser && this.context.currentUser.company) {
      connectToSharelink(this.props.link).then(() => {
        if (this.props.link) {
          goToSharelinkChannelPage(this.props.link)
        } else {
          enterMyagi()
        }
      })
    }
  }

  componentDidMount() {
    this.refs.searchInput.refs.textInput.focus()
    this.redirectIfNecessary()
  }

  componentDidUpdate() {
    this.redirectIfNecessary()
  }

  selectedACompany = (company) => {
    this.setState({ selectedCompany: company })
  }

  resetSelectedCompany = (company) => {
    this.setState({ selectedCompany: null })
  }

  onInputChange = () => {
    this.setState({ createError: null })
  }

  createCompany = () => {
    const searchInput = this.refs.searchInput.refs.textInput.state.value
    const linkName = this.props.link ? this.props.link.name : ''
    const hasACompanyInvitation = Boolean(qs('c'))
    if (hasACompanyInvitation) {
      // a user can't use a sharelink and a connection invitation at the same time
      this.context.router.push({
        pathname: `/signup/new-company/${window.location.search}`,
        state: { link: linkName, newCompanyName: searchInput },
      })
    } else {
      this.context.router.push({
        pathname: `/signup/new-company/${linkName}`,
        state: { link: linkName, newCompanyName: searchInput },
      })
    }
  }

  renderCompanySearchInput = () => (
    <div style={styles.searchInputContainer}>
      <FieldHeader required>{t('your_company_name')}</FieldHeader>
      {this.getSearchInput({
        initialValue: `${t('search_companies')}...`,
        name: "employerSearch",
        style: { container: { width: '100%' } },
      })}
    </div>
  )

  renderJoinTeamForm = () => (
    <JoinTeamForm
      company={this.state.selectedCompany}
      resetSelectedCompany={this.resetSelectedCompany}
      userID={this.context.currentUser && this.context.currentUser.id}
      link={this.props.link}
    />
  )

  renderCompanyList = () => (
    <div style={{ width: '100%' }}>
      <div style={styles.companyListTitle}>{t('pick_your_company')}</div>
      <PrimaryLink
        onClick={() => {
          this.createCompany()
        }}
        className="addCompanyLink"
      >
        {t('cant_find_company')}
        <i className="angle right icon" style={{ textDecoration: 'none' }} />
      </PrimaryLink>
      {this.renderCompanyItems(this.props.companies)}
    </div>
  )

  renderCompanyItems = (companies) => companies.map((co, i) => {
    const existingInvitation = this.existingInvitation(this.props.invitations, co)
    return (
      <CompanyItem
        key={i}
        company={co}
        userId={this.context.currentUser && this.context.currentUser.id}
        selectCompany={this.selectedACompany}
        pendingInvitation={existingInvitation}
      />
    )
  })

  existingInvitation = (pendingInvitations, company) => {
    let result = false
    if (pendingInvitations && pendingInvitations.size > 0) {
      pendingInvitations.map((invitation) => {
        if (invitation.company.id === company.id) {
          result = invitation
        }
      })
    }
    return result
  }

  render() {
    const search = PageState.Store.getSearch()
    return (
      <div style={styles.container}>
        {this.context.currentUser && renderWelcomeMessage(this.context.currentUser)}
        {!this.state.selectedCompany && this.renderCompanySearchInput()}
        <LoadingContainer
          loadingProps={[this.props.companies]}
          createComponent={() => (
            <div style={styles.companyListContainer}>
              {this.state.selectedCompany ? this.renderJoinTeamForm() : this.renderCompanyList()}
            </div>
          )}
          noDataText={search ? t('no_companies_match') : ''}
        />
        {this.props.invitations && (
          <InvitesPending
            currentUser={this.context.currentUser}
            invitations={this.props.invitations}
          />
        )}
      </div>
    )
  }
}

const PageInner = Marty.createContainer(CompanySignupPage, {
  listenTo: [UsersState.Store, PageState.Store, InvitationsState.Store],

  contextTypes: {
    currentUser: PropTypes.object,
  },

  getCurrentDatetime() {
    if (!this._currentDatetime) {
      this._currentDatetime = momentToISO(moment())
    }
    return this._currentDatetime
  },

  fetch: {
    companies() {
      const search = PageState.Store.getSearch()
      if (!search) {
        return Im.freeze([])
      }
      return PublicCompaniesState.Store.getItems({
        limit: 6,
        company_type: 'retailer',
        companysettings__public_access: true,
        search,
        ordering: '-search_rank, name',
        fields: [
          'url',
          'id',
          'user_count',
          'region_of_responsibility',
          'company_url',
          'name',
          'company_type',
          'company_logo',
          'learnergroups',
          'child_companies',
          'learnergroups.name',
          'learnergroups.url',
          'subdomain',
          'companysettings.allow_signups_to_create_teams',
          'companysettings.public_access',
          'subscription.user_permissions_enabled',
          'force_sso_via_url',
          'subscription.account_status',
        ],
      })
    },
    invitations() {
      const email = this.context.currentUser && this.context.currentUser.email
      if (!email) {
        return null
      }
      return InvitationsState.Store.getItems({
        invite_id__isnull: false,
        invite_type: 1,
        registered_emails__icontains: email,
        ordering: '-created_at',
        expires__gte: this.getCurrentDatetime(),
        fields: [
          'id',
          'invite_id',
          'expires',
          'invite_type',
          'company.company_logo',
          'company.name',
          'company.region_of_responsibility',
          'company.company_url',
          'company.id',
        ],
      })
    },
  },

  pending() {
    return containerUtils.defaultPending(this, CompanySignupPage, {
      isFetching: true,
    })
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, CompanySignupPage, errors)
  },
})

export class Page extends React.Component {
  static contextTypes = {
    location: PropTypes.object.isRequired,
  }

  render() {
    return (
      <PublicStyling>
        <PageInner />
      </PublicStyling>
    )
  }
}
