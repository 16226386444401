import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import SearchableAsyncSelect from './searchable-async'
import { convertDataToReadableFormat, getIds } from './utils'

const GET_REGIONS = gql`
  query GetRegions($search: String, $orderBy: [String]!) {
    Region__List(first: 100, search: $search, orderBy: $orderBy) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const UPDATE_REGIONS = gql`
  mutation UpdateRegions($id: ID!, $regionIds: [Int]!) {
    Company__Update(input: { id: $id, regionsOfResponsibility: $regionIds }) {
      result {
        id
        name
      }
    }
  }
`

const MultiRegionAsyncSelect = ({ company }) => {
  const defaultOptions = convertDataToReadableFormat(company.regions_of_responsibility, false)
  const [updateRegions] = useMutation(UPDATE_REGIONS)

  function onChange(regions) {
    updateRegions({
      variables: { id: company.id, regionIds: getIds(regions) },
    })
  }

  return (
    <SearchableAsyncSelect
      query={GET_REGIONS}
      defaultOptions={defaultOptions}
      entityName="Region__List"
      onChange={onChange}
      multi
    />
  )
}

export default MultiRegionAsyncSelect
