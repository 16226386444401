import { ANALYTICS_EVENTS } from 'core/constants'
import { ChannelAbout } from 'components/common/channel-about'
import { HoverableLink } from 'components/common/hover'
import { Image } from 'components/common/image'
import { LoadingSpinner } from 'components/common/loading'
import MYAGI_LOGO from 'img/logo.svg'
import Marty from 'marty'
import Button from 'shared-js/components/common/buttons'
import PropTypes from 'prop-types'
import PublicLinkState from 'state/public-links'
import { PublicStyling } from 'components/common/public-styling'
import Radium from 'radium'
import React from 'react'
import Style from 'style'
import UsersState from 'state/users'
import containerUtils from 'utilities/containers'
import { t } from 'i18n'
import { PlansCarousel } from './plans-carousel'

const LOGIN_PATH = '/accounts/login/'

class ActionButton extends React.Component {
  render() {
    const companyName = this.props.linkCompanyName ? this.props.linkCompanyName : 'this company'
    return (
      <div style={styles.viewContent}>
        <Button
          color="primary"
          containerStyle={styles.actionButton}
          onPress={this.props.next}
          loading={this.props.loading}
        >
          {t('view_company_content', { companyName })}
        </Button>
      </div>
    )
  }
}

@Radium
class ShareLinkPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  getFirstChannel = () => {
    const link = this.props.link
    let initialChannel = null
    if (link && link.channels) {
      initialChannel = link.initial_channel || link.channels[0]
    }
    return initialChannel
  }

  renderUnavailableMessage = () => (
    <div style={styles.container}>
      <Image src={MYAGI_LOGO} style={styles.logo} />
      <h1 style={styles.heading}>{t('sharelink_unavailable')}</h1>
      <div style={styles.centered}>
        {this.props.currentUser ? (
          <Button
            color="primary"
            containerStyle={styles.unavailableButtonContainer}
            onClick={() => this.context.router.push('/views/training/')}
          >
            {t('continue_to_myagi')}
          </Button>
        ) : (
          <Button
            color="primary"
            containerStyle={styles.unavailableButtonContainer}
            onClick={() => this.context.router.push('/signup/user/')}
          >
            {t('sign_up')}
          </Button>
        )}
      </div>
    </div>
  )

  renderLoginLink = () => (
    <HoverableLink
      hoverStyle={styles.linkHover}
      style={styles.existingAccountLink}
      onClick={() => {
        const firstChannel = this.getFirstChannel()
        this.goToLogIn()
      }}
    >
      {t('i_already_have_an_account')}
      <i style={styles.forwardIcon} className="angle right icon" />
    </HoverableLink>
  )

  goToLogIn = () => {
    analytics.track(ANALYTICS_EVENTS.LOG_IN_VIA_SHARELINK)
    const firstChannel = this.getFirstChannel()
    this.context.router.push({
      pathname: `${LOGIN_PATH}${this.props.link.name}`,
      state: {
        link: this.props.link.name,
        firstChannelId: firstChannel.id,
      },
    })
  }

  next = () => {
    if (this.props.currentUser && this.props.currentUser.company) {
      this.viewContent()
    } else {
      this.signUp()
    }
  }

  viewContent = async () => {
    const firstChannel = this.getFirstChannel()
    this.setState({ loading: true })
    analytics.track(ANALYTICS_EVENTS.VIEW_CONTENT_VIA_SHARELINK)

    await PublicLinkState.ActionCreators.doListAction('connect_to_sharelink', {
      link_name: this.props.link.name,
    })

    window.location.href = `/views/channel-content/${firstChannel.id}/?fromSharelink=true`
  }

  signUp = () => {
    this.setState({ loading: true })
    analytics.track(ANALYTICS_EVENTS.SIGN_UP_VIA_SHARELINK)
    this.context.router.push({
      pathname: `/signup/user/${this.props.link.name}`,
      state: { link: this.props.link.name },
    })
  }

  render() {
    const { link, linkCompany, currentUser } = this.props
    const firstChannel = this.getFirstChannel()
    if (!link) {
      return this.renderUnavailableMessage()
    }
    const plans = []
    link.channels.map((channel) =>
      channel.training_plans.forEach((plan) => {
        if (plan.is_published) {
          plans.push(plan)
        }
      })
    )

    const heading = t('has_shared_training_plans', {}, { pluralize: plans.length })
    return (
      <div style={styles.container}>
        <Image src={linkCompany.company_logo} style={styles.logo} />
        <h1 style={styles.heading}>{`${linkCompany.name} ${heading}`}</h1>

        <ActionButton
          linkCompanyName={linkCompany.name}
          firstChannel={firstChannel}
          link={link}
          currentUser={currentUser}
          next={this.next}
          loading={this.state.loading}
        />
        <PlansCarousel plans={plans} next={this.next} />
        {!currentUser && this.renderLoginLink()}
      </div>
    )
  }
}

class ShareLinkPageContainer extends React.Component {
  render() {
    return (
      <PublicStyling>
        <ShareLinkPage currentUser={this.props.currentUser} />
      </PublicStyling>
    )
  }
}

export const Page = Marty.createContainer(ShareLinkPageContainer, {
  contextTypes: {
    routeParams: PropTypes.object.isRequired,
  },
  listenTo: [UsersState.Store],

  fetch: {
    currentUser() {
      const fetch = UsersState.Store.getCurrent()
      return fetch
    },
  },

  done(results) {
    return <ShareLinkPageContainer {...results} />
  },

  failed(errors) {
    return <ShareLinkPageContainer />
  },

  pending() {
    return <LoadingSpinner containerStyle={styles.loadingContainer} />
  },
})

const styles = {
  container: {
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    width: '80%',
    margin: '0px auto 40px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    [Style.vars.media.computer]: {
      width: '75%',
    },
    [Style.vars.media.mobile]: {
      width: '90%',
    },
  },
  loadingContainer: {
    height: '100vh',
  },
  logo: {
    height: '13vh',
    width: '50%',
    margin: '0 auto 3vh auto',
  },
  viewContent: {
    paddingTop: '1.5vh',
    marginBottom: 35,
  },
  heading: {
    fontSize: '2.5rem',
    marginBottom: 30,
    fontWeight: '400',
    [Style.vars.media.mobile]: {
      fontSize: '2rem',
    },
    [Style.vars.media.xSmall]: {
      fontSize: '1.5rem',
      marginBottom: '1.75rem',
    },
  },
  unavailableButtonContainer: {
    margin: 'auto',
  },
  centered: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signUpButton: {
    height: 37,
    width: '10em',
    background: Style.vars.deprecatedColors.primary,
    color: Style.vars.deprecatedColors.white,
    margin: '0 auto 30px auto',
  },
  existingAccountLink: {
    color: Style.vars.deprecatedColors.primary,
    textAlign: 'center',
    margin: '10px 0 30px',
    display: 'block',
    fontSize: 15,
    cursor: 'pointer',
    [Style.vars.media.xSmall]: {
      fontSize: 13,
    },
  },
  linkHover: {
    color: Style.vars.deprecatedColors.primary,
    textDecoration: 'underline',
  },
  actionButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}
