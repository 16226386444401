import React, { useState } from 'react'
import { t } from 'shared-js/i18n'
import { useQuery } from '@apollo/react-hooks'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import gql from 'graphql-tag'
import { Button, TextButton } from 'ui-kit'
import { LoadingSpinner } from 'shared-js/components/common/loading'
import IndustryList from './industries-list'
import { Container, Title, ButtonContainer, CancelButtonContainer } from './common'
import { GetCurrentLevelThreeIndustries_Tag__List_edges as ActiveIndustryEdge } from './gql-types/GetCurrentLevelThreeIndustries'
import { GetLevelThreeIndustries_Tag__List_edges as IndustryEdge } from './gql-types/GetLevelThreeIndustries'

const MAX_FETCH = 100

type IndustriesStepProps = {
  secondLevelIndustries: string[]
  setIndustries(): void
  setSelectedIndustries(thirdLevelIndustries?: string[]): void
  resetForm(): void
}

type IndustriesInnerProps = {
  activeIndustries: string[]
  levelThreeIndustries: (IndustryEdge | null)[]
  setSelectedIndustries(thirdLevelIndustries?: string[]): void
  resetForm(): void
}

const GET_CURRENT_LEVEL_THREE_INDUSTRIES = gql`
  query GetCurrentLevelThreeIndustries(
    $companyID: ID!
    $loadingSize: Int!
    $secondLevelIndustries: [ID]!
  ) {
    Tag__List(
      first: $loadingSize
      orderBy: ["id"]
      company: $companyID
      category: "general"
      thirdLevelTags: true
      inHierarchyWithTags: $secondLevelIndustries
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`

const GET_LEVEL_THREE_INDUSTRIES = gql`
  query GetLevelThreeIndustries($loadingSize: Int!, $secondLevelIndustries: [ID]!) {
    Tag__List(
      thirdLevelTags: true
      inHierarchyWithTags: $secondLevelIndustries
      first: $loadingSize
      orderBy: ["name"]
    ) {
      edges {
        node {
          id
          name
        }
      }
      totalCount
    }
  }
`

const ThirdLevelIndustries = ({
  secondLevelIndustries,
  setIndustries,
  ...otherProps
}: IndustriesStepProps) => {
  const currentUser = useCurrentUser()
  const currentLevelThreeIndustries = useQuery(GET_CURRENT_LEVEL_THREE_INDUSTRIES, {
    variables: {
      loadingSize: MAX_FETCH,
      secondLevelIndustries,
      companyID: currentUser.company.id,
    },
  })

  const levelThreeIndustries = useQuery(GET_LEVEL_THREE_INDUSTRIES, {
    variables: {
      loadingSize: MAX_FETCH,
      secondLevelIndustries,
    },
  })

  const hasFetchedData =
    !levelThreeIndustries.loading &&
    levelThreeIndustries.data?.Tag__List &&
    !currentLevelThreeIndustries.loading &&
    currentLevelThreeIndustries.data?.Tag__List

  if (
    (hasFetchedData && levelThreeIndustries.data.Tag__List.totalCount === 0) ||
    secondLevelIndustries.length === 0
  ) {
    setIndustries()
  }

  const activeIndustries =
    hasFetchedData &&
    currentLevelThreeIndustries.data.Tag__List.edges
      .filter((industry: ActiveIndustryEdge) => industry?.node?.id)
      .map((industry: ActiveIndustryEdge) => industry!.node!.id)

  return hasFetchedData && levelThreeIndustries.data.Tag__List.totalCount ? (
    <ThirdLevelIndustriesInner
      activeIndustries={activeIndustries}
      levelThreeIndustries={levelThreeIndustries.data.Tag__List.edges}
      {...otherProps}
    />
  ) : (
    <LoadingSpinner />
  )
}

const ThirdLevelIndustriesInner = ({
  activeIndustries,
  setSelectedIndustries,
  levelThreeIndustries,
  resetForm,
}: IndustriesInnerProps) => {
  const [activeIndustriesIDs, setActiveIndustriesIDs] = useState<string[]>(activeIndustries)

  const toggleIndustry = (industryID: string, isActive: boolean) => {
    setActiveIndustriesIDs((prevIDs) =>
      isActive ? prevIDs.filter((id) => id !== industryID) : [...prevIDs, industryID]
    )
  }

  const setIndustriesState = () => {
    if (activeIndustriesIDs.length) {
      setSelectedIndustries(activeIndustriesIDs)
    } else {
      setSelectedIndustries()
    }
  }

  return (
    <>
      <Container>
        <Title>{t('last_one_do_any_of_these_apply')}</Title>
      </Container>
      <Container>
        <IndustryList
          industries={levelThreeIndustries}
          activeIndustriesIDs={activeIndustriesIDs}
          toggleIndustry={toggleIndustry}
        />
      </Container>
      <ButtonContainer>
        <CancelButtonContainer>
          <TextButton onClick={resetForm}>{t('cancel')}</TextButton>
        </CancelButtonContainer>
        <Button onClick={setIndustriesState}>{t('next')}</Button>
      </ButtonContainer>
    </>
  )
}

export default ThirdLevelIndustries
