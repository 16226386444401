import {
  Form,
  HiddenTextInput,
  SearchableSelect,
  SubmitButton,
} from 'components/common/form-deprecated/index'
import { ManyUsersSelection } from 'components/common/many-users-selection/index'
import { Modal } from 'components/common/modals/index'
import { TrainingPlanSearchableMultiSelect } from 'components/common/searchable-multiselect'
import { ANALYTICS_EVENTS } from 'core/constants'
import { t } from 'i18n'
import _ from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'
import TrainingSchedulesState from 'state/training-schedules'
import { momentToISO } from 'utilities/time'

function getEnrollmentDate(...args) {
  const now = moment()
  return momentToISO(now.add(...args))
}

const NO_DUE_DATE = 'NO_DUE_DATE'
const ENROLLMENT_DUE_DATES = [
  {
    label: 'No due date',
    // Required because searchable dropdown does not like
    // falsey values.
    value: NO_DUE_DATE,
  },
  {
    label: '1 week',
    value: getEnrollmentDate(1, 'week'),
  },
  {
    label: '2 weeks',
    value: getEnrollmentDate(2, 'weeks'),
  },
  {
    label: '1 month',
    value: getEnrollmentDate(1, 'month'),
  },
  {
    label: '45 days',
    value: getEnrollmentDate(45, 'days'),
  },
  {
    label: '2 months',
    value: getEnrollmentDate(2, 'months'),
  },
]

class DueDateSelectionInput extends React.Component {
  getNameAndValue() {
    return this.refs.selection.getNameAndValue()
  }

  isValid() {
    return this.refs.selection.isValid()
  }

  render() {
    return (
      <div style={this.props.style}>
        <h3>{t('select_due_date')}</h3>
        <p>{t('date_to_complete_plan_by')}</p>
        <HiddenTextInput />
        <SearchableSelect
          options={ENROLLMENT_DUE_DATES}
          name={this.props.name}
          style={{ container: { marginBottom: 10 } }}
          ref="selection"
        />
      </div>
    )
  }
}

export class SelectUsersModal extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  static contextTypes = {
    routeParams: PropTypes.object.isRequired,
  }

  show() {
    this.refs.modal.show()
  }

  hide() {
    this.refs.modal.hide()
  }

  render() {
    // This modal will only handle due dates if channel based enrollments is enabled
    const enrolledInPlanId = this.context.routeParams && this.context.routeParams.planId

    return (
      <Modal ref="modal" header={t('due_dates')} closeOnDimmerClick>
        <div className="content">
          <Form onSubmitAndValid={this.props.onSubmitAndValid}>
            <DueDateSelectionInput name="due_date" />
            <h3>{t('select_users')}</h3>
            <p>{t('select_users_that_are_enrolled')}</p>
            <ManyUsersSelection
              currentUser={this.props.currentUser}
              enrolledInPlanId={enrolledInPlanId}
              required
              returnLearners
            />
            <SubmitButton text={this.props.submitText} />
          </Form>
        </div>
      </Modal>
    )
  }
}

export class SelectTrainingPlansModal extends React.Component {
  show() {
    this.refs.modal.show()
  }

  hide() {
    this.refs.modal.hide()
  }

  render() {
    return (
      <Modal ref="modal" closeOnDimmerClick header={t('set_due_dates')}>
        <div className="content">
          <Form onSubmitAndValid={this.props.onSubmitAndValid}>
            <h3>{t('select_plans')}</h3>
            <HiddenTextInput />
            <p>{t('only_plans_with_lessons_can_be_selected')}</p>
            <TrainingPlanSearchableMultiSelect
              name="trainingPlanURLs"
              fetchOpts={this.props.fetchOpts}
              required
            />
            {this.props.showDueDateSelection ? (
              <DueDateSelectionInput style={{ marginTop: 10 }} name="due_date" />
            ) : null}
            <SubmitButton text={this.props.submitText || t('next')} />
          </Form>
        </div>
      </Modal>
    )
  }
}

const EnrollModalMixin = {
  contextTypes: {
    displayTempPositiveMessage: PropTypes.func.isRequired,
  },

  propTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  createEnrollments(data) {
    analytics.track(ANALYTICS_EVENTS.ENROLL_USERS_IN_CONTENT, {
      assignedBy: data.assigned_by,
      trainingPlans: data.training_plans,
      learners: data.learners,
      teams: data.teams,
    })
    if (data.due_date === NO_DUE_DATE) data.due_date = undefined
    data.assigned_by = this.props.currentUser.url
    TrainingSchedulesState.ActionCreators.doListAction('bulk_create', data)
    this.context.displayTempPositiveMessage({
      heading: 'Enrollment successful',
    })
    this.hideAll()
  },
}

@reactMixin.decorate(EnrollModalMixin)
export class SetDueDateForPlanModal extends React.Component {
  static propTypes = {
    selectedTrainingPlans: PropTypes.array.isRequired,
  }

  onFormSubmitAndValid = (data) => {
    data.training_plans = this.props.selectedTrainingPlans.map((plan) => plan.url)
    this.createEnrollments(data)
  }

  hideAll() {
    this.refs.selectUsersModal.hide()
  }

  show() {
    this.refs.selectUsersModal.show()
  }

  render() {
    return (
      <div>
        <SelectUsersModal
          ref="selectUsersModal"
          onSubmitAndValid={this.onFormSubmitAndValid}
          currentUser={this.props.currentUser}
        />
      </div>
    )
  }
}

@reactMixin.decorate(EnrollModalMixin)
export class SetDueDateForUserModal extends React.Component {
  static propTypes = {
    selectedUser: PropTypes.object.isRequired,
  }

  onFormSubmitAndValid = (data) => {
    const submitData = {}
    const user = this.props.selectedUser
    submitData.learners = [user.learner.url || user.learner]
    submitData.due_date = data.due_date
    delete data.due_date
    submitData.training_plans = data.trainingPlanURLs
    this.createEnrollments(submitData)
  }

  hideAll() {
    this.refs.selectPlansModal.hide()
  }

  show() {
    this.refs.selectPlansModal.show()
  }

  render() {
    return (
      <div>
        <SelectTrainingPlansModal
          ref="selectPlansModal"
          onSubmitAndValid={this.onFormSubmitAndValid}
          currentUser={this.props.currentUser}
          submitText={t('submit')}
          fetchOpts={{ userIsEnrolled: this.props.selectedUser.id }}
          showDueDateSelection
        />
      </div>
    )
  }
}
