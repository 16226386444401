import cx from 'classnames'
import { t } from 'i18n'
import Radium from 'radium'
import React from 'react'
import Style from 'style'

const styles = {
  editButton: {
    backgroundColor: '#eee',
    color: '#444',
    display: 'inline-block',
    padding: '4px 8px',
    borderRadius: 4,
    margin: '4px 8px 4px 0px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    ':hover': {
      backgroundColor: Style.vars.deprecatedColors.primary,
      color: Style.vars.deprecatedColors.primaryFontColor,
    },
  },
}

@Radium
export class EditButton extends React.Component {
  render() {
    const classes = cx('ui icon circle', {
      add: this.props.length == 0,
      'edit outline': this.props.length > 0,
    })
    return (
      <div key={this.props.id} style={styles.editButton} onClick={this.props.onClick}>
        <i className={classes} />
        {this.props.length === 0 ? t('add') : t('edit')}
      </div>
    )
  }
}
