import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import { t } from 'i18n'

import ModuleTrainingPlansState from 'state/module-training-plans'

import { Modal } from 'components/common/modals'
import { getIdFromApiUrl } from 'utilities/generic'
import { PlainTabs } from 'components/common/router-tabs'

import { Form, SubmitButton, FieldHeader } from 'components/common/form-deprecated'
import { ModuleCardSelect } from 'components/common/card-searchable-select'

class SelectModuleModalContent extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  onFormSubmit = (data) => {
    const modules = data.modules
    const trainingPlan = this.props.trainingPlan
    // add the training plan to all selected modules.
    const promises = _.map(modules, (module) => {
      // let the module select component handle finding available modules.
      const availableModules = this.refs.form.refs.moduleSelection.getSelectedEntitiesArray()
      const moduleObj = _.find(availableModules, (item) => item.url == module)
      const currentTrainingPlans = moduleObj.training_plans
      if (!_.includes(currentTrainingPlans, trainingPlan.url)) {
        return ModuleTrainingPlansState.ActionCreators.create({
          module: moduleObj.url,
          training_plan: trainingPlan.url,
          order: 0,
        })
      }
      return Promise.resolve(true) // for promise.all
    })
    Promise.all(promises)
      .then((r) => {
        this.context.displayTempPositiveMessage({
          heading: 'Success',
          body: 'Lessons added to plan',
        })
      })
      .catch((r) => {
        this.context.displayTempNegativeMessage({
          heading: t('unable_to_add_lessons_to_channel_error'),
        })
      })
    this.props.onModuleUpdate()
  }

  render() {
    const planModules = this.props.trainingPlan.modules
    const initModuleIds = planModules.map((item) => item.id)

    if (initModuleIds.length) {
      // exclude modules that are already in current training plan
      _.extend(this.props.fetchOpts, { exclude__id__in: initModuleIds })
    }

    return (
      <div>
        <Form ref="form" onSubmitAndValid={this.onFormSubmit}>
          <ModuleCardSelect
            name="modules"
            onChange={this.props.onModuleSelectChange}
            ref="moduleSelection"
            currentUser={this.props.currentUser}
            hide={this.props.hide}
            plan={this.props.trainingPlan}
            fetchOpts={this.props.fetchOpts}
            required
            many
          />
          <SubmitButton onSubmit={_.noop} text={t('add_lessons_to_plan')} />
        </Form>
      </div>
    )
  }
}

const EXTERNAL_TAB = 'external'
const INTERNAL_TAB = 'internal'

export class AddExistingLessonModal extends React.Component {
  static contextTypes = {
    featureFlags: PropTypes.objectOf(PropTypes.bool),
  }

  state = {
    tab: INTERNAL_TAB,
  }

  show = () => {
    _.defer(() => this.refs.modal.show())
  }

  hide = () => {
    this.refs.modal.hide()
  }

  onModuleUpdate = () => {
    _.defer(() => {
      this.hide()
      if (this.props.onModuleUpdate) {
        this.props.onModuleUpdate()
      }
    })
  }

  render() {
    const { trainingPlan, onModuleSelectChange, currentUser, createNewModule } = this.props
    let fetchOpts = {
      available_for_attempting: this.props.currentUser.id,
    }
    if (this.state.tab === INTERNAL_TAB) {
      fetchOpts = {
        ...fetchOpts,
        company: currentUser.company.id,
        deactivated__isnull: true,
      }
    } else {
      fetchOpts = {
        ...fetchOpts,
        exclude_from_company: currentUser.company.id,
        deactivated__isnull: true,
      }
    }

    return (
      <Modal
        ref="modal"
        header={t('add_lessons_to_the_name_plan', {
          name: trainingPlan.name,
        })}
        size="large"
        closeOnDimmerClick
        scrolling
      >
        {this.context.featureFlags.add_external_lessons_to_internal_plans && (
          <PlainTabs
            tabs={[INTERNAL_TAB, EXTERNAL_TAB]}
            current={this.state.tab}
            onClick={(tab) => this.setState({ ...this.state, tab })}
          />
        )}
        <div>
          {trainingPlan && (
            <SelectModuleModalContent
              {...this.props}
              onChange={onModuleSelectChange}
              hide={this.props.hide}
              currentUser={currentUser}
              fetchOpts={fetchOpts}
              createNewModule={createNewModule}
              onModuleUpdate={this.onModuleUpdate}
            />
          )}
        </div>
      </Modal>
    )
  }
}
