import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import Im from 'shared-js/immutable'
import reactMixin from 'react-mixin'
import Radium from 'radium'

import $ from 'vendor/jquery/semantic'

import { ClickOutsideContainer } from 'components/common/click-outside-container'
import { SearchBarContainer } from 'components/common/universal-search-bar'
import Style from 'style/index.js'
import { MOBILE_WIDTH } from 'components/navbar'

const styles = {
  mobileDropdownIcon: {
    color: Style.vars.deprecatedColors.xDarkGrey,
    fontSize: 19,
    [MOBILE_WIDTH]: {
      display: 'block',
    },
  },
  mobileMenu: {
    display: 'block',
    backgroundColor: Style.vars.deprecatedColors.white,
    marginTop: 15,
  },
  search: {
    fontSize: 12,
    borderRadius: 20,
    order: '1',
    height: '100%',
    container: {
      width: '90vw',
      margin: '0 auto 20px auto',
    },
  },
}

@Radium
export class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  onToggle = () => {
    this.setState({ open: !this.state.open })
  }

  onClickOutside = () => {
    if (this.state.open) {
      this.onToggle()
    }
  }

  render() {
    const children = this.props.children.map((child, i) => (
      <div key={i} onClick={this.onToggle} className="tw-flex tw-justify-center tw-py-3">
        {child}
      </div>
    ))
    return (
      <ClickOutsideContainer onClickOutside={this.onClickOutside}>
        <div className="ui top left dropdown">
          <i
            className="ui icon content tw-w-8 tw-h-8 tw-p-1 tw-text-gray-500 hover:tw-text-gray-700 md:tw-hidden"
            style={styles.mobileDropdownIcon}
            onClick={this.onToggle}
          />
          {this.state.open ? (
            <div
              style={{
                ...styles.mobileMenu,
              }}
              className="menu"
            >
              <div className="tw-bg-white md:tw-bg-transparent tw-h-full tw-container tw-mx-auto tw-px-4">
                <div className="tw-flex tw-py-2 tw-flex-col tw-h-full">
                  <nav className="tw-flex tw-flex-col">{children}</nav>
                  <div className="tw-flex tw-mt-10">
                    <SearchBarContainer style={styles.search} onSearch={this.onClickOutside} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </ClickOutsideContainer>
    )
  }
}
