import PropTypes from 'prop-types'
import React from 'react'
import Style from 'style/index.js'
import Im from 'shared-js/immutable'
import Marty from 'marty'
import _ from 'lodash'
import { t } from 'i18n'

import ChannelsState from 'state/channels'
import containerUtils from 'utilities/containers.js'
import TrainingPlansState from 'state/training-plans'
// import { AddTrainingPlansModal } from '../add-training-plans-modal';
import { PlanCard } from 'components/content-management/plans-tab/plan-card'
import { Modal } from 'components/common/modals/index'
import { LoadingContainer, NoData } from 'components/common/loading'
import { PrimaryButton } from 'components/common/buttons'
import { FullWidthSegment } from 'components/common/box'
import TrainingPlanTrainingUnitsState from 'state/training-plan-training-units'
import { resolve } from 'utilities/deprecated-named-routes'

import {
  SortableElement,
  SortableContainer,
  arrayMove,
  ReorderButton,
} from 'components/common/ordering'

const styles = {
  segment: {
    padding: '20px 10px 0 10px',
    minHeight: '16em',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  plansContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  buttonContainer: {
    height: 30,
    marginTop: -20,
  },
}

@SortableElement
class TrainingPlanItem extends React.Component {
  static propTypes = {
    trainingPlan: PropTypes.object.isRequired,
    channel: PropTypes.object.isRequired,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  onRemoveIconClick = (evt) => {
    evt.stopPropagation()
    this.refs.archiveModal.show()
  }

  getDropdownItems() {
    const items = []
    if (!this.props.isUneditableChannel) {
      items.push({
        label: 'Edit plan',
        action: this.editPlan,
      })
      items.push({
        label: 'Remove from channel',
        action: this.showRemoveFromChannelModal,
      })
    }
    return items
  }

  doArchive = () => {
    TrainingPlanTrainingUnitsState.ActionCreators.doListAction(
      'delete_for_plan_and_training_unit',
      {
        training_plan: this.props.trainingPlan.url,
        training_unit: this.props.channel.url,
      }
    )
  }

  viewPlan = () => {
    this.context.router.push(
      `${resolve('plan-management', {
        planId: this.props.trainingPlan.id,
      })}`
    )
  }

  showRemoveFromChannelModal = () => {
    this.refs.archiveModal.show()
  }

  editPlan = () => {
    this.context.router.push(
      `${resolve('plan-management', {
        planId: this.props.trainingPlan.id,
      })}?prevChannel=${this.props.channel.id}`
    )
  }

  render() {
    const badgesWithTp = this.props.trainingPlan.badges
    let archiveConfirmText
    if (badgesWithTp.length) {
      archiveConfirmText = t('remove_last_badge')
    } else {
      // TODO
      archiveConfirmText = `This will permanently remove the "${this.props.trainingPlan.name}" plan from this channel.`
    }

    return (
      <div>
        <PlanCard
          key={this.props.trainingPlan.id}
          trainingPlan={this.props.trainingPlan}
          dropdownItems={this.getDropdownItems()}
          currentUser={this.props.currentUser}
          channels={Im.freeze([this.props.channel])}
          showTrainingPlanDetails={this.viewPlan}
          highlight={this.props.reorderEnabled}
          addPadding
        />
        <Modal
          header="Remove from channel"
          content={archiveConfirmText}
          onConfirm={this.doArchive}
          ref="archiveModal"
          basic
        />
      </div>
    )
  }
}

@SortableContainer
class TrainingPlansCollection extends React.Component {
  static propTypes = {
    trainingPlans: PropTypes.instanceOf(Im.List).isRequired,
    channel: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div style={styles.plansContainer}>
        {this.props.trainingPlans.map((plan, idx) => (
          <TrainingPlanItem
            key={plan.id}
            index={idx}
            trainingPlan={plan}
            channel={this.props.channel}
            currentUser={this.props.currentUser}
            reorderEnabled={this.props.reorderEnabled}
            isUneditableChannel={this.props.isUneditableChannel}
          />
        ))}
      </div>
    )
  }
}

export class TrainingPlansTab extends React.Component {
  constructor(props) {
    super()
    this.state = {
      sortedTrainingPlanTrainingUnits: props.trainingPlanTrainingUnits,
    }
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.trainingPlanTrainingUnits && !this.state.reorderEnabled) {
      if (
        !this.state.sortedTrainingPlanTrainingUnits ||
        _.size(nextProps.trainingPlanTrainingUnits) !==
          _.size(this.state.sortedTrainingPlanTrainingUnits)
      ) {
        this.setState({
          sortedTrainingPlanTrainingUnits: nextProps.trainingPlanTrainingUnits,
        })
      }
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const currentSortedTrainingUnits = this.state.sortedTrainingPlanTrainingUnits
    this.setState({
      sortedTrainingPlanTrainingUnits: Im.freeze(
        arrayMove(currentSortedTrainingUnits, oldIndex, newIndex)
      ),
    })
    _.defer(this.saveSorting)
  }

  saveSorting = () => {
    this.state.sortedTrainingPlanTrainingUnits.forEach((mtp, idx) => {
      if (mtp.order !== idx) {
        TrainingPlanTrainingUnitsState.ActionCreators.update(mtp.id, {
          order: idx,
        })
      }
    })
  }

  toggleReorder = () => {
    const currentReordedEnabled = this.state.reorderEnabled
    this.setState({ reorderEnabled: !currentReordedEnabled })
  }

  render() {
    return (
      <FullWidthSegment style={styles.segment}>
        {!this.props.isUneditableChannel && (
          <div style={styles.buttonContainer}>
            <ReorderButton
              reorderEnabled={this.state.reorderEnabled}
              toggleReorder={this.toggleReorder}
              containerStyle={{ float: 'right' }}
              entity="plans"
            />
            <PrimaryButton to={`${this.props.location.pathname}add/`} floatRight>
              {t('add_plans')}
            </PrimaryButton>
          </div>
        )}
        <LoadingContainer
          loadingProps={{
            trainingPlanTrainingUnits: this.state.sortedTrainingPlanTrainingUnits,
            channel: this.props.channel,
          }}
          createComponent={(props) => (
            <TrainingPlansCollection
              {...props}
              trainingPlans={this.state.sortedTrainingPlanTrainingUnits.map((tptu) =>
                Im.freeze(tptu.training_plan)
              )}
              axis="xy"
              onSortEnd={this.onSortEnd}
              shouldCancelStart={() => !this.state.reorderEnabled}
              reorderEnabled={this.state.reorderEnabled}
              currentUser={this.context.currentUser}
              isUneditableChannel={this.props.isUneditableChannel}
            />
          )}
          shouldRenderNoData={(props) => !_.size(this.state.sortedTrainingPlanTrainingUnits)}
          createNoDataComponent={(standardStyling) => (
            <NoData style={{ clear: 'both' }}>
              <span style={{ cursor: 'pointer' }} onClick={this.showAddTrainingPlansModal}>
                {`${t('no_plans_available_add_some')}`}
              </span>
{' '}
            </NoData>
          )}
        />
        {this.props.children}
      </FullWidthSegment>
    )
  }
}
