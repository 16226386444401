import React from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import PLACEHOLDER_IMAGE from 'img/placeholder.svg'
import { PublicPlanCard_plan } from './gql-types/PublicPlanCard_plan'

const WIDTH = 330
const HEIGHT = (9 / 16) * WIDTH // ratio should be 16:9 with width

type Props = {
  plan: PublicPlanCard_plan
  onPlanCardClick?: () => void
  overlayMessage?: string
}

/**
 * This PlanCard does not assume the user has access to the TrainingPlanType
 * endpoint. Only fields accessible on PublicTrainingPlanType are used.
 * Plan card contains an optional overlay with a message.
 */
export const PublicPlanCard = ({ plan, onPlanCardClick, overlayMessage }: Props) => {
  return (
    <Container>
      {overlayMessage ? (
        <OverlayContainer>
          <OverlayText onClick={onPlanCardClick} key={`overlay-text${plan.id}`}>
            {overlayMessage}
          </OverlayText>
        </OverlayContainer>
      ) : null}

      <TrainingPlanImage src={plan.customThumbnailSmall || PLACEHOLDER_IMAGE} />
      <PlanName>{plan.name}</PlanName>
    </Container>
  )
}

PublicPlanCard.fragment = gql`
  fragment PublicPlanCard_plan on PublicTrainingPlanType {
    id
    name
    customThumbnailSmall
  }
`

const Container = styled.div`
  width: ${WIDTH}px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  position: relative; /* for new indicator */
`

const TrainingPlanImage = styled.div<{ src: string }>`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  background-image: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
`

const PlanName = styled.div`
  margin: 5px 15px 5px 0;
  font-size: 18px;
  color: black;
`

const OverlayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  background-color: black;
  position: absolute;
  color: white;
  transition: 'all .2s ease-in-out';
  opacity: 0;
  :hover {
    opacity: 1;
  }
`
const OverlayText = styled.div`
  font-size: 18px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  :hover {
    text-decoration: underline;
  }
`
