import React from 'react'
import Im from 'shared-js/immutable'
import $y from 'utilities/yaler'
import PropTypes from 'prop-types'
import Radium from 'radium'
import { t } from 'i18n'

import Style from 'style'
import { ANALYTICS_EVENTS } from 'core/constants'
import { Modal } from 'components/common/modals/index'
import { ChannelCoverImageCard, CHANNEL_CARD_WIDTH } from 'components/common/channel-card/base/card'
import { LogoName } from 'components/common/channel-card/base/logo-name'
import { PublicPage as ChannelPage } from 'components/training/channel-content/public-page'
import { PrimaryButton } from 'components/common/buttons'
import { getConnectionStatusText } from 'components/discover/channel-discovery/channel-directory/page'
import LearnersState from 'state/learners'
import ChannelShareRequestsState from 'state/channel-share-requests'
import { CardCollection } from 'components/common/cards'
import camelcaseKeysDeep from 'camelcase-keys-deep'

const styles = {
  channelCardContainer: {
    padding: 10,
    transition: 'all .2s ease',
    ':hover': {
      transform: 'scale(1.02)',
    },
  },
  connectBtn: {
    display: 'block',
    width: '100%',
    marginLeft: 0,
    borderRadius: '0 0 3px 3px',
  },
  channelStats: {
    paddingTop: 10,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
  },
  likeRatingText: {
    color: 'gray',
  },
  extraContentBase: {
    cursor: 'pointer',
    overflow: 'visible',
    display: 'flex',
  },
  extraContentSelected: {
    backgroundColor: Style.vars.deprecatedColors.white,
    color: Style.vars.deprecatedColors.green,
    zIndex: 9,
    width: CHANNEL_CARD_WIDTH,
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    borderRight: `1px solid ${Style.vars.deprecatedColors.green}`,
    borderLeft: `1px solid ${Style.vars.deprecatedColors.green}`,
    borderBottom: `1px solid ${Style.vars.deprecatedColors.green}`,
    borderTop: 'none',
  },
  extraContentNotConnected: {
    backgroundColor: Style.vars.deprecatedColors.white,
    color: Style.vars.deprecatedColors.myagiOrange,
    zIndex: 9,
    width: CHANNEL_CARD_WIDTH,
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRight: `1px solid ${Style.vars.deprecatedColors.myagiOrange}`,
    borderLeft: `1px solid ${Style.vars.deprecatedColors.myagiOrange}`,
    borderBottom: `1px solid ${Style.vars.deprecatedColors.myagiOrange}`,
    borderTop: 'none',
  },
}

@Radium
class CardExtraContent extends React.Component {
  static propTypes = {
    channel: PropTypes.object.isRequired,
  }

  onConnectClick = () => {
    // if already connected or existing request, show info modal.
    if (this.props.isFollowed) {
      this.modal.show()
    } else {
      this.props.connect()
    }
  }

  render() {
    const { channel, isFollowed, curated, style } = this.props
    const isSubscribed = channel.is_subscribed_to_by_current_company
    const requestToAccess = channel.request_to_access
    const connectionStatusText = getConnectionStatusText({
      channel,
      isFollowed,
    })

    const followIcon = isFollowed ? 'checkmark icon white' : 'add circle icon white'
    const hasAccessToChannel = isSubscribed || (isFollowed && !requestToAccess)
    const modalHeader = hasAccessToChannel ? t('connected') : t('connection_requested')
    const modalContentText = hasAccessToChannel
      ? t('you_are_already_connected')
      : t('we_let_company_name_know_channel_requested', {
        companyName: channel.company.name,
      })
    return (
      <div
        className="extra content"
        style={Style.funcs.merge(Style.common.ellipsisOverflow, styles.extraContentBase)}
        key={`extra-content-${channel.id}`}
      >
        <a
          style={Style.funcs.merge(style, {
            color: Style.vars.deprecatedColors.green,
            borderColor: Style.vars.deprecatedColors.green,
          })}
          title={channel.display_name ? channel.display_name : channel.name}
          onClick={this.onConnectClick}
        >
          <span>
            <i className={followIcon} />
            {connectionStatusText}
          </span>
        </a>
        <Modal ref={(modal) => (this.modal = modal)} header={modalHeader} basic message>
          <div>{modalContentText}</div>
        </Modal>
      </div>
    )
  }
}

@Radium
export class ChannelCardContainer extends React.Component {
  static data = {
    channel: {
      fields: [
        'id',
        'name',
        'display_name',
        'description',
        'url',
        'logo',
        'cover_image',
        'cover_image_thumbnail',
        'viable_logo',
        'viable_cover_image',
        'request_to_access',
        'avg_like_rating',
        'is_subscribed_to_by_current_company',
        'existing_request_for_current_company',
        'company.id',
        'company.name',
        'company.deactivated',
        'company.cover_image',
        'company.cover_image_thumbnail',
        'company.company_logo',
        'training_plans.id',
        'training_plans.name',
        'training_plans.description',
        'training_plans.thumbnail_url',
      ],
    },
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    router: PropTypes.object,
  }

  static propTypes = {
    channel: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isFollowed:
        props.channel.is_subscribed_to_by_current_company ||
        props.channel.existing_request_for_current_company,
      showConnectToChannel: false,
      renderKey: 0,
      ignored: false,
    }
  }

  onPlanCardClick = () => {
    this.setState({ showConnectToChannel: true })
    this.channelDetailsModal.hide()
    this.connect()
  }

  showChannelDetailsModal = () => {
    analytics.track(ANALYTICS_EVENTS.VIEW_CHANNEL_DETAILS_DISCOVERY_PAGE, {
      channel: this.props.channel.id,
      channelName: this.props.channel.display_name
        ? this.props.channel.display_name
        : this.props.channel.name,
      user: this.context.currentUser.email,
    })
    this.channelDetailsModal.show()
  }

  connect = (evt) => {
    if (evt && evt.stopPropagation) evt.stopPropagation()
    if (this.state.isFollowed) return
    this.createConnection()
  }

  createConnection = () => {
    this.setState({ isFollowed: true })
    const companyURL = this.context.currentUser.company.url

    this.channelDetailsModal.hide()
    ChannelShareRequestsState.ActionCreators.create({
      company: companyURL.replace('/companies', '/public/companies'),
      training_unit: this.props.channel.url.replace('/public/training_unit', '/training_units'),
      requester: this.context.currentUser.url,
    })
      .then((dat) => {
        let message
        this.props.incrementNumChannelsConnected()
        if (!this.props.channel.request_to_access) {
          message = {
            heading: 'connected',
            body: 'you_can_now_access_content',
          }
        } else {
          message = {
            heading: 'connection_requested',
            body: 'we_let_company_know_channel_requested',
          }
        }
        this.props.displayTempPositiveMessage(message)
        // This is to ensure that onboarding steps get updated.
        // Otherwise, first onboarding step (about connecting to content)
        // remains incomplete until user refreshes the page.
        LearnersState.ActionCreators.clearRecentFetches()
      })
      .catch((err) => {
        this.props.displayTempNegativeMessage({
          heading: t('request_failed'),
          body: t('failed_to_create_request'),
        })
      })
  }

  updateParentModal = () => {
    // Refresh parent modal so it's scrollable once video is closed. This is
    // a hack that's needed because if there's a modal on top of another modal,
    // the 1st modal will lose ability to scroll after the 2nd modal is closed.
    // Bumping the modal's key fixes this problem.
    this.setState({ renderKey: this.state.renderKey + 1 })
  }

  render() {
    // If channel is ignored, don't render the card. Done this way so we don't
    // have to re-fetch the entire qs of curated channels.
    if (this.state.ignored) return null
    const channelPage = (
      <ChannelPage
        channelId={this.props.channel.id}
        onPlanCardClick={this.onPlanCardClick}
        showConnectToChannel={this.state.showConnectToChannel}
        updateParentModal={this.updateParentModal}
        isPublicPage
      />
    )
    const connectionStatusText = getConnectionStatusText({
      ...this.props,
      detailedView: true,
    })
    const camelCaseChannel = camelcaseKeysDeep(this.props.channel)
    return (
      <div style={styles.channelCardContainer}>
        <div>
          <div onClick={this.showChannelDetailsModal}>
            <ChannelCoverImageCard channel={camelCaseChannel}>
              <LogoName channel={camelCaseChannel} />
            </ChannelCoverImageCard>
          </div>
          <CardExtraContent
            isFollowed={this.state.isFollowed}
            key={`extra-${this.props.channel.id}`}
            style={
              this.state.isFollowed ? styles.extraContentSelected : styles.extraContentNotConnected
            }
            channel={this.props.channel}
            connect={this.connect}
            curated={this.props.curated}
            showChannelDetailsModal={this.showChannelDetailsModal}
          />
        </div>
        <Modal
          header=""
          key={this.state.renderKey}
          showOnInit={this.state.renderKey > 0}
          size="large"
          ref={(c) => (this.channelDetailsModal = c)}
          contentStyle={{ padding: 0 }}
        >
          <div>{channelPage}</div>

          {!this.state.isFollowed &&
          !this.props.channel.is_subscribed_to_by_current_company &&
          !this.props.channel.existing_request_for_current_company ? (
            <div className="actions">
                <PrimaryButton onClick={this.connect} style={styles.connectBtn}>
                {connectionStatusText}
              </PrimaryButton>
              </div>
            ) : null}
        </Modal>
      </div>
    )
  }
}

export class ChannelCardList extends React.Component {
  static data = {
    channels: {
      many: true,
      required: false,
      fields: [$y.getFields(ChannelCardContainer, 'channel'), 'company.url'],
    },
  }

  static propTypes = {
    channels: PropTypes.instanceOf(Im.List).isRequired,
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  getConnectionRequest = (channel) => {
    if (!this.props.companyConnections) return null
    const request = this.props.companyConnections.find(
      (obj) => obj.supplier === channel.company.url
    )
    return request
  }

  createCard = (channel) => (
    <ChannelCardContainer
      key={channel.id}
      channel={channel}
      incrementNumChannelsConnected={this.props.incrementNumChannelsConnected}
      displayTempPositiveMessage={this.context.displayTempPositiveMessage}
      displayTempNegativeMessage={this.context.displayTempNegativeMessage}
      currentUser={this.props.currentUser}
      renderKey={this.props.renderKey}
      curated={this.props.curated}
      companyConnectionRequest={this.getConnectionRequest(channel)}
    />
  )

  render() {
    return <CardCollection entities={this.props.channels} createCard={this.createCard} centered />
  }
}
