import React from 'react'
import styled from 'styled-components'
import Form from './form'
import SuccessMessage from './success-message'

type DataCollectionFormProps = {
  unlockNetworkFeature(): void
  showSuccessMessage: boolean
  setShowSuccessMessage(value: boolean): void
}

const DataCollectionForm = ({
  unlockNetworkFeature,
  showSuccessMessage,
  setShowSuccessMessage,
}: DataCollectionFormProps) => {
  const onCompletedForm = () => setShowSuccessMessage(true)
  return (
    <FormContainer>
      {showSuccessMessage ? (
        <SuccessMessage unlockNetworkFeature={unlockNetworkFeature} />
      ) : (
        <Form onCompletedForm={onCompletedForm} />
      )}
    </FormContainer>
  )
}

const FormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  margin: 20px 0px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 24px 38px 3px rgba(0, 0, 0, 0.14);
  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`

export default DataCollectionForm
