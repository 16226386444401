import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Radium, { StyleRoot } from 'radium'
import Style from 'style/index.js'
import { t } from 'shared-js/i18n'
import { ANALYTICS_EVENTS } from 'core/constants'

import { Form, TextInput, SubmitButton } from 'components/common/form-deprecated'
import { ClickOutsideContainer } from 'components/common/click-outside-container'

import './universal-search-bar.css'

const styles = {
  form: {
    margin: 0,
    display: 'flex',
  },
  searchInput: {
    height: 'auto',
    borderWidth: '1px',
    borderColor: '#d1d5db',
    borderRadius: '16px',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'inherit',
    padding: '6px 40px 6px 16px',

    container: {
      margin: 0,
      width: '100%',
    },
  },
  buttonContainer: {
    position: 'absolute',
    right: '1px',
    top: '1px',
  },
  button: {
    backgroundColor: '#f9fafb',
    color: '#6b7280',
    borderRadius: '0 16px 16px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
    width: '36px',
    padding: '0 15px',
    margin: 0,
    fontSize: '12px',
    transition: '',

    ':hover': {
      backgroundColor: '#e5e7eb',
      color: '#374151',
    },
  },
}

export class SearchBarContainer extends React.Component {
  // I had to create this container as wrapping <NavBar/> with 'StyleRoot' in the App component didn't work
  render() {
    return (
      <StyleRoot>
        <SearchBar
          style={this.props.style}
          onChange={this.props.onChange}
          onSearch={this.props.onSearch}
        />
      </StyleRoot>
    )
  }
}

@Radium
export class SearchBar extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { search: '', initialValue: 'search' }
    this.searchInput = React.createRef()
    this.searchForm = React.createRef()
  }

  onChange = (event) => {
    this.setState({ search: event.target.value, initialValue: '' })
  }

  onSearch = (event) => {
    const userInput = this.state.search
    if (userInput === '') {
      return
    }
    analytics.track(ANALYTICS_EVENTS.EXECUTED_SEARCH, { Search: userInput })
    let path = `/views/search?q=${userInput}`
    if (this.context.location.pathname.startsWith('/views/search/')) {
      const pathSplit = this.context.location.pathname.split('/')
      const tab =
        pathSplit[pathSplit.length - 1] === ''
          ? pathSplit[pathSplit.length - 2]
          : pathSplit[pathSplit.length - 1]
      path = `/views/search?q=${userInput}&content=${tab}`
    }
    this.context.router.push(path)
    // Close hamburger menu on search
    if (this.props.onSearch) {
      this.props.onSearch()
    }
    this.searchInput.current.reset()
    this.setState({ search: '', initialValue: 'search' })
  }

  onUnfocus = () => {
    this.setState({ initialValue: 'search' })
  }

  render() {
    return (
      <SearchContainer>
        <Form onSubmitAndValid={this.onSearch} style={styles.form}>
          <ClickOutsideContainer onClickOutside={this.onUnfocus} style={{ width: '100%' }}>
            <TextInput
              className="searchbar-Y8z9Wqn7ftqr97Se"
              initialValue={`${t(this.state.initialValue)}...`}
              onChange={this.onChange}
              style={styles.searchInput}
              ref={this.searchInput}
            />
          </ClickOutsideContainer>
          <div style={styles.buttonContainer}>
            <SubmitButton
              text={<i className="search icon" style={{ margin: 0 }} />}
              style={styles.button}
            />
          </div>
        </Form>
      </SearchContainer>
    )
  }
}

const SearchContainer = styled.div`
  width: 100%;
  justify-content: center;
  margin: 0px 15px 0px 0px;
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    right: 0;
    transform: none;
    margin: 0 auto 10px auto;
  }
`
