import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import reactMixin from 'react-mixin'

import Style from 'style'
import { t } from 'i18n'

import containerUtils from 'utilities/containers'
import createPaginatedStateContainer from 'state/pagination'

import ChannelSharesState from 'state/channel-shares'
import ChannelShareRequestsState from 'state/channel-share-requests'
import ChannelsState from 'state/channels'

import { Modal } from 'components/common/modals/index.jsx'
import { GatedFeatureBox, CONTENT_SHARING } from 'components/common/gated-feature'
import { LoadingContainer, NoData } from 'components/common/loading'
import { remoteSearchMixinFactory } from 'components/common/search'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import { PrimaryButton } from 'components/common/buttons'
import BLUR_IMAGE from 'img/channel-connect-blur.jpg'
import { ConnectionCard } from './card-connection'
import { RequestCard } from './card-request'
import PageState from './state'
import { ConnectionModal } from '../../../common/create-connection-modal'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  connectionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
}

@reactMixin.decorate(remoteSearchMixinFactory(PageState.ActionCreators.setSearch))
class ConnectionsPage extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  render() {
    const user = this.context.currentUser
    const company = user.company
    const enableSharedContent = company.subscription.shared_content_enabled

    if (!enableSharedContent) {
      return (
        <GatedFeatureBox
          backgroundImage={BLUR_IMAGE}
          hideContent
          headerText={t('upgrade_to_pro_share_content')}
          descriptionText={t('share_content_desc')}
          featureType={CONTENT_SHARING}
        />
      )
    }
    return (
      <div style={styles.container}>
        <div style={styles.searchContainer}>
          <div>
            <PrimaryButton onClick={() => this.createConnectionModal.show()}>
              {t('create_connection')}
            </PrimaryButton>
          </div>
          {this.getSearchInput({
            borderless: true,
          })}
        </div>

        {this.props.requests && (
          <div style={styles.connectionsContainer}>
            {this.props.requests.map((r) => (
              <RequestCard request={r} key={r.id} />
            ))}
          </div>
        )}

        <LoadingContainer
          loadingProps={[this.props.connections]}
          createComponent={(props) => (
            <InfiniteScroll
              loadMore={this.props.loadMore}
              moreAvailable={this.props.moreAvailable}
              isLoading={this.props.isLoading}
            >
              <div style={styles.connectionsContainer}>
                {this.props.connections.map((c) => (
                  <ConnectionCard connection={c} key={c.id} channel={this.props.channel} />
                ))}
              </div>
            </InfiniteScroll>
          )}
          createNoDataComponent={() => <NoData>{t('there_are_no_channel_connections')}</NoData>}
        />
        <Modal ref={(c) => (this.createConnectionModal = c)} header={t('create_connection')}>
          <ConnectionModal
            onConnectionsCreated={() => this.createConnectionModal.hide()}
            channel={this.props.channel}
          />
        </Modal>
      </div>
    )
  }
}

export const Page = createPaginatedStateContainer(ConnectionsPage, {
  mixins: [],

  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [ChannelSharesState.Store, ChannelShareRequestsState.Store, PageState.Store],

  paginate: {
    store: ChannelSharesState.Store,
    propName: 'connections',
    limit: 36,
    getQuery() {
      const q = {
        involving_company: this.context.currentUser.company.id,
        fields: [
          'id',
          'company.name',
          'company.company_logo',
          'company.id',
          'company.user_count',
        ],
        ordering: 'company',
        training_unit: this.props.channel.id,
      }
      const search = PageState.Store.getSearch()
      if (search) {
        q.search = search
        q.ordering = '-search_rank'
      }
      return q
    },
  },

  fetch: {
    requests() {
      const q = {
        training_unit: this.props.channel.id,
        pending: true,
        fields: [
          'id',
          'direction',
          'company.name',
          'company.company_logo',
          'company.id',
          'company.user_count',
        ],
        ordering: '-id',
        limit: 0,
      }

      const search = PageState.Store.getSearch()
      if (search) {
        q.search = search
        q.ordering = '-search_rank'
      }

      return ChannelShareRequestsState.Store.getItems(q)
    },
  },

  componentDidMount() {
    PageState.Store.resetState()
  },

  pending() {
    return containerUtils.defaultPending(this, ConnectionsPage)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, ConnectionsPage, errors)
  },
})
