import { PrimaryButton, SecondaryButton } from 'components/common/buttons'
import { LoadingContainer } from 'components/common/loading'
import { arrayMove, ReorderButton, SortableContainer } from 'components/common/ordering'
import { SortableLessonCard } from 'components/content-management/lesson/lesson-card'
import { t } from 'i18n'
import _ from 'lodash'
import Marty from 'marty'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import CompaniesState from 'state/companies'
import ModuleTrainingPlansState from 'state/module-training-plans'
import ModulesState from 'state/modules'
import TrainingPlansState from 'state/training-plans'
import containerUtils from 'utilities/containers'
import { isMobileWidth } from 'utilities/generic'
import { momentToISO } from 'utilities/time'
import $y from 'utilities/yaler'
import { AddExistingLessonModal } from './add-existing-lesson-modal'
import { AddLessonModal } from './add-lesson-modal'
import { AddNewLessonModal } from './add-new-lesson-modal'

const MOBILE_LAYOUT = '@media screen and (max-width: 480px)'

const styles = {
  planDetailsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  addLessonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  addLessonBtn: {
    float: 'right',
  },
  addLessonCard: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  sortableContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 -20px',
  },
}

@SortableContainer
export class LessonCardCollection extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
  }

  addLessonToPlan = () => {
    this.props.addLessonToPlan()
  }

  hideModal = () => {
    this.refs.selectLessonModal.hide()
  }

  removeConfirmClick = (lesson) => {
    ModuleTrainingPlansState.ActionCreators.doListAction('delete_for_module_and_plan', {
      module: lesson.url,
      training_plan: this.props.plan.url,
    }).then((res) => {
      this.context.displayTempPositiveMessage({
        heading: 'changes_saved',
      })
    })
  }

  archiveConfirmClick = (lesson) => {
    if (lesson.deactivated !== null) return
    const now = momentToISO(moment())
    ModulesState.ActionCreators.update(lesson.id, {
      deactivated: now,
    }).then((res) => {
      this.context.displayTempPositiveMessage({
        heading: 'Lesson archived',
      })
    })
  }

  render() {
    return (
      <div style={styles.planDetailsContainer}>
        <div style={{ width: '100%' }}>
          <div style={styles.sortableContainer}>
            {this.props.lessons.map((module, idx) => (
              <SortableLessonCard
                key={module.id}
                index={idx}
                lesson={Im.freeze(module)}
                highlight={this.props.reorderEnabled}
                currentUser={this.props.currentUser}
                plan={Im.freeze(this.props.plan)}
                onTransition={_.noop}
                archiveConfirmClick={this.archiveConfirmClick}
                removeConfirmClick={this.removeConfirmClick}
                editable={!this.props.reorderEnabled}
                showArchive={this.props.showArchive}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export class LessonsContainer extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super()
    this.state = {
      sortedModuleTrainingPlans: props.moduleTrainingPlans,
      reorderEnabled: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.moduleTrainingPlans && !this.state.reorderEnabled) {
      if (
        !this.state.sortedModuleTrainingPlans ||
        nextProps.moduleTrainingPlans !== this.state.sortedModuleTrainingPlans
      ) {
        this.setState({
          sortedModuleTrainingPlans: nextProps.moduleTrainingPlans,
        })
      }
    }
    // Forcing a refetch here to ensure state reflects changes in Module
    ModuleTrainingPlansState.ActionCreators.resetLocalData()
  }

  addLessonToPlan = () => {
    // If company doesn't have any lessons, no need to show addOrCreateLessonModal
    const hasContent = this.props.currentUser.company.has_content
    if (hasContent) {
      this.refs.addOrCreateLessonModal.show()
    } else {
      this.refs.newLessonModal.show()
    }
  }

  createNewLesson = () => {
    this.refs.addOrCreateLessonModal.hide()
    this.refs.newLessonModal.show()
  }

  addExistingLesson = () => {
    this.refs.addOrCreateLessonModal.hide()
    this.refs.selectLessonModal.show()
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      sortedModuleTrainingPlans: Im.freeze(
        arrayMove(this.state.sortedModuleTrainingPlans, oldIndex, newIndex)
      ),
    })
    _.defer(this.saveSorting)
  }

  saveSorting = () => {
    this.state.sortedModuleTrainingPlans.map((mtp, idx) => {
      if (mtp.order !== idx) {
        ModuleTrainingPlansState.ActionCreators.update(mtp.id, {
          order: idx,
        })
      }
    })
  }

  toggleReorder = () => {
    this.setState({ reorderEnabled: !this.state.reorderEnabled })
  }

  render() {
    const { company } = this.props.currentUser
    const hasContent = company.has_content
    const { showArchivedLessons } = this.props
    return (
      <div>
        {this.props.userCanEditPlan && (
          <div style={styles.addLessonContainer}>
            {this.props.moduleTrainingPlans && this.props.moduleTrainingPlans.length > 1 && (
              <ReorderButton
                reorderEnabled={this.state.reorderEnabled}
                toggleReorder={this.toggleReorder}
                entity="lessons"
                containerStyle={{
                  display: isMobileWidth() ? 'flex' : 'inital',
                }}
              />
            )}
            {hasContent && (
              <SecondaryButton onClick={this.props.showArchive}>
                <i className="ui icon archive" />
                {showArchivedLessons ? t('hide_archived') : t('view_archived')}
              </SecondaryButton>
            )}
            <PrimaryButton onClick={this.addLessonToPlan} style={styles.addLessonBtn}>
              <i className="add circle icon" />
              {t('add_lesson')}
            </PrimaryButton>
          </div>
        )}
        <LoadingContainer
          loadingProps={[this.state.sortedModuleTrainingPlans]}
          createComponent={(props) => (
            <LessonCardCollection
              {...this.props}
              onSortEnd={this.onSortEnd}
              shouldCancelStart={() => !this.state.reorderEnabled}
              reorderEnabled={this.state.reorderEnabled}
              axis="xy"
              lessons={this.state.sortedModuleTrainingPlans.map((mtp) => Im.freeze(mtp.module))}
              currentPage={this.props.currentPage}
              numAvailablePages={this.props.numAvailablePages}
              goToPage={this.props.goToPage}
              addLessonToPlan={this.addLessonToPlan}
            />
          )}
          createNoDataComponent={(props) => (
            <div style={styles.planDetailsContainer}>
              <span style={{ fontSize: 15, paddingTop: 25, paddingBottom: 25 }}>
                {showArchivedLessons
                  ? t('there_are_no_archived_lessons')
                  : t('creat_first_lesson_desc')}
              </span>
              {!showArchivedLessons && (
                <div>
                  {this.props.userCanEditPlan ? (
                    <div>
                      &nbsp;
                      <u style={{ cursor: 'pointer' }} onClick={this.addLessonToPlan} />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        />

        <AddLessonModal
          ref="addOrCreateLessonModal"
          createNewLesson={this.createNewLesson}
          addExistingLesson={this.addExistingLesson}
          plan={this.props.plan}
        />

        <AddNewLessonModal
          ref="newLessonModal"
          plan={this.props.plan}
          currentUser={this.props.currentUser}
        />

        <AddExistingLessonModal
          trainingPlan={this.props.plan}
          ref="selectLessonModal"
          hide={this.hideModal}
          currentUser={this.props.currentUser}
          createNewModule={this.createNewModule}
          onModuleUpdate={this.hideModal}
        />
      </div>
    )
  }
}

export const LessonSection = Marty.createContainer(LessonsContainer, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
  },
  listenTo: [
    TrainingPlansState.Store,
    CompaniesState.Store,
    ModulesState.Store,
    ModuleTrainingPlansState.Store,
  ],
  fetch: {
    moduleTrainingPlans() {
      const query = {
        limit: 0,
        ordering: 'order,module__name',
        training_plan: this.props.plan.id,
        module__deactivated__isnull: true,
        fields: ['order', $y.getFields(SortableLessonCard, 'lesson', 'module')],
      }
      if (this.props.showArchivedLessons) {
        delete query.ordering
        query.module__deactivated__isnull = false
      }
      return ModuleTrainingPlansState.Store.getItems(query, {
        dependantOn: ModulesState.Store,
      })
    },
  },

  pending() {
    return containerUtils.defaultPending(this, LessonsContainer)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, LessonsContainer, errors)
  },
})
