import {
  FieldHeader,
  Form,
  SlideToggle,
  SubmitButton,
  TextInput,
} from 'components/common/form-deprecated'
import { NeutralMessage } from 'components/common/message'
import { Modal } from 'components/common/modals/index'
import { DeprecatedScrollableDataTable } from 'components/common/table'
import { SHARELINK_BLACKLIST } from 'core/constants'
import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import LinksState from 'state/links'
import Style from 'style'

const SHARELINK_URL = 'https://myagi.com/s/'

const styles = {
  noChanges: {
    color: Style.vars.deprecatedColors.xDarkGrey,
    margin: '10px 0 0 0',
    width: '100%',
    display: 'inline-block',
    textAlign: 'center',
  },
}

export class SharelinkModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedChannels: this.props.sharelink ? this.props.sharelink.channels.map((c) => c.url) : [],
      possibleInitialChannels: this.props.sharelink
        ? this.props.sharelink.channels.map((c) => ({
            value: c.url,
            label: c.name,
          }))
        : [],
      initialChannel:
        this.props.sharelink && this.props.sharelink.initial_channel
          ? this.props.sharelink.initial_channel.url
          : 'set to default',
      shortName: this.props.sharelink ? this.props.sharelink.name : '',
      showError: false,
      errorText: '',
      editing: false,
    }
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  show = () => {
    // disabling submit button when editing sharelink but no changes have been made yet
    if (this.state.shortName) {
      this.refs.modal.show()
      this.setState({ editing: true })
    }
    this.refs.modal.show()
  }

  hide = () => {
    this.setState({
      selectedChannels: [],
      possibleInitialChannels: [],
      initialChannel: null,
      editing: false,
    })
    this.refs.modal.hide()
  }

  onNameChange = (e) => {
    const name = e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/gi, '')
    this.setState({
      shortName: name,
    })
  }

  showNameTakenError = () => {
    this.setState({
      showError: true,
      errorText: t('sharelink_name_taken'),
    })
  }

  showInvalidChannelError = () => {
    this.setState({
      showError: true,
      errorText: t('channel_remix_plan_error'),
    })
  }

  onSubmitAndValid = (data) => {
    this.setState({ showError: false })
    if (this.state.selectedChannels.length) {
      const name = data.shortName
        .toLowerCase()
        .trim()
        .replace(/[^A-Z0-9]/gi, '')
      if (SHARELINK_BLACKLIST.indexOf(name) > -1) {
        this.showNameTakenError()
        return
      }

      // only way to get the value of select to be 'set to default' as having the state = null does not work
      const initialChannel =
        this.state.initialChannel === 'set to default' ? null : this.state.initialChannel

      const saveData = {
        name,
        channels: this.state.selectedChannels,
        initial_channel: initialChannel,
        company: this.context.currentUser.company.url,
      }

      const promise = this.props.sharelink
        ? LinksState.ActionCreators.update(this.props.sharelink.id, saveData)
        : LinksState.ActionCreators.create(saveData)

      promise
        .then((res) => {
          // Reset state
          this.setState({
            selectedChannels: [],
            initialChannel: null,
            shortName: '',
          })
          this.hide()
        })
        .catch((err) => {
          if (err.response.body.channels) {
            this.showInvalidChannelError()
          } else if (err.response.body.name) {
            this.showNameTakenError()
          }
        })
    } else {
      this.setState({ showError: true })
    }
  }

  onToggle = (channelName, channelUrl) => {
    if (_.includes(this.state.selectedChannels, channelUrl)) {
      const index = this.state.selectedChannels.indexOf(channelUrl)
      const newState = {
        selectedChannels: _.filter(
          this.state.selectedChannels,
          (_, channelUrl) => channelUrl !== index
        ),
        possibleInitialChannels: _.filter(
          this.state.possibleInitialChannels,
          (_, channelUrl) => channelUrl !== index
        ),
      }
      if (this.state.initialChannel === channelUrl) {
        this.setState({
          ...newState,
          initialChannel: 'set to default',
        })
      }
      this.setState(newState)
    } else {
      this.setState({
        selectedChannels: [...this.state.selectedChannels, channelUrl],
        possibleInitialChannels: [
          ...this.state.possibleInitialChannels,
          { value: channelUrl, label: channelName },
        ],
      })
    }
  }

  static tableDataMapping = {
    channel: (x) => x.name,
    '': (x, cxt) => (
      <SlideToggle
        initialValue={
          cxt.props.sharelink
            ? cxt.props.sharelink.channels.findIndex((c) => c.id === x.id) != -1
            : false
        }
        onChange={function() {
          cxt.onToggle(x.name, x.url)
        }}
      />
    ),
  }

  getDataMapping() {
    const mapping = SharelinkModal.tableDataMapping
    return mapping
  }

  getHeaders() {
    return Im.freeze(_.keys(this.getDataMapping()))
  }

  getRows() {
    const funcs = _.values(this.getDataMapping())
    const channels = this.props.channels
    if (channels) {
      return channels.map((x) => Im.freeze(funcs.map((f) => f(x, this))))
    }
    // empty list to satisfy prop types
    return Im.freeze([])
  }

  onInitialChannelSelect = (e) => {
    this.setState({
      initialChannel: e.target.value,
    })
  }

  onHideModal = () => {
    this.setState({
      selectedChannels: this.props.sharelink ? this.props.sharelink.channels.map((c) => c.url) : [],
      possibleInitialChannels: this.props.sharelink
        ? this.props.sharelink.channels.map((c) => ({
            value: c.url,
            label: c.name,
          }))
        : [],
      initialChannel: this.props.sharelink ? this.props.sharelink.initial_channel : null,
      shortName: this.props.sharelink ? this.props.sharelink.name : '',
      editing: false,
    })
  }

  checkDisable = () => {
    if (this.state.editing) {
      const channelsChanged = this.props.sharelink
        ? _.isEqual(
            this.props.sharelink.channels.map((c) => c.url),
            this.state.selectedChannels
          )
        : false
      const nameChanged = this.props.sharelink
        ? this.props.sharelink.name === this.state.shortName
        : false
      const initialChannel =
        this.props.sharelink &&
        this.props.sharelink.initial_channel &&
        this.props.sharelink.initial_channel.url
      const initialChannelChanged = initialChannel
        ? _.isEqual(initialChannel, this.state.initialChannel)
        : false
      const checkEdited =
        channelsChanged &&
        nameChanged &&
        (initialChannelChanged ||
          (this.props.sharelink.initial_channel === null &&
            this.state.initialChannel === 'set to default'))
      return this.state.selectedChannels.length === 0 || !this.state.shortName || checkEdited
    }
    return this.state.selectedChannels.length === 0 || !this.state.shortName
  }

  render() {
    const headers = this.getHeaders()
    const rows = this.getRows()
    const header = this.props.sharelink ? t('edit_sharelink') : t('create_sharelink')
    return (
      <Modal ref="modal" closeOnDimmerClick header={header} onHidden={this.onHideModal}>
        <div className="content">
          <NeutralMessage>
            <p>{`${t('sharelinks_info')}`}</p>
          </NeutralMessage>
          <Form onSubmitAndValid={this.onSubmitAndValid}>
            <FieldHeader required>{`${t('name_your_sharelink')}`}</FieldHeader>
            <TextInput
              name="shortName"
              required
              initialIsAcceptable
              initialValue={this.state.shortName}
              onChange={this.onNameChange}
              placeholder={t('enter_a_name')}
              maxLength={30}
            />
            <div className="ui center aligned segment">
              <p>{`${SHARELINK_URL}${this.state.shortName}`}</p>
            </div>

            <FieldHeader required>{`${t('select_channels')}`}</FieldHeader>
            <DeprecatedScrollableDataTable
              name="channels"
              headers={headers}
              rows={rows}
              bodyHeight={null}
              reformatForMobile={false}
            />
            <FieldHeader explanation={t('select_initial_channel_info')}>
              {`${t('select_initial_channel')}`}
            </FieldHeader>
            {/* Couldn't use the DropdownSelect select component as I cannot reset on componentDidUpdate in the jQueryMixin without breaking the component elsewhere */}
            <div className="field">
              <span>
                <select
                  className="ui dropdown"
                  id="dropdown"
                  value={this.state.initialChannel}
                  onChange={this.onInitialChannelSelect}
                >
                  {this.state.possibleInitialChannels.map((opt, i) => (
                    <option key={i} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                  <option value="set to default" style={{ color: '#ccc' }}>
                    {t('set_default')}
                  </option>
                </select>
              </span>
            </div>
            {this.state.showError && (
              <span style={{ color: 'red' }}>
                {this.state.errorText || `${t('please_select_channel')}`}
              </span>
            )}
            <SubmitButton text={t('create')} disabled={this.checkDisable()} />
          </Form>
        </div>
      </Modal>
    )
  }
}
