import React, { useContext, useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { getIdFromApiUrl } from 'utilities/generic'
import { Panel, BoxHeader, BoxContent } from 'components/common/box'
import { CompanySearchableSelect } from 'components/common/company-searchable-select'
import gql from 'fraql'
import { CommonRouterContext } from 'shared-js/context'
import { LoadingSpinner } from '../../common/loading'
import { ConnectionsTable } from './connections-table'
import { CSVUploader } from './uploader'
import { CompanyReportDetails } from './details'

const POLL_TIME = 2000
const IN_PROGRESS = 'MATCHING_IN_PROGRESS'

const styles = {
  search: {
    width: 500,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadText: {
    marginTop: 10,
    marginLeft: 20,
  },
  spinner: {
    marginTop: -30,
  },
}

function CompanySearch(props) {
  const { handlers } = useContext(CommonRouterContext)
  const onCompanySelect = useCallback(
    (val) => {
      if (!val) return
      const id = getIdFromApiUrl(val)
      handlers.goToNetworkReport(id)
    },
    [handlers]
  )

  return (
    <div style={styles.search}>
      <CompanySearchableSelect onChange={onCompanySelect} internalUse />
    </div>
  )
}

function LoadingBox() {
  const adminUrl = 'https://myagi.com/admin/reporting/networkreport/'
  return (
    <div style={styles.loadingContainer}>
      <LoadingSpinner containerStyle={styles.spinner} />
      <div style={styles.loadText}>
        Matching in progress... This process can take up to 5 minutes.
        <br />
        <br />
        If this is taking too long and you think there is an error, visit this page and delete the
        report to start over:
        <br />
        <br />
        <a href={adminUrl} target="_blank">
          {adminUrl}
        </a>
      </div>
    </div>
  )
}

class Container extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      companyId: props.companyId,
    }
  }

  render() {
    const { companyId } = this.state
    const { report, polling } = this.props
    return (
      <Panel>
        <BoxHeader>
          <h1>Search for a company and upload their dealer list</h1>
          <h4>(If you select a brand, it will only try to match with retailers, and vice versa)</h4>
        </BoxHeader>
        <BoxContent>
          <CompanySearch />
          {companyId && (
            <React.Fragment>
              {report && report.status === IN_PROGRESS ? (
                <LoadingBox />
              ) : (
                <CSVUploader {...this.props} />
              )}
              <CompanyReportDetails report={report} />
              {report && report.status !== IN_PROGRESS && (
                <ConnectionsTable
                  supplierId={companyId}
                  report={report}
                  data={report.rawOutputData}
                  polling={polling}
                />
              )}
            </React.Fragment>
          )}
        </BoxContent>
      </Panel>
    )
  }
}

export const FETCH_REPORT = gql`
  query NetworkReport($orderBy: [String]!, $companyId: ID) {
    NetworkReport__Item: NetworkReport__List(first: 1, orderBy: $orderBy, company: $companyId) {
      edges {
        node {
          id
          status
          inputFile
          outputFile
          rawOutputData
          company {
            id
            name
            companyType
            regionOfResponsibility
          }
        }
      }
    }
  }
`

export function Page() {
  const { routeParams } = useContext(CommonRouterContext)
  const { companyId } = routeParams
  const variables = { orderBy: '-id' }
  if (companyId) {
    variables.companyId = companyId
  }

  const { data, error, loading, startPolling, stopPolling } = useQuery(FETCH_REPORT, {
    variables,
  })

  if (loading && !data) return <span>Loading...</span>

  const reportItems = data.NetworkReport__Item.edges
  const report = reportItems.length ? reportItems[0].node : null
  if (report && report.status === IN_PROGRESS) {
    startPolling(POLL_TIME)
  } else {
    stopPolling()
  }
  return <Container companyId={companyId} report={report} polling={{ startPolling, stopPolling }} />
}
