import Marty from 'marty'
import app from 'core/application'

const Constants = Marty.createConstants(['COMPLETE_FOR_USER_SEARCH'])

class CompleteForUserActionCreators extends Marty.ActionCreators {
  setSearch = (str) => this.dispatch(Constants.COMPLETE_FOR_USER_SEARCH, str)
}

class CompleteForUserStore extends Marty.Store {
  constructor(opts) {
    super(opts)
    this.handlers = {
      onSetSearch: Constants.COMPLETE_FOR_USER_SEARCH,
    }
    this.resetState()
  }

  resetState() {
    const state = {
      search: '',
    }
    this.setState(state)
    this.hasChanged()
  }

  onSetSearch(search) {
    this.setState({ search })
    this.hasChanged()
  }

  getSearch() {
    return this.state.search
  }
}

app.register('CompleteForUserStore', CompleteForUserStore)
app.register('CompleteForUserActionCreators', CompleteForUserActionCreators)

export default {
  Store: app.CompleteForUserStore,
  ActionCreators: app.CompleteForUserActionCreators,
}
