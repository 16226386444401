import { Form, SubmitButton, TextInput } from 'components/common/form-deprecated'

import { ANALYTICS_EVENTS } from 'core/constants'
import { LoadingSpinner } from 'components/common/loading'
import { Modal } from 'components/common/modals'
import ModuleState from 'state/modules'
import { PrimaryButton } from 'components/common/buttons'
import PropTypes from 'prop-types'
import React from 'react'
import { resolve } from 'utilities/deprecated-named-routes'
import { t } from 'i18n'

const styles = {
  container: {
    padding: '25',
  },
  button: {
    display: 'block',
    width: 100,
    marginLeft: 0,
  },
  spinner: {
    height: 30,
    width: 100,
  },
  header: {
    borderBottom: 'none',
    paddingBottom: 0,
    fontSize: 24,
  },
}

export class AddNewLessonForm extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      error: false,
      submitted: false,
    }
  }

  componentDidMount() {
    if (this.input) this.input.focus()
  }

  createLesson = () => {
    const name = this.input.refs.input.value
    if (name && name.length > 0) {
      this.setState({ ...this.state, error: false, submitted: true })
      const data = {
        name,
        description: '',
        training_plans: [this.props.plan.url],
        company: this.context.currentUser.company.url,
      }

      ModuleState.ActionCreators.create(data).then((res) => {
        this.props.hide()
        analytics.track(ANALYTICS_EVENTS.START_MODULE_CREATION, {
          lessonId: res.body.id,
          lessonName: name,
        })
        this.context.router.push(resolve('lesson-management', { lessonId: res.body.id }))
      })
    } else {
      this.setState({ ...this.state, error: true, submitting: false })
    }
  }

  render() {
    return (
      <Form onSubmitAndValid={this.createLesson}>
        <p>{t('create_new_lesson_modal_content')}</p>
        <TextInput
          type="text"
          ref={(c) => (this.input = c)}
          placeholder={t('lesson_name_example')}
        />
        {this.state.error && (
          <div className="ui negative message">{t('please_enter_a_valid_name')}</div>
        )}
        {this.state.submitted ? (
          <LoadingSpinner containerStyle={styles.spinner} />
        ) : (
          <SubmitButton style={styles.button} text={t('next')} />
        )}
      </Form>
    )
  }
}

export class AddNewLessonModal extends React.Component {
  show() {
    this.addLessonModal.show()
  }

  render() {
    return (
      <Modal
        ref={(m) => (this.addLessonModal = m)}
        header={t('create_new_lesson_modal_header')}
        headerStyle={styles.header}
      >
        <AddNewLessonForm {...this.props} hide={() => this.addLessonModal.hide()} />
      </Modal>
    )
  }
}
