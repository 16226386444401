/* eslint-disable react/jsx-no-literals */
import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'

import containerUtils from 'utilities/containers'
import { getIdFromApiUrl } from 'utilities/generic'
import { LoadingContainer } from 'components/common/loading'
import { Box, BoxHeader, BoxContent } from 'components/common/box'
import Style from 'style'

import UsersState from 'state/users'

import { MAIN_DOMAIN } from 'core/constants'
import { Page as UserDetails } from './user-details'
import { UserSearchableSelect } from './common'

class UserManagementTool extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.instanceOf(Im.Map).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      userId: null,
    }
  }

  UNSAFE_componentWillMount() {
    // The `move_to` and `next` are convenient ways to allow the creation of links
    // which move admin / staff users between accounts. In particular, this is used
    // by Metabase questions to simplify the process of company connection creation.
    const newCo = this.context.location.query ? this.context.location.query.move_to : null
    const nxt = this.context.location.query ? this.context.location.query.next : null
    if (newCo) {
      const origin = window.location.origin
      const coURL = `${origin}/api/v1/companies/${newCo}/`
      UsersState.ActionCreators.doDetailAction(
        this.props.currentUser.id,
        'change_user_to_new_company',
        {
          company_url: coURL,
        }
      ).then(() => {
        if (nxt) this.context.router.push(nxt)
      })
    }
  }

  transitionToUser = (id) => {
    this.context.router.push({
      pathname: `/views/myagi-staff/user-management/${id}/`,
    })
  }

  onUserSelect = (val) => {
    if (val) {
      const id = getIdFromApiUrl(val)
      this.transitionToUser(id)
    }
  }

  setYourself = () => {
    this.transitionToUser(this.context.currentUser.id)
  }

  resetYourself = () => {
    const origin = window.location.origin
    const hostname = window.location.hostname
    const currentSubdomain = hostname.split('.')[0]

    const userId = this.props.currentUser.id

    UsersState.ActionCreators.doDetailAction(userId, 'change_user_to_new_company', {
      company_url: `${origin}/api/v1/companies/6919/`,
    })
      .then(() => {
        const protocol = window.location.protocol

        if (process.env.NODE_ENV === 'production' && currentSubdomain !== 'staging') {
          window.location.href = `${protocol}//${MAIN_DOMAIN}/views/myagi-staff/user-management/${userId}/`
        }
      })
      .catch((err) => {
        console.log(err)
      })
    this.context.displayTempPositiveMessage({
      heading: 'Success',
      body: 'You have changed back to Myagi! Woo!',
    })
  }

  render() {
    return (
      <Box>
        <BoxHeader>
          <h1>USER MANAGEMENT TOOL</h1>
        </BoxHeader>
        <BoxContent>
          <button
            type="button"
            className="ui button"
            onClick={this.resetYourself}
            style={{
              background: Style.vars.deprecatedColors.primary,
              color: 'white',
            }}
          >
            <i className="icon pied piper alternate" style={{ fontSize: 20 }} />
            {' Move me back to Myagi Internal'}
          </button>
          <br />
          <h4>Search for a user</h4>
          <div style={{ display: 'flex', height: 50 }}>
            <UserSearchableSelect onChange={this.onUserSelect} />
            <button
              type="button"
              className="ui blue button"
              onClick={this.setYourself}
              style={{ marginLeft: 20, marginBottom: 14 }}
            >
              <i className="icon child" />
              {' Select Yourself'}
            </button>
          </div>
        </BoxContent>
        {this.props.children}
      </Box>
    )
  }
}

export const Page = Marty.createContainer(UserManagementTool, {
  listenTo: [UsersState.Store],

  fetch: {
    currentUser() {
      const fetch = UsersState.Store.getCurrent()
      return fetch
    },
  },

  pending() {
    return containerUtils.defaultPending(this, UserManagementTool)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, UserManagementTool, errors)
  },
})
