import Marty from 'marty'
import _ from 'lodash'

import app from 'core/application'

export const ALL_CHANNELS = 'all_channels'
export const YOUR_CHANNELS = 'your_channels'
export const EXTERNAL_CHANNELS = 'external_channels'

const STD_ORDER = 'order,name'

const Constants = Marty.createConstants([
  'CHANNEL_LIST_SET_CHANNEL_SEARCH',
  'CHANNEL_LIST_SET_FILTER',
  'CHANNEL_LIST_SET_CHANNEL_ORDER',
])

const ChannelListActionCreators = Marty.createActionCreators({
  id: 'ChannelListActionCreators',
  onSetFilter(filterName) {
    this.dispatch(Constants.CHANNEL_LIST_SET_FILTER, filterName)
  },
  onSetChannelSearch(searchTerm) {
    this.dispatch(Constants.CHANNEL_LIST_SET_CHANNEL_SEARCH, searchTerm)
  },
  setChannelOrder(str) {
    this.dispatch(Constants.CHANNEL_LIST_SET_CHANNEL_ORDER, str)
  },
})

const ChannelListStore = Marty.createStore({
  id: 'ChannelListStore',
  handlers: {
    onSetChannelSearch: Constants.CHANNEL_LIST_SET_CHANNEL_SEARCH,
    onFilter: Constants.CHANNEL_LIST_SET_FILTER,
    onSetChannelOrder: Constants.CHANNEL_LIST_SET_CHANNEL_ORDER,
  },
  getInitialState() {
    return {
      search: '',
      events: [],
    }
  },
  onFilter(key) {
    this.clearChannelFilter()
    this.state.filterKey = key
    this.hasChanged()
  },

  onSetChannelSearch(str) {
    // this.clearChannelFilter();
    this.state.search = str
    this.hasChanged()
  },

  onSetChannelOrder(order) {
    this.state.order = order
    this.hasChanged()
  },

  clearChannelFilter() {
    if (!this.state.planIds) return
    this.hasChanged()
  },

  getCurrentFilterQuery(currentUser, initialValue) {
    const allFilters = this.setupFiltersForUser(currentUser, initialValue)
    const filterDesc = _.find(allFilters, { name: this.state.filterKey })
    return filterDesc.query
  },

  getFilterNames() {
    return _.map(this.state.filterCache, 'name')
  },

  getCurrentFilter() {
    return this.state.filterKey
  },

  getFiltersForUser(currentUser) {
    const { company } = currentUser
    let filters = [
      {
        name: ALL_CHANNELS,
        query: {
          deactivated__isnull: true,
          ordering: STD_ORDER,
        },
      },
      {
        name: YOUR_CHANNELS,
        query: {
          deactivated__isnull: true,
          ordering: STD_ORDER,
          company: company.id,
        },
      },
      {
        name: EXTERNAL_CHANNELS,
        query: {
          deactivated__isnull: true,
          ordering: STD_ORDER,
          is_external: company.id,
        },
      },
      currentUser.learner.can_manage_training_content && {
        name: 'archived_channels',
        query: {
          show_deactivated: true,
          ordering: '-deactivated',
          company: company.id,
        },
      },
    ]
    filters = _.filter(filters, Boolean)
    return filters
  },

  setupFiltersForUser(currentUser, initialValue) {
    if (!this.state.filterCache) {
      this.state.filterCache = this.getFiltersForUser(currentUser)
      this.state.filterKey = initialValue || this.state.filterCache[0].name
    }
    return this.state.filterCache
  },

  getChannelSearch() {
    return this.state.search
  },

  getChannelOrder() {
    return this.state.order
  },

  resetState() {
    this.state = this.getInitialState()
  },
})

app.register('ChannelListStore', ChannelListStore)
app.register('ChannelListActionCreators', ChannelListActionCreators)

export default {
  Constants,
  ActionCreators: app.ChannelListActionCreators,
  Store: app.ChannelListStore,
}
