import CreatePostForm from 'shared-js/components/home/feed/create-post'
import Im from 'shared-js/immutable'
import { Info } from 'components/common/info'
import { LoadingContainer } from 'components/common/loading'
import { NeutralMessage } from 'components/common/message'
import { PostBodyPlanCard } from 'shared-js/components/home/feed/types/post'
import { PrimaryButton } from 'components/common/buttons'
import PropTypes from 'prop-types'
import React from 'react'
import Style from 'shared-js/style/native'
import _ from 'lodash'
import moment from 'moment-timezone'
import { t } from 'i18n'

export function PlanAnnouncement({ plan, style }, { currentUser }) {
  return (
    <div key={plan.last_announcement_date} style={{ ...style, ...styles.container }}>
      <CreateNewPost plan={plan} disabled={false} />
    </div>
  )
}
PlanAnnouncement.contextTypes = {
  currentUser: PropTypes.object.isRequired,
}

export function CreateNewPost(props, context) {
  const buttonStyle = {
    ...styles.btn,
    ...(props.plan.needs_announcement && btnHighlight),
  }
  const prevChannelRoute =
    context.location.query && context.location.query.prevChannel
      ? `?prevChannel=${context.location.query.prevChannel}`
      : ''
  const toRoute = canAnnounce(props.plan)
    ? `${window.location.pathname}announce/${prevChannelRoute}`
    : null
  return (
    <div style={styles.container} key={props.plan.last_announcement_date}>
      <PrimaryButton style={buttonStyle} disabled={!canAnnounce(props.plan)} to={toRoute}>
        {t('announce_to_users')}
        &nbsp;&nbsp;
        <i className="ui icon comment outline" />
      </PrimaryButton>
    </div>
  )
}
CreateNewPost.propTypes = {
  plan: PropTypes.object.isRequired,
}
CreateNewPost.contextTypes = {
  displayTempPositiveMessage: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export function CreateNewPostForm(props) {
  return (
    <LoadingContainer
      loadingProps={{ plan: props.plan }}
      createComponent={(innerProps) => (
        <CreateNewPostFormInner {...innerProps} onSubmit={props.onSubmit} />
      )}
    />
  )
}
CreateNewPostForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

function CreateNewPostFormInner(props, context) {
  const link = `${window.location.origin}/views/training/?plans=${props.plan.id}`
  const userCount =
    props.plan.owner.id === context.currentUser.company.id
      ? props.plan.num_enrolled_users
      : props.plan.num_enrolled_users_in_own_company
  const lastAnnounceDate = props.plan.last_announcement_date

  return (
    <div>
      <NeutralMessage>
        <div className="header">{t('announce_to_users')}</div>
        <div>
          {t('plan_announce_info')}
          {' '}
        </div>
        <br />
        <div>
          <b>{t('users_will_be_notified', { numUsers: userCount }, { pluralize: userCount })}</b>
          {lastAnnounceDate && (
            <div>
              <br />
              <b>
                {t('last_announcement_date', {
                  date: moment(lastAnnounceDate).format('MMMM Do YYYY, h:mm a'),
                })}
              </b>
            </div>
          )}
        </div>
      </NeutralMessage>
      <div style={styles.formContainer}>
        <CreatePostForm
          placeholder={t('tell_users_about_plan')}
          link={link}
          plan={props.plan}
          refreshData={props.onSubmit}
        />
        <div style={styles.postBodyContainer}>
          <PostBodyPlanCard plan={Im.freeze(props.plan)} currentUser={context.currentUser} />
        </div>
      </div>
    </div>
  )
}
CreateNewPostFormInner.contextTypes = {
  displayTempPositiveMessage: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
}

export function canAnnounce(plan) {
  const hasAttemptableLesson = _.some(plan.modules, 'is_attemptable')
  const belongsToChannel = plan.training_units.length
  const isPublished =
    plan.is_published && (plan.published_at ? !moment(plan.published_at).isAfter(moment()) : true)
  return isPublished && hasAttemptableLesson && belongsToChannel
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  formContainer: {
    marginTop: 20,
  },
  btn: {
    width: '100%',
    color: Style.vars.deprecatedColors.primaryFontColor,
  },
  postBodyContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tooltip: {
    wrapper: {
      width: '100%',
      zIndex: 2,
    },
  },
}

const btnHighlight = {
  boxShadow: `0px 0px 4px 4px ${Style.vars.deprecatedColors.fadedPrimary}`,
}
