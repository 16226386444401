import React, { useState } from 'react'
import { t } from 'shared-js/i18n'
import { useCommonRouter } from 'shared-js/routing'
import _ from 'lodash'
import styled from 'styled-components'
import { Modal } from 'components/common/modals'
import { Button, OutlinedButton } from 'ui-kit'
import {
  useBrandsFilter,
  useIndustryFilter,
} from 'components/content-management/discover-content/context'
import { FilterByConnectionMobile } from './filter-by-brand'
import { FilterByIndustryMobile } from './filter-by-industry'

type FilterModalProps = {
  innerRef: React.RefObject<Modal>
}

const MobileFilterModal = ({ innerRef }: FilterModalProps) => {
  const { handlers } = useCommonRouter()
  const { brandsFiltered, setBrandsFilterQueryString } = useBrandsFilter()
  const { industriesFiltered, setIndustryFilterQueryString } = useIndustryFilter()
  const [newBrandsFiltered, setNewBrandsFiltered] = useState(brandsFiltered)
  const [newIndustriesFiltered, setNewIndustriesFiltered] = useState(industriesFiltered)
  // we want to enable the apply button even if the changes leads to the same filters that was
  // set before as it'll be bad UX to suddenly disable the button
  const [changesMade, setChangesMade] = useState(false)

  const clearAllFilters = () => {
    setIndustryFilterQueryString('')
    setBrandsFilterQueryString('')
    setNewBrandsFiltered([])
    setNewIndustriesFiltered([])
    // fixes a bug that's hard to replicate where a filter remains in the query string
    handlers.goToDiscoverContent()
  }

  const updateBrandsFilter = (brandID: string) => {
    if (!changesMade) setChangesMade(true)
    if (newBrandsFiltered.includes(brandID)) {
      setNewBrandsFiltered(newBrandsFiltered.filter((filteredBrand) => filteredBrand !== brandID))
    } else {
      setNewBrandsFiltered([...newBrandsFiltered, brandID])
    }
  }

  const updateIndustriesFilter = (industryID: string) => {
    if (!changesMade) setChangesMade(true)
    if (newIndustriesFiltered.includes(industryID)) {
      setNewIndustriesFiltered(
        newIndustriesFiltered.filter((filteredIndustry) => filteredIndustry !== industryID)
      )
    } else {
      setNewIndustriesFiltered([...newIndustriesFiltered, industryID])
    }
  }

  const applyChanges = async () => {
    await setBrandsFilterQueryString(newBrandsFiltered.join(','))
    await setIndustryFilterQueryString(newIndustriesFiltered.join(','))
    if (innerRef.current) innerRef.current.hide()
  }

  const resetState = () => {
    setNewBrandsFiltered(brandsFiltered)
    setNewIndustriesFiltered(industriesFiltered)
    setChangesMade(false)
  }

  return (
    <Modal ref={innerRef} closeOnDimmerClick onHidden={resetState}>
      <Container>
        <Title>{t('filter_by')}</Title>
        <ButtonsContainer>
          <Button disabled={!changesMade} onClick={applyChanges}>
            {t('apply')}
          </Button>
          <OutlinedButton onClick={clearAllFilters}>{t('clear_all')}</OutlinedButton>
        </ButtonsContainer>
        <FiltersContainer>
          <FilterByConnectionMobile
            updateBrandsFilter={updateBrandsFilter}
            brandIDsFiltered={newBrandsFiltered}
          />
          <FilterByIndustryMobile
            updateIndustriesFilter={updateIndustriesFilter}
            industryIDsFiltered={newIndustriesFiltered}
          />
        </FiltersContainer>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  padding: 6px;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey900};
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 28px;
`

// there's a bug in our common modal component where it becomes narrow after a certain dimension
const FiltersContainer = styled.div`
  max-height: 50vh;
  overflow: auto;
`

export default MobileFilterModal
