import React from 'react'
import { SectionTitle, SectionTitleLoading } from 'components/common/title/section-title'
import styled from 'styled-components'

export const Title = ({ text }: { text: string }) => (
  <Container>
    <SectionTitle text={text} />
  </Container>
)

export const TitleLoading = () => (
  <Container>
    <SectionTitleLoading />
  </Container>
)

const Container = styled.div`
  grid-column: 1 / -1;
  margin: 30px 0px 0px;
`
