import React from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks'
import { blue } from '@material-ui/core/colors'
import SearchableAsyncSelect from './searchable-async'
import { convertDataToReadableFormat, getIds } from './utils'

const GET_TAGS = gql`
  query GetTags($category: String!, $company: ID!) {
    Tag__List(first: 100, category: $category, company: $company) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const GET_ALL_TAGS = gql`
  query GetAllTags($search: String, $orderBy: [String]) {
    Tag__List(first: 100, category: "general", search: $search, orderBy: $orderBy) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const UPDATE_TAGS = gql`
  mutation UpdateTags($id: ID!, $tagIds: [Int]!) {
    Company__Update(input: { id: $id, tags: $tagIds }) {
      result {
        id
        name
        tags(first: 100) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

const MultiTagAsyncSelect = ({ company }) => {
  const variables = {
    category: 'general',
    company: company ? company.id : null,
  }
  const { data, loading, error } = useQuery(GET_TAGS, { variables })
  const [updateTags] = useMutation(UPDATE_TAGS, {
    // Force refetch here to update cache
    refetchQueries: [
      {
        query: GET_TAGS,
        variables,
      },
    ],
  })

  if (!company || loading || error) return <React.Fragment />

  const onChange = (tags) => {
    updateTags({
      variables: { id: company.id, tagIds: getIds(tags) },
    })
  }

  return (
    <SearchableAsyncSelect
      query={GET_ALL_TAGS}
      defaultOptions={convertDataToReadableFormat(data.Tag__List.edges)}
      entityName="Tag__List"
      onChange={onChange}
      multi
    />
  )
}

export default MultiTagAsyncSelect
