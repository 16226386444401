import MUICollapse from '@material-ui/core/Collapse'
import MUIFade from '@material-ui/core/Fade'
import MUIGrow from '@material-ui/core/Grow'
import MUISlide from '@material-ui/core/Slide'
import MUIZoom from '@material-ui/core/Zoom'

export const Collapse = MUICollapse
export const Fade = MUIFade
export const Grow = MUIGrow
export const Slide = MUISlide
export const Zoom = MUIZoom
