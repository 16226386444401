import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, Transition } from 'react-transition-group'
import { reject } from 'lodash'
import Style from 'shared-js/style'

const NEGATIVE_MESSAGE = 'negative'
const POSITIVE_MESSAGE = 'positive'
const TIME_ONSCREEN = 5000
const INCORRECT_ARGUMENT_ERROR = `toastMessage accepts a heading and body argument, not an options 
object. See \`frontend/web/src/js/components/app/toast.jsx\``

/**
 * Web-app toast notification messges.
 *
 * @description
 * This is the toast message creator provided in `ToastMessageContext`.
 */
export const ToastMessages = forwardRef((props, ref) => {
  const [messages, setMessages] = useState([])
  const idRef = useRef(1)

  useImperativeHandle(ref, () => ({
    showPositive: showMessage(POSITIVE_MESSAGE),
    showNegative: showMessage(NEGATIVE_MESSAGE),
  }))

  const showMessage = (type) => (heading, body) => {
    if (typeof heading === 'object') console.error(INCORRECT_ARGUMENT_ERROR)
    const id = idRef.current++
    const message = { id, type, heading, body }

    setMessages((prevMessages) => [message, ...prevMessages])
    setTimeout(removeMessage, TIME_ONSCREEN, id)
  }

  const removeMessage = (id) => {
    setMessages((prevMessages) => reject(prevMessages, { id }))
  }

  return (
    <div style={styles.toastsContainer}>
      <TransitionGroup>
        {messages.map((message) => (
          <Transition key={message.id} timeout={{ enter: 0, exit: 500 }}>
            {(transitionState) => (
              <ToastMessage
                message={message}
                style={transitionStyles[transitionState]}
                onClick={() => removeMessage(message.id)}
              />
            )}
          </Transition>
        ))}
      </TransitionGroup>
    </div>
  )
})

function ToastMessage({ message, onClick, style }) {
  return (
    <div
      style={{
        ...styles.toastContainer,
        ...styles[message.type],
        ...style,
      }}
      onClick={onClick}
    >
      <p style={styles.heading}>{message.heading}</p>
      {message.body && <p style={styles.body}>{message.body}</p>}
    </div>
  )
}
ToastMessage.propTypes = {
  message: PropTypes.object,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

const styles = {
  toastsContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 99999,
  },
  toastContainer: {
    transition: 'all 500ms ease-in-out',
    margin: 3,
    color: 'white',
    padding: '15px 25px',
    borderRadius: 5,
    cursor: 'pointer',
    maxWidth: 300,
  },
  heading: {
    fontWeight: 'bold',
    margin: 0,
    padding: 0,
  },
  body: {
    margin: 0,
    marginTop: 8,
    padding: 0,
  },
  positive: {
    backgroundColor: Style.vars.deprecatedColors.positiveGreen,
  },
  negative: {
    backgroundColor: Style.vars.deprecatedColors.negativeRed,
  },
}

const transitionStyles = {
  entering: {
    opacity: 0,
    transform: 'translateY(10%)',
  },
  entered: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  exiting: {
    opacity: 0,
    transform: 'translateY(10%)',
  },
}
