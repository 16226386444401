import React from 'react'
import { t } from 'shared-js/i18n'
import styled from 'styled-components'
import { OutlinedButton } from 'ui-kit'
import { SectionTitle, SectionTitleIcon, SectionTitleContainer } from './common'

const SMALL_PHONE_WIDTH = 350

type SectionProps = {
  showSubscribedCompanies(): void
  numSubscribedCompanies: number
}

const ShareWithSpecificCompanies = ({
  showSubscribedCompanies,
  numSubscribedCompanies,
}: SectionProps) => {
  return (
    <SectionContainer>
      <SectionTitleContainer>
        <SectionTitleIcon iconName="location-city" />
        <SectionTitle>{t('share_with_specific_companies')}</SectionTitle>
      </SectionTitleContainer>
      <Info>{t('the_companies_you_share_this_channel_with_will_receive_a_notification')}</Info>
      {Boolean(numSubscribedCompanies) && (
        <ConnectedCount>
          {t(
            'shared_with_num_companies',
            { companiesCount: numSubscribedCompanies },
            { pluralize: numSubscribedCompanies }
          )}
        </ConnectedCount>
      )}
      <ButtonContainer>
        <OutlinedButton size="small" onClick={showSubscribedCompanies}>
          {numSubscribedCompanies ? t('edit_companies') : t('select_companies')}
        </OutlinedButton>
      </ButtonContainer>
    </SectionContainer>
  )
}

const SectionContainer = styled.div`
  padding-top: 24px;
`

const ButtonContainer = styled.div`
  margin-top: 16px;
`

const ConnectedCount = styled.div`
  margin-top: 16px;
  font-weight: bold;
`

const Info = styled.div`
  @media (max-width: ${SMALL_PHONE_WIDTH}px) {
    display: none;
  }
`

export default ShareWithSpecificCompanies
