import React from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { Link } from 'react-router'
import _ from 'lodash'

import { t } from 'shared-js/i18n'
import { resolve } from 'shared-js/utilities/deprecated-named-routes'
import {
  SmallPlanCard,
  SmallPlanCardLoading,
  LearnerPlanCard,
  LearnerPlanCardLoading,
} from 'components/common/plan-card'
import { Plans_plan } from './gql-types/Plans_plan'
import { PlanQuery_TrainingPlan__List } from './gql-types/PlanQuery'

type Props = {
  plans: PlanQuery_TrainingPlan__List['edges'],
}

const getUrl = (plan: Plans_plan) => resolve('new-module-attempt', {
  moduleId: plan.nextModuleIdForUser,
  trainingPlanId: plan.id,
})

export const Plans = ({ plans }: Props) => {
  const [firstPlan, ...otherPlans] = plans

  if (plans.length === 0) {
    return <PlanContainer>{t('your_most_recently_started_training_will_show_here')}</PlanContainer>
  }

  return (
    <PlanContainer>
      <BigCardLink to={getUrl(firstPlan!.node!)}>
        <LearnerPlanCard plan={firstPlan!.node!} />
      </BigCardLink>
      <SmallCardList>
        {otherPlans.map((edge) => (
          <SmallCardLink to={getUrl(edge!.node!)} key={edge!.node!.id}>
            <SmallCardContainer>
              <SmallPlanCard plan={edge!.node!} />
            </SmallCardContainer>
          </SmallCardLink>
        ))}
      </SmallCardList>
    </PlanContainer>
  )
}

Plans.fragment = gql`
  fragment Plans_plan on TrainingPlanType {
    id
    nextModuleIdForUser
    ...LearnerPlanCard_plan
    ...SmallPlanCard_plan
  }
  ${LearnerPlanCard.fragment}
  ${SmallPlanCard.fragment}
`

export const PlansLoading = () => (
  <PlanContainer>
    <LearnerPlanCardLoading />
    <SmallCardList>
      {_.map(_.range(0, 4), (num) => (
        <SmallCardContainer key={num}>
          <SmallPlanCardLoading />
        </SmallCardContainer>
      ))}
    </SmallCardList>
  </PlanContainer>
)

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 21px;
`

const SmallCardList = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`

const BigCardLink = styled(Link)`
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.85;
  }
`

const SmallCardLink = styled(Link)`
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.75;
  }
`

const SmallCardContainer = styled.div`
  margin: 20px 0px;
`
