/*
 *  This file serves as a register for all ui-kit components
 *
 * It also allows us to import components directly from 'ui-kit' instead of the nested
 * file structure so..
 * 'import { Button, Checkbox } from 'ui-kit'
 */

// Data Display
export { Avatar } from './data-display/avatar'
export { Badge } from './data-display/badge'
export { Chip } from './data-display/chip'
export { Divider } from './data-display/divider'
export {
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from './data-display/list'
export { Table } from './data-display/table'
export { Tooltip } from './data-display/tooltip'
export { Typography } from './data-display/typography'

// Utils
export { ClickAwayListener } from './utils/click-away-listener'
export { CssBaseline } from './utils/css-baseline'
export { NoSsr } from './utils/no-ssr'
export { Popover } from './utils/popover'
export { Popper } from './utils/popper'
export { Portal } from './utils/portal'
export { TextareaAutosize } from './utils/textarea-autosize'
export { Collapse, Fade, Grow, Slide, Zoom } from './utils/transitions'
export { useMediaQuery } from './utils/useMediaQuery'

// Feedback
export { CircularProgress, LinearProgress } from './feedback/progress'
export { SimpleDialog, Dialog, DialogContentText } from './feedback/Dialog/dialog'
export { Snackbar } from './feedback/snackbar'

// Inputs
export {
  Button,
  OutlinedButton,
  TextButton,
  DangerButton,
  IconButton,
} from './inputs/Button/button'
export { Checkbox } from './inputs/Checkbox/checkbox'
export {
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  FormGroup,
} from './inputs/Radio/radio'
export { Select } from './inputs/select'
export { Slider } from './inputs/slider'
export { Switch } from './inputs/Switch/switch'
export { TextField } from './inputs/Textfield/text-field'

// Layout
export { Box } from './layout/box'
export { Container } from './layout/container'
export { Grid } from './layout/grid'
export { GridList } from './layout/grid-list'
export { Hidden } from './layout/hidden'

// Navigation
export { BottomNavigation } from './navigation/bottom-navigation'
export { Breadcrumbs } from './navigation/breadcrumbs'
export { Drawer } from './navigation/drawer'
export { Link } from './navigation/link'
export { Menu, MenuItem, MenuList } from './navigation/menu'
export { Stepper } from './navigation/stepper'
export { Tabs, Tab } from './navigation/Tabs/tabs'

// Surfaces
export { AppBar } from './surfaces/app-bar'
export { Paper } from './surfaces/paper'
export { Card } from './surfaces/card'
export { ExpansionPanel } from './surfaces/expansion-panel'

// Lab - Experimental
export { MultiSelect, SingleSelect } from './lab/Autocomplete/autocomplete'
export { Rating } from './lab/rating'
export { Skeleton } from './lab/skeleton'
export { SpeedDial, SpeedDialAction, SpeedDialIcon } from './lab/speed-dial'
export { ToggleButton, ToggleButtonGroup } from './lab/toggle-button'
export { TreeItem, TreeView } from './lab/tree-view'
