import app from 'core/application'
import Marty from 'marty'

const autoDispatch = Marty.autoDispatch

const Constants = Marty.createConstants(['PEOPLE_USER_SET_SEARCH', 'PEOPLE_USER_SET_FILTER'])

class PeopleUserActionCreators extends Marty.ActionCreators {
  setSearch = (str) => this.dispatch(Constants.PEOPLE_USER_SET_SEARCH, str)

  setCurrentFilterQuery = (str) => this.dispatch(Constants.PEOPLE_USER_SET_FILTER, str)
}

class PeopleUserStore extends Marty.Store {
  constructor(opts) {
    super(opts)
    this.handlers = {
      onSetSearch: Constants.PEOPLE_USER_SET_SEARCH,
      onSetCurrentFilterQuery: Constants.PEOPLE_USER_SET_FILTER,
    }
    this.resetState()
  }

  resetState() {
    const state = {
      search: '',
      query: {},
    }
    this.setState(state)
    this.hasChanged()
  }

  onSetSearch(search) {
    this.setState({ search })
    this.hasChanged()
  }

  getSearch() {
    return this.state.search
  }

  getCurrentFilterQuery() {
    return this.state.query
  }

  onSetCurrentFilterQuery(query) {
    this.setState({ query })
    this.hasChanged()
  }
}

app.register('PeopleUserStore', PeopleUserStore)
app.register('PeopleUserActionCreators', PeopleUserActionCreators)

export default {
  Store: app.PeopleUserStore,
  ActionCreators: app.PeopleUserActionCreators,
}
