import { LoadingSpinner } from 'components/common/loading'
import { Modal } from 'components/common/modals'
import { PrimaryButton } from 'components/common/buttons'
import PropTypes from 'prop-types'
import React from 'react'
import { TextInput, SubmitButton, Form } from 'components/common/form-deprecated'
import TrainingPlansState from 'state/training-plans'
import { resolve } from 'utilities/deprecated-named-routes'
import { t } from 'i18n'

const styles = {
  button: {
    display: 'block',
    width: 100,
    marginLeft: 0,
  },
  spinner: {
    height: 30,
    width: 100,
  },
  header: {
    borderBottom: 'none',
    paddingBottom: 0,
    fontSize: 24,
  },
}

export class CreatePlanModal extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      error: false,
      submitted: false,
    }
  }

  show() {
    this.refs.createPlanModal.show()
  }

  createPlan = () => {
    const name = this.input.refs.input.value
    if (name && name.length > 0) {
      this.setState({ ...this.state, error: false, submitted: true })
      const data = {
        name,
        description: '',
        is_published: false,
        owner: this.context.currentUser.company.url,
      }
      if (this.props.channelId) {
        data.training_units = [
          `http://localhost:8000/api/v1/training_units/${this.props.channelId}/`,
        ]
      }

      TrainingPlansState.ActionCreators.create(data).then((res) => {
        const learner = this.context.currentUser.learner
        this.refs.createPlanModal.hide()
        this.context.router.push(
          `${resolve('plan-management', { planId: res.body.id })}?prevChannel=${
            this.props.channelId
          }`
        )
      })
    } else {
      this.setState({ ...this.state, error: true, submitting: false })
    }
  }

  render() {
    return (
      <Modal
        ref="createPlanModal"
        header={t('create_your_plan_call_it')}
        headerStyle={styles.header}
      >
        <Form onSubmitAndValid={this.createPlan}>
          <p>{t('plan_desc')}</p>
          <TextInput
            type="text"
            ref={(c) => (this.input = c)}
            placeholder={t('plan_name_example')}
            autoFocus
          />
          {this.state.error && (
            <div className="ui negative message">{t('valid_name_for_plan')}</div>
          )}
          {this.state.submitted ? (
            <LoadingSpinner containerStyle={styles.spinner} />
          ) : (
            <SubmitButton style={styles.button} onSubmit={this.createPlan} text={t('next')} />
          )}
        </Form>
      </Modal>
    )
  }
}
