import React from 'react'
import Im from 'shared-js/immutable'
import Style from 'style'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import Radium from 'radium'
import PLACEHOLDER_IMAGE from 'img/placeholder-square.svg'
import { Modal } from 'components/common/modals'
import ChannelSharesState from 'state/channel-shares'
import { Info } from 'components/common/info'
import { CONTENT_SELLER } from 'core/constants'

const styles = {
  container: {
    margin: 15,
    width: 230,
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: 230,
    width: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  cancelContainer: {
    height: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancel: {
    zIndex: 10,
    marginTop: 3,
    height: 10,
    color: '#888',
    cursor: 'pointer',
    ':hover': {
      color: Style.vars.deprecatedColors.red,
    },
  },
  companyName: {
    marginTop: 5,
    fontSize: '1.1rem',
  },
}

@Radium
export class ConnectionCard extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  delete = () => {
    ChannelSharesState.ActionCreators.delete(this.props.connection.id)
  }

  render() {
    const company = this.props.connection.company
    return (
      <div style={styles.container}>
        <div style={styles.cancelContainer}>
          <div style={styles.cancel} onClick={() => this.deleteModal.show()}>
            <i className="ui icon remove" />
          </div>
        </div>
        <div
          style={{
            ...styles.image,
            backgroundImage: `url(${company.company_logo || PLACEHOLDER_IMAGE})`,
          }}
        />
        <div style={styles.companyName}>{company.name}</div>
        <div>
          <Info key="users" content={t('total_number_of_users')}>
            <div>
              <i className="ui icon users" />
              {company.user_count}
            </div>
          </Info>
        </div>

        <Modal
          ref={(c) => (this.deleteModal = c)}
          header={t('are_you_sure_archive_connection')}
          content={t('company_will_no_longer_have_access_to_content', {
            company: company.name,
          })}
          onConfirm={this.delete}
          basic
        />
      </div>
    )
  }
}
