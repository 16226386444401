export const DEALER = 'dealer'
export const SUPPLIER = 'supplier'
export const BRAND = 'brand'
export const COMPANY_CONNECTION_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
  SUGGESTED: 'suggested',
}

export default {
  DEALER,
  SUPPLIER,
  BRAND,
  COMPANY_CONNECTION_STATUSES,
}
