import React from 'react'
import { useCommonRouter } from 'shared-js/routing'
import styled from 'styled-components'
import Style from 'shared-js/style'
import { t } from 'shared-js/i18n'
import { LoadingSpinner } from 'shared-js/components/common/loading'
import { getPaginationProps } from 'shared-js/state/graphql'
import { ContainedList } from 'components/common/virtualized-components'
import Button from 'shared-js/components/common/buttons'
import Brand, { ITEM_HEIGHT } from './brand-item'
import { Search } from '../common'
import { LOADING_SIZE, EMPTY_SEARCH_INPUT, useConnectedBrands } from './connected-brands-provider'

const MAX_ITEMS_SHOWN = 6

export const BrandListDesktop = () => {
  const { apolloProps, searchTerm, setSearchTerm } = useConnectedBrands()
  if (apolloProps.error) return null

  const hasFetchedConnections = apolloProps.data?.PublicCompany__List
  const hasConnections = Boolean(apolloProps.data?.PublicCompany__List?.totalCount)

  if (hasFetchedConnections) {
    const paginationProps = getPaginationProps({
      apolloProps,
      listFieldName: 'PublicCompany__List',
    })

    if (hasConnections) {
      return (
        <>
          <Search
            value={searchTerm}
            placeholder={t('search_by_brand')}
            onChange={(value) => setSearchTerm(value)}
          />
          <ContainedList
            items={apolloProps.data?.PublicCompany__List?.edges || []}
            totalCount={apolloProps.data?.PublicCompany__List?.totalCount || 0}
            loadingSize={LOADING_SIZE}
            loadMoreData={paginationProps.loadMoreData}
            dataIsLoading={paginationProps.dataIsLoading}
            maxNumRowsShownAtATime={MAX_ITEMS_SHOWN}
            fetchMoreThreshold={LOADING_SIZE}
            rowRenderer={({ index, key, style }) =>
              apolloProps.data?.PublicCompany__List?.edges[index]?.node && (
                <Brand
                  style={style}
                  key={key}
                  brand={apolloProps.data?.PublicCompany__List?.edges[index].node}
                />
              )
            }
            rowHeight={ITEM_HEIGHT}
          />
        </>
      )
    }

    return searchTerm !== EMPTY_SEARCH_INPUT ? (
      <>
        <Search
          value={searchTerm}
          placeholder={t('search_by_brand')}
          onChange={(value) => setSearchTerm(value)}
        />
        {`${t('no_results_for')} "${searchTerm}"`}
      </>
    ) : (
      <NoConnectionsMessage />
    )
  }

  // loading state must be placed at the end to avoid a scrolling bug with the virtualized list
  if (apolloProps.loading) return <LoadingSpinner />

  // returning null as fallback to avoid a bug we've had where the page seems to infinitely load
  return null
}

const NoConnectionsMessage = () => {
  const { handlers } = useCommonRouter()
  return (
    <NoConnectionsContainer>
      {t('no_connections_to_show')}
      <Button
        color="transparent"
        textStyle={{ color: Style.vars.deprecatedColors.primary }}
        onPress={() => handlers.goToFindConnections()}
      >
        {t('find_brands')}
      </Button>
    </NoConnectionsContainer>
  )
}

const NoConnectionsContainer = styled.div`
  margin-bottom: 28px;
`
