import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { t } from 'i18n'
import Style from 'style'
import StyleSheet from 'shared-js/style/style-sheet'
import { Info } from 'components/common/info'
import PublicPagelink from './public-info'

export const COVER_IMAGE_DATA = {
  trainingUnit: {
    fragment: gql`
      fragment CoverImage_channel on TrainingUnitType {
        id
        name
        displayName
        description
        logo
        coverImage
        company {
          id
          companyLogo
          coverImage
        }
      }
    `,
  },
}

const CoverImage = ({ channel, isPublicPage }) => {
  const precedentedCoverImage = channel.coverImage || channel.company.coverImage
  return (
    <React.Fragment>
      <div
        style={Style.funcs.merge(styles.coverContainer, {
          backgroundImage: `url('${precedentedCoverImage}')`,
        })}
      >
        <div style={styles.coverInnerContainer}>
          {!isPublicPage && <PublicPagelink />}

          <div style={styles.coverDetailsContainer}>
            <img style={styles.companyLogo} src={channel.logo || channel.company.companyLogo} />
            <div style={styles.borderedNameContainer}>
              <ChannelName channel={channel} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const ChannelName = ({ channel }) => (
  <div style={styles.nameContainer}>
    <span style={styles.nameContainerText}>
      {channel.displayName || channel.name}
      {' '}
    </span>
    {channel.description && (
      <Info
        title={t('description')}
        content={channel.description}
        style={styles.infoIcon}
        tooltipStyle={styles.tooltipStyle}
      />
    )}
  </div>
)

export default CoverImage

const styles = StyleSheet.create({
  coverContainer: {
    position: 'relative',
    width: '100%',
    height: 400,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundColor: Style.vars.deprecatedColors.navBackground,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  coverInnerContainer: {
    width: '100%',
    maxWidth: Style.vars.widths.mainContentMaxWidth,
    height: '100%',
    display: 'flex',
    margin: '0 auto',
    position: 'relative',
  },
  coverDetailsContainer: {
    display: 'inline-block',
    margin: '0 auto',
    padding: '0 20px',
    justifySelf: 'center',
    alignSelf: 'center',
  },
  tooltipStyle: {
    fontSize: 18,
  },
  infoIcon: {
    fontSize: 15,
    verticalAlign: 'middle',
  },
  companyLogo: {
    position: 'relative',
    height: 150,
    borderRadius: 10,
    padding: 5,
    display: 'block',
    margin: '10px auto',
    [Style.vars.media.mobile]: {
      height: 100,
    },
  },
  nameContainerText: {
    fontSize: 40,
    lineHeight: 1,
    [Style.vars.media.mobile]: {
      fontSize: 30,
    },
  },
  borderedNameContainer: {
    borderRadius: 5,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
  },
  nameContainer: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
