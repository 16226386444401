import React from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import Style from 'shared-js/style'
import Icon from 'react-native-vector-icons/MaterialIcons'

export const BRAND_FRAGMENT = gql`
  fragment ConnectedBrands_brand on PublicCompanyType {
    id
    name
    discoverableChannelsCount
  }
`

export const TAG_FRAGMENT = gql`
  fragment RelevantIndustryTags_tag on TagType {
    id
    name
  }
`

type StyledCheckboxProps = {
  isActive: any
}

type SearchProps = {
  value: string
  placeholder: string
  onChange(val: string): void
}

export const Search = ({ placeholder, onChange }: SearchProps) => (
  <Container>
    <Input placeholder={placeholder} onChange={(event) => onChange(event.target.value)} />
  </Container>
)

const Container = styled.div`
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 1px ${Style.colors.grey200};
  background-color: ${Style.colors.grey50};
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 10px 15px;
`

const Input = styled.input`
  border: none;
  width: 100%;
  background: transparent;
  color: ${Style.colors.grey600};
`

export const FilterType = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${Style.colors.grey900};
  margin-bottom: 20px;
  text-transform: uppercase;
  display: flex;
  cursor: pointer;
`

export const StyledArrowIcon = styled(Icon)`
  margin-left: 8px;
`
export const StyledCheckboxIcon = styled(Icon)<StyledCheckboxProps>`
  margin-right: 15px;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : 'rgba(101, 119, 134, 0.4)')};
`
