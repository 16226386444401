import PropTypes from 'prop-types'
import React from 'react'
import UsersState from 'state/users'

import { Modal } from 'components/common/modals/index'

export class SyncFeeds extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  syncFeeds = () => {
    UsersState.ActionCreators.doDetailAction(this.props.user.id, 'sync_feeds', {
      user_id: this.props.user.id,
    })
      .then((res) => {
        this.context.displayTempPositiveMessage({
          heading: 'Success',
        })
        window.location.reload()
      })
      .catch((err) => {
        this.context.displayTempNegativeMessage({
          heading: 'Error',
          body: err.message,
        })
      })
    this.modal.hide()
  }

  render() {
    return (
      <div>
        <button className="ui button orange" onClick={() => this.modal.show()}>
          Sync User Feeds
        </button>
        <Modal
          ref={(c) => (this.modal = c)}
          header={`Sync Stream Feeds for ${this.props.user.full_name}`}
          closeOnDimmerClick={false}
        >
          <div className="content">
            <button className="ui blue button" onClick={this.syncFeeds}>
              {`Sync ${this.props.user.full_name}`}
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}
