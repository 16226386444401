import { remoteSearchMixinFactory } from 'components/common/search'
import { SelectObjects } from 'components/common/select-objects'
import PropTypes from 'prop-types'
import Marty from 'marty'
import React from 'react'
import _ from 'lodash'
import reactMixin from 'react-mixin'
import ChannelSharesState from 'state/channel-shares'
import TrainingPlansState from 'state/training-plans'
import containerUtils from 'utilities/containers'
import PageState from './state'

class PlansSelectorInner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectionChanges: PlansSelectorInner.emptySelectionState,
      saving: false,
      value: PlansSelectorInner.emptySelectionState,
    }
  }

  renderObject = (plan) => <div>{plan.name}</div>

  static emptySelectionState = {
    newSelected: {},
    newDeselected: {},
  }

  isSelected = (obj) => true

  onSelectionChange = (newSelection, changedObj) => {
    this.setState({
      ...this.state,
      selectionChanges: newSelection,
    })
    this.props.onSelectionChange(newSelection, this.props.plans)
  }

  render() {
    return (
      <SelectObjects
        loadMore={_.noop}
        moreDataAvailable={false}
        dataIsLoading={false}
        objects={this.props.plans}
        renderObject={this.renderObject}
        isSelected={this.isSelected}
        isDisabled={false}
        onChange={this.onSelectionChange}
        value={this.state.selectionChanges}
      />
    )
  }
}

export const PlansSelector = Marty.createContainer(PlansSelectorInner, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [PageState.Store, TrainingPlansState.Store, ChannelSharesState.Store],

  fetch: {
    plans() {
      const query = {
        ordering: 'name',
        owner__id: this.context.currentUser.company.id,
        fields: ['id', 'name', 'url'],
      }
      if (this.props.channelId) {
        query.training_units__id = this.props.channelId
      }
      return TrainingPlansState.Store.getItems(query)
    },
  },

  UNSAFE_componentWillMount() {
    PageState.Store.resetState()
  },

  show() {
    this.innerComponent.show()
  },

  pending() {
    return containerUtils.defaultPending(this, PlansSelectorInner)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, PlansSelectorInner, errors)
  },
})
