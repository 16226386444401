import { PrimaryButton } from 'components/common/buttons'
import { EmbedlyCard } from 'components/common/media-players/embed'
import {
  Form,
  HiddenTextInput,
  SubmitButton,
  URLInput,
  YesNoToggle,
} from 'components/common/form-deprecated'
import { RegionMultiSelect } from 'components/common/form-deprecated/select/region-select'
import { Info } from 'components/common/info'
import { Modal } from 'components/common/modals/index'
import { TagSearchableMultiSelect } from 'components/common/tag-searchable-multiselect'
import { CONTENT_SELLER } from 'core/constants'
import { t } from 'i18n'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import Textarea from 'react-autosize-textarea'
import _ from 'lodash'
import Button from 'shared-js/components/common/buttons'
import ChannelsState from 'state/channels'
import Style from 'style/index.js'
import { momentToISO } from 'utilities/time'
import { Badges } from './badges'
import { LearnItems } from './learn-items'
import { CopyChannelForm } from '../../../common/copy-channel-form'

const COLUMN_PANELS = '@media screen and (max-width: 800px)'

const styles = {
  container: {
    margin: '0px 20px',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    [COLUMN_PANELS]: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  column: {
    minWidth: 400,
    padding: '0 20px',
  },
  field: {
    margin: '0px 0px 30px',
  },
  embedlyCardContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  embedlyForm: {
    width: 500,
  },
  textarea: {
    width: '100%',
    color: 'black',
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    fontSize: '1.1rem',
    border: 0,
  },
  modalForm: {
    marginTop: 10,
  },
  clickableField: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 0px 30px',
    cursor: 'pointer',
    transition: '0.3s all',
    ':hover': {
      paddingLeft: 10,
      borderLeft: `6px solid ${Style.vars.deprecatedColors.primary}`,
    },
  },
  placeholder: {
    color: '#888',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 5,
  },
  description: {
    maxWidth: 500,
  },
  archiveButton: {
    fontSize: '1.2rem',
    color: Style.vars.deprecatedColors.red,
  },
  tagsButton: {
    display: 'inline-block',
  },
  requireSequentialContainer: {
    marginBottom: 40,
  },
}

@Radium
export class DetailsTab extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  }

  static data = {
    channel: {
      required: true,
      fields: [
        'name',
        'id',
        'url',
        'description',
        'display_name',
        'logo',
        'cover_image',
        'cover_image_thumbnail',
        'company.id',
        'regions',
        'public',
        'request_to_access',
        'tags',
        'video',
      ],
    },
  }

  onVideoUpdate = (data) => {
    if (!data.video) data.video = ''
    ChannelsState.ActionCreators.update(this.props.channel.id, data)
    this.context.displayTempPositiveMessage({ heading: 'Channel updated' })
    _.delay(() => {
      if (this.videoModal) this.videoModal.hide()
    }, 500)
  }

  onTagsFormSubmit = (data) => {
    ChannelsState.ActionCreators.update(this.props.channel.id, data)
    this.tagModal.hide()
    this.context.displayTempPositiveMessage({ heading: 'changes_saved' })
  }

  onRequireToggleChange = (val) => {
    this.updateFields({ require_sequential_completion: val })
  }

  onRegionFormSubmit = (data) => {
    ChannelsState.ActionCreators.update(this.props.channel.id, data).then((res) => {
      this.context.displayTempPositiveMessage({
        heading: 'changes_saved',
      })
    })
    this.regionModal.hide()
  }

  showArchiveChannelModal = () => {
    if (!this.props.channel) return
    if (_.size(this.props.trainingPlanTrainingUnits) === 0) {
      this.archiveModal.show()
    } else {
      this.cannotArchiveModal.show()
    }
  }

  archiveChannel = () => {
    const now = momentToISO(moment())
    ChannelsState.ActionCreators.update(this.props.channel.id, {
      deactivated: now,
    }).then((res) => this.context.router.push('/views/content/channels/'))
    this.archiveModal.hide()
  }

  saveDescription = () => {
    this.descriptionModal.hide()
    this.updateFields({ description: this.descriptionInput.value })
  }

  updateFields = (data) => {
    _.forOwn(data, (value, key) => {
      data[key] = _.trim(value)
    })
    if (!data.name) data.name = this.props.channel.name
    ChannelsState.ActionCreators.update(this.props.channel.id, data).then((res) => {
      this.context.displayTempPositiveMessage({
        heading: 'changes_saved',
      })
    })
  }

  render() {
    const { learner, company } = this.context.currentUser
    const userCanCopyChannel =
      (learner.is_parent_company_admin &&
        learner.can_manage_training_content &&
        company.subscription.parent_child_companies_enabled &&
        company.id === this.props.channel.company.id) ||
      learner.is_myagi_staff
    return (
      <div style={styles.container}>
        <div style={styles.column}>
          <div
            onClick={() => this.descriptionModal.show()}
            style={styles.clickableField}
            key="description"
          >
            <div style={styles.title}>{t('description')}</div>
            {this.props.channel.description.split('\n').map((text, i) => (
              <div key={i} style={{ marginBottom: 8 }}>
                {text}
              </div>
            ))}
            {!this.props.channel.description && (
              <div style={styles.placeholder}>{t('no_content')}</div>
            )}
          </div>

          <div onClick={() => this.learnItemsComponentModal.show()} style={styles.clickableField}>
            <div style={styles.title}>{t('what_youll_learn')}</div>
            <LearnItems
              channel={this.props.channel}
              ref={(c) => (this.learnItemsComponentModal = c)}
            />
          </div>

          <div onClick={() => this.videoModal.show()} style={styles.clickableField} key="video">
            <div style={styles.title}>{t('introductory_video')}</div>
            <div>{this.props.channel.video}</div>
            {!this.props.channel.video && <div style={styles.placeholder}>{t('no_content')}</div>}
          </div>

          <div style={styles.requireSequentialContainer}>
            <div style={styles.title}>
              {t('require_sequential_completion')}{' '}
              <Info content={t('sequential_completion_info')} />
            </div>

            <YesNoToggle
              onChange={this.onRequireToggleChange}
              initialValue={this.props.channel.require_sequential_completion}
            />
          </div>

          <div onClick={() => this.regionModal.show()} style={styles.clickableField} key="region">
            <div style={styles.title}>{t('regions')}</div>
            <div style={styles.description}>{this.props.channel.regions.join(', ')}</div>
            {!this.props.channel.regions && <div style={styles.placeholder}>{t('no_content')}</div>}
          </div>

          {userCanCopyChannel && (
            <div style={styles.field}>
              <Button color="green" onPress={() => this.copyChannelModal.show()} outline>
                <i className="ui icon copy" />
                {t('copy_to_another_company')}
              </Button>
            </div>
          )}
          <div style={styles.field}>
            <Button color="red" onPress={this.showArchiveChannelModal} outline>
              <i className="ui icon archive" />
              {t('archive_channel')}
            </Button>
          </div>
        </div>

        <div style={styles.column}>
          <div style={styles.field}>
            <div style={styles.title}>{t('badges')}</div>
            <Badges
              location={this.props.location}
              channel={this.props.channel}
              trainingPlanTrainingUnits={this.props.trainingPlanTrainingUnits}
            />
          </div>
        </div>
        {this.props.children}
        <Modal
          ref={(c) => (this.descriptionModal = c)}
          header={t('description')}
          basic
          message
          noConfirm
        >
          <div className="content">
            <Textarea
              placeholder={t('description')}
              type="text"
              innerRef={(c) => (this.descriptionInput = c)}
              defaultValue={this.props.channel.description}
              style={styles.textarea}
              rows={4}
            />
            <PrimaryButton onClick={this.saveDescription}>{t('save')}</PrimaryButton>
          </div>
        </Modal>

        <Modal ref={(c) => (this.videoModal = c)} header={t('video')} basic message noConfirm>
          <div className="content">
            {t('introductory_video_info')}
            <EmbedlyCard url={this.props.channel.video} />
            <div style={styles.embedlyCardContainer}>
              <Form style={styles.embedlyForm} onSubmitAndValid={this.onVideoUpdate}>
                <HiddenTextInput />
                <URLInput
                  name="video"
                  initialValue={this.props.channel.video || 'Video URL'}
                  initialIsAcceptable={Boolean(this.props.channel.video)}
                  required={false}
                />
                <SubmitButton />
              </Form>
            </div>
          </div>
        </Modal>

        <Modal ref={(c) => (this.regionModal = c)} header={t('regions')} basic message noConfirm>
          <div className="content">
            {t('region_info_channel')}
            <Form onSubmitAndValid={this.onRegionFormSubmit} style={styles.modalForm}>
              <HiddenTextInput />
              <RegionMultiSelect name="regions" initialSelections={this.props.channel.regions} />
              <SubmitButton />
            </Form>
          </div>
        </Modal>

        <Modal ref={(c) => (this.tagModal = c)} header={t('channel_tags')} basic message noConfirm>
          <div className="content">
            {t('channel_tags_info')}
            <Form onSubmitAndValid={this.onTagsFormSubmit}>
              <HiddenTextInput />
              <TagSearchableMultiSelect
                name="tags"
                initialSelections={this.props.channel.tags}
                currentUser={this.context.currentUser}
                fetchOpts={{
                  exclude_type: 'brand',
                }}
              />
              <SubmitButton />
            </Form>
          </div>
        </Modal>

        <Modal
          ref={(c) => (this.cannotArchiveModal = c)}
          header={t('unable_to_archive_channel')}
          content={t('unable_to_archive_channel_info')}
          message
          basic
        />
        <Modal
          ref={(c) => (this.archiveModal = c)}
          header={t('are_you_sure_archive_channel')}
          content={t('are_you_sure_archive_channel_info')}
          onConfirm={this.archiveChannel}
          basic
        />
        <Modal ref={(m) => (this.copyChannelModal = m)} header={t('copy_to_another_company')}>
          <CopyChannelForm
            onSubmit={() => this.copyChannelModal.hide()}
            channel={this.props.channel}
          />
        </Modal>
      </div>
    )
  }
}
