import React, { useRef } from 'react'
import Marty from 'marty'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { t } from 'i18n'
import ChannelsState from 'state/channels'
import LinksState from 'state/links'
import containerUtils from 'utilities/containers'
import { PrimaryButton } from 'components/common/buttons'
import { LoadingContainer } from 'components/common/loading'
import { useFeatureFlag } from 'shared-js/utilities/hooks'
import { SharelinkItem } from './sharelink-item'
import { SharelinkModal } from './sharelink-modal'

const SHARELINK_URL = 'https://myagi.com/s/'

const LinksTabContent = (props) => {
  const shareLinkModalRef = useRef(null)

  return (
    <Container style={props.containerStyle}>
      <ButtonContainer>
        <PrimaryButton onClick={() => shareLinkModalRef.current.show()}>
          <i className="external share icon" />
          {` ${t('create_link')}`}
        </PrimaryButton>
      </ButtonContainer>
      <LoadingContainer
        loadingProps={[props.links, props.channels]}
        createComponent={() => (
          <div>
            {props.links.map((link) => (
              <SharelinkItem key={link.id} sharelink={link} allChannels={props.channels} />
            ))}
          </div>
        )}
      />
      {props.links && props.links.length == 0 && (
        <NoDataContainer>
          <strong>{t('what_are_sharelinks')}</strong>
          <br />
          <span>{t('sharelinks_info')}</span>
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>
            Eg.
            {SHARELINK_URL}
            acme-channel
          </span>
          <br />
          <br />
          <CreateText onClick={() => shareLinkModalRef.current.show()}>
            {`${t('create_one_now')}.`}
          </CreateText>
        </NoDataContainer>
      )}
      <SharelinkModal ref={shareLinkModalRef} channels={props.channels} />
    </Container>
  )
}

const SharelinkSectionInner = Marty.createContainer(LinksTabContent, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [LinksState.Store, ChannelsState.Store],

  fetch: {
    links() {
      const query = {
        fields: [
          'active',
          'name',
          'url',
          'num_companies_used_by',
          'channels',
          'channels.url',
          'channels.id',
          'channels.name',
          'channels.logo',
          'initial_channel',
          'initial_channel.id',
          'initial_channel.url',
          'initial_channel.name',
        ],
        limit: 0,
        company: this.context.currentUser.company.id,
        active: true,
      }
      return LinksState.Store.getItems(query)
    },
    channels() {
      return ChannelsState.Store.getItems({
        fields: ['name', 'id', 'logo'],
        limit: 0,
        // Get channels owned by current company
        ordering: 'name',
        ...(this.props.contentForwarding ? {} : { company: this.context.currentUser.company.id }),
      })
    },
  },

  pending() {
    return containerUtils.defaultPending(this, LinksTabContent)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, LinksTabContent, errors)
  },
})

export const SharelinkSection = (props) => {
  const contentForwarding = useFeatureFlag('content_forwarding')
  return <SharelinkSectionInner {...props} contentForwarding={contentForwarding} />
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 40px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  box-shadow: rgba(0, 0, 0, 0.18) 4px 3px 20px;
`

const NoDataContainer = styled.div`
  margin: 30px;
`

const CreateText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-top: -70px;
  margin-bottom: 30px;
`
