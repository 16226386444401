/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react'
import config from 'core/config'

export default function setupSentry() {
  const isStaging = window.location.hostname.includes('staging.myagi.com')
  const environment = isStaging ? 'staging' : process.env.NODE_ENV

  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    beforeSend(event) {
      // Adding custom fingerprints otherwise errors are per scorm/video url which causes a large
      // number of duplicates in Sentry.
      // @ts-ignore
      const transaction: string = event.transaction || event.culprit || ''
      if (transaction) console.info('Sentry transaction', transaction) // TODO: remove this

      if (transaction.includes('iframe_page/proxy')) {
        event.level = Sentry.Severity.Warning
        event.tags = { ...event.tags, contentFormat: 'scorm' }
        event.fingerprint = ['scorm', event.type ?? '', event.message ?? '']
      }

      if (transaction.includes('assets/external')) {
        event.level = Sentry.Severity.Warning
        event.tags = { ...event.tags, contentFormat: 'video' }
        event.fingerprint = ['video', event.type ?? '', event.message ?? '']
      }

      // You can add other fingerprinting here or, use a Sentry.ErrorBoundary with a
      // beforeCapture prop
      return event
    },
  })
}
