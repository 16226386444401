import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { t } from 'i18n'
import { Info } from 'components/common/info'
import { Modal } from 'components/common/modals'
import styled, { css } from 'styled-components'
import ChannelsState from 'state/channels'
import Style from 'style'
import { resolve } from 'utilities/deprecated-named-routes'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import { ChannelCoverImageCard } from './base/card'
import { LogoName } from './base/logo-name'

export class ChannelCardAdmin extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  static propTypes = {
    channel: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.restoreModalRef = React.createRef()
  }

  restoreConfirmClick = () => {
    // Pass in doFetch update opt to force re-fetch of channels because
    // channel queryset will change.
    ChannelsState.ActionCreators.update(
      this.props.channel.id,
      { deactivated: null },
      { doFetch: true }
    )
    this.restoreModalRef.current.hide()
    this.context.displayTempPositiveMessage({
      heading: 'changes_saved',
    })
  }

  onChannelItemClick = () => {
    if (this.props.reorderEnabled) return
    const deactivated = this.props.channel.deactivated
    if (deactivated) {
      this.restoreModalRef.current.show()
      return
    }
    this.context.router.push(
      resolve('channel', {
        channelId: this.props.channel.id,
      })
    )
  }

  getIcons() {
    const companyOwnsChannel = this.props.channel.company.id == this.context.currentUser.company.id
    const icons = []
    const channelIsAutoEnroll = this.props.channel.auto_enroll_turned_on_for_current_user_company
    const plansCount = companyOwnsChannel
      ? this.props.channel.training_plans_count
      : this.props.channel.active_and_published_training_plans_count
    const publicStatus = this.props.channel.public
    const requestToAccess = this.props.channel.request_to_access
    const subscribedCompaniesCount = this.props.channel.subscribed_companies_count

    let pubPrivText
    let pubPrivStyle = styles.greenIcon
    if (requestToAccess && publicStatus) {
      pubPrivText = t('request_to_access')
    } else if (publicStatus) {
      pubPrivText = t('public')
    } else {
      pubPrivText = t('private')
      pubPrivStyle = styles.redIcon
    }

    icons.push({
      info: 'Plans',
      el: (
        <div style={styles.blackIcon}>
          <i className="ui icon browser" />
          {plansCount}
        </div>
      ),
    })

    if (companyOwnsChannel) {
      icons.push({
        info: pubPrivText,
        el: (
          <div style={pubPrivStyle}>
            <i
              className={cx('ui', 'icon', {
                unlock: publicStatus && !requestToAccess,
                lock: !publicStatus || requestToAccess,
              })}
            />
          </div>
        ),
      })
    }

    if (channelIsAutoEnroll) {
      icons.push({
        info: t('auto_enroll_on'),
        el: <i className="ui icon student" style={styles.greenIcon} />,
      })
    }

    if (companyOwnsChannel) {
      icons.push({
        info: t('company_connections'),
        el: (
          <div>
            <i className="ui icon share alternate" />
            {subscribedCompaniesCount}
          </div>
        ),
      })
    }

    return icons
  }

  render() {
    const learner = this.context.currentUser.learner
    const channel = this.props.channel

    // ChannelCard expects camelcase keys because it has been converted to use
    // the GraphQL API.
    const camelcaseChannel = camelcaseKeysDeep(channel)

    return (
      <Container onClick={this.onChannelItemClick} reorderEnabled={this.props.reorderEnabled}>
        <ChannelCoverImageCard channel={camelcaseChannel}>
          <LogoName channel={camelcaseChannel} isAdmin />
        </ChannelCoverImageCard>
        {learner.can_manage_training_content && (
          <IconsList>
            {this.getIcons().map((icon) => (
              <Info content={icon.info} key={icon.info} position="top">
                <Icon>{icon.el}</Icon>
              </Info>
            ))}
          </IconsList>
        )}

        <Modal
          ref={this.restoreModalRef}
          onConfirm={this.restoreConfirmClick}
          header={t('channel_currently_archived')}
          basic
        />
      </Container>
    )
  }
}

const Container = styled.div`
  margin: 0px 14px 21px;
  padding-bottom: 4px;
  transition: all 0.2s ease;
  ${({ reorderEnabled }) =>
    reorderEnabled &&
    css`
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
      transform: scale(1.02);
    `}
  &:hover {
    opacity: 0.85;
  }
`

const IconsList = styled.div`
  display: flex;
  justify-content: center;
`

const Icon = styled.div`
  /* for some reason, info popups don't work well without these margins */
  margin: 2px 0px 0px 12px;
  display: flex;
`

const styles = {
  greenIcon: {
    color: Style.vars.deprecatedColors.oliveGreen,
  },
  redIcon: {
    color: Style.vars.deprecatedColors.red,
  },
  blackIcon: {
    color: '#666',
  },
  greyIcon: {
    color: '#bbb',
  },
}
