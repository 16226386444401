/* eslint-disable react/jsx-no-literals */
import PropTypes from 'prop-types'
import React from 'react'
import UsersState from 'state/users'
import { TextInput } from 'components/common/form-deprecated'
import { Modal } from 'components/common/modals/index'

export class ChangePassword extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      newPassword: '',
    }
  }

  passwordText = (e) => {
    this.setState({
      newPassword: e.target.value,
    })
  }

  changePassword = () => {
    UsersState.ActionCreators.doDetailAction(this.props.user.id, 'admin_reset_password', {
      user_id: this.props.user.id,
      new_password: this.state.newPassword,
    })
      .then((res) => {
        this.context.displayTempPositiveMessage({
          heading: 'Password change successful',
        })
        this.modal.hide()
      })
      .catch((err) => {
        this.setState({
          newPassword: 'Error! Try refreshing the page!',
        })
      })
  }

  render() {
    return (
      <div>
        <button type="button" className="ui button pink" onClick={() => this.modal.show()}>
          Change Password
        </button>
        <Modal
          ref={(el) => (this.modal = el)}
          header={`Set ${this.props.user.full_name}'s password`}
          closeOnDimmerClick={false}
        >
          <div className="content">
            <div>
              <TextInput type="text" placeholder="New password..." onChange={this.passwordText} />
            </div>
            <button
              type="button"
              className="ui blue button"
              onClick={this.changePassword}
              disabled={!(this.state.newPassword.length > 5)}
            >
              {`Set ${this.props.user.full_name}'s password`}
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}
