import React from 'react'

type EmojiProps = {
  label?: string,
  symbol: string,
  style?: React.CSSProperties,
}

export default (props: EmojiProps) => (
  <span
    role="img"
    // only add a label if the emoji adds useful info to the page, otherwise it will just be
    // ignored by the screen reader
    aria-label={props.label ? props.label : ''}
    aria-hidden={props.label ? 'false' : 'true'}
    style={props.style}
  >
    {props.symbol}
  </span>
)
