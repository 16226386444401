import React from 'react'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import { Chip } from 'ui-kit'
import { DropdownInput } from 'shared-js/components/common/inputs'
import { useConnectedBrands } from './connected-brands-provider'
import { ConnectedBrandsQuery_PublicCompany__List_edges as ConnectedBrand } from './gql-types/ConnectedBrandsQuery'

type BrandListProps = {
  updateBrandsFilter(brandID: string): void
  brandIDsFiltered: string[]
}

type FilteredBrandProps = {
  filteredBrand: ConnectedBrand
  updateBrandsFilter(brandID: string): void
}

export const BrandListMobile = ({ updateBrandsFilter, brandIDsFiltered }: BrandListProps) => {
  const { apolloProps } = useConnectedBrands()
  const connectedBrands = apolloProps?.data?.PublicCompany__List?.edges || []

  const options = connectedBrands.map((brand) => {
    return {
      label: brand?.node?.name,
      value: brand?.node?.id,
    }
  })

  const handleDropdownChange = (brandID: string) => {
    const brand = connectedBrands.find((connectedBrand) => connectedBrand?.node?.id === brandID)
    if (brand?.node) updateBrandsFilter(brand.node.id)
  }

  return (
    <Container>
      <ChipsContainer>
        {brandIDsFiltered.map((brandID) => {
          const filteredBrand = connectedBrands.find((brand) => brand?.node?.id === brandID)
          if (filteredBrand) {
            return (
              <FilteredBrand
                filteredBrand={filteredBrand}
                updateBrandsFilter={updateBrandsFilter}
              />
            )
          }
          return null
        })}
      </ChipsContainer>
      <DropdownContainer>
        <DropdownInput
          placeholder={t('select_a_connection')}
          options={options}
          onChange={handleDropdownChange}
          value=""
        />
      </DropdownContainer>
    </Container>
  )
}

const FilteredBrand = ({ filteredBrand, updateBrandsFilter }: FilteredBrandProps) => {
  return (
    <ChipContainer>
      <Chip
        key={filteredBrand?.node?.id}
        label={filteredBrand?.node?.name}
        clickable
        onClick={() => {
          if (filteredBrand?.node) updateBrandsFilter(filteredBrand.node.id)
        }}
        onDelete={() => {
          if (filteredBrand?.node) updateBrandsFilter(filteredBrand.node.id)
        }}
      />
    </ChipContainer>
  )
}

const Container = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
`

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ChipContainer = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
`

// a bit hacky but unfortunately there's a built-in margin in our common component :(
const DropdownContainer = styled.div`
  margin-top: -10px;
  margin-bottom: -20px;
`
