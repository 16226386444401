import CompaniesState from 'state/companies'
import { Modal } from 'components/common/modals/index'
import PropTypes from 'prop-types'
import React from 'react'
import { nowInISO } from 'utilities/time'

export class DeactivateCompany extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  deactivate = () => {
    CompaniesState.ActionCreators.update(this.props.company.id, {
      deactivated: nowInISO(),
    })
      .then(() => {
        this.context.displayTempPositiveMessage({
          heading: 'Success',
          body: 'You have deactivated this company',
        })
      })
      .catch((err) => {
        console.log(err)
        this.context.displayTempNegativeMessage({
          heading: "Oh nooooo, it didn't work",
          body: 'Try again...',
        })
      })
  }

  showDeactivateModal = () => {
    this.deactivateModal.show()
  }

  render() {
    return (
      <React.Fragment>
        <button className="ui red button" onClick={this.showDeactivateModal}>
          Deactivate
        </button>

        <Modal
          ref={(e) => (this.deactivateModal = e)}
          header="Deactivate company"
          closeOnDimmerClick={false}
          onConfirm={this.deactivate}
          basic
        >
          This company will no longer be searchable or joinable if you deactivate it. No company
          data will be deleted though. This can be reversed if necessary.
        </Modal>
      </React.Fragment>
    )
  }
}
