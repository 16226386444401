import { Box } from 'components/common/box'
import ChannelsState from 'state/channels'
import CompaniesState from 'state/companies'
import CompanySettingsState from 'state/companysettings'
import { CompanySettingsTab } from 'components/settings/company-settings'
import { LoadingContainer } from 'components/common/loading'
import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import SubscriptionsState from 'shared-js/state/subscriptions'
import containerUtils from 'utilities/containers'

class CompanySettingsContainer extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  render() {
    return (
      <Box>
        <LoadingContainer
          loadingProps={[this.props.company, this.props.companySettings]}
          createComponent={() => (
            <Box>
              <CompanySettingsTab
                company={this.props.company}
                companySettings={this.props.companySettings}
                currentUser={this.context.currentUser}
                internalUse
              />
            </Box>
          )}
        />
      </Box>
    )
  }
}

export const Page = Marty.createContainer(CompanySettingsContainer, {
  listenTo: [
    CompanySettingsState.Store,
    CompaniesState.Store,
    ChannelsState.Store,
    SubscriptionsState.Store,
  ],

  fetch: {
    companySettings() {
      return CompanySettingsState.Store.getItems({
        company: this.props.params.companyId,
        fields: ['*'],
      })
    },
    company() {
      return CompaniesState.Store.getItem(this.props.params.companyId, {
        fields: ['*', 'regions_of_responsibility.id', 'regions_of_responsibility.name'],
      })
    },
  },

  pending() {
    return containerUtils.defaultPending(this, CompanySettingsContainer)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, CompanySettingsContainer, errors)
  },
})
