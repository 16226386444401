import React from 'react'
import { InfiniteLoader, WindowScroller, AutoSizer } from 'react-virtualized'
import { getFetchableCount } from './common'

export type InfiniteWindowScrollerChildProps = {
  // prettier-ignore
  onRowsRendered: (params: { startIndex: number, stopIndex: number }) => void,
  height: number,
  scrollTop: number,
  width: number,
}

type InfiniteWindowScrollerProps = {
  items: any[],
  totalCount: number,
  loadingSize: number,
  loadMoreData: (vars: any) => void,
  dataIsLoading: boolean,
  fetchMoreThreshold?: number,
  children(scrollerProps: InfiniteWindowScrollerChildProps): React.ReactNode,
}

/**
 * A combination of react virtualize's InfiniteLoader and WindowScroller that lazy loads data and
 * uses the window as the scrolling container
 */
const InfiniteWindowScroller = (props: InfiniteWindowScrollerProps) => {
  const isRowLoaded = ({ index }: { index: number }): boolean => !!props.items[index]

  const loadMore = () => {
    props.loadMoreData(null)
    // prettier-ignore
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => (props.dataIsLoading
        ? reject(new Error('Fetch has not finished'))
        : resolve()), 1000)
    })
    return promise.then().catch(() => {})
  }

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMore}
      rowCount={getFetchableCount({
        itemsLength: props.items.length,
        totalCount: props.totalCount,
        loadingSize: props.loadingSize,
      })}
      threshold={props.fetchMoreThreshold}
    >
      {({ onRowsRendered }) => (
        <WindowScroller>
          {(
            // registerChild hasn't been added to DefinitelyTyped yet
            // @ts-ignore
            { height, scrollTop, registerChild }
          ) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <div ref={(element) => registerChild(element)}>
                  {props.children({
                    onRowsRendered,
                    height,
                    scrollTop,
                    width,
                  })}
                </div>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  )
}

export default InfiniteWindowScroller
