import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from '@apollo/react-components'
import { useApolloClient } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { t } from 'i18n'

import Style from 'style'
import { SecondaryButton } from 'components/common/buttons'
import { TextInput } from 'shared-js/components/common/inputs'
import {
  Formik,
  SubmitButton,
  Validation,
  getPropsFromFormik,
} from 'shared-js/components/common/form'
import { GET_OR_CREATE_CONVERSATION_WITH_USERS } from 'shared-js/components/comms/conversation-creation'

const FEEDBACK_CONVERSATION = 'feedback'

const CREATE_MESSAGE = gql`
  mutation createMessage($input: CreateOrUpdateMessageMutationInput!) {
    Message__Create(input: $input) {
      ok
      result {
        id
        body
        created
        author {
          id
          firstName
          lastName
          profilePhoto
        }
      }
    }
  }
`

const UPDATE_MESSAGE_RESPONSE = gql`
  mutation updateMessageResponse($input: CreateOrUpdateModuleFeedbackSurveyResultMutationInput!) {
    ModuleFeedback__Update(input: $input) {
      ok
      result {
        id
      }
    }
  }
`

class FeedbackReplyInner extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  getOrCreateConversationId = async () => {
    const { feedback, currentUser } = this.props

    const { data } = await this.props.client.mutate({
      mutation: GET_OR_CREATE_CONVERSATION_WITH_USERS,
      variables: {
        conversationType: FEEDBACK_CONVERSATION,
        withUsers: [feedback.user.id, currentUser.id],
      },
    })

    return data ? data.Conversation__GetOrCreate.id : null
  }

  handleCancel = () => {
    this.props.cancelReply()
  }

  updateFeedbackResponseMessage = async (messageId) => {
    if (!messageId) return

    const { feedback, lesson } = this.props

    await this.props.client
      .mutate({
        mutation: UPDATE_MESSAGE_RESPONSE,
        variables: {
          input: {
            id: feedback.id,
            module: lesson.id,
            responseMessage: messageId,
            likeRating: feedback.likeRating,
            learnRating: feedback.learnRating,
          },
        },
      })
      .catch((err) => {
        console.error(err)
      })
  }

  handleSubmit = async (data) => {
    this.setState({ loading: true })
    const conversationId = await this.getOrCreateConversationId()

    const createMessageResult = await this.commitMutation({
      variables: {
        input: {
          body: data.body,
          conversation: conversationId,
          author: this.props.currentUser.id,
        },
      },
    })

    if (!createMessageResult.data) {
      this.setState({ loading: false })
      return
    }

    const replyMessage = createMessageResult.data.Message__Create.result
    await this.updateFeedbackResponseMessage(replyMessage.id)

    this.setState({ loading: false })
    if (this.props.showReply) this.props.showReply()

    return replyMessage.id
  }

  render() {
    return (
      <div style={styles.container}>
        <Mutation mutation={CREATE_MESSAGE}>
          {(commitMutation) => {
            this.commitMutation = commitMutation
            return (
              <Formik
                ref={(el) => (this.formik = el)}
                validationSchema={Validation.createSchema({
                  body: Validation.text({ required: true }),
                })}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values)
                }}
              >
                {(innerProps) => (
                  <div style={styles.formContainer}>
                    <TextInput
                      ref={(messageInput) => (this.messageInput = messageInput)}
                      placeholder={t('send_a_message')}
                      style={styles.textInput}
                      onFocus={null}
                      autoFocus={false}
                      returnKeyType="default"
                      hideError
                      multiline
                      editable
                      {...getPropsFromFormik(innerProps, 'body')}
                    />

                    <div style={styles.buttonRow}>
                      <SecondaryButton onClick={this.handleCancel} style={styles.cancelButton}>
                        {t('cancel')}
                      </SecondaryButton>

                      <SubmitButton
                        containerStyle={styles.submitButton}
                        isValid={innerProps.isValid}
                        onPress={innerProps.handleSubmit}
                        loading={this.state.loading}
                      >
                        {t('send')}
                      </SubmitButton>
                    </div>
                  </div>
                )}
              </Formik>
            )
          }}
        </Mutation>
      </div>
    )
  }
}

export const FeedbackReply = (props) => {
  // Needed to provide client in a hotfix related to
  // https://github.com/apollographql/react-apollo/issues/3366
  // TODO: change mutation and queries to hooks.
  const client = useApolloClient()
  return <FeedbackReplyInner {...props} client={client} />
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '18vh',
    border: '1px solid rgb(229,229,229)',
    borderStyle: 'solid',
    borderRadius: '3px',
    margin: 0,
    borderLeftColor: Style.vars.deprecatedColors.primary,
    borderLeftWidth: '4px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-between',
  },
  textInput: {
    minHeight: '10vh',
    paddingLeft: 10,
    borderWidth: 0,
    margin: 5,
    paddingTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
    fontFamily: 'Open Sans,  sans-serif',
    fontSize: '14px',
    outline: 'none',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    verticalAlign: 'center',
    margin: '10px',
    marginLeft: '5px',
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '35px',
    maxHeight: '35px',
    minWidth: '70px',
    maxWidth: '70px',
    margin: 0,
    marginLeft: 5,
  },
  cancelButton: {
    minHeight: '35px',
    maxHeight: '35px',
    minWidth: '70px',
    maxWidth: '70px',
    padding: '7px 0px',
  },
}
