import React, { useState } from 'react'
import { t } from 'shared-js/i18n'
import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { FilterType, StyledArrowIcon } from '../common'
import { BrandListDesktop } from './brand-list-desktop'
import { BrandListMobile } from './brand-list-mobile'

type MobileFilterProps = {
  updateBrandsFilter(brandID: string): void
  brandIDsFiltered: string[]
}

export const FilterByConnectionDesktop = () => {
  const [connectionFilterIsExpanded, toggleConnectionFilterIsExpanded] = useState(true)
  return (
    <>
      <FilterType onClick={() => toggleConnectionFilterIsExpanded(!connectionFilterIsExpanded)}>
        {t('company_name')}
        <StyledArrowIcon
          name={connectionFilterIsExpanded ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
          size={20}
        />
      </FilterType>
      {connectionFilterIsExpanded && <BrandListDesktop />}
    </>
  )
}

export const FilterByConnectionMobile = ({
  updateBrandsFilter,
  brandIDsFiltered,
}: MobileFilterProps) => {
  const [connectionFilterIsExpanded, toggleConnectionFilterIsExpanded] = useState(false)
  const sectionTitle = brandIDsFiltered.length
    ? `${t('company_name')} (${brandIDsFiltered.length})`
    : t('company_name')

  return (
    <Container>
      <TitleContainer onClick={() => toggleConnectionFilterIsExpanded(!connectionFilterIsExpanded)}>
        <SectionTitle>{sectionTitle}</SectionTitle>
        {connectionFilterIsExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
      </TitleContainer>
      {connectionFilterIsExpanded && (
        <BrandListMobile
          updateBrandsFilter={updateBrandsFilter}
          brandIDsFiltered={brandIDsFiltered}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px rgba(220, 220, 220, 0.5);
  padding: 20px 0px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const SectionTitle = styled.div`
  text-transform: uppercase;
`
