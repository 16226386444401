import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import _ from 'lodash'
import moment from 'moment-timezone'
import reactMixin from 'react-mixin'

import { t } from 'i18n'
import Style from 'style/index'

import containerUtils from 'utilities/containers'
import $y from 'utilities/yaler'
import { getIdFromApiUrl } from 'utilities/generic'

import createPaginatedStateContainer from 'state/pagination'

import TrainingPlansState from 'state/training-plans'
import UsersState from 'state/users'

import { LoadingContainer } from 'components/common/loading'
import { Panel, BoxHeader, BoxContent } from 'components/common/box'
import { Modal } from 'components/common/modals/index'
import { DeprecatedScrollableDataTable } from 'components/common/table'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import { remoteSearchMixinFactory } from 'components/common/search'
import { PrimaryButton } from 'components/common/buttons'
import { SetDueDateForPlanModal } from '../due-date-modal'
import PageState from './state'

const styles = {
  table: {
    marginTop: '1em',
  },
}

class EnrollmentsCollection extends React.Component {
  static data = {
    enrollments: {
      many: true,
      fields: [
        'id',
        'profile_photo',
        'first_name',
        'last_name',
        'company.name',
        'team_name',
        'plan_due_date',
        'plan_progress',
        'plan_completed_date',
      ],
    },
    trainingPlan: {
      fields: ['name'],
    },
  }

  static propTypes = $y.propTypesFromData(EnrollmentsCollection)

  static tableDataMapping = {
    user: (userData) => `${userData.first_name} ${userData.last_name}`,
    company: (userData) => {
      let userCompany = userData.company
      userCompany = userCompany ? userCompany.name : 'No team'
      return <span key={userCompany}>{userCompany}</span>
    },
    team: (userData) => {
      const val = userData.team_name || 'No team'
      return <span key={val}>{val}</span>
    },
    due_date: (userData) =>
      userData.plan_due_date
        ? t('due_from_now', { date: moment(userData.plan_due_date).fromNow() })
        : t('no_due_date'),
    progress: (userData) => {
      let progress = userData.plan_progress * 100
      progress = progress.toFixed(1)

      const completed = progress >= 100
      const col = completed ? Style.vars.deprecatedColors.green : Style.vars.deprecatedColors.red
      const progressStyle = { color: col }

      // Key is used for sorting
      return (
        <span key={progress} style={progressStyle}>
          {progress}
        </span>
      )
    },
  }

  constructor() {
    super()
    this.state = {
      deleteModalCallback: null,
    }
  }

  showDeleteModal = (confirmCallback) => {
    this.setState({ deleteModalCallback: confirmCallback })
    this.refs.deleteModal.show()
  }

  deleteConfirmClick = () => {
    this.state.deleteModalCallback()
    this.refs.deleteModal.hide()
  }

  getHeaders() {
    return Im.freeze(_.keys(EnrollmentsCollection.tableDataMapping))
  }

  getRows() {
    const funcs = _.values(EnrollmentsCollection.tableDataMapping)
    return this.props.enrollments.map((userData) => Im.freeze(funcs.map((f) => f(userData))))
  }

  render() {
    const headers = this.getHeaders()
    const rows = this.getRows()
    const name = this.props.trainingPlan.name
    return (
      <div>
        <InfiniteScroll
          loadMore={this.props.loadMore}
          moreAvailable={this.props.moreAvailable}
          isLoading={this.props.isLoading}
        >
          <DeprecatedScrollableDataTable
            headers={headers}
            rows={rows}
            bodyHeight={null}
            ref="table"
            style={styles.table}
            exportEnabled
          />
        </InfiniteScroll>
        <Modal
          ref="deleteModal"
          onConfirm={this.deleteConfirmClick}
          header="Are you sure you want to remove this enrollment?"
          basic
        />
      </div>
    )
  }
}

@reactMixin.decorate(remoteSearchMixinFactory(PageState.ActionCreators.setSearch))
export class TrainingPlanEnrollmentsPage extends React.Component {
  static data = {
    enrollments: {
      required: false,
      many: true,
      fields: ['id', $y.getFields(EnrollmentsCollection, 'enrollments')],
    },
    trainingPlan: {
      required: false,
      fields: ['id', 'url', 'name', 'thumbnail_url'],
    },
  }

  static propTypes = $y.propTypesFromData(TrainingPlanEnrollmentsPage, {
    currentUser: PropTypes.object.isRequired,
    loading: PropTypes.bool,
  })

  showEnrollModal = () => {
    this.refs.enrollModal.show()
  }

  render() {
    const plan = this.props.trainingPlan
    return (
      <Panel>
        <BoxHeader
          heading={plan ? `${plan.name}` : ''}
          imgSrc={plan ? plan.thumbnail_url : null}
          backOpts={{
            text: 'Back',
          }}
        />
        <BoxContent>
          {this.getSearchInput()}
          <LoadingContainer
            loadingProps={[this.props.trainingPlan, this.props.enrollments]}
            createComponent={() => (
              <EnrollmentsCollection
                user={this.props.user}
                trainingPlan={this.props.trainingPlan}
                enrollments={this.props.enrollments}
                loadMore={this.props.loadMore}
                moreAvailable={this.props.moreAvailable}
                isLoading={this.props.isLoading}
              />
            )}
            shouldRenderNoData={() => !_.size(this.props.enrollments)}
            noDataText="This plan has no enrollments"
          />
        </BoxContent>
        <SetDueDateForPlanModal
          ref="enrollModal"
          currentUser={this.props.currentUser}
          selectedTrainingPlans={[this.props.trainingPlan]}
        />
      </Panel>
    )
  }
}

export const Page = createPaginatedStateContainer(TrainingPlanEnrollmentsPage, {
  mixins: [],

  contextTypes: {
    routeParams: PropTypes.object.isRequired,
  },

  listenTo: [TrainingPlansState.Store, PageState.Store],

  paginate: {
    store: UsersState.Store,
    propName: 'enrollments',
    limit: 20,
    getQuery() {
      const query = {
        fields: $y.getFields(TrainingPlanEnrollmentsPage, 'enrollments'),
        ordering: 'first_name',
        distinct_for_user: true,
        is_active: true,
        has_company: true,
        has_access_to_plan: this.context.routeParams.planId,
        // Don't show staff enrollments...otherwise people see staff enrollments when
        // we have jumped between companies.
        exclude_is_staff: true,
        //To show users only from same company
        company: this.props.currentUser.company,
      }
      const search = PageState.Store.getSearch()
      if (search) {
        query.search = search
        query.ordering = '-search_rank'
      }
      const learner = this.props.currentUser.learner
      const isCompanyAdmin = learner.is_company_admin
      const isTeamManager = learner.is_learner_group_admin
      const isAreaManager = learner.is_area_manager
      if (isCompanyAdmin) {
        // Is company admin, so do not limit the query
      } else if (isAreaManager) {
        // Only show area manager members from their area
        query.users_with_area_manager = this.props.currentUser.id
      } else if (isTeamManager && learner.learner_group) {
        // Only show team manager their team members
        query.learner__learnergroups = getIdFromApiUrl(learner.learner_group)
      } else {
        // If regular user, only show own enrollments.
        query.learner__user = this.props.currentUser.id
      }
      if (!query) return query
      return query
    },
  },

  fetch: {
    trainingPlan() {
      return TrainingPlansState.Store.getItem(this.context.routeParams.planId, {
        fields: $y.getFields(TrainingPlanEnrollmentsPage, 'trainingPlan'),
      })
    },
  },

  componentDidMount() {
    PageState.Store.resetState()
  },

  pending() {
    return containerUtils.defaultPending(this, TrainingPlanEnrollmentsPage)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, TrainingPlanEnrollmentsPage, errors)
  },
})
