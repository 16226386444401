import { ANALYTICS_EVENTS } from 'core/constants'
import CompaniesState from 'state/companies'
import CompanyConnectionsState from 'state/company-connections'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import { LoadingContainer } from 'components/common/loading'
import { PrimaryButton } from 'components/common/buttons'
import PropTypes from 'prop-types'
import PublicCompaniesState from 'state/public-companies'
import Radium from 'radium'
import React from 'react'
import Style from 'style'
import containerUtils from 'utilities/containers'
import createPaginatedStateContainer from 'state/pagination'
import reactMixin from 'react-mixin'
import { remoteSearchMixinFactory } from 'components/common/search'
import { t } from 'i18n'
import PageState from './page-state'

const MIN_CCR_THRESHOLD = 1
const DEFAULT_IMG = 'https://myagi.com/img/placeholder.svg?bee6d34cc432df74f5eea677d0d12e63'

const styles = {
  removeBtn: {
    backgroundColor: Style.vars.deprecatedColors.primary,
    border: 'none',
    height: 40,
    width: 40,
    borderRadius: '50%',
    position: 'absolute',
    top: -15,
    right: -15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  removeIcon: {
    marginTop: -5,
    marginLeft: 5,
  },
  nameContainer: {
    height: 30,
    position: 'absolute',
    bottom: -31,
    width: '100%',
    textAlign: 'center',
  },
  header: {
    fontSize: 22,
    lineHeight: 1.2,
    marginTop: 50,
    textAlign: 'center',
    width: '100%',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: {
    marginBottom: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingBottom: 200,
    position: 'relative',
  },
  textContainer: {
    marginTop: 100,
    marginBottom: 50,
  },
  btnContainer: {
    position: 'absolute',
    right: 0,
    top: 50,
  },
  navBar: {
    position: 'fixed',
    height: 100,
    background: 'white',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    borderTop: '2px solid rgb(224, 237, 237)',
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  column: {
    height: 150,
    width: 150,
    background: 'white',
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    marginBottom: 50,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
  },
  errorTxt: {
    color: Style.vars.deprecatedColors.errorRed,
  },
  introContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  h2: {
    marginTop: 50,
    marginBottom: 30,
  },
  h3: {
    lineHeight: 1.5,
  },
  search: {
    container: {
      maxWidth: 800,
      margin: '0 auto',
      minWidth: 300,
    },
    marginBottom: 50,
    border: 'none',
    borderBottom: '1px solid #CCCCCC',
    borderRadius: 0,
    height: 50,
    fontSize: 20,
  },
  searchIcon: {
    marginTop: -25,
  },
}

export class CompanyCard extends React.Component {
  render() {
    const { company, selected, handleSelect } = this.props
    const extraStyles = {
      border: `1px solid ${selected ? Style.vars.deprecatedColors.primary : '#E0EDED'}`,
      backgroundImage: `url(${company.company_logo || DEFAULT_IMG})`,
    }
    return (
      <div
        onClick={() => handleSelect(company.id)}
        className="column coCard"
        style={{ ...styles.column, ...extraStyles }}
      >
        {selected && (
          <div style={styles.removeBtn}>
            <i
              className="remove icon"
              ref={(removeIcon) => (this.removeIcon = removeIcon)}
              style={styles.removeIcon}
            />
          </div>
        )}
        <div style={styles.nameContainer}>{company.name}</div>
      </div>
    )
  }
}

export class CompanyCollection extends React.Component {
  render() {
    const { companies, handleSelect, selected } = this.props
    return (
      <div className="ui equal width grid">
        {companies.map((co) => (
          <CompanyCard
            key={co.id}
            company={co}
            handleSelect={handleSelect}
            selected={selected.indexOf(co.id) > -1}
          />
        ))}
      </div>
    )
  }
}

@Radium
@reactMixin.decorate(
  remoteSearchMixinFactory(
    PageState.ActionCreators.setPublicCompanySearch.bind(PageState.ActionCreators)
  )
)
class BrandDiscoveryContainer extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  state = {
    loadingNextPage: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.preSelectConnections(nextProps)
  }

  preSelectConnections = (nextProps) => {
    // This is mainly for users who land on this page after receiving an
    // email notification about scraping being completed
    const existingConnections = PageState.Store.getSelectedCompanies()
    if (nextProps.connections && nextProps.connections.length === 0) return
    if (nextProps.connections && nextProps.connections.length && existingConnections.length === 0) {
      nextProps.connections.map((cr) => {
        PageState.ActionCreators.setSelectedCompanies(cr.supplier.id)
      })
    }
  }

  handleSelect = (id) => {
    PageState.ActionCreators.setSelectedCompanies(id)
  }

  goNext = () => {
    // This can be slow sometimes when there are too many channels,
    // so we need to disable the button and show loading icon
    this.setState({ loadingNextPage: true })
    PublicCompaniesState.Store.resetState()
    CompanyConnectionsState.Store.resetState()
    const selected = PageState.Store.getSelectedCompanies()
    analytics.track(ANALYTICS_EVENTS.CONNECTED_TO_BRANDS)
    CompaniesState.ActionCreators.doListAction('connect_to_relevant_channels', {
      to_co_ids: selected,
      from_co_id: this.props.currentUser.company.id,
    }).then((res) => {
      this.context.router.push({
        pathname: `/views/training/channels/`,
      })
      this.setState({ loadingNextPage: false })
    })
  }

  render() {
    const { companies, connections, loadMore, moreAvailable, isLoading } = this.props
    const { loadingNextPage } = this.state
    const selected = PageState.Store.getSelectedCompanies()
    return (
      <div className="ui grid container" style={styles.container}>
        <div style={styles.btnContainer}>
          <PrimaryButton
            isLoading={loadingNextPage}
            onClick={this.goNext}
            disabled={selected < MIN_CCR_THRESHOLD || loadingNextPage}
          >
            {`${t('continue')} `}
            <i className="angle right icon" />
          </PrimaryButton>
        </div>
        <span style={styles.header}>
          <span style={styles.text}>
            {`${t('connect_with_brands_you_stock')} (${selected.length} ${t('selected')})`}
          </span>
        </span>

        {this.getSearchInput({
          style: styles.search,
          iconStyle: styles.searchIcon,
          initialValue: `${t('search')}...`,
        })}
        <LoadingContainer
          ref="companiesLoadingContainer"
          loadingProps={{ companies }}
          createComponent={() => (
            <InfiniteScroll
              loadMore={loadMore}
              moreAvailable={moreAvailable}
              isLoading={isLoading}
              threshold={0}
            >
              <CompanyCollection
                {...this.props}
                selected={selected}
                handleSelect={this.handleSelect}
              />
            </InfiniteScroll>
          )}
        />
      </div>
    )
  }
}

export const Page = createPaginatedStateContainer(BrandDiscoveryContainer, {
  listenTo: [PublicCompaniesState.Store, CompanyConnectionsState.Store, PageState.Store],

  paginate: {
    store: PublicCompaniesState.Store,
    propName: 'companies',
    limit: 40,
    dependantOn: [CompanyConnectionsState.Store],
    getQuery() {
      const co = this.props.currentUser.company
      const query = {
        fields: [
          'name',
          'cover_image_thumbnail',
          'company_logo',
          'company_url',
          'is_connected_to_current_company',
        ],
        order_as_connection_suggestions_for_company: co.id,
        company_type: 'brand',
        includes_region: co.region_of_responsibility,
      }
      const search = PageState.Store.getPublicCompanySearch()
      if (search) {
        query.search = search
      }
      return query
    },
  },

  fetch: {
    connections() {
      const coId = this.props.currentUser.company.id
      const q = {
        fields: ['id', 'supplier.id'],
        dealer: coId,
        limit: 0,
      }
      return CompanyConnectionsState.Store.getItems(q)
    },
  },

  pending() {
    return containerUtils.defaultPending(this, BrandDiscoveryContainer)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, BrandDiscoveryContainer, errors)
  },
})
