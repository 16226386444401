import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useCurrentUser, useWindowWidth } from 'shared-js/utilities/hooks'
import { getPaginationProps } from 'shared-js/state/graphql'
import { isMobileWidth } from 'shared-js/utilities/browser'
import gql from 'graphql-tag'
import { t } from 'shared-js/i18n'
import styled from 'styled-components'
import { ExecutionResult } from 'graphql'
import { DataProxy } from 'apollo-cache'
import { ErrorMessage, NoChannelsMessage } from './fetch-states'
import { useBrandsFilter, useIndustryFilter } from '../context'
import SearchInfo from './search-info'
import { PageTitle, UpdateChannelContext } from '../common'
import ChannelList from './channel-list'
import {
  DiscoverableContentQuery_PublicTrainingUnit__List_edges as Channel_edges,
  DiscoverableContentQuery as Query,
  DiscoverableContentQueryVariables as Variables,
} from './gql-types/DiscoverableContentQuery'
import { MobileFilterSection } from '../filter/index'

type QueryDataType = {
  [ley: string]: {
    [key: string]: any
    totalCount: number
  }
} | null

const LOADING_SIZE = 30

const DISCOVERABLE_CONTENT = gql`
  query DiscoverableContentQuery(
    $cursor: String
    $loadingSize: Int!
    $companyID: ID!
    $suppliers: [ID]
    $industries: [ID]
  ) {
    PublicTrainingUnit__List(
      after: $cursor
      first: $loadingSize
      discoverableChannelsForCompany: $companyID
      createdByCompanies: $suppliers
      industries: $industries
      orderBy: ["id"]
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          coverImageThumbnail
          isSubscribedToByCurrentCompany
          company {
            id
            name
            companyLogo
          }
        }
      }
      totalCount
    }
  }
`

const ChannelListSection = () => (
  <Container>
    <ChannelListSectionInner />
  </Container>
)

const ChannelListSectionInner = () => {
  const currentUser = useCurrentUser()
  const windowWidth = useWindowWidth()
  const { brandsFiltered } = useBrandsFilter()
  const { industriesFiltered } = useIndustryFilter()

  const queryVariables = {
    loadingSize: LOADING_SIZE,
    companyID: currentUser.company.id,
    suppliers: brandsFiltered,
    industries: industriesFiltered,
  }

  const apolloProps = useQuery<Query, Variables>(DISCOVERABLE_CONTENT, {
    variables: queryVariables,
  })

  const updateSubscribedChannel = (cache: DataProxy, mutationResult: ExecutionResult) => {
    const queryData: QueryDataType = cache.readQuery({
      query: DISCOVERABLE_CONTENT,
      variables: queryVariables,
    })

    const isSubscribedChannel = (channel: Channel_edges) =>
      channel?.node?.id === mutationResult?.data?.SharedTrainingUnit__Create.result.trainingUnit.id

    if (queryData) {
      cache.writeQuery({
        query: DISCOVERABLE_CONTENT,
        variables: queryVariables,
        data: {
          PublicTrainingUnit__List: {
            ...queryData?.PublicTrainingUnit__List,
            edges: queryData?.PublicTrainingUnit__List.edges.map((channel: Channel_edges) => {
              if (isSubscribedChannel(channel)) {
                return {
                  ...channel,
                  node: {
                    ...channel.node,
                    isSubscribedToByCurrentCompany: true,
                  },
                }
              }

              return channel
            }),
          },
        },
      })
    }
  }

  if (apolloProps.error) return <ErrorMessage />

  const hasFetchedChannels = apolloProps.data?.PublicTrainingUnit__List

  if (!hasFetchedChannels) return null

  const hasChannelsToShow = apolloProps.data?.PublicTrainingUnit__List?.totalCount
  const hasActiveFilters = Boolean(brandsFiltered.length || industriesFiltered.length)

  if (!hasChannelsToShow) {
    return hasActiveFilters ? (
      <>
        <InfoContainer>
          <PageTitle />
          {t('click_channel_to_preview_its_contents_and_add_to_your_library')}
          {isMobileWidth(windowWidth) ? (
            <MobileFilterContainer>
              <MobileFilterSection />
            </MobileFilterContainer>
          ) : (
            <SearchInfo />
          )}
        </InfoContainer>
        <NoChannelsMessage marginTop={50} />
      </>
    ) : (
      <NoChannelsMessage />
    )
  }

  const paginationProps = getPaginationProps({
    apolloProps,
    listFieldName: 'PublicTrainingUnit__List',
  })

  return (
    <>
      <InfoContainer>
        <PageTitle />
        {t('click_channel_to_preview_its_contents_and_add_to_your_library')}
        {hasActiveFilters && !isMobileWidth(windowWidth) && <SearchInfo />}
        {isMobileWidth(windowWidth) && (
          <MobileFilterContainer>
            <MobileFilterSection />
          </MobileFilterContainer>
        )}
      </InfoContainer>
      <UpdateChannelContext.Provider value={updateSubscribedChannel}>
        <ChannelList
          pagination={paginationProps}
          channels={apolloProps?.data?.PublicTrainingUnit__List?.edges || []}
          totalCount={apolloProps?.data?.PublicTrainingUnit__List?.totalCount || 0}
          loadingSize={LOADING_SIZE}
        />
      </UpdateChannelContext.Provider>
    </>
  )
}

export default ChannelListSection

const Container = styled.div`
  flex: 2;
  margin-left: 10px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 10px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    margin-left: 0px;
    margin-right: 10px;
  }
`

const MobileFilterContainer = styled.div`
  margin-top: 20px;
`
