import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-lightweight-tooltip'
import Style from 'style/index.js'

/**
 * Info icon which  displays help information when user mouses over it.
 */
export function Info(props) {
  if (props.disabled) return <React.Fragment>{props.children}</React.Fragment>

  tooltipStyles.tooltip.width = props.content.length > 80 ? 300 : 150

  if (props.position === 'bottom') {
    tooltipStyles.tooltip = {
      ...tooltipStyles.tooltip,
      ...tooltipBottomStyles.tooltip,
    }
    tooltipStyles.arrow = tooltipBottomStyles.arrow
  }

  if (props.tooltipStyles) {
    tooltipStyles.wrapper = {
      ...tooltipStyles.wrapper,
      ...props.tooltipStyles.wrapper,
    }
    tooltipStyles.tooltip = {
      ...tooltipStyles.tooltip,
      ...props.tooltipStyles.tooltip,
    }
  }

  return (
    <Tooltip content={props.content} styles={tooltipStyles}>
      {props.children || <i className="ui info circle icon" style={{ ...style, ...props.style }} />}
    </Tooltip>
  )
}

Info.propTypes = {
  content: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom']),
  disabled: PropTypes.bool,
  style: PropTypes.object,
  tooltipStyles: PropTypes.object,
  children: PropTypes.node,
}

Info.defaultProps = {
  position: 'top',
  disabled: false,
}

const style = {
  color: Style.vars.deprecatedColors.darkGrey,
  cursor: 'pointer',
}

const tooltipStyles = {
  wrapper: {
    zIndex: 10,
  },
  tooltip: {
    textAlign: 'center',
    opacity: 0.9,
    left: '50%',
    padding: '5px',
    WebkitTransform: 'translateX(-50%)',
    msTransform: 'translateX(-50%)',
    OTransform: 'translateX(-50%)',
    transform: 'translateX(-50%)',
  },
  content: {
    whiteSpace: 'wrap',
    fontWeight: 'normal',
    fontSize: '12px',
    backgroundColor: 'transparent',
  },
}
const tooltipBottomStyles = {
  tooltip: {
    bottom: 'initial',
    top: '100%',
    marginBottom: '0px',
    marginTop: '20px',
    left: '50%',
    WebkitTransform: 'translateX(-50%)',
    msTransform: 'translateX(-50%)',
    OTransform: 'translateX(-50%)',
    transform: 'translateX(-50%)',
  },
  arrow: {
    bottom: 'initial',
    top: '-5px',
    borderTop: '0',
    borderBottom: 'solid #000 5px',
  },
}
