import React from 'react'
import styled from 'styled-components'
import Style from 'shared-js/style'
import { ExecutionResult } from 'graphql'
import { DataProxy } from 'apollo-cache'
import { t } from 'shared-js/i18n'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const UpdateChannelContext = React.createContext<
  (cache: DataProxy, mutationResult: ExecutionResult) => void
>(() => {})

const SUBSCRIBE_TO_CHANNEL = gql`
  mutation CreateSharedTrainingUnit($company: ID!, $channel: ID!) {
    SharedTrainingUnit__Create(input: { company: $company, trainingUnit: $channel }) {
      ok
      result {
        id
        trainingUnit {
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const useSubscribeToChannel = () => {
  const [subscribeToChannel] = useMutation(SUBSCRIBE_TO_CHANNEL)
  return subscribeToChannel
}

export const PageTitle = () => (
  <Title style={{ marginBottom: 15 }}>{t('add_content_from_your_network')}</Title>
)

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 0.8;
  color: ${Style.colors.grey900};
`
