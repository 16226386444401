import React from 'react'
import shortid from 'shortid'
import ReactTable from 'react-table'
import selectTableHOC from 'react-table/lib/hoc/selectTable'
import 'react-table/react-table.css'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'

export const COMPLETE = 'COMPLETE'
export const MATCH_RATIO = 'match_ratio'
export const MYAGI_ID = 'myagi_id'
export const USER_COUNT = 'user_count'
export const PERFECT_MATCH_RATIO = 100
const DEFAULT_COLUMN_WIDTH = 250
export const SELECTED_KEY_PREFIX = 'select-'

const SelectableTableHOC = withFixedColumns(selectTableHOC(ReactTable))

const COLUMNS = [
  {
    Header: 'Dealer Name',
    accessor: 'dealer_name',
    width: DEFAULT_COLUMN_WIDTH,
    fixed: 'left',
  },
  {
    Header: 'Website',
    accessor: 'dealer_website',
    width: DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Email',
    accessor: 'dealer_email',
    width: DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Match Ratio',
    accessor: 'match_ratio',
    width: DEFAULT_COLUMN_WIDTH,
    fixed: 'left',
  },
  {
    Header: 'Myagi Id',
    accessor: 'myagi_id',
    width: DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Myagi Name',
    accessor: 'company',
    width: DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Region of Responsibility',
    accessor: 'region',
    width: DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Website',
    accessor: 'website',
    width: DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'User count',
    accessor: 'user_count',
    width: DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Last activity',
    accessor: 'last_activity',
    width: DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Connected by Channels',
    accessor: 'channels',
    width: DEFAULT_COLUMN_WIDTH,
  },
  {
    Header: 'Unclaimed',
    accessor: 'unclaimed',
    width: DEFAULT_COLUMN_WIDTH,
  },
]

export default class SelectableTable extends React.Component {
  constructor(props) {
    super(props)
    const { rows, perfectMatches } = this.getRows()
    this.state = {
      rows,
      isLoading: false,
      selection: perfectMatches,
    }
  }

  getRows = () => {
    const { data } = this.props
    const rowsData = data.slice(1, data.length)
    const rows = []
    const perfectMatches = []
    rowsData.forEach((row) => {
      const _id = shortid.generate()
      const newRow = { _id }
      row.forEach((r, i) => {
        if (
          COLUMNS[i].accessor === MATCH_RATIO ||
          COLUMNS[i].accessor === MYAGI_ID ||
          COLUMNS[i].accessor === USER_COUNT
        ) {
          // Need to make sure numbers are actually typeof Number
          // otherwise sorting won't work properly
          r = Number(r)
          if (r === PERFECT_MATCH_RATIO) {
            perfectMatches.push(`${SELECTED_KEY_PREFIX}${newRow._id}`)
          }
        }
        newRow[COLUMNS[i].accessor] = r
      })
      rows.push(newRow)
    })
    return { rows, perfectMatches }
  }

  getMyagiIdFromSelection = () => {
    const { rows, selection } = this.state
    const myagi_ids = []
    selection.forEach((s) => {
      rows.forEach((r) => {
        if (`${SELECTED_KEY_PREFIX}${r._id}` === s) {
          myagi_ids.push(Number(r.myagi_id))
        }
      })
    })
    return myagi_ids
  }

  toggleSelection = (key) => {
    const { updateSelection } = this.props
    let selection = [...this.state.selection]
    const keyIndex = selection.indexOf(key)
    if (keyIndex >= 0) {
      selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)]
    } else {
      selection.push(key)
    }
    updateSelection(this.getMyagiIdFromSelection())
    this.setState({ selection })
  }

  onRowClick = (rowInfo) => {
    const { _id } = rowInfo.original
    const selectId = `${SELECTED_KEY_PREFIX}${_id}`
    this.toggleSelection(selectId)
  }

  isSelected = (key) => {
    const { selection } = this.state
    return selection.includes(`${SELECTED_KEY_PREFIX}${key}`)
  }

  render() {
    const { selectType, rows } = this.state
    const { toggleSelection, isSelected } = this
    const extraProps = {
      isSelected,
      toggleSelection,
      selectType,
    }

    return (
      <SelectableTableHOC
        columns={COLUMNS}
        data={rows}
        ref={(r) => (this.selectTable = r)}
        className="-striped -highlight"
        resizable={false}
        defaultSorted={[
          {
            id: 'match_ratio',
            desc: true,
          },
        ]}
        getTrProps={(state, rowInfo, column, instance) => ({
          onClick: (e, o) => this.onRowClick(rowInfo),
        })}
        {...extraProps}
      />
    )
  }
}
