import React from 'react'
import reactMixin from 'react-mixin'
import { HoverMixin } from 'components/common/hover'
import Style from 'style'

const styles = {
  socialButton: {
    marginTop: 10,
    width: 50,
    height: 50,
    borderRadius: 25,
    paddingLeft: 11,
    background: 'none',
    border: '2px solid ',
    backgroundColor: Style.vars.deprecatedColors.white,
  },
  socialButtonHover: {
    color: Style.vars.deprecatedColors.white,
  },
  socialButtonsContainer: {
    padding: '0px 50px 20px 50px',
    textAlign: 'center',
  },
  socialLink: {
    padding: 12,
  },
}

@reactMixin.decorate(HoverMixin)
class SocialButton extends React.Component {
  render() {
    const primary = Style.vars.deprecatedColors.primary.toString().startsWith('#ff')
      ? Style.vars.deprecatedColors.darkPrimary
      : Style.vars.deprecatedColors.primary
    let style = this.getHoverStyle(
      { ...styles.socialButton, color: primary, borderColor: primary },
      { ...styles.socialButtonHover, backgroundColor: primary }
    )
    style = Style.funcs.merge(style, this.props.style)
    return (
      <button
        {...this.getHoverProps()}
        style={style}
        onClick={this.props.onClick}
        onTouchEnd={this.props.onClick}
      >
        {this.props.children}
      </button>
    )
  }
}

export class SocialLoginButtons extends React.Component {
  render() {
    return (
      <div className="social-signin-btn" style={styles.socialButtonsContainer}>
        {/* Google login does not work for subdomains, so just hide it. */}
        {!this.props.subdomain && (
          <a href="/accounts/google/login/?process=login" style={styles.socialLink}>
            <SocialButton className="btn g-button">
              <i className="google icon" />
            </SocialButton>
          </a>
        )}
        <a href="/accounts/facebook/login/?process=login" style={styles.socialLink}>
          <SocialButton className="btn g-button">
            <i className="facebook icon" />
          </SocialButton>
        </a>
        <a href="/accounts/linkedin/login/?process=login" style={styles.socialLink}>
          <SocialButton className="btn g-button">
            <i className="linkedin icon" />
          </SocialButton>
        </a>
      </div>
    )
  }
}
