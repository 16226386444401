import { PropTypes } from 'prop-types'

export function SignupFromShareLink(props, context) {
  const { router } = context
  const { link } = props.params
  router.push({ pathname: `/signup/user/${link}`, state: { link } })
  return null
}

SignupFromShareLink.contextTypes = {
  router: PropTypes.object.isRequired,
}
