import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import Textarea from 'react-autosize-textarea'
import TrainingPlansState from 'state/training-plans'
import { Info } from 'components/common/info'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useToastMessage, useHover, useCurrentUser } from 'shared-js/utilities/hooks'
import { EditableImage, useImageMutation } from 'components/common/image'
import { PlanStats as BasePlanStats } from './stats'

const COMPRESS_LAYOUT = '@media screen and (maxWidth: 1100px)'

export function PlanDetails({ plan, isEditable }) {
  const depricatedOnPlanUpdate = () => {
    TrainingPlansState.ActionCreators.resetLocalData()
  }

  return (
    <div style={styles.planDetailContainer}>
      <EditablePlanImage plan={plan} isEditable={isEditable} onChange={depricatedOnPlanUpdate} />

      <EditablePlanDetails plan={plan} isEditable={isEditable} />

      <PlanStats plan={plan} />
    </div>
  )
}
PlanDetails.propTypes = {
  plan: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
}
PlanDetails.defaultProps = {
  isEditable: false,
}
PlanDetails.data = {
  channels: {
    required: true,
    fields: ['id', 'name', 'url'],
  },
  plan: {
    required: true,
    fields: ['training_units', 'description', 'thumbnail_url'],
  },
}

function EditablePlanImage({ plan, isEditable, onChange }) {
  const planImageMutation = useImageMutation(UPDATE_PLAN_IMAGE)

  const uploadimage = (image) => {
    const variables = { planId: plan.id, image }
    planImageMutation({ variables }).then(onChange)
  }

  return (
    <EditableImage
      src={plan.thumbnail_url}
      isEditable={isEditable}
      name="custom_thumbnail"
      aspectRatio={16 / 9}
      style={styles.planImage}
      cropperWidth={400}
      onSubmit={uploadimage}
      renderCropTip={() => <div style={styles.planImage}>{t('leave_thumbnail_blank')}</div>}
    />
  )
}
EditablePlanImage.propTypes = {
  plan: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
}

const UPDATE_PLAN_IMAGE = gql`
  mutation UpdatePlanImage($planId: ID!, $image: Upload!) {
    TrainingPlan__Update(input: { id: $planId, customThumbnail: $image }) {
      result {
        id
        customThumbnail
        thumbnailUrl
      }
      errors {
        messages
      }
    }
  }
`

function EditablePlanDetails({ plan, isEditable, onChange }) {
  const toast = useToastMessage()
  const [name, setName] = useState(plan.name)
  const [description, setDescription] = useState(plan.description)
  const [dirty, setDirty] = useState(false)
  const [isHovered, bindIsHovered] = useHover()
  const [planDetailsMutation, {}] = useMutation(UPDATE_PLAN_DETAILS)

  const updateDetails = () => {
    if (!dirty) return
    const variables = { planId: plan.id, name, description }
    planDetailsMutation({ variables })
      .then((result) => {
        toast.showPositive(t('saved'))
        setDirty(false)
        if (onChange) onChange(result)
      })
      .catch(() => {
        toast.showNegative(t('error_updating_details'))
      })
  }

  const handleEnter = (event) => {
    // If user presses enter, save changes
    if (event.charCode === 13) {
      event.preventDefault()
      event.stopPropagation()
      updateDetails()
    }
  }

  const textAreaStyles = {
    ...styles.textArea,
    ...(isEditable && (isHovered || dirty) && styles.textAreaHover),
    pointerEvents: isEditable ? 'auto' : 'none',
  }

  return (
    <div style={styles.detailsContainer} {...bindIsHovered}>
      <Textarea
        style={{ ...textAreaStyles, ...styles.planName }}
        placeholder={t('name')}
        type="text"
        key="planName"
        value={name}
        onKeyPress={handleEnter}
        onChange={(event) => {
          setName(event.target.value)
          setDirty(true)
        }}
        onBlur={updateDetails}
        disabled={!isEditable}
      />
      <Textarea
        style={{ ...textAreaStyles, ...styles.planDescription }}
        key="planDescription"
        placeholder={t('description')}
        value={description}
        onKeyPress={handleEnter}
        onChange={(event) => {
          setDescription(event.target.value)
          setDirty(true)
        }}
        onBlur={updateDetails}
        disabled={!isEditable}
      />
    </div>
  )
}
EditablePlanDetails.propTypes = {
  plan: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
}

const UPDATE_PLAN_DETAILS = gql`
  mutation UpdatePlanDetails($planId: ID!, $name: String!, $description: String!) {
    TrainingPlan__Update(input: { id: $planId, name: $name, description: $description }) {
      result {
        id
        name
        description
      }
      errors {
        messages
      }
    }
  }
`

function PlanStats({ plan }) {
  const currentUser = useCurrentUser()
  const isExternalPlan = plan.owner.url && currentUser.company.url !== plan.owner.url

  return (
    <div style={styles.statsContainer}>
      {isExternalPlan && (
        <div style={{ flex: 1 }}>
          <div style={styles.statsCreatedBy}>{t('created_by')}</div>

          <div style={styles.statsPlanOwner}>
            <img src={plan.owner.company_logo} style={styles.statsCompanyLogo} />

            <div style={styles.statsCompanyName}>{plan.owner.name}</div>
            <Info
              style={{ marginLeft: 5 }}
              content={t('only_plan_creator_may_change', {
                companyName: plan.owner.name,
              })}
            />
          </div>
        </div>
      )}
      <div style={styles.baseStats}>
        <BasePlanStats plan={plan} currentUser={currentUser} />
      </div>
    </div>
  )
}
PlanStats.propTypes = {
  plan: PropTypes.object.isRequired,
}

const styles = {
  planDetailContainer: {},

  imageContainer: {
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    width: 400,
    maxWidth: '100%',
    position: 'relative',
  },
  imageContainerHover: {
    backgroundColor: '#fff',
  },
  planImage: {
    width: 400,
    maxWidth: '100%',
  },
  editImageContainer: {
    pointer: 'cursor',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editIcon: {
    color: 'white',
    transition: 'all 0.3s ease',
    opacity: 1,
    fontSize: '2rem',
    lineHeight: '2rem',
  },
  editModal: {
    display: 'flex',
    justifyContent: 'center',
  },
  editModalMessage: {
    marginBottom: 10,
  },

  detailsContainer: {
    flex: 1,
    margin: '10px 0px',
  },
  textArea: {
    border: '1px solid rgba(0,0,0,0)',
    outline: 'none',
    width: '100%',
    resize: 'none',
    marginBottom: 7,
    transition: 'all 0.3s ease',
    borderRadius: 10,
    padding: '4px 6px',
  },
  textAreaHover: {
    border: '1px solid #ddd',
  },
  planName: {
    fontSize: '2rem',
    fontWeight: 200,
    lineHeight: '30px',
  },
  planDescription: {
    maxHeight: 112,
  },

  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 5,
    flexWrap: 'wrap',
    [COMPRESS_LAYOUT]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  statsCompanyLogo: {
    maxWidth: 26,
    maxHeight: 20,
    marginRight: 5,
  },
  statsCreatedBy: {
    paddingBottom: 4,
  },
  statsPlanOwner: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  statsCompanyName: {
    fontSize: '1.3rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#888',
  },
  baseStats: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
}
