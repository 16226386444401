/* eslint-disable react/jsx-no-literals */
import PropTypes from 'prop-types'
import React from 'react'
import UsersState from 'state/users'

import { Modal } from 'components/common/modals/index'
import { getIdFromApiUrl } from 'utilities/generic'
import { LoadingSpinner } from 'components/common/loading'
import { UserSearchableSelect } from '../common'

export class MergeAttemptData extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      userId: null,
      loading: false,
    }
  }

  onUserSelect = (val) => {
    let userId = null
    if (val) {
      userId = getIdFromApiUrl(val)
    }
    this.setState({ ...this.state, userId })
  }

  save = () => {
    this.setState({ ...this.state, loading: true })
    UsersState.ActionCreators.doDetailAction(this.props.user.id, 'move_attempt_data_from_user', {
      from_user_id: this.state.userId,
    }).then((r) => {
      this.setState({ userId: null, loading: false })
      this.context.displayTempPositiveMessage({
        heading: `Attempts moved to ${this.props.user.email}`,
      })
      this.modal.hide()
    })
  }

  render() {
    const selectedValidUser = this.state.userId && this.state.userId != this.props.user.id

    return (
      <div>
        <button type="button" className="ui button purple" onClick={() => this.modal.show()}>
          Merge Attempt Data
        </button>
        <Modal
          ref={(el) => (this.modal = el)}
          header="Merge Attempt Data"
          closeOnDimmerClick={false}
        >
          <div className="content">
            <p>
              {`All attempt data will be moved to `}
              <b>{this.props.user.full_name}</b>
              {` (${this.props.user.id}) `}
              {`from:`}
            </p>

            <UserSearchableSelect onChange={this.onUserSelect} />

            <p>
              <b>
                {`The selected user `}
                {this.state.userId && `with ID ${this.state.userId} `}
                will be deactivated.
              </b>
            </p>

            {this.state.loading && <LoadingSpinner />}

            <button
              type="button"
              className="ui blue button"
              onClick={this.save}
              disabled={!selectedValidUser || this.state.loading}
            >
              {`Yes, merge data into ${this.props.user.full_name}'s profile`}
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}
