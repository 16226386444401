import { LoadingContainer } from 'components/common/loading'
import { Modal } from 'components/common/modals'
import { t } from 'i18n'
import Marty from 'marty'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import { Link } from 'react-router'
import ChannelsState from 'state/channels'
import CompaniesState from 'state/companies'
import TrainingPlanTrainingUnitsState from 'state/training-plan-training-units'
import TrainingPlansState from 'state/training-plans'
import Style from 'style'
import containerUtils from 'utilities/containers'
import { resolve } from 'utilities/deprecated-named-routes'
import $y from 'utilities/yaler'
import { EditButton } from '../../../common/edit-button'
import { ChannelsModal } from './modal/modal'
import PageState from './state'

const styles = {
  container: {},
  channel: {
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    borderRadius: 5,
    border: '1px solid #fff',
    cursor: 'pointer',
    padding: 4,
    ':hover': {
      transform: 'translateX(5px)',
    },
  },
  autoenrollTxt: {
    color: Style.vars.deprecatedColors.green,
  },
  title: {
    fontWeight: '200',
    fontSize: '1.6rem',
    marginBottom: 10,
  },
  img: {
    height: 50,
    width: 50,
    marginRight: 10,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  name: {
    color: '#111',
  },
  warning: {
    maxWidth: 300,
  },
}

@Radium
export class ChannelsSectionInner extends React.Component {
  static data = {
    channels: {
      required: true,
      fields: [
        'id',
        'name',
        'url',
        'logo',
        'training_plans.url',
        'training_plans.training_units',
        'subscribed_companies_count',
        'auto_enroll_turned_on_for_current_user_company',
        'company',
        'company.company_logo',
      ],
    },
  }

  planChannels = () => _.filter(this.props.channels, (c) => {
    const planURLS = c.training_plans.map((plan) => plan.url)
    return planURLS.indexOf(this.props.plan.url) > -1
  })

  render() {
    const channels = this.planChannels()
    return (
      <div style={styles.container}>
        <div>
          <div style={styles.title}>{t('channels')}</div>
          {/* Warn if plan does not belong to a channel, because it will not
          be accessible in the mobile app */}
          {channels.length === 0 && (
            <div className="ui red message" style={styles.warning}>
              <div className="header">{t('this_plan_is_not_accessible')}</div>
              <p>{t('plan_does_not_belong_to_channel')}</p>
            </div>
          )}
          {channels.map((c) => (
            <Link to={resolve('channel', { channelId: c.id })} key={c.id}>
              <div style={styles.channel} key={c.id}>
                <div
                  style={{
                    ...styles.img,
                    backgroundImage: `url(${c.logo || c.company.company_logo})`,
                  }}
                />
                <div style={styles.name}>
                  {c.name}
                  {' '}
                  <br />
                  {c.auto_enroll_turned_on_for_current_user_company && (
                    <span style={styles.autoenrollTxt}>
(
                      {t('auto_enroll_on')}
)
                    </span>
                  )}
                </div>
              </div>
            </Link>
          ))}
          <EditButton
            onClick={() => {
              this.refs.channelModal.show()
            }}
            length={channels.length}
          />
        </div>

        <Modal ref="channelModal" header={t('select_channels')}>
          <ChannelsModal {...this.props} />
        </Modal>
      </div>
    )
  }
}

export class ChannelsSectionContainer extends React.Component {
  render() {
    return (
      <LoadingContainer
        loadingProps={{
          channels: this.props.channels,
        }}
        createComponent={(props) => <ChannelsSectionInner {...this.props} />}
      />
    )
  }
}

export const ChannelsSection = Marty.createContainer(ChannelsSectionContainer, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
  },
  listenTo: [
    TrainingPlansState.Store,
    CompaniesState.Store,
    ChannelsState.Store,
    PageState.Store,
    TrainingPlanTrainingUnitsState.Store,
  ],
  fetch: {
    channels() {
      const learner = this.props.currentUser.learner
      if (!learner) return
      const co = this.props.currentUser.company
      if (!co) return
      const q = ChannelsState.Store.getItems(
        {
          company: co.id,
          fields: $y.getFields(ChannelsSectionInner, 'channels'),
          limit: 0,
          ordering: 'name',
        },
        { dependantOn: TrainingPlanTrainingUnitsState.Store }
      )
      return q
    },
  },

  pending() {
    return containerUtils.defaultPending(this, ChannelsSectionContainer)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, ChannelsSectionContainer, errors)
  },
})
