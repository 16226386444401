import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import Select from 'react-select'
import t from 'shared-js/i18n'
import { convertDataToReadableFormat } from './utils'

const SearchableAsyncSelect = ({
  query,
  extraOpts = {},
  defaultOptions,
  entityName,
  onChange,
  multi,
  placeholder,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultOptions)
  const [search, setSearch] = useState('')

  const variables = {
    search,
    orderBy: search ? ['-search_rank'] : ['name'],
    ...extraOpts,
  }
  const { data } = useQuery(query, { variables })

  const options =
    data && data[entityName] ? convertDataToReadableFormat(data[entityName].edges) : []

  function loadOptions(input, callback) {
    setSearch(input)
    callback(null, { options, complete: false })
  }

  function onSelectChange(items) {
    setSelectedOptions(items)
    onChange(items)
  }

  return (
    <Select.Async
      value={selectedOptions}
      options={options}
      onChange={onSelectChange}
      loadOptions={loadOptions}
      multi={multi}
      placeholder={placeholder || t('select')}
    />
  )
}

export default SearchableAsyncSelect
