import { EmbedlyCard } from 'components/common/media-players/embed'
import { t } from 'i18n'
import Radium from 'radium'
import React from 'react'
import Style from 'style'
import gql from 'graphql-tag'

const EXPAND_LAYOUT = '@media screen and (min-width: 1300px)'

const styles = {
  container: {
    width: '100%',
    backgroundColor: '#f6f6f6',
    padding: '10px 5%',
    textAlign: 'left',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0px',
  },
  fieldTitle: {
    fontSize: '1.6rem',
    marginBottom: 5,
  },
  bullet: {
    marginRight: 5,
    fontSize: '0.6rem',
  },
  fieldContent: {
    display: 'flex',
    margin: 5,
  },
  learnItemsVideoRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [Style.vars.media.largeScreen]: {
      flexWrap: 'nowrap',
    },
  },
  learnItems: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    width: '100%',
    [Style.vars.media.largeScreen]: {
      width: 321,
    },
  },
  learnItem: {
    width: 320,
  },
  video: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
}

@Radium
export class ChannelAbout extends React.Component {
  static data = {
    channel: {
      fragment: gql`
        fragment ChannelAbout_channel on TrainingUnitType {
          video
          learnItems
          description
        }
      `,
    },
  }

  render() {
    const { channel } = this.props
    const learnItems = channel.learnItems || channel.learn_items
    if (!channel.video && !channel.description && !learnItems.length) {
      return null
    }
    return (
      <div style={styles.container}>
        <div style={styles.learnItemsVideoRow}>
          {learnItems.length > 0 && (
            <div style={styles.field}>
              <div style={styles.fieldTitle}>{t('what_youll_learn')}</div>
              <div style={styles.learnItems}>
                {learnItems.map((l) => (
                  <div
                    style={{
                      ...styles.fieldContent,
                      ...styles.learnItem,
                    }}
                    key={l}
                  >
                    <i className="ui icon circle" style={styles.bullet} />
                    {l}
                  </div>
                ))}
              </div>
            </div>
          )}
          {channel.video && (
            <div style={styles.video}>
              <EmbedlyCard url={channel.video} />
            </div>
          )}
        </div>

        {channel.description && (
          <div style={styles.field}>
            <div style={styles.fieldTitle}>{t('description')}</div>
            {channel.description.split('\n').map((text, i) => (
              <div key={i} style={{ marginBottom: 8 }}>
                {text}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
