import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import _ from 'lodash'
import cx from 'classnames'
import { t } from 'i18n'
import { resolve } from 'utilities/deprecated-named-routes'
import UsersState from 'state/users'

import { APP_DEEP_LINKS } from 'core/constants'

import Style from 'style'

const CALENDARLY_LINK = 'https://calendly.com/sam-parsons/30mins'

export default class Announcement extends React.Component {
  constructor() {
    super()
    this.state = {
      show: true,
    }
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  dismiss = (evt) => {
    evt.stopPropagation()
    this.setState({ show: false })
  }

  isAppDeepLink = (url) => {
    let isAppDeepLink = false
    _.forEach(APP_DEEP_LINKS, (value) => {
      const regexp = new RegExp(value)
      if (regexp.test(url)) {
        isAppDeepLink = true
      }
    })
    return isAppDeepLink
  }

  openIntercom = () => {
    const scheduleMeetingMessage = `Hi Myagi Team,

I would like to schedule a meeting with my account manager regarding my subscription.`
    if (window.Intercom) {
      window.Intercom('showNewMessage', scheduleMeetingMessage)
    }
  }

  releaseHijack = () => {
    UsersState.ActionCreators.doDetailAction(this.props.currentUser.id, 'release_hijack', {
      user_id: this.props.currentUser.id,
    }).then((res) => {
      window.location.reload()
    })
  }

  navigateToSettings = () => {
    this.context.router.push(resolve('settings'))
  }

  render() {
    // There are currently two types of possible announcment:
    // 1) The company's subscription is paused, and their content will be hidden.
    // 2) Prompt to download the iOS app.
    // 2) You're Myagi staff, and you've "hijacked" a user's account.
    if (this.props.currentUser) {
      let subscription
      const learner = this.props.currentUser.learner
      const company = this.props.currentUser.company
      if (company) subscription = company.subscription
      let message
      if (learner.is_company_admin && subscription && subscription.paused) {
        message = (
          <div>
            Your subscription has been paused & your training content is temporarily hidden from all
            users. Please
            {' '}
            <u style={{ cursor: 'pointer' }} onClick={this.openIntercom}>
              click here
            </u>
            &nbsp;to contact a Myagi staff and schedule a meeting with an account manager.
          </div>
        )
      }
      if (this.props.currentUser.is_hijacked_user) {
        message = (
          <div>
            You are currently in
            {' '}
            {`${this.props.currentUser.first_name} ${this.props.currentUser.last_name}`}
            's account. &nbsp;
            <u style={{ cursor: 'pointer' }} onClick={this.releaseHijack}>
              Click here
            </u>
            &nbsp;to switch back to your own account.
          </div>
        )
      }
      if (message && this.state.show) {
        return (
          <div style={styles.container}>
            <i style={styles.dismiss} className="remove icon" onClick={this.dismiss} />
            {message}
          </div>
        )
      }
    }
    return null
  }
}

const styles = {
  container: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    padding: 20,
    color: Style.vars.deprecatedColors.primaryFontColor,
    backgroundColor: Style.vars.deprecatedColors.primary,
    cursor: 'default',
    zIndex: 9,
  },
  dismiss: {
    position: 'absolute',
    top: 3,
    left: 3,
    cursor: 'pointer',
  },
}
