import { CompanySearchableSelect } from 'components/common/company-searchable-select.jsx'
import { Modal } from 'components/common/modals/index'
import PropTypes from 'prop-types'
import React from 'react'
import { Icon } from 'semantic-ui-react'
import CompaniesState from 'state/companies'

const styles = {
  removeIcon: {
    cursor: 'pointer',
  },
}

class AddCompaniesModal extends React.Component {
  state = {
    company: null,
  }

  onCompanySelect = (company) => {
    this.setState({ company })
  }

  show = () => {
    this.changeCompaniesModal.show()
  }

  hide = () => {
    this.changeCompaniesModal.hide()
  }

  saveCompanySelection = () => {
    this.props.saveCompanySelection(this.state.company)
    this.hide()
  }

  render() {
    return (
      <Modal
        ref={(changeCompaniesModal) => (this.changeCompaniesModal = changeCompaniesModal)}
        header="Add Company"
        closeOnDimmerClick={false}
      >
        <div className="content">
          <CompanySearchableSelect
            internalUse
            onChange={this.onCompanySelect}
            fetchPrivateCompanies
          />
          <button className="ui blue button" onClick={this.saveCompanySelection}>
            Save
            {' '}
            {this.state.isLoading && <div className="ui active small inline loader" />}
          </button>
        </div>
      </Modal>
    )
  }
}

export class SelectDistributors extends React.Component {
  static data = {
    company: {
      required: true,
      fields: [
        'id',
        'distributors.id',
        'distributors.url',
        'distributors.name',
        'distributor_of.id',
        'distributor_of.url',
        'distributor_of.name',
      ],
    },
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  showAddDistributorsModal = () => {
    this.addDistributorsModal.show()
  }

  showAddDistributorOfModal = () => {
    this.addDistributorOfModal.show()
  }

  removeCompany = (co, fieldName) => {
    const currentValue = this.props.company[fieldName].map((co) => co.url)
    const data = {}
    data[fieldName] = _.without(currentValue, co.url)

    CompaniesState.ActionCreators.update(this.props.company.id, data).then((res) => {
      this.context.displayTempPositiveMessage({
        heading: 'Company removed',
      })
    })
  }

  saveCompanySelection = (company, fieldName) => {
    const data = {}
    data[fieldName] = this.props.company[fieldName].map((co) => co.url)
    data[fieldName].push(company)
    CompaniesState.ActionCreators.update(this.props.company.id, data).then((res) => {
      this.context.displayTempPositiveMessage({
        heading: 'Company added',
      })
    })
  }

  renderCompany = (co, fieldName) => (
    <div key={co.id}>
      {co.name}
      {' '}
(
      {co.id}
      )
      <Icon
        onClick={() => this.removeCompany(co, fieldName)}
        name="remove"
        style={styles.removeIcon}
      />
      <br />
    </div>
  )

  render() {
    const distributors = this.props.company.distributors
    const distributorOf = this.props.company.distributor_of
    return (
      <div>
        <h3>This company distributes products to the following companies:</h3>
        <div>
          {distributors.length ? (
            <div>{distributors.map((co) => this.renderCompany(co, 'distributors'))}</div>
          ) : (
            <div>
              None
              <br />
            </div>
          )}
          <br />
          <button className="ui blue button" onClick={this.showAddDistributorsModal}>
            Add Distributor
          </button>
        </div>
        <AddCompaniesModal
          saveCompanySelection={(co) => this.saveCompanySelection(co, 'distributors')}
          ref={(addDistributorsModal) => (this.addDistributorsModal = addDistributorsModal)}
        />

        <h3>This company distributes the following brands:</h3>
        <div>
          {distributorOf.length ? (
            <div>{distributorOf.map((co) => this.renderCompany(co, 'distributor_of'))}</div>
          ) : (
            <div>
              None
              <br />
            </div>
          )}
          <br />
          <button className="ui blue button" onClick={this.showAddDistributorOfModal}>
            Add Brand
          </button>
          <br />
        </div>
        <AddCompaniesModal
          saveCompanySelection={(co) => this.saveCompanySelection(co, 'distributor_of')}
          ref={(addDistributorOfModal) => (this.addDistributorOfModal = addDistributorOfModal)}
        />
      </div>
    )
  }
}
