export const USER_TAB = 'user'
export const TEAM_TAB = 'team'
export const GROUP_TAB = 'group'
export const AREA_TAB = 'area'

export const getDisplayNameFunction = (tab) => ({
  [USER_TAB]: (user) => `${user.first_name} ${user.last_name}`,
  [TEAM_TAB]: (team) => team.name,
  [GROUP_TAB]: (group) => group.name,
  [AREA_TAB]: (area) => area.name,
}[tab])
