/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-literals */
import Marty from 'marty'
import React from 'react'
import { Link, Redirect } from 'react-router'
import { resolve } from 'utilities/deprecated-named-routes'

import NavbarState from 'components/navbar/component-state'

import { Panel, BoxHeader, BoxContent } from 'components/common/box'

const pageStyle = {
  headingContainer: {
    paddingBottom: 0,
    // Overflow visible and minHeight required for
    // export dropdown to be visible.
    overflow: 'visible',
    minHeight: '4.2em',
  },
  heading: {
    display: 'none',
  },
}

export const Page = () => (
  <Panel>
    <BoxHeader>
      <h1>Myagi Staff Menu</h1>
    </BoxHeader>
    <BoxContent>
      <Link to={resolve('company-select')}>
        <div className="ui raised segment">
          Company Admin Tool -{' '}
          <span style={{ color: 'black' }}>
            Access any company and update their details, subscription status etc...
          </span>
        </div>
      </Link>
      <br />
      <Link to={resolve('user-management')}>
        <div className="ui raised segment">
          User Admin Tool -{' '}
          <span style={{ color: 'black' }}>
            Change companies, passwords, teams & merge account data
          </span>
        </div>
      </Link>
      <br />
      <Link to={resolve('sales-dashboard')}>
        <div className="ui raised segment">
          Sales Dashboard - <span style={{ color: 'black' }}>Access company data and stuff...</span>
        </div>
      </Link>
      <br />
      <Link to={resolve('channel-management')}>
        <div className="ui raised segment">
          Channel Admin Tool -{' '}
          <span style={{ color: 'black' }}>Make bulk channel connections and tag channels</span>
        </div>
      </Link>
      <br />
      <Link to={resolve('company-review')}>
        <div className="ui raised segment">
          Company Review Tool -{' '}
          <span style={{ color: 'black' }}>Find, merge and deactivate duplicate companies</span>
        </div>
      </Link>
      <br />
      <a href="/views/myagi-staff/account-creation/">
        <div className="ui raised segment">Account Creation Tool</div>
      </a>
      <br />
      <Link to={resolve('company-edit')}>
        <div className="ui raised segment">Easily edit companies</div>
      </Link>
      <br />
      <Link to={resolve('create-microdeck')}>
        <div className="ui raised segment">Create Microdeck</div>
      </Link>
      <br />
      <Link to={resolve('generate-network-page')}>
        <div className="ui raised segment">Generate Network Report</div>
      </Link>
      <br />
      <a href="/admin" target="_blank">
        <div className="ui raised segment">Myagi Admin (Django)</div>
      </a>
    </BoxContent>
  </Panel>
)
