import React, { useState, useRef } from 'react'
import { useCurrentUser, useFeatureFlag, useEventListener } from 'shared-js/utilities/hooks'
import { t } from 'shared-js/i18n'
import ActionBar from 'components/common/action-bar'
import styled from 'styled-components'
import Style from 'shared-js/style'
import { Constants } from './common'
import DataCollectionForm from './data-collection-form'

const NAVBAR_HEIGHT = 80
const FIND_ROUTE = '/views/network/find/'
const YOUR_NETWORK_ROUTE = '/views/network/manage/'
const INVITATIONS_ROUTE = '/views/network/invitations/'
const NETWORK_GROUPS_ROUTE = '/views/network/network-groups/'
const SHARED_GROUPS_ROUTE = '/views/network/shared-groups/'

type NavItem = {
  name: string
  to: string
  condition?: boolean
}

type NetworkTabProps = {
  subNavItems: NavItem[]
  children: React.ReactNode
}

type LockedNetworkTabProps = {
  unlockNetworkFeature(): void
  showSuccessMessage: boolean
  setShowSuccessMessage(val: boolean): void
}

const Page = ({ children }: { children: React.ReactNode }) => {
  // used to unlock the network feature temporarily since useCurrentUser isn't updating
  // when writing to cache
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const currentUser = useCurrentUser()
  const companyType = currentUser.company.productSegmentType
  const findCompaniesLabel =
    companyType === Constants.DEALER ? t('find_brands') : t('find_retailers')

  const getSubNavItems = (): NavItem[] => {
    const canAccessSharedNetworkGroup = currentUser.canAccessSharedNetworkGroup ?? false
    const isStaff = currentUser.isStaff
    const isCompanyAdmin = currentUser.learner.isCompanyAdmin || false
    const isBrandCompany =
      currentUser.company.companyType.toLowerCase() === Constants.BRAND.toLowerCase()

    const subNavItems: NavItem[] = isCompanyAdmin
      ? [
          {
            name: findCompaniesLabel,
            to: FIND_ROUTE,
          },
          {
            name: t('your_network'),
            to: YOUR_NETWORK_ROUTE,
          },
          {
            name: t('invitations'),
            to: INVITATIONS_ROUTE,
          },
        ]
      : []

    const networkGroupsNavItem: NavItem = {
      name: t('network_groups'),
      to: NETWORK_GROUPS_ROUTE,
      condition: isBrandCompany && (isCompanyAdmin || isStaff),
    }

    const sharedNetworkGroupsNavItem: NavItem = {
      name: t('shared_groups'),
      to: SHARED_GROUPS_ROUTE,
      condition: canAccessSharedNetworkGroup,
    }

    subNavItems.push(networkGroupsNavItem)
    subNavItems.push(sharedNetworkGroupsNavItem)

    return subNavItems
  }

  const companyDataFormFlag = useFeatureFlag('company_data_form')

  // this unlock functionality must be done in this component to avoid state update of unmounted
  // component and cannot flush updates errors when using innerRefs and event listeners
  const unlockNetworkFeature = () => setShowSuccessMessage(false)

  return (currentUser.company.hasUnlockedNetworkFeature || !companyDataFormFlag) &&
    !showSuccessMessage ? (
    <NetworkTab subNavItems={getSubNavItems()}>{children}</NetworkTab>
  ) : (
    <LockedNetworkTab
      unlockNetworkFeature={unlockNetworkFeature}
      subNavItems={getSubNavItems()}
      showSuccessMessage={showSuccessMessage}
      setShowSuccessMessage={setShowSuccessMessage}
    >
      {children}
    </LockedNetworkTab>
  )
}

const NetworkTab = ({ subNavItems, children }: NetworkTabProps) => (
  // change <BackgroundContainer/> to <React.Fragment/> once the background color
  // is used through the app
  <BackgroundContainer>
    <ActionBar tabs={subNavItems} />
    <NetworkTabContainer>{children}</NetworkTabContainer>
  </BackgroundContainer>
)

const LockedNetworkTab = ({
  unlockNetworkFeature,
  subNavItems,
  showSuccessMessage,
  setShowSuccessMessage,
  children,
}: NetworkTabProps & LockedNetworkTabProps) => {
  // used to show the success message
  const form = useRef<HTMLDivElement>(null)

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    // there's a typescript issue that says assertion is unavoidable in this instance
    if (form.current && !form.current.contains(event.target as Node) && showSuccessMessage) {
      unlockNetworkFeature()
    }
  }

  useEventListener(document, 'click', handleClick)

  return (
    <GatedContainer>
      <Overlay>
        <div ref={form}>
          <DataCollectionForm
            unlockNetworkFeature={unlockNetworkFeature}
            // showSuccessMessage state cannot be owned by the DataCollectionForm component
            // see unlockNetworkFeature function for more info
            showSuccessMessage={showSuccessMessage}
            setShowSuccessMessage={setShowSuccessMessage}
          />
        </div>
      </Overlay>
      <BackgroundContainer>
        <ActionBar tabs={subNavItems} />
        <NetworkTabContainer>{children}</NetworkTabContainer>
      </BackgroundContainer>
    </GatedContainer>
  )
}

const BackgroundContainer = styled.div`
  background-color: ${Style.deprecatedColors.grey90};
  min-height: 100vh;
`

const NetworkTabContainer = styled.div`
  margin: 30px 32px 0px 32px;
  display: flex;
  grid-template-columns: repeat(6, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  max-width: 100%;
  padding: 0px 15px;
  @media (max-width: 768px) {
    padding: 0px;
  }
  @media (max-width: 481px) {
    margin: 30px 16px 0px 16px;
  }
`
const GatedContainer = styled.div`
  max-height: calc(100vh - ${NAVBAR_HEIGHT}px);
  overflow: hidden;
`

const Overlay = styled.div`
  width: 100vw;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 12;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`

export default Page
