import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import CommsHomePage from '.'

export const commsRoutes = (
  <Route>
    <IndexRedirect to="conversations/" />
    <Route path="conversations/" name="inbox" component={CommsHomePage} />
  </Route>
)
