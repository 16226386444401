/* eslint-disable react/jsx-no-literals */
import PropTypes from 'prop-types'
import React from 'react'
import UsersState from 'state/users'
import { TextInput } from 'components/common/form-deprecated'
import { Modal } from 'components/common/modals/index'

export class EraseUser extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      userId: '',
      incorrectUserId: true,
    }
  }

  userIdText = (event) => {
    const userId = event.target.value.trim()
    this.setState({
      userId,
      // eslint-disable-next-line eqeqeq
      incorrectUserId: userId != this.props.user.id,
    })
  }

  eraseUser = () => {
    if (this.state.incorrectUserId) return
    UsersState.ActionCreators.doDetailAction(this.props.user.id, 'erase_user', {
      user_id: this.props.user.id,
    })
      .then((res) => {
        this.context.displayTempPositiveMessage({
          heading: 'Success',
          body: res.message,
        })
        window.location.reload()
      })
      .catch((err) => {
        this.context.displayTempNegativeMessage({
          heading: 'Error',
          body: err.message,
        })
      })
    this.modal.hide()
  }

  render() {
    return (
      <div>
        <button className="ui button red" onClick={() => this.modal.show()}>
          Delete User
        </button>
        <Modal
          ref={(el) => (this.modal = el)}
          header={`Permanently Delete ${this.props.user.full_name}`}
          closeOnDimmerClick={false}
        >
          <div className="content">
            <h3 style={{ color: 'red' }}>
              {' '}
              WARNING: This will permanently delete the user. This operation cannot be undone.
            </h3>
            <p>Please enter the user's ID to confirm deletion:</p>
            <div>
              <TextInput type="text" placeholder="User ID" onChange={this.userIdText} />
            </div>
            {this.state.incorrectUserId && this.state.userId && (
              <p style={{ color: 'red' }}>User Ids do not match.</p>
            )}
            <button
              type="button"
              className="ui red button"
              onClick={this.eraseUser}
              disabled={this.state.incorrectUserId}
            >
              {`Yes, permanently delete ${this.props.user.full_name}`}
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}
