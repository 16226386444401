import { CreatePlanModal } from 'components/content-management/plans-tab/create-plan-modal'
import { PrimaryButton } from 'components/common/buttons'
import React, { useRef, useState } from 'react'
import { StaticModal } from 'components/common/modals/static'
import { t } from 'i18n'
import { AddExistingPlanModal } from './existing'

const styles = {
  modalContent: {
    textAlign: 'center',
  },
  primaryButton: {
    display: 'inline-block',
    alignSelf: 'center',
  },
}

export const AddPlanModal = (props) => {
  const createPlanModal = useRef(null)
  const [showExistingPlanModal, setShowExistingPlanModal] = useState(false)
  return (
    <React.Fragment>
      {/* This is weird, because we are rendering modals ontop of modals, we need to render
      them BEFORE the modal underneath */}
      {showExistingPlanModal && (
        <AddExistingPlanModal onClose={() => setShowExistingPlanModal(false)} />
      )}
      <CreatePlanModal ref={createPlanModal} channelId={props.params.channelId} />

      <StaticModal header={t('add_plans')} {...props} contentStyle={styles.modalContent}>
        <PrimaryButton style={styles.primaryButton} onClick={() => createPlanModal.current.show()}>
          {t('create_a_new_plan')}
        </PrimaryButton>

        <div className="ui horizontal divider">{t('or')}</div>

        <PrimaryButton style={styles.primaryButton} onClick={() => setShowExistingPlanModal(true)}>
          {t('add_existing_plan')}
        </PrimaryButton>
      </StaticModal>
    </React.Fragment>
  )
}
