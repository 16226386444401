import { Modal } from 'components/common/modals/index.jsx'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React from 'react'
import { resolve } from 'utilities/deprecated-named-routes'
import { ChannelDetailsForm } from './channel-details-form.jsx'

export class CreateChannelModal extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  show = () => {
    this.refs.modal.show()
  }

  hide = () => {
    this.refs.modal.hide()
  }

  onChannelSaved = (channel) => {
    this.hide()
    this.context.router.push(`/views/edit-content/channel/${channel.id}/plans/`)
  }

  render() {
    return (
      <Modal ref="modal" closeOnDimmerClick header={t('create_channel')}>
        <div className="content">
          <ChannelDetailsForm
            onChannelSaved={this.onChannelSaved}
            currentUser={this.props.currentUser}
          />
        </div>
      </Modal>
    )
  }
}
