import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import { Link } from 'react-router'
import { t } from 'i18n'
import Style from 'style'

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 12,
  },
  hide: {
    opacity: 0,
  },
  item: {
    position: 'relative',
    cursor: 'pointer',
    marginBottom: 10,
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    borderWidth: 3,
    borderBottomStyle: 'solid',
    borderColor: 'rgba(0,0,0,0)',
    ':hover': {
      borderColor: '#eee',
      color: 'black',
    },
  },
  itemActive: {
    borderColor: Style.vars.deprecatedColors.primary,
    color: 'black',
  },
  link: {
    transition: 'all 0.3s ease',
    color: 'grey',
    display: 'block',
  },
  indicator: {
    marginLeft: '1em',
    position: 'absolute',
    right: 0,
  },
  secondLevelLink: {
    boxShadow: 'none',
    margin: '5px 5px 5px 0',
    padding: '9px 13px',
    display: 'inline-block',
    cursor: 'pointer',
    color: Style.vars.deprecatedColors.darkGrey,
    border: `1px solid ${Style.vars.deprecatedColors.darkGrey}`,
    borderRadius: 40,
    ':hover': {
      borderColor: Style.vars.deprecatedColors.primary,
      color: Style.vars.deprecatedColors.primary,
    },
    ...Style.funcs.makeTransitionAll(),
  },
  secondLevelActiveLink: {
    color: Style.vars.deprecatedColors.primary,
    borderColor: Style.vars.deprecatedColors.primary,
  },
}

@Radium
export class PlainTabs extends React.Component {
  render() {
    return (
      <div style={{ ...styles.container, ...this.props.style }}>
        {this.props.tabs.map((tab) => {
          const active = this.props.current === tab
          return (
            <div key={tab} onClick={() => this.props.onClick(tab)}>
              <div
                style={{
                  ...styles.item,
                  ...this.props.itemStyle,
                  ...(active && styles.itemActive),
                  ...(active && this.props.itemActiveStyle),
                }}
                key={tab}
              >
                {t(tab)}

                {// If HeaderTabs are passed an object with an `indicator` key that
                // contains an array of tab names, a little dot will appear next
                // to the tab name. See components/content-management/channels-tab/page.jsx for an example.
                  this.props.indicator && this.props.indicator.includes(tab) ? (
                    <div className="ui orange empty circular mini label" style={styles.indicator} />
                  ) : null}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

@Radium
export class RouterTabs extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  render() {
    const linkStyle = this.props.secondLevel ? {} : styles.link
    return (
      <div style={{ ...styles.container, ...this.props.style }}>
        {this.props.tabs.map((tab) => {
          const active = this.context.router.isActive(tab.to)
          const itemStyle = this.props.secondLevel
            ? {
              ...styles.secondLevelLink,
              ...(active && styles.secondLevelActiveLink),
            }
            : {
              ...styles.item,
              ...this.props.itemStyle,
              ...(active && styles.itemActive),
              ...(active && this.props.itemActiveStyle),
            }
          return (
            <Link style={linkStyle} to={tab.to} key={tab.to}>
              <div style={itemStyle} key={tab.to}>
                {tab.name}
                {// If HeaderTabs are passed an object with an `indicator` key that
                // contains an array of tab names, a little dot will appear next
                // to the tab name. See components/content-management/channels-tab/page.jsx for an example.
                  (tab.indicator ||
                  (this.props.indicator && this.props.indicator.includes(tab.name))) && (
                    <span className="ui orange empty circular mini label" style={styles.indicator} />
                  )}
              </div>
            </Link>
          )
        })}
      </div>
    )
  }
}
