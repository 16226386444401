import React from 'react'
import { Checkbox, Chip } from 'ui-kit'
import MUIAutocomplete, {
  AutocompleteProps as MUIAutocompleteProps,
  GetTagProps,
  RenderOptionState,
  RenderInputParams,
} from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CloseIcon from '@material-ui/icons/Close'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import { grey, cyan, red } from '@material-ui/core/colors'

const checkBoxIcon = <CheckBoxOutlineBlankIcon />
const checkedIcon = <CheckBoxIcon />
const deleteIcon = <CloseIcon fontSize="small" />

type BaseAutocompleteProps = Omit<MUIAutocompleteProps, 'renderInput'> & {
  getOptionLabel: (option: any) => string
  error?: string
  touched?: boolean
  helperText?: string
}

const StyledTextField = withStyles(() =>
  createStyles({
    root: {
      '& label.Mui-focused': {
        color: cyan[800],
      },
      '& label.Mui-focused.Mui-error': {
        color: red[800],
      },
      '& div.MuiInputBase-root': {
        marginBottom: 18.5,
      },
      '& div.MuiInputBase-root.Mui-error': {
        marginBottom: 0,
      },
      '& p.MuiFormHelperText-contained': {
        marginTop: -10.5,
      },
      '& p.MuiFormHelperText-contained.Mui-error': {
        marginTop: 8,
      },
    },
  })
)((props: TextFieldProps) => <TextField {...props} />)

const StyledAutocomplete = withStyles(() =>
  createStyles({
    tag: {
      color: grey[900],
      background: '#fff',
    },
    inputRoot: {
      paddingRight: '40px',
      backgroundColor: fade(grey[900], 0.04),
      '&:hover': {
        backgroundColor: fade(grey[900], 0.09),
      },
      '&:after': {
        borderBottom: `2px solid ${cyan[500]}`,
      },
      '&.Mui-disabled:before': {
        borderBottomStyle: 'solid',
        borderBottomColor: '#eeeeee',
      },
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: fade(cyan[400], 0.08),
      },
      '&[data-focus="true"]': {
        backgroundColor: fade(grey[900], 0.04),
      },
    },
  })
)((props: MUIAutocompleteProps) => <MUIAutocomplete {...props} />)

const StyledCheckbox = withStyles({
  root: {
    color: cyan[700],
    '&$checked': {
      color: cyan[700],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

const StyledCircularProgress = withStyles({
  root: {
    color: grey[700],
    marginTop: '-22px',
  },
})((props: CircularProgressProps) => <CircularProgress color="inherit" size={20} {...props} />)

const BaseAutocomplete = (props: BaseAutocompleteProps) => {
  const {
    getOptionLabel,
    getOptionSelected,
    loading,
    multiple,
    onChange,
    onInputChange,
    options,
    placeholder,
    renderTags,
    renderOption,
    error,
    touched,
    helperText,
    ...other
  } = props

  const renderInput = (params: RenderInputParams) => {
    return (
      <StyledTextField
        {...params}
        variant="filled"
        label={placeholder}
        fullWidth
        InputLabelProps={{}}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <StyledCircularProgress /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
        error={Boolean(touched && error)}
        helperText={touched && error ? error : helperText}
      />
    )
  }

  return (
    <StyledAutocomplete
      {...other}
      onInputChange={onInputChange}
      loading={loading}
      getOptionSelected={getOptionSelected}
      options={options}
      onChange={onChange}
      disableCloseOnSelect={multiple}
      getOptionLabel={getOptionLabel}
      multiple={multiple}
      renderOption={renderOption}
      renderTags={renderTags}
      renderInput={renderInput}
      autoHighlight
      disableClearable
    />
  )
}

type SingleSelectAutocompleteProps = Omit<BaseAutocompleteProps, 'multiple'>
export const SingleSelect = (props: SingleSelectAutocompleteProps) => {
  const { options, loading, placeholder, getOptionLabel, onInputChange, onChange, ...other } = props

  const renderOption = (option: any) => getOptionLabel(option)

  return (
    <BaseAutocomplete
      {...other}
      getOptionLabel={getOptionLabel}
      loading={loading}
      multiple={false}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      placeholder={placeholder}
      renderOption={renderOption}
      debug
    />
  )
}

type MultiSelectAutocompleteProps = Omit<BaseAutocompleteProps, 'multiple'> & {
  optionKey: string | number
}
export const MultiSelect = (props: MultiSelectAutocompleteProps) => {
  const {
    options,
    loading,
    placeholder,
    getOptionLabel,
    onInputChange,
    optionKey,
    ...other
  } = props
  const [selectedOptions, setSelectedOptions] = React.useState<any[]>([])
  const renderOption = (option: any, { selected }: RenderOptionState) => {
    return (
      <>
        <StyledCheckbox
          icon={checkBoxIcon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {getOptionLabel(option)}
      </>
    )
  }

  const renderTags = (value: any, getTagProps: GetTagProps) => {
    return value.map((option: object, index: number) => (
      <Chip
        key={getOptionLabel(option)}
        deleteIcon={deleteIcon}
        variant="outlined"
        label={getOptionLabel(option)}
        size="small"
        {...getTagProps({ index })}
      />
    ))
  }

  const getOptionSelected = (opt: any) => {
    return selectedOptions.filter((option) => option[optionKey] === opt[optionKey]).length > 0
  }

  const onSelectionChange = (event: React.ChangeEvent<{}>, value: any) => {
    setSelectedOptions(value)
    if (props.onChange) {
      props.onChange(event, value)
    }
  }

  return (
    <BaseAutocomplete
      {...other}
      getOptionSelected={getOptionSelected}
      // loading={loading && options && !options.length}
      loading={loading}
      getOptionLabel={getOptionLabel}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      options={options}
      placeholder={placeholder}
      renderOption={(option, state) => renderOption(option, state)}
      renderTags={renderTags}
      multiple
    />
  )
}
