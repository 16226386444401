import React from 'react'

const styles = {
  preload: {
    height: '100vh',
    width: '100%',
  },
  preloadingContainer: {
    position: 'static',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    flexDirection: 'column',
  },
  preloadingContainerSpinner: {
    borderBottom: '2px solid rgba(0,0,0,0.03)',
    borderLeft: '2px solid rgba(0,0,0,0.03)',
    borderRight: '2px solid rgba(0,0,0,0.03)',
    borderTop: '2px solid rgba(0,0,0,0.3)',
    borderRadius: '50%',
    width: 50,
    height: 50,
    animation: 'loaderKeyframes 2s cubic-bezier(0.5,0.3,0.5,0.7) infinite',
    marginTop: 'auto',
  },
  preloadingLogo: {
    marginTop: 'auto',
    marginBottom: 40,
    height: 30,
  },
}

export default class Preloader extends React.Component {
  render() {
    return (
      <div style={styles.preload}>
        <div style={styles.preloadingContainer}>
          <div style={styles.preloadingContainerSpinner} />
          <img
            style={styles.preloadingLogo}
            src="https://myagi.com/public-content/img/myagi-logo.png"
          />
        </div>
      </div>
    )
  }
}
