import Marty from 'marty'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import Im from 'shared-js/immutable'
import _ from 'lodash'
import moment from 'moment-timezone'

import Style from 'style'

import { t } from 'i18n'

import { nowInISO } from 'utilities/time'

import ConsentPreferencesState from 'shared-js/state/consent-preferences'

import { Modal } from 'components/common/modals'
import { PrimaryButton } from 'components/common/buttons'

const styles = {
  btn: {
    paddingLeft: 60,
    paddingRight: 60,
    marginTop: 20,
    marginLeft: 0,
    display: 'inline-block',
    position: 'relative',
    left: '50%',
    ...Style.funcs.makeTransform('translateX(-50%)'),
  },
}

export default class ConsentModal extends React.Component {
  onAgree = () => {
    ConsentPreferencesState.ActionCreators.update(this.props.currentUser.consent_preferences.id, {
      privacy_and_terms_consent: nowInISO(),
    })
    this.modal.hide()
  }

  renderDefaultConsentMessage() {
    return (
      <React.Fragment>
        <p>{t('consent_overview')}</p>
        <a target="_blank" href="https://www.getmyagi.com/privacy">
          {t('privacy_policy')}
          {' '}
&rsaquo;
        </a>
        <br />
        <a target="_blank" href="https://www.getmyagi.com/terms">
          {t('terms_and_conditions')}
          {' '}
&rsaquo;
        </a>
        <br />
        <a target="_blank" href="/views/settings/?tab=Notifications">
          {t('comm_settings')}
          {' '}
&rsaquo;
        </a>
        <br />
        <a target="_blank" href="https://www.getmyagi.com/gdpr">
          {t('gdpr_information_and_requests')}
          {' '}
&rsaquo;
        </a>
      </React.Fragment>
    )
  }

  renderCustomConsentMessage(msg) {
    const parts = msg.split('\n')
    return (
      <p>
        {parts.map((part) => (
          <p>{part}</p>
        ))}
      </p>
    )
  }

  render() {
    const customConsent = this.props.currentUser.company.consent_message
    return (
      <Modal
        ref={(e) => (this.modal = e)}
        showOnInit={this.props.currentUser.should_request_consent}
        onHidden={this.props.onHidden}
        header={t('welcome_consent')}
        closeOnDimmerClick={false}
        closeIcon={false}
      >
        <div className="content" style={styles.content}>
          {customConsent
            ? this.renderCustomConsentMessage(customConsent)
            : this.renderDefaultConsentMessage()}
          <br />
          <PrimaryButton onClick={this.onAgree} style={styles.btn}>
            {t('i_agree')}
          </PrimaryButton>
        </div>
      </Modal>
    )
  }
}
