import React from 'react'
import Style from 'shared-js/style'
import { createMuiTheme, ThemeProvider as MaterialThemeProvider } from '@material-ui/core'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { useCurrentUser } from 'shared-js/utilities/hooks'

const StylingThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const currentUser = useCurrentUser()

  const colors = {
    ...Style.colors,
    primary: currentUser.company?.companysettings?.primaryColor || Style.colors.indigo800,
  }

  const theme = {
    colors,
  }

  const muiTheme = createMuiTheme({
    palette: {
      primary: {
        main: theme.colors.primary,
      },
      error: {
        main: theme.colors.red900,
      },
    },
  })

  return (
    <StyledComponentsThemeProvider theme={theme}>
      <MaterialThemeProvider theme={muiTheme}>{children}</MaterialThemeProvider>
    </StyledComponentsThemeProvider>
  )
}

export default StylingThemeProvider
