import React from 'react'
import Radium from 'radium'
import { t } from 'i18n'
import Style from 'style'

import NPSResponsesState from 'state/nps-responses'

import { PrimaryButton } from 'components/common/buttons'

import { checkStorageSupport } from 'utilities/storage'

const NPS_SCORES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const NPS_CO_BLACKLIST = [
  7164, // Life Style Sports
]

const styles = {
  npsContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    height: 150,
    width: '100%',
    borderTop: `5px solid ${Style.vars.deprecatedColors.grey}`,
    paddingTop: 30,
    paddingLeft: 100,
    paddingRight: 100,
    background: Style.vars.deprecatedColors.white,
    textAlign: 'center',
    zIndex: 9999,
    [Style.vars.media.mobile]: {
      height: 280,
      padding: 30,
    },
  },
  scoreContainer: {
    textAlign: 'center',
  },
  textLeft: {
    color: Style.vars.deprecatedColors.darkGrey,
    marginRight: 20,
    [Style.vars.media.mobile]: {
      display: 'block',
      marginRight: 'auto',
      marginBottom: 10,
    },
  },
  textRight: {
    color: Style.vars.deprecatedColors.darkGrey,
    marginLeft: 20,
    [Style.vars.media.mobile]: {
      display: 'block',
      marginLeft: 'auto',
      marginTop: 10,
    },
  },
  comments: {
    width: '100%',
    maxWidth: 400,
    height: 70,
    padding: 10,
  },
  scoreButton: {
    width: 50,
    height: 50,
  },
  button: {
    [Style.vars.media.mobile]: {
      display: 'block',
      marginTop: 20,
    },
  },
  removeIcon: {
    position: 'absolute',
    right: 20,
    top: 20,
    color: Style.vars.deprecatedColors.darkGrey,
    cursor: 'pointer',
    [Style.vars.media.mobile]: {
      right: 10,
      top: 10,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [Style.vars.media.mobile]: {
      flexDirection: 'column',
    },
  },
}

@Radium
export class NPSModal extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      score: null,
      hideModal: false,
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.shouldShowModal()) {
      this.hideModal()
    }
  }

  shouldShowModal = () => {
    const { learner, company } = this.props.currentUser
    const coId = company && company.id
    const completedNPS = learner.completed_nps
    const modulesCompleted = learner.num_modules_completed
    const randomNum = Math.random()

    // Comment this if-statement out to always show NPS Modal
    if (
      !company ||
      completedNPS ||
      modulesCompleted < 5 ||
      NPS_CO_BLACKLIST.includes(coId) ||
      sessionStorage.getItem('hideNPSModal') === true ||
      randomNum < 0.9 || // Only show NPS Modal 10% of the time
      learner.is_demo_account
    ) {
      return false
    }
    return true
  }

  setScore = (score, i) => {
    this.setState({ score })
  }

  hideModal() {
    this.setState({ hideModal: true })
  }

  closeModal = () => {
    if (this.state.score) {
      this.onSubmit()
    } else {
      this.hideModal()
      if (checkStorageSupport()) {
        sessionStorage.setItem('hideNPSModal', true)
      }
    }
  }

  onSubmit = () => {
    const user = this.props.currentUser
    const score = this.state.score
    const comment = this.refs.comment.value

    NPSResponsesState.ActionCreators.create({
      user: user.url,
      score,
      comment: comment || '',
      no_mods_completed: user.learner.num_modules_completed,
    })
    this.hideModal()
  }

  render() {
    if (this.state.hideModal) {
      return null
    }

    return (
      <div style={styles.npsContainer}>
        <h4>
          {!this.state.score ? (
            <span>{t('nps_recommend_friend')}</span>
          ) : (
            <span>
              <strong>{t('one_thing_to_make_myagi_amazing')}</strong>
            </span>
          )}
        </h4>
        <div style={styles.removeIcon} onClick={this.closeModal}>
          <i className="ui icon remove" />
        </div>
        <div style={styles.scoreContainer}>
          {!this.state.score ? (
            <div>
              <span style={styles.textLeft}>{t('no_likely_at_all')}</span>
              {NPS_SCORES.map((score) => (
                <button
                  className="circular ui icon basic button"
                  style={styles.scoreButton}
                  key={score}
                  onClick={this.setScore.bind(this, score)}
                >
                  {score}
                </button>
              ))}
              <span style={styles.textRight}>{t('extremely_likely')}</span>
            </div>
          ) : (
            <div style={styles.container}>
              <textarea ref="comment" style={styles.comments} placeholder={t('let_us_know')} />
              <PrimaryButton onClick={this.onSubmit} style={styles.button}>
                {t('send')}
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    )
  }
}
