import React, { useEffect, useMemo, useLayoutEffect } from 'react'
import { ApolloProvider } from 'react-apollo'
import { useQuery } from '@apollo/react-hooks'
import CompaniesState from 'state/companies'
import { DEFAULT_LANG } from 'i18n/constants'
import Im from 'shared-js/immutable'
import _ from 'lodash'
import Marty from 'marty'
import { NavBar } from 'components/navbar'
import StyleCustomization from 'style/customization'
import UsersState from 'state/users'
import { configureForLocale } from 'i18n'
import { createApolloClient } from 'shared-js/state/graphql'
import { getOrigin } from 'shared-js/utilities/http'
import { useManagedHistory } from 'shared-js/utilities/hooks/managed-history'
import { ErrorBoundary } from 'react-error-boundary'
import moment from 'moment-timezone'
import {
  AppContextProviders,
  DeprecatedAppContextProviders,
} from 'shared-js/context/app-context-providers'
import { GET_CONTEXT, flagItemsToObject } from 'shared-js/context/query'
import Fallback from 'components/common/error-fallback'
import Preloader from '../common/preloader'
import { TitleController } from './title-controller'
import { TeamSelectionModal } from './team-selection-modal'
import { RequestsReceivedModal } from './request-received-modal'
import PoweredByMyagi from './powered-by'
import { NPSModal } from './nps'
import ConsentModal from './consent-preferences-modal'
import AppState from './state'
import Announcement from './announcement'
import StyleThemeProvider from './style-theme-provider'

export function getDeprecatedCurrentUserFromSessionStorage() {
  return JSON.parse(sessionStorage.getItem('deprecatedCurrentUser'))
}

export function getApolloClient() {
  const origin = getOrigin()
  const wsOrigin = _.replace(origin, 'http', 'ws')
  // For default, cookies are sent with all requests, so no need for these
  const getAuthHeaders = () => ({})
  const { client: apolloClient } = createApolloClient({
    httpUri: `${origin}/graphql/`,
    wsUri: `${wsOrigin}/graphql/`,
    getAuthHeaders,
    cachePersistenceStorage: window.localStorage,
  })
  return apolloClient
}

/**
 * Container that provides apolloClient to all children.
 * @description A seperate wrapper component to `AppInner` to allow it to make apollo queries.
 */
function AppOuter(props) {
  const apolloClient = useMemo(getApolloClient, [])

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <ApolloProvider client={apolloClient}>
        <AppInner {...props} />
      </ApolloProvider>
    </ErrorBoundary>
  )
}

/**
 * Container that provides locale, Navbar UI, and context to all children.
 */
function AppInner({
  deprecatedCurrentUser = getDeprecatedCurrentUserFromSessionStorage(),
  ...props
}) {
  const { data, loading, error } = useQuery(GET_CONTEXT)

  const featureFlags = data && !(loading || error) ? flagItemsToObject(data.flagItems) : {}
  const company = deprecatedCurrentUser && deprecatedCurrentUser.company
  const currentUser = data && data.currentUser ? data.currentUser : null

  const defaultTrackedLocations = useManagedHistory()

  useLayoutEffect(() => {
    const locale =
      currentUser && currentUser.learner && currentUser.learner.locale
        ? currentUser.learner.locale
        : DEFAULT_LANG

    configureForLocale(locale)
  }, [currentUser])

  useEffect(() => {
    if (!deprecatedCurrentUser || !company) return
    // Set the company timezone if not already set
    if (!company.timezone) {
      const guess = moment.tz.guess()
      if (guess) {
        CompaniesState.ActionCreators.doDetailAction(company.id, 'set_timezone', {
          timezone: guess,
        })
      }
    }

    // use it for align header between current and new frontend
    sessionStorage.setItem('deprecatedCurrentUser', JSON.stringify(deprecatedCurrentUser))

    return () => {
      deprecatedCurrentUser.pending_sent_request = false
      sessionStorage.setItem('deprecatedCurrentUser', JSON.stringify(deprecatedCurrentUser))
    }
  }, [company, deprecatedCurrentUser])

  useEffect(() => {
    if (!company) return
    // Customize for the current user
    StyleCustomization.setStylingForCompany(Im.freeze(company))
    AppState.ActionCreators.setBaseTitle(`${company.name} - Myagi`)
  }, [company])

  if (loading || props.deprecatedLoading) {
    return <AppLoading currentUser={deprecatedCurrentUser} />
  }

  if (error || props.deprecatedError) {
    return <AppLoading />
  }
  // Becuase of the contidional above, we can't use any React hooks past this point.

  const showTeamSelectionModal =
    !deprecatedCurrentUser.learner.learner_group &&
    !deprecatedCurrentUser.learner.is_internal_user &&
    company &&
    !deprecatedCurrentUser.learner.is_in_child_company

  const hasRequestsToJoinPending = deprecatedCurrentUser.pending_received_requests

  return (
    <AppContextProviders
      currentUser={currentUser}
      router={props.router}
      featureFlags={featureFlags}
      managedHistory={defaultTrackedLocations}
    >
      <DeprecatedAppContextProviders
        currentUser={deprecatedCurrentUser}
        router={props.router}
        featureFlags={featureFlags}
      >
        <StyleThemeProvider>
          <TitleController />
          <NavBar currentUser={deprecatedCurrentUser} />
          <Announcement currentUser={deprecatedCurrentUser} />
          <ErrorBoundary FallbackComponent={Fallback}>
            {React.cloneElement(props.children, {
              currentUser: deprecatedCurrentUser,
            })}
          </ErrorBoundary>
          {showTeamSelectionModal && (
            <TeamSelectionModal currentUser={deprecatedCurrentUser} showOnInit />
          )}
          {hasRequestsToJoinPending && (
            <RequestsReceivedModal currentUser={deprecatedCurrentUser} showOnInit />
          )}
          <PoweredByMyagi />
          <NPSModal currentUser={deprecatedCurrentUser} />
          <ConsentModal currentUser={deprecatedCurrentUser} />
        </StyleThemeProvider>
      </DeprecatedAppContextProviders>
    </AppContextProviders>
  )
}

function AppLoading({ currentUser }) {
  // Preload container which is added by HTML file takes care of showing
  // loading spinner

  if (currentUser) {
    // use it for align header between current and new frontend
    configureForLocale(currentUser.learner.locale)
  }

  return (
    <React.Fragment>
      {currentUser && <NavBar currentUser={currentUser} isPreload />}
      <Preloader />
    </React.Fragment>
  )
}

export const App = Marty.createContainer(AppOuter, {
  listenTo: [UsersState.Store, AppState.Store],

  fetch: {
    deprecatedCurrentUser() {
      const fetch = UsersState.Store.getCurrent()
      return fetch
    },
  },

  done(results) {
    return (
      <AppOuter {...results} router={this.props.router}>
        {this.props.children}
      </AppOuter>
    )
  },

  pending() {
    return (
      <AppOuter deprecatedLoading router={this.props.router}>
        {this.props.children}
      </AppOuter>
    )
  },

  failed(error) {
    return (
      <AppOuter deprecatedError={error} router={this.props.router}>
        {this.props.children}
      </AppOuter>
    )
  },
})
