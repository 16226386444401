import React from 'react'

import MUIDialog, { DialogProps } from '@material-ui/core/Dialog'
import MUIDialogContent, { DialogContentProps } from '@material-ui/core/DialogContent'
import MUIDialogContentText from '@material-ui/core/DialogContentText'
import MUIDialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle'
import MUIDialogActions, { DialogActionsProps } from '@material-ui/core/DialogActions'
import { withStyles, createStyles } from '@material-ui/core/styles'

import { TextButton, Button, OutlinedButton } from 'ui-kit'
import { CustomButtonProps } from 'ui-kit/inputs/Button/button'

export const DialogContentText = MUIDialogContentText

type ButtonProps = { text: string } & CustomButtonProps
type BaseDialogProps = DialogProps & {
  children: React.ReactNode
  inverted?: boolean
  renderActions: () => React.ReactNode
  renderDialogTitle: () => React.ReactNode
}
const BaseDialog = (props: BaseDialogProps) => {
  const { children, renderActions, inverted, renderDialogTitle } = props

  const renderInnerComponent = () => {
    // This is needed because design requires an inverted
    // modal where action buttons are displayed at the top
    // of the modal.
    if (inverted) {
      return (
        <>
          {renderDialogTitle()}
          {renderActions()}
          {children}
        </>
      )
    }
    return (
      <>
        {renderDialogTitle()}
        {children}
        {renderActions()}
      </>
    )
  }

  return <MUIDialog {...props}>{renderInnerComponent()}</MUIDialog>
}

type UIKitDialogProps = DialogProps & {
  dialogContentProps?: DialogContentProps
  inverted?: boolean
  primaryButtonProps?: ButtonProps
  secondaryButtonProps?: ButtonProps
  title: string
}

type SimpleDialogProps = Omit<DialogProps, 'maxSize' | 'inverted'> & UIKitDialogProps

export const SimpleDialog = (props: SimpleDialogProps) => {
  const {
    children,
    dialogContentProps,
    primaryButtonProps,
    secondaryButtonProps,
    title,
    ...extraProps
  } = props

  const renderPrimaryButton = () => {
    if (!primaryButtonProps) return null
    return <TextButton {...primaryButtonProps}>{primaryButtonProps.text}</TextButton>
  }

  const renderSecondaryButton = () => {
    if (!secondaryButtonProps) return null
    return <TextButton {...secondaryButtonProps}>{secondaryButtonProps.text}</TextButton>
  }

  const renderActions = () => {
    const primaryButton = renderPrimaryButton()
    const secondaryButton = renderSecondaryButton()
    return (
      <SimpleDialogActions>
        {secondaryButton}
        {primaryButton}
      </SimpleDialogActions>
    )
  }

  const renderDialogTitle = () => <SimpleDialogTitle>{title}</SimpleDialogTitle>

  return (
    <BaseDialog
      {...extraProps}
      maxWidth="xs"
      renderActions={renderActions}
      renderDialogTitle={renderDialogTitle}
    >
      <MUIDialogContent {...dialogContentProps}>{children}</MUIDialogContent>
    </BaseDialog>
  )
}

export const Dialog = (props: UIKitDialogProps) => {
  const {
    children,
    inverted,
    dialogContentProps,
    primaryButtonProps,
    secondaryButtonProps,
    title,
    ...extraProps
  } = props

  const renderPrimaryButton = () => {
    if (!primaryButtonProps) return null
    return <Button {...primaryButtonProps}>{primaryButtonProps.text}</Button>
  }

  const renderSecondaryButton = () => {
    if (!secondaryButtonProps) return null
    return <OutlinedButton {...secondaryButtonProps}>{secondaryButtonProps.text}</OutlinedButton>
  }

  const renderActions = () => {
    const primaryButton = renderPrimaryButton()
    const secondaryButton = renderSecondaryButton()
    // If there's only going to be 1 button, center the DialogActions component
    // by using the styled component.
    let DialogActionsComponent = BaseDialogActions
    if (inverted) {
      DialogActionsComponent = InvertedDialogActions
    }
    if (!primaryButton || !secondaryButton) {
      DialogActionsComponent = CenteredDialogActions
    }
    return (
      <DialogActionsComponent>
        {secondaryButton}
        {primaryButton}
      </DialogActionsComponent>
    )
  }

  const renderDialogTitle = () => <BaseDialogTitle>{title}</BaseDialogTitle>

  return (
    <BaseDialog
      {...extraProps}
      inverted={inverted}
      renderActions={renderActions}
      renderDialogTitle={renderDialogTitle}
    >
      <MUIDialogContent {...dialogContentProps}>{children}</MUIDialogContent>
    </BaseDialog>
  )
}

const BASE_DIALOG_SPACING = {
  paddingTop: '20px',
  paddingRight: '20px',
  paddingBottom: '20px',
  paddingLeft: '20px',
}

const BaseDialogActions = withStyles(() =>
  createStyles({
    spacing: BASE_DIALOG_SPACING,
  })
)((props: DialogActionsProps) => <MUIDialogActions {...props} />)

const SimpleDialogActions = withStyles(() =>
  createStyles({
    spacing: {
      paddingTop: '16',
      paddingRight: '8px',
      paddingBottom: '16px',
      paddingLeft: '8px',
    },
  })
)((props: DialogActionsProps) => <BaseDialogActions {...props} />)

const CenteredDialogActions = withStyles(() =>
  createStyles({
    root: {
      'justify-content': 'center',
    },
    spacing: BASE_DIALOG_SPACING,
  })
)((props: DialogActionsProps) => <BaseDialogActions {...props} />)

const InvertedDialogActions = withStyles(() =>
  createStyles({
    root: {
      'justify-content': 'flex-start',
    },
    spacing: {
      // Spec show 28px top padding, DialogTitle has 16px bottom padding, so use 2px top here
      paddingTop: '12px',
      paddingRight: '20px',
      paddingBottom: '28px',
      paddingLeft: '20px',
    },
  })
)((props: DialogActionsProps) => <BaseDialogActions {...props} />)

const BaseDialogTitle = withStyles(() =>
  createStyles({
    root: {
      '& .MuiTypography-h6': {
        'font-size': '20px',
      },
    },
  })
)((props: DialogTitleProps) => <MUIDialogTitle {...props} />)

const SimpleDialogTitle = withStyles(() =>
  createStyles({
    root: {
      '& .MuiTypography-h6': {
        'font-size': '16px',
      },
    },
  })
)((props: DialogTitleProps) => <BaseDialogTitle {...props} />)
