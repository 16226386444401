import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import gql from 'graphql-tag'
import { resolve } from 'utilities/deprecated-named-routes'

import { t } from 'i18n'
import $y from 'utilities/yaler'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import { Modal } from 'components/common/modals'
import { EditPlanModal } from 'components/training/plans/edit-plan-modal'
import { TrainingPlanCardDetails } from './card'

// TODO - Create a public training plan card and have both derive from a base training plan card
// We are currently fetching fields that aren't needed in a public training plan card
// e.g successfullyCompletedByCurrentUser
export class TrainingPlanCard extends React.Component {
  static data = {
    trainingPlan: {
      required: true,
      fragment: gql`
        fragment TrainingPlanCard_plan on TrainingPlanType {
          ...TrainingPlanDetails_plan
        }
        ${TrainingPlanCardDetails.data.trainingPlan.fragment}
      `,
      fields: [
        'id',
        'url',
        'modules.id',
        'modules.url',
        'modules.successfully_completed_by_current_user',
        'owner.url',
        'owner.name',
        'user_is_enrolled',
        'owner',
        'owner.company_logo',
        $y.getFields(TrainingPlanCardDetails, 'trainingPlan'),
        $y.getFields(EditPlanModal, 'trainingPlan'),
      ],
    },
    publicTrainingPlan: {
      required: true,
      fragment: gql`
        fragment PublicTrainingPlanCard_plan on PublicTrainingPlanType {
          id
          customThumbnailSmall
          thumbnailUrl
          isPublished
          name
          owner {
            id
            companyLogo
            name
          }
          badges(orderBy: "id", first: 20, trainingUnit: $channelID, badgeActive: true) {
            edges {
              node {
                id
                name
                badgeImage
                trainingUnit {
                  id
                }
              }
            }
          }
          modules(orderBy: "id", isAttemptable: true, first: 20) {
            totalCount
            edges {
              node {
                id
                successfullyCompletedByCurrentUser
              }
            }
          }
        }
      `,
    },
    channel: {
      fragment: gql`
        fragment TrainingPlanCard_channel on TrainingUnitType {
          isSubscribedToByCurrentCompany
          existingRequestForCompany
        }
      `,
    },
    channels: $y.getData(EditPlanModal, 'channels'),
  }

  static propTypes = $y.propTypesFromData(TrainingPlanCard, {
    currentUser: PropTypes.object.isRequired,
  })

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false
    _.each(this.props, (val, key) => {
      if (nextProps[key] !== val) {
        shouldUpdate = true
      }
    })
    return shouldUpdate
  }

  continuePlan = () => {
    const nextModule = this.props.trainingPlan.next_module
    if (!nextModule) {
      this.showNoModulesWarning()
      return
    }

    this.context.router.push(
      resolve('new-module-attempt', {
        moduleId: nextModule.id,
        trainingPlanId: this.props.trainingPlan.id,
      })
    )
  }

  showNoModulesWarning = () => {
    this.refs.noModulesWarningModal.show()
  }

  showPlanDetails = () => {
    if (this.props.isPublicPage && this.props.onPlanCardClick) {
      this.props.onPlanCardClick()
    }
  }

  render() {
    return (
      <React.Fragment>
        <TrainingPlanCardDetails
          {...this.props}
          showTrainingPlanDetails={this.showPlanDetails}
          isFromANewChannel={this.props.isFromANewChannel}
          linkTo={this.props.linkTo}
          hoverIcon={this.props.hoverIcon}
          lock={this.props.lock}
        />
        <Modal ref="noModulesWarningModal" header={t('this_plan_has_no_lessons')} basic message>
          <div className="content">{t('lessons_must_be_added')}</div>
        </Modal>
      </React.Fragment>
    )
  }
}

export class TrainingPlansCollection extends React.Component {
  static data = {
    trainingPlans: {
      many: true,
      required: true,
      fields: ['id'],
    },
    channel: {
      fragment: gql`
        fragment TrainingPlansCollection_channel on TrainingUnitType {
          requireSequentialCompletion
        }
      `,
    },
  }

  static propTypes = $y.propTypesFromData(TrainingPlansCollection, {
    loadMore: PropTypes.func.isRequired,
    moreAvailable: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    enrollModeEnabled: PropTypes.bool,
    createCard: PropTypes.func.isRequired,
    requireSequentialCompletion: PropTypes.bool,
  })

  planIsComplete(tp) {
    return _.every(_.map(tp.modules.edges, (e) => e.node.successfullyCompletedByCurrentUser))
  }

  shouldLock = (i, lastComplete) => i > lastComplete + 1 && this.props.requireSequentialCompletion

  render() {
    const {
      loadMore,
      moreAvailable,
      isLoading,
      trainingPlans,
      requireSequentialCompletion,
      createCard,
    } = this.props

    // Init with -1 so that first plan is auto-unlocked
    let lastComplete = -1

    return (
      <InfiniteScroll
        loadMore={loadMore}
        moreDataAvailable={moreAvailable}
        dataIsLoading={isLoading}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {trainingPlans.map((trainingPlan, i) => {
            const isComplete = this.planIsComplete(trainingPlan)
            if (isComplete) lastComplete = i
            // Only unlock the "next" plan (i.e. the first plan or next incomplete plan)
            return createCard(trainingPlan, this.shouldLock(i, lastComplete))
          })}
        </div>
      </InfiniteScroll>
    )
  }
}
