import { t } from 'i18n'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import Textarea from 'react-autosize-textarea'
import ChannelsState from 'state/channels'
import Style from 'style/index.js'
import _ from 'lodash'

const styles = {
  editableTextArea: {
    border: '1px solid rgba(0,0,0,0)',
    borderRadius: 10,
    color: 'white',
    outline: 'none',
    transition: 'all 0.3s ease',
    width: '100%',
    resize: 'none',
    backgroundColor: 'inherit',
    ':hover': {},
  },
  textAreaHover: {
    border: '1px solid #ddd',
  },
  nameAndDesc: {
    ':hover': {},
  },
  editPencil: {
    color: Style.vars.deprecatedColors.white,
    fontSize: '1rem',
    position: 'absolute',
    right: -15,
    top: -20,
  },
  channelName: {
    fontSize: '2rem',
    fontWeight: 200,
    lineHeight: '30px',
    textAlign: 'center',
  },
  channelDisplayName: {
    fontSize: '1rem',
    width: 270,
    textAlign: 'left',
    verticalAlign: 'middle',
    overflow: 'visible',
    marginBottom: 2,
    marginLeft: 10,
  },
  channelDescription: {
    fontSize: '1rem',
    width: '100%',
    textAlign: 'center',
    marginTop: 15,
  },
  channelDetailText: {
    display: 'inline',
    marginLeft: 10,
  },
  borderedNameContainer: {
    borderRadius: 5,
    marginTop: 10,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    maxWidth: 500,
    [Style.vars.media.mobile]: {
      maxWidth: '100%',
    },
  },
  nameContainer: {
    position: 'relative',
    textAlign: 'center',
    fontSize: 30,
    marginBottom: 10,
    lineHeight: '1em',
  },
  uneditableTitle: {
    fontSize: 30,
    lineHeight: '30px',
    textAlign: 'center',
  },
}

@Radium
export class NameContainer extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  updateFields = (data) => {
    _.forOwn(data, (value, key) => {
      data[key] = _.trim(value)
    })
    if (!data.name) data.name = this.props.channel.name
    ChannelsState.ActionCreators.update(this.props.channel.id, data).then((res) => {
      this.context.displayTempPositiveMessage({
        heading: 'changes_saved',
      })
    })
  }

  keyPress = (event, data) => {
    // If user presses enter, save changes
    if (event.charCode === 13) {
      event.preventDefault()
      event.stopPropagation()
      this.updateFields(data)
    }
  }

  focusChannelNameInput = () => {
    this.channelNameInput.focus()
  }

  render() {
    if (!this.props.isEditable) {
      return (
        <div style={styles.borderedNameContainer}>
          <div style={styles.uneditableTitle}>
            {this.props.channel.display_name || this.props.channel.name}
          </div>
        </div>
      )
    }

    const displayName = this.props.channel.display_name || this.props.channel.name
    const noDataStyle = { fontStyle: 'italic', opacity: 0.5 }
    const normalFont = { opacity: 1, fontStyle: 'normal' }
    // Have to do this because the styles object properties are set to noDataStyle
    // when the relevant prop changes.
    const displayNameStyle = this.props.channel.display_name
      ? Object.assign(styles.channelDisplayName, normalFont)
      : Object.assign(styles.channelDisplayName, noDataStyle)

    const textAreaHover = Radium.getState(this.state, 'nameAndDesc', ':hover')
    return (
      <div style={styles.borderedNameContainer}>
        <div style={styles.nameAndDescz} key="nameAndDesc">
          <div style={styles.nameContainer}>
            <Textarea
              style={{
                ...styles.editableTextArea,
                ...styles.channelName,
                ...(textAreaHover ? styles.textAreaHover : {}),
              }}
              placeholder={this.props.channel.name}
              type="text"
              key="channelName"
              innerRef={(el) => (this.channelNameInput = el)}
              defaultValue={this.props.channel.name}
              onBlur={(event) => {
                this.updateFields({ name: event.target.value })
              }}
              onKeyPress={(event) => {
                this.keyPress(event, { name: event.target.value })
              }}
              rows={1}
            />
            <i
              style={styles.editPencil}
              onClick={this.focusChannelNameInput}
              className="icon edit outline"
            />
          </div>
          <div style={styles.channelDetailText}>
            <b>{`${t('displayed_as')}: `}</b>
            <Textarea
              style={{
                ...styles.editableTextArea,
                ...displayNameStyle,
                ...(textAreaHover ? styles.textAreaHover : {}),
              }}
              placeholder={displayName}
              type="text"
              key="channelDisplayName"
              innerRef={(el) => (this.channelDisplayNameInput = el)}
              defaultValue={displayName}
              onBlur={(event) => {
                this.updateFields({ display_name: event.target.value })
              }}
              onKeyPress={(event) => {
                this.keyPress(event, { display_name: event.target.value })
              }}
              rows={1}
            />
          </div>
        </div>
      </div>
    )
  }
}
