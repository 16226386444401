// Generate required css
import deprecatedIconFont from 'react-native-vector-icons/Fonts/Ionicons.ttf'
import iconFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf'

export default function () {
  const deprecatedIconFontStyles = `@font-face {
    src: url(${deprecatedIconFont});
    font-family: Ionicons;
  }`

  const iconFontStyles = `@font-face {
    src: url(${iconFont});
    font-family: MaterialIcons;
  }`

  // Create stylesheet
  const style = document.createElement('style')
  style.type = 'text/css'

  if (style.styleSheet) {
    style.styleSheet.cssText = `${deprecatedIconFontStyles}\n${iconFontStyles}`
  } else {
    style.appendChild(document.createTextNode(deprecatedIconFontStyles))
    style.appendChild(document.createTextNode(iconFontStyles))
  }

  // Inject stylesheet
  document.head.appendChild(style)
}
