import React from 'react'
import gql from 'fraql'
import { graphql } from 'react-apollo'
import { PrimaryButton } from 'components/common/buttons'
import t from 'shared-js/i18n'
import { SelectableTable, ReadOnlyTable, AWAITING_USER_RESPONSE, COMPLETE } from './tables'
import 'react-table/react-table.css'
import './style.css'

const styles = {
  btnContainer: {
    marginTop: 50,
    marginBottom: 200,
  },
}

@graphql(gql`
  mutation CreateConnections($id: ID!, $selectedCompanies: [ID]!, $supplierId: ID!) {
    NetworkReport__Update(
      input: { id: $id, selectedCompanies: $selectedCompanies, supplier: $supplierId }
    ) {
      result {
        id
        status
        inputFile
        outputFile
        rawOutputData
      }
    }
  }
`)
export class ConnectionsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      selection: [],
    }
  }

  updateSelection = (val) => {
    this.setState({ selection: val })
  }

  connectCompanies = () => {
    const { selection } = this.state
    const { report, supplierId, mutate, polling } = this.props
    this.setState({ isLoading: true })
    mutate({
      variables: {
        id: report.id,
        supplierId,
        selectedCompanies: selection,
      },
    }).then((res) => {
      polling.startPolling(2000)
    })
  }

  render() {
    const { isLoading } = this.state
    const { report } = this.props
    return (
      <React.Fragment>
        {report.status === COMPLETE ? (
          <ReadOnlyTable {...this.props} />
        ) : (
          <SelectableTable updateSelection={this.updateSelection} {...this.props} />
        )}
        {report.status === AWAITING_USER_RESPONSE && (
          <div style={styles.btnContainer}>
            <PrimaryButton onClick={this.connectCompanies} isLoading={isLoading} disabled>
              {t('connect')}
            </PrimaryButton>
          </div>
        )}
      </React.Fragment>
    )
  }
}
