import Marty from 'marty'
import React from 'react'
import PropTypes from 'prop-types'
import Im from 'immutable'

import containerUtils from 'utilities/containers'
import { getIdFromApiUrl } from 'utilities/generic'

import { Box, BoxHeader, BoxContent } from 'components/common/box'
import { CompanySearchableSelect } from 'components/common/company-searchable-select'
import SubscriptionsState from 'shared-js/state/subscriptions'
import CompaniesState from 'state/companies'
import CompanySettingsState from 'state/companysettings'
import UsersState from 'state/users'

class CompanySelectContainer extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
  }

  onCompanySelect = (val) => {
    if (val) {
      const id = getIdFromApiUrl(val)
      this.context.router.push({
        pathname: `/views/myagi-staff/company/${id}/`,
      })
    }
  }

  render() {
    return (
      <Box>
        <BoxHeader>
          <h1>Company admin</h1>
        </BoxHeader>
        <BoxContent>
          <div>
            <CompanySearchableSelect
              ref="coSelect"
              name="coURL"
              onChange={this.onCompanySelect}
              internalUse
            />
          </div>
          {this.props.children}
        </BoxContent>
      </Box>
    )
  }
}

export const Page = Marty.createContainer(CompanySelectContainer, {
  contextTypes: {
    currentUser: PropTypes.instanceOf(Im.Map).isRequired,
    routeParams: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  },

  listenTo: [
    CompanySettingsState.Store,
    CompaniesState.Store,
    UsersState.Store,
    SubscriptionsState.Store,
  ],

  pending() {
    return containerUtils.defaultPending(this, CompanySelectContainer)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, CompanySelectContainer, errors)
  },
})
