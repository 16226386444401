import React, { useRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'shared-js/components/common/buttons'
import { Modal } from 'components/common/modals'
import { t } from 'i18n'
import { useToastMessage, useHover } from 'shared-js/utilities/hooks'
import { useMutation } from '@apollo/react-hooks'
import Style from 'style'
import { BLANK_IMAGE } from 'core/constants'
import { Transition, TransitionGroup } from 'react-transition-group'
import ImageCropper from './image-cropper'
import { get, noop } from 'lodash'
import MobileCropper from './mobile-cropper'

export { default as ImageCropper } from './image-cropper'
export { default as ImageCropperInput } from './image-cropper-input'

/**
 * Renders an image as background image to a div.
 * This allows for more control over how image responds to changes in container size.
 */
export function Image({ src, style, ...props }) {
  const backgroundImage = `url(${src})`
  return <div {...props} style={{ ...imageStyle, ...style, backgroundImage }} />
}
Image.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object,
}
Image.defaultProps = {
  src: BLANK_IMAGE,
  style: {},
}

const imageStyle = {
  height: '12em',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundColor: 'white',
}

/**
 * `useMutation` with additional toast messages shown on image upload start, complete, and error.
 * These are the `'image_uploading'`, `'image_updated'`, and `'error_updating_image'` strings
 * respectively.
 */
export function useImageMutation(mutation, defaultOptions) {
  const toast = useToastMessage()
  const [updateImage] = useMutation(mutation, defaultOptions)

  return (options) => {
    toast.showPositive(t('image_uploading'))
    return updateImage(options)
      .then((result) => {
        toast.showPositive(t('image_updated'))
        return result
      })
      .catch((error) => {
        toast.showNegative(t('error_updating_image'))
        return error
      })
  }
}

/**
 * An image that also allows users to select a new image and crop it.
 */
export function EditableImage({ style, isEditable, src, ...props }) {
  const editModalRef = useRef()
  const [isHovered, bindIsHovered] = useHover()

  return (
    <div
      style={{
        ...styles.container,
        cursor: isEditable ? 'pointer' : 'default',
        ...style,
      }}
      onClick={() => isEditable && editModalRef.current.show()}
      {...bindIsHovered}
    >
      {src && <img src={src} style={styles.image} />}
      <TransitionGroup>
        {isHovered && isEditable && (
          <Transition timeout={{ enter: 0, exit: 700 }}>
            {(state) => (
              <div style={styles.editButtonContainer}>
                <i
                  className="icon edit outline"
                  style={{ ...styles.editButton, ...transitionStyles[state] }}
                />
              </div>
            )}
          </Transition>
        )}
      </TransitionGroup>
      <EditImageModal ref={editModalRef} {...props} initialValue={src} />
    </div>
  )
}
EditableImage.propTypes = {
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  isEditable: PropTypes.bool,
}
EditableImage.defaultProps = {
  style: {},
  isEditable: true,
}

/**
 * A modal containing the `ImageCropper` component.
 */
export const EditImageModal = React.forwardRef((props, ref) => {
  const editModalRef = useRef()
  const [croppedImage, setCroppedImage] = useState()
  const [platform, setPlatform] = useState('web') // 'web' | 'mobile'
  const coverImageUrl =
    get(props, 'channel.cover_image') || get(props, 'channel.company.cover_image', '')

  // Note: This is "analog" of feature flag to allow users from
  //       Haglofs and Myagi Internal see mobile cropper component.
  //       This thing should be deleted as soon as possible.
  const companyId = get(props, 'channel.company.id', 0)
  let showMobileCropper = false
  if (companyId === 6919 || companyId === 37327) {
    showMobileCropper = true
  }

  useImperativeHandle(ref, () => ({
    show: () => editModalRef.current.show(),
    hide: () => editModalRef.current.hide(),
  }))

  const handelOnSubmit = () => {
    props.onSubmit(croppedImage)
    editModalRef.current.hide()
  }

  const handlePlatformChange = () => {
    switch (platform) {
      case 'mobile':
        setPlatform('web')
        break
      case 'web':
        setPlatform('mobile')
        break
      default:
        setPlatform('web')
        break
    }
  }

  return (
    <Modal ref={editModalRef} size={props.modalSize} header={props.modalHeader || t('crop_photo')}>
      {platform === 'web' && (
        <div style={styles.editModal}>
          <ImageCropper
            name={props.name}
            fileType={props.fileType}
            width={props.cropperWidth}
            aspectRatio={props.aspectRatio}
            style={{ ...styles.cropper, ...props.cropperStyle }}
            initialValue={props.initialValue}
            onCrop={(image) => setCroppedImage(image)}
          />
          {props.renderCropTip()}

          <div style={styles.submit}>
            <Button containerStyle={styles.submitButton} color="primary" onPress={handelOnSubmit}>
              {t('submit')}
            </Button>
          </div>
        </div>
      )}

      {platform === 'mobile' && (
        <MobileCropper coverImageUrl={coverImageUrl} channelId={get(props, 'channel.id', 0)} />
      )}

      {showMobileCropper && (
        <Button containerStyle={styles.submitButton} onPress={handlePlatformChange}>
          {platform === 'web' ? t('set_mobile_view') : t('set_web_view')}
        </Button>
      )}
    </Modal>
  )
})
EditImageModal.displayName = 'EditImageModal'
EditImageModal.propTypes = {
  modalSize: Modal.propTypes.size,
  modalHeader: PropTypes.string,
  renderCropTip: PropTypes.func,
  name: PropTypes.string,
  fileType: PropTypes.oneOf(['jpg', 'png']),
  aspectRatio: PropTypes.number,
  cropperStyle: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}
EditImageModal.defaultProps = {
  renderCropTip: noop,
  cropperStyle: {},
  modalSize: 'small',
}

// Add EditImageModal proptypes to EditableImages
EditableImage.propTypes = {
  ...EditImageModal.propTypes,
  ...EditableImage.propTypes,
}
EditableImage.defaultProps = {
  ...EditImageModal.defaultProps,
  ...EditableImage.defaultProps,
}

const styles = {
  container: {
    transition: 'all 0.3s ease',
    zIndex: 10,
    cursor: 'pointer',
    position: 'relative',
  },
  image: {
    width: '100%',
  },
  editButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editButton: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: Style.vars.deprecatedColors.primaryFontColor,
    backgroundColor: Style.vars.deprecatedColors.primary,
    transition: 'all 200ms ease-in-out',
  },
  editModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cropper: {
    maxWidth: '100%',
    marginBottom: 10,
  },
  submit: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  submitButton: {
    // flex: 1,
  },
}

const transitionStyles = {
  entering: {
    transform: 'scale(0.7)',
    opacity: 0,
  },
  entered: {
    transform: 'scale(1)',
    opacity: 1,
  },
  exiting: {
    transform: 'scale(0.7)',
    opacity: 0,
  },
}
