import React from 'react'
import { Panel } from 'components/common/box'
import PropTypes from 'prop-types'
import Style from 'style'
import Im from 'shared-js/immutable'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import t from 'shared-js/i18n'
import styled from 'styled-components'

import { PublicPlanCard } from 'components/common/plan-card'

function getConnectionText({
  isSubscribedToByCurrentCompany,
  currentCompanyHasExistingRequest,
  company,
}) {
  let connectedStatus = t('connect_to_access')
  if (currentCompanyHasExistingRequest) {
    connectedStatus = t('you_have_requested_access', {
      companyName: company.name,
    })
  }
  if (isSubscribedToByCurrentCompany) {
    connectedStatus = t('you_are_already_connected')
  }
  return connectedStatus
}

const PlanList = ({
  channel,
  onPlanCardClick,
  trainingPlanTrainingUnits,
  openTrainingPlanModal,
  paginationProps,
}) => {
  const connectionText = getConnectionText(channel)
  const plans = trainingPlanTrainingUnits.map((tptu) => Im.freeze(tptu.node.trainingPlan))
  const { loadMoreData, moreDataAvailable, dataIsLoading } = paginationProps
  return (
    <Panel>
      <div style={Style.common.clearBoth} />
      <InfiniteScroll
        loadMore={loadMoreData}
        moreDataAvailable={moreDataAvailable}
        dataIsLoading={dataIsLoading}
      >
        <PlansContainer>
          {plans.map((plan) => {
            return (
              <PublicPlanCard
                key={plan.id}
                plan={plan}
                openTrainingPlanModal={openTrainingPlanModal}
                onPlanCardClick={onPlanCardClick}
                overlayMessage={connectionText}
              />
            )
          })}
        </PlansContainer>
      </InfiniteScroll>
    </Panel>
  )
}

PlanList.propTypes = {
  channel: PropTypes.object.isRequired,
  onPlanCardClick: PropTypes.func,
  trainingPlanTrainingUnits: PropTypes.object,
  openTrainingPlanModal: PropTypes.func,
  paginationProps: PropTypes.object,
}

PlanList.defaultProps = {
  trainingPlanTrainingUnits: [],
  paginationProps: {
    loadMoreData: false,
    moreDataAvailable: false,
    dataIsLoading: false,
  },
}

const PlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export default PlanList
