import React, { useMemo } from 'react'
import shortid from 'shortid'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

export const COLUMNS = [
  {
    Header: 'Myagi Id',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Region',
    accessor: 'regionOfResponsibility',
  },
  {
    Header: 'Website',
    accessor: 'companyUrl',
  },
  {
    Header: 'User Count',
    accessor: 'realUserCount',
  },
  {
    Header: 'Latest Activity',
    accessor: 'latestActivity',
  },
  {
    Header: 'Unclaimed',
    accessor: 'unclaimed',
  },
]

export default function ReadOnlyTable(props) {
  const rows = useMemo(() => {
    const { data } = props
    const rowsData = data.slice(1, data.length)
    const rows = []
    rowsData.forEach((row) => {
      const _id = shortid.generate()
      const newRow = { _id }
      row.forEach((r, i) => {
        newRow[COLUMNS[i].accessor] = r
      })
      rows.push(newRow)
    })
    return rows
  }, [props])
  return (
    <ReactTable
      columns={COLUMNS}
      data={rows}
      className="-striped -highlight"
      defaultSorted={[
        {
          id: 'name',
          desc: true,
        },
      ]}
    />
  )
}
