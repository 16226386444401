import { BoxContent, Panel } from 'components/common/box'
import {
  ButtonToggle,
  Form,
  KeyInput,
  SubmitButton,
  TextInput,
  URLInput,
} from 'components/common/form-deprecated'

import $y from 'utilities/yaler'
import CompaniesState from 'state/companies'
import CompanySettingsState from 'state/companysettings'
import PropTypes from 'prop-types'
import React from 'react'
import Style from 'style'
import { MultiTagAsyncSelect } from 'components/common/select'
import { t } from 'i18n'
import { LoadingSpinner } from 'components/common/loading'
import { DescriptionBox } from '../../common'
import { CompanyRegionFields } from '../regions'
import { CompanyImages } from '../company-images'

const YES = 'Yes'
const NO = 'No'

export class GeneralTabsContent extends React.Component {
  static propTypes = {
    companySettings: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  static data = {
    company: {
      required: true,
      fields: [
        'id',
        'name',
        'company_logo',
        'cover_image',
        'company_url',
        'subdomain',
        'num_external_teams_connected_to_content',
        'subscription.id',
        'is_freemium',
        'tags.id',
        'tags.name',
        'regions_of_responsibility.id',
        'regions_of_responsibility.name',
        'office_location',
      ],
    },
    companySettings: {
      required: true,
      fields: [
        'public_access',
        'host_whitelist_enabled',
        'users_can_invite_others_to_join',
        'allow_signups_to_create_teams',
        'style_customization_enabled',
        'public_api_key',
      ],
    },
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    featureFlags: PropTypes.objectOf(PropTypes.bool),
  }

  constructor(props) {
    super(props)
    this.state = {
      formNotChanged: true,
      office_location: null,
      isSaving: false,
    }
  }

  onCompanyUpdate = () => {
    CompanySettingsState.Store.resetState()
    CompaniesState.Store.resetState()
    this.context.displayTempPositiveMessage({
      heading: 'Success',
      body: 'Company details saved',
    })
  }

  onCompanyUpdateFail = (err) => {
    this.context.displayTempNegativeMessage({
      heading: 'Request failed',
      body: err,
    })
  }

  onSubmit = async (data) => {
    this.setState({ isSaving: true })
    const companySettingsId = this.props.companySettings.id
    const company = this.props.company
    const companyId = company.id
    const newSubdomain = data.subdomain.length ? data.subdomain : null
    const tags = data.tags
    const updatePromises = []

    if (this.state.office_location) {
      data.office_location = this.state.office_location
    }

    updatePromises.push(
      CompanySettingsState.ActionCreators.update(companySettingsId, {
        fields: $y.getFields(GeneralTabsContent, 'companySettings'),
      })
    )

    if (data.company_url || data.name || data.office_location) {
      const coData = {
        company_url: data.company_url,
        name: data.name,
        office_location: data.office_location,
      }

      if (tags !== undefined) coData.tags = tags

      if (newSubdomain !== this.props.company.subdomain) {
        coData.subdomain = newSubdomain
      }

      updatePromises.push(
        CompaniesState.ActionCreators.update(companyId, coData, {
          fields: $y.getFields(GeneralTabsContent, 'company'),
        })
      )
    }

    await Promise.all(updatePromises)
      .then(() => {
        this.setState({ isSaving: false })
        this.onCompanyUpdate()
      })
      .catch((err) => {
        this.onCompanyUpdateFail(err.message)
      })
  }

  onChangePublicAccess = (data) => {
    const companySettingsId = this.props.companySettings.id
    data = { public_access: data === YES }
    CompanySettingsState.ActionCreators.update(companySettingsId, data, {
      fields: $y.getFields(GeneralTabsContent, 'company'),
    }).then(() => {
      this.onCompanyUpdate()
    })
  }

  onChangeUsersCanInviteOthers = (data) => {
    const companySettingsId = this.props.companySettings.id
    data = { users_can_invite_others_to_join: data === YES }
    CompanySettingsState.ActionCreators.update(companySettingsId, data, {
      fields: $y.getFields(GeneralTabsContent, 'company'),
    }).then(() => {
      this.onCompanyUpdate()
    })
  }

  onChangeNewUsersCanCreateTeams = (data) => {
    const companySettingsId = this.props.companySettings.id
    data = { allow_signups_to_create_teams: data === YES }
    CompanySettingsState.ActionCreators.update(companySettingsId, data, {
      fields: $y.getFields(GeneralTabsContent, 'companySettings'),
    }).then(() => {
      this.onCompanyUpdate()
    })
  }

  onOfficeLocationChange = (val) => {
    this.inputChanged()
    this.setState({
      office_location: val,
    })
  }

  inputChanged = () => {
    this.setState({ formNotChanged: false })
  }

  render() {
    const { company, companySettings, currentUser } = this.props
    const usersCanInviteOthersToJoin = companySettings.users_can_invite_others_to_join ? YES : NO
    const publicAccess = companySettings.public_access ? YES : NO
    const allowSignupsToCreateTeams = companySettings.allow_signups_to_create_teams ? YES : NO
    const publicAPIKey = companySettings.public_api_key
    const companyName = company.name
    const legalTradingName = company.legal_trading_name
    const companyURL = company.company_url
    const subdomain = company.subdomain
    const tags = company.tags
    const userIsStaff = currentUser.learner.is_myagi_staff

    const displayPublicApiKeyField = !!this.context.featureFlags.public_api_key

    return (
      <Panel>
        <BoxContent>
          <Form onSubmitAndValid={this.onSubmit} ref="companySettingsForm">
            <CompanyImages
              company={this.props.company}
              onCompanyUpdateFail={this.onCompanyUpdateFail}
              onCompanyUpdate={this.onCompanyUpdate}
            />

            <div className="ui grid stackable">
              <div className="column nine wide">
                <DescriptionBox title={t('your_company_name')} info={t('your_company_name_info')} />
              </div>

              <div className="column seven wide" style={styles.actionCol}>
                <div style={{ maxWidth: 400 }}>
                  <TextInput
                    initialValue={companyName}
                    name="name"
                    onChange={this.inputChanged}
                    initialIsAcceptable
                    disabled={!userIsStaff}
                  />
                  {!userIsStaff && (
                    <a href="#request-change-of-company-name">
                      {t('request_to_change_company_name')}
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="ui grid stackable">
              <div className="column nine wide">
                <DescriptionBox
                  title={t('your_company_website_url')}
                  info={t('enter_your_company_website_url')}
                />
              </div>

              <div className="column seven wide" style={styles.actionCol}>
                <div style={{ maxWidth: 400 }}>
                  <URLInput
                    initialValue={companyURL}
                    name="company_url"
                    ref="company_url"
                    onChange={this.inputChanged}
                    required={false}
                    initialIsAcceptable
                  />
                </div>
              </div>
            </div>

            {/* The multi-select inputs save to the DB with graphql as soon as you select them.
                 Hence there is no need to enable 'Save' button on input change.
              */}
            <CompanyRegionFields
              company={company}
              onOfficeLocationChange={this.onOfficeLocationChange}
            />

            <div className="ui grid stackable">
              <div className="column nine wide">
                <DescriptionBox title={t('tags')} info={t('tags_settings_info')} />
              </div>

              <div className="column seven wide" style={styles.actionCol}>
                <div style={{ maxWidth: 400 }}>
                  <MultiTagAsyncSelect company={company} />
                </div>
              </div>
            </div>

            <div className="ui grid stackable">
              <div className="column nine wide">
                <DescriptionBox title="Myagi URL" info={t('myagi_url_settings_info')} />
              </div>

              <div className="column seven wide" style={styles.actionCol}>
                <div style={{ maxWidth: 400 }}>
                  <TextInput
                    initialValue={subdomain}
                    name="subdomain"
                    ref="subdomain"
                    onChange={this.inputChanged}
                    required={false}
                    initialIsAcceptable
                  />
                  <div>
                    {subdomain ? (
                      <span>
                        {`${t('your_myagi_url')} `}
                        <a
                          href={`https://${subdomain}.myagi.com`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`${subdomain}.myagi.com`}
                        </a>
                      </span>
                    ) : (
                      <span>{t('no_myagi_url')}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {displayPublicApiKeyField && (
              <div className="ui grid stackable">
                <div className="column nine wide">
                  <DescriptionBox title="API Key" info={t('myagi_public_api_key')} />
                </div>

                <div className="column seven wide" style={styles.actionCol}>
                  <div style={{ maxWidth: 400 }}>
                    <KeyInput
                      value={publicAPIKey}
                      name="public_api_key"
                      ref="public_api_key"
                      required={false}
                      prompt={t('display_api_key')}
                    />
                    <div>{!publicAPIKey ? <span>{t('no_public_api_key')}</span> : null}</div>
                  </div>
                </div>
              </div>
            )}

            {!company.is_freemium && (
              <div className="ui grid stackable">
                <div className="column nine wide">
                  <DescriptionBox
                    title={t('is_publicly_accessible')}
                    info={t('publicly_accessible_info')}
                  />
                </div>

                <div className="column seven wide" style={styles.actionCol}>
                  <ButtonToggle
                    name="public_access"
                    leftLabel={YES}
                    rightLabel={NO}
                    initialValue={publicAccess}
                    initialIsAcceptable
                    onChange={this.onChangePublicAccess}
                  />
                </div>
              </div>
            )}

            <div className="ui grid stackable">
              <div className="column nine wide">
                <DescriptionBox
                  title={t('allow_signup_invitations')}
                  info={t('allow_signup_invitations_info')}
                />
              </div>

              <div className="column seven wide" style={styles.actionCol}>
                <ButtonToggle
                  name="users_can_invite_others_to_join"
                  leftLabel={YES}
                  rightLabel={NO}
                  initialValue={usersCanInviteOthersToJoin}
                  initialIsAcceptable
                  onChange={this.onChangeUsersCanInviteOthers}
                />
              </div>
            </div>

            <div className="ui grid stackable">
              <div className="column nine wide">
                <DescriptionBox
                  title={t('allow_new_users_to_create_teams')}
                  info={t('allow_new_users_to_create_teams_info')}
                />
              </div>

              <div className="column seven wide" style={styles.actionCol}>
                <ButtonToggle
                  name="allow_signups_to_create_teams"
                  leftLabel={YES}
                  rightLabel={NO}
                  initialValue={allowSignupsToCreateTeams}
                  initialIsAcceptable
                  onChange={this.onChangeNewUsersCanCreateTeams}
                />
              </div>
            </div>

            <div>
              <span style={{ display: 'inline-block' }}>
                <SubmitButton
                  text={t('save')}
                  style={styles.submitButton}
                  disabled={this.state.formNotChanged || this.state.isSaving}
                />
              </span>
              <span style={{ display: 'inline-block', width: 100 }}>
                {this.state.isSaving && <LoadingSpinner size="small" />}
              </span>
            </div>
          </Form>
        </BoxContent>
      </Panel>
    )
  }
}

const styles = {
  actionCol: {
    paddingTop: 50,
    paddingLeft: 50,
  },
  tabContainer: {
    marginBottom: 50,
    padding: 0,
    height: 60,
  },
  inputStyle: {
    container: {
      maxWidth: 250,
    },
    labelStyle: {
      height: 36,
      color: Style.vars.deprecatedColors.white,
    },
  },
  submitButton: {
    width: 120,
    height: 50,
    float: 'left',
    // marginLeft: 50,
    paddingTop: 18,
  },
}
