import cx from 'classnames'
import { Form, SubmitButton } from 'components/common/form-deprecated'
import { Modal } from 'components/common/modals'
import { MAX_FILE_SIZE } from 'core/constants'
import { t } from 'i18n'
import PLACEHOLDER_IMAGE from 'img/placeholder.svg'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import Style from 'style'
import { validateImage } from 'utilities/validators'
import ReactCrop from 'react-image-crop'
import { SUPPORTED_IMAGE_MIME_TYPES } from 'shared-js/constants'

const DEFAULT_PLAN_IMAGE = '/static/img/empty_placeholder.png'
const DEFAULT_IMG_RATIO = 9 / 16
const DEFAULT_IMG_HEIGHT = 200

const styles = {
  input: {
    display: 'none',
  },
  noImageContainer: {
    backgroundColor: '#f4f4f4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectImageContainer: {
    color: '#666',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      color: 'black',
      cursor: 'pointer',
    },
  },
  selectImageIcon: {
    fontSize: '3rem',
    lineHeight: '54px',
    margin: 0,
    height: 'auto',
  },
  resetButton: {
    color: '#666',
    marginLeft: 5,
    marginTop: 5,
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0)',
  },
  cropContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxWidth: '100%',
  },
  imageCropper: {
    maxWidth: '100%',
    backgroundColor: '#FFF',
  },
  image: {
    maxWidth: '100%',
    maxHeight: 500,
    backgroundColor: '#FFF',
  },
  buttonRow: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButton: {
    color: 'grey',
    position: 'relative',
    right: 0,
    zIndex: 2,
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0)',
  },
  loadingContainer: {
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#999',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    padding: 10,
  },
  img: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#eee',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tickContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {},
  },
  tick: {
    transition: 'all .2s ease-in-out',
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: Style.vars.deprecatedColors.primaryFontColor,
    zIndex: '3',
    backgroundColor: Style.vars.deprecatedColors.primary,
    ...Style.funcs.makeTransform('translateY(-50%)'),
  },
}

const transitionStyles = {
  entering: {
    transform: 'scale(0.7)',
    opacity: 0,
  },
  entered: {
    transform: 'scale(1)',
    opacity: 1,
  },
  exiting: {
    transform: 'scale(0.7)',
    opacity: 0,
  },
}

/**
 * @deprecated Use `ImageCropper` from `components/common/image` instead.
 */
@Radium
export class DeprecatedImageCropper extends React.Component {
  static contextTypes = {
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    aspectRatio: PropTypes.number,
    onCrop: PropTypes.func,
    onChange: PropTypes.func,
    showError: PropTypes.func,
    onImageRemove: PropTypes.func,
    name: PropTypes.string,
    required: PropTypes.bool,
    initialValue: PropTypes.string,
    containerStyle: PropTypes.object,
    cropContainerStyle: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: null,
      cancelled: false,
      inputRef: `input${Math.random()}`, // For when there are multiple ImageCroppers on the page
      cropShape: { aspect: props.aspectRatio, width: props.width },
      base64Image: null,
    }
    this.imgRef = React.createRef()
  }

  readImage(file) {
    const reader = new FileReader()
    reader.onload = (readFile) => {
      if (reader.readyState === FileReader.DONE) {
        this.setState({
          value: readFile.target.result,
          cancelled: false,
        })
      }
    }
    this.props.onChange && this.props.onChange(this.getValue())
    reader.readAsDataURL(file)
    if (this.props.showError) {
      this.props.showError(false)
    }
  }

  onInputChange = (e) => {
    const file = e.target.files[0]
    if (!validateImage(file)) {
      this.context.displayTempNegativeMessage({
        heading: t('error_png_jpg'),
      })
      if (this.props.showError) {
        this.props.showError('error_png_jpg')
      }
      return
    }
    if (file.size > MAX_FILE_SIZE) {
      this.context.displayTempNegativeMessage({
        heading: t('error_file_too_large'),
      })
      if (this.props.showError) {
        this.props.showError('error_file_too_large')
      }
      return
    }
    this.readImage(file)
  }

  cancelImage = () => {
    this.setState({ cancelled: true, value: null })
    this.props.onChange && this.props.onChange()
    this.props.onImageRemove && this.props.onImageRemove()
  }

  enableCrop = () => {
    this.setState({ value: this.props.initialValue })
    this.props.onChange && this.props.onChange()
  }

  getValue() {
    if (this.state.cancelled) return null
    // if this.state.value
    if (_.startsWith(this.state.value, 'data:image/gif;base64')) {
      return this.state.value
    }
    return this.state.base64Image
    // return this.cropper && this.cropper.getCroppedCanvas().toDataURL()
  }

  getNameAndValue() {
    // only used for <Form> components
    return { [this.props.name]: this.getValue() }
  }

  isValid() {
    // only used for <Form> components
    return this.props.required ? this.hasValue() : true
  }

  hasValue() {
    const initialValue = this.props.initialValue && this.props.initialValue != DEFAULT_PLAN_IMAGE
    return !(this.state.cancelled || !(this.state.value || initialValue))
  }

  onCrop = () => {
    if (this.props.onCrop) {
      this.props.onCrop(this.getValue())
    }
  }

  onComplete = (crop) => {
    const img = this.imgRef.current
    // We want to use the natural image size so we don't loose any quality.
    // However, because all crop dimentions are relative to the size on the
    // screen, they all have to be multiplied by a factor.
    const scaleX = img.naturalWidth / img.width
    const scaleY = img.naturalHeight / img.height

    const canvas = document.createElement('canvas')
    canvas.width = crop.width * scaleX
    canvas.height = crop.height * scaleY
    const canvas2d = canvas.getContext('2d')

    canvas2d.drawImage(
      img,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    )

    const base64Image = canvas.toDataURL('image/png')
    this.setState({ base64Image })
    if (this.props.onCrop) this.props.onCrop(base64Image)
  }

  handleImageLoaded = (img) => {
    this.imgRef.current = img
    if (!this.props.aspectRatio) {
      // If no aspectRatio is specified, we ensure the crop area is auto-selected by setting the
      // crop area to be (almost) as big as the image dimentions. Making the auto crop area slightly
      // smaller makes the cropping controls much more noticable.
      // eslint-disable-next-line id-length
      const cropShape = { x: 20, y: 20, width: img.width - 40, height: img.height - 40 }
      this.setState({ cropShape })
      this.onComplete(cropShape)
    }
  }

  reset = () => this.setState({
    cancelled: false,
    value: null,
    base64Image: null,
  })

  render() {
    return (
      <div style={this.props.containerStyle}>
        {this.hasValue() ? (
          <div style={[styles.cropContainer, this.props.cropContainerStyle]}>
            {this.state.value ? (
              <div>
                <div style={{ height: 0 }}>
                  <div
                    style={{
                      width: this.props.width,
                      height: this.props.height,
                      ...styles.loadingContainer,
                    }}
                  >
                    {t('loading_with_dots')}
                  </div>
                </div>
                <ReactCrop
                  style={styles.imageCropper}
                  imageStyle={styles.image}
                  keepSelection
                  crop={this.state.cropShape}
                  src={this.state.value || this.props.initialValue}
                  onImageLoaded={this.handleImageLoaded}
                  onImageError={(error) => console.error(error)}
                  onChange={(cropShape) => this.setState({ cropShape })}
                  onComplete={this.onComplete}
                  crossorigin="anonymous"
                />
              </div>
            ) : (
              <img
                src={this.props.initialValue}
                style={{
                  maxWidth: this.props.width,
                  maxHeight: this.props.height,
                  backgroundColor: '#eee',
                }}
              />
            )}
            <div style={styles.buttonRow}>
              <button type="button" style={styles.actionButton} onClick={this.cancelImage}>
                <i className="ui icon remove" />
              </button>
              {/*
              Removing this crop-intitial-image button until we sort out the CORS issue. This is
              because images are stored using cloudfrount and browsers view manipulating images from
              different domains with the canvas element as insecure.
              {!this.state.cancelled && this.props.initialValue && !this.state.value && (
                <button type="button" style={styles.actionButton} onClick={this.enableCrop}>
                  <i className="ui icon crop" />
                </button>
              )} */}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: this.props.width,
              height: this.props.height,
              ...styles.noImageContainer,
            }}
          >
            <label htmlFor={this.state.inputRef} style={styles.selectImageContainer}>
              <i style={styles.selectImageIcon} className="ui icon file image outline" />
              <div style={styles.selectImageText}>{t('add_image')}</div>
            </label>
            <input
              style={styles.input}
              type="file"
              accept={SUPPORTED_IMAGE_MIME_TYPES.join()}
              id={this.state.inputRef}
              onChange={this.onInputChange}
            />

            {this.props.initialValue && (
              <button type="button" style={styles.resetButton} onClick={this.reset}>
                <i className="ui icon repeat" />
              </button>
            )}
          </div>
        )}
      </div>
    )
  }
}

/**
 * @deprecated Use `EditableImage` from `components/common/image` instead.
 */
@Radium
export class DeprecatedImageEdit extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      error: false,
    }
  }

  onError = (err) => {
    this.setState({
      error: err,
    })
  }

  onImageCropSubmit = () => {
    const thumbnail = this.image.getValue()
    this.props.onSave(thumbnail)
    this.cropModal.hide()
  }

  showCropModal = () => {
    if (this.props.editable) {
      this.cropModal.show()
    }
  }

  render() {
    const { error } = this.state
    const imgHover = Radium.getState(this.state, 'tickContainer', ':hover')
    const { width } = this.props
    let height = this.props.height || width * DEFAULT_IMG_RATIO
    if (typeof width === 'string') {
      // When width given is a '%', set default height
      height = DEFAULT_IMG_HEIGHT
    }
    return (
      <div
        ref="bgImg"
        className="thumbnail"
        style={{
          ...styles.img,
          width,
          height,
          backgroundImage: `url(${this.props.image || PLACEHOLDER_IMAGE})`,
          ...(this.props.editable && { cursor: 'pointer' }),
        }}
        onClick={this.showCropModal}
      >
        {this.props.editable && (
          <div key="play" style={styles.tickContainer} ref="tickContainer">
            <TransitionGroup>
              {imgHover && (
                <Transition timeout={{ enter: 0, exit: 700 }}>
                  {(state) => (
                    <i
                      style={{
                        ...styles.tick,
                        top: height / 2,
                        ...transitionStyles[state],
                      }}
                      className={cx('ui', 'icon', 'edit', 'outline')}
                    />
                  )}
                </Transition>
              )}
            </TransitionGroup>
          </div>
        )}
        <Modal
          ref={(ref) => (this.cropModal = ref)}
          header={t('crop_photo')}
          closeOnDimmerClick={false}
        >
          <div className="content" style={{ display: 'flex', justifyContent: 'center' }}>
            <Form
              ref="imageCropForm"
              onSubmitAndValid={this.onImageCropSubmit}
              style={styles.container}
            >
              <DeprecatedImageCropper
                ref={(i) => (this.image = i)}
                clearError={this.clearError}
                showError={(err) => this.onError(err)}
                name={this.props.name || 'custom_thumbnail'}
                aspectRatio={this.props.aspectRatio}
                height={height}
                width={width}
                initialValue={this.props.image}
              />
              {error && (
                <span style={{ color: Style.vars.deprecatedColors.darkRed }}>{t(error)}</span>
              )}
              {this.props.modalMessage && (
                <div style={{ width: this.props.width + 35, marginTop: 10 }}>
                  {this.props.modalMessage}
                </div>
              )}
              <SubmitButton disabled={error} />
            </Form>
          </div>
        </Modal>
      </div>
    )
  }
}
