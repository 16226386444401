import Marty from 'marty'
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Style from 'style'
import { t } from 'i18n'
import containerUtils from 'utilities/containers'
import { toValidURL } from 'shared-js/utilities/value-formatters'
import { qs } from 'shared-js/utilities/http'
import {
  Formik,
  FieldHeader,
  SubmitButton,
  Validation,
  getPropsFromFormik,
} from 'shared-js/components/common/form'
import * as Inputs from 'shared-js/components/common/inputs'
import { View } from 'react-native'
import { Text } from 'shared-js/components/common/text'
import { Image } from 'components/common/image'
import CompaniesState from 'state/companies'
import TagsState from 'state/tags'
import RegionsState from 'state/regions'
import {
  ANALYTICS_EVENTS,
  RETAILER,
  BRAND,
  HOSPO_COMPANY,
  CONTENT_SELLER,
  OTHER,
} from 'core/constants'
import CreateCompanyConnectionMutationContainer from 'components/accounts/create-company-connection-container'
import { REGION_OPTIONS, GENERAL_REGIONS } from 'components/common/form-deprecated/select/regions'
import { sortTranslatedRegions } from 'components/common/form-deprecated/select/region-select'

import { connectToSharelink, goToSharelinkChannelPage, nextSignupStep } from '../../common'

// TODO: upgrade lodash and replace with differenceWith
const SELECTABLE_REGIONS = REGION_OPTIONS.filter((reg) => GENERAL_REGIONS.indexOf(reg.value) === -1)
const COMPANY_TYPES = [
  { value: RETAILER, label: 'we_are_a_retailer' },
  { value: BRAND, label: 'we_are_a_brand' },
  { value: HOSPO_COMPANY, label: 'we_are_a_hospitality_co' },
  { value: CONTENT_SELLER, label: 'we_sell_content' },
  { value: OTHER, label: 'other' },
]
const CLEARBIT_LOGO_URL = 'https://logo.clearbit.com/'
const NEW_COMPANY_PAGE = 'new company page'

export class NewCompanyFormInner extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
  }

  constructor() {
    super()
    this.state = {
      logoURL: null,
    }
  }

  getOptions = (options) =>
    _.map(options, (val, key) => ({
      value: val.value,
      label: t(val.label),
    }))

  createCompany = (values) => {
    const companyUrl = toValidURL(values.companyUrl)
    const companyData = {
      name: values.companyName,
      company_url: companyUrl,
      company_type: values.companyType,
      office_location: values.officeLocation,
      regions_of_responsibility: values.regionsOfResponsibility,
      tags: values.tags,
      logo_url: this.state.logoURL,
      num_stores_est: values.numStores || null,
      num_people_per_store_est: values.numPeoplePerStore || null,
      link: this.props.link && this.props.link.name,
    }
    analytics.track(ANALYTICS_EVENTS.CREATED_COMPANY, { ...companyData })
    CompaniesState.ActionCreators.doListAction('create_and_add_to_company', companyData).then(
      (res) => {
        // If company has a website and is a retailer,
        // automatically trigger diffbot to scrape their website
        const { companyType } = values
        if (companyType === RETAILER && companyUrl) {
          CompaniesState.ActionCreators.doListAction('start_diffbot_job', {
            company_id: res.body.id,
          })
        }
        if (qs('c')) {
          this.props.createCompanyConnection(res.body.id)
          this.props.setReferralMethod(res.body.id)
          nextSignupStep(companyType, NEW_COMPANY_PAGE, true)
          return
        }
        if (this.props.link) {
          const sharelinkPromise = connectToSharelink(this.props.link)
          return sharelinkPromise.then(() => {
            goToSharelinkChannelPage(this.props.link)
          })
        }
        nextSignupStep(companyType, NEW_COMPANY_PAGE)
      }
    )
  }

  invalidateLogo = () => {
    this.setState({ logoURL: null })
  }

  handleUrlChange = (value) => {
    const companyDomain = value
    const clearbitLogoURL = CLEARBIT_LOGO_URL + companyDomain

    fetch(clearbitLogoURL)
      .then((res) => {
        this.setState({ logoURL: res.url })
      })
      .catch(() => {
        this.setState({ logoURL: null })
      })
  }

  makeTagOption = (tag) => ({
    value: tag.url,
    label: t(tag.name.toLowerCase().replace(/ /g, '_')),
  })

  render() {
    // Allows the new company's name to be determined from the previous page
    // (user sign up or custom sign up) via the 'company' field
    const newCompanyName =
      (this.context.location.state && this.context.location.state.newCompanyName) || undefined
    const sortedRegions = sortTranslatedRegions(SELECTABLE_REGIONS)
    const locationOptions = this.getOptions(sortedRegions)
    const companyTypeOptions = this.getOptions(COMPANY_TYPES)
    let tagOptions = []
    let regionOptions = []
    if (this.props.tags) tagOptions = this.props.tags.map((tag) => this.makeTagOption(tag))
    if (this.props.regions)
      regionOptions = this.props.regions.map((region) => this.makeTagOption(region))
    return (
      <Formik
        validationSchema={Validation.createSchema({
          companyName: Validation.text({ required: true }),
          officeLocation: Validation.text({ required: true }),
          tags: Validation.array({}),
          companyType: Validation.text({ required: true }),
          regionsOfResponsibility: Validation.array().when('companyType', {
            is: BRAND,
            then: Validation.array({ required: true }),
            otherwise: Validation.array(),
          }),
          hasNoWebsite: Validation.boolean(),
          companyUrl: Validation.text().when('hasNoWebsite', {
            is: false,
            then: Validation.url({ required: true }),
            otherwise: Validation.text(),
          }),
          numStores: Validation.number({}),
          numPeoplePerStore: Validation.number({}),
        })}
        onSubmit={(values) => {
          this.createCompany(values)
        }}
        initialValues={{
          companyName: newCompanyName,
          hasNoWebsite: false,
          companyType: RETAILER
        }}
      >
        {(innerProps) => {
          const isRetailerOrHospitality =
            innerProps.values.companyType === RETAILER ||
            innerProps.values.companyType === HOSPO_COMPANY

          const isBrand = innerProps.values.companyType === BRAND
          return (
            <View>
              <FieldHeader>{t('name')}</FieldHeader>
              <Inputs.TextInput
                testID="companyNameInput"
                style={styles.input}
                errorMessageStyle={styles.errorMsg}
                defaultValue={newCompanyName || undefined}
                placeholderTextColor={Style.vars.deprecatedColors.grey}
                autoCorrect={false}
                autoFocus
                {...getPropsFromFormik(innerProps, 'companyName')}
              />

              <FieldHeader>{t('office_location')}</FieldHeader>
              <Inputs.DropdownInput
                testID="officeLocationInput"
                style={styles.input}
                errorMessageStyle={styles.errorMsg}
                options={locationOptions}
                placeholder={t('select_office_location')}
                {...getPropsFromFormik(innerProps, 'officeLocation')}
              />

              <FieldHeader>{t('industry_choose_as_many')}</FieldHeader>
              <Inputs.MultiSelectInput
                options={tagOptions}
                placeholder={`${t('please_choose')}...`}
                {...getPropsFromFormik(innerProps, 'tags')}
              />

              <FieldHeader>{t('company_website_url_optional')}</FieldHeader>
              <Inputs.URLInput
                testID="companyURLInput"
                style={[styles.input, { marginBottom: 0 }]}
                errorMessageStyle={styles.errorMsg}
                placeholderTextColor={Style.vars.deprecatedColors.grey}
                placeholder="yourcompany.com"
                {...getPropsFromFormik(innerProps, 'companyUrl')}
                onChange={(value) => {
                  this.handleUrlChange(value)
                  innerProps.setFieldValue('companyUrl', value)
                }}
                disabled={innerProps.values.hasNoWebsite}
              />
              <label style={styles.checkbox}>
                <Inputs.CheckboxInput
                  testID="companyHasNoWebsiteInput"
                  errorMessageStyle={styles.errorMsg}
                  placeholderTextColor={Style.vars.deprecatedColors.grey}
                  placeholder="yourcompany.com"
                  {...getPropsFromFormik(innerProps, 'hasNoWebsite')}
                />
                <Text style={styles.checkboxText}>{t('no_website')}</Text>
              </label>

              {this.state.logoURL && (
                <View>
                  <Image src={this.state.logoURL} style={styles.clearbitImage} />
                  <Text style={styles.removeLogo}>
                    {`${t('not_your_logo')} `}
                    <Text style={styles.remove} onClick={this.invalidateLogo}>
                      {t('remove_it')}
                    </Text>
                    <br />
                    {`${t('you_can_upload_your_own_after')}.`}
                  </Text>
                </View>
              )}

              <Inputs.DropdownInput
                testID="companyTypeInput"
                style={{display: 'none'}}
                errorMessageStyle={styles.errorMsg}
                options={companyTypeOptions}
                {...getPropsFromFormik(innerProps, 'companyType')}
              />

              {isBrand && (
                <View>
                  <FieldHeader>{t('regions_info_brand')}</FieldHeader>
                  <Inputs.MultiSelectInput
                    errorMessageStyle={styles.errorMsg}
                    options={regionOptions}
                    placeholder={t('regions_of_responsibility')}
                    {...getPropsFromFormik(innerProps, 'regionsOfResponsibility')}
                  />
                </View>
              )}

              {isRetailerOrHospitality && (
                <View>
                  <FieldHeader>{t('approx_num_stores')}</FieldHeader>
                  <Inputs.TextInput
                    testID="numStoresInput"
                    style={styles.input}
                    placeholder="e.g. 50"
                    {...getPropsFromFormik(innerProps, 'numStores')}
                  />

                  <FieldHeader>{t('approx_num_people')}</FieldHeader>
                  <Inputs.TextInput
                    testID="numPeopleInput"
                    style={styles.input}
                    placeholder="e.g. 10"
                    {...getPropsFromFormik(innerProps, 'numPeoplePerStore')}
                  />
                </View>
              )}

              <SubmitButton
                containerStyle={styles.signupButton}
                textStyle={styles.signupButtonText}
                testID="new-company-signup-submit-btn"
                loading={innerProps.isSubmitting}
                isValid={innerProps.isValid}
                onPress={innerProps.handleSubmit}
              >
                {t('continue')}
              </SubmitButton>
            </View>
          )
        }}
      </Formik>
    )
  }
}

export const NewCompanyForm = Marty.createContainer(NewCompanyFormInner, {
  listenTo: [TagsState.Store, RegionsState.Store],
  fetch: {
    tags() {
      const opts = _.extend(
        {
          limit: 0,
          category: 'industry',
          fields: ['name', 'type', 'url', 'id'],
          ordering: 'name',
        },
        this.props.fetchOpts
      )
      return TagsState.Store.getItems(opts)
    },
    regions() {
      const opts = _.extend({
        limit: 0,
        fields: ['name', 'url', 'id'],
        ordering: 'name',
      })
      return RegionsState.Store.getItems(opts)
    },
  },

  done(results) {
    return (
      <CreateCompanyConnectionMutationContainer>
        {(createCompanyConnection, setReferralMethod) => (
          <NewCompanyFormInner
            {...this.props}
            {...results}
            createCompanyConnection={createCompanyConnection}
            setReferralMethod={setReferralMethod}
          />
        )}
      </CreateCompanyConnectionMutationContainer>
    )
  },

  pending() {
    return containerUtils.defaultPending(this, NewCompanyFormInner)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, NewCompanyFormInner, errors)
  },
})

const styles = {
  input: {
    marginTop: 0,
  },
  checkboxText: {
    paddingLeft: 10,
  },
  checkbox: {
    marginTop: -5,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 15,
  },
  errorMsg: {
    color: Style.vars.deprecatedColors.errorRed,
  },
  signupButton: {
    marginTop: 30,
    marginBottom: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signupButtonText: {
    lineHeight: 2,
    color: Style.vars.deprecatedColors.white,
  },
  remove: {
    cursor: 'pointer',
    fontWeight: 'bolder',
  },
  removeLogo: {
    textAlign: 'center',
    marginTop: 20,
  },
}
