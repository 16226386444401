import React, { useContext } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'

import trainingPageUtils from 'shared-js/utilities/component-helpers/training-page'
import { ProgressBarWithLabel, makeProgressBarStyle } from 'components/common/progress'
import { ANALYTICS_EVENTS } from 'core/constants'
import { CommonRouterContext } from 'shared-js/context'

import { NewIndicator } from 'shared-js/components/common/indicators'
import { useFeatureFlag } from 'shared-js/utilities/hooks'
import { Placeholder } from 'shared-js/components/common/placeholder'

import { getAnalytics } from 'core/state-configuration'
import { ChannelCoverImageCard, CHANNEL_CARD_HEIGHT, CHANNEL_CARD_WIDTH } from './base/card'
import { ChannelCardLearner_channel } from './gql-types/ChannelCardLearner_channel'
import { LogoName } from './base/logo-name'
import { DueDate } from './base/due-date'

const analytics = getAnalytics()

type Props = {
  channel: ChannelCardLearner_channel
}

export const ChannelCardLearner = ({ channel }: Props) => {
  const newContentIndicatorDisabled = useFeatureFlag('disable_new_content_indicator')
  const progressPercentage = Math.floor((channel.progressForUser || 0) * 100)
  const router = useContext(CommonRouterContext)

  const onClick = () => {
    analytics.track(ANALYTICS_EVENTS.CLICKED_CHANNEL_CARD_VIA_LEARNING, {
      'Channel has new content': Boolean(channel.hasNewLessonsForCurrentUser),
    })
    router.handlers.goToLearningChannel(channel.id)
  }

  return (
    <Container onClick={onClick}>
      <ChannelCoverImageCard channel={channel}>
        {!newContentIndicatorDisabled && channel.hasNewLessonsForCurrentUser && (
          <NewIndicator containerStyle={{}} />
        )}
        <Body>
          <LogoName channel={channel} />
          <DueDate date={channel.earliestIncompleteDueDateForUser} />
          <ProgressBarContainer>
            <ProgressBarWithLabel
              barColor={trainingPageUtils.getColorForProgress(progressPercentage)}
              percent={progressPercentage}
            />
          </ProgressBarContainer>
        </Body>
      </ChannelCoverImageCard>
    </Container>
  )
}

ChannelCardLearner.fragment = gql`
  fragment ChannelCardLearner_channel on TrainingUnitType {
    id
    hasNewLessonsForCurrentUser # This field isn't available from the DRF api
    progressForUser
    earliestIncompleteDueDateForUser
    ...ChannelCoverImageCard_channel
    ...LogoName_channel
  }
  ${ChannelCoverImageCard.fragment}
  ${LogoName.fragment}
`

ChannelCardLearner.deprecatedFields = [
  'id',
  'viableLogo',
  'progress_for_user',
  'name',
  'display_name',
  'company.id',
  ...ChannelCoverImageCard.deprecatedFields,
  ...LogoName.deprecatedFields,
]

export const ChannelCardLearnerLoading = () => (
  <Container>
    <Placeholder.Box height={CHANNEL_CARD_HEIGHT} width={CHANNEL_CARD_WIDTH} animate="fade" />
  </Container>
)

const Container = styled.div`
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.85;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE11: for the learning tab */
    margin: 10px;
  }
`

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const ProgressBarContainer = styled.div`
  padding-top: 5px;
  width: 100%;
  transform: scaleX(0.9);
  margin-left: -20px;
`
