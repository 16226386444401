import app from 'core/application'
import Marty from 'marty'
import Im from 'shared-js/immutable'

const Constants = Marty.createConstants(['TOGGLE_TAG_SELECTION'])

const TagCompanyActionCreators = Marty.createActionCreators({
  id: 'TagCompanyActionCreators',
  toggleTagSelection(tag) {
    this.dispatch(Constants.TOGGLE_TAG_SELECTION, tag)
  },
})

const TagCompanyStore = Marty.createStore({
  id: 'TagCompanyStore',

  handlers: {
    onToggleTagSelection: Constants.TOGGLE_TAG_SELECTION,
  },

  getInitialState() {
    return {
      selectedTags: Im.freeze({}),
    }
  },

  onToggleTagSelection(tag) {
    if (this.state.selectedTags[tag.id]) {
      this.state.selectedTags = this.state.selectedTags.delete(tag.id)
    } else {
      this.state.selectedTags = Im.set(this.state.selectedTags, tag.id, tag)
    }
    this.hasChanged()
  },

  isTagSelected(tag) {
    return Boolean(this.state.selectedTags[tag.id])
  },

  getSelectedTags() {
    return this.state.selectedTags
  },

  resetState(currentUser) {
    this.state = this.getInitialState()
    // Determine already selected tags from currentUser object
    if (currentUser.company.tags) {
      currentUser.company.tags.forEach((t) => {
        this.state.selectedTags = Im.set(this.state.selectedTags, t.id, Im.freeze(t))
      })
    }
  },
})

app.register('TagCompanyStore', TagCompanyStore)
app.register('TagCompanyActionCreators', TagCompanyActionCreators)

export default {
  Constants,
  ActionCreators: app.TagCompanyActionCreators,
  Store: app.TagCompanyStore,
}
