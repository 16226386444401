import Marty from 'marty'
import app from 'core/application'

const Constants = Marty.createConstants(['PLANS_SET_SEARCH'])

class PlansSelectActionCreators extends Marty.ActionCreators {
  setSearch = (str) => this.dispatch(Constants.PLANS_SET_SEARCH, str)
}

class PlansSelectStore extends Marty.Store {
  constructor(opts) {
    super(opts)
    this.handlers = {
      onSetSearch: Constants.PLANS_SET_SEARCH,
    }
    this.resetState()
  }

  resetState() {
    const state = {
      search: '',
    }
    this.setState(state)
    this.hasChanged()
  }

  onSetSearch(search) {
    this.setState({ search })
    this.hasChanged()
  }

  getSearch() {
    return this.state.search
  }
}

app.register('PlansSelectStore', PlansSelectStore)
app.register('PlansSelectActionCreators', PlansSelectActionCreators)

export default {
  Store: app.PlansSelectStore,
  ActionCreators: app.PlansSelectActionCreators,
}
