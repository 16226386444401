import React from 'react'
import { t } from 'i18n'
import Style from 'style'
import Radium from 'radium'
import Slider from 'react-slick'
import Rating from 'react-rating'

/* 
`Slider` from `react-slick` needs `slick-theme.css` and `slick.css` imported below 
to function properly. Please, DO NOT REMOVE!
*/
import 'slick-carousel/slick/slick-theme.css'; 
import 'slick-carousel/slick/slick.css'; 

const styles = {
  container: {
    marginBottom: '2rem',
  },
  carouselContainer: {
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
  },
  slideContainer: {
    width: '100%',
  },
  slideInnerContainer: {
    width: '92%',
    margin: '0 auto 15px auto',
  },
  cardContainer: {
    boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
  },
  cardImg: {
    width: '100%',
    paddingTop: '56.25%',
    height: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  planInfoContainer: {
    width: '100%',
    height: '7rem',
    padding: '10px 15px 0 15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  slideTitle: {
    fontSize: '1.25rem',
    fontWeight: '400',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '2',
    margin: 0,
    [Style.vars.media.mobile]: {
      fontSize: '1.1rem',
    },
  },
  slideFooter: {
    textAlign: 'center',
    width: '100%',
    minHeight: '7vh',
    display: 'flex',
    justifyContent: 'center',
  },
  slideFooterHalf: {
    width: '50%',
    height: '100%',
    padding: 5,
  },
  sliderImg: {
    height: '17vh',
    backgroundColor: 'none',
    margin: '0 10px 1.5rem 10px',
  },
  slideFooterTextNumber: {
    fontSize: 20,
    color: Style.vars.deprecatedColors.primary,
    [Style.vars.media.mobile]: {
      fontSize: 18,
    },
  },
  slideFooterText: {
    margin: '0 auto',
  },
  star: {
    fontSize: '1.2rem',
    color: Style.vars.deprecatedColors.primary,
    margin: '10px 0 5px 0',
    [Style.vars.media.xSmall]: {
      fontSize: '0.80rem',
    },
  },
  tilesContainer: {
    whiteSpace: 'nowrap',
    padding: '0 10px',
    [Style.vars.media.mobile]: {
      overflowX: 'auto',
    },
  },
  tileContainer: {
    width: '30%',
    margin: '0 10px 10px 10px',
    display: 'inline-block',
    [Style.vars.media.mobile]: {
      width: '44%',
    },
    [Style.vars.media.xSmall]: {
      width: '60%',
    },
  },
}

@Radium
class TrainingPlanCard extends React.Component {
  render() {
    return (
      <div style={styles.cardContainer} onClick={this.props.next}>
        <div
          style={{
            ...styles.cardImg,
            backgroundImage: `url(${this.props.img})`,
          }}
        />
        <div style={styles.planInfoContainer}>
          <h1 style={styles.slideTitle}>{this.props.name}</h1>
          {this.props.rating > 3 ? (
            <Rating
              emptySymbol={<i style={styles.star} className="icon outline star" />}
              fullSymbol={<i style={styles.star} className="icon star" />}
              initialRating={this.props.rating}
              readonly
            />
          ) : null}
        </div>
        <div style={styles.slideFooter}>
          <div style={styles.slideFooterHalf}>
            <h2
              style={{
                ...styles.slideFooterText,
                ...styles.slideFooterTextNumber,
              }}
            >
              {this.props.num_modules}
            </h2>
            <p style={styles.slideFooterText}>
              {t('lessons_titlecase', {}, { pluralize: this.props.num_modules })}
            </p>
          </div>
          <div style={styles.slideFooterHalf}>
            <h2
              style={{
                ...styles.slideFooterText,
                ...styles.slideFooterTextNumber,
              }}
            >
              {this.props.num_of_attempts}
            </h2>
            <p style={styles.slideFooterText}>
              {t('views', {}, { pluralize: this.props.num_of_attempts })}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

@Radium
class Tiles extends React.Component {
  render() {
    return (
      <div style={{ ...styles.plansContainer, ...styles.tilesContainer }}>
        {this.props.plans.map((plan, i) => (
          <div key={i} style={styles.tileContainer}>
            <TrainingPlanCard
              img={plan.thumbnail_url}
              name={plan.name}
              num_modules={plan.modules.length}
              num_of_attempts={plan.num_of_attempts}
              rating={plan.avg_like_rating}
              next={this.props.next}
            />
          </div>
        ))}
      </div>
    )
  }
}

@Radium
class Carousel extends React.Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      swipeToSlide: true,
      centerMode: true,
      slidesToShow: 3,
      centerPadding: '60px',
      responsive: [
        { breakpoint: 992, settings: { slidesToShow: 2 } },
        { breakpoint: 520, settings: { slidesToShow: 1 } },
      ],
    }

    return (
      <div style={styles.carouselContainer}>
        <Slider ref="slick" {...settings}>
          {this.props.plans.map((plan, i) => (
            <div key={i} style={styles.slideContainer}>
              <div style={styles.slideInnerContainer}>
                <TrainingPlanCard
                  img={plan.thumbnail_url}
                  name={plan.name}
                  num_modules={plan.modules.length}
                  num_of_attempts={plan.num_of_attempts}
                  rating={plan.avg_like_rating}
                  next={this.props.next}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

@Radium
export class PlansCarousel extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        {this.props.plans.length > 3 ? (
          <Carousel plans={this.props.plans} next={this.props.next} />
        ) : (
          <Tiles plans={this.props.plans} next={this.props.next} />
        )}
      </div>
    )
  }
}
