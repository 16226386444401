import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import RedirectToFrontend2 from '../common/redirect-to-frontend2'

export const peopleRoutes = (
  <Route>
    <Route component={RedirectToFrontend2}>
      <IndexRedirect to="users/" />
      <Route name="teams" path="teams/" component={RedirectToFrontend2} />
      <Route name="users" path="users/" component={RedirectToFrontend2} />
      <Route name="invites" path="invites/" component={RedirectToFrontend2}>
        <Route path="*" component={RedirectToFrontend2} />
      </Route>
      <Route name="areas" path="areas/" component={RedirectToFrontend2} />
      <Route name="groups" path="groups/" component={RedirectToFrontend2} />
    </Route>
    <Route name="team" path="teams/:teamId/" component={RedirectToFrontend2} />
    <Route name="area" path="areas/:areaId/" component={RedirectToFrontend2} />
    <Route
      name="enrollment-groups"
      path="enrollment-groups/:enrollmentGroupId/"
      component={RedirectToFrontend2}
    />
  </Route>
)
