import React from 'react'
import MUITabs, { TabsProps } from '@material-ui/core/Tabs'
import { Theme, withStyles, createStyles } from '@material-ui/core/styles'
import MUITab, { TabProps } from '@material-ui/core/Tab'

/**
 * TabIndicatorProps is the way you can override the current children and give is a
 * static `div` this will allow you to override the style using the indicator css class
 * provided by Material UI
 */

const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > div': {
        width: '100%',
        backgroundColor: theme.palette.text.primary,
      },
    },
  })
)((props: TabsProps) => <MUITabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: theme.palette.text.primary,
      '&:focus': {
        opacity: 1,
      },
    },
  })
)((props: TabProps) => <MUITab {...props} />)

export const Tabs = StyledTabs
export const Tab = StyledTab
