import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import { Page as ChannelDiscovery } from './channel-discovery/channel-directory/page'
import { Page as BrandDiscovery } from './brand-discovery/page'

export const discoverRoutes = (
  <Route>
    <IndexRedirect to="brands/" />
    <Route path="channels/" name="channel-discovery" component={ChannelDiscovery} />
    <Route path="brands/" name="brand-discovery" component={BrandDiscovery}>
      <Route path="*" component={BrandDiscovery} />
    </Route>
  </Route>
)
