import React from 'react'
import { Route, Redirect, IndexRedirect, IndexRoute, browserHistory } from 'react-router'

import { Page as User } from './user-enrollments/page'
import { Page as TrainingPlan } from './training-plan-enrollments/page'

export const enrollmentRoutes = (
  <Route>
    <Route path="users/:userId/" name="user-enrollments" component={User} />
    <Route
      path="training-plans/:planId/"
      name="training-plan-enrollments"
      component={TrainingPlan}
    />
  </Route>
)
