import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import $y from 'utilities/yaler'
import { t } from 'i18n'
import Style from 'style'
import { Modal } from 'components/common/modals'
import { Form, SubmitButton } from 'components/common/form-deprecated'
import { AsyncSearchableSelect } from 'components/common/form-deprecated/select'

import CompaniesState from 'state/companies'
import UsersState from 'state/users'
import PageState from './state'

const styles = {
  button: {
    background: Style.vars.deprecatedColors.primary,
    borderRadius: 0,
    width: '40%',
    color: Style.vars.deprecatedColors.white,
  },
  buttonInvalid: {
    background: Style.vars.deprecatedColors.fadedPrimary,
    cursor: 'not-allowed',
  },
}

export class ActionConfirmationModal extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  showModal = () => {
    this.actionConfirmationModal.show()
  }

  hideModal = () => {
    this.actionConfirmationModal.hide()
  }

  render() {
    const action = this.props.action
    const message =
      this.props.actionDescription === 'Deactivate'
        ? `DEACTIVATE company with id: ${this.props.companyId}?`
        : `REMOVE this duplicate from this company's duplicate list?`

    return (
      <Modal
        ref={(element) => (this.actionConfirmationModal = element)}
        header={`Are you sure you want to ${message}`}
        closeOnDimmerClick={false}
      >
        <button
          className="ui button"
          style={styles.button}
          onClick={() => {
            this.hideModal()
          }}
        >
          Cancel
        </button>
        <button
          className="ui button"
          style={styles.button}
          onClick={() => {
            action().then(this.hideModal())
          }}
        >
          {this.props.actionDescription}
        </button>
      </Modal>
    )
  }
}
