import Marty from 'marty'
import React from 'react'
import CompaniesState from 'state/companies'
import CompanyConnectionsState from 'state/company-connections'
import { LoadingSpinner } from 'components/common/loading'
import containerUtils from 'utilities/containers'
import PropTypes from 'prop-types'
import Im from 'shared-js/immutable'

const DEFAULT_MSG =
  'There is no record of this website on Diffbot. (Diffbot clears our scraping data out every 14 days)'

const styles = {
  spinnerContainer: {
    display: 'inline-block',
    height: 'auto',
  },
  spinner: {
    height: 20,
    width: 20,
  },
  icon: {
    opacity: 1,
    margin: 0,
  },
}

class DiffbotScraper extends React.Component {
  state = {
    loading: false,
    hasFetched: false,
    job: {
      jobStatus: {
        message: DEFAULT_MSG,
      },
      pageCrawlSuccesses: 0,
      pageProcessSuccesses: 0,
    },
  }

  scrapeAction = (action) => {
    this.setState({ loading: action })
    if (action === 'check-status') {
      this.checkScrapeStatus()
      return
    }
    CompaniesState.ActionCreators.doListAction(action, {
      company_id: this.props.params.companyId,
    })
      .then((res) => {
        this.setState({ loading: false })
        this.checkScrapeStatus()
      })
      .catch((res) => {
        this.setState({ loading: false })
      })
  }

  checkScrapeStatus = () => {
    this.setState({ loading: 'check-status' })
    CompaniesState.ActionCreators.doListAction('check_diffbot_job_status', {
      company_id: this.props.params.companyId,
    }).then((res) => {
      this.setState({ loading: false, hasFetched: true })
      try {
        const job = res.body.jobs[0]
        this.setState({ job })
      } catch (err) {
        this.setState({
          job: {
            jobStatus: {
              message: DEFAULT_MSG,
            },
            pageCrawlSuccesses: 0,
            pageProcessSuccesses: 0,
          },
        })
      }
    })
  }

  renderButtons = () => {
    const { loading } = this.state
    const { company } = this.props
    const BUTTONS = [
      {
        action: 'check-status',
        text: 'Check Status',
      },
      {
        action: 'start_diffbot_job',
        icon: 'play',
      },
      {
        action: 'pause_diffbot_job',
        icon: 'pause',
      },
      {
        action: 'restart_diffbot_job',
        icon: 'undo',
      },
      {
        action: 'delete_diffbot_job',
        icon: 'trash',
      },
    ]
    return BUTTONS.map((b) => (
      <button
        key={b.action}
        className="ui blue button"
        onClick={() => this.scrapeAction(b.action)}
        disabled={!company.company_url}
      >
        {loading === b.action ? (
          <LoadingSpinner containerStyle={styles.spinnerContainer} spinnerStyle={styles.spinner} />
        ) : b.icon ? (
          <i style={styles.icon} className={`ui icon ${b.icon}`} />
        ) : (
          <span>{b.text}</span>
        )}
      </button>
    ))
  }

  render() {
    const { job, hasFetched } = this.state
    const { company } = this.props
    if (!company) return <span />
    return (
      <div style={{ marginBottom: 100 }}>
        <h3>Scraper</h3>
        <div className="ui blue message" style={{ marginBottom: 20 }}>
          <b>What is this tool used for?</b>
          <p>
            {`This will scrape the company's website and make any relevant brand connections.
            Once completed, it will ping the slack channel #scraper. Make sure the company you select as a website set!
            `}
          </p>
        </div>
        <div>
          <ul>
            <li>{`Company: ${company.name} (${company.id})`}</li>
            <li>{`Website: ${company.company_url}`}</li>
            <li>
              {`No. of Company Connection Requests: ${company.dealer_connections_count}`}
            </li>
          </ul>
        </div>
        {!company.company_url && (
          <div className="ui red message">This company has no website set</div>
        )}
        {this.renderButtons()}
        <div>
          {hasFetched && (
            <div style={{ marginTop: 50 }}>
              <h3>Job Status</h3>
              <ul>
                <li>{`Status: ${job.jobStatus.message}`}</li>
                <li>{`Pages Crawled: ${job.pageCrawlSuccesses}`}</li>
                <li>{`Pages Processed: ${job.pageProcessSuccesses}`}</li>
                <li>
                  {`JSON:`}
                  {' '}
                  <a target="_blank" href={job.downloadJson}>
                    {job.downloadJson}
                  </a>
                </li>
                <li>
                  {`CSV:`}
                  {' '}
                  <a target="_blank" href={job.downloadUrls}>
                    {job.downloadUrls}
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export const Page = Marty.createContainer(DiffbotScraper, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [CompaniesState.Store, CompanyConnectionsState.Store],

  fetch: {
    company() {
      const company = this.props.params.companyId
      return CompaniesState.Store.getItem(company, {
        fields: ['*'],
      })
    },
  },

  pending() {
    return containerUtils.defaultPending(this, DiffbotScraper)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, DiffbotScraper, errors)
  },
})
