import Marty from 'marty'
import app from 'core/application'

const Constants = Marty.createConstants(['PUBLIC_COMPANY_SEARCH', 'SELECTED_COMPANIES'])

const PublicCompanyActionCreators = Marty.createActionCreators({
  id: 'PublicCompanyActionCreators',
  setPublicCompanySearch(str) {
    this.dispatch(Constants.PUBLIC_COMPANY_SEARCH, str)
  },
  setSelectedCompanies(id) {
    this.dispatch(Constants.SELECTED_COMPANIES, id)
  },
})

const PublicCompanyStore = Marty.createStore({
  id: 'PublicCompanyStore',
  handlers: {
    onSetPublicCompanySearch: Constants.PUBLIC_COMPANY_SEARCH,
    onSetSelectedCompanies: Constants.SELECTED_COMPANIES,
  },
  getInitialState() {
    return {
      search: '',
      selected: [],
    }
  },
  onSetPublicCompanySearch(str) {
    this.state.search = str
    this.hasChanged()
  },
  onSetSelectedCompanies(id) {
    const { selected } = this.state
    if (selected.indexOf(id) > -1) {
      this.state.selected = _.filter(selected, (i) => i !== id)
    } else {
      this.state.selected = [...selected, id]
    }
    this.hasChanged()
  },
  getPublicCompanySearch() {
    return this.state.search
  },
  getSelectedCompanies() {
    return this.state.selected
  },
  resetState() {
    this.state = this.getInitialState()
  },
})

app.register('PublicCompanyStore', PublicCompanyStore)
app.register('PublicCompanyActionCreators', PublicCompanyActionCreators)

export default {
  Constants,
  ActionCreators: app.PublicCompanyActionCreators,
  Store: app.PublicCompanyStore,
}
