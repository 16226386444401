import React from 'react'
import styled from 'styled-components'
import { Button } from 'ui-kit'
import { t } from 'shared-js/i18n'
import { successImage } from './images'

type SuccessMessageProps = {
  unlockNetworkFeature(): void
}

const SuccessMessage = ({ unlockNetworkFeature }: SuccessMessageProps) => {
  return (
    <Container>
      <Image />
      <Title>{t('youre_in_exclamation')}</Title>
      <Info>{t('entered_the_network_feature_info')}</Info>
      <ButtonContainer>
        <Button onClick={unlockNetworkFeature}>{t('access_the_network_titlecase')}</Button>
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 92vw;
  max-width: 326px;
  padding: 52px 24px 32px 24px;
`
const Image = styled.div`
  height: 92px;
  width: 161px;
  margin: auto;
  margin-bottom: 24px;
  background-image: ${`url("${successImage}")`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.grey800};
  margin-bottom: 8px;
`

const Info = styled.div`
  line-height: 1.71;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey700};
`

const ButtonContainer = styled.div`
  margin-top: 28px;
`

export default SuccessMessage
