import { useState, useEffect } from 'react'
import queryString from 'query-string'

// as any fixes a type mismatch between @types and the actual package
const PARSE_OPTIONS = { parseBooleans: true, parseNumbers: true, arrayFormat: 'comma' as any }
const REMOVABLE_VALUES = [false, null, undefined, '']

/**
 * State that is reflected in the url as a search param.
 *
 * Note:
 * - The query param in the url takes precedence over `defaultValue` when initializing the
 * state.
 * - The url cannot handle objects that are not stringified.
 * - It can handle arrays, (`?stuff=1,2,3`) however a single item array looks the same as a single
 * value.
 */
export function useUrlState(key: string, defaultValue?: any) {
  const {
    url: currentUrl,
    query: { [key]: urlState, ...otherParams },
  } = queryString.parseUrl(window.location.href, PARSE_OPTIONS)

  const [state, setState] = useState(urlState || defaultValue)

  useEffect(() => {
    if ((!urlState && !state) || urlState == state) return // eslint-disable-line eqeqeq

    // To keep the url clean, some falsy state values are removed from the url
    const updatedParams = REMOVABLE_VALUES.includes(state)
      ? otherParams
      : { ...otherParams, [key]: state }

    const paramsString = queryString.stringify(updatedParams, PARSE_OPTIONS)
    const updatedUrl = currentUrl + (paramsString !== '' ? '?' : '') + paramsString

    window.history.pushState({}, '', updatedUrl)
  }, [key, state, otherParams, currentUrl, urlState])

  return [state, setState]
}

/**
 * Gets a url param value - Prefer `useUrlState` if you're using a functional component.
 */
export function getUrlParam(name: string) {
  const { query } = queryString.parseUrl(window.location.href, PARSE_OPTIONS)
  return query[name]
}

/**
 * Sets a url param value - Prefer `useUrlState` if you're using a functional component.
 */
export function setUrlParam(name: string, value: any) {
  const {
    url: currentUrl,
    query: { [name]: urlState, ...otherParams },
  } = queryString.parseUrl(window.location.href, PARSE_OPTIONS)

  if ((!urlState && !value) || urlState == value) return // eslint-disable-line eqeqeq

  // To keep the url clean, some falsy state values are removed from the url
  const updatedParams = REMOVABLE_VALUES.includes(value)
    ? otherParams
    : { ...otherParams, [name]: value }

  const paramsString = queryString.stringify(updatedParams, PARSE_OPTIONS)
  const updatedUrl = currentUrl + (paramsString !== '' ? '?' : '') + paramsString

  window.history.pushState({}, '', updatedUrl)
}
