import React from 'react'
import styled from 'styled-components'
import { Placeholder } from 'shared-js/components/common/placeholder'

export const SectionTitle = ({ text }: { text: string }) => <Title>{text}</Title>

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`

export const SectionTitleLoading = () => <Placeholder.Box height={30} width={200} animate="fade" />

export default SectionTitle
