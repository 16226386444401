/* eslint-disable react/jsx-no-literals */
import { DatetimeInput } from 'components/common/form-deprecated'
import { SlideToggleInput } from 'shared-js/components/common/inputs'
import { Modal } from 'components/common/modals/index'
import CompaniesState from 'state/companies'
import PropTypes from 'prop-types'
import React from 'react'
import SubscriptionsState from 'state/subscriptions'
import moment from 'moment-timezone'

import { Tooltip } from 'ui-kit'
import InfoIcon from '@material-ui/icons/Info'

const pageStyle = {
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  stat: {
    fontWeight: 'bold',
    verticalAlign: 'bottom',
  },
  slideToggle: {
    verticalAlign: 'bottom',
    marginLeft: 10,
  },
  icon: {
    verticalAlign: 'bottom',
  },
}

export class EditSubscription extends React.Component {
  static data = {
    subscription: {
      required: false,
      fields: [
        'id',
        'url',
        'paused',
        'pause_on_date',
        'groups_and_areas_enabled',
        'user_permissions_enabled',
        'num_external_teams_connected_to_content',
        'analytics_enabled',
        'shared_content_enabled',
        'parent_child_companies_enabled',
        'company.name',
        'company.id',
        'company.company_type',
      ],
    },
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super()
    const subscription = props.company.subscription
    this.state = {
      pause_on_date: subscription.pause_on_date,
    }
  }

  onPauseOnDateChange = (field, val) => {
    let newVal
    if (moment.isMoment(val)) {
      newVal = val.toJSON()
    } else {
      newVal = null
    }
    this.setState({ [field]: newVal })
    SubscriptionsState.ActionCreators.update(this.props.company.subscription.id, {
      [field]: newVal,
    }).then(() => {
      this.context.displayTempPositiveMessage({
        heading: 'Pause date updated',
      })
    })
  }

  onToggleSubscriptionOpt = (opt) => {
    const subscription = this.props.company.subscription
    const data = {}
    data[opt] = !subscription[opt]
    SubscriptionsState.ActionCreators.update(this.props.company.subscription.id, data).then(
      (res) => {
        this.context.displayTempPositiveMessage({
          heading: 'changes_saved',
        })
      }
    )
  }

  showCompanyAdminPermissonsDeactivateModal = () => {
    const subscription = this.props.company.subscription
    if (
      subscription.user_permissions_enabled &&
      subscription.company.company_type === 'retailer' &&
      (!subscription.account_status || subscription.account_status === 'freemium')
    ) {
      this.deactivateModal.show()
    } else {
      this.onToggleSubscriptionOpt('user_permissions_enabled')
    }
  }

  onConfirmRevokeCompanyUsersPermission = () => {
    CompaniesState.ActionCreators.doDetailAction(
      this.props.company.id,
      'revoke_company_users_admin_permissions'
    )
      .then(() => {
        this.context.displayTempPositiveMessage({
          heading: 'Success',
          body: 'You ran a task to revoke users admin permissions',
        })
      })
      .catch((err) => {
        const message = err.response.body.detail
        this.context.displayTempNegativeMessage({
          heading: 'Error',
          body: message,
        })
      })
  }

  showRevokeCompanyUsersPermissionsModal = () => {
    this.revokeCompanyUsersPermissionsModal.show()
  }

  render() {
    const subscription = this.props.company.subscription
    const enableAnalytics = subscription.analytics_enabled
    const enableGroupsAndAreas = subscription.groups_and_areas_enabled
    const enableUserPermissions = subscription.user_permissions_enabled
    const enableSharedContent = subscription.shared_content_enabled

    let accountSubscriptionType = 'Not Categorised'
    let accountSubscriptionTypeExplanation =
      'Does not fall under any Freemium Retailer subscription type.'

    if (
      subscription.company.company_type === 'retailer' &&
      (!subscription.account_status || subscription.account_status === 'freemium')
    ) {
      if (
        !(enableAnalytics || enableGroupsAndAreas || enableUserPermissions || enableSharedContent)
      ) {
        accountSubscriptionType = 'Freemium Retailer'
        accountSubscriptionTypeExplanation =
          'Does not pay for Myagi, and no direct relationship with Customer Success. No Subscription Permissions will be enabled for them.'
      } else if (
        !enableAnalytics &&
        !enableSharedContent &&
        (enableGroupsAndAreas || enableUserPermissions)
      ) {
        accountSubscriptionType = 'Brand Supported Free Retailer'
        accountSubscriptionTypeExplanation =
          'Does not pay for Myagi, and is an unmanaged account where Myagi Staff have intervened to grant access to a limited set of features.This is most commonly Enable team / area / group creation and/or Enable company admin / team manager Permissions.'
      }
    }

    return (
      <React.Fragment>
        <h3>Subscription</h3>
        <p>
          <span>
            <b>Number of external teams connected to content: </b>
            {this.props.company.num_external_teams_connected_to_content}
          </span>
          <br />
          <span>
            This figure represents the number of external (out of company) teams that have users
            enrolled in plans created by this company.
          </span>
        </p>
        <p>
          <span>
            <b>Pause on date:</b>
          </span>
          <br />
          <span>
            Schedule a future subscription pause date. If set, the subscription will be paused on
            the this date. Clear field to cancel pause on date.
          </span>
        </p>

        <DatetimeInput
          style={pageStyle.dateInput}
          onChange={this.onPauseOnDateChange.bind(this, 'pause_on_date')}
          initialValue={moment(this.state.pause_on_date).utc()}
          isValidDate={this.isValidPauseOnDate}
        />
        <br />

        <p>
          <span>
            <b>Free Retail Subscription Type: </b>
          </span>
          <span>
            {`${accountSubscriptionType} `}
            <Tooltip title={accountSubscriptionTypeExplanation} arrow>
              <InfoIcon style={pageStyle.icon} />
            </Tooltip>
          </span>
        </p>

        <br />

        <SubscriptionToggle
          label="Enable analytics"
          initialValue={enableAnalytics}
          onToggle={() => this.onToggleSubscriptionOpt('analytics_enabled')}
        />

        <SubscriptionToggle
          label="Enable enrollments, area, group creation"
          tooltip="Enables Admins, Area Managers, and Team Managers to create Areas, Groups and Enrollments."
          initialValue={enableGroupsAndAreas}
          onToggle={() => this.onToggleSubscriptionOpt('groups_and_areas_enabled')}
        />

        <Modal
          ref={(e) => (this.deactivateModal = e)}
          header="Deactivate company admin / team manager permissions"
          closeOnDimmerClick={false}
          onConfirm={() => this.onToggleSubscriptionOpt('user_permissions_enabled')}
          basic
        >
          If you disable admin permissions management for this company, all company users will be
          automatically promoted to admins. Are you sure?
        </Modal>

        <SubscriptionToggle
          label="Enable company admin / team manager permissions"
          tooltip="Enables Admins to create, assign and remove Team Manager and Admin permissions."
          initialValue={enableUserPermissions}
          onToggle={this.showCompanyAdminPermissonsDeactivateModal}
        />

        <SubscriptionToggle
          label="Enable shared content"
          initialValue={enableSharedContent}
          onToggle={() => this.onToggleSubscriptionOpt('shared_content_enabled')}
        />

        <br />

        <button className="ui blue button" onClick={this.showRevokeCompanyUsersPermissionsModal}>
          Revoke users permissions
        </button>

        <Tooltip
          title="Revoke Admins permissions for all company users. All users will become learners."
          arrow
        >
          <InfoIcon style={pageStyle.icon} />
        </Tooltip>

        <Modal
          ref={(e) => (this.revokeCompanyUsersPermissionsModal = e)}
          header="Revoke all company users permissions"
          closeOnDimmerClick={false}
          onConfirm={this.onConfirmRevokeCompanyUsersPermission}
          basic
        >
          Revoke Admins permissions for all company users. All users will become learners. Are you
          sure?
        </Modal>
      </React.Fragment>
    )
  }
}

const SubscriptionToggle = ({ label, tooltip, onToggle, initialValue }) => (
  <div style={pageStyle.toggleContainer}>
    <span style={pageStyle.stat}>
      {`${label}: `}
      {tooltip && (
        <Tooltip title={tooltip} arrow>
          <InfoIcon style={pageStyle.icon} />
        </Tooltip>
      )}
    </span>
    <SlideToggleInput value={initialValue} style={pageStyle.slideToggle} onChange={onToggle} />
  </div>
)
