import React from 'react'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import { TextField, SingleSelect } from 'ui-kit'
import { getFormikPropsForMUI, FormikProps } from 'shared-js/components/common/form'
import RegionsField from './regions-of-responsibility-field'

const ONE_TO_FIFTEEN = '1-15'
const SIXTEEN_TO_ONE_HUNDRED = '16-100'
const ONE_HUNDRED_AND_ONE_TO_FIVE_HUNDRED = '101-500'
const MORE_THAN_FIVE_HUNDRED = '500 <'

type FormValues = {
  website?: string
  size?: string
  regions?: string[]
}

type FieldsProps = {
  formikProps: FormikProps<FormValues>
  isABrand: boolean
}

const Fields = ({ formikProps, isABrand }: FieldsProps) => {
  const moreThan500Label = isABrand
    ? t('more_than_stores', { number: 500 })
    : t('more_than_employees', { number: 500 })

  const sizeOptions = [
    { value: ONE_TO_FIFTEEN, label: ONE_TO_FIFTEEN },
    { value: SIXTEEN_TO_ONE_HUNDRED, label: SIXTEEN_TO_ONE_HUNDRED },
    { value: ONE_HUNDRED_AND_ONE_TO_FIVE_HUNDRED, label: ONE_HUNDRED_AND_ONE_TO_FIVE_HUNDRED },
    { value: MORE_THAN_FIVE_HUNDRED, label: moreThan500Label },
  ]

  const sizePlaceholder = isABrand
    ? t('number_of_stores_titlecase')
    : t('number_of_employees_titlecase')

  return (
    <>
      <InputContainer>
        <TextField
          label={t('your_website_url_titlecase')}
          fullWidth
          helperText="www.website.com"
          {...getFormikPropsForMUI(formikProps, 'website')}
        />
      </InputContainer>
      <InputContainer>
        <SingleSelect
          placeholder={sizePlaceholder}
          getOptionLabel={(opt: any) => opt.label}
          options={sizeOptions}
          {...getFormikPropsForMUI(formikProps, 'size')}
        />
      </InputContainer>
      <InputContainer>
        <RegionsField
          isABrand={isABrand}
          {...getFormikPropsForMUI(formikProps, 'regionsOfOperation')}
        />
      </InputContainer>
    </>
  )
}

const InputContainer = styled.div`
  margin-bottom: 12px;
`

export default Fields
