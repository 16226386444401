import React from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'

import Style from 'shared-js/style'
import { ChannelCoverImageCard_channel } from './gql-types/ChannelCoverImageCard_channel'

export const CHANNEL_CARD_HEIGHT = 270
export const CHANNEL_CARD_WIDTH = 230

type Props = {
  channel: ChannelCoverImageCard_channel,
  children: React.ReactNode,
}

export const ChannelCoverImageCard = ({ channel, children }: Props) => (
  <Card image={channel.viableCoverImage} color={Style.vars.deprecatedColors.navBackground}>
    <Overlay />
    <Children>{children}</Children>
  </Card>
)

ChannelCoverImageCard.fragment = gql`
  fragment ChannelCoverImageCard_channel on TrainingUnitType {
    viableCoverImage
  }
`

ChannelCoverImageCard.deprecatedFields = ['viableCoverImage']

type CardProps = {
  image: string | null,
  color: string,
}

const Card = styled.div<CardProps>`
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: ${CHANNEL_CARD_HEIGHT}px;
  width: ${CHANNEL_CARD_WIDTH}px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-image: ${({ image }) => `url('${image}')`};
  background-color: ${({ color }) => `url('${color}')`};
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  opacity: 0.6;
  zindex: 1;
  cursor: pointer;
  transition: all 0.3s ease;
`

const Children = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  height: 100%;
  width: 100%;
`
