import React from 'react'
import { t } from 'shared-js/i18n'
import styled from 'styled-components'

import { Chip } from 'ui-kit'
import { useQuery } from '@apollo/react-hooks'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import gql from 'graphql-tag'
import { useBrandsFilter, useIndustryFilter } from '../context'
import { TAG_FRAGMENT, BRAND_FRAGMENT } from '../filter/common'
import {
  ActiveBrandFiltersQuery_PublicCompany__List_edges as BrandFilter_edge,
  ActiveBrandFiltersQuery_PublicCompany__List_edges_node as BrandFilter_node,
} from './gql-types/ActiveBrandFiltersQuery'
import {
  ActiveIndustryFiltersQuery_Tag__List_edges as IndustryFilter_edge,
  ActiveIndustryFiltersQuery_Tag__List_edges_node as IndustryFilter_node,
} from './gql-types/ActiveIndustryFiltersQuery'

const LOADING_SIZE = 100

const ACTIVE_BRAND_FILTERS = gql`
  query ActiveBrandFiltersQuery($loadingSize: Int!, $IDs: [ID!], $companyID: ID!) {
    PublicCompany__List(
      first: $loadingSize
      id_In: $IDs
      isConnectedWith: $companyID
      orderBy: ["id"]
    ) {
      edges {
        node {
          ...ConnectedBrands_brand
        }
      }
    }
  }
  ${BRAND_FRAGMENT}
`

const ACTIVE_INDUSTRY_FILTERS = gql`
  query ActiveIndustryFiltersQuery($loadingSize: Int!, $IDs: [ID!], $companyID: ID!) {
    Tag__List(first: $loadingSize, id_In: $IDs, networkTags: $companyID) {
      edges {
        node {
          ...RelevantIndustryTags_tag
        }
      }
    }
  }
  ${TAG_FRAGMENT}
`

// prettier-ignore
const isAConnectionFilter = (
  filter: BrandFilter_node | IndustryFilter_node
): filter is BrandFilter_node => (filter as BrandFilter_node)?.discoverableChannelsCount !== undefined

const orderFilters = (
  queryStringArray: string[],
  filteredObjects: (BrandFilter_edge | IndustryFilter_edge)[]
) => {
  return queryStringArray.map((connectionIDFromQueryString) =>
    filteredObjects.find(
      (connectionFromState) => connectionFromState?.node?.id === connectionIDFromQueryString
    )
  )
}

const SearchInfo = () => {
  const { brandsFiltered } = useBrandsFilter()
  const { industriesFiltered } = useIndustryFilter()
  const currentUser = useCurrentUser()

  const connectionQueryVariables = {
    loadingSize: LOADING_SIZE,
    companyID: currentUser.company.id,
    IDs: brandsFiltered,
  }

  const connectionsApolloProps = useQuery(ACTIVE_BRAND_FILTERS, {
    variables: connectionQueryVariables,
  })

  const industryQueryVariables = {
    loadingSize: LOADING_SIZE,
    companyID: currentUser.company.id,
    IDs: industriesFiltered,
  }

  const industriesApolloProps = useQuery(ACTIVE_INDUSTRY_FILTERS, {
    variables: industryQueryVariables,
  })

  return (
    <SearchInfoInner
      brands={connectionsApolloProps?.data?.PublicCompany__List?.edges || []}
      industries={industriesApolloProps?.data?.Tag__List?.edges || []}
    />
  )
}

const SearchInfoInner = ({
  brands,
  industries,
}: {
  brands: BrandFilter_edge[]
  industries: IndustryFilter_edge[]
}) => {
  const { brandsFiltered, addOrRemoveBrandFilter } = useBrandsFilter()
  const { industriesFiltered, addOrRemoveIndustryFilter } = useIndustryFilter()

  const removeFilter = (filter: BrandFilter_node | IndustryFilter_node) => {
    if (isAConnectionFilter(filter)) {
      addOrRemoveBrandFilter(filter as BrandFilter_node)
    } else {
      addOrRemoveIndustryFilter(filter as IndustryFilter_node)
    }
  }

  const orderedBrands = orderFilters(brandsFiltered, brands)
  const orderedIndustries = orderFilters(industriesFiltered, industries)
  const filtersUsed = [...orderedBrands, ...orderedIndustries]

  return filtersUsed.length > 0 ? (
    <Container>
      <SectionTitle>{t('showing_results_for')}</SectionTitle>

      {filtersUsed.map(
        (filter) =>
          filter && (
            <ChipContainer>
              <Chip
                key={filter.node?.id}
                label={filter.node?.name}
                clickable
                onDelete={() => {
                  if (filter.node) {
                    removeFilter(filter.node)
                  }
                }}
                onClick={() => {
                  if (filter.node) {
                    removeFilter(filter.node)
                  }
                }}
              />
            </ChipContainer>
          )
      )}
    </Container>
  ) : null
}

export default SearchInfo

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: baseline;
  justify-content: left;
  flex-wrap: wrap;
`

const SectionTitle = styled.div`
  margin-right: 20px;
`

const ChipContainer = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
`
