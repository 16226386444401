import { LoadingSpinner } from 'components/common/loading'
import { Modal } from 'components/common/modals'
import React, { useImperativeHandle } from 'react'
import _ from 'lodash'
import iFrameResize from 'iframe-resizer'
import { useUrlState } from 'utilities/querystring'
import Style from 'shared-js/style'

// NOTE: This has been copied directly from css/style.css
const baseStyling = `
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
input,
textarea,
td {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
}

span,
div {
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
}

h2 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
}

h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

body,
p,
span,
div {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

small {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

`

const NO_RESULTS_IMG_SRC = '../app/assets/img/no_results.svg'
const NO_RESULTS_ABSOLUTE_IMG_SRC = 'app/assets/img/no_results.svg'

const styles = {
  iframe: {
    overflow: 'visible',
    height: 0,
    marginBottom: '60px',
  },
  modalContent: {
    marginTop: 20,
  },
}

export class MetabaseDashboardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.registerIFrameOnLoad()
  }

  componentDidUpdate() {
    this.registerIFrameOnLoad()
  }

  componentWillUnmount() {
    if (this.observer) this.observer.disconnect()
  }

  getIFrameDocEl() {
    const doc = this.iframe.contentWindow.document || this.iframe.contentDocument
    return doc
  }

  setDynamicReportListener = () => {
    /*
     ** The main goal of this function is to override the "No results" text which appears
     ** on dashcards when an account is new. It's a bit complicated because we have to
     ** wait until dash cards have loaded their data, and there is no simple DOM selector
     ** for finding no results or warning text. Because of this, it is prone to breaking
     ** if Metabase templates change. Will need to keep this in mind when upgrading.
     */
    // MutationObserver not supported, so do nothing
    if (!window.MutationObserver || !this.iframe) return
    const doc = this.getIFrameDocEl()
    const target = doc.getElementsByClassName('EmbedFrame')[0]
    // Create an observer instance
    const observer = new MutationObserver((mutations) => {
      try {
        // Make sure style is set for surrounding elements
        this.setReportStyle()
        // Update no results text for cards
        const cards = doc.getElementsByClassName('DashCard')
        _.forEach(cards, (dashCard) => {
          this.modifyCard(dashCard)
        })
      } catch (err) {
        console.error(err)
      }
    })
    // Pass in the target node, as well as the observer options
    observer.observe(target, { childList: true, subtree: true })
    this.observer = observer
  }

  setReportStyle() {
    /*
     ** When iframe first loads, this function sets some key style / DOM elements to
     ** improve the look of the dashboard.
     */
    if (!this.iframe) return
    try {
      const doc = this.getIFrameDocEl()
      // Hide Metabase branding
      const footers = doc.getElementsByClassName('EmbedFrame-footer')
      _.each(footers, (footer) => (footer.style.display = 'none'))
      // Change background colour
      const dashes = doc.getElementsByClassName('Dashboard')
      _.each(dashes, (dash) => {
        dash.style.backgroundColor = 'transparent'
        dash.style.padding = 0
      })
      const frames = doc.getElementsByClassName('EmbedFrame')
      _.each(frames, (frame) => {
        frame.style.backgroundColor = 'transparent'
        frame.style.padding = 0
      })
      const headers = doc.getElementsByClassName('EmbedFrame-header')
      _.each(headers, (header) => {
        header.style.padding = 0
        header.firstChild.style.marginLeft = 0
      })
      if (!this.setBodyStyle) {
        // We only want to set the body styling once to avoid excessive style tags if
        // setReportsStyle is called multiple times, hence the use of the setBodyStyle flag.
        const { body } = doc
        // Scalar-title styling allows titles to run across multiple lines when screen width is small
        body.insertAdjacentHTML(
          'afterbegin',
          `<style>.Scalar-title { white-space: normal; } ${baseStyling} </style>`
        )
        this.setBodyStyle = true
      }
    } catch (err) {
      console.error(err)
    }
  }

  modifyCard(dashCard) {
    const overrideTxt = this.props.noResultsText
    // Find warning images
    const warningEl = dashCard.getElementsByClassName('Icon Icon-warning')[0]
    if (warningEl) {
      // Update text next to warning images
      const sibling = warningEl.nextSibling
      if (overrideTxt && sibling && sibling.innerHTML !== overrideTxt) {
        sibling.innerHTML = overrideTxt
      }
      // We want to use the warning image if it exists instead of the no results image.
      // So keep an instance of warning image around if there is one.
      if (!this.warningElCopy) this.warningElCopy = warningEl
    }
    // Make sure we have query selector support before continuing.
    if (!dashCard.querySelectorAll) return
    // Find no results image if it exists
    const noResultImgEl = dashCard.querySelectorAll(`[src="${NO_RESULTS_IMG_SRC}"]`)[0]
    if (noResultImgEl) {
      // Update text next to no results image
      const sibling = noResultImgEl.nextSibling
      if (overrideTxt && sibling && sibling.innerHTML !== overrideTxt) {
        sibling.innerHTML = overrideTxt
      }
      // If we previously found a warning image, use that for the sake of consistency.
      // That way all cards with no data look the same.
      if (this.warningElCopy && noResultImgEl) {
        noResultImgEl.replaceWith(this.warningElCopy.cloneNode(true))
      } else {
        // Otherwise use absolute path so image link doesn't break
        noResultImgEl.src = NO_RESULTS_ABSOLUTE_IMG_SRC
      }
    }
  }

  registerIFrameOnLoad() {
    if (!this.iframe) return
    this.iframe.onload = () => {
      try {
        iFrameResize.iframeResizer({}, this.iframe)
        this.setReportStyle()
        this.setState({ loading: false })
        this.setDynamicReportListener()
      } catch (err) {
        console.error(err)
      }
    }
  }

  goFullscreen = () => {
    const win = window.open(this.getDashboardURL(), '_blank')
    win.focus()
  }

  /**
   * Get the dashboard url while avoiding cross-origin errors in the iframe when a company
   * subdomain is used.
   *
   * Replaces `myagi.com` and `localhost` with `location.hostname`.
   * */

  getDashboardURL = () => {
    const hostnameRegexp = /\b(?:localhost|myagi\.com|staging\.myagi\.com)\b/g;
    return (this.props.dashboardURL || '').replace(hostnameRegexp, window.location.hostname)
  }

  render() {
    const dashboardURL = this.getDashboardURL()
    return (
      <div>
        {this.state.loading && <LoadingSpinner transparent />}
        {dashboardURL && (
          <iframe
            src={dashboardURL}
            title="dashboard"
            frameBorder="0"
            width="100%"
            scrolling="no"
            ref={(el) => (this.iframe = el)}
            style={styles.iframe}
          />
        )}
      </div>
    )
  }
}

export class MetabaseDashboardModal extends React.Component {
  show() {
    this.modal.show()
  }

  render() {
    return (
      <Modal ref={(el) => (this.modal = el)} size="fullscreen">
        <div style={styles.modalContent}>
          <MetabaseDashboardContainer {...this.props} />
        </div>
      </Modal>
    )
  }
}

export const MetabaseDashboardStaticModal = React.forwardRef(
  ({ urlParamName, ...props }, modalRef) => {
    const [isOpen, setIsOpen] = useUrlState(urlParamName, false)

    useImperativeHandle(modalRef, () => ({
      show: () => setIsOpen(true),
      hide: () => setIsOpen(false),
    }))

    return (
      <Modal
        key={urlParamName}
        showOnInit={!!(isOpen || isOpen === 'true')}
        onHidden={() => setIsOpen(false)}
        size="fullscreen"
      >
        <div style={styles.modalContent}>
          <MetabaseDashboardContainer {...props} />
        </div>
      </Modal>
    )
  }
)
