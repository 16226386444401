import { Box } from 'components/common/box'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import { Info } from 'components/common/info'
import { LoadingContainer } from 'components/common/loading'
import PropTypes from 'prop-types'
import React from 'react'
import CompaniesState from 'state/companies'
import CompanyConnectionsState from 'state/company-connections'
import createPaginatedStateContainer from 'state/pagination'
import containerUtils from 'utilities/containers'
import { resolve } from 'utilities/deprecated-named-routes'

const UNCLAIMED = 'unclaimed'
const CLAIMED = 'claimed'
const ACTIVE = 'active' // Has at least 1 module attempt in the past 30 days
const INACTIVE = 'inactive'
const CLEAR = 'clear'

class CompanyInfoContainer extends React.Component {
  static contextTypes = {
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  }

  onToggle = (val) => {
    const pathname = '/views/myagi-staff/sales-dashboard/'
    const query = { ...this.context.location.query, filter: val }
    if (val === CLEAR) {
      delete query.filter
    }
    this.context.router.push(
      resolve({
        pathname,
        query,
      })
    )
  }

  renderStats = () => {
    const { company, numResults, unclaimedCompaniesCount } = this.props
    const { filter } = this.context.location.query
    return (
      <Box>
        <h1>{`${company.name} - #${company.id}`}</h1>
        <p style={{ lineHeight: 2 }}>
          <span>
            <b>Type: </b>
            {`${company.company_type}`}
          </span>
          <br />
          <span>
            <b>Parent company: </b>
            {`${company.parent_company && company.parent_company.name}`}
          </span>
          <br />
          <span>
            <b>Region: </b>
            {`${company.region}`}
          </span>
          <br />
          <span>
            <b>Account Subscription Status: </b>
            {`${company.subscription.account_status}`}
          </span>
          <br />
          <span>
            <b>Tags: </b>
            {`${company.tags.map((t) => `${t.name}`)}`}
          </span>
          <br />
          <span>
            <b>No. of teams in Myagi: </b>
            {`${company.num_teams}`}
          </span>
          <br />
          <span>
            <b>No. of estimated teams: </b>
            {`${company.num_stores_est}`}
          </span>
          <br />
          <span>
            <b>No. of users: </b>
            {`${company.user_count}`}
          </span>
          <br />
        </p>
        <h1>
          Brands they carry
          {' '}
          {filter ? `(${numResults} ${filter})` : `(${numResults}) - `}
          {filter
            ? ''
            : `(Claimed: ${numResults -
                unclaimedCompaniesCount} / Unclaimed: ${unclaimedCompaniesCount} )`}
        </h1>
        <div style={{ marginBottom: 20 }}>
          <div style={{ display: 'inline' }}>Filters: </div>
          <button onClick={() => this.onToggle(CLAIMED)}>Claimed</button>
          <button onClick={() => this.onToggle(UNCLAIMED)}>Unclaimed</button>
          <button onClick={() => this.onToggle(CLEAR)}>Clear</button>
        </div>
      </Box>
    )
  }

  render() {
    const { company, connections, loadMore, moreAvailable, isLoading } = this.props
    return (
      <Box>
        {company && this.renderStats()}
        <LoadingContainer
          loadingProps={[company, connections]}
          createComponent={() => {
            const sortedConnections = connections.sort(
              (a, b) => b.supplier.num_unfulfilled_incoming_company_connection_requests -
                a.supplier.num_unfulfilled_incoming_company_connection_requests
            )
            return (
              <Box>
                <InfiniteScroll
                  loadMore={loadMore}
                  moreAvailable={moreAvailable}
                  isLoading={isLoading}
                >
                  <table className="ui celled padded table">
                    <thead>
                      <tr style={{ textAlign: 'left' }}>
                        <th>Company</th>
                        <th>
                          Myagi Acc Status
                          <Info content="Active: At least 1 lesson attempt in the past 30 days" />
                        </th>
                        <th>User Count</th>
                        <th>
                          Channel Connection
                          <Info
                            content={`Yes: At least 1 channel owned by the companies below has been shared with ${
                              company.name
                            }`}
                          />
                        </th>
                        <th>
                          Subscription Status
                          <Info content="This field is being pulled from Salesforce Account Subscription Status. It syncs everyday at 7am." />
                        </th>
                        <th>Unfulfilled requests</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedConnections.map((c) => {
                        const company = c.supplier
                        let myagiStatus = company.unclaimed ? UNCLAIMED : CLAIMED
                        if (myagiStatus === CLAIMED) {
                          myagiStatus = company.is_active ? ACTIVE : INACTIVE
                        }
                        return (
                          <tr key={c.id}>
                            <td>
                              {`${company.name} (${company.id}) - ${
                                company.region_of_responsibility
                              }`}
                            </td>
                            <td>{myagiStatus}</td>
                            <td>{company.user_count}</td>
                            <td>{c.companies_are_connected ? 'Yes' : 'No'}</td>
                            <td>{company.subscription.account_status}</td>
                            <td>{company.num_unfulfilled_incoming_company_connection_requests}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </Box>
            )
          }}
        />
      </Box>
    )
  }
}

function getConnectionsFetchFields(company) {
  return {
    dealer: Number(company),
    fields: [
      'companies_are_connected',
      'supplier.id',
      'supplier.name',
      'supplier.unclaimed',
      'supplier.subscription.account_status',
      'supplier.num_stores_est',
      'supplier.num_unfulfilled_incoming_company_connection_requests',
      'supplier.region_of_responsibility',
      'supplier.is_active',
      'supplier.user_count',
      'supplier.latest_activity',
    ],
  }
}

export const CompanyInfoPage = createPaginatedStateContainer(CompanyInfoContainer, {
  contextTypes: {
    location: PropTypes.object.isRequired,
  },

  listenTo: [CompaniesState.Store, CompanyConnectionsState.Store],

  paginate: {
    store: CompanyConnectionsState.Store,
    propName: 'connections',
    limit: 20,
    getQuery() {
      const company = this.props.data
      const { filter } = this.context.location.query
      const q = getConnectionsFetchFields(company)
      if (filter === CLAIMED) {
        q.supplier__unclaimed = false
      } else if (filter === UNCLAIMED) {
        q.supplier__unclaimed = true
      }
      return q
    },
  },

  fetch: {
    company() {
      const company = this.props.data
      return CompaniesState.Store.getItem(company, {
        fields: [
          '*',
          'subscription.account_status',
          'tags.name',
          'parent_company.id',
          'parent_company.name',
          'parent_company.subscription.account_status',
        ],
      })
    },
    unclaimedCompaniesCount() {
      const company = this.props.data
      const q = getConnectionsFetchFields(company)
      q.supplier__unclaimed = true
      q.fields = ['id']
      CompanyConnectionsState.Store.getItems(q)
      return CompanyConnectionsState.Store.getKnownCountForQuery(q)
    },
  },

  pending() {
    return containerUtils.defaultPending(this, CompanyInfoContainer)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, CompanyInfoContainer, errors)
  },
})
