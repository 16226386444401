import React, { lazy } from 'react'
import { lazyLoadComponent } from 'core/lazy-load-component'
import { IndexRedirect, Redirect, Route, browserHistory } from 'react-router'
import { accountRoutes, publicRoutes, signupRoutes } from 'components/accounts/routes'
import { contentRoutes, editContentRoutes } from 'components/content-management/routes'
import { discoverRoutes } from 'components/discover/routes'
import { App } from 'components/app'
import { Page as ChannelContentPage } from 'components/training/channel-content/page'
import { Page as ChannelsPage } from 'components/content-management/channels-tab/page'
import { Page as ContentUnavailablePage } from 'components/not-found/content-not-available.jsx'
import HomePage from 'components/home'

import { Page as NotFoundPage } from 'components/not-found/page.jsx'
import { NetworkRoutes } from 'components/network/routes'
import { App as PublicApp } from 'components/public-app'

import { Page as ShareLinkPage } from 'components/sharelink/page'
import SingleActivityPage from 'components/home/single-activity-item'
import { Page as TagCompanyPage } from 'components/tag-company/page'
import { enrollmentRoutes } from 'components/enrollments/routes'
import { peopleRoutes } from 'components/people/routes'
import { staffRoutes } from 'components/staff-menu/routes'
import { trainingRoutes } from 'components/training/routes'
import { commsRoutes } from 'components/comms/routes'
import RedirectToFrontend2 from '../../components/common/redirect-to-frontend2'
import Frontend2Page from '../../components/common/frontend2-page'

const ModuleAttemptPage = lazy(() =>
  import(
    /* webpackChunkName: "module-attempt" */ 'components/module-attempt/page'
  ).then((result) => ({ default: result.Page }))
)

const ModuleAttemptSummaryPage = lazy(() =>
  import(
    /* webpackChunkName: "module-attempt" */ 'components/module-attempt/attempt-summary/page'
  ).then((result) => ({ default: result.Page }))
)

let previousRoute = null
// Use segment to keep track of page changes
browserHistory.listen(() => {
  if (window.analytics) {
    if (!previousRoute || previousRoute != window.location.href) {
      analytics.page()
    }
    previousRoute = window.location.href
  }
})

export const routes = (
  <Route path="/">
    <Route name="sharelinks" path="s/" component={PublicApp}>
      <Route path="*" component={ShareLinkPage} />
    </Route>
    <Route path="signup/" name="signup" component={PublicApp}>
      {signupRoutes}
    </Route>
    <Route path="accounts/" name="accounts" component={PublicApp}>
      {accountRoutes}
    </Route>
    <Route path="public/" name="public" component={PublicApp}>
      {publicRoutes}
    </Route>

    <Route name="app" path="views/" component={App}>
      <IndexRedirect to="training/" />
      <Route name="network" path="network/">
        {NetworkRoutes}
      </Route>
      <Route name="training" path="training/">
        <IndexRedirect to="channels/" />
        {trainingRoutes}
      </Route>
      <Route path="edit-content/">{editContentRoutes}</Route>
      <Redirect from="channels/:channelId/*" to="/views/edit-content/channel/:channelId/" />
      <Route path="content/" name="content">
        {contentRoutes}
      </Route>
      <Route path="people/" name="people">
        {peopleRoutes}
      </Route>
      <Route path="analytics/" name="analytics" component={Frontend2Page} />
      <Route path="analytics/leaderboards/" name="leaderboards" component={Frontend2Page} />
      <Route path="myagi-staff/">{staffRoutes}</Route>
      <Route path="admin/" name="myagi-admin" />
      <Route path="enrollments/">{enrollmentRoutes}</Route>
      <Route path="discover/">{discoverRoutes}</Route>
      <Route path="tag-company/" name="tag-company" component={TagCompanyPage} />
      <Route path="profile/:userId/" name="profile" component={Frontend2Page} />
      <Route path="channels/" name="channels" component={ChannelsPage} />
      <Route
        path="channel-content/:channelId/"
        name="channel-content"
        component={ChannelContentPage}
      />
      <Route
        name="module-attempt"
        path="modules/:moduleId/attempts/:attemptId/"
        component={lazyLoadComponent(ModuleAttemptSummaryPage)}
      />
      <Route
        name="new-module-attempt"
        path="training_plans/:trainingPlanId/modules/:moduleId/attempts/new/"
        component={lazyLoadComponent(ModuleAttemptPage)}
        onLeave={ModuleAttemptPage.willTransitionFrom}
      />
      <Route name="settings" path="settings/" component={RedirectToFrontend2} />
      <Route name="home" path="home/" component={HomePage} />
      <Route name="homeWithoutSlash" path="home" component={HomePage} />
      <Route
        name="single-activity-item"
        path="home/feed/:activityId/"
        component={SingleActivityPage}
      />
      <Route
        name="content-unavailable"
        path="content-unavailable/"
        component={ContentUnavailablePage}
      />
      <Route path="badges/*" name="badges" component={RedirectToFrontend2} />
      <Route path="search*" name="search" component={RedirectToFrontend2} />
      <Route path="inbox">{commsRoutes}</Route>
      <Route path="*" component={NotFoundPage} />
    </Route>
  </Route>
)
