import Marty from 'marty'
import React from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import { resolve } from 'utilities/deprecated-named-routes'

import Style from 'style/index'

import { t } from 'i18n'

import { Box, BoxContent } from 'components/common/box'

const styles = {
  container: {
    textAlign: 'center',
    paddingTop: 60,
    paddingBottom: 60,
  },
  contentContainer: {
    marginTop: 25,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tickContainer: {
    width: '2em',
    height: '2em',
    borderRadius: '2em',
    fontSize: '5em',
    marginBottom: 20,
    backgroundColor: Style.vars.deprecatedColors.green,
    color: Style.vars.deprecatedColors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tick: {
    margin: '50px 0 0 0',
  },
}

export class Page extends React.Component {
  static contextTypes = {
    location: PropTypes.object.isRequired,
  }

  render() {
    const companyName = this.context.location.state && this.context.location.state.companyName
    return (
      <Box style={styles.container}>
        <BoxContent style={styles.contentContainer}>
          <div style={styles.tickContainer}>
            <i className="checkmark icon" style={styles.tick} />
          </div>
          <h1>{t('request_sent')}</h1>
          <h3>
            {t('approval_required_explanation', {
              companyName,
            })}
          </h3>
        </BoxContent>
      </Box>
    )
  }
}
