import { PrimaryButton } from 'components/common/buttons'
import { ButtonToggle } from 'components/common/form-deprecated'
import { LoadingSpinner } from 'components/common/loading'
import { Modal } from 'components/common/modals'
import { PlainTabs } from 'components/common/router-tabs'
import { remoteSearchMixinFactory } from 'components/common/search'
import { t } from 'i18n'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'

import ChannelsAccessCompaniesState from 'state/channel-access-company'
import ChannelAccessGroupState from 'state/channel-access-groups'
import ChannelAccessTeamState from 'state/channel-access-teams'
import ChannelAccessUserState from 'state/channel-access-users'
import ChannelAccessAreaState from 'state/channel-access-areas'
import ChannelSharesState from 'state/channel-shares'
import EnrollmentGroupsState from 'state/enrollment-groups'
import TrainingPlanTrainingUnitsState from 'state/training-plan-training-units'
import TeamsState from 'state/teams'
import UsersState from 'state/users'
import AreasState from 'state/areas'

import Style from 'style/index.js'
import { momentToISO } from 'utilities/time'
import { ConfirmChangeModal } from './confirm-change-modal'
import { CUSTOM, EVERYONE, GROUP_TAB, TEAM_TAB, USER_TAB, AREA_TAB } from './constants'
import { GroupSelector } from './selectors/group'
import { TeamSelector } from './selectors/team'
import { UserSelector } from './selectors/user'
import { AreaSelector } from './selectors/area'
import PageState from './state'

const styles = {
  selectorContainer: {
    margin: '10px 0px 30px',
  },
  error: {
    marginLeft: 10,
    color: Style.vars.deprecatedColors.red,
  },
  topRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

const TAB_DETAILS = {
  [USER_TAB]: {
    GenericChannelAccessState: ChannelAccessUserState,
    fieldName: 'user',
    routeName: 'users',
    Selector: UserSelector,
  },
  [TEAM_TAB]: {
    GenericChannelAccessState: ChannelAccessTeamState,
    fieldName: 'team',
    routeName: 'learner_groups',
    Selector: TeamSelector,
  },
  [GROUP_TAB]: {
    GenericChannelAccessState: ChannelAccessGroupState,
    fieldName: 'group',
    routeName: 'enrollment_groups',
    Selector: GroupSelector,
  },
  [AREA_TAB]: {
    GenericChannelAccessState: ChannelAccessAreaState,
    fieldName: 'area',
    routeName: 'areas',
    Selector: AreaSelector,
  },
}

@reactMixin.decorate(remoteSearchMixinFactory(PageState.ActionCreators.setSearch))
export class EnrollModal extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    featureFlags: PropTypes.objectOf(PropTypes.bool),
  }

  static emptySelectionState = {
    newSelected: {},
    newDeselected: {},
  }

  constructor(props) {
    super(props)
    const companyAccessInitial = this.props.companyAccess ? EVERYONE : CUSTOM
    this.state = {
      selectionChanges: EnrollModal.emptySelectionState,
      saving: false,
      deselectAttempt: false,
      currentTab: USER_TAB,
      companyAccessInitial,
      companyAccessValue: companyAccessInitial,
    }
  }

  show = () => {
    this.modal.show()
  }

  isSelected = (obj) => {
    const access = obj.access_to_requested_channel
    return access[this.state.currentTab].length > 0
  }

  save = () => {
    if (this.state.saving) return
    const companyAccessChanged = this.state.companyAccessInitial != this.state.companyAccessValue

    this.setState({ ...this.state, saving: true })

    if (companyAccessChanged && this.state.companyAccessValue == EVERYONE) {
      const promise = this.saveCompanyAccess()
      this.postSave([promise])
    } else if (companyAccessChanged) {
      this.saveCompanyAccess().then((r) => {
        const promises = this.saveCustomAccess()
        this.postSave(promises)
      })
    } else {
      const promises = this.saveCustomAccess()
      this.postSave(promises)
    }
  }

  saveCompanyAccess = () => {
    const { company } = this.context.currentUser
    let promise = null
    if (this.state.companyAccessValue == EVERYONE) {
      promise = ChannelsAccessCompaniesState.ActionCreators.doListAction('create_access', {
        channel: this.props.channel.id,
        to_create: [company.id],
      })
    } else {
      promise = ChannelsAccessCompaniesState.ActionCreators.update(this.props.companyAccess.id, {
        deactivated: momentToISO(moment()),
      })
    }

    return promise
  }

  saveCustomAccess = () => {
    const { GenericChannelAccessState, fieldName, routeName } = TAB_DETAILS[this.state.currentTab]
    const { newSelected, newDeselected } = this.state.selectionChanges

    const now = momentToISO(moment())

    // Create created multiple requests for each item to deactivate
    const deactivatePromises = Object.keys(newDeselected).map((id) => {
      const access = newDeselected[id].access_to_requested_channel
      // we can assume the element we want will always be [0]
      // each user/team/group enrollment must be unique (it is an array, because
      // a user can have multiple group/team enrollments)
      const accessObjId = access[this.state.currentTab][0].id
      return GenericChannelAccessState.ActionCreators.update(accessObjId, {
        deactivated: now,
      })
    })

    const toCreateIds = Object.keys(newSelected)

    // create one request to create all access objects
    const createPromise =
      toCreateIds.length === 0
        ? true
        : GenericChannelAccessState.ActionCreators.doListAction('create_access', {
            channel: this.props.channel.id,
            to_create: Object.keys(newSelected),
          })

    return [createPromise, ...deactivatePromises]
  }

  postSave = (promises) => {
    Promise.all(promises)
      .then((r) => {
        this.modal.hide()
        this.setState({
          ...this.state,
          companyAccessInitial: this.state.companyAccessValue,
        })
      })
      .finally((r, w, e) => {
        this.setState({
          ...this.state,
          saving: false,
          selectionChanges: EnrollModal.emptySelectionState,
        })

        ChannelsAccessCompaniesState.ActionCreators.resetLocalData()
        ChannelAccessUserState.ActionCreators.resetLocalData()
        ChannelAccessTeamState.ActionCreators.resetLocalData()
        ChannelAccessGroupState.ActionCreators.resetLocalData()
        ChannelAccessAreaState.ActionCreators.resetLocalData()
        ChannelSharesState.ActionCreators.resetLocalData()
        UsersState.ActionCreators.resetLocalData()
        EnrollmentGroupsState.ActionCreators.resetLocalData()
        TeamsState.ActionCreators.resetLocalData()
        TrainingPlanTrainingUnitsState.ActionCreators.resetLocalData()
        AreasState.ActionCreators.resetLocalData()
      })
  }

  toggleCompanyAccess = (companyAccessValue) => {
    this.setState({
      ...this.state,
      companyAccessValue,
      selectionChanges: EnrollModal.emptySelectionState,
    })
  }

  hasChanged = () => {
    const companyAccessChanged = this.state.companyAccessInitial != this.state.companyAccessValue
    const customSelectionAdd = Object.keys(this.state.selectionChanges.newSelected).length
    const customSelectionRemove = Object.keys(this.state.selectionChanges.newDeselected).length
    return companyAccessChanged || customSelectionAdd || customSelectionRemove
  }

  onSelectionChange = (newSelection, changedObj) => {
    this.setState({
      ...this.state,
      selectionChanges: newSelection,
      deselectAttempt: false,
    })
  }

  changeTab = (tab) => {
    if (this.state.currentTab !== tab) {
      this.setState({
        ...this.state,
        currentTab: tab,
        selectionChanges: EnrollModal.emptySelectionState,
      })
    }
  }

  renderSelector() {
    const { Selector } = TAB_DETAILS[this.state.currentTab]
    return (
      <div style={styles.selectorContainer}>
        <Selector
          ref={(c) => (this.currentSelector = c)}
          channel={this.props.channel}
          isSelected={this.isSelected}
          onChange={this.onSelectionChange}
          value={this.state.selectionChanges}
        />
      </div>
    )
  }

  renderModals = () => (
    <React.Fragment>
      <ConfirmChangeModal
        ref={(c) => (this.confirmSaveModal = c)}
        selectionChanges={this.state.selectionChanges}
        tab={this.state.currentTab}
        onConfirm={this.save}
        companyAccessValueChanged={this.state.companyAccessValue != this.state.companyAccessInitial}
        companyAccessValue={this.state.companyAccessValue}
      />
    </React.Fragment>
  )

  render() {
    const learner = this.context.currentUser.learner
    return (
      <Modal header={t('enrollments')} ref={(c) => (this.modal = c)}>
        {learner.can_manage_training_content && (
          <div style={{ marginBottom: 20 }}>
            <ButtonToggle
              name="public_access"
              leftLabel={EVERYONE}
              rightLabel={CUSTOM}
              initialValue={this.state.companyAccessValue}
              onChange={this.toggleCompanyAccess}
            />
          </div>
        )}

        {this.state.companyAccessValue == CUSTOM && (
          <React.Fragment>
            <div style={styles.topRowContainer}>
              <PlainTabs
                tabs={
                  learner.can_manage_training_content
                    ? [
                        USER_TAB,
                        TEAM_TAB,
                        GROUP_TAB,
                        ...(this.context.featureFlags.area_enrollments ? [AREA_TAB] : []),
                      ]
                    : [USER_TAB, TEAM_TAB]
                }
                current={this.state.currentTab}
                onClick={this.changeTab}
                style={{ marginBottom: 0 }}
              />
              <div>{this.getSearchInput({ borderless: true })}</div>
            </div>
            {this.renderSelector()}
          </React.Fragment>
        )}

        {this.state.saving && <LoadingSpinner />}

        {!learner.can_manage_training_content && this.state.companyAccessValue == EVERYONE ? (
          t('everyone_in_your_team_is_enrolled')
        ) : (
          <PrimaryButton
            onClick={() => this.confirmSaveModal.show()}
            disabled={this.state.saving || !this.hasChanged()}
          >
            {t('save')}
          </PrimaryButton>
        )}

        {this.renderModals()}
      </Modal>
    )
  }
}
