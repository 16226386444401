import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { QueryDataType } from 'shared-js/state/graphql/update-cache'
import { t } from 'shared-js/i18n'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import { LoadingSpinner } from 'shared-js/components/common/loading'
import { ErrorMessage } from 'components/common/error-message'
import ManageSubscriptions, { UpdateCacheProps } from './mutations-container'
import { SaveChangesResult, DEACTIVATED } from './success-message'
import { LOADING_SIZE } from './company-list'
import {
  ConnectedCompaniesQuery,
  ConnectedCompaniesQuery_PublicCompany__List_edges as PublicCompanyEdge,
} from './gql-types/ConnectedCompaniesQuery'
import { GetChannelInfoQuery_TrainingUnit__Item as Channel } from '../gql-types/GetChannelInfoQuery'
import NoConnectionsMessage from './no-connections-message'

const EMPTY_SEARCH_INPUT = ''

export type ConnectedCompaniesApolloProps = {
  loading: boolean
  data: ConnectedCompaniesQuery | undefined
  error?: any
  [key: string]: any
}

type ContainerProps = {
  goBack(): void
  channel: Channel
  setSaveChangesResults(result: SaveChangesResult[]): void
  updateSubscribedCompaniesCount(props: UpdateCacheProps): void
}

type InnerProps = {
  apolloProps: ConnectedCompaniesApolloProps
  channel: Channel
  setSaveChangesResults(result: SaveChangesResult[]): void
  updateSubscribedCompaniesCount(props: UpdateCacheProps): void
  updateConnectedCompany(props: UpdateCacheProps): void
  setSearchTerm(val: string): void
  searchTerm: string
}

const CONNECTED_COMPANIES = gql`
  query ConnectedCompaniesQuery(
    $cursor: String
    $loadingSize: Int!
    $companyID: ID!
    $searchTerm: String
    $channelID: String
    $orderBy: [String]!
  ) {
    PublicCompany__List(
      after: $cursor
      first: $loadingSize
      search: $searchTerm
      isConnectedWith: $companyID
      deactivated_Isnull: true
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          isSubscribedToChannel(channelId: $channelID)
        }
      }
      totalCount
    }
  }
`

const EditSubscribedCompanies = ({
  goBack,
  channel,
  setSaveChangesResults,
  updateSubscribedCompaniesCount,
}: ContainerProps) => {
  const currentUser = useCurrentUser()
  const [searchTerm, setSearchTerm] = useState(EMPTY_SEARCH_INPUT)

  const queryVariables = {
    loadingSize: LOADING_SIZE,
    searchTerm,
    companyID: currentUser.company.id,
    orderBy: searchTerm === EMPTY_SEARCH_INPUT ? ['name'] : ['-search_rank'],
    channelID: channel.id,
  }

  const apolloProps = useQuery(CONNECTED_COMPANIES, {
    variables: queryVariables,
  })

  const updateConnectedCompany = ({ cache, mutationResult, mutation }: UpdateCacheProps) => {
    const queryData: QueryDataType = cache.readQuery({
      query: CONNECTED_COMPANIES,
      variables: queryVariables,
    })

    const deactivatedSubscription = mutation === DEACTIVATED

    const isMutatedCompany = (company: PublicCompanyEdge) => {
      if (deactivatedSubscription) {
        return (
          company.node?.id ===
          mutationResult?.data?.SharedTrainingUnit__Deactivate.result.company.id
        )
      }
      return company.node?.id === mutationResult?.data?.SharedTrainingUnit__Create.result.company.id
    }

    if (queryData) {
      const isNowSubscribed = !deactivatedSubscription
      cache.writeQuery({
        query: CONNECTED_COMPANIES,
        variables: queryVariables,
        data: {
          PublicCompany__List: {
            ...queryData.PublicCompany__List,
            edges: queryData.PublicCompany__List.edges.map((company: PublicCompanyEdge) => {
              if (isMutatedCompany(company)) {
                return {
                  ...company,
                  node: {
                    ...company.node,
                    isSubscribedToChannel: isNowSubscribed,
                  },
                }
              }
              return company
            }),
          },
        },
      })
    }
  }

  return (
    <>
      <BackLink onClick={goBack}>
        <BackIcon />
        {t('sharing_options_titlecase')}
      </BackLink>
      <EditSubscribedCompaniesInner
        apolloProps={apolloProps}
        channel={channel}
        setSaveChangesResults={setSaveChangesResults}
        updateSubscribedCompaniesCount={updateSubscribedCompaniesCount}
        updateConnectedCompany={updateConnectedCompany}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
    </>
  )
}

const EditSubscribedCompaniesInner = ({
  apolloProps,
  channel,
  setSaveChangesResults,
  updateSubscribedCompaniesCount,
  setSearchTerm,
  updateConnectedCompany,
  searchTerm,
}: InnerProps) => {
  if (apolloProps.error) return <ErrorMessage location="share channel modal" margin="auto" />

  if (apolloProps.data?.PublicCompany__List) {
    if (!apolloProps.data.PublicCompany__List?.totalCount && searchTerm === EMPTY_SEARCH_INPUT) {
      return <NoConnectionsMessage />
    }

    return (
      <ManageSubscriptions
        channel={channel}
        setSaveChangesResults={setSaveChangesResults}
        updateSubscribedCompaniesCount={updateSubscribedCompaniesCount}
        updateConnectedCompany={updateConnectedCompany}
        // @ts-ignore - we've check that apolloProps.data is not undefined above
        apolloProps={apolloProps}
        setSearchTerm={setSearchTerm}
      />
    )
  }

  return (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  )
}

const BackLink = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: max-content;
`

const BackIcon = styled(Icon).attrs({ name: 'keyboard-arrow-left', size: 18 })`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 4px;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export default EditSubscribedCompanies
