import React from 'react'
import PropTypes from 'prop-types'
import { Modal as SemanticModal } from 'semantic-ui-react'
import { ModalHeader, ModalContent } from './utils'

/*
  How to use with Router:
  - Add component to router eg <Route path="my-modal/" component={ModalComponent}>
  - Ensure the parent component is rendering its sub routes e.g {this.props.children}

  How to use without Router:
  - Have a conditional wrapped around entire component ie:
      {showModal &&
        <StaticModal onClose={this.onClose}> modal content </StaticModal>
      }
*/

export class StaticModal extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  close = () => {
    // pop the top route off the router
    // need to convert route name ids ie `/channel/:channelId/` => `/channel/[0-9]*/
    const replaceRegex = this.props.route.path.replace(/:\w*/, '[0-9]*')
    const backPath =
      this.props.backPath || this.props.location.pathname.replace(new RegExp(replaceRegex), '')
    this.context.router.push(backPath)
  }

  render() {
    return (
      <SemanticModal open onClose={this.props.onClose || this.close}>
        <ModalHeader {...this.props} />
        <ModalContent {...this.props} />
      </SemanticModal>
    )
  }
}
