import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import {
  ConnectedBrandsQuery as Query,
  ConnectedBrandsQueryVariables as Variables,
  ConnectedBrandsQuery,
} from './gql-types/ConnectedBrandsQuery'

import { BRAND_FRAGMENT } from '../common'

export const LOADING_SIZE = 50
export const EMPTY_SEARCH_INPUT = ''

type ApolloPropsType = {
  loading: boolean
  data: ConnectedBrandsQuery | undefined
  error?: any
  [key: string]: any
}

type BrandsContextValues = {
  apolloProps: ApolloPropsType
  searchTerm: string
  setSearchTerm(term: string): void
}

const CONNECTED_BRANDS = gql`
  query ConnectedBrandsQuery(
    $cursor: String
    $loadingSize: Int!
    $companyID: ID!
    $searchTerm: String
    $orderBy: [String]
  ) {
    PublicCompany__List(
      after: $cursor
      first: $loadingSize
      isConnectedWith: $companyID
      search: $searchTerm
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ConnectedBrands_brand
        }
      }
      totalCount
    }
  }
  ${BRAND_FRAGMENT}
`

const ConnectedBrandsContext = React.createContext<BrandsContextValues>({
  apolloProps: { loading: true, data: undefined },
  searchTerm: '',
  setSearchTerm: () => {},
})

export const ConnectedBrandsProvider = ({ children }: { children: React.ReactElement }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const currentUser = useCurrentUser()

  const queryVariables = {
    loadingSize: LOADING_SIZE,
    searchTerm,
    companyID: currentUser.company.id,
    // "-id" is only required here to add specification for the pagination cursor"
    orderBy:
      searchTerm === EMPTY_SEARCH_INPUT
        ? ['-discoverable_channels_count', '-id']
        : ['-search_rank'],
  }

  const apolloProps = useQuery<Query, Variables>(CONNECTED_BRANDS, {
    variables: queryVariables,
  })

  return (
    <ConnectedBrandsContext.Provider
      value={{
        apolloProps,
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </ConnectedBrandsContext.Provider>
  )
}

export const useConnectedBrands = () => useContext(ConnectedBrandsContext)
