import React from 'react'
import { qs } from 'shared-js/utilities/http.js'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'

const CONNECTION_INVITATION_QUERY = gql`
  query ConnectionInvitationQuery($invitationID: String) {
    PublicCompany__List(first: 1, orderBy: ["id"], connectionInvitationId: $invitationID) {
      edges {
        node {
          id
          name
          companyLogo
          productSegmentType
        }
      }
    }
  }
`

export const ConnectionInvitationContext = React.createContext(null)

export const ConnectionInvitationProvider = ({ children }: { children: React.ReactNode }) => {
  const [fetchInviter, { called, loading, data }] = useLazyQuery(CONNECTION_INVITATION_QUERY)
  const hasReturnedAMatchingCompany = Boolean(
    data &&
      data.PublicCompany__List &&
      data.PublicCompany__List.edges &&
      data.PublicCompany__List.edges[0]
  )
  const hasSuccessfullyFetchedInviter = called && hasReturnedAMatchingCompany

  let value = null

  if (qs('c') && !hasSuccessfullyFetchedInviter && !loading) {
    fetchInviter({ variables: { invitationID: qs('c') } })
  }

  if (hasSuccessfullyFetchedInviter) value = data.PublicCompany__List.edges[0].node

  return (
    <ConnectionInvitationContext.Provider value={value}>
      {children}
    </ConnectionInvitationContext.Provider>
  )
}
