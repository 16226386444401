import React, { useMemo } from 'react'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import { RETAILER, BRAND, DISTRIBUTOR } from 'core/constants'
import { GetCompanyTypes_CompanyType__List_edges as CompanyTypeNode } from '../gql-types/GetCompanyTypes'
import CompanyInfoForm from './form'

type CompanyTypeNodes = {
  brand: CompanyTypeNode | null | undefined
  distributor: CompanyTypeNode | null | undefined
  retailer: CompanyTypeNode | null | undefined
}

type CompanyType = {
  value: string
  label: string
}

type CompanyInfoStep = {
  companyTypeNodes: CompanyTypeNodes
  industryIDs: string[]
  companyType: CompanyType[]
  isABrand: boolean
  onCompletedForm(): void
  resetForm(): void
}

const CompanyInfoStep = ({
  companyTypeNodes,
  industryIDs,
  companyType,
  ...otherProps
}: CompanyInfoStep) => {
  const companyTypeIDs = useMemo(() => {
    const IDs: number[] = []

    if (companyType) {
      if (companyType.some((item) => item.value === BRAND) && companyTypeNodes.brand?.node?.id) {
        // a dirty solution while our ManyToManyIDField is still an Integer
        IDs.push(parseInt(companyTypeNodes.brand.node.id))
      }

      if (
        companyType.some((item) => item.value === RETAILER) &&
        companyTypeNodes.retailer?.node?.id
      ) {
        IDs.push(parseInt(companyTypeNodes.retailer.node.id))
      }

      if (
        companyType.some((item) => item.value === DISTRIBUTOR) &&
        companyTypeNodes.distributor?.node?.id
      ) {
        IDs.push(parseInt(companyTypeNodes.distributor.node.id))
      }
    }

    return IDs
  }, [companyType, companyTypeNodes])

  return (
    <Container>
      <Title>{t('were_almost_done')}</Title>
      <Info>{t('the_information_you_provide_will_make_for_a_better_experience')}</Info>
      <CompanyInfoForm companyTypeIDs={companyTypeIDs} industryIDs={industryIDs} {...otherProps} />
    </Container>
  )
}

const Container = styled.div`
  width: 92vw;
  max-width: 450px;
  padding: 20px 24px 30px 24px;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey900};
  margin-bottom: 8px;
`

const Info = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  margin-bottom: 16px;
`

export default CompanyInfoStep
