import PropTypes from 'prop-types'
import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import { StyleRoot } from 'radium'

const INVALID_SPAN_PROPS = ['_radiumStyleKeeper']

const ContentContainer = createReactClass({
  displayName: 'ContentContainer',

  propsTypes: {
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    _radiumStyleKeeper: PropTypes.object.isRequired,
  },

  childContextTypes: {
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    _radiumStyleKeeper: PropTypes.object.isRequired,
  },

  getChildContext() {
    return {
      location: this.props.location,
      router: this.props.router,
      _radiumStyleKeeper: this.props._radiumStyleKeeper,
    }
  },

  render() {
    const spanProps = _.omit(this.props, INVALID_SPAN_PROPS)
    return (
      <StyleRoot>
        <span {...spanProps}>{this.props.children}</span>
      </StyleRoot>
    )
  },
})

export const JQueryComponentMixin = {
  /*
    Contains boilerplate for using JQuery and React
    together in same component. Prevents React from
    complaining when JQuery code manipulates the DOM,
    but also makes it easy to make the component
    reactive.
  */

  contextTypes: {
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    _radiumStyleKeeper: PropTypes.object.isRequired,
  },

  setup() {
    if (!this.containerEl) return

    // `renderJQueryControllerContent` func should be implemented by subclass.
    ReactDOM.render(this.renderContent(), this.containerEl)

    // Manipulate DOM with JQuery causes an infinite loop when testing with JSDom.
    if (process.env.NODE_ENV === 'test') return
    this.manipulateDOMWithJQuery()
  },

  renderContent() {
    return (
      <ContentContainer
        location={this.context.location}
        router={this.context.router}
        _radiumStyleKeeper={this.context._radiumStyleKeeper}
      >
        {this.renderJQueryControlledContent()}
      </ContentContainer>
    )
  },

  destroy() {
    // Original destroy causes errors in React16 - Seems to work fine without
    // const $component = this.getContainerEl();
    // ReactDOM.unmountComponentAtNode($component[0]);
    // $component.off();
    // $component.empty();
  },

  refresh() {
    // This can be called in componentDidUpdate to
    // make inheriting component reactive.
    this.destroy()
    this.setup()
  },

  componentDidMount() {
    this.setup()
    // Fixes JQuery components in React16
    _.defer(() => this.setup())
  },

  componentWillUnmount() {
    try {
      this.destroy()
    } catch (err) {
      // Do nothing, assume React has already destroyed this component.
    }
  },

  render() {
    return (
      <span>
        <span ref={(el) => (this.containerEl = el)} />
        {/* This method can be optionally added */}
        {this.renderPostJQueryControlledContent && this.renderPostJQueryControlledContent()}
      </span>
    )
  },
}
