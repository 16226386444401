import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import NetworkPage from './page'
import Frontend2Page from '../common/frontend2-page'

export const NetworkRoutes = (
  <Route component={NetworkPage}>
    <IndexRedirect to="manage/" />
    <Route name="find-connections" path="find/" component={Frontend2Page} />
    <Route name="your-network" path="manage/" component={Frontend2Page} />
    <Route name="network-groups" path="network-groups/" component={Frontend2Page} />
    <Route name="shared-network-groups" path="shared-groups/" component={Frontend2Page} />
    <Route name="invitations" path="invitations/" component={Frontend2Page} />
  </Route>
)
