import React from 'react'
import Radium from 'radium'
import Style from 'style/index.js'

import { InfiniteScroll } from 'components/common/infinite-scroll'

/*
  Used to select/deselect objects in a lazy loaded list
*/

const styles = {
  containerOuter: {
    borderLeft: '5px solid #ccc',
    paddingLeft: 15,
  },
  container: {
    height: 400,
    overflowY: 'scroll',
    marginLeft: -10,
  },
  objectRow: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    marginLeft: 10,
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    ':hover': {},
  },
  objectRowHover: {
    opacity: 0.7,
  },
  circle: {
    height: 30,
    width: 30,
    marginRight: 10,
    borderWidth: 1,
    borderRadius: 100,
    borderStyle: 'solid',
    transition: 'all 0.2s ease',
  },
  cirectSelected: {
    backgroundColor: Style.vars.deprecatedColors.green,
    borderColor: 'rgba(0,0,0,0)',
  },
  circleDeselected: {
    backgroundColor: 'rgba(0,0,0,0)',
    borderColor: '#aaa',
  },
  circleDisabled: {
    opacity: 0.5,
  },
  circleHover: {
    backgroundColor: '#21ba4585',
    borderColor: 'rgba(0,0,0,0)',
    transform: 'scale(1.2)',
  },
  changedIndicatorContainer: {
    width: 0,
  },
  changedIndicator: {
    width: 10,
    height: 10,
    backgroundColor: '#0000',
    borderRadius: 5,
    marginLeft: -15,
    transition: 'all 0.8s ease',
  },
  changedIndicatorActive: {
    backgroundColor: '#0005',
  },
  tick: {
    marginLeft: 5,
    marginTop: 4,
  },
  tickSelected: {
    color: '#fff',
  },
  tickDeselected: {
    color: '#aaa',
  },
  tickHover: {},
}

@Radium
export class SelectObjects extends React.Component {
  show = () => {
    this.refs.modal.show()
  }

  onChange = (newVal, o) => {
    if (!newVal.newSelected) {
      newVal.newSelected = {}
    }
    if (!newVal.newDeselected) {
      newVal.newDeselected = {}
    }
    this.props.onChange(newVal, o)
  }

  select = (o) => {
    if (this.props.value.newDeselected[o.id]) {
      const newDeselected = { ...this.props.value.newDeselected }
      delete newDeselected[o.id]
      this.onChange(
        {
          ...this.props.value,
          newDeselected,
        },
        o
      )
    } else {
      this.onChange(
        {
          ...this.props.value,
          newSelected: {
            ...this.props.value.newSelected,
            [o.id]: o,
          },
        },
        o
      )
    }
  }

  deselect = (o) => {
    if (this.props.value.newSelected[o.id]) {
      const newSelected = { ...this.props.value.newSelected }
      delete newSelected[o.id]
      this.onChange(
        {
          ...this.props.value,
          newSelected,
        },
        o
      )
    } else {
      this.onChange(
        {
          ...this.props.value,
          newDeselected: {
            ...this.props.value.newDeselected,
            [o.id]: o,
          },
        },
        o
      )
    }
  }

  isSelected = (o) => {
    if (this.props.value.newSelected[o.id]) {
      return true
    }
    if (this.props.value.newDeselected[o.id]) {
      return false
    }
    return this.props.isSelected(o)
  }

  isChanged = (o) => this.props.value.newSelected[o.id] || this.props.value.newDeselected[o.id]

  render() {
    return (
      <div style={styles.containerOuter}>
        <InfiniteScroll
          style={styles.container}
          loadMore={this.props.loadMore}
          moreDataAvailable={this.props.moreDataAvailable}
          dataIsLoading={this.props.dataIsLoading}
          scrollableContainer
        >
          {this.props.objects &&
            this.props.objects.map((o) => {
              const selected = this.isSelected(o)
              const changed = this.isChanged(o)
              const disabled = this.props.isDisabled && this.props.isDisabled(o)

              const hover = !disabled && Radium.getState(this.state, o.id, ':hover')

              return (
                <div
                  key={o.id}
                  style={{
                    ...styles.objectRow,
                    ...(hover ? styles.objectRowHover : {}),
                  }}
                  onClick={selected ? () => this.deselect(o) : () => this.select(o)}
                >
                  <div style={styles.changedIndicatorContainer}>
                    <div
                      style={{
                        ...styles.changedIndicator,
                        ...(changed ? styles.changedIndicatorActive : {}),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      ...styles.circle,
                      ...(selected ? styles.cirectSelected : styles.circleDeselected),
                      ...(disabled ? styles.circleDisabled : {}),
                      ...(hover ? styles.circleHover : {}),
                    }}
                  >
                    <i
                      style={{
                        ...styles.tick,
                        ...(selected ? styles.tickSelected : styles.tickDeselected),
                        ...(hover ? styles.tickHover : {}),
                      }}
                      className="ui icon checkmark"
                    />
                  </div>
                  {this.props.renderObject(o)}
                </div>
              )
            })}
        </InfiniteScroll>
      </div>
    )
  }
}
