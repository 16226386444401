import React from 'react'
import { Route, Redirect, IndexRedirect, IndexRoute, browserHistory } from 'react-router'

import { Page as StaffPage } from './page'
import { Page as UserDetails } from './user-management/user-details'
import { Page as SalesDashboardPage } from './sales-dashboard/page'
import { Page as UserManagementPage } from './user-management/page'
import { Page as ChannelManagementPage } from './channel-management/page'
import { Page as StandardsReviewPage } from './company-review/page'
import { Page as NetworkReportPage } from './network-reports/page'
import CreateMicrodeckPage from './create-microdeck'
import { Page as CompanySelect } from './company-management'
import { Page as CompanyDetails } from './company-management/details'
import { Page as CompanyDetailsAdmin } from './company-management/details/admin'
import { Page as CompanyDetailsScraper } from './company-management/details/scraper'
import { Page as CompanyDetailsSettings } from './company-management/details/settings'
import { Page as CompanyDetailsRequests } from './company-management/details/requests'

export const staffRoutes = (
  <Route>
    <IndexRedirect to="menu/" />
    <Route name="myagi-staff" path="menu/" component={StaffPage} />
    <Route name="channel-management" path="channel-management/" component={ChannelManagementPage} />
    <Route path="company/" name="company-select" component={CompanySelect}>
      <Route path=":companyId/" component={CompanyDetails}>
        <IndexRedirect to="admin/" />
        <Route path="admin/" component={CompanyDetailsAdmin} />
        <Route path="settings/" component={CompanyDetailsSettings} />
        <Route path="scraper/" component={CompanyDetailsScraper} />
        <Route path="requests/" component={CompanyDetailsRequests} />
      </Route>
    </Route>
    <Route name="sales-dashboard" path="sales-dashboard/" component={SalesDashboardPage} />
    <Route name="user-management" path="user-management/" component={UserManagementPage}>
      <Route path=":userId/" component={UserDetails} />
    </Route>
    <Route name="generate-network-page" path="generate-network-page/" component={NetworkReportPage}>
      <Route path=":companyId/" component={NetworkReportPage} />
    </Route>
    <Route name="company-review" path="company-review/" component={StandardsReviewPage} />
    <Route name="create-microdeck" path="create-microdeck/" component={CreateMicrodeckPage} />
  </Route>
)
