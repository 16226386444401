import { CompanyHierarchyTree, getCompanyDepth } from 'components/common/parent-child-tree'

import $y from 'shared-js/utilities/yaler'
import CompaniesState from 'state/companies'
import { CompanySearchableSelect } from 'components/common/company-searchable-select.jsx'
import { Icon } from 'semantic-ui-react'
import Marty from 'marty'
import { Modal } from 'components/common/modals/index'
import PropTypes from 'prop-types'
import React from 'react'
import { SlideToggle } from 'components/common/form-deprecated'
import SubscriptionsState from 'state/subscriptions'
import containerUtils from 'utilities/containers'
import { getIdFromApiUrl } from 'shared-js/utilities/generic'

const styles = {
  removeParentIcon: {
    cursor: 'pointer',
  },
  slideToggle: {
    verticalAlign: 'bottom',
    marginLeft: 10,
  },
  parentChildToggle: {
    marginTop: 10,
  },
  stat: {
    fontWeight: 'bold',
  },
  hierarchyContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  errorText: {
    color: 'red',
  },
}

export class SelectParentCompanyInner extends React.Component {
  static data = {
    company: {
      required: true,
      fields: [
        'id',
        'parent_company.id',
        'parent_company.url',
        'parent_company.name',
        'parent_company.subscription.id',
        'parent_company.subscription.parent_child_companies_enabled',
        'child_companies',
        'subscription.id',
        'subscription.parent_child_companies_enabled',
        'name',
      ],
    },
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super()
    const subscription = props.company.subscription
    this.state = {
      parentCompany: null,
      errorMessage: null,
    }
  }

  onParentCompanySelect = (val) => {
    if (val) {
      this.setState({
        errorMessage: null,
      })
      const selfParentSelected = getIdFromApiUrl(val) == this.props.company.id
      if (selfParentSelected) {
        this.setState({
          errorMessage: 'A company cannot be a parent of itsef. Bad things will happen.',
        })
      }
      this.setState({ parentCompany: val })
    }
  }

  showChangeParentModal = () => {
    this.changeParentCompanyModal.show()
  }

  removeParentCompany = () => {
    this.saveParentCompanySelection(true)
  }

  saveParentCompanySelection = (clearParentCompany = false) => {
    let parentCompany = this.state.parentCompany
    if (clearParentCompany === true) {
      parentCompany = null
    }
    CompaniesState.ActionCreators.doDetailAction(this.props.company.id, 'set_parent_company', {
      parent_company: parentCompany,
    })
      .then((res) => {
        this.changeParentCompanyModal.hide()
        this.context.displayTempPositiveMessage({
          heading: 'Parent company updated',
        })
      })
      .catch((err) => {
        this.setState({
          errorMessage: `Update failed. Does ${
            this.props.company.name
          } also distribute this company? Parent company cannot also be in set of distributors.`,
        })
      })
  }

  onParentChildCompaniesEnabledChange = (company) => {
    SubscriptionsState.ActionCreators.update(company.subscription.id, {
      parent_child_companies_enabled: !company.subscription.parent_child_companies_enabled,
    })
    this.context.displayTempPositiveMessage({
      heading: 'Updating...',
    })
  }

  createCompanyItem = (company) => {
    const depth = getCompanyDepth(this.props.parentChildCompanies, company)
    const dashes = ' -'.repeat(depth)
    return (
      <div style={styles.parentChildToggle} key={`company-item-${company.subscription.id}`}>
        <a href={`/views/myagi-staff/company/${company.id}/admin/`}>
          {`${dashes} ${company.name} (${company.id}) - ${company.region_of_responsibility}`}
        </a>
        <SlideToggle
          initialValue={company.subscription.parent_child_companies_enabled}
          key={`company-item-slide-${company.subscription.id}`}
          style={styles.slideToggle}
          onChange={() => this.onParentChildCompaniesEnabledChange(company)}
        />
      </div>
    )
  }

  render() {
    const currentCompany = this.props.company
    const parentCompany = currentCompany.parent_company
    return (
      <div>
        <h3>Parent Company</h3>
        <div>
          {parentCompany ? (
            <div>
              {`${parentCompany.name} (${parentCompany.id}) `}
              <Icon
                onClick={this.removeParentCompany}
                name="remove"
                style={styles.removeParentIcon}
              />
            </div>
          ) : (
            <div>
              None
              <br />
            </div>
          )}
          <br />
          <button className="ui blue button" onClick={this.showChangeParentModal}>
            Select Parent Company
          </button>
          <div style={styles.hierarchyContainer}>
            <span style={styles.stat}>
              This is the company hierarchy. Toggle to enable / disable user movement between these
              companies:
            </span>
            <CompanyHierarchyTree
              companies={this.props.parentChildCompanies}
              currentUser={this.props.currentUser}
              createCompanyItem={this.createCompanyItem}
            />
          </div>
        </div>
        <Modal
          ref={(changeParentCompanyModal) => (this.changeParentCompanyModal = changeParentCompanyModal)
          }
          header="Select Parent Company"
          closeOnDimmerClick={false}
        >
          <div className="content">
            <CompanySearchableSelect
              internalUse
              onChange={this.onParentCompanySelect}
              fetchPrivateCompanies
            />
            {this.state.errorMessage ? (
              <div>
                <b style={styles.errorText}>{this.state.errorMessage}</b>
                <br />
                <br />
              </div>
            ) : null}
            <p>
              If you want users to be able to jump between companies, enable the "Parent Company
              Admin" option in the User Management Tool.
            </p>
            <button
              className="ui blue button"
              onClick={this.saveParentCompanySelection}
              disabled={this.state.errorMessage}
            >
              Save
              {' '}
              {this.state.isLoading && <div className="ui active small inline loader" />}
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}

export const SelectParentCompany = Marty.createContainer(SelectParentCompanyInner, {
  listenTo: [CompaniesState.Store, SubscriptionsState.Store],

  fetch: {
    parentChildCompanies() {
      const q = {
        fields: [
          $y.getFields(CompanyHierarchyTree, 'companies'),
          'region_of_responsibility',
          'subscription.parent_child_companies_enabled',
          'subscription.id',
        ],
        relative_companies: this.props.company.id,
        ordering: 'has_parent,name',
      }
      return CompaniesState.Store.getItems(q)
    },
  },

  pending() {
    return <div className="ui active small inline loader" />
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, SelectParentCompanyInner, errors)
  },
})
