import { PrimaryButton } from 'components/common/buttons'
import { FieldHeader } from 'components/common/form-deprecated'
import { LoadingSpinner } from 'components/common/loading'
import { t } from 'i18n'
import Marty from 'marty'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'

import ChannelShareRequestsState from 'state/channel-share-requests'
import ChannelsState from 'state/channels'
import containerUtils from 'utilities/containers'
import { Info } from 'components/common/info.jsx'
import { ChannelSelect } from '../channel-select'
import { CompanySelect } from '../company-select'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    margin: '0px 0px 20px',
  },
  infoText: {
    paddingTop: 5,
    paddingBottom: 5,
    color: '#BCBCBC',
  },
  link: {
    color: '#0074D9',
    fontSize: '0.9rem',
    cursor: 'pointer',
  },
  sharelinkContainer: {
    boxShadow: 'none',
    border: 0,
    marginBottom: '20px',
  },
}

@Radium
export class ConnectionModalInner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showShareLinks: false,
      showError: false,
      saving: false,
    }
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
  }

  onCompanySelect = (c) => {
    if (c) {
      this.setState({ ...this.state, companies: [...this.state.companies, c] })
      this.companySelect.getInput().reset()
    }
  }

  onSubmit = () => {
    const companiesObject = this.companySelect.getCompanies()

    const companies = Object.keys(companiesObject).map((id) => companiesObject[id])

    let channels = []
    if (this.props.channel) {
      channels = [this.props.channel]
    } else {
      const channelsObject = this.channelSelect.getChannels()
      channels = Object.keys(channelsObject).map((id) => channelsObject[id])
    }

    if (companies.length == 0 || channels.length == 0) {
      this.setState({ ...this.state, showError: true })
      return
    }
    this.setState({ ...this.state, showError: false, saving: true })

    const promises = []

    companies.forEach((company) => {
      channels.forEach((channel) => {
        const p = ChannelShareRequestsState.ActionCreators.create({
          company: company.url,
          training_unit: channel.url,
          requester: this.context.currentUser.url,
        })
        promises.push(p)
      })
    })

    Promise.all(promises)
      .then(() => {
        this.context.displayTempPositiveMessage({
          heading: t('connection_requested'),
        })
        this.setState({ ...this.state, saving: false })
      })
      .catch((err) => {
        const warning = err.response.body.training_unit
          ? t('channel_remix_plan_error')
          : t('connection_requested_if_not_exists')
        this.context.displayTempNegativeMessage({
          heading: warning,
        })
        this.setState({ ...this.state, saving: false })
      })
      .finally(() => {
        if (this.props.onConnectionsCreated) {
          this.props.onConnectionsCreated()
        }
      })
  }

  render() {
    const company = this.props.company
    return (
      <div className="content" style={styles.container}>
        <div style={styles.section}>
          <FieldHeader
            required
            headerStyle={styles.title}
            explanation={t('select_companies_explanation')}
          >
            {t('companies')}
          </FieldHeader>
          <CompanySelect ref={(c) => (this.companySelect = c)} />
          <div style={styles.infoText}>
            {`${t('no_myagi_send_sharelink')} `}
            <Info content={t('sharelinks_info')} />
          </div>
        </div>
        {!this.props.channel && (
          <div style={styles.section}>
            <FieldHeader
              required
              headerStyle={styles.title}
              explanation={t('select_channels_explanation')}
            >
              {t('channels')}
            </FieldHeader>
            <ChannelSelect ref={(c) => (this.channelSelect = c)} channels={this.props.channels} />
            {this.state.showError && (
              <div className="ui red message">{t('select_channel_and_company')}</div>
            )}
          </div>
        )}
        {this.state.saving ? (
          <LoadingSpinner />
        ) : (
          <PrimaryButton onClick={this.onSubmit}>{t('create')}</PrimaryButton>
        )}
      </div>
    )
  }
}

export const ConnectionModal = Marty.createContainer(ConnectionModalInner, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [ChannelsState.Store],

  fetch: {
    channels() {
      return ChannelsState.Store.getItems({
        fields: ['name', 'id', 'logo', 'url'],
        limit: 0,
        // Get channels owned by current company
        company: this.context.currentUser.company.id,
        ordering: 'name',
      })
    },
  },

  pending() {
    return containerUtils.defaultPending(this, ConnectionModalInner)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, ConnectionModalInner, errors)
  },
})
