import React from 'react'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import { useCommonRouter } from 'shared-js/routing'
import Emoji from 'components/common/emoji'
import { Button } from 'ui-kit'

const NoConnectionsMessage = () => {
  const { handlers } = useCommonRouter()
  return (
    <NoConnectionsMessageContainer>
      <NoConnectionsTitle>
        <Emoji symbol="😟" style={{ marginRight: 10 }} />
        {t('there_are_no_companies_in_your_network')}
      </NoConnectionsTitle>
      {t('expand_your_network_to_start_sharing_content')}
      <ButtonContainer>
        <Button onClick={() => handlers.goToFindConnections()}>{t('expand_your_network')}</Button>
      </ButtonContainer>
    </NoConnectionsMessageContainer>
  )
}

const NoConnectionsMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NoConnectionsTitle = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey900};
  margin-bottom: 15px;
`

const ButtonContainer = styled.div`
  margin: 15px auto 0 auto;
`

export default NoConnectionsMessage
