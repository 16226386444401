import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import reactMixin from 'react-mixin'
import containerUtils from 'utilities/containers'
import createPaginatedStateContainer from 'state/pagination'
import { remoteSearchMixinFactory } from 'components/common/search'
import { t } from 'i18n'
import Style from 'style'
import { Panel, BoxHeader, BoxContent } from 'components/common/box'
import { LoadingContainer } from 'components/common/loading'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import { DeprecatedScrollableDataTable } from 'components/common/table'
import moment from 'moment-timezone'
import { validateURL } from 'utilities/validators'
import CompaniesState from 'state/companies'
import PageState from './state'
import { Merge, Deactivate } from './merge-deactivate-utils'

const styles = {
  duplicates: {
    color: Style.vars.deprecatedColors.primary,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}

@reactMixin.decorate(remoteSearchMixinFactory(PageState.ActionCreators.setSearch))
class StandardsReview extends React.Component {
  static propTypes = {
    companies: PropTypes.instanceOf(Im.List),
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  static tableDataMapping = {
    'Company Name': (u, cxt) => <div>{u.name}</div>,
    'Myagi Id': (u, cxt) => <div>{u.id}</div>,
    Region: (u, cxt) => <div>{u.region_of_responsibility}</div>,
    'Date Created': (u, cxt) => <div>{moment(u.created_at).format('DD MMMM YYYY')}</div>,
    'Latest Activity': (u, cxt) => (
      <div>
        {u.latest_activity ? (
          <div>{moment(u.latest_activity.start_time).format('DD MMMM YYYY')}</div>
        ) : (
          <p>No Activity</p>
        )}
      </div>
    ),
    'Num of Users': (u, cxt) => <div>{u.real_user_count}</div>,
    'Potential Duplicates\n(name:id)': (u, cxt) =>
      u.potential_duplicates_info.length ? (
        u.potential_duplicates_info.map((duplicate, index) => (
          <div key={index}>
            <div
              onClick={() => {
                cxt.handleDuplicateClick(u)
              }}
              style={styles.duplicates}
            >
              {`${duplicate}\n`}
            </div>
          </div>
        ))
      ) : (
        <div>N/A</div>
      ),
    'Num External Teams Connected to Content': (u, cxt) => (
      <div>{u.num_external_teams_connected_to_content}</div>
    ),
    Actions: (u, cxt) => (
      <div>
        <Merge company={u} />
        <Deactivate id={u.id} />
      </div>
    ),
  }

  constructor(props) {
    super(props)
    this.debouncedInputUpdate = _.debounce(this.onInputChange, 200)
  }

  onInputChange = () => {
    const { company } = this.props
    let { value } = this.input
    if (value && !value.includes('http')) {
      value = `http://${value}`
    }
    if (validateURL(value) === true || !value) {
      CompaniesState.ActionCreators.update(company.id, {
        company_url: value,
      })
        .then(() => {
          this.context.displayTempPositiveMessage({
            heading: 'Success',
            body: `URL updated to: ${value}`,
          })
        })
        .catch((err) => {
          console.log(err)
          this.context.displayTempNegativeMessage({
            heading: "Oh nooooo, it didn't work",
            body: 'Try again...',
          })
        })
    } else {
      this.context.displayTempNegativeMessage({
        heading: 'Invalid URL',
        body: `${value} is not a valid URL`,
      })
    }
  }

  getDataMapping() {
    return StandardsReview.tableDataMapping
  }

  getHeaders() {
    return Im.freeze(_.keys(this.getDataMapping()))
  }

  getRows() {
    const funcs = _.values(this.getDataMapping())
    return this.props.companies.map((u) => Im.freeze(funcs.map((f) => f(u, this))))
  }

  handleDuplicateClick(company) {
    const searchIds = company.potential_duplicates_info.reduce((ids, dup, index) => {
      const duplicateId = dup.split(' : ')[1]
      ids = `${duplicateId} ${ids}`
      return ids
    }, `${company.id}`)
    this.refs.searchInput.setValue('')
    PageState.ActionCreators.setSearch(`${searchIds} ${company.name}`)
  }

  render() {
    return (
      <Panel>
        <BoxHeader>
          <h1>Company Review</h1>
        </BoxHeader>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="ui blue message" style={{ width: '50%' }}>
            <p>
              <b>What is this tool used for?</b>
              <br />
              <p>
                Use this tool to find duplicate companies and merge them into the original company,
                or to deactivate companies that are no longer in use. 
{' '}
<br />
                <br />
{' '}
What is being merged:
<br />
{' '}
Users
<br />
{' '}
Channels
<br />
                Plans 
{' '}
<br />
{' '}
Lessons
<br />
{' '}
Outgoing and incoming shared training units
<br />
                Outgoing and incoming company connections
              </p>
            </p>
          </div>
        </div>
        <BoxContent>
          <div className="ui column">
            <div className="ui column">
              {(this.props.companies || PageState.Store.getSearch()) &&
                this.getSearchInput({
                  initialValue: t('search_companies'),
                })}
            </div>
          </div>
          <LoadingContainer
            loadingProps={[this.props.companies]}
            createComponent={() => (
              <div>
                <InfiniteScroll
                  style={{ marginTop: 20 }}
                  loadMore={this.props.loadMore}
                  moreAvailable={this.props.moreAvailable}
                  isLoading={this.props.isLoading}
                >
                  <DeprecatedScrollableDataTable
                    headers={this.getHeaders()}
                    rows={this.getRows()}
                    bodyHeight={null}
                    sortDisabled
                    style={{ textAlign: 'center' }}
                  />
                </InfiniteScroll>
              </div>
            )}
          />
        </BoxContent>
      </Panel>
    )
  }
}

export const Page = createPaginatedStateContainer(StandardsReview, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },
  listenTo: [CompaniesState.Store, PageState.Store],
  paginate: {
    store: CompaniesState.Store,
    propName: 'companies',
    limit: 30,
    getQuery() {
      const search = PageState.Store.getSearch()
      const q = {
        ordering: 'name',
        fields: [
          'id',
          'url',
          'company_url',
          'real_user_count',
          'name',
          'region_of_responsibility',
          'latest_activity',
          'num_publicly_viewable_channels',
          'deactivated',
          'created_at',
          'most_recent_user_activity',
          'num_external_teams_connected_to_content',
          'potential_duplicates_info',
        ],
      }
      if (search) {
        q.search = search
        q.ordering = '-search_rank'
      }
      return q
    },
  },
  UNSAFE_componentWillMount() {
    PageState.Store.resetState()
  },
  pending() {
    return containerUtils.defaultPending(this, StandardsReview)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, StandardsReview, errors)
  },
})
