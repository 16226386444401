import React, { useState } from 'react'
import Style from 'shared-js/style'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import { getPaginationProps } from 'shared-js/state/graphql'
import { ContainedList } from 'components/common/virtualized-components'
import { LoadingSpinner } from 'shared-js/components/common/loading'
import { FilterType, StyledArrowIcon, Search } from '../common'
import IndustryItem, { ITEM_HEIGHT } from './industry-item'
import { LOADING_SIZE, EMPTY_SEARCH_INPUT, useIndustries } from './industries-provider'

const MAX_ITEMS_SHOW = 6

const FilterByIndustryDesktop = () => {
  const { apolloProps, searchTerm, setSearchTerm } = useIndustries()
  if (apolloProps.error) return null

  const hasFetchedIndustries = apolloProps.data?.Tag__List
  const hasIndustries = Boolean(apolloProps.data?.Tag__List?.totalCount)

  if (hasFetchedIndustries) {
    const paginationProps = getPaginationProps({
      apolloProps,
      listFieldName: 'Tag__List',
    })

    if (hasIndustries) {
      return (
        <SectionContainer>
          <Search
            value={searchTerm}
            placeholder={t('search_by_industry')}
            onChange={(value) => setSearchTerm(value)}
          />
          <ContainedList
            items={apolloProps.data?.Tag__List?.edges || []}
            totalCount={apolloProps.data?.Tag__List?.totalCount || 0}
            loadingSize={LOADING_SIZE}
            loadMoreData={paginationProps.loadMoreData}
            dataIsLoading={paginationProps.dataIsLoading}
            maxNumRowsShownAtATime={MAX_ITEMS_SHOW}
            rowRenderer={({ index, key, style }) =>
              apolloProps.data?.Tag__List?.edges[index] ? (
                <IndustryItem
                  style={style}
                  key={key}
                  tag={apolloProps.data?.Tag__List?.edges[index]}
                />
              ) : (
                <div key={key} style={style} />
              )
            }
            rowHeight={ITEM_HEIGHT}
          />
        </SectionContainer>
      )
    }

    return searchTerm !== EMPTY_SEARCH_INPUT ? (
      <SectionContainer>
        <Search
          value={searchTerm}
          placeholder={t('search_by_industry')}
          onChange={(value) => setSearchTerm(value)}
        />
        {`${t('no_results_for')} "${searchTerm}"`}
      </SectionContainer>
    ) : null
  }

  // loading state must be placed at the end to avoid a scrolling bug with the virtualized list
  if (apolloProps.loading) return <LoadingSpinner />

  // returning null as fallback to avoid a bug we've had where the page seems to infinitely load
  return null
}

const SectionContainer = ({ children }: { children: React.ReactNode }) => {
  const [industryFilterIsExpanded, toggleIndustryFilterIsExpanded] = useState(true)
  return (
    <Container>
      <FilterType onClick={() => toggleIndustryFilterIsExpanded(!industryFilterIsExpanded)}>
        {t('industry')}
        <StyledArrowIcon
          name={industryFilterIsExpanded ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
          size={20}
        />
      </FilterType>
      {industryFilterIsExpanded && children}
    </Container>
  )
}

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;
  border-top: 1px solid ${Style.colors.grey200};
  padding-top: 20px;
`

export default FilterByIndustryDesktop
