import { View } from 'react-native'
import { FieldHeader, getPropsFromFormik } from 'shared-js/components/common/form'
import * as Inputs from 'shared-js/components/common/inputs'
import React, { useEffect, useState } from 'react'
import Style from 'style'
import { t } from 'i18n'
import { getAPIBaseURL } from '../../../../core/state-configuration'

const RESULT_COUNT_HEADER = 'X-Result-Count'

const fetchTeams = async (companyId, offset = 0, limit = 0) => {
  const queryParams = {
    offset,
    limit,
    ordering: 'name',
    fields: ['name', 'url', 'id', 'location_address'].join(','),
    company: companyId,
  }
  const url = `public/learner_groups/?${new URLSearchParams(queryParams).toString()}`
  const baseURL = getAPIBaseURL()
  return fetch(`${baseURL}${url}`)
}

export const TeamsSelect = ({ innerProps, companyId }) => {
  const [teamItems, setTeamItems] = useState([])

  useEffect(() => {
    window.sessionStorage.setItem('teamItems', JSON.stringify(teamItems))
  }, [teamItems])

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetchTeams(companyId)
        const itemsCount = response.headers.get(RESULT_COUNT_HEADER)
        const items = await response.json()

        setTeamItems(items)

        if (items.length < itemsCount) {
          const limit = items.length
          const pages = Math.ceil((itemsCount - limit) / limit)
          const additionalItems = await Promise.all(
            new Array(pages).fill(null).map(async (_, index) => {
              const pageResponse = await fetchTeams(companyId, (index + 1) * limit, limit)
              return pageResponse.json()
            })
          )
          setTeamItems((prevItems) => [...prevItems, ...additionalItems.flat()])
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [companyId])

  const teamOptions = teamItems.map((team) => ({
    value: team.id,
    label: team.location_address ? `${team.name} (${team.location_address})` : team.name,
  }))

  return (
    <View>
      <FieldHeader>{t('select_a_team')}</FieldHeader>
      <Inputs.DropdownInput
        style={styles.input}
        options={teamOptions}
        placeholder={`${t('please_choose')}...`}
        {...getPropsFromFormik(innerProps, 'teamId')}
      />
    </View>
  )
}

const styles = {
  input: {
    color: Style.vars.deprecatedColors.xxxDarkGrey,
  },
}
