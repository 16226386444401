import React from 'react'
import styled from 'styled-components'
import { GetLevelOneIndustries_Tag__List_edges as IndustryEdge } from './gql-types/GetLevelOneIndustries'

type IndustriesProps = {
  activeIndustriesIDs: (string | null)[]
  industries: (IndustryEdge | null)[]
  toggleIndustry(industryID: string, isActive: boolean): void
}

type ItemProps = {
  isActive: boolean
  onClick(): void
}

type IndustryProps = {
  name: string
} & ItemProps

const IndustryList = ({ industries, activeIndustriesIDs, toggleIndustry }: IndustriesProps) => {
  return (
    <ListContainer>
      {industries.map((industry: IndustryEdge | null) => {
        if (industry && industry.node && industry.node.name) {
          const isActive = activeIndustriesIDs.some(
            (activeIndustryID) => activeIndustryID === industry.node!.id
          )
          return (
            <Industry
              key={industry.node.id}
              name={industry.node.name}
              isActive={isActive}
              onClick={() => toggleIndustry(industry.node!.id, isActive)}
            />
          )
        }
        return null
      })}
    </ListContainer>
  )
}

const Industry = ({ name, isActive, onClick }: IndustryProps) => {
  return (
    <ItemContainer isActive={isActive} onClick={onClick}>
      <Name>{name}</Name>
    </ItemContainer>
  )
}

const ListContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 18px;
  max-height: 45vh;
  overflow-y: auto;
  @media (max-width: 768px) {
    max-height: 40vh;
  }
`

const ItemContainer = styled.div<ItemProps>`
  border-radius: 16px;
  border: ${({ theme, isActive }) =>
    isActive ? `solid 1px ${theme.colors.indigo800}` : `solid 1px ${theme.colors.grey400}`};
  background-color: ${({ isActive }) => (isActive ? 'rgba(40, 53, 147, 0.12)' : 'none')};
  padding: 6px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  white-space: nowrap;
  cursor: pointer;
`

const Name = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
`

export default IndustryList
