import { remoteSearchMixinFactory } from 'components/common/search'
import { SelectObjects } from 'components/common/select-objects'
import { getViewableUsersQuery } from 'components/people/users/page'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'
import ChannelSharesState from 'state/channel-shares'
import createPaginatedStateContainer from 'state/pagination'
import TeamsState from 'state/teams'
import containerUtils from 'utilities/containers'
import { getIdFromApiUrl } from 'utilities/generic'
import { Modal } from 'components/common/modals'
import { t } from 'i18n'
import { getDisplayNameFunction, TEAM_TAB, AREA_TAB } from '../constants'
import PageState from '../state'

@reactMixin.decorate(remoteSearchMixinFactory(PageState.ActionCreators.setSearch))
class TeamSelectorInner extends React.Component {
  state = {
    deselectTeam: null,
  }

  onChange = (newValue, team) => {
    if (this.isDisabled(team)) {
      this.setState({ ...this.state, deselectTeam: team })
      this.modal.show()
      return
    }
    return this.props.onChange(newValue, team)
  }

  renderObject = (team) => {
    const disabled = this.isDisabled(team)
    return (
      <div
        style={{
          ...styles.row,
          ...(disabled ? styles.disabledRow : {}),
        }}
      >
        <div style={styles.name}>{getDisplayNameFunction(TEAM_TAB)(team)}</div>

        {disabled && <div style={styles.areaContainer}>{this.getAreaEnrollments(team)}</div>}
      </div>
    )
  }

  getAreaEnrollments = (team) => {
    const access = team.access_to_requested_channel
    const area_names = access[AREA_TAB].map((a) => a.area__name)

    return area_names.map((name) => (
      <div key={name} style={styles.area}>
        {name}
      </div>
    ))
  }

  isDisabled = (obj) => {
    const access = obj.access_to_requested_channel
    return access[AREA_TAB].length > 0
  }

  isSelected = (obj) => {
    const enrolledIndirectly = this.isDisabled(obj)
    if (enrolledIndirectly) {
      return true
    }
    return this.props.isSelected(obj)
  }

  render() {
    return (
      <div>
        <SelectObjects
          loadMore={this.props.loadMore}
          moreDataAvailable={this.props.moreDataAvailable}
          dataIsLoading={this.props.dataIsLoading}
          objects={this.props.teams}
          renderObject={this.renderObject}
          isSelected={this.isSelected}
          isDisabled={this.isDisabled}
          onChange={this.onChange}
          value={this.props.value}
        />
        <Modal ref={(modal) => (this.modal = modal)} header={t('sorry')} basic message>
          <div>{t('channel_enrollments_inderect_enrollment_explainer')}</div>
          <div>
            <br />
            {this.state.deselectTeam && this.getAreaEnrollments(this.state.deselectTeam)}
          </div>
        </Modal>
      </div>
    )
  }
}

export const TeamSelector = createPaginatedStateContainer(TeamSelectorInner, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [PageState.Store, TeamsState.Store, ChannelSharesState.Store],

  paginate: {
    store: TeamsState.Store,
    propName: 'teams',
    limit: 40,
    getQuery() {
      const learner = this.context.currentUser.learner
      const query = {
        ordering: 'name',
        fields: ['id', 'name', 'access_to_requested_channel'],
        access_to_requested_channel: this.props.channel.id,
      }

      const search = PageState.Store.getSearch()
      if (search) {
        query.search = search
        query.ordering = '-search_rank'
      }

      if (!learner.can_manage_training_content) {
        query.id__in = [getIdFromApiUrl(learner.learner_group)]
      }

      return getViewableUsersQuery(query, this.context.currentUser)
    },
  },

  UNSAFE_componentWillMount() {
    PageState.Store.resetState()
  },

  show() {
    this.innerComponent.show()
  },

  pending() {
    return containerUtils.defaultPending(this, TeamSelectorInner)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, TeamSelectorInner, errors)
  },
})

const styles = {
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  disabledRow: {},
  name: {
    marginRight: 10,
  },
  areaContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  area: {
    display: 'inline-block',
    border: '1px solid #aaa',
    padding: '2px 5px',
    borderRadius: 3,
    marginLeft: 3,
  },
}
