import React from 'react'
import { Route, Redirect } from 'react-router'

import { Page as NotFoundPage } from 'components/not-found/page.jsx'

import { Page as SharedReportPage } from 'components/analytics/shared-report-page'
import { Page as CreditCardPaymentPage } from 'components/payments/page'

import { Page as LoginPage } from './login/page'
import { Page as PasswordResetPage } from './password/page'
import { Page as UnsubscribePage } from './unsubscribe/page'
import { Page as SignupPage } from './signup'
import { Page as UserSignupPage } from './signup/user/page'
import { SignupFromShareLink } from './signup/sharelink-signup'
import { Page as CompanySignupPage } from './signup/company/page'
import { Page as InvitePage } from './signup/invite/page'
import { Page as RequestSentPage } from './signup/company/request-sent/page'
import { Page as NewCompanySignupPage } from './signup/new-company/page'

export const signupRoutes = (
  <Route component={SignupPage}>
    {/* Expanded * routes to avoid '/* /' trails when using resolve */}
    <Route name="user-signup" path="user" component={UserSignupPage}>
      <Route path="*" />
    </Route>
    <Route name="company-signup" path="company" component={CompanySignupPage}>
      <Route path="*" />
    </Route>
    <Route name="new-company-signup" path="new-company" component={NewCompanySignupPage}>
      <Route path="*" />
    </Route>
    <Route name="signup-invite" path="invite" component={InvitePage}>
      <Route path="*" />
    </Route>
    <Route name="request-sent" path="request-sent" component={RequestSentPage}>
      <Route path="*" />
    </Route>
    <Route name="signup-sharelink" path="from-sharelink/:link" component={SignupFromShareLink} />
    <Redirect from="*" to="user/" component={UserSignupPage} />
  </Route>
)

export const accountRoutes = (
  <Route>
    <Route name="login" path="login/*" component={LoginPage} />
    <Route name="password-reset" path="password-reset/" component={PasswordResetPage} />
    <Route name="unsubscribe" path="unsubscribe/" component={UnsubscribePage} />
    <Route name="create-password" path="create-password/" component={PasswordResetPage} />
    <Route path="*" component={NotFoundPage} />
  </Route>
)

export const publicRoutes = (
  <Route>
    <Route name="company-home" path="home/" component={UserSignupPage} />
    <Route name="shared-report" path="report/:token/" component={SharedReportPage} />
    <Route name="payments" path="payments/" component={CreditCardPaymentPage} />
    <Route path="*" component={NotFoundPage} />
  </Route>
)
