import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router'
import { t } from 'shared-js/i18n'
import { StaticModal } from 'components/common/modals/static'
import PLACEHOLDER_IMAGE from 'img/placeholder.svg'
import LinksState from 'shared-js/state/links'
import { resolve } from 'shared-js/utilities/deprecated-named-routes'
import { Dropdown } from 'components/common/dropdown'
import { Modal } from 'components/common/modals/index.jsx'
import MENU from '../common/menu.svg'
import { SharelinkModal } from './sharelink-modal'
import ConnectedCompaniesModal from './connected-companies-modal'

const SHARELINK_URL =
  window.location.hostname.includes('staging') ||
  /^(127(\.\d+){1,3}|[0:]+1|localhost)$/.test(window.location.hostname)
    ? `${window.location.protocol}//${window.location.host}/s/`
    : 'https://myagi.com/s/'

type Props = {
  sharelink: {
    id: string
    name: string
    num_companies_used_by: number
    channels: {
      id: string
      logo: string
      name: string
    }[]
  }
  allChannels: any[]
}

export const SharelinkItem = (props: Props) => {
  const [showModal, setShowModal] = useState(false)
  const deleteLink = () => {
    LinksState.ActionCreators.update(props.sharelink.id, {
      active: false,
    })
  }
  const sharelinkModalRef = useRef<SharelinkModal>(null)
  const deleteModal = useRef<Modal>(null)

  const sharelink = props.sharelink

  return (
    <Container>
      <LogoContainer>
        {sharelink.channels.map((channel) => (
          <Logo key={channel.id} logo={channel.logo} />
        ))}
      </LogoContainer>

      <TextContainer>
        {sharelink.channels.map((channel) => (
          <Link to={resolve('channel', { channelId: channel.id })} key={channel.id}>
            <ChannelName>{channel.name}</ChannelName>
          </Link>
        ))}
        <Url>{`${SHARELINK_URL + sharelink.name}`}</Url>
      </TextContainer>

      <Dropdown className="ui pointing top right dropdown">
        <Menu src={MENU} key="dropdownIcon" />
        <div className="menu">
          <div className="item" onClick={() => setShowModal(true)}>
            {t(
              'used_by_x_companies',
              { numCompanies: sharelink.num_companies_used_by },
              { pluralize: sharelink.num_companies_used_by }
            )}
          </div>
          <div
            key="edit"
            className="item"
            onClick={() => sharelinkModalRef.current && sharelinkModalRef.current.show()}
          >
            {t('edit')}
          </div>
          <div
            key="delete"
            className="item"
            onClick={() => deleteModal.current && deleteModal.current.show()}
          >
            {t('delete')}
          </div>
        </div>
      </Dropdown>

      {showModal && (
        <StaticModal header={t('sharelink_used_by')} onClose={() => setShowModal(false)}>
          <ConnectedCompaniesModal sharelink={sharelink} />
        </StaticModal>
      )}
      <SharelinkModal ref={sharelinkModalRef} sharelink={sharelink} channels={props.allChannels} />
      <Modal
        ref={deleteModal}
        header="Are you sure you want to delete this sharelink?"
        content="If you have given this link to anyone, it will no longer work."
        onConfirm={deleteLink}
        basic
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 20px;
`
const Logo = styled.div<{ logo: string }>`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  background-image: ${(props) => `url(${props.logo || PLACEHOLDER_IMAGE})`};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid black;
  margin-bottom: -20px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 300px;
`

const ChannelName = styled.div`
  color: black;
  &:hover {
    transform: translate(5px, 0px) scale(1.02);
    transition: all 0.2s;
  }
`

const Url = styled.div`
  color: #33b;
  font-size: 0.9rem;
`

const Menu = styled.img`
  height: 25px;
  margin-left: 10px;
`
