import { t } from 'shared-js/i18n'

export const getListSummary = (
  originalList: any[],
  maxShown: number,
  getRemainderDefinition: (remainingCount: number) => string,
  getLabel: (item: any) => string
) => {
  // prevent manipulating the original list
  const list = [...originalList]
  if (list.length <= maxShown) {
    let listedItems = ''
    list.forEach((item, index) => {
      if (index === list.length - 2) {
        listedItems = `${listedItems + getLabel(item)} ${t('and')} `
      } else if (index === list.length - 1) {
        listedItems += getLabel(item)
      } else {
        listedItems = `${listedItems + getLabel(item)}, `
      }
    })
    return listedItems
  }

  let listedItems = ''
  const itemsToList = list.splice(0, maxShown)
  itemsToList.forEach((item, index) => {
    if (index === itemsToList.length - 1) {
      listedItems += getLabel(item)
    } else {
      listedItems = `${listedItems + getLabel(item)}, `
    }
  })

  return `${listedItems} ${getRemainderDefinition(list.length)}`
}
