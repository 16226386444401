import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { UpdateCacheProps as UniversalUpdateCacheProps } from 'shared-js/state/graphql/update-cache'
import { useHandleChannelSubscriptions } from './context'
import {
  RemoveChannelSubscription as DeactivateMutation,
  RemoveChannelSubscriptionVariables as DeactivateVariables,
} from './gql-types/RemoveChannelSubscription'
import {
  CreateChannelSubscription as CreateMutation,
  CreateChannelSubscriptionVariables as CreateVariables,
} from './gql-types/CreateChannelSubscription'
import { GetChannelInfoQuery_TrainingUnit__Item as Channel } from '../gql-types/GetChannelInfoQuery'
import ManageSubscriptions from './manage-subscriptions'
import { ERROR, CREATED, DEACTIVATED, SaveChangesResult, OutcomeOptions } from './success-message'
import { FetchedConnectedCompaniesApolloProps } from './company-list'

export type UpdateCacheProps = UniversalUpdateCacheProps & {
  mutation: typeof CREATED | typeof DEACTIVATED
}

type MutationsContainerProps = {
  channel: Channel
  setSaveChangesResults(result: SaveChangesResult[]): void
  updateSubscribedCompaniesCount(props: UpdateCacheProps): void
  updateConnectedCompany(props: UpdateCacheProps): void
  apolloProps: FetchedConnectedCompaniesApolloProps
  setSearchTerm(val: string): void
}

const REMOVE_CHANNEL_SUBSCRIPTION = gql`
  mutation RemoveChannelSubscription($channelID: ID!, $companyID: ID!) {
    SharedTrainingUnit__Deactivate(channelId: $channelID, companyId: $companyID) {
      ok
      result {
        id
        company {
          id
          name
        }
      }
    }
  }
`

const CREATE_CHANNEL_SUBSCRIPTION = gql`
  mutation CreateChannelSubscription($channelID: ID!, $companyID: ID!) {
    SharedTrainingUnit__Create(input: { company: $companyID, trainingUnit: $channelID }) {
      ok
      result {
        id
        company {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

const MutationsContainer = ({
  channel,
  setSaveChangesResults,
  updateSubscribedCompaniesCount,
  updateConnectedCompany,
  apolloProps,
  setSearchTerm,
}: MutationsContainerProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    companiesToSubscribe,
    companiesToUnsubscribe,
    resetSubscriptionChanges,
  } = useHandleChannelSubscriptions()
  const [deactivateChannelSubscription] = useMutation<DeactivateMutation, DeactivateVariables>(
    REMOVE_CHANNEL_SUBSCRIPTION
  )
  const [createChannelSubscription] = useMutation<CreateMutation, CreateVariables>(
    CREATE_CHANNEL_SUBSCRIPTION
  )

  const asyncDeactivateSubscriptions: () => Promise<SaveChangesResult>[] = () => {
    return companiesToUnsubscribe.map(async (company) => {
      let outcome: OutcomeOptions = ERROR
      let companyName = null

      if (company) {
        const variables = {
          channelID: channel.id,
          companyID: company.id,
        }
        const result = await deactivateChannelSubscription({
          variables,
          update: (store, mutationResult) => {
            if (mutationResult?.data?.SharedTrainingUnit__Deactivate?.ok) {
              const updateProps = {
                cache: store,
                mutationResult,
              }
              updateSubscribedCompaniesCount({
                ...updateProps,
                mutation: DEACTIVATED,
              })
              updateConnectedCompany({
                ...updateProps,
                mutation: DEACTIVATED,
              })
            }
          },
        })

        if (result?.data?.SharedTrainingUnit__Deactivate?.ok) {
          outcome = DEACTIVATED
          companyName = result.data.SharedTrainingUnit__Deactivate.result?.company.name
        } else {
          outcome = ERROR
          companyName = company.name
        }
      }

      return { outcome, companyName }
    })
  }

  const asyncCreateSubscriptions: () => Promise<SaveChangesResult>[] = () => {
    return companiesToSubscribe.map(async (company) => {
      let outcome: OutcomeOptions = ERROR
      let companyName = null

      if (company) {
        const variables = {
          channelID: channel.id,
          companyID: company.id,
        }

        const result = await createChannelSubscription({
          variables,
          update: (store, mutationResult) => {
            if (mutationResult?.data?.SharedTrainingUnit__Create?.ok) {
              const updateProps = {
                cache: store,
                mutationResult,
              }
              updateSubscribedCompaniesCount({
                ...updateProps,
                mutation: CREATED,
              })
              updateConnectedCompany({
                ...updateProps,
                mutation: CREATED,
              })
            }
          },
        })

        if (result?.data?.SharedTrainingUnit__Create?.ok) {
          outcome = CREATED
          companyName = result.data.SharedTrainingUnit__Create.result?.company.name
        } else {
          outcome = ERROR
          companyName = company.name
        }
      }

      return { outcome, companyName }
    })
  }

  const saveChanges = () => {
    setIsLoading(true)
    const promiseArray = [...asyncDeactivateSubscriptions(), ...asyncCreateSubscriptions()]
    Promise.all(promiseArray).then((res) => {
      setIsLoading(false)
      resetSubscriptionChanges()
      setSaveChangesResults(res)
    })
  }

  return (
    <ManageSubscriptions
      channel={channel}
      isLoading={isLoading}
      saveChanges={saveChanges}
      apolloProps={apolloProps}
      setSearchTerm={setSearchTerm}
    />
  )
}

export default MutationsContainer
