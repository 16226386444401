import React from 'react'
import _ from 'lodash'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'

import Style from 'shared-js/style'
import InfiniteListView from 'shared-js/components/common/list/infinite'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import { getPaginationProps } from 'shared-js/state/graphql'
import { NAVBAR_HEIGHT } from 'components/navbar'
import { ChannelTabListQueryVariables, ChannelTabListQuery } from './gql-types/ChannelTabListQuery'
import { ChannelCardList, ChannelCardListLoading } from './channel-list'
import { RecentlyViewedDesktop } from './recently-viewed-desktop'
import { Title } from './common'

export const CHANNEL_LOADING_SIZE = 24

export const CHANNELS_QUERY = gql`
  query ChannelTabListQuery($cursor: String, $userId: ID!, $channelLoadingSize: Int) {
    TrainingUnit__List(
      after: $cursor
      first: $channelLoadingSize
      hasContent: true
      hasEnrollmentsForUser_New: $userId
      deactivated_Isnull: true
      orderByDueDateForUser: $userId
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...ChannelCardList_channel
        }
      }
    }
  }
  ${ChannelCardList.fragment}
`

export const Page: React.StatelessComponent = () => {
  const currentUser = useCurrentUser()
  const apolloProps = useQuery<ChannelTabListQuery, ChannelTabListQueryVariables>(CHANNELS_QUERY, {
    variables: {
      userId: currentUser.id,
      channelLoadingSize: CHANNEL_LOADING_SIZE,
    },
  })
  const edges =
    apolloProps.data &&
    apolloProps.data.TrainingUnit__List &&
    apolloProps.data.TrainingUnit__List.edges
  return (
    <Container>
      <LeftPanel>
        {edges && edges.length === 0 ? (
          <React.Fragment>
            <Title text={`🚀 ${t('explore')}`} />
            <NoContentText style={{ marginTop: 30 }}>{t('no_channels_available')}</NoContentText>
          </React.Fragment>
        ) : (
          <InfiniteListView
            {...getPaginationProps({
              apolloProps,
              listFieldName: 'TrainingUnit__List',
            })}
            renderLoading={() => <ChannelCardListLoading showTitle={!(edges && edges.length)} />}
            contentContainerStyle={{ width: '100%' }}
            renderItems={() => edges && edges.length && <ChannelCardList channels={edges} />}
          />
        )}
      </LeftPanel>
      <RecentlyViewedDesktop />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: ${Style.vars.widths.mainContentMaxWidth}px;
  display: flex;
  background-color: ${Style.colors.grey100};
  min-height: calc(100vh - ${NAVBAR_HEIGHT}px);
`

const LeftPanel = styled.div`
  padding: 20px 70px;
  flex-grow: 1;
`

const NoContentText = styled.div`
  margin-top: 30px;
`
