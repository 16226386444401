import { remoteSearchMixinFactory } from 'components/common/search'
import { SelectObjects } from 'components/common/select-objects'
import { getViewableUsersQuery } from 'components/people/users/page'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'
import ChannelSharesState from 'state/channel-shares'
import EnrollmentGroupsState from 'state/enrollment-groups'
import createPaginatedStateContainer from 'state/pagination'
import containerUtils from 'utilities/containers'
import PageState from '../state'

@reactMixin.decorate(remoteSearchMixinFactory(PageState.ActionCreators.setSearch))
class GroupSelectorInner extends React.Component {
  renderObject = (team) => <div>{team.name}</div>

  render() {
    return (
      <SelectObjects
        loadMore={this.props.loadMore}
        moreDataAvailable={this.props.moreDataAvailable}
        dataIsLoading={this.props.dataIsLoading}
        objects={this.props.groups}
        renderObject={this.renderObject}
        isSelected={this.props.isSelected}
        onChange={this.props.onChange}
        value={this.props.value}
      />
    )
  }
}

export const GroupSelector = createPaginatedStateContainer(GroupSelectorInner, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [PageState.Store, EnrollmentGroupsState.Store, ChannelSharesState.Store],

  paginate: {
    store: EnrollmentGroupsState.Store,
    propName: 'groups',
    limit: 40,
    getQuery() {
      const query = {
        ordering: 'name',
        fields: ['id', 'name', 'access_to_requested_channel'],
        access_to_requested_channel: this.props.channel.id,
      }

      const search = PageState.Store.getSearch()
      if (search) {
        query.search = search
        query.ordering = '-search_rank'
      }

      return getViewableUsersQuery(query, this.context.currentUser)
    },
  },

  UNSAFE_componentWillMount() {
    PageState.Store.resetState()
  },

  show() {
    this.innerComponent.show()
  },

  pending() {
    return containerUtils.defaultPending(this, GroupSelectorInner)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, GroupSelectorInner, errors)
  },
})
