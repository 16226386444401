import React, { useContext } from 'react'
import { t } from 'shared-js/i18n'
import styled from 'styled-components'
import { defaultLogo } from 'core/style-configuration'
import { ConnectionInvitationContext } from 'components/public-app/context'
import { ConnectionInvitationQuery_PublicCompany__List_edges_node } from 'components/public-app/gql-types/ConnectionInvitationQuery'

type CompanyLogoProps = {
  src: string,
}

type InviterNode = ConnectionInvitationQuery_PublicCompany__List_edges_node | null

const ConnectionInvitationMessage = () => {
  const connectionInviter: InviterNode = useContext(ConnectionInvitationContext)
  return connectionInviter ? (
    <Container>
      <CompanyLogo src={connectionInviter!.companyLogo} />
      <CompanyName>{`${connectionInviter!.name} `}</CompanyName>
      <Text>{t('has_invited_you_to_join_myagi')}</Text>
    </Container>
  ) : null
}

const Container = styled.div`
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`

const CompanyName = styled.b`
  white-space: pre;
`

const Text = styled.span`
  font-size: 30px;
`

const CompanyLogo = styled.div<CompanyLogoProps>`
  height: 84px;
  max-height: 84px;
  min-width: 84px;
  max-width: 84px;
  border: ${(props) => (props.src ? `solid 1px #dcdcdc` : `none`)};
  border-radius: 4px;
  margin-right: 20px;
  background-color: ${(props) => (props.src ? 'none' : '#f8f8f8')};
  background-image: ${(props) => (props.src ? `url("${props.src}")` : `url("${defaultLogo}")`)};
  background-repeat: no-repeat;
  background-size: ${(props) => (props.src ? 'contain' : '30px 30px')};
  background-position: center;
`

export default ConnectionInvitationMessage
