import React, { useContext } from 'react'
import { useUrlState } from 'utilities/querystring'
import { ActiveBrandFiltersQuery_PublicCompany__List_edges_node as BrandFilter } from './channel-list-section/gql-types/ActiveBrandFiltersQuery'
import { ActiveIndustryFiltersQuery_Tag__List_edges_node as IndustryFilter } from './channel-list-section/gql-types/ActiveIndustryFiltersQuery'

type BrandsFilterContextType = {
  brandsFiltered: string[]
  addOrRemoveBrandFilter(brand: BrandFilter | string): void
  // using a promise to fix a race condition that occurs when we run both set query string
  // functions at the same time
  setBrandsFilterQueryString(queryString: string): Promise<null>
}

type IndustryFilterContextType = {
  industriesFiltered: string[]
  addOrRemoveIndustryFilter(tag: IndustryFilter): void
  // using a promise to fix a race condition that occurs when we run both set query string
  // functions at the same time
  setIndustryFilterQueryString(queryString: string): Promise<null>
}

const BrandsFilterContext = React.createContext<BrandsFilterContextType>({
  brandsFiltered: [],
  addOrRemoveBrandFilter: () => {},
  setBrandsFilterQueryString: () => Promise.resolve(null),
})

const IndustryFilterContext = React.createContext<IndustryFilterContextType>({
  industriesFiltered: [],
  addOrRemoveIndustryFilter: () => {},
  setIndustryFilterQueryString: () => Promise.resolve(null),
})

export const FiltersContextProvider = ({ children }: { children: React.ReactNode }) => (
  <BrandsFilterContextProvider>
    {/* prettier-ignore */}
    <IndustryFilterContextProvider>
      {children}
    </IndustryFilterContextProvider>
  </BrandsFilterContextProvider>
)

const BrandsFilterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [brandsFilterQueryString, setBrandsFilterQueryString] = useUrlState('brands')

  const brandsFiltered = brandsFilterQueryString ? brandsFilterQueryString.split(',') : []

  const addOrRemoveBrandID = (brand: string) => {
    // .includes() was unreliable
    const isAnActiveFilter = brandsFiltered.some((filteredBrand: string) => brand === filteredBrand)

    if (isAnActiveFilter) {
      const newConnectionFilters = brandsFiltered.filter(
        (filteredBrand: string) => brand !== filteredBrand
      )

      setBrandsFilterQueryString(newConnectionFilters.join(','))
    } else {
      const newQueryString = brandsFilterQueryString ? `${brandsFilterQueryString},${brand}` : brand
      setBrandsFilterQueryString(newQueryString)
    }
  }

  const addOrRemoveBrandFilter = (brand: BrandFilter | string) => {
    if (typeof brand === 'string') {
      addOrRemoveBrandID(brand)
    } else {
      addOrRemoveBrandID(brand.id)
    }
  }

  return (
    <BrandsFilterContext.Provider
      value={{
        brandsFiltered,
        addOrRemoveBrandFilter,
        setBrandsFilterQueryString: (queryString) => {
          return new Promise((resolve) => {
            setBrandsFilterQueryString(queryString)
            resolve()
          })
        },
      }}
    >
      {children}
    </BrandsFilterContext.Provider>
  )
}

const IndustryFilterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [industriesFilteredQueryString, setIndustryFilterQueryString] = useUrlState('industries')

  const industriesFiltered = industriesFilteredQueryString
    ? industriesFilteredQueryString.split(',')
    : []

  const addOrRemoveIndustryID = (tag: string) => {
    // .includes() was unreliable
    const isAnActiveFilter = industriesFiltered.some(
      (filteredIndustry: string) => tag === filteredIndustry
    )

    if (isAnActiveFilter) {
      const newIndustryFilters = industriesFiltered.filter(
        (filteredIndustry: string) => tag !== filteredIndustry
      )

      setIndustryFilterQueryString(newIndustryFilters.join(','))
    } else {
      const newQueryString = industriesFilteredQueryString
        ? `${industriesFilteredQueryString},${tag}`
        : tag
      setIndustryFilterQueryString(newQueryString)
    }
  }

  const addOrRemoveIndustryFilter = (tag: IndustryFilter | string) => {
    if (typeof tag === 'string') {
      addOrRemoveIndustryID(tag)
    } else {
      addOrRemoveIndustryID(tag.id)
    }
  }

  return (
    <IndustryFilterContext.Provider
      value={{
        industriesFiltered,
        addOrRemoveIndustryFilter,
        setIndustryFilterQueryString: (queryString) => {
          return new Promise((resolve) => {
            setIndustryFilterQueryString(queryString)
            resolve()
          })
        },
      }}
    >
      {children}
    </IndustryFilterContext.Provider>
  )
}

export const useBrandsFilter = () => useContext(BrandsFilterContext)
export const useIndustryFilter = () => useContext(IndustryFilterContext)
