import React from 'react'
import styled from 'styled-components'
import { useWindowWidth } from 'shared-js/utilities/hooks'
import { isMobileWidth } from 'shared-js/utilities/browser'
import { withRedirectToFrontend2ByFeatureFlag } from 'components/common/redirect-to-frontend2'
import { DesktopFilterSection } from './filter'
import ChannelListSection from './channel-list-section'
import { FiltersContextProvider } from './context'
import { IndustriesProvider } from './filter/filter-by-industry/industries-provider'
import { ConnectedBrandsProvider } from './filter/filter-by-brand/connected-brands-provider'

const PageInner = () => {
  const windowWidth = useWindowWidth()
  return (
    <FiltersContextProvider>
      <IndustriesProvider>
        <ConnectedBrandsProvider>
          <Container>
            {!isMobileWidth(windowWidth) && <DesktopFilterSection />}
            <ChannelListSection />
          </Container>
        </ConnectedBrandsProvider>
      </IndustriesProvider>
    </FiltersContextProvider>
  )
}

export const Page = withRedirectToFrontend2ByFeatureFlag('frontend2_content_discover')(PageInner)

const Container = styled.div`
  max-width: 1024px;
  display: flex;
  margin: 0 auto;
`
