import { Modal } from 'components/common/modals'
import { remoteSearchMixinFactory } from 'components/common/search'
import { SelectObjects } from 'components/common/select-objects'
import { getViewableUsersQuery } from 'components/people/users/page'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'
import ChannelSharesState from 'state/channel-shares'
import createPaginatedStateContainer from 'state/pagination'
import UsersState from 'state/users'
import containerUtils from 'utilities/containers'
import { getIdFromApiUrl } from 'utilities/generic'
import {
  getDisplayNameFunction,
  GROUP_TAB,
  TEAM_TAB,
  USER_TAB,
  ROLE_TAB,
  AREA_TAB,
} from '../constants'
import PageState from '../state'

const USER_ROLE_NAMES = {
  COMPANY_ADMINS: 'company_admins',
  TEAM_MANAGERS: 'team_managers',
}

@reactMixin.decorate(remoteSearchMixinFactory(PageState.ActionCreators.setSearch))
class UserSelectorInner extends React.Component {
  state = {
    deselectUser: null,
  }

  onChange = (newValue, user) => {
    if (this.isDisabled(user)) {
      this.setState({ ...this.state, deselectUser: user })
      this.modal.show()
      return
    }
    return this.props.onChange(newValue, user)
  }

  isSelected = (obj) => {
    const enrolledIndirectly = this.isDisabled(obj)
    if (enrolledIndirectly) {
      return true
    }
    return this.props.isSelected(obj)
  }

  isDisabled = (obj) => {
    const access = obj.access_to_requested_channel
    if (
      access[AREA_TAB].length > 0 ||
      access[TEAM_TAB].length > 0 ||
      access[GROUP_TAB].length > 0 ||
      access[ROLE_TAB].length > 0
    ) {
      return true
    }
    return false
  }

  // Get Group, Team and Area enrollments.
  getIndirectEnrollments = (user) => {
    const access = user.access_to_requested_channel
    const names = [
      ...access[TEAM_TAB].map((a) => a.team__name),
      ...access[GROUP_TAB].map((a) => a.group__name),
      ...access[AREA_TAB].map((a) => a.area__name),
      ...access[ROLE_TAB].map((a) => this.getUserRoleTypeTrans(a.user_role_type)),
    ]

    return names.map((name) => (
      <div key={name} style={styles.teamOrGroup}>
        {name}
      </div>
    ))
  }

  getUserRoleTypeTrans = (role_type) => {
    if (role_type === USER_ROLE_NAMES.COMPANY_ADMINS) {
      return t('company_admins')
    }
    if (role_type === USER_ROLE_NAMES.TEAM_MANAGERS) {
      return t('team_managers')
    }
    return role_type
  }

  renderObject = (user) => {
    const disabled = this.isDisabled(user)
    return (
      <div
        style={{
          ...styles.row,
          ...(disabled ? styles.disabledRow : {}),
        }}
      >
        <div style={styles.name}>{getDisplayNameFunction(USER_TAB)(user)}</div>

        {disabled && (
          <div style={styles.teamsAndGroupsContainer}>{this.getIndirectEnrollments(user)}</div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div>
        <SelectObjects
          loadMore={this.props.loadMore}
          moreDataAvailable={this.props.moreDataAvailable}
          dataIsLoading={this.props.dataIsLoading}
          objects={this.props.users}
          renderObject={this.renderObject}
          isSelected={this.isSelected}
          isDisabled={this.isDisabled}
          onChange={this.onChange}
          value={this.props.value}
        />
        <Modal ref={(modal) => (this.modal = modal)} header={t('sorry')} basic message>
          <div>{t('channel_enrollments_inderect_enrollment_explainer')}</div>
          <div>
            <br />
            {this.state.deselectUser && this.getIndirectEnrollments(this.state.deselectUser)}
          </div>
        </Modal>
      </div>
    )
  }
}

export const UserSelector = createPaginatedStateContainer(UserSelectorInner, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [PageState.Store, UsersState.Store, ChannelSharesState.Store],

  paginate: {
    store: UsersState.Store,
    propName: 'users',
    dependantOn: [ChannelSharesState.Store],
    limit: 20,
    getQuery() {
      const learner = this.context.currentUser.learner
      const query = {
        ordering: 'first_name,last_name',
        fields: ['id', 'first_name', 'last_name', 'access_to_requested_channel'],
        is_active: true,
        access_to_requested_channel: this.props.channel.id,
      }

      if (!learner.can_manage_training_content) {
        query.learner__learnergroups = getIdFromApiUrl(learner.learner_group)
      }

      const search = PageState.Store.getSearch()
      if (search) {
        query.search = search
        query.ordering = '-search_rank'
      }

      return getViewableUsersQuery(query, this.context.currentUser)
    },
  },

  UNSAFE_componentWillMount() {
    PageState.Store.resetState()
  },

  show() {
    this.innerComponent.show()
  },

  pending() {
    return containerUtils.defaultPending(this, UserSelectorInner)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, UserSelectorInner, errors)
  },
})

const styles = {
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  disabledRow: {},
  name: {
    marginRight: 10,
  },
  teamsAndGroupsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  teamOrGroup: {
    display: 'inline-block',
    border: '1px solid #aaa',
    padding: '2px 5px',
    borderRadius: 3,
    marginLeft: 3,
  },
}
