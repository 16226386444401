/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-literals */
import PropTypes from 'prop-types'
import React from 'react'
import UsersState from 'state/users'
import PublicCompanies from 'state/public-companies'
import JoinCompanyRequestsState from 'state/join-company-requests'
import { withApollo } from 'react-apollo'
import { MAIN_DOMAIN } from 'core/constants'
import { Modal } from 'components/common/modals/index'
import { AsyncSearchableSelect } from 'components/common/form-deprecated/select'
import { CompanySearchableSelect } from 'components/common/company-searchable-select'

export class ChangeCompanyInner extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      companyURL: null,
    }
  }

  onCompanySelect = (val) => {
    this.setState({ companyURL: val })
  }

  changeCompany = () => {
    this.setState({ isLoading: true })
    if (!this.state.companyURL) return
    const userId = this.props.user.id

    UsersState.ActionCreators.doDetailAction(userId, 'change_user_to_new_company', {
      company_url: this.state.companyURL,
    })
      .then(() => {
        this.setState({ isLoading: false })

        const protocol = window.location.protocol
        const hostname = window.location.hostname
        const currentSubdomain = hostname.split('.')[0]

        if (process.env.NODE_ENV === 'production' && currentSubdomain !== 'staging') {
          window.location.href = `${protocol}//${MAIN_DOMAIN}/views/myagi-staff/user-management/${userId}/`
        }

        JoinCompanyRequestsState.ActionCreators.doDetailAction(
          this.props.user.pending_sent_request,
          'close'
        )
        this.props.client.resetStore()
      })
      .catch((err) => {
        console.log(err)
      })
    this.modal.hide()
    this.context.displayTempPositiveMessage({
      heading: 'Changing company...',
    })
  }

  render() {
    return (
      <div>
        <button type="button" className="ui blue button" onClick={() => this.modal.show()}>
          Change Company
        </button>
        <Modal
          ref={(el) => (this.modal = el)}
          header={`Move ${this.props.user.full_name} to a new company`}
          closeOnDimmerClick={false}
        >
          <div className="content">
            <CompanySearchableSelect
              ref="coSelect"
              name="coURL"
              onChange={this.onCompanySelect}
              internalUse
            />
            <button
              type="button"
              className="ui red button"
              onClick={this.changeCompany}
              disabled={!this.state.companyURL}
            >
              {`Yes, move ${this.props.user.full_name}`}
              {this.state.isLoading && <div className="ui active small inline loader" />}
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}

// use withApollo to get client to reset store on company change
export const ChangeCompany = withApollo(ChangeCompanyInner)
