import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'

import moment from 'moment-timezone'
import containerUtils from 'utilities/containers'
import { LoadingSpinner } from 'components/common/loading'

import UsersState from 'state/users'
import LearnersState from 'state/learners'
import AdminTeamsState from 'state/admin-teams'

import { ButtonToggle } from 'components/common/form-deprecated'
import { ChangeCompany } from './change-company'
import { ChangeTeam } from './change-team'
import { ChangePassword } from './change-password'
import { MergeAttemptData } from './merge-attempt-data'
import { Hijack } from './hijack'
import { EraseUser } from './erase-user'
import { SyncFeeds } from './sync-feeds'

const YES = 'Yes'
const NO = 'No'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f6f6f6',
    padding: 20,
  },
  userHeader: {
    paddingBottom: 20,
    borderBottom: '1px solid black',
    textAlign: 'center',
  },
  containerInner: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 20px',
  },
  buttonPadding: {
    margin: '10px 0px',
  },
}

class UserDetails extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  toggleGroupMembership(u, isMember, groupName) {
    const method = isMember ? 'remove_from_group' : 'add_to_group'
    UsersState.ActionCreators.doDetailAction(
      u.id,
      method,
      { name: groupName },
      { query: { fields: ['*'] } }
    ).catch((err) => {
      const message = err.response.body.message
      this.context.displayTempNegativeMessage({
        heading: 'Error',
        body: message,
      })
    })
  }

  toggleTeamManagerStatus = () => {
    const u = this.props.user
    this.toggleGroupMembership(u, u.learner.is_learner_group_admin, 'team_managers')
  }

  toggleCompanyAdminStatus = () => {
    const u = this.props.user
    this.toggleGroupMembership(u, u.learner.is_company_admin, 'company_admins')
  }

  toggleParentCompanyAdminStatus = () => {
    const u = this.props.user
    LearnersState.ActionCreators.update(u.learner.id, {
      is_parent_company_admin: !u.learner.is_parent_company_admin,
    }).then((res) => {
      UsersState.Store.clearRecentFetches()
      UsersState.Store.hasChanged()
      UsersState.Store.resetState()
    })
  }

  render() {
    const user = this.props.user
    let company
    let companyId
    let userDetails = []

    if (user) {
      company = user.company
      if (company) companyId = company.id
      userDetails = [
        { ID: user.id },
        {
          Company: company ? (
            <span>
              <a href={`/views/myagi-staff/company/${companyId}/admin/`}>
                <b>{user.company.name}</b>
                {' '}
                {companyId}
              </a>
            </span>
          ) : (
            'No Company'
          ),
        },
        { 'Date joined': moment(user.date_joined).toString() },
        { 'Last activity': moment(user.learner.last_activity).toString() },
        { 'Lessons completed': user.learner.num_modules_completed },
        {
          Team: user.learner.learnergroup_name ? (
            <b>{user.learner.learnergroup_name}</b>
          ) : (
            'No Team'
          ),
        },
        {
          'Team Manager': (
            <ButtonToggle
              name="learner_group_admin"
              leftLabel={YES}
              rightLabel={NO}
              initialValue={user.learner.is_learner_group_admin ? YES : NO}
              onChange={this.toggleTeamManagerStatus}
              initialIsAcceptable
            />
          ),
        },
        {
          'Company Admin': (
            <ButtonToggle
              name="company_admin"
              leftLabel={YES}
              rightLabel={NO}
              initialValue={user.learner.is_company_admin ? YES : NO}
              onChange={this.toggleCompanyAdminStatus}
              initialIsAcceptable
            />
          ),
        },
        {
          'Parent Company Admin': (
            <ButtonToggle
              name="company_admin"
              leftLabel={YES}
              rightLabel={NO}
              initialValue={user.learner.is_parent_company_admin ? YES : NO}
              onChange={this.toggleParentCompanyAdminStatus}
              initialIsAcceptable
            />
          ),
        },
      ]
    }

    return (
      <div style={styles.container} key={user.id}>
        <h2 style={styles.userHeader} className="fs-sensitive">
          {user.first_name}
          {' '}
          {user.last_name}
          {' '}
-
          {' '}
          {user.email}
        </h2>
        <div style={styles.containerInner}>
          <div>
            {userDetails.map((i) => {
              const key = Object.keys(i)[0]
              const val = i[key]
              return (
                <div key={key} style={{ marginBottom: 25 }}>
                  {`${key}: `}
                  {val}
                </div>
              )
            })}
          </div>
          <div style={styles.buttonContainer}>
            <div style={styles.buttonPadding}>
              <ChangeCompany user={this.props.user} />
            </div>
            <div style={styles.buttonPadding}>
              <ChangeTeam user={this.props.user} companyId={companyId} />
            </div>
            <div style={styles.buttonPadding}>
              <ChangePassword user={this.props.user} />
            </div>
            <div style={styles.buttonPadding}>
              <MergeAttemptData user={this.props.user} />
            </div>
            {!this.context.currentUser.is_hijacked_user &&
            this.context.currentUser.id !== this.props.user.id ? (
              <div style={styles.buttonPadding}>
                  <Hijack user={this.props.user} />
                </div>
              ) : null}
            {!(user.full_name === 'Anonymized User') && (
              <div style={styles.buttonPadding}>
                <EraseUser user={this.props.user} />
              </div>
            )}
            <div style={styles.buttonPadding}>
              <SyncFeeds user={this.props.user} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const Page = Marty.createContainer(UserDetails, {
  contextTypes: {
    currentUser: PropTypes.instanceOf(Im.Map).isRequired,
    routeParams: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  },

  listenTo: [UsersState.Store, AdminTeamsState.Store],

  fetch: {
    user() {
      if (this.props.params.userId) {
        return UsersState.Store.getItem(this.props.params.userId, {
          fields: [
            'first_name',
            'last_name',
            'id',
            'email',
            'last_login',
            'date_joined',
            'company.id',
            'company.name',
            'learner.id',
            'learner.last_activity',
            'learner.num_modules_completed',
            'learner.learnergroup_name',
            'learner.is_learner_group_admin',
            'learner.is_company_admin',
            'learner.is_parent_company_admin',
            'pending_sent_request',
          ],
        })
      }
      return null
    },
  },

  pending() {
    return <LoadingSpinner />
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, UserDetails, errors)
  },
})
