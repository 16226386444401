import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import Style from 'style/index'

import { qs, parseJwt } from 'utilities/http'

import { t } from 'i18n'

import PublicUsersState from 'state/public-users'

import { ThinBox } from 'components/common/box'
import { Form, SubmitButton } from 'components/common/form-deprecated'

import MYAGI_LOGO from 'img/logo.svg'

const styles = {
  container: {
    background: Style.colors.white,
    color: Style.colors.grey800,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    minHeight: 650,
    width: '100vw',
  },
  formTitle: {
    fontSize: 15,
    marginTop: 0,
    color: Style.colors.grey800,
    opacity: 0.4,
    textAlign: 'center',
  },
  formContainer: {
    maxWidth: 400,
    width: '100%',
    margin: 'auto',
    marginTop: 0,
    borderRadius: 5,
  },
  form: {
    paddingLeft: 50,
    paddingRight: 50,
    marginBottom: 30,
  },
  submitButton: {
    height: 50,
    background: Style.colors.primary,
    minWidth: 200,
    marginTop: 30,
    lineHeight: 2,
    color: Style.colors.white,
  },
  logo: {
    margin: '12vh auto 15px auto',
    height: 150,
    width: 220,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  successContainer: {
    textAlign: 'center',
    color: Style.colors.green400,
    padding: '15px 30px',
  },
  errorContainer: {
    textAlign: 'center',
    color: Style.colors.amber900,
    padding: '15px 30px',
  },
  wrongEmail: {
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
  },
}

export class UnsubscribePage extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  constructor() {
    super()
    this.state = {
      successful: false,
      failed: false,
    }
  }

  onSubmitAndValid = async (data) => {
    const token = qs('token')
    try {
      await PublicUsersState.ActionCreators.doListAction('unsubscribe_notifications', { token })
      this.setState({ successful: true, failed: false })
    } catch (err) {
      console.error('error', err)
      this.setState({ successful: false, failed: true })
    }
  }

  render() {
    const token = qs('token')
    const payload = parseJwt(token)

    return (
      <div style={styles.container}>
        <div style={{ ...styles.logo, backgroundImage: `url(${MYAGI_LOGO})` }} />

        <div style={styles.formTitle}>
          <h3>{t('unsubscribe_all_notifications')}</h3>
        </div>
        <br />

        <div style={styles.formContainer}>
          {!this.state.successful && (
            <Form onSubmitAndValid={this.onSubmitAndValid} style={styles.form}>
              <SubmitButton text={t('unsubscribe')} style={styles.submitButton} />
            </Form>
          )}

          {this.state.successful && (
            <div style={styles.successContainer}>
              {`${t('success')}. ${t('all_notifications_disabled')}.`}
            </div>
          )}

          {this.state.failed && (
            <div style={styles.errorContainer}>
              {`${t('error_unsubscribing')}. ${t('please_try_again')}.`}
            </div>
          )}

          <div style={styles.wrongEmail}>
            <span className="fs-sensitive">
              {t('you_will_no_longer_receive_emails')}
              <br />
              <a href={`mailto:${payload.email}`}>{payload.email}</a>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export class Page extends React.Component {
  render() {
    return (
      <ThinBox style={styles.container}>
        <UnsubscribePage />
      </ThinBox>
    )
  }
}
