import { t } from 'i18n'
import React from 'react'
import gql from 'fraql'
import { PrimaryButton } from 'components/common/buttons'
import { Info } from 'components/common/info'
import Style from 'style'
import { ExtractFragmentsFromData } from 'shared-js/state/graphql'
import { Icon } from 'semantic-ui-react'
import moment from 'moment'
import { CompanyAvatar } from 'shared-js/components/common/avatar'
import PropTypes from 'prop-types'
import { WithCommonRouter } from 'shared-js/routing'
import { ReplyMessageCard } from './reply-message-card'
import { FeedbackReply } from './feedback-conversation-creation'
import { UserRemovedText } from './index'

const NORMAL_WEIGHT = 500
const LIGHT_WEIGHT = 200
const CUSTOM_BOLD_WEIGHT = 600
// all other text sizes will be em ratios of this parent size
const PARENT_TEXT_SIZE = '12px'
const MIN_CARD_WIDTH = '275px'

@WithCommonRouter
class FeedbackCardInner extends React.Component {
  static propTypes = {
    feedback: PropTypes.object,
    commonRouter: PropTypes.object,
    currentUser: PropTypes.object,
  }

  static data = {
    feedback: {
      required: false,
      fragment: gql`
        fragment _ on ModuleFeedbackType {
          id
          likeRating
          learnRating
          extraComments
          created
          module {
            id
            name
            company {
              id
            }
          }
          user {
            id
            firstName
            lastName
            profilePhoto
            company {
              id
              name
              companyLogo
            }
            team {
              id
              name
            }
          }
          responseMessage {
            id
            created
            author {
              id
              firstName
              lastName
              profilePhoto
              company {
                id
                name
                companyLogo
              }
            }
          }
        }
      `,
    },
  }

  constructor() {
    super()
    this.state = {
      isReply: false,
      showReply: false,
    }
  }

  handleReply = () => {
    this.setState({ isReply: true })
  }

  handleReplyCancel = () => {
    this.setState({ isReply: false })
  }

  showReply = () => {
    this.setState({ showReply: true, isReply: false })
  }

  hideReply = () => {
    this.setState({ showReply: false, isReply: false })
  }

  navigateToLesson = () => {
    const { commonRouter } = this.props
    const lessonId = this.props.feedback.module.id
    commonRouter.trigger('goToLesson', lessonId)
  }

  validateCompany = (feedback) => {
    const { id, user } = feedback
    const errorMessage = `RE: Feedback with id:${id} \nFeedback left by company-less users should not be visible.
      Check to see if queryvar \"companyRequired\" = true or that the user exists`
    if (!user || !user.company) {
      console.error(errorMessage)
      throw Error(errorMessage)
    }
  }

  renderReplyBox = (feedbackToRespond) => {
    const { isReply, showReply } = this.state
    const { currentUser } = this.props
    return isReply && !showReply ? (
      <div style={styles.replyBox}>
        <FeedbackReply
          feedback={feedbackToRespond}
          lesson={feedbackToRespond.module}
          currentUser={currentUser}
          cancelReply={this.handleReplyCancel}
          showReply={this.showReply}
        />
      </div>
    ) : null
  }

  renderReplyButton = (responseMessage, ownsLesson) =>
    responseMessage || this.state.isReply ? null : (
      <Info style={styles.info} content={t('cant_reply_to_external_lesson')} disabled={ownsLesson}>
        <PrimaryButton
          style={{ ...styles.btn, ...(!ownsLesson && styles.btnDisabled) }}
          disabled={!ownsLesson}
          onClick={ownsLesson && this.handleReply}
        >
          <Icon name="reply" size="small" style={styles.replyIcon} />
          {t('reply')}
        </PrimaryButton>
      </Info>
    )

  renderShowResponseButton = (responseMessage) => {
    const { author, created } = responseMessage
    const { company } = author
    const responseTime = moment(created).format('MMM D')
    const text = `${t('responded')} - ${responseTime}`
    const showOrHideButton = this.state.showReply ? this.hideReply : this.showReply
    return (
      <div style={styles.showResponseButton} onClick={showOrHideButton}>
        <CompanyAvatar company={company} canNavigateToProfile={false} size={16} isRound />
        <HoverUnderlineText style={styles.showOrHideButtonText} text={text} />
      </div>
    )
  }

  renderHideResponseButton = () => {
    const text = t('hide_response')
    const showOrHideButton = this.state.showReply ? this.hideReply : this.showReply
    return (
      <div onClick={showOrHideButton} style={styles.hideResponseButton}>
        <HoverUnderlineText style={styles.showOrHideButtonText} text={text} />
      </div>
    )
  }

  renderViewOrHideMessageButton(responseMessage) {
    if (!this.state.showReply && (!responseMessage || this.state.isReply)) {
      return null
    }
    // quick fix for responses from users that have no company
    if (responseMessage && !responseMessage.author.company) {
      return <UserRemovedText />
    }
    return (
      <div>
        {this.state.showReply
          ? this.renderHideResponseButton()
          : this.renderShowResponseButton(responseMessage)}
      </div>
    )
  }

  renderLessonTitle = (lessonName) => (
    <HoverUnderlineText
      style={styles.lessonHeader}
      text={lessonName}
      onClick={this.navigateToLesson}
    />
  )

  renderReplyMessage = (feedbackId) =>
    this.state.showReply ? <ReplyMessageCard feedbackId={feedbackId} /> : null

  renderRating = (rating) => (
    <div style={{ display: 'inline-block' }}>
      <Icon name="star outline" size="small" style={styles.ratingIcon} />
      <span style={styles.ratingText}>{rating}</span>
    </div>
  )

  renderStats(feedback) {
    const { likeRating, learnRating } = feedback
    const likeTitle = t('like')
    const learnTitle = t('learn')
    return (
      <div style={styles.statsFrame}>
        <div style={styles.statsContainer}>
          <div>
            <span style={styles.ratingText}>{`${likeTitle}:`}</span>
            {this.renderRating(likeRating)}
          </div>
          <div>
            <span style={styles.ratingText}>{`${learnTitle}:`}</span>
            {this.renderRating(learnRating)}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { feedback } = this.props
    const { extraComments, user, responseMessage } = feedback
    const { firstName, company, team } = user
    const teamName = team ? `(${team.name})` : ''
    const companyName = company ? company.name : ''
    const feedbackCreatedRelative = moment(feedback.created)
      .startOf('day')
      .fromNow()
    this.validateCompany(feedback)
    const ownsLesson =
      this.props.feedback &&
      this.props.feedback.module.company.id == this.props.currentUser.company.id

    return (
      <div style={styles.container}>
        <div style={styles.feedbackContainer}>
          {this.renderLessonTitle(feedback.module.name)}
          <div style={styles.headerRow}>
            <div style={styles.subject}>
              <CompanyAvatar company={user.company} canNavigateToProfile={false} isRound />
              <div style={styles.subejectText}>
                <span style={styles.companyName}>{`${firstName} @${companyName} ${teamName}`}</span>
                <span style={styles.relativeCreated}>{feedbackCreatedRelative}</span>
              </div>
            </div>
            {this.renderStats(feedback)}
          </div>
          <div style={styles.body}>
            <p style={styles.comment}>{extraComments}</p>
          </div>
          <div style={styles.replyInfo}>
            {this.renderReplyButton(responseMessage, ownsLesson)}
            {this.renderViewOrHideMessageButton(responseMessage)}
          </div>
        </div>
        {this.renderReplyMessage(feedback.id)}
        {this.renderReplyBox(feedback)}
      </div>
    )
  }
}

export const FeedbackCard = ExtractFragmentsFromData(FeedbackCardInner)

class HoverUnderlineText extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
  }

  constructor() {
    super()
    this.state = {
      hover: false,
    }
  }

  handleMouseEnter = () => {
    this.setState({ hover: true })
  }

  handleMouseLeave = () => {
    this.setState({ hover: false })
  }

  render() {
    const { text } = this.props
    const style = this.props.style ? this.props.style : {}
    const onClick = this.props.onClick ? this.props.onClick : null
    const hoverStyle = this.state.hover ? { ...style, textDecoration: 'underline' } : style
    return (
      <span
        style={hoverStyle}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={onClick}
      >
        {text}
      </span>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: MIN_CARD_WIDTH,
  },
  feedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: 'white',
    border: '1px solid rgb(229,229,229)',
    boxShadow: 'rgb(0,0,0,0.18) 4px 3px 20px',
    margin: '10px 0px',
    borderRadius: '2px',
    padding: '8px 20px',
    fontSize: PARENT_TEXT_SIZE,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: '1px',
    borderColor: '#aaaaaa',
  },
  lessonHeader: {
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: CUSTOM_BOLD_WEIGHT,
    fontFamily: 'Open Sans,  sans-serif',
    fontSize: '0.8vw',
    width: 'auto',
    minHeight: '20px',
    maxHeight: '20px',
    margin: 0,
    padding: 0,
    color: '#aaaaaa',
    cursor: 'pointer',
  },
  subject: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '5px',
    width: 'auto',
  },
  subejectText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    marginLeft: '10px',
    fontFamily: 'Open Sans,  sans-serif',
    width: '100%',
  },
  companyName: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  relativeCreated: {
    fontSize: '10px',
    fontWeight: LIGHT_WEIGHT,
    color: '#aaaaaa',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  comment: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    marginTop: '10px',
    marginLeft: '50px',
    marginRight: '10%',
    marginBottom: '10px',
    color: Style.vars.colors.grey900,
  },
  statsFrame: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: 'auto',
    minWidth: '20%',
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    justifySelf: 'center',
  },
  rating: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  ratingText: {
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: NORMAL_WEIGHT,
    fontFamily: 'Open Sans,  sans-serif',
    fontSize: '1.25em',
    marginRight: '5px',
    minWidth: '100%',
  },
  showResponseButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    cursor: 'pointer',
    marginLeft: '15px',
  },
  showOrHideButtonText: {
    verticalAlign: 'baseline',
    marginLeft: '5px',
    color: Style.vars.colors.cyan600,
    fontSize: 12,
    lineHeight: '16px',
  },
  replyInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  replyBox: {
    width: '90%',
    alignSelf: 'flex-end',
  },
  btn: {
    minWidth: '70px',
    maxWidth: '70px',
    minHeight: '20px',
    maxHeight: '20px',
    textAlign: 'left',
    padding: '0px',
    alignSelf: 'flex-start',
    backgroundColor: 'white',
    color: 'black',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  btnDisabled: {
    color: '#aaa',
  },
  replyIcon: {
    color: 'inherit',
    fontSize: '1em',
  },
  replyCheck: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '100%',
    marginBottom: '10px',
  },
  hideResponseButton: {
    cursor: 'pointer',
    marginLeft: '15px',
  },
  ratingIcon: {
    color: Style.vars.colors.amber900,
    fontSize: '1.25em',
  },
}
