import MUIFormControlLabel from '@material-ui/core/FormControlLabel'
import MUIFormLabel from '@material-ui/core/FormLabel'
import MUIRadio from '@material-ui/core/Radio'
import MUIRadioGroup from '@material-ui/core/RadioGroup'
import MUIFormControl from '@material-ui/core/FormControl'
import MUIFormGroup from '@material-ui/core/FormGroup'

export const FormControlLabel = MUIFormControlLabel
export const FormLabel = MUIFormLabel
export const FormControl = MUIFormControl
export const Radio = MUIRadio
export const RadioGroup = MUIRadioGroup
export const FormGroup = MUIFormGroup
