import styled from 'styled-components'

export const Container = styled.div`
  margin: 0px 24px;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey900};
  line-height: 1.4;
  margin-bottom: 12px;
`

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
`

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 28px;
  box-shadow: ${({ theme }) => `0 -2px 2px 0 ${theme.colors.grey200}`};
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    padding: 14px 24px;
  }
`

export const CancelButtonContainer = styled.div`
  padding-right: 16px;
`
