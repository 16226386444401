import Im from 'shared-js/immutable'
import Marty from 'marty'
import _ from 'lodash'
import app from 'core/application'
import moment from 'moment'
import { momentToISO } from 'utilities/time'

const Constants = Marty.createConstants([
  'TRAINING_LIST_SET_FILTER',
  'TRAINING_LIST_SET_ENROLL_MODE',
  'TRAINING_LIST_SELECT_FOR_ENROLLMENT',
  'TRAINING_LIST_SET_TRAINING_PLAN_SEARCH',
  'TRAINING_LIST_SET_TRAINING_PLAN_ID_FILTER',
])

export const filterNames = {
  ALL_PLANS: 'all_plans',
  COMPANY_PLANS: 'company_plans',
  TEAM_PLANS: 'team_plans',
  MY_TEAM_PLANS: 'my_team_plans',
  PLANS_FROM_CHANNELS: 'external_plans',
  ARCHIVED_PLANS: 'archived_plans',
}

const PlanListActionCreators = Marty.createActionCreators({
  id: 'PlanListActionCreators',
  setFilter(filterName) {
    this.dispatch(Constants.TRAINING_LIST_SET_FILTER, filterName)
  },
  setTrainingPlanSearch(searchTerm) {
    this.dispatch(Constants.TRAINING_LIST_SET_TRAINING_PLAN_SEARCH, searchTerm)
  },
  setTrainingPlanIdFilter(str) {
    this.dispatch(Constants.TRAINING_LIST_SET_TRAINING_PLAN_ID_FILTER, str)
  },
})

const PlanListStore = Marty.createStore({
  id: 'PlanListStore',

  handlers: {
    onFilter: Constants.TRAINING_LIST_SET_FILTER,
    onSetTrainingPlanSearch: Constants.TRAINING_LIST_SET_TRAINING_PLAN_SEARCH,
    onSetTrainingPlanIdFilter: Constants.TRAINING_LIST_SET_TRAINING_PLAN_ID_FILTER,
  },

  getInitialState() {
    return {
      filterKey: null,
      filters: [],
      enrollModeEnabled: false,
      selectedForEnrollment: Im.freeze({}),
      search: '',
      planIds: '',
      events: [],
    }
  },

  onFilter(key) {
    this.clearTrainingPlanFilter()
    this.state.filterKey = key
    this.hasChanged()
  },

  onSetTrainingPlanSearch(str) {
    this.clearTrainingPlanFilter()
    this.state.search = str
    this.hasChanged()
  },

  onSetTrainingPlanIdFilter(str) {
    // set filter for training plan id in query string
    this.state.planIds = str
    this.hasChanged()
  },

  clearTrainingPlanFilter() {
    // We want to get rid of any filtering by plan id if the user tries
    // to apply another filter or search.
    if (!this.state.planIds) return
    this.onSetTrainingPlanIdFilter('')
    history.replaceState({}, '', window.location.pathname)
    this.hasChanged()
  },

  getTrainingPlanIDFilter() {
    return this.state.planIds
  },

  getCurrentFilterQuery(currentUser, initialValue) {
    const allFilters = this.setupFiltersForUser(currentUser, initialValue)
    const filterDesc = _.find(allFilters, { name: this.state.filterKey })
    return filterDesc.query
  },

  getFilterNames() {
    return _.map(this.state.filterCache, 'name')
  },

  getFiltersForUser(currentUser) {
    const learner = currentUser.learner
    const filters = []
    filters.push({
      name: filterNames.ALL_PLANS,
      query: {
        ordering: 'name',
      },
    })
    filters.push({
      name: filterNames.COMPANY_PLANS,
      query: {
        owner: currentUser.company.id,
        ordering: 'name',
      },
    })
    filters.push({
      name: filterNames.PLANS_FROM_CHANNELS,
      query: {
        shared_with: currentUser.company.id,
        exclude_owner: currentUser.company.id,
        ordering: 'name',
      },
    })
    if (learner.can_manage_training_content) {
      filters.push({
        name: filterNames.ARCHIVED_PLANS,
        query: {
          show_deactivated: true,
          owner: currentUser.company.id,
          ordering: '-deactivated',
        },
      })
    }
    return filters
  },

  getDescriptionForFilter(filterName) {
    return FILTER_DESCRIPTIONS[filterName]
  },

  getPlanDueSoonDatetime() {
    const now = moment()
    return momentToISO(now.add(...SOON_TIME_SPAN))
  },

  setupFiltersForUser(currentUser, initialValue) {
    if (!this.state.filterCache) {
      this.state.filterCache = this.getFiltersForUser(currentUser)
      this.state.filterKey = initialValue || this.state.filterCache[0].name
    }
    return this.state.filterCache
  },

  getTrainingPlanSearch() {
    return this.state.search
  },

  getCurrentFilterName() {
    return this.state.filterKey
  },

  resetState() {
    this.state = this.getInitialState()
  },
})

app.register('PlanListStore', PlanListStore)
app.register('PlanListActionCreators', PlanListActionCreators)

export default {
  Constants,
  ActionCreators: app.PlanListActionCreators,
  Store: app.PlanListStore,
}
