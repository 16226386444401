import React from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import Style from 'shared-js/style'
import { Placeholder } from 'shared-js/components/common/placeholder'
import { getDueDetails } from 'shared-js/utilities/time'
import { NewIndicator } from 'shared-js/components/common/indicators'
import { ProgressBar } from 'components/common/progress-bar'
import PLACEHOLDER_IMAGE from 'img/placeholder.svg'
import { LearnerPlanCard_plan } from './gql-types/LearnerPlanCard_plan'

const WIDTH = 330
const HEIGHT = (9 / 16) * WIDTH // ratio should be 16:9 with width

type Props = {
  plan: LearnerPlanCard_plan,
}

export const LearnerPlanCard = ({ plan }: Props) => {
  const { dueText, overdue } = getDueDetails(plan.nextDueDateForUser)
  return (
    <Container>
      <TrainingPlanImage src={plan.thumbnailUrl || PLACEHOLDER_IMAGE}>
        {plan.hasNewLessonsForCurrentUser && <NewIndicator containerStyle={{}} />}
      </TrainingPlanImage>
      {/*
      //@ts-ignore: seems to be inferring types incorrectly from js */}
      <ProgressBar progress={plan.progressForUser} asPercentage />
      <CompanyRow>
        <CompanyLogo src={plan.owner && plan.owner.companyLogo} />
        <CompanyName>{plan.owner && plan.owner.name}</CompanyName>
      </CompanyRow>
      <PlanName>{plan.name}</PlanName>
      {dueText && <DueText overdue={overdue}>{dueText}</DueText>}
    </Container>
  )
}

export const LearnerPlanCardLoading = () => (
  <LoadingContainerPadding>
    <Placeholder.Box height={HEIGHT} width={WIDTH} animate="fade" />
  </LoadingContainerPadding>
)

LearnerPlanCard.fragment = gql`
  fragment LearnerPlanCard_plan on TrainingPlanType {
    id
    name
    thumbnailUrl
    nextDueDateForUser
    progressForUser
    hasNewLessonsForCurrentUser
    owner {
      id
      companyLogo
      name
    }
  }
`

const Container = styled.div`
  width: ${WIDTH}px;
  display: flex;
  flex-direction: column;
  position: relative; /* for new indicator */
`

const TrainingPlanImage = styled.div<{ src: string }>`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  background-image: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
`

const CompanyRow = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`

const CompanyLogo = styled.img`
  max-width: 26px;
  max-height: 20px;
  margin-right: 5px;
`

const CompanyName = styled.div`
  font-size: 12px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #888;
`

const PlanName = styled.div`
  margin: 5px 15px 5px 0;
  font-size: 18px;
  color: black;
`

const LoadingContainerPadding = styled.div`
  margin-bottom: 80px;
`

const DueText = styled.div<{ overdue: boolean }>`
  color: ${({ overdue }) => (overdue ? Style.colors.amber800 : 'black')};
`
