import React from 'react'
import Im from 'shared-js/immutable'
import Style from 'style'

const styles = {
  repContainer: {
    marginTop: 20,
  },
  repFrame: {
    marginTop: 10,
    border: 'none',
  },
  plotContainer: {
    marginTop: 20,
    border: `1px solid ${Style.vars.deprecatedColors.fadedOffBlack}`,
    backgroundColor: 'white',
  },
  btnContainer: {
    width: '100%',
    height: 50,
  },
  btn: {
    marginRight: 10,
  },
}

export class Report extends React.Component {
  static data = {
    report: {
      required: false,
      fields: ['report_url', 'share_token', 'plot_set.id', 'plot_set.data', 'plot_set.layout'],
    },
  }

  renderPlot = (plot) => <Plot key={plot.id} plot={Im.freeze(plot)} />

  render() {
    const url = this.props.report.report_url
    return <div style={styles.repContainer}>{this.props.report.plot_set.map(this.renderPlot)}</div>
  }
}
