import React from 'react'
import { SwitchAvatar } from 'shared-js/components/common/avatar'
import PropTypes from 'prop-types'
import Style from 'shared-js/style/native'
import ConversationListItemHOC from 'shared-js/components/comms/conversation-list'

import { NoData } from 'shared-js/components/common/loading'
import { Placeholder } from 'shared-js/components/common/placeholder'

export function ConversationNoData() {
  return <NoData />
}

export function ConversationPlaceholder() {
  return (
    <div style={styles.placeholderContainer}>
      <Placeholder.Box
        height={60}
        width={300}
        style={styles.placeholder}
        radius={10}
        animate="fade"
        color={Style.vars.deprecatedColors.mediumGrey}
      />
    </div>
  )
}

function UnseenMessageCount(props) {
  if (!props.unseenCountForUser) {
    return null
  }
  return (
    <div style={styles.unseenCountContainer}>
      <p style={styles.unseenCountText}>{props.unseenCountForUser}</p>
    </div>
  )
}

function ConversationTimestamp(props) {
  return <p style={styles.timestamp}>{props.timestamp}</p>
}

function navigateToConversation(conversation, router) {
  router.push({
    pathname: `/views/inbox/conversations/${conversation.id}/`,
  })
}

function ConversationListItem(props, context) {
  const {
    conversation,
    conversationSubject,
    isFromCurrentUserCompany,
    lastMessagePreview,
    timestamp,
    hasUnseenMessages,
  } = props

  const boldText = hasUnseenMessages ? styles.unseenBoldText : {}
  return (
    <div style={styles.conversationListItem}>
      <div
        onClick={() => navigateToConversation(conversation, context.router)}
        style={styles.conversationContents}
      >
        <div style={styles.avatar}>
          <SwitchAvatar
            canNavigateToProfile={false}
            isFromCurrentUserCompany={isFromCurrentUserCompany}
            authorUser={props.conversation.createdBy}
            size={50}
          />
        </div>
        <div style={{ ...styles.messagePreview, ...styles.messageInfo }}>
          <p
            style={{
              ...(hasUnseenMessages ? styles.unseenBoldText : styles.boldText),
              ...styles.messageFromText,
            }}
          >
            {conversationSubject}
          </p>
          <p style={{ ...boldText, ...styles.messagePreviewText }}>{lastMessagePreview}</p>
        </div>
        <div style={{ ...styles.timestampContainer, ...styles.messageInfo }}>
          <ConversationTimestamp timestamp={timestamp} />
          <UnseenMessageCount unseenCountForUser={conversation.unseenCountForUser} />
        </div>
      </div>
    </div>
  )
}
ConversationListItem.contextTypes = {
  currentUser: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
}

export default ConversationListItemHOC(ConversationListItem, ConversationPlaceholder)

const styles = {
  conversationListItem: {
    padding: 10,
    paddingRight: 0,
  },
  conversationContents: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  avatar: {},
  placeholderContainer: {
    display: 'flex',
  },
  placeholder: {
    marginBottom: 5,
  },
  messagePreview: {
    flexGrow: 1,
    flex: 1,
    marginLeft: 10,
    flexWrap: 'wrap',
  },
  timestampContainer: {
    paddingRight: 10,
  },
  messageInfo: {
    paddingBottom: 10,
    borderBottomColor: Style.vars.deprecatedColors.darkGrey,
    borderBottomWidth: 1,
  },
  timestamp: {
    color: Style.vars.deprecatedColors.xxDarkGrey,
    marginTop: 5,
    fontSize: 11,
  },
  unseenCountContainer: {
    backgroundColor: Style.vars.deprecatedColors.primary,
    width: 20,
    height: 20,
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 5,
    marginTop: 5,
  },
  unseenCountText: {
    fontSize: 11,
    fontWeight: '600',
    color: Style.vars.deprecatedColors.primaryFontColor,
  },
  boldText: {
    fontWeight: '500',
  },
  unseenBoldText: {
    fontWeight: '600',
  },
  messageFromText: {
    marginBottom: 5,
    paddingTop: 3,
    fontSize: 14,
  },
  messagePreviewText: {
    fontSize: 12,
  },
}
