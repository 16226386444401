import { CardCollection } from 'components/common/cards'
import { LoadingContainer } from 'components/common/loading'
import { remoteSearchMixinFactory } from 'components/common/search'
import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'
import PageState from './page-state'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}

class EntityCardsCollection extends React.Component {
  createCard = (entity) => this.props.createCard(entity)

  loadMore = (e) => {
    // prevent form from being submitted
    e.preventDefault()
    this.props.loadMore()
  }

  render() {
    return (
      <div style={styles.container}>
        <CardCollection entities={this.props.entities} createCard={this.createCard} />
        {this.props.moreAvailable() ? (
          <button className="ui basic button" onClick={this.loadMore} style={{ marginTop: 20 }}>
            {t('show_more')}
          </button>
        ) : null}
      </div>
    )
  }
}

@reactMixin.decorate(
  remoteSearchMixinFactory(PageState.ActionCreators.setEntitySearch.bind(PageState.ActionCreators))
)
export class EntitySelect extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    initialValue: PropTypes.array,
  }

  constructor(props) {
    super()
    let selections = []
    if (props.initialValue) {
      selections = props.initialValue
    }

    this.state = {
      selections,
      selectedEntities: [],
    }
  }

  componentWillUnmount() {
    // reset page state so next modal open doesn't already have a search
    // filter set on it.
    PageState.Store.resetState()
  }

  componentDidMount() {
    // if (this.props.registerComponent) this.props.registerComponent(this);
    PageState.ActionCreators.setEntitySelectComponent(this)
    _.defer(() => {
      if (this.props.onChange) this.props.onChange(this)
    })
  }

  onCardClick = (entity) => {
    // This component contains the isValid method. Use PageState to
    // keep track of component which will be used to determine if the
    // field is valid.
    PageState.ActionCreators.toggleEntitySelection(entity)

    const entityURL = entity.url
    let selections = this.state.selections
    if (this.props.many) {
      // if many entities are allowed, append new one to list
      if (_.includes(selections, entityURL)) {
        // if entity is already selected, a click should remove the selection
        _.pull(selections, entityURL)
      } else {
        selections.push(entityURL)
      }
    } else {
      // only one entity is allowed
      selections = [entityURL]
    }
    this.setState({ selections, selectedEntities: [entity] })
    _.defer(() => {
      if (this.props.onChange) this.props.onChange(this)
      if (this.props.onCardClick) this.props.onCardClick()
    })
  }

  createEntityCard = (entity) => {
    const EntityCard = this.props.cardComponent
    return (
      <EntityCard
        key={entity.id}
        entity={entity}
        currentUser={this.props.currentUser}
        onCardClick={this.onCardClick}
        selectedEntities={this.state.selections}
      />
    )
  }

  getNameAndValue = () => ({
    [this.props.name]: this.state.selections,
  })

  isValid() {
    if (this.props.required && !this.state.selections.length) return false
    return true
  }

  render() {
    return (
      <div>
        {this.getSearchInput()}
        <LoadingContainer
          loadingProps={{
            entities: this.props.entities,
          }}
          createComponent={() => (
            <EntityCardsCollection {...this.props} createCard={this.createEntityCard} />
          )}
        />
      </div>
    )
  }
}
