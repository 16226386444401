import { BordelessButton } from 'components/common/buttons'
import {
  ButtonToggle,
  Form,
  HiddenTextInput,
  SearchableSelect,
  SubmitButton,
} from 'components/common/form-deprecated'
import {
  CONTENT_SHARING,
  GatedFeatureModal,
  GROUPS_AND_AREAS,
} from 'components/common/gated-feature'
import { Info } from 'components/common/info.jsx'
import { Modal } from 'components/common/modals/index.jsx'
import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import ChannelSharesState from 'state/channel-shares'
import ChannelsState from 'state/channels'
import Style from 'style/index.js'
import { ChannelAnalyticsBox } from './analytics'
import { EnrollModal } from '../../common/enroll-users-modal'
import { ShareChannelModal } from '../share-channel-modal'

const PUBLIC = 'public'
const PUBLIC_WITH_REQUEST = 'request_to_access'
const PRIVATE = 'private'
const ON = 'On'
const OFF = 'Off'

const ACCESSIBILITY_MAPPING = {
  [PUBLIC]: { public: true, request_to_access: false },
  [PUBLIC_WITH_REQUEST]: { public: true, request_to_access: true },
  [PRIVATE]: { public: false, request_to_access: true },
}

const styles = {
  topButton: {
    marginTop: 5,
    minWidth: 100,
  },
  smallTopButton: {
    marginTop: 5,
    minWidth: 50,
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  extraOptionButtonIcon: {
    fontSize: 15,
  },
}

const ACCESSIBILITY_OPTS = [
  {
    label: PUBLIC,
    value: PUBLIC,
  },
  {
    label: PUBLIC_WITH_REQUEST,
    value: PUBLIC_WITH_REQUEST,
  },
  {
    label: PRIVATE,
    value: PRIVATE,
  },
]

const getAccessibilityKey = (channel) => {
  let accessibility = PUBLIC
  if (channel.public !== undefined) {
    if (channel.public) {
      if (channel.request_to_access) {
        accessibility = PUBLIC_WITH_REQUEST
      } else {
        accessibility = PUBLIC
      }
    } else {
      accessibility = PRIVATE
    }
  }
  return accessibility
}

const getAccessibilityText = (channel) => t(getAccessibilityKey(channel))

export class ButtonsRow extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    featureFlags: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  }

  static data = {
    channel: {
      required: true,
      fields: [
        'id',
        'public',
        'request_to_access',
        'auto_enroll_turned_on_for_current_user_company',
        'contains_external_content',
        // Used by the channel analytics component
        'avg_like_rating',
        'avg_learn_rating',
      ],
    },
  }

  constructor(props) {
    super()
    this.shareChannelModal = React.createRef()
  }

  onAccessibilityFormSubmit = (data) => {
    const accessData = ACCESSIBILITY_MAPPING[data.accessibility]
    const newChannelData = {
      public: accessData.public,
      request_to_access: accessData.request_to_access,
    }
    ChannelsState.ActionCreators.update(this.props.channel.id, newChannelData)
    this.context.displayTempPositiveMessage({ heading: 'changes_saved' })
    _.delay(() => {
      if (this.accessibilityModal) this.accessibilityModal.hide()
    }, 500)
  }

  removeConnection = () => {
    ChannelSharesState.ActionCreators.delete(this.props.ownCompanyConnection.id)
    ChannelsState.Store.onDelete(this.props.channel.id)
    this.context.router.push('/views/content/channels/?filter=external_channels')
  }

  onAccessibilityClick = () => {
    if (this.context.currentUser.company.subscription.shared_content_enabled) {
      this.accessibilityModal.show()
    } else {
      this.sharingGatedModal.show()
    }
  }

  getButtons = () => {
    const { learner, company } = this.context.currentUser
    let lockIconClass = 'lock'
    if (this.props.channel.public && !this.props.channel.request_to_access) {
      lockIconClass = 'unlock'
    }

    const buttons = []

    // if the user is on a free tier, the modal will display upgrade message
    buttons.push({
      name: 'enrollUsers',
      onClick: () => {
        const modal = company.subscription.groups_and_areas_enabled
          ? this.enrollUsersModal
          : this.enrollmentsGatedModal
        modal.show()
      },
      icon: 'student',
      iconStyle: {
        color: this.props.companyAccess ? Style.vars.deprecatedColors.green : 'inherit',
      },
      text: t('enrollments'),
    })

    const hasNoExternalContentRestriction =
      !this.props.channel.contains_external_content ||
      (this.props.channel.contains_external_content && this.context.featureFlags.content_forwarding)

    if (!this.props.isUneditableChannel && hasNoExternalContentRestriction) {
      buttons.push({
        name: 'shareBtn',
        onClick: () => this.shareChannelModal.current.show(),
        icon: 'share alternate',
        text: t('share_channel_titlecase'),
      })
    }

    if (!this.props.isUneditableChannel) {
      buttons.push({
        name: 'statsBtn',
        onClick: () => this.statsModal.show(),
        icon: 'bar chart',
        text: t('analytics'),
      })
    }
    if (company.id != this.props.channel.company.id && learner.can_manage_training_content) {
      buttons.push({
        name: 'cancelConnectionBtn',
        onClick: () => this.cancelConnectionModal.show(),
        icon: 'remove',
        text: t('remove_connection'),
      })
    }
    return buttons
  }

  render() {
    return (
      <div style={styles.buttonsContainer}>
        {this.getButtons().map((b) => (
          <BordelessButton key={b.name} name={b.name} style={styles.topButton} onClick={b.onClick}>
            <i
              className={`${b.icon} icon`}
              style={{
                ...styles.extraOptionButtonIcon,
                ...(b.iconStyle || {}),
              }}
            />
            {b.text}
          </BordelessButton>
        ))}

        <EnrollModal
          ref={(c) => (this.enrollUsersModal = c)}
          channel={this.props.channel}
          ownCompanyConnection={this.props.ownCompanyConnection}
          companyAccess={this.props.companyAccess}
        />
        <Modal
          ref={(c) => (this.accessibilityModal = c)}
          header={
            <div>
              {t('accessibility')}
{' '}
              <Info style={styles.infoIcon} content={t('accessibility_info')} />
            </div>
          }
          basic
          message
          noConfirm
        >
          <div className="content">
            <Form onSubmitAndValid={this.onAccessibilityFormSubmit}>
              <HiddenTextInput />
              <SearchableSelect
                name="accessibility"
                initialSelection={getAccessibilityKey(this.props.channel)}
                options={ACCESSIBILITY_OPTS.map((opt) => ({
                  ...opt,
                  label: t(opt.label),
                }))}
              />
              <SubmitButton text={t('submit')} />
            </Form>
          </div>
        </Modal>
        <Modal
          ref={(c) => (this.statsModal = c)}
          header={t('channel_stats')}
          size="large"
          noConfirm
        >
          <div className="content">
            <ChannelAnalyticsBox {...this.props} />
          </div>
        </Modal>
        <GatedFeatureModal
          ref={(c) => (this.sharingGatedModal = c)}
          headerText={t('upgrade_to_pro_share_content')}
          descriptionText={t('share_content_desc')}
          featureType={CONTENT_SHARING}
        />
        <GatedFeatureModal
          ref={(c) => (this.enrollmentsGatedModal = c)}
          headerText={t('upgrade_to_pro_enroll_users')}
          descriptionText={t('upgrade_to_pro_enroll_users_desc')}
          featureType={GROUPS_AND_AREAS}
        />
        <Modal
          ref={(c) => (this.cancelConnectionModal = c)}
          header={t('are_you_sure')}
          content={t('you_will_no_longer_have_access_to_this_content')}
          onConfirm={this.removeConnection}
          basic
        />
        <ShareChannelModal innerRef={this.shareChannelModal} />
      </div>
    )
  }
}
