import React from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { Progress, Icon } from 'semantic-ui-react'

import Style from 'shared-js/style'
import { Placeholder } from 'shared-js/components/common/placeholder'
import { getDueDetails } from 'shared-js/utilities/time'
import { SmallPlanCard_plan } from './gql-types/SmallPlanCard_plan'

type Props = {
  plan: SmallPlanCard_plan,
}

const WIDTH = 330

export const SmallPlanCard = ({ plan }: Props) => {
  const { dueText, overdue } = getDueDetails(plan.nextDueDateForUser)
  return (
    <Container>
      <TrainingPlanImage src={plan.customThumbnailSmall || plan.thumbnailUrl} />
      <MiddleContainer>
        <Text>{plan.name}</Text>
        {dueText && overdue && <Text overdue={overdue}>{dueText}</Text>}
        <ProgressContainer>
          <Progress success percent={(plan.progressForUser || 0) * 100} size="tiny" />
        </ProgressContainer>
      </MiddleContainer>
      <PlayButton>
        <Icon inverted name="play" size="small" />
      </PlayButton>
    </Container>
  )
}

SmallPlanCard.fragment = gql`
  fragment SmallPlanCard_plan on TrainingPlanType {
    id
    customThumbnailSmall
    thumbnailUrl
    nextDueDateForUser
    name
    progressForUser
  }
`

export const SmallPlanCardLoading = () => (
  <Placeholder.Box height={85} width={WIDTH} animate="fade" />
)

const Container = styled.div`
  width: ${WIDTH}px;
  background-color: #f5f5f5;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
`

const TrainingPlanImage = styled.div<{ src: string }>`
  width: 45px;
  height: 45px;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
  flex-shrink: 0;
`

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0px 20px;
`

const ProgressContainer = styled.div`
  margin-top: 6px;
  margin-bottom: -1.1em; /* remove semantic ui padding */
`

const Text = styled.div<{ overdue?: boolean }>`
  margin-bottom: 3px;
  max-height: 75px; /* don't show more than 4 lines of text */
  overflow: hidden;
  color: ${({ overdue }) => (overdue ? Style.colors.amber800 : 'black')};
`

const PlayButton = styled.div`
  width: 34px;
  height: 34px;
  background-color: black;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  flex-shrink: 0;
`
