import React from 'react'
import { t } from 'shared-js/i18n'
import styled from 'styled-components'

export const UserRemovedText = () => <RemovedTextBox>{t('removed_from_myagi')}</RemovedTextBox>

const RemovedTextBox = styled.div`
  fontfamily: 'Avenir';
  fontsize: 14px;
  marginbottom: 15;
  color: rgb(254, 206, 52);
  marginleft: 10px;
`
