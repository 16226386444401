import React from 'react'
import { Modal as SemanticModal } from 'semantic-ui-react'

export class ModalHeader extends React.Component {
  render() {
    return (
      <SemanticModal.Header style={this.props.headerStyle ? this.props.headerStyle : {}}>
        {this.props.header}
      </SemanticModal.Header>
    )
  }
}

export class ModalContent extends React.Component {
  render() {
    const { children, content } = this.props
    if (!children && !content) return <span />
    return (
      <SemanticModal.Content
        style={this.props.contentStyle ? this.props.contentStyle : {}}
        scrolling={this.props.scrolling}
      >
        {children && children}
        {content && content}
      </SemanticModal.Content>
    )
  }
}
