import React from 'react'
import Style from 'shared-js/style'
import styled from 'styled-components'
import { useCommonRouter } from 'shared-js/routing'
import { t } from 'shared-js/i18n'
import { ErrorMessage as UniversalErrorMessage } from 'components/common/error-message'
import Button from 'shared-js/components/common/buttons'
import Emoji from 'components/common/emoji'
import { PageTitle } from '../common'

type NoChannelsContainerProps = {
  marginTop?: number
}

export const ErrorMessage = () => (
  <>
    <PageTitle />
    {t('click_channel_to_preview_its_contents_and_add_to_your_library')}
    <UniversalErrorMessage location="discover content" margin="40px 0 25vh 0" />
  </>
)

export const NoChannelsMessage = ({ marginTop }: NoChannelsContainerProps) => {
  const { handlers } = useCommonRouter()
  return (
    <NoChannelsMessageContainer marginTop={marginTop}>
      <NoChannelsTitle>
        <Emoji symbol="😟" style={{ marginRight: 10 }} />
        {t('theres_nothing_here')}
      </NoChannelsTitle>
      {t('expand_your_network_to_see_more_training_content')}
      <ButtonContainer>
        <Button color="primary" onPress={() => handlers.goToFindConnections()}>
          {t('expand_your_network')}
        </Button>
      </ButtonContainer>
    </NoChannelsMessageContainer>
  )
}

const NoChannelsMessageContainer = styled.div<NoChannelsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ marginTop }) => `${marginTop || 20}px`};
`

const NoChannelsTitle = styled.div`
  font-weight: 600;
  color: ${Style.colors.grey900};
  margin-bottom: 15px;
`

const ButtonContainer = styled.div`
  margin: 15px auto 0 auto;
`
