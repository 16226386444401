import React from 'react'
import styled from 'styled-components'
import Style from 'shared-js/style'

type LinkProps = {
  onClick(): void,
  children: React.ReactNode,
}

export default styled.div<LinkProps>`
  text-align: center;
  color: ${Style.deprecatedColors.bluish};
  cursor: pointer;
`
