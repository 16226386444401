import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  GetCompanyTypes as Query,
  GetCompanyTypesVariables as QueryVariables,
} from './gql-types/GetCompanyTypes'
import CompanyTypeStep, { RETAILER, BRAND, DISTRIBUTOR, CompanyType } from './company-type-step'
import CompanyInfoStep from './company-info-step'
import IndustryStep from './industries-step'

const COMPANY_TYPES = [RETAILER, BRAND, DISTRIBUTOR]

const GET_COMPANY_TYPES = gql`
  query GetCompanyTypes($names: [String]!) {
    CompanyType__List(name_In: $names, first: 10) {
      edges {
        node {
          id
          name
        }
      }
      totalCount
    }
  }
`

const Form = ({ onCompletedForm }: { onCompletedForm(): void }) => {
  const [companyType, setCompanyType] = useState<CompanyType[]>()
  const [industries, setIndustries] = useState()

  const checkIsABrand = () => {
    if (companyType) {
      if (companyType.some((item) => item.value === RETAILER)) {
        return false
      }
      return true
    }
    return undefined
  }

  const classifyAsBrand = companyType && checkIsABrand()

  const apolloProps = useQuery<Query, QueryVariables>(GET_COMPANY_TYPES, {
    variables: { names: COMPANY_TYPES },
  })

  const getCompanyTypeNodes = () => {
    const brandType = apolloProps.data?.CompanyType__List?.edges.find(
      (type) => type?.node?.name === BRAND
    )

    const retailerType = apolloProps.data?.CompanyType__List?.edges.find(
      (type) => type?.node?.name === RETAILER
    )

    const distributorType = apolloProps.data?.CompanyType__List?.edges.find(
      (type) => type?.node?.name === DISTRIBUTOR
    )

    return {
      brand: brandType,
      retailer: retailerType,
      distributor: distributorType,
    }
  }

  const reset = () => {
    setCompanyType(undefined)
    setIndustries(undefined)
  }

  if (companyType && classifyAsBrand !== undefined) {
    if (industries) {
      const companyTypeNodes = getCompanyTypeNodes()
      return (
        <CompanyInfoStep
          isABrand={classifyAsBrand}
          onCompletedForm={onCompletedForm}
          companyTypeNodes={companyTypeNodes}
          industryIDs={industries}
          companyType={companyType}
          resetForm={reset}
        />
      )
    }
    return <IndustryStep setIndustries={setIndustries} resetForm={reset} />
  }

  return <CompanyTypeStep setCompanyType={setCompanyType} />
}

export default Form
