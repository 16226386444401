import React, { ComponentProps, useContext, useEffect } from 'react'
import { FeatureFlagContext } from 'shared-js/context/context'

export default function RedirectToFrontend2() {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    query.append('cacheBuster', Date.now().toString())

    const forceF2Url = `/~f2${window.location.pathname}?${query.toString()}${window.location.hash}`
    window.location.replace(window.location.origin + forceF2Url)
  }, [])

  return null
}

export function withRedirectToFrontend2ByFeatureFlag(featureFlag: string) {
  return (WrappedComponent: any) => {
    function Component(props: ComponentProps<any>) {
      const featureFlags = useContext(FeatureFlagContext)

      if (featureFlags[featureFlag]) {
        return <RedirectToFrontend2 />
      }

      return <WrappedComponent {...props} />
    }

    Component.displayName = `${getComponentDisplayName(
      WrappedComponent
    )}WithRedirectToFrontend2ByFeatureFlag`

    return Component
  }
}

function getComponentDisplayName(WrappedComponent: any): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}
