import React from 'react'
import styled from 'styled-components'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ErrorIcon from '@material-ui/icons/Error'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { t } from 'shared-js/i18n'
import Style from 'shared-js/style'
import { Button } from 'ui-kit'
import { getListSummary } from 'utilities'

export const ERROR = 'error'
export const CREATED = 'created'
export const DEACTIVATED = 'deactivated'

export type OutcomeOptions = typeof ERROR | typeof CREATED | typeof DEACTIVATED

export type SaveChangesResult = {
  outcome: OutcomeOptions
  companyName: string | null | undefined
}

type SuccessMessageProps = {
  saveChangesResults: SaveChangesResult[]
  closeModal(): void
}

export const SuccessMessage = ({ saveChangesResults, closeModal }: SuccessMessageProps) => {
  const sortResults = () => {
    const createdSubscriptions: string[] = []
    const removedSubscriptions: string[] = []
    const errors: (string | null | undefined)[] = []

    saveChangesResults.forEach((result) => {
      if (result.outcome === CREATED && result.companyName) {
        createdSubscriptions.push(result.companyName)
      } else if (result.outcome === DEACTIVATED && result.companyName) {
        removedSubscriptions.push(result.companyName)
      } else {
        errors.push(result.companyName)
      }
    })

    return {
      createdSubscriptions,
      removedSubscriptions,
      errors,
    }
  }

  const sortedResults = sortResults()
  const getCompanyName = (item: string) => item
  const getRemainderDefinition = (numRemainingItems: number) =>
    `${t('and')} ${t(
      'other_companies',
      { companiesCount: numRemainingItems },
      { pluralize: numRemainingItems }
    )}`

  const sharedWithCompaniesSummary =
    sortedResults.createdSubscriptions.length > 0
      ? getListSummary(
          sortedResults.createdSubscriptions,
          2,
          getRemainderDefinition,
          getCompanyName
        )
      : null

  const removedCompaniesSummary =
    sortedResults.removedSubscriptions.length > 0
      ? getListSummary(
          sortedResults.removedSubscriptions,
          2,
          getRemainderDefinition,
          getCompanyName
        )
      : null

  const errorsSummary =
    sortedResults.errors.length > 0
      ? getListSummary(sortedResults.errors, 2, getRemainderDefinition, getCompanyName)
      : null

  return (
    <SuccessMessageContainer>
      {sortedResults.createdSubscriptions.length > 0 && (
        <MessageContainer>
          <StyledAddedIcon />
          {`${t('your_channel_is_now_shared_with')} ${sharedWithCompaniesSummary}`}
        </MessageContainer>
      )}

      {sortedResults.removedSubscriptions.length > 0 && (
        <MessageContainer>
          <StyledRemoveIcon />
          {`${t('your_channel_is_no_longer_shared_with')} ${removedCompaniesSummary}`}
        </MessageContainer>
      )}

      {sortedResults.errors.length > 0 && (
        <MessageContainer>
          <StyledErrorIcon />
          {`${t('there_was_an_error_with')} ${errorsSummary}. ${t('please_try_again')}.`}
        </MessageContainer>
      )}
      <ButtonContainer>
        <Button onClick={closeModal}>{t('done')}</Button>
      </ButtonContainer>
    </SuccessMessageContainer>
  )
}

const StyledAddedIcon = styled(AddCircleIcon)`
  color: ${Style.colors.green700};
  margin-right: 18px;
`

const StyledRemoveIcon = styled(RemoveCircleIcon)`
  color: ${Style.colors.grey400};
  margin-right: 18px;
`

const StyledErrorIcon = styled(ErrorIcon)`
  color: ${Style.colors.red900};
  margin-right: 18px;
`

const SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 34px;
`

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
`
