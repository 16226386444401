import { HoverMixin } from 'components/common/hover'
import { CompanyHierarchyTree } from 'components/common/parent-child-tree'
import { remoteSearchMixinFactory } from 'components/common/search'
import { SearchBarContainer } from 'components/common/universal-search-bar'
import { t } from 'i18n'
import _ from 'lodash'
import Marty from 'marty'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import reactMixin from 'react-mixin'
import { Link } from 'react-router'
import Im from 'shared-js/immutable'
import CompaniesState from 'state/companies'
import UsersState from 'state/users'
import Style from 'style'
import StyleCustomization from 'style/customization'
import containerUtils from 'utilities/containers'
import { resolve } from 'utilities/deprecated-named-routes'
import { getIdFromApiUrl } from 'utilities/generic'
import $y from 'utilities/yaler'
import AppState from '../app/state'
import { AvatarMenu, AvatarInfo } from './avatar-menu'
import { HamburgerMenu } from './hamburger-menu'
import goToApp from './goToApp'

import '../../../css/tailwind.css'

const MOBILE_WIDTH_PX = 768
export const MOBILE_WIDTH = `@media screen and (max-width: ${MOBILE_WIDTH_PX}px)`

const NAVBAR_HEIGHT_INNER = 80
export const NAVBAR_HEIGHT = NAVBAR_HEIGHT_INNER + 5 // 5 is for the border

const styles = {
  navbarContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Style.vars.deprecatedColors.white,
  },
  indicator: {
    marginLeft: 10,
  },
  logoContainer: {
    marginRight: 20,
    display: 'flex',
  },
  logoImage: {
    width: '100%',
    height: 24,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },

  // Start NavItems CSS
  navItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    marginRight: 20,
    marginLeft: 20,
  },
  navItem: Style.funcs.merge(
    {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 10,
      paddingBottom: 10,
      cursor: 'pointer',
      color: Style.vars.deprecatedColors.navInactiveGrey, // TODO Use Style.vars.deprecatedColors.navFontColor instead
    },
    Style.funcs.makeTransitionAll()
  ),

  // Menu CSS
  rightMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  mobileDropdown: {
    display: 'none',
    width: '104px',
    [MOBILE_WIDTH]: {
      display: 'inherit',
    },
  },
  desktopDropdown: {
    [MOBILE_WIDTH]: {
      display: 'none',
    },
  },
  searchDesktopContainer: {
    [MOBILE_WIDTH]: {
      display: 'none',
    },
  },
  desktopRightSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

@reactMixin.decorate(HoverMixin)
@Radium
export class NavItem extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  static propTypes = {
    to: PropTypes.string,
    currentRoute: PropTypes.string,
    onClick: PropTypes.func,
    params: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    shouldRenderIndicator: PropTypes.bool,
  }

  isActive() {
    const loc = resolve(this.props.to, this.props.params)
    const locNavItem = loc.match(/\/views\/\w+\//) // E.g. /views/people/
    return _.includes(this.props.currentRoute, locNavItem)
  }

  goToRoute = (evt) => {
    if (this.props.onClick) {
      return this.props.onClick(evt)
    } // Custom click handler
    this.context.router.push(resolve(this.props.to, this.props.params)) // Default link
  }

  render() {
    return (
      <Link
        className="tw-py-1.5 tw-px-3 tw-truncate tw-text-gray-500 hover:tw-text-gray-800"
        to={this.props.to}
        onClick={this.goToRoute}
      >
        {this.props.children}
        {/* Display a little orange dot next to a navbar link if
        shouldRenderIndicator is truthy. */}
        {this.props.shouldRenderIndicator && (
          <div style={styles.indicator} className="ui orange empty circular mini label" />
        )}
      </Link>
    )
  }
}

@reactMixin.decorate(
  remoteSearchMixinFactory(AppState.ActionCreators.setSearch.bind(AppState.ActionCreators))
)
@Radium
export class NavBarInner extends React.Component {
  static propTypes = {
    isPreload: PropTypes.bool,
    currentUser: PropTypes.object.isRequired,
    curSearch: PropTypes.func.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
  }

  static contextTypes = {
    router: PropTypes.object,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    featureFlags: PropTypes.objectOf(PropTypes.bool),
  }

  constructor(props, context) {
    super(props, context)

    const learner = props.currentUser.learner
    const hasCompany = Boolean(props.currentUser.company)
    const companyAdmin = learner.is_company_admin && hasCompany
    const canAccessSharedNetworkGroup = props.currentUser.can_access_shared_network_group
    const hasTeam = Boolean(learner.learner_group) && hasCompany
    const everyoneCanInvite = Boolean(
      props.currentUser.company.companysettings.users_can_invite_others_to_join
    )
    const isTeamManager = hasTeam && learner.is_learner_group_admin && hasCompany
    const canManageOrEnroll =
      learner.can_manage_training_content || learner.can_enroll_others_in_training_content
    const subscription = hasCompany ? props.currentUser.company.subscription : null
    const subscriptionPaused = subscription ? subscription.paused : false
    const teamId = hasTeam ? getIdFromApiUrl(learner.learner_group) : null
    const peopleTabName = companyAdmin ? 'people' : 'team'

    // Currently checking the feature flag below before showing leaderboards
    const canViewAnalytics =
      hasCompany &&
      (companyAdmin ||
        isTeamManager ||
        learner.is_area_manager ||
        !!(context && context.featureFlags && !context.featureFlags.hide_analytics_leaderboards))

    const navItems = [
      {
        name: 'home',
        route: '/views/home/',
        show: hasCompany,
      },
      {
        name: 'learning',
        route: '/views/training/channels/',
        show: hasCompany && !subscriptionPaused,
      },
      {
        name: 'content',
        route: `/views/content/${hasCompany ? 'channels/' : 'plans/'}`,
        show: canManageOrEnroll && !subscriptionPaused,
      },
      {
        name: peopleTabName,
        route:
          companyAdmin || learner.is_area_manager
            ? '/views/people/teams/'
            : everyoneCanInvite && !isTeamManager
            ? `/views/people/invites/`
            : `/views/people/teams/${teamId}/`,
        show: (isTeamManager || companyAdmin || everyoneCanInvite) && !subscriptionPaused,
      },
      {
        name:
          companyAdmin || isTeamManager || learner.is_area_manager ? 'analytics' : 'leaderboards',
        route:
          companyAdmin || isTeamManager || learner.is_area_manager
            ? '/views/analytics/'
            : '/views/analytics/leaderboards/',
        show: canViewAnalytics,
      },
      {
        name: 'network',
        route: companyAdmin ? '/views/network/' : '/views/network/shared-groups/',
        show: !!(companyAdmin || canAccessSharedNetworkGroup),
      },
      {
        name: 'incentives',
        route: '/views/badges/',
        show:
          companyAdmin && context && context.featureFlags && context.featureFlags.frontend2_badges,
      },
    ]

    this.state = {
      navItems,
    }
  }

  updateDimensions = () => {
    if (this.state.width !== window.innerWidth) {
      this.setState({ width: window.innerWidth })
    }
  }

  UNSAFE_componentWillMount() {
    this.updateDimensions()
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  componentDidUpdate() {
    // Ensures that search input is cleared when
    // AppStore search state is cleared
    if (!this.props.curSearch && this.getSearchVal()) {
      this.clearSearch()
    }
  }

  shouldRenderIndicator = () =>
    this.shouldRenderCuratedIndicator() || this.shouldRenderChannelIndicator()

  shouldRenderCuratedIndicator = () => {
    const learner = this.props.currentUser.learner
    return Boolean(
      learner.num_unfulfilled_company_connection_request_channels_for_user &&
        learner.can_make_new_channel_connections
    )
  }

  shouldRenderChannelIndicator = () => {
    // Check if there are any open channel connections. This function is passed
    // as a prop so that updates to the current user fetch will cause the
    // NavItem to re-render.
    const { learner, company } = this.props.currentUser
    // Don't show indicator dot if shared content is turned off, or the user
    // can't manage training content.
    return Boolean(
      learner.can_manage_training_content &&
        (company.open_connection_request_count.incoming ||
          (company.open_connection_request_count.outgoing &&
            company.subscription.shared_content_enabled))
    )
  }

  render() {
    const { learner, company } = this.props.currentUser
    const logo = StyleCustomization.getNavLogoForCompany(company)
    const logoLink = learner.is_myagi_staff ? 'myagi-staff' : 'home'
    const navItems = _.filter(this.state.navItems, (nav) => nav.show).map((nav) => (
      <NavItem
        key={nav.route}
        to={nav.route}
        params={nav.params}
        currentRoute={window.location.href}
        shouldRenderIndicator={nav.shouldRenderIndicator}
        currentUser={this.props.currentUser}
        className="tw-flex tw-items-center tw-justify-between tw-py-1.5"
      >
        {t(nav.name)}
      </NavItem>
    ))

    return (
      <div className="tailwind-container">
        <div className="tw-border-b">
          <div className="tw-container tw-mx-auto tw-px-4">
            <div className="tw-relative tw-z-20 tw-h-14 md:tw-h-16 tw-py-2 tw-flex tw-justify-between tw-items-center">
              <div style={styles.mobileDropdown}>
                {/* Only render the hamburger menu on mobile so that the onClickOutside function isn't triggered on desktop */}
                {window.innerWidth < MOBILE_WIDTH_PX ? (
                  <HamburgerMenu currentUser={this.props.currentUser}>
                    {[
                      // "Get the app" is added to the start of the the navItems for mobile
                      <div style={styles.navItemContainer} onClick={goToApp} key="get_app">
                        <p style={styles.navItem}>{t('get_the_app')}</p>
                      </div>,
                      ...navItems,
                    ]}
                  </HamburgerMenu>
                ) : null}
              </div>

              <Link to={resolve(logoLink)} className="tw-flex tw-h-8 tw-w-28">
                <img className="tw-object-contain" src={logo} alt="" />
              </Link>

              <div
                style={styles.desktopDropdown}
                className="tw-flex tw-flex-grow tw-items-center tw-justify-between tw-px-6"
              >
                <nav className="tw-flex tw-flex">{navItems}</nav>

                <div style={styles.searchDesktopContainer}>
                  <SearchBarContainer />
                </div>
              </div>

              <div className="tw-flex tw-items-center">
                <a
                  className={
                    'tw-w-9 tw-h-9 lg:tw-mr-2 tw-mr-6 tw-p-1.5 ' +
                    'tw-rounded-md tw-text-gray-400 hover:tw-bg-gray-100 ' +
                    'md:tw-hidden lg:tw-block'
                  }
                  href="https://www.getmyagi.com/roadmap/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="tw-w-full tw-h-full"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                    />
                  </svg>
                </a>

                <div
                  style={styles.desktopRightSection}
                  className="tw-flex tw-items-center tw-h-11 tw-w-11 lg:tw-w-auto"
                >
                  {this.props.isPreload ? (
                    <AvatarInfo currentUser={this.props.currentUser} />
                  ) : (
                    <AvatarMenu
                      companies={this.props.companies}
                      currentUser={this.props.currentUser}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const NavBar = Marty.createContainer(NavBarInner, {
  listenTo: [AppState.Store, CompaniesState.Store, UsersState.Store],

  fetch: {
    curSearch() {
      return AppState.Store.getSearch()
    },
    companies() {
      if (
        !this.props.currentUser.learner.is_parent_company_admin ||
        !this.props.currentUser.company
      ) {
        // Don't bother requesting companies if the user isn't the parent company
        // admin. Companies are only used for switching between child companies.
        return Im.freeze([])
      }
      const query = {
        fields: $y.getFields(CompanyHierarchyTree, 'companies'),
        relative_companies: this.props.currentUser.company.id,
        ordering: 'has_parent,name',
      }
      return CompaniesState.Store.getItems(query)
    },
  },

  pending() {
    return containerUtils.defaultPending(this, NavBarInner)
  },

  failed(errors) {
    return containerUtils.defaultPending(this, NavBarInner)
  },
})
