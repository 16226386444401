import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { CommonRouterContext } from 'shared-js/context'
import { UpdateCacheProps, QueryDataType } from 'shared-js/state/graphql/update-cache'
import { t } from 'shared-js/i18n'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Modal } from 'components/common/modals'
import { Button, OutlinedButton } from 'ui-kit'
import ShareWithMyNetworkSection from './share-with-my-network'
import ShareWithSpecificCompanies from './share-with-specific-companies'
import EditSubscribedCompanies from './edit-subscribed-companies'
import {
  GetChannelInfoQuery_TrainingUnit__Item,
  GetChannelInfoQuery as ChannelQuery,
  GetChannelInfoQueryVariables as ChannelVariables,
} from './gql-types/GetChannelInfoQuery'
import {
  UpdateChannelSharableSettings,
  UpdateChannelSharableSettingsVariables,
} from './gql-types/UpdateChannelSharableSettings'
import {
  HandleChannelSubscriptionsProvider,
  useHandleChannelSubscriptions,
} from './edit-subscribed-companies/context'
import {
  SaveChangesResult,
  SuccessMessage,
  CREATED,
  DEACTIVATED,
} from './edit-subscribed-companies/success-message'

const SMALL_PHONE_WIDTH = 350

type ShareChannelModalProps = {
  innerRef: React.RefObject<Modal>
}

type InnerModalProps = {
  channel: GetChannelInfoQuery_TrainingUnit__Item
  closeModal(): void
  setSaveChangesResults(result: SaveChangesResult[]): void
  updateSubscribedCompaniesCount(props: UpdateSubscribeCompaniesCountProps): void
}

type UpdateSubscribeCompaniesCountProps = UpdateCacheProps & {
  mutation: typeof CREATED | typeof DEACTIVATED
}

const GET_CHANNEL_SHARABLE_SETTINGS = gql`
  query GetChannelInfoQuery($channelID: ID!) {
    TrainingUnit__Item(id: $channelID) {
      id
      name
      isDiscoverable
      subscribedCompaniesCount
    }
  }
`

const UPDATE_CHANNEL_SHARABLE_SETTINGS = gql`
  mutation UpdateChannelSharableSettings($channelId: ID!, $isDiscoverable: Boolean!) {
    TrainingUnit__Update(
      input: { id: $channelId, requestToAccess: $isDiscoverable, public: $isDiscoverable }
    ) {
      result {
        id
        isDiscoverable
      }
      errors {
        messages
      }
    }
  }
`

const ShareChannelModalInner = ({ innerRef }: ShareChannelModalProps) => {
  const [saveChangesResults, setSaveChangesResults] = useState<SaveChangesResult[] | null>(null)
  const { routeParams } = useContext(CommonRouterContext)
  const channelQueryVariables = {
    channelID: routeParams.channelId,
  }

  const apolloProps = useQuery<ChannelQuery, ChannelVariables>(GET_CHANNEL_SHARABLE_SETTINGS, {
    variables: channelQueryVariables,
  })

  const updateSubscribedCompaniesCount = (
    props: UpdateCacheProps & {
      mutation: typeof CREATED | typeof DEACTIVATED
    }
  ) => {
    const queryData: QueryDataType = props.cache.readQuery({
      query: GET_CHANNEL_SHARABLE_SETTINGS,
      variables: channelQueryVariables,
    })
    if (queryData) {
      let currentSubscribedCompaniesCount = queryData.TrainingUnit__Item.subscribedCompaniesCount
      if (props.mutation === CREATED) currentSubscribedCompaniesCount += 1
      if (props.mutation === DEACTIVATED) currentSubscribedCompaniesCount -= 1

      props.cache.writeQuery({
        query: GET_CHANNEL_SHARABLE_SETTINGS,
        variables: channelQueryVariables,
        data: {
          TrainingUnit__Item: {
            ...queryData.TrainingUnit__Item,
            subscribedCompaniesCount: currentSubscribedCompaniesCount,
          },
        },
      })
    }
  }

  const { resetSubscriptionChanges } = useHandleChannelSubscriptions()

  const resetStates = () => {
    resetSubscriptionChanges()
    setSaveChangesResults(null)
  }

  const closeModal = () => {
    innerRef.current && innerRef.current.hide()
    resetStates()
  }

  return (
    <Modal ref={innerRef} onHidden={resetStates}>
      {!saveChangesResults && <ModalHeader>{t('share_this_channel')}</ModalHeader>}
      {!saveChangesResults && apolloProps.data && apolloProps.data.TrainingUnit__Item && (
        <ModalContents
          channel={apolloProps.data.TrainingUnit__Item}
          closeModal={closeModal}
          setSaveChangesResults={setSaveChangesResults}
          updateSubscribedCompaniesCount={updateSubscribedCompaniesCount}
        />
      )}
      {saveChangesResults && (
        <SuccessMessage saveChangesResults={saveChangesResults} closeModal={closeModal} />
      )}
    </Modal>
  )
}

const ModalContents = ({
  channel,
  closeModal,
  setSaveChangesResults,
  updateSubscribedCompaniesCount,
}: InnerModalProps) => {
  const [isDiscoverable, setIsDiscoverable] = useState(channel.isDiscoverable)
  const [showingSubscribedCompanies, toggleShowSubscribedCompanies] = useState(false)

  const [updateSharableSettings] = useMutation<
    UpdateChannelSharableSettings,
    UpdateChannelSharableSettingsVariables
  >(UPDATE_CHANNEL_SHARABLE_SETTINGS, {
    onCompleted: () => {
      closeModal()
    },
  })

  const changesHaveBeenMade = isDiscoverable !== channel.isDiscoverable

  const saveChanges = () =>
    updateSharableSettings({
      variables: {
        channelId: channel.id,
        isDiscoverable: isDiscoverable == null ? false : isDiscoverable,
      },
    })

  return showingSubscribedCompanies ? (
    <EditSubscribedCompanies
      goBack={() => toggleShowSubscribedCompanies(false)}
      channel={channel}
      setSaveChangesResults={setSaveChangesResults}
      updateSubscribedCompaniesCount={updateSubscribedCompaniesCount}
    />
  ) : (
    <>
      <ShareWithMyNetworkSection
        isDiscoverable={isDiscoverable == null ? false : isDiscoverable}
        setIsDiscoverable={setIsDiscoverable}
      />
      <ShareWithSpecificCompanies
        showSubscribedCompanies={() => toggleShowSubscribedCompanies(true)}
        numSubscribedCompanies={channel.subscribedCompaniesCount || 0}
      />
      <ButtonsContainer>
        {changesHaveBeenMade ? (
          <>
            <Button onClick={saveChanges}>{t('save')}</Button>
            <CancelButtonContainer>
              <OutlinedButton onClick={closeModal}>{t('cancel')}</OutlinedButton>
            </CancelButtonContainer>
          </>
        ) : (
          <Button onClick={closeModal}>{t('done')}</Button>
        )}
      </ButtonsContainer>
    </>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`

const CancelButtonContainer = styled.div`
  margin-left: 15px;
  @media (max-width: ${SMALL_PHONE_WIDTH}px) {
    display: none;
  }
`

const ModalHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey900};
  margin-bottom: 28px;
  margin-top: 12px;
`

export const ShareChannelModal = ({ innerRef }: ShareChannelModalProps) => (
  <HandleChannelSubscriptionsProvider>
    <ShareChannelModalInner innerRef={innerRef} />
  </HandleChannelSubscriptionsProvider>
)
