import Marty from 'marty'
import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import Style from 'style'
import $y from 'utilities/yaler'
import containerUtils from 'utilities/containers'
import { t } from 'i18n'
import moment from 'moment-timezone'
import { momentToISO } from 'utilities/time'

import TrainingPlansState from 'state/training-plans'
import CompaniesState from 'state/companies'
import ModulesState from 'state/modules'
import UsersState from 'state/users'

import { Modal } from 'components/common/modals'
import { PrimaryButton } from 'components/common/buttons'
import { LessonCard } from 'components/common/lesson-card'
import { LoadingContainer } from 'shared-js/components/common/loading'
import { ButtonToggle, DatetimeInput } from 'components/common/form-deprecated'
import { TrainingPlanCardDetails } from 'components/common/plan-card-deprecated/card'
import { ManagedHistoryTracker } from 'components/content-management/common/managed-history-tracker.tsx'
import RouterBackButton, {
  editPlanParentLocationReducer,
} from 'components/content-management/common/context-aware-back-button'
import { DueDateSection } from './due-dates'
import { ViewEnrollments } from './view-enrollments'
import { LessonSection } from './lessons'
import { ChannelsSection } from './channels'
import { PlanDetails } from './details'
import { PlanStats } from './details/stats'
import { Analytics } from './analytics'
import { PlanAnnouncement, canAnnounce } from './announcement'

const COLUMN_PANELS = '@media screen and (max-width: 750px)'

const PLAN_DATA = {
  plan: {
    required: true,
    fields: [
      'id',
      'owner.id',
      'owner.url',
      'owner.name',
      'owner.company_logo',
      'name',
      'is_published',
      'published_at',
      'thumbnail_url',
      'custom_thumbnail',
      'modules.id',
      'modules.url',
      'modules.is_attemptable',
      'deactivated',
      'needs_announcement',
      'last_announcement_date',
      'num_enrolled_users',
      'num_enrolled_users_in_own_company',
      'analytics_dashboard_url',

      $y.getFields(PlanDetails, 'plan'),
      $y.getFields(PlanStats, 'plan'),
    ],
  },
}

@Radium
export class PlanDetailsPage extends React.Component {
  static data = PLAN_DATA

  static contextTypes = {
    location: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props, context)
    const learner = this.context.currentUser.learner
    this.state = {
      userCanEditPlan: this.canUserEditPlan(this.context.currentUser),
      userCanViewAnalytics: this.canUserViewAnalytics(learner),
      showArchivedLessons: false,
    }
  }

  canUserEditPlan = (user) =>
    user.company.url === this.props.plan.owner.url && user.learner.can_manage_training_content

  canUserViewAnalytics = (learner) =>
    learner.is_company_admin || learner.is_learner_group_admin || learner.is_area_manager

  showArchive = () => {
    this.setState((prevState) => ({
      showArchivedLessons: !prevState.showArchivedLessons,
    }))
  }

  render() {
    const currentUser = this.context.currentUser
    return (
      <div style={styles.container}>
        {this.props.plan && (
          <ManagedHistoryTracker
            topics={['editLessonParentLocation']}
            type="plan"
            parameters={[this.props.plan.id]}
          />
        )}
        <div style={styles.panelContainer}>
          <div style={styles.leftPanels}>
            <div style={styles.backLink}>
              <RouterBackButton reducer={editPlanParentLocationReducer} />
            </div>
            <div style={styles.panel}>
              <PlanDetails plan={this.props.plan} isEditable={this.state.userCanEditPlan} />
            </div>
            <div style={styles.panel}>
              <LessonSection
                {...this.props}
                currentUser={currentUser}
                userCanEditPlan={this.state.userCanEditPlan}
                showArchivedLessons={this.state.showArchivedLessons}
                showArchive={this.showArchive}
              />
            </div>
          </div>

          <div style={{ ...styles.panel, ...styles.rightPanel }}>
            {currentUser.learner.can_manage_training_content && (
              <div style={{ marginTop: 15 }}>
                {this.state.userCanEditPlan && (
                  <React.Fragment>
                    <PublishToggle plan={this.props.plan} />
                    {/* <PublishDateControls plan={this.props.plan} /> */}
                  </React.Fragment>
                )}

                <PlanAnnouncement
                  key={this.props.plan.last_announcement_date}
                  plan={this.props.plan}
                  style={styles.announcementBtn}
                />
                <div style={styles.break} />
              </div>
            )}

            {this.state.userCanViewAnalytics && (
              <React.Fragment>
                <Analytics plan={this.props.plan} />
                <div style={styles.break} />
              </React.Fragment>
            )}

            {currentUser.learner.can_enroll_others_in_training_content && (
              <React.Fragment>
                <React.Fragment>
                  <DueDateSection plan={this.props.plan} currentUser={currentUser} />
                  <div style={styles.break} />
                </React.Fragment>
                {currentUser.company.subscription.groups_and_areas_enabled && (
                  <React.Fragment>
                    <ViewEnrollments plan={this.props.plan} />
                    <div style={styles.break} />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {currentUser.learner.is_company_admin && this.state.userCanEditPlan && (
              <div>
                <ChannelsSection plan={this.props.plan} currentUser={currentUser} />
              </div>
            )}
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }
}

function PublishToggle({ plan }, context) {
  const onPlanPublishedToggle = () => {
    const planIsCurrentlyPublished = Boolean(plan.is_published)
    const newPublishedValue = planIsCurrentlyPublished ? null : momentToISO(moment())
    TrainingPlansState.ActionCreators.update(plan.id, {
      is_published: !plan.is_published,
      published_at: newPublishedValue,
      name: plan.name,
      owner: plan.owner.url,
    }).then((res) => {
      context.displayTempPositiveMessage({ heading: 'changes_saved' })
      if (res.body.is_published && canAnnounce(plan)) {
        context.router.push({
          pathname: `/views/edit-content/plan/${plan.id}/announce/`,
        })
      }
    })
  }

  return (
    <ButtonToggle
      leftLabel={t('published')}
      rightLabel={t('unpublished')}
      initialValue={plan.is_published ? t('published') : t('unpublished')}
      initialIsAcceptable
      onChange={onPlanPublishedToggle}
      style={styles.buttonToggle}
    />
  )
}
PublishToggle.contextTypes = {
  displayTempPositiveMessage: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
}

class PublishDateControls extends React.Component {
  static propTypes = {
    plan: PropTypes.object.isRequired,
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.publishLaterModal = React.createRef()
  }

  setPublishOn = (datetime) => {
    const publishAt = momentToISO(datetime)
    TrainingPlansState.ActionCreators.update(this.props.plan.id, {
      published_at: publishAt,
      is_published: true,
    }).then(() => {
      this.context.displayTempPositiveMessage({ heading: 'changes_saved' })
    })
    this.publishLaterModal.current.hide()
  }

  publishLater = () => this.publishLaterModal.current.show()

  publishNow = () => {
    const publishAt = momentToISO(moment())
    TrainingPlansState.ActionCreators.update(this.props.plan.id, {
      published_at: publishAt,
      is_published: true,
    }).then(() => {
      context.displayTempPositiveMessage({ heading: 'changes_saved' })
    })
  }

  unpublish = () => {
    TrainingPlansState.ActionCreators.update(this.props.plan.id, {
      published_at: null,
      is_published: false,
    }).then(() => {
      context.displayTempPositiveMessage({ heading: 'changes_saved' })
    })
  }

  render() {
    const plan = this.props.plan
    const publishedAtAfterNow = moment(plan.published_at).isAfter(moment())
    return (
      <React.Fragment>
        {plan.published_at ? (
          <div>
            {/* Is published */}
            {publishedAtAfterNow && (
              <React.Fragaent>
                <p style={styles.planDatePublishText}>
                  {t('plan_scheduled_for_publish', {
                    publishDate: moment(plan.published_at).format('MMMM Do YYYY, h:mm a z'),
                  })}
                </p>
                <div style={{ width: '100%', display: 'flex' }}>
                  <PrimaryButton style={styles.primaryButton} onClick={this.unpublish}>
                    {t('remove_publish_date')}
                  </PrimaryButton>
                </div>
              </React.Fragaent>
            )}
          </div>
        ) : (
          <div style={styles.publishNowLaterContainer}>
            {/* Is unpublished */}
            <div style={styles.publishButtonContainer}>
              <PrimaryButton style={styles.primaryButton} onClick={this.publishNow}>
                {t('publish_now')}
              </PrimaryButton>
            </div>
            <div style={styles.publishButtonContainer}>
              <PrimaryButton style={styles.primaryButton} onClick={this.publishLater}>
                {t('publish_later')}
              </PrimaryButton>
            </div>
            <PublishLaterModal ref={this.publishLaterModal} setPublishOn={this.setPublishOn} />
          </div>
        )}
      </React.Fragment>
    )
  }
}

export class PublishLaterModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      datetime: null,
    }
    this.modal = React.createRef()
  }

  onDateChange = (datetime) => {
    this.setState({ datetime })
  }

  show = () => this.modal.current.show()

  hide = () => this.modal.current.hide()

  publish = () => {
    this.props.setPublishOn(this.state.datetime)
  }

  isValidDate(current) {
    return current.isAfter(moment())
  }

  render() {
    return (
      <Modal style={{ width: '310px' }} ref={this.modal}>
        <div className="content" style={{ textAlign: 'center' }}>
          <div style={styles.dateInputContainer}>
            <DatetimeInput
              isValidDate={this.isValidDate}
              onChange={this.onDateChange}
              input={false}
            />
          </div>
          <PrimaryButton style={[styles.primaryButton, { marginTop: 10 }]} onClick={this.publish}>
            {t('set_publish_date')}
          </PrimaryButton>
        </div>
      </Modal>
    )
  }
}

export class PlanDetailsPageContainer extends React.Component {
  render() {
    return (
      <LoadingContainer
        loadingProps={{ plan: this.props.plan }}
        createComponent={() => <PlanDetailsPage {...this.props} />}
      />
    )
  }
}

export const Page = Marty.createContainer(PlanDetailsPageContainer, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  },
  listenTo: [TrainingPlansState.Store, CompaniesState.Store, UsersState.Store, ModulesState.Store],
  fetch: {
    plan() {
      return TrainingPlansState.Store.getItem(
        this.context.routeParams.planId,
        {
          fields: [
            $y.getFields(PlanDetailsPage, 'plan'),
            $y.getFields(LessonCard, 'module', 'modules'),
            $y.getFields(DueDateSection, 'plan'),
            $y.getFields(TrainingPlanCardDetails, 'trainingPlan'),
          ],
        },
        { dependantOn: [ModulesState.Store, CompaniesState.Store] }
      )
    },
  },

  pending() {
    return containerUtils.defaultPending(this, PlanDetailsPageContainer)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, PlanDetailsPageContainer, errors)
  },
})

const styles = {
  container: {
    background: '#eee',
    minHeight: 'calc(100vh - 80px)',
  },
  buttonToggle: {
    container: { width: '100%' },
  },
  panelContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    background: '#eee',
    width: '100%',
    [COLUMN_PANELS]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  panel: {
    backgroundColor: '#fff',
    marginTop: 10,
    marginLeft: 10,
    marginBottom: 10,
    marginRight: 10,
    padding: 22,
  },
  rightPanel: {
    marginTop: 40,
    maxWidth: 500,
    minWidth: 325,
  },
  leftPanels: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1300,
    flexGrow: 1,
  },
  break: {
    borderTop: '1px solid #e5e5e5',
    borderBottom: '1px solid rgba(0,0,0,0)',
    marginTop: 40,
    marginBottom: 40,
  },
  backLink: {
    color: '#555',
    fontSize: '1.4rem',
    paddingTop: 10,
    paddingLeft: 30,
  },
  announcementBtn: {
    marginTop: 20,
  },
  dateInputContainer: {
    marginBottom: 20,
  },
  publishNowLaterContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  primaryButton: {
    width: '100%',
    borderRadius: 4,
  },
  planDatePublishText: {
    maxWidth: 280,
    padding: 5,
  },
  publishButtonContainer: {
    width: '100%',
    margin: 0,
    padding: 0,
    flex: 1,
    display: 'flex',
  },
}
