import { HoverableLink } from 'components/common/hover'
import { logout } from 'components/common/logout'
import { configureForLocale, getNavigatorLocale, t } from 'i18n'
import logoImg from 'img/logo.svg'
import Marty from 'marty'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import UsersState from 'state/users'
import Style from 'style/index'
import containerUtils from 'utilities/containers'
import { qs } from 'utilities/http'
import { SignUpInfo } from './signup-info'
import ConnectionInvitationMessage from './connection-invitation-message'

const LOGIN_PATH = '/accounts/login/'

function getLocaleFromURL() {
  return qs('locale')
}

@Radium
export class PageInner extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static propTypes = {
    currentUser: PropTypes.object,
  }

  static childContextTypes = {
    currentUser: PropTypes.object,
  }

  getChildContext() {
    return {
      currentUser: this.props.currentUser,
    }
  }

  redirectViaTopBanner = () => {
    const loginPath =
      this.context.location.state && this.context.location.state.link
        ? LOGIN_PATH + this.context.location.state.link
        : LOGIN_PATH + window.location.search
    if (this.props.currentUser) {
      logout()
    } else {
      this.context.router.push({
        pathname: loginPath,
        state: this.context.location.state,
      })
    }
  }

  render() {
    const locale = getLocaleFromURL() || getNavigatorLocale()
    configureForLocale(locale)

    const children = React.cloneElement(this.props.children, {
      currentUser: this.props.currentUser,
    })
    const onRequestSentPage = this.props.children.props.route.name === 'request-sent'
    const hasAConnectionInvitation = Boolean(qs('c'))
    return (
      <div>
        <div style={styles.header}>
          <a href="/">
            <img src={logoImg} style={styles.logo} />
          </a>
          <div style={styles.headerRight}>
            <HoverableLink
              hoverStyle={styles.linkHover}
              style={styles.existingAccountLink}
              onClick={this.redirectViaTopBanner}
            >
              {this.props.currentUser
                ? t('logged_in_check', {
                  name: this.props.currentUser.first_name,
                })
                : t('already_using_myagi')}
              {' '}
              <i style={styles.forwardIcon} className="angle right icon" />
            </HoverableLink>
          </div>
        </div>
        <div style={styles.outerContainer}>
          {hasAConnectionInvitation && <ConnectionInvitationMessage />}
          <div style={styles.container}>
            <div style={styles.infoHalf}>{!onRequestSentPage && <SignUpInfo />}</div>
            <div style={styles.formContainer}>{children}</div>
          </div>
        </div>
      </div>
    )
  }
}

export const Page = Marty.createContainer(PageInner, {
  listenTo: [UsersState.Store],

  fetch: {
    currentUser() {
      const fetch = UsersState.Store.getCurrent()
      return fetch
    },
  },

  pending() {
    return containerUtils.defaultPending(this, PageInner, {
      isFetching: true,
    })
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, PageInner, errors)
  },
})

const styles = {
  header: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    zIndex: 1,
    boxShadow: 'rgba(0,0,0,.2) 0 1px 3px',
    padding: '10px 10px 10px 25px',
    [Style.vars.media.mobile]: {
      padding: 10,
    },
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    padding: 10,
    top: 0,
    right: 0,
    height: '100%',
  },
  logo: {
    height: 28,
    [Style.vars.media.mobile]: {
      height: 24,
      marginTop: 8,
    },
  },
  linkHover: {
    textDecoration: 'underline',
  },
  existingAccountLink: {
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    textAlign: 'center',
    display: 'block',
    cursor: 'pointer',
  },
  forwardIcon: {
    margin: '0, 0, 15px, 0',
  },
  outerContainer: {
    paddingTop: 130,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    color: Style.vars.deprecatedColors.softText,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    width: '80%',
    [Style.vars.media.mobile]: {
      flexDirection: 'column',
      width: '100%',
      paddingTop: 0,
    },
  },
  infoHalf: {
    background: Style.vars.deprecatedColors.white,
    flex: '10%',
    order: 1,
    marginTop: 20,
    position: 'relative',
    [Style.vars.media.mobile]: {
      display: 'none',
    },
  },
  formContainer: {
    flex: '50%',
    order: '2',
    backgroundColor: Style.vars.deprecatedColors.white,
    [Style.vars.media.mobile]: {
      flex: '100%',
      order: '1',
      paddingTop: 100,
    },
  },
}
