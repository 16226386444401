import { LoadingSpinner } from 'components/common/loading'
import Marty from 'marty'
import { Modal } from 'components/common/modals/index'
import PropTypes from 'prop-types'
import React from 'react'
import Style from 'style'
import { TrainingPlanCard } from 'components/common/plan-card-deprecated'
import { TrainingPlanCardDetails } from 'components/common/plan-card-deprecated/card'
import TrainingPlansState from 'state/training-plans'
import { ViewTrainingPlanModal } from 'components/training/plans/plan-modal'
import containerUtils from 'utilities/containers'
import { t } from 'i18n'
import _ from 'lodash'

const TRAINING_PLAN_WIDTH = 100

const styles = {
  badgeCard: {
    width: 50,
    boxShadow: 'none',
    padding: 5,
    margin: 5,
    border: `1px solid ${Style.vars.deprecatedColors.mediumGrey}`,
    borderRadius: 4,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    marginBottom: 30,
    paddingBottom: 30,
    borderBottom: '1px solid #eee',
  },
  badgeImage: {
    height: 50,
    width: 50,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginRight: 20,
  },
  trainingPlans: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  trainingPlan: {
    width: (16 / 9) * TRAINING_PLAN_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },
  trainingPlanThumbnail: {
    height: TRAINING_PLAN_WIDTH,
    width: (16 / 9) * TRAINING_PLAN_WIDTH,
    backgroundColor: 'black',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
}

class BadgeAwardModalInner extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.modalRef = React.createRef()
  }

  componentDidMount() {
    const { badgeAward, show } = this.props
    if (badgeAward && show) {
      this.show()
    }
  }

  show = () => this.modalRef.current.show()

  render() {
    const { badgeAward } = this.props
    if (!badgeAward) return <Modal ref={this.modalRef} />

    const badge = badgeAward.badge
    const discountCode = badge.discount_code || badgeAward.unique_code

    return (
      <div>
        <Modal
          ref={this.modalRef}
          showOnInit={this.props.showOnInit}
          onHidden={this.props.onHidden}
          header={
            <div style={styles.header}>
              <div
                style={{
                  ...styles.badgeImage,
                  backgroundImage: `url(${badge.badge_image})`,
                }}
              />
              <div>{badge.name}</div>
            </div>
          }
        >
          <div className="content">
            <div style={styles.description}>
              {t('congratulations_you_earned_this_badge')}
              <br />

              {badge.description}

              {(badge.discount_url || discountCode) && <div className="ui divider" />}

              {badge.discount_url && (
                <p>
                  <a href={badge.discount_url} target="_blank" rel="noopener">
                    {t('click_here_for_more')}
                  </a>
                </p>
              )}

              {discountCode && (
                <p>
                  <b>{`${t('reward_code')}: `}</b>
                  {discountCode}
                </p>
              )}
            </div>

            <div style={styles.trainingPlans}>
              {this.props.trainingPlans &&
                this.props.trainingPlans.map((tp) => (
                  <TrainingPlanCardDetails
                    key={tp.id}
                    trainingPlan={tp}
                    currentUser={this.props.currentUser}
                    dropDownItems={[]}
                  />
                ))}

              {this.props.trainingPlans === undefined && <LoadingSpinner />}

              {this.props.trainingPlans && this.props.trainingPlans.length == 0 && (
                <div>{t('the_plans_for_this_badge_are_no_longer')}</div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export const BadgeAwardModal = Marty.createContainer(BadgeAwardModalInner, {
  listenTo: [TrainingPlansState.Store],

  fetch: {
    trainingPlans() {
      if (this.props.badgeAward) {
        return TrainingPlansState.Store.getItems({
          badges: this.props.badgeAward.badge.id,
          fields: TrainingPlanCard.data.trainingPlan.fields,
        })
      }
      return null
    },
  },

  show() {
    if (this.innerComponentRef) this.innerComponentRef.show()
  },

  pending() {
    return containerUtils.defaultPending(this, BadgeAwardModalInner)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, BadgeAwardModalInner, errors)
  },
})
