import React from 'react'
import { Link } from 'react-router'
import { t } from 'i18n'

const LEARNING_TAB = '/views/training/channels/'

const PublicPagelink = () => (
  <Link
    to={{
      pathname: LEARNING_TAB,
    }}
  >
    <div style={styles.coverBackIconContainer}>
      <i className="chevron left icon" />
      {t('all_content')}
    </div>
  </Link>
)

export default PublicPagelink

const styles = {
  coverBackIconContainer: {
    position: 'absolute',
    color: 'white',
    cursor: 'pointer',
    fontSize: 14,
    top: 18,
    left: 18,
    borderRadius: 5,
    padding: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
}
