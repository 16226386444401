import React from 'react'
import Im from 'shared-js/immutable'
import _ from 'lodash'
import reactMixin from 'react-mixin'

import PropTypes from 'prop-types'

import Radium from 'radium'

import Style from 'style'
import containerUtils from 'utilities/containers'
import { LoadingContainer } from 'components/common/loading'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import createPaginatedStateContainer from 'state/pagination'

import ChannelsState from 'state/channels'
import NavbarState from 'components/navbar/component-state'
import ChannelShareRequestsState from 'state/channel-share-requests'

import { remoteSearchMixinFactory } from 'components/common/search'
import {
  SortableContainer,
  arrayMove,
  ReorderButton,
  SortableElement,
} from 'components/common/ordering'
import { ChannelCardAdmin } from 'components/common/channel-card/admin'
import { RequestItem } from './request-item'

import PageState, { ALL_CHANNELS, YOUR_CHANNELS, EXTERNAL_CHANNELS } from './page-state'

@SortableElement
class SortableChannelItem extends React.Component {
  render() {
    return (
      <ChannelCardAdmin
        key={this.props.key}
        channel={this.props.channel}
        index={this.props.index}
        reorderEnabled={this.props.reorderEnabled}
      />
    )
  }
}

@SortableContainer
export class ChannelsCollection extends React.Component {
  static propTypes = {
    channels: PropTypes.instanceOf(Im.List).isRequired,
  }

  render() {
    return (
      <div style={styles.channelCollection}>
        {this.props.channels.map((channel, index) => (
          <SortableChannelItem
            key={channel.id}
            channel={channel}
            index={index}
            reorderEnabled={this.props.reorderEnabled}
          />
        ))}
      </div>
    )
  }
}

@reactMixin.decorate(
  remoteSearchMixinFactory(
    PageState.ActionCreators.onSetChannelSearch.bind(PageState.ActionCreators)
  )
)
@Radium
export class ChannelsPage extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static propTypes = {
    channels: PropTypes.instanceOf(Im.List),
    companies: PropTypes.instanceOf(Im.List),
  }

  static defaultProps = {
    companies: Im.freeze([]),
  }

  constructor(props) {
    super()
    this.state = {
      newChannel: Im.freeze({}),
      sortedChannels: props.channels,
      savingOrder: false,
      reorderEnabled: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.channels && this.state.savingOrder === false) {
      // the savingOrder check prevents a flicker on sorting save
      this.setState({ sortedChannels: nextProps.channels })
    }
  }

  onChannelCreated = (newChannel) => {
    this.setState({ newChannel })
    this.refs.createChannelModal.hide()
  }

  saveSorting = () => {
    this.setState({ savingOrder: true })
    const promises = []

    this.state.sortedChannels.forEach((ch, i) => {
      if (ch.order !== i) {
        promises.push(ChannelsState.ActionCreators.update(ch.id, { order: i }))
      }
    })

    Promise.all(promises).then((values) => {
      this.setState({ savingOrder: false })
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      sortedChannels: Im.freeze(arrayMove(this.state.sortedChannels, oldIndex, newIndex)),
    })
  }

  toggleReorder = () => {
    if (this.state.reorderEnabled) {
      _.defer(this.saveSorting)
    }
    this.setState((state) => ({ reorderEnabled: !state.reorderEnabled }))
  }

  setFilter = (newFilter) => {
    if (newFilter) {
      this.context.router.push(`${this.context.location.pathname}?filter=${newFilter}`)
    }
    PageState.ActionCreators.onSetFilter(newFilter)
  }

  render() {
    const { learner, company } = this.context.currentUser
    const showReorder = !this.props.curSearch && this.props.curFilter == YOUR_CHANNELS

    const indicators = company.open_connection_request_count.incoming ? [EXTERNAL_CHANNELS] : []
    const showChannelRequests =
      this.props.curFilter === ALL_CHANNELS || this.props.curFilter === EXTERNAL_CHANNELS
    return (
      <div style={styles.background}>
        {showReorder && learner.can_manage_training_content && (
          <ReorderButton
            containerStyle={styles.createChannelBtn}
            reorderEnabled={this.state.reorderEnabled}
            toggleReorder={this.toggleReorder}
            entity="channels"
          />
        )}
        <div style={{ padding: 50 }}>
          <div style={styles.channelCollection}>
            {showChannelRequests &&
              this.props.requests &&
              this.props.requests.map((request) => (
                <RequestItem key={request.id} request={request} />
              ))}
          </div>
          <LoadingContainer
            loadingProps={{
              channels: this.props.channels,
              // this is a hack to trigger the loading state when saving order
              savingOrder: this.state.savingOrder ? undefined : [],
            }}
            createComponent={(props) => (
              <InfiniteScroll
                loadMore={this.props.loadMore}
                moreAvailable={this.props.moreAvailable}
                isLoading={this.props.isLoading}
              >
                <ChannelsCollection
                  channels={this.state.sortedChannels}
                  shouldCancelStart={() => !this.state.reorderEnabled}
                  onSortEnd={this.onSortEnd}
                  reorderEnabled={this.state.reorderEnabled}
                  axis="xy"
                />
              </InfiniteScroll>
            )}
            noDataText="There are no channels available."
          />
        </div>
      </div>
    )
  }
}

export const Page = createPaginatedStateContainer(ChannelsPage, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  },

  listenTo: [ChannelsState.Store, PageState.Store, ChannelShareRequestsState.Store],

  paginate: {
    store: ChannelsState.Store,
    propName: 'channels',
    limit: 24,
    getQuery() {
      const curFilterQuery = PageState.Store.getCurrentFilterQuery(
        this.context.currentUser,
        this.context.location ? this.context.location.query.filter : null
      )
      const query = _.extend(
        {
          fields: [
            'name',
            'display_name',
            'logo',
            'viable_logo',
            'viable_cover_image',
            'cover_image',
            'cover_image_thumbnail',
            'deactivated',
            'subscribed_companies_count',
            'training_plans_count',
            'active_and_published_training_plans_count',
            'training_plans',
            'public',
            'order',
            'request_to_access',
            'auto_enroll_turned_on_for_current_user_company',
            'company.id',
            'company.cover_image',
            'company.company_logo',
            'company.cover_image_thumbnail',
          ],
        },
        curFilterQuery
      )
      const search = PageState.Store.getChannelSearch()
      if (search) {
        query.search = search
        query.ordering = '-search_rank'
      }
      return query
    },
  },
  fetch: {
    requests() {
      const learner = this.context.currentUser.learner
      if (!learner.can_manage_training_content) return null
      const company = this.context.currentUser.company
      const query = ChannelShareRequestsState.Store.getItems({
        fields: [
          'id',
          'accepted',
          'direction',
          'decided_at',
          'sharedtrainingunit_set.id',
          'url',
          'training_unit.id',
          'training_unit.name',
          'training_unit.display_name',
          'training_unit.logo',
          'training_unit.cover_image',
          'training_unit.company.id',
          'training_unit.company.cover_image',
          'training_unit.company.company_logo',
          'training_unit.company.name',
        ],
        ordering: 'direction,company',
        company: company.id,
        pending: true,
        // currently fetching "all", might need to sort out pagination
        // if this starts to take too long
        limit: 150,
      })
      return query
    },
    curFilter() {
      return PageState.Store.getCurrentFilter()
    },
    curSearch() {
      return PageState.Store.getChannelSearch()
    },
  },

  UNSAFE_componentWillMount() {
    NavbarState.ActionCreators.setTitle('Channels')
    NavbarState.ActionCreators.setInfo(`Channels allow you to group training plans
      and publish them to other companies.
      Subscribing companies can then attempt those training plans.`)
    PageState.Store.resetState()
  },

  pending() {
    return containerUtils.defaultPending(this, ChannelsPage)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, ChannelsPage, errors)
  },
})

const styles = {
  channelCollection: {
    zIndex: 10000,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  background: {
    margin: '0px 20px 30px',
  },
  filterSetContainer: {
    marginTop: -12,
    marginBottom: 25,
    padding: '5px 0px',
    color: Style.vars.deprecatedColors.xxDarkGrey,
    clear: 'both',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  filterButtonContainer: {
    margin: '5px 0 5px 0',
    display: 'inline-block',
  },
  searchAndSortContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 20,
  },
  createChannelBtn: {
    float: 'right',
    [Style.vars.media.tablet]: {
      marginTop: 0,
    },
    [Style.vars.media.mobile]: {
      float: 'left',
      marginTop: 10,
      marginLeft: 0,
    },
  },
  infoBtn: {
    float: 'right',
    marginTop: 10,
    [Style.vars.media.mobile]: {
      float: 'left',
    },
  },
}
