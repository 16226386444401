import PropTypes from 'prop-types'
import React from 'react'
import CompanySettingsState from 'state/companysettings'
import { SlideToggle } from 'components/common/form-deprecated'

const styles = {
  toggleContainer: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
  },
  slideToggle: {
    marginLeft: 10,
  },
}

export class Notifications extends React.Component {
  static data = {
    company: {
      required: true,
      fields: ['companysettings.disable_all_notifications'],
    },
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = {}
  }

  updateCompanySettingsToggle = (opt) => {
    const settings = this.props.company.companysettings
    const data = { [opt]: !settings[opt] }
    CompanySettingsState.ActionCreators.update(settings.id, data).then((res) => {
      this.context.displayTempPositiveMessage({
        heading: 'changes_saved',
      })
    })
  }

  render() {
    return (
      <React.Fragment>
        <h3>Notifications</h3>
        <div style={styles.toggleContainer}>
          <b>Disable all notifications:</b>
          <SlideToggle
            initialValue={this.props.company.companysettings.disable_all_notifications}
            style={styles.slideToggle}
            onChange={() => this.updateCompanySettingsToggle('disable_all_notifications')}
          />
        </div>
      </React.Fragment>
    )
  }
}
