import React from 'react'
import Style from 'style'
import { t } from 'i18n'

export function ProgressBar({
  progress,
  total,
  style,
  barColor,
  textColor,
  barTextColor,
  asPercentage,
}) {
  const percent = ((progress / total) * 100).toFixed(1)
  const overHalfWay = percent > 50

  const textStyle = {
    float: overHalfWay ? 'left' : 'right',
    color: overHalfWay ? barTextColor : textColor,
  }

  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={{ ...styles.text, ...textStyle }}>
        {asPercentage ? `${percent}% ${t('complete')}` : `${progress} / ${total} ${t('complete')}`}
      </div>

      <div style={{ ...styles.bar, backgroundColor: barColor, width: `${percent}%` }} />
    </div>
  )
}
ProgressBar.defaultProps = {
  progress: 0,
  total: 1,
  asPercentage: false,
  containerStyle: {},
  barStyle: {},
  barColor: Style.vars.deprecatedColors.green,
  textColor: '#888',
  barTextColor: '#fff',
}

const styles = {
  container: {
    alignItems: 'center',
    width: '100%',
    height: 16,
    backgroundColor: '#f3f3f3',
    fontSize: '0.8rem',
    overflow: 'hidden',
  },
  bar: {
    height: '100%',
    position: 'relative',
    maxWidth: '100%',
  },
  text: {
    position: 'relative',
    padding: '0px 10px',
    height: '100%',
    zIndex: 2,
  },
}
