import styled from 'styled-components'
import { defaultLogo } from 'core/style-configuration'

type CompanyLogoProps = {
  src: string,
}

export const CompanyLogo = styled.div<CompanyLogoProps>`
  height: 50px;
  min-width: 50px;
  border: ${(props) => (props.src ? `solid 1px #dcdcdc` : `none`)};
  border-radius: 25px;
  margin-right: 15px;
  background-color: ${(props) => (props.src ? 'none' : '#f8f8f8')};
  background-image: ${(props) => (props.src ? `url("${props.src}")` : `url("${defaultLogo}")`)};
  background-repeat: no-repeat;
  background-size: ${(props) => (props.src ? `contain` : '18px 19px')};
  background-position: center;
`

export const CompanyLogoHideOnMobile = styled(CompanyLogo)`
  @media (max-width: 768px) {
    display: none;
  }
`
