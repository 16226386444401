import setupSentry from 'core/setup-sentry'

import { Router, browserHistory } from 'react-router'

import { NamedURLResolver } from 'react-router-named-routes'
import React from 'react'
import ReactDOM from 'react-dom'
import { StyleRoot } from 'radium'
import { routes } from 'core/routes'
import { setupFacebook } from 'core/setup-facebook'
import { setupMaps } from 'core/setup-maps'
import { setupPluralize } from 'core/setup-pluralize'
import setupVectorIcons from './core/setup-vector-icons'

setupSentry()

// Include required CSS files
require('css/vendor/date-range-picker.css')
require('css/vendor/react-datetime.css')
require('react-select/dist/react-select.css')

// node_modules
require('css/vendor/semantic.min.css')
require('react-image-crop/dist/ReactCrop.css')

// Override all styling with our own CSS
require('css/override.css')
require('css/style.css')

// Icons
require('img/flags.png')
require('semantic-ui-css/themes/default/assets/fonts/icons.eot')
require('semantic-ui-css/themes/default/assets/fonts/icons.svg')
require('semantic-ui-css/themes/default/assets/fonts/icons.ttf')
require('semantic-ui-css/themes/default/assets/fonts/icons.woff')
require('semantic-ui-css/themes/default/assets/fonts/icons.woff2')
require('semantic-ui-css/themes/default/assets/fonts/outline-icons.eot')
require('semantic-ui-css/themes/default/assets/fonts/outline-icons.svg')
require('semantic-ui-css/themes/default/assets/fonts/outline-icons.ttf')
require('semantic-ui-css/themes/default/assets/fonts/outline-icons.woff')
require('semantic-ui-css/themes/default/assets/fonts/outline-icons.woff2')

// React-Router v1 removed support for named routes.
// This function re-enables support for them.
NamedURLResolver.mergeRouteTree(routes, '/')

setupVectorIcons()
setupPluralize()
setupFacebook()
setupMaps()

const el = document.getElementById('react-container')
ReactDOM.render(
  <StyleRoot>
    <Router history={browserHistory} routes={routes} />
  </StyleRoot>,
  el
)
