import { RouterBackButton } from 'components/common/box'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React from 'react'
import Style from 'style/index.js'
import ChannelsState from 'state/channels'
import { colors } from 'shared-js/style/colors'
import { EditCoverImage } from './edit-cover-image'
import { Logo } from './logo'
import { NameContainer } from './name-container'

export function ChannelPageCoverImage({ channel, isUneditableChannel }) {
  const coverImage = channel.cover_image || channel.company.cover_image
  const imageURL = `url('${coverImage}')`

  const depricatedOnChannelUpdate = () => {
    ChannelsState.ActionCreators.resetLocalData()
  }

  return (
    <div style={{ ...styles.coverContainer, backgroundImage: imageURL }}>
      <div style={styles.coverInnerContainer}>
        <div style={styles.topRow}>
          <div style={styles.coverBackIconContainer}>
            <RouterBackButton
              text={t('channels')}
              style={styles.back}
              hoverStyle={styles.backHover}
              route="content-channels"
            />
          </div>

          {!isUneditableChannel && (
            <EditCoverImage channel={channel} onChange={depricatedOnChannelUpdate} />
          )}
        </div>

        <div style={styles.coverDetailsContainer}>
          <Logo
            channel={channel}
            onChange={depricatedOnChannelUpdate}
            isEditable={!isUneditableChannel}
          />
          <NameContainer channel={channel} isEditable={!isUneditableChannel} />
        </div>
      </div>
    </div>
  )
}
ChannelPageCoverImage.propTypes = {
  channel: PropTypes.object.isRequired,
}
ChannelPageCoverImage.contextTypes = {
  displayTempPositiveMessage: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
}

export const GET_CHANNEL = {
  channel: {
    required: true,
    fields: [
      'name',
      'display_name',
      'description',
      'logo',
      'cover_image',
      'company.cover_image',
      'company.company_logo',
      'video',
      'avg_like_rating',
      'avg_learn_rating',
    ],
  },
}

const styles = {
  coverContainer: {
    width: '100%',
    minHeight: 400,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  coverInnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  coverDetailsContainer: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  coverBackIconContainer: {
    color: 'white',
    cursor: 'pointer',
    fontSize: 14,
    borderRadius: 5,
    padding: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  back: {
    color: colors.grey200,
  },
  backHover: {
    color: 'white',
  },
}
