import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { t } from 'i18n'
import Style from 'style'
import { nowInISO } from 'utilities/time'
import CompaniesState from 'state/companies'
import { Modal } from 'components/common/modals'
import { Form, SubmitButton } from 'components/common/form-deprecated'
import { AsyncSearchableSelect } from 'components/common/form-deprecated/select'
import { ActionConfirmationModal } from './action-confirmation-modal'

const styles = {
  mergeButton: {
    marginBottom: 5,
    background: Style.vars.deprecatedColors.primary,
    color: Style.vars.deprecatedColors.white,
  },
  deactivateButton: {
    background: Style.vars.deprecatedColors.secondaryBtnBckgGrey,
    color: Style.vars.deprecatedColors.secondaryBtnTextBlack,
  },
  removeDuplicatesButton: {
    background: Style.vars.deprecatedColors.secondaryBtnBckgGrey,
    color: Style.vars.deprecatedColors.secondaryBtnTextBlack,
    borderRadius: 5,
    margin: 5,
    fontSize: 12,
  },
  button: {
    background: Style.vars.deprecatedColors.primary,
    borderRadius: 0,
    width: '40%',
    color: Style.vars.deprecatedColors.white,
  },
  buttonInvalid: {
    background: Style.vars.deprecatedColors.fadedPrimary,
    cursor: 'not-allowed',
  },
}

export class MergeCompaniesModal extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  fetch = (search) => {
    if (!search) return null
    return CompaniesState.Store.getItems({
      limit: 20,
      search,
      ordering: '-search_rank',
      fields: [
        'url',
        'id',
        'user_count',
        'name',
        'company_type',
        'company_url',
        'company_logo',
        'subdomain',
      ],
    })
  }

  makeOption = (u) => {
    const label = `${u.name} (${u.id}) - ${u.user_count} Users`
    return {
      value: u.id,
      label,
    }
  }

  mergeCompanies = (data) => {
    this.setState({ loading: true })
    CompaniesState.ActionCreators.doListAction('run_merge_companies', {
      duplicate_company_id: this.props.company.id,
      original_company_id: data.originalCompanyId,
    })
      .then((res) => {
        CompaniesState.Store.resetState()
        this.hideModal()
        this.setState({ loading: false })
        this.context.displayTempPositiveMessage({
          heading: 'Success',
          body: `You have merged and deactivated ${this.props.company.name}`,
        })
      })
      .catch((err) => {
        console.log(err)
        this.hideModal()
        this.context.displayTempNegativeMessage({
          heading: 'Something went wrong',
          body: 'Please try again...',
        })
      })
  }

  showModal = () => {
    this.mergeCompaniesModal.show()
  }

  hideModal = () => {
    this.mergeCompaniesModal.hide()
  }

  render() {
    const companyName = this.props.company && this.props.company.name
    const companyId = this.props.company && this.props.company.id
    return (
      <Modal
        ref={(element) => (this.mergeCompaniesModal = element)}
        header={`Merging ${companyName} (${companyId})`}
        closeOnDimmerClick={false}
      >
        <p>
          Please pick a company below for
          {companyName}
          {' '}
to merge into
        </p>
        <Form
          onSubmitAndValid={this.mergeCompanies}
          ref={(element) => (this.mergeCompaniesForm = element)}
        >
          <AsyncSearchableSelect
            {...this.props}
            style={{
              textbox: styles.input,
            }}
            fetch={this.fetch}
            makeOption={this.makeOption}
            // breaks if ref isn't a string
            ref="searchableCompany"
            name="originalCompanyId"
            required
          />
          <SubmitButton
            style={styles.button}
            invalidStyle={styles.buttonInvalid}
            text={t('merge')}
            className="btn"
            loading={this.state.loading}
          />
        </Form>
      </Modal>
    )
  }
}

export class Merge extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  showMergeCompanyModal = (data) => {
    this.mergeCompanyContainer.showModal()
  }

  render() {
    return (
      <div>
        <button
          className="ui button"
          onClick={this.showMergeCompanyModal}
          style={styles.mergeButton}
        >
          Merge
        </button>
        <MergeCompaniesModal
          ref={(element) => (this.mergeCompanyContainer = element)}
          company={this.props.company}
        />
      </div>
    )
  }
}

export class Deactivate extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  deactivateCompany = (data) => {
    const companyId = this.props.id
    const p = new Promise((resolve, reject) => {
      CompaniesState.ActionCreators.update(companyId, {
        deactivated: nowInISO(),
      })
      resolve('success')
    })
      .then(() => {
        this.context.displayTempPositiveMessage({
          heading: 'Success',
          body: 'You have deactivated this company',
        })
      })
      .catch((err) => {
        console.log(err)
        this.context.displayTempNegativeMessage({
          heading: "Oh nooooo, it didn't work",
          body: 'Try again...',
        })
      })
    return p
  }

  showActionConfirmationModal = () => {
    this.actionConfirmationContainer.showModal()
  }

  render() {
    return (
      <div>
        <button
          className="ui button"
          onClick={this.showActionConfirmationModal}
          style={styles.deactivateButton}
        >
          Deactivate
        </button>
        <ActionConfirmationModal
          ref={(element) => (this.actionConfirmationContainer = element)}
          action={this.deactivateCompany}
          actionDescription="Deactivate"
          companyId={this.props.id}
        />
      </div>
    )
  }
}
