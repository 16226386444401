import React, { useEffect, useRef } from 'react'
import phone from './iphone.png'

function Simulator({
  width,
  coverImageUrl,
  crop,
  cropperWidth,
  cropperHeight,
  updateResult,
}) {
  const canvasRef = useRef(null)
  const height = (width * phoneHeight) / phoneWidth

  useEffect(() => {
    const dpr = window.devicePixelRatio || 1
    const canvas = canvasRef.current
    if (canvas && crop) {
      canvas.width = width * dpr
      canvas.height = height * dpr
      canvas.style.width = `${width}px`
      canvas.style.height = `${height}px`

      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.setTransform(dpr, 0, 0, dpr, 0, 0)
      }

      const image = new Image()
      image.src = phone
      image.onload = () => {
        if (!ctx) {
          return
        }
        ctx.drawImage(image, 0, 0, width, height)

        const coverImage = new Image()
        coverImage.src = coverImageUrl
        coverImage.onload = () => {
          if (
            !crop.width ||
            !crop.height ||
            !crop.aspect ||
            crop.x === undefined ||
            crop.y === undefined
          ) {
            return
          }

          const coverImageWidth = width - (2 * (160 * width)) / phoneWidth
          const coverImageHeight = coverImageWidth / crop.aspect
          const startDrawX = (160 * width) / phoneWidth
          const startDrawY = (1150 * width) / crop.aspect / phoneHeight
          const scaledCropX = (crop.x * coverImage.naturalWidth) / cropperWidth
          const scaledCropY = (crop.y * coverImage.naturalHeight) / cropperHeight
          const scaledCropWidth = (crop.width * coverImage.naturalWidth) / cropperWidth
          const scaledCropHeight = (crop.height * coverImage.naturalHeight) / cropperHeight

          ctx.save()

          roundedImage(
            ctx,
            startDrawX,
            startDrawY,
            coverImageWidth,
            coverImageHeight,
            (30 * width) / coverImage.naturalWidth
          )
          ctx.clip()

          ctx.drawImage(
            coverImage,
            scaledCropX,
            scaledCropY,
            scaledCropWidth,
            scaledCropHeight,
            startDrawX,
            startDrawY,
            coverImageWidth,
            coverImageHeight
          )

          const grd = ctx.createLinearGradient(0, 0, 0, coverImageWidth)
          grd.addColorStop(0, 'rgba(0, 0, 0, 0)')
          grd.addColorStop(0.33, 'rgba(0, 0, 0, 0.2)')
          grd.addColorStop(0.66, 'rgba(0, 0, 0, 0.4)')
          grd.addColorStop(1, 'rgba(0, 0, 0, 0.8)')

          ctx.fillStyle = grd
          ctx.fillRect(startDrawX, startDrawY, coverImageWidth, coverImageHeight)
          ctx.restore()

          updateResult({
            aspect: crop.aspect,
            x: scaledCropX,
            y: scaledCropY,
            width: scaledCropWidth,
            height: scaledCropHeight,
          })
        }
      }
    }
  }, [width, height, coverImageUrl, crop, cropperWidth, cropperHeight])

  return <canvas ref={canvasRef} width={width} height={height} />
}

export default Simulator

const phoneWidth = 1385
const phoneHeight = 2696

function roundedImage(ctx, x, y, width, height, radius) {
  if (!ctx) return

  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
}
