import React from 'react'
import Im from 'shared-js/immutable'
import PropTypes from 'prop-types'
import Radium from 'radium'
import _ from 'lodash'
import { Link } from 'react-router'

import Style from 'style'

@Radium
export class AvatarImage extends React.Component {
  /*
    Given a `user` prop, renders a circular image
    for that user. Assumes that user has a `profile_photo`
    attr or an attached learner with a `profile_photo` attr.
    If `profile_photo` has not been set, will render initials
    of given user instead. Initials is determine using the
    `first_name` and `last_name` attrs of the user, OR by
    looking for an `initials` attr on the user.
  */

  static data = {
    user: {
      fields: ['first_name', 'last_name', 'profile_photo'],
    },
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    size: PropTypes.string,
    style: PropTypes.object,
    useDefaultImage: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    const size = props.size || '2.5em'

    this.state = {
      style: {
        circular: {
          backgroundColor: Style.vars.deprecatedColors.darkGrey,
          marginBottom: '.25em',
          color: 'white',
          fontSize: 16,
          lineHeight: size,
          borderRadius: '50%',
          width: size,
          height: size,
          textAlign: 'center',
          display: 'block',
          ...props.style,
        },
        overlay: {
          position: 'absolute',
          backgroundColor: 'black',
          borderRadius: '50%',
          opacity: 0.8,
          textAlign: 'center',
          color: 'white',
          marginTop: `-${size}`,
          cursor: 'pointer',
        },
        image: {
          width: size,
          height: size,
          border: '3px solid white',
        },
        noBorder: {
          border: 'none',
          borderRadius: '50%',
        },
        photoIcon: {
          fontSize: 20,
          marginLeft: 5,
        },
        userIcon: {
          fontSize: 70,
          marginLeft: 5,
          marginTop: -3,
          color: 'rgba(255,255,255,0.6)',
        },
      },
      onHover: false,
    }
  }

  getInitials() {
    if (this.props.user.initials !== undefined) {
      return this.props.user.initials
    }
    const firstChar = this.props.user.first_name.charAt(0) || ''
    const lastChar = this.props.user.last_name.charAt(0) || ''
    return firstChar.toUpperCase() + lastChar.toUpperCase()
  }

  render() {
    const { style, onHover } = this.state
    const { onClick, edit, large, user } = this.props
    const src = user.profile_photo

    const hover = Radium.getState(this.state, 'hoverImage', ':hover') && edit

    if (large) {
      return (
        <Link
          to={{ pathname: '/views/settings/', query: { tab: 'Profile' } }}
          style={{ pointerEvents: edit ? 'all' : 'none' }}
        >
          <div
            key="hoverImage"
            style={{
              ...style.circular,
              ...style.noBorder,
              lineHeight: 'inherit',
              ':hover': {},
            }}
          >
            {src ? (
              <img className="ui avatar image" src={src} style={style.image} />
            ) : (
              <div style={style.circular}>
                <i className="icon user" style={style.userIcon} />
              </div>
            )}
            <div
              style={{
                ...style.circular,
                ...style.overlay,
                visibility: hover ? 'visible' : 'hidden',
              }}
            >
              <i className="icon photo" style={style.photoIcon} />
            </div>
          </div>
        </Link>
      )
    }
    if (src) {
      return (
        <img className="ui avatar image" src={src} style={style.circular} onClick={onClick}>
          {this.props.children}
        </img>
      )
    }

    return (
      <div className="ui avatar image" style={style.circular} onClick={onClick}>
        {this.getInitials()}
        {/* Margin is added because initials push children across. This does not happen when
          an image is used, so we just add it here. */}
        <div style={{ marginLeft: -38 }}>{this.props.children}</div>
      </div>
    )
  }
}

const avatarCollectionStyles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}

export class AvatarImageCollection extends React.Component {
  /*
    Renders a grid of avatar images. If `maxImages`
    prop is reached, then final image in grid will be
    a count of remaining, undisplayed images.
  */
  static propTypes = {
    users: PropTypes.instanceOf(Im.List).isRequired,
    containerStyle: PropTypes.object,
    maxImages: PropTypes.number,
  }

  static defaultProps = {
    maxImages: 999,
  }

  constructor(props) {
    super(props)
    this.state = {
      style: {
        container: _.extend(avatarCollectionStyles.container, this.props.containerStyle),
      },
    }
  }

  render() {
    const i = 0
    let imagesToShow = null
    const fullCount = _.size(this.props.users)
    let undisplayedCount = 0
    let undisplayedCountAvatar = null
    if (fullCount <= this.props.maxImages) {
      imagesToShow = this.props.users
    } else {
      imagesToShow = this.props.users.slice(0, this.props.maxImages - 1)
      undisplayedCount = fullCount - this.props.maxImages + 1
      const pseudoUser = Im.freeze({
        initials: `+${undisplayedCount}`,
      })
      undisplayedCountAvatar = <AvatarImage key="undisplayed" user={pseudoUser} />
    }
    return (
      <div style={this.state.style.container}>
        {imagesToShow.map((user) => (
          <AvatarImage key={user.id} user={user} />
        ))}
        {undisplayedCountAvatar}
      </div>
    )
  }
}
