// If you want to update these names, make sure you also
// update the regions available in myagi_common/fields.py.

// NOTE: Some continents have been removed to force users
// to select more granular regions (e.g. we had a lot of people signing up
// from germany but selecting "Europe" because that was the first option).
// This is a quick, short term solution. If necessary, we could just move these
// to the bottom of the list.
export const REGION_OPTIONS = [
  { label: 'afghanistan', value: 'Afghanistan' },
  { label: 'africa', value: 'Africa' },
  { label: 'albania', value: 'Albania' },
  { label: 'algeria', value: 'Algeria' },
  { label: 'andorra', value: 'Andorra' },
  { label: 'angola', value: 'Angola' },
  { label: 'anguilla', value: 'Anguilla' },
  { label: 'antigua_and_barbuda', value: 'Antigua & Barbuda' },
  { label: 'argentina', value: 'Argentina' },
  { label: 'armenia', value: 'Armenia' },
  { label: 'asia', value: 'Asia' },
  { label: 'australia', value: 'Australia' },
  { label: 'australia_and_new_zealand', value: 'Australia & New Zealand' },
  { label: 'austria', value: 'Austria' },
  { label: 'azerbaijan', value: 'Azerbaijan' },
  { label: 'bahamas', value: 'Bahamas' },
  { label: 'bahrain', value: 'Bahrain' },
  { label: 'bangladesh', value: 'Bangladesh' },
  { label: 'barbados', value: 'Barbados' },
  { label: 'belarus', value: 'Belarus' },
  { label: 'belgium', value: 'Belgium' },
  { label: 'belize', value: 'Belize' },
  { label: 'benelux', value: 'Benelux' },
  { label: 'benin', value: 'Benin' },
  { label: 'bermuda', value: 'Bermuda' },
  { label: 'bhutan', value: 'Bhutan' },
  { label: 'bolivia', value: 'Bolivia' },
  { label: 'bosnia_and_herzegovina', value: 'Bosnia & Herzegovina' },
  { label: 'botswana', value: 'Botswana' },
  { label: 'brazil', value: 'Brazil' },
  { label: 'brunei_darussalam', value: 'Brunei Darussalam' },
  { label: 'bulgaria', value: 'Bulgaria' },
  { label: 'burkina_faso', value: 'Burkina Faso' },
  { label: 'burundi', value: 'Burundi' },
  { label: 'cambodia', value: 'Cambodia' },
  { label: 'cameroon', value: 'Cameroon' },
  { label: 'canada', value: 'Canada' },
  { label: 'cape_verde', value: 'Cape Verde' },
  { label: 'cayman_islands', value: 'Cayman Islands' },
  { label: 'central_and_south_america', value: 'Central & South America' },
  { label: 'central_african_republic', value: 'Central African Republic' },
  { label: 'chad', value: 'Chad' },
  { label: 'chile', value: 'Chile' },
  { label: 'china', value: 'China' },
  { label: 'colombia', value: 'Colombia' },
  { label: 'comoros', value: 'Comoros' },
  { label: 'congo', value: 'Congo' },
  { label: 'costa_rica', value: 'Costa Rica' },
  { label: 'croatia', value: 'Croatia' },
  { label: 'cuba', value: 'Cuba' },
  { label: 'cyprus', value: 'Cyprus' },
  { label: 'czechia', value: 'Czechia' },
  {
    label: 'democratic_republic_of_the_congo',
    value: 'Democratic Republic of the Congo',
  },
  { label: 'denmark', value: 'Denmark' },
  { label: 'djibouti', value: 'Djibouti' },
  { label: 'dominica', value: 'Dominica' },
  { label: 'dominican_republic', value: 'Dominican Republic' },
  { label: 'ecuador', value: 'Ecuador' },
  { label: 'egypt', value: 'Egypt' },
  { label: 'el_salvador', value: 'El Salvador' },
  { label: 'equatorial_guinea', value: 'Equatorial Guinea' },
  { label: 'eritrea', value: 'Eritrea' },
  { label: 'estonia', value: 'Estonia' },
  { label: 'ethiopia', value: 'Ethiopia' },
  { label: 'europe', value: 'Europe' },
  { label: 'fiji', value: 'Fiji' },
  { label: 'finland', value: 'Finland' },
  { label: 'france', value: 'France' },
  { label: 'french_guiana', value: 'French Guiana' },
  { label: 'gabon', value: 'Gabon' },
  { label: 'gambia', value: 'Gambia' },
  { label: 'georgia', value: 'Georgia' },
  { label: 'germany', value: 'Germany' },
  { label: 'ghana', value: 'Ghana' },
  { label: 'global', value: 'Global' },
  { label: 'greece', value: 'Greece' },
  { label: 'grenada', value: 'Grenada' },
  { label: 'guadeloupe', value: 'Guadeloupe' },
  { label: 'guam', value: 'Guam' },
  { label: 'guatemala', value: 'Guatemala' },
  { label: 'guinea', value: 'Guinea' },
  { label: 'guineabissau', value: 'Guinea-Bissau' },
  { label: 'guyana', value: 'Guyana' },
  { label: 'haiti', value: 'Haiti' },
  { label: 'honduras', value: 'Honduras' },
  { label: 'hungary', value: 'Hungary' },
  { label: 'iceland', value: 'Iceland' },
  { label: 'india', value: 'India' },
  { label: 'indonesia', value: 'Indonesia' },
  { label: 'iran', value: 'Iran' },
  { label: 'iraq', value: 'Iraq' },
  { label: 'ireland', value: 'Ireland' },
  {
    label: 'israel',
    value: 'Israel',
  },
  { label: 'italy', value: 'Italy' },
  { label: 'ivory_coast_cote_divoire', value: "Ivory Coast (Cote d'Ivoire)" },
  { label: 'jamaica', value: 'Jamaica' },
  { label: 'japan', value: 'Japan' },
  { label: 'jordan', value: 'Jordan' },
  { label: 'kazakhstan', value: 'Kazakhstan' },
  { label: 'kenya', value: 'Kenya' },
  { label: 'kosovo', value: 'Kosovo' },
  { label: 'kuwait', value: 'Kuwait' },
  {
    label: 'kyrgyz_republic_kyrgyzstan',
    value: 'Kyrgyz Republic (Kyrgyzstan)',
  },
  { label: 'laos', value: 'Laos' },
  { label: 'latvia', value: 'Latvia' },
  { label: 'lebanon', value: 'Lebanon' },
  { label: 'lesotho', value: 'Lesotho' },
  { label: 'liberia', value: 'Liberia' },
  { label: 'libya', value: 'Libya' },
  { label: 'liechtenstein', value: 'Liechtenstein' },
  { label: 'lithuania', value: 'Lithuania' },
  { label: 'luxembourg', value: 'Luxembourg' },
  { label: 'madagascar', value: 'Madagascar' },
  { label: 'malawi', value: 'Malawi' },
  { label: 'malaysia', value: 'Malaysia' },
  { label: 'maldives', value: 'Maldives' },
  { label: 'mali', value: 'Mali' },
  { label: 'malta', value: 'Malta' },
  { label: 'martinique', value: 'Martinique' },
  { label: 'mauritania', value: 'Mauritania' },
  { label: 'mauritius', value: 'Mauritius' },
  { label: 'mayotte', value: 'Mayotte' },
  { label: 'mexico', value: 'Mexico' },
  { label: 'moldova_republic_of', value: 'Moldova, Republic of' },
  { label: 'monaco', value: 'Monaco' },
  { label: 'mongolia', value: 'Mongolia' },
  { label: 'montenegro', value: 'Montenegro' },
  { label: 'montserrat', value: 'Montserrat' },
  { label: 'morocco', value: 'Morocco' },
  { label: 'mozambique', value: 'Mozambique' },
  { label: 'myanmarburma', value: 'Myanmar/Burma' },
  { label: 'namibia', value: 'Namibia' },
  { label: 'nepal', value: 'Nepal' },
  { label: 'netherlands', value: 'Netherlands' },
  { label: 'new_zealand', value: 'New Zealand' },
  { label: 'nicaragua', value: 'Nicaragua' },
  { label: 'niger', value: 'Niger' },
  { label: 'nigeria', value: 'Nigeria' },
  { label: 'norway', value: 'Norway' },
  { label: 'north_america', value: 'North America' },
  { label: 'oman', value: 'Oman' },
  { label: 'other', value: 'Other' },
  { label: 'pacific_islands', value: 'Pacific Islands' },
  { label: 'pakistan', value: 'Pakistan' },
  { label: 'panama', value: 'Panama' },
  { label: 'papua_new_guinea', value: 'Papua New Guinea' },
  { label: 'paraguay', value: 'Paraguay' },
  { label: 'peru', value: 'Peru' },
  { label: 'philippines', value: 'Philippines' },
  { label: 'poland', value: 'Poland' },
  { label: 'portugal', value: 'Portugal' },
  { label: 'puerto_rico', value: 'Puerto Rico' },
  { label: 'qatar', value: 'Qatar' },
  { label: 'republic_of_macedonia', value: 'Republic of Macedonia' },
  { label: 'reunion', value: 'Reunion' },
  { label: 'romania', value: 'Romania' },
  { label: 'russia', value: 'Russia' },
  { label: 'rwanda', value: 'Rwanda' },
  { label: 'saint_kitts_and_nevis', value: 'Saint Kitts and Nevis' },
  { label: 'saint_lucia', value: 'Saint Lucia' },
  {
    label: 'saint_vincents_and_grenadines',
    value: "Saint Vincent's & Grenadines",
  },
  { label: 'samoa', value: 'Samoa' },
  { label: 'sao_tome_and_principe', value: 'Sao Tome and Principe' },
  { label: 'saudi_arabia', value: 'Saudi Arabia' },
  { label: 'senegal', value: 'Senegal' },
  { label: 'serbia', value: 'Serbia' },
  { label: 'seychelles', value: 'Seychelles' },
  { label: 'sierra_leone', value: 'Sierra Leone' },
  { label: 'singapore', value: 'Singapore' },
  { label: 'slovakia', value: 'Slovakia' },
  { label: 'slovenia', value: 'Slovenia' },
  { label: 'solomon_islands', value: 'Solomon Islands' },
  { label: 'somalia', value: 'Somalia' },
  { label: 'south_africa', value: 'South Africa' },
  { label: 'south_korea', value: 'South Korea' },
  { label: 'south_sudan', value: 'South Sudan' },
  { label: 'southeast_asia', value: 'South-East Asia' },
  { label: 'spain', value: 'Spain' },
  { label: 'sri_lanka', value: 'Sri Lanka' },
  { label: 'sudan', value: 'Sudan' },
  { label: 'suriname', value: 'Suriname' },
  { label: 'swaziland', value: 'Swaziland' },
  { label: 'sweden', value: 'Sweden' },
  { label: 'switzerland', value: 'Switzerland' },
  { label: 'syria', value: 'Syria' },
  { label: 'tajikistan', value: 'Tajikistan' },
  { label: 'taiwan', value: 'Taiwan' },
  { label: 'tanzania', value: 'Tanzania' },
  { label: 'thailand', value: 'Thailand' },
  { label: 'the_middle_east', value: 'The Middle East' },
  { label: 'the_nordics', value: 'The Nordics' },
  { label: 'timor_leste', value: 'Timor Leste' },
  { label: 'togo', value: 'Togo' },
  { label: 'trinidad_and_tobago', value: 'Trinidad & Tobago' },
  { label: 'tunisia', value: 'Tunisia' },
  { label: 'turkey', value: 'Turkey' },
  { label: 'turkmenistan', value: 'Turkmenistan' },
  { label: 'turks_and_caicos_islands', value: 'Turks & Caicos Islands' },
  { label: 'uganda', value: 'Uganda' },
  { label: 'ukraine', value: 'Ukraine' },
  { label: 'united_arab_emirates', value: 'United Arab Emirates' },
  { label: 'united_states', value: 'United States' },
  { label: 'united_kingdom', value: 'United Kingdom' },
  { label: 'united_kingdom_and_ireland', value: 'United Kingdom & Ireland' },
  { label: 'uruguay', value: 'Uruguay' },
  { label: 'uzbekistan', value: 'Uzbekistan' },
  { label: 'venezuela', value: 'Venezuela' },
  { label: 'vietnam', value: 'Vietnam' },
  { label: 'virgin_islands_uk', value: 'Virgin Islands (UK)' },
  { label: 'virgin_islands_us', value: 'Virgin Islands (US)' },
  { label: 'yemen', value: 'Yemen' },
  { label: 'zambia', value: 'Zambia' },
  { label: 'zimbabwe', value: 'Zimbabwe' },
  { label: 'san_marino', value: 'San Marino' },
  { label: 'new_caledonia', value: 'New Caledonia' },
]

export const GENERAL_REGIONS = [
  'Global',
  'Europe',
  'Australia & New Zealand',
  'United Kingdom & Ireland',
  'Benelux',
  'The Nordics',
  'Central & South America',
  'The Middle East',
  'South-East Asia',
  'Africa',
]
