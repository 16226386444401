import React from 'react'
import gql from 'graphql-tag'
import t from 'shared-js/i18n'
import { SearchableAsyncSelect } from '.'
import { GetTeams_Team__List_edges_node } from './gql-types/GetTeams'

const GET_TEAMS = gql`
  query GetTeams($search: String, $orderBy: [String]!) {
    Team__List(first: 100, search: $search, orderBy: $orderBy) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

type TeamAsyncSelectProps = {
  team: GetTeams_Team__List_edges_node
  onChange(team: GetTeams_Team__List_edges_node): void
}

const TeamAsyncSelect = ({ team, onChange }: TeamAsyncSelectProps) => (
  <SearchableAsyncSelect
    query={GET_TEAMS}
    defaultOptions={team ? { id: team.id, label: team.name, value: team.id } : null}
    entityName="Team__List"
    onChange={onChange}
    placeholder={t('no_team')}
    multi={false}
  />
)

export default TeamAsyncSelect
