import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import _ from 'lodash'
import ReactCrop from 'react-image-crop'
import { validateImage } from 'utilities/validators'
import { useToastMessage, useHover } from 'shared-js/utilities/hooks'
import { MAX_FILE_SIZE } from 'core/constants'
import { SUPPORTED_IMAGE_MIME_TYPES } from 'shared-js/constants'

const DEFAULT_ASPECT_RATIO = 16 / 9
const DEFAULT_WIDTH = 500
const DEFAULT_NAME = 'upload'
const DEFAULT_FILE_TYPE = 'jpg'
const IMAGE_MIME_TYPE_MAP = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
}

function getImageExtension(image) {
  if (!image) return DEFAULT_FILE_TYPE
  return _.invert(IMAGE_MIME_TYPE_MAP)[image.type] || DEFAULT_FILE_TYPE
}

/**
 * An image cropper that outputs an image file on crop.
 *
 * @description Includes controls for users to select a new image
 * from their computer and crop it. The crop is converted to an image
 * file (default is the original type unless `fileType` is specified)
 * and passed to the `onCrop` prop.
 *
 * Cropping a remote image while in development is not possible
 * due to CORS but will work in production.
 */
export default function ImageCropper({
  initialValue,
  aspectRatio,
  style,
  width,
  name,
  fileType,
  onCrop,
}) {
  const imgRef = useRef()
  const height = width / (aspectRatio || DEFAULT_ASPECT_RATIO)
  const [cropShape, setCropShape] = useState({ aspect: aspectRatio, width })
  const [image, setImage] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [cropInitial, setCropInitial] = useState(false)
  const fileExtention = fileType || getImageExtension(image)

  const showAddImage = !!(!image && !imageUrl)
  const showLoading = !!(image && !imageUrl)
  const showCroppingImage = !!((image || cropInitial) && imageUrl)
  const showInitialImage = !!(!image && !cropInitial && imageUrl)

  // We're using `useEffect` to create the image url as it will then
  // automatically call `revokeObjectURL` when dismounting.
  useEffect(() => {
    if (!image) return () => {}
    setImageUrl(URL.createObjectURL(image))
    return () => window.URL.revokeObjectURL(image)
  }, [image])

  const handelComplete = (crop) => {
    if (!onCrop) return
    const img = imgRef.current
    // We want to use the natural image size so we don't loose any quality.
    // However, because all crop dimentions are relative to the size on the
    // screen, they all have to be multiplied by a factor.
    const scaleX = img.naturalWidth / img.width
    const scaleY = img.naturalHeight / img.height

    const canvas = document.createElement('canvas')
    canvas.width = crop.width * scaleX
    canvas.height = crop.height * scaleY
    const canvas2d = canvas.getContext('2d')

    canvas2d.drawImage(
      img,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    )

    canvas.toBlob((blob) => {
      blob.name = `${name}.${fileExtention}` // eslint-disable-line
      onCrop(blob, crop)
    }, IMAGE_MIME_TYPE_MAP[fileExtention])
  }

  const handleClear = useCallback(
    (event) => {
      event.preventDefault()
      setImage(null)
      setImageUrl(null)
      setCropInitial(false)
      setCropShape({ aspect: aspectRatio, width })
    },
    [aspectRatio, width]
  )

  const handleReset = useCallback(
    (event) => {
      event.preventDefault()
      setImage(null)
      setImageUrl(initialValue)
      setCropInitial(false)
      setCropShape({ aspect: aspectRatio, width })
    },
    [aspectRatio, initialValue, width]
  )

  // CropButton is currently hidden so this is unneeded for now.
  // const handleCrop = useCallback(
  //   (event) => {
  //     event.preventDefault()
  //     setImage(null)
  //     setImageUrl(initialValue)
  //     setCropInitial(true)
  //     setCropShape({ aspect: aspectRatio, width })
  //   },
  //   [aspectRatio, initialValue, width]
  // )

  const handleImageLoaded = (img) => {
    imgRef.current = img
    const cropShape = { x: 0, y: 0, width: img.width, height: img.height }
    if (!aspectRatio) {
      // If no aspectRatio is specified, we ensure the crop area is auto-selected by setting the
      // crop area to be (almost) as big as the image dimentions. Making the auto crop area slightly
      // smaller makes the cropping controls much more noticable.
      // eslint-disable-next-line id-length
      setCropShape(cropShape)
    }
    handelComplete(cropShape)
  }

  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={styles.controlsContainer}>
        <div style={styles.controlsText}>
          {showCroppingImage && !cropShape.height && t('select_crop_area')}
        </div>
        {/*
          Removing this crop-intitial-image button until we sort out the CORS issue. This is
          because images are stored using cloudfrount and browsers view manipulating images from
          different domains with the canvas element as insecure.
          {showInitialImage && <CropButton onClick={handleCrop} />}
         */}
        {(showCroppingImage || (showAddImage && initialValue)) && (
          <ResetButton onClick={handleReset} />
        )}
        {imageUrl && <RemoveButton onClick={handleClear} />}
      </div>

      {showCroppingImage && (
        <div style={{ ...styles.imageContainer, width, minHeight: height }}>
          <ReactCrop
            style={styles.imageCropper}
            imageStyle={styles.image}
            keepSelection
            crop={cropShape}
            src={imageUrl}
            onImageLoaded={handleImageLoaded}
            onImageError={(error) => console.error(error)}
            onChange={setCropShape}
            onComplete={handelComplete}
            crossorigin="anonymous"
          />
        </div>
      )}

      {showInitialImage && (
        <div style={{ ...styles.imageContainer, width }}>
          <img src={imageUrl} style={styles.image} />
        </div>
      )}

      {(showAddImage || showLoading) && (
        <div style={{ ...styles.addImageContainer, width, height }}>
          {showLoading ? t('loading_with_dots') : <AddImageButton onAdd={setImage} />}
        </div>
      )}
    </div>
  )
}
ImageCropper.propTypes = {
  initialValue: PropTypes.string,
  aspectRatio: PropTypes.number,
  style: PropTypes.object,
  name: PropTypes.string,
  fileType: PropTypes.oneOf(['jpg', 'png']),
  onCrop: PropTypes.func,
  width: PropTypes.number,
}
ImageCropper.defaultProps = {
  name: DEFAULT_NAME,
  width: DEFAULT_WIDTH,
}

function AddImageButton({ onAdd }) {
  const toast = useToastMessage()
  // For when there are multiple buttons on the same page
  const inputId = useRef(`input${Math.random()}`)
  const [isHovered, bindHover] = useHover()

  const onInputChange = (event) => {
    const file = event.target.files[0]
    if (!validateImage(file)) {
      toast.showNegative(t('error_png_jpg'))
    } else if (file.size > MAX_FILE_SIZE) {
      toast.showNegative(t('error_file_too_large'))
    } else {
      onAdd(file)
    }
  }

  return (
    <React.Fragment>
      <label
        htmlFor={inputId.current}
        style={{
          ...styles.addImageButton,
          ...(isHovered && styles.addImageButtonHover),
        }}
        {...bindHover}
      >
        <i style={styles.addImageIcon} className="ui icon file image outline" />
        <div style={styles.selectImageText}>{t('add_image')}</div>
      </label>
      <input
        style={styles.fileInput}
        type="file"
        accept={SUPPORTED_IMAGE_MIME_TYPES.join()}
        id={inputId.current}
        onChange={onInputChange}
      />
    </React.Fragment>
  )
}
AddImageButton.propTypes = {
  onAdd: PropTypes.func.isRequired,
}

const ResetButton = (props) => <CropControlButton {...props} icon="repeat" />

const CropButton = (props) => <CropControlButton {...props} icon="crop" />

const RemoveButton = (props) => <CropControlButton {...props} icon="remove" />

const CropControlButton = ({ icon, onClick }) => {
  const [isHovered, bindHover] = useHover()
  return (
    <button
      type="button"
      style={{ ...styles.controlButton, color: isHovered ? 'black' : 'grey' }}
      onClick={onClick}
      {...bindHover}
    >
      <i className={`ui icon ${icon}`} />
    </button>
  )
}
CropControlButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

const styles = {
  container: {
    maxWidth: '100%',
  },

  controlsContainer: {
    flex: 1,
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  controlsText: {
    flex: 1,
    padding: '0 10px',
  },
  controlButton: {
    cursor: 'pointer',
    padding: 8,
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0)',
    transition: 'all 200ms ease',
  },

  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.05)',
    maxWidth: '100%',
  },
  imageCropper: {
    maxWidth: '100%',
    backgroundColor: '#FFF',
  },
  image: {
    maxWidth: '100%',
    maxHeight: 500,
    backgroundColor: 'rgba(0,0,0,0.05)',
  },

  addImageContainer: {
    flex: 1,
    maxWidth: '100%',
    backgroundColor: 'rgba(0,0,0,0.05)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addImageButton: {
    color: '#666',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addImageButtonHover: {
    color: 'black',
  },
  addImageIcon: {
    fontSize: '3rem',
    lineHeight: '54px',
    margin: 0,
    height: 'auto',
  },
  fileInput: {
    display: 'none',
  },
}

/**
 * A low performance polyfill based on toDataURL.
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob Original source}
 */
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value(callback, type, quality) {
      const dataURL = this.toDataURL(type, quality).split(',')[1]
      setTimeout(() => {
        const binStr = atob(dataURL)
        const len = binStr.length
        const arr = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        callback(
          new Blob([arr], {
            type: type || IMAGE_MIME_TYPE_MAP[DEFAULT_FILE_TYPE],
          })
        )
      })
    },
  })
}
