import React from 'react'
import PropTypes from 'prop-types'
import ImageCropper from './image-cropper'

/**
 * A wrapper around `ImageCropper` for use in `<Form>` components.
 */
export default class ImageCropperInput extends React.Component {
  static propTypes = {
    ...ImageCropper.propTypes,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
  }

  static defaultProps = {
    required: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      croppedImage: null,
    }
  }

  getValue = () => this.state.croppedImage

  getNameAndValue = () => ({ [this.props.name]: this.state.croppedImage })

  isValid = () => (this.props.required ? this.hasValue() : true)

  hasValue = () => !!this.state.croppedImage

  handleOnCrop = (croppedImage, pixelCrop) => {
    this.setState({ croppedImage })
    if (this.props.onCrop) this.props.onCrop(croppedImage, pixelCrop)
  }

  render() {
    return <ImageCropper {...this.props} onCrop={this.handleOnCrop} />
  }
}
