import Announcement from 'components/app/announcement'
import { EmailInput, Form, PasswordInput, SubmitButton } from 'components/common/form-deprecated'
import { Checkbox } from 'components/common/form-deprecated/checkbox'
import { Info } from 'components/common/info'
import { shouldRenderNoData } from 'mixins/loading'
import { LoadingContainer } from 'components/common/loading'
import { PublicStyling } from 'components/common/public-styling'
import { t } from 'i18n'
import MYAGI_LOGO from 'img/logo.svg'
import _ from 'lodash'
import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import PublicCompaniesState from 'state/public-companies'
import PublicUsersState from 'state/public-users'
import Style from 'style'
import StyleCustomization from 'style/customization'
import containerUtils from 'utilities/containers'
import { getSubdomain } from 'utilities/generic'
import { getOrigin, qs } from 'utilities/http'
import $y from 'utilities/yaler'
import Radium from 'radium'
import CreateCompanyConnectionMutationContainer from 'components/accounts/create-company-connection-container'
import { connectToSharelink, goToSharelinkChannelPage, goToRelevantNetworkSection } from '../common'
import { SocialLoginButtons } from './social-login-buttons'

@Radium
class LoginPage extends React.Component {
  static data = {
    company: {
      required: false,
      fields: [
        'id',
        'url',
        'name',
        'company_logo',
        'companysettings.style_customization_enabled',
        'companysettings.nav_logo',
        'companysettings.nav_color',
        'companysettings.primary_color',
      ],
    },
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor() {
    super()
    this.state = {
      loginError: null,
      loading: false,
      incorrectPassword: false,
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.company) {
      StyleCustomization.setStylingForCompany(this.props.company)
    }
  }

  onSubmitAndValid = (data) => {
    this.setState({ loading: true })
    PublicUsersState.ActionCreators.doListAction('login', data)
      .then((res) => {
        if (qs('c') && res.body.company.id) {
          // the user used a connection invitation link
          this.props.createCompanyConnection(res.body.company.id)
          if (res.body.is_company_admin) goToRelevantNetworkSection(res.body.company.company_type)
          return
        }
        const link = this.props.link
        let next = '/views/home/'
        if (link) {
          connectToSharelink(link).then(() => goToSharelinkChannelPage(link))
          return
        }
        const nextQS = qs('next')
        if (nextQS) {
          next = nextQS
        }
        // page must be re-loaded to fetch new CSRF token
        window.location.href = getOrigin() + next
      })
      .catch((err) => {
        let error
        // Default error code
        let errorCode = 403
        if (err.response && err.response.status) {
          errorCode = err.response.status
        }
        if (errorCode === 400) {
          this.setState({ incorrectPassword: true })
          error = 'invalid_email_or_password'
        }
        if (errorCode === 302) {
          window.location.href = `${getOrigin()}/signup/company/`
        }
        if (errorCode === 403) error = t('account_deactivated')
        this.setState({ loginError: error, loading: false })
      })
  }

  onInputChange = () => {
    this.setState({ loginError: null })
  }

  render() {
    let logoURL = MYAGI_LOGO
    const companyName = this.props.company && this.props.company.name
    const companyLogo = this.props.company && this.props.company.company_logo
    if (companyLogo) {
      logoURL = companyLogo
    }
    const logo = <div style={{ ...styles.logo, backgroundImage: `url(${logoURL})` }} />
    const primary = Style.vars.deprecatedColors.primary.toString().startsWith('#ff')
      ? Style.vars.deprecatedColors.darkPrimary
      : Style.vars.deprecatedColors.primary

    const ssoLoginUrl =
      (this.props.subdomainCompany && this.props.subdomainCompany.force_sso_via_url) || null

    return (
      <div>
        <Announcement />
        <div style={styles.container}>
          {logo}

          {ssoLoginUrl && (
            <React.Fragment>
              <SSOLogin url={ssoLoginUrl} companyName={companyName} primaryColor={primary} />
              <div style={styles.signupText}>{t('or')}</div>
            </React.Fragment>
          )}

          <Form onSubmitAndValid={this.onSubmitAndValid} style={styles.signInForm}>
            <EmailInput
              name="email"
              placeholder={t('email')}
              placeholderColor="darkGrey"
              style={styles.input}
              onChange={this.onInputChange}
              required
              autoComplete="on"
              className="fs-sensitive"
              autoFocus
            />
            <hr style={styles.hr} />

            <PasswordInput
              name="password"
              placeholder={t('password')}
              placeholderColor="darkGrey"
              style={styles.input}
              onChange={this.onInputChange}
              required
              autoComplete="on"
              className="fs-sensitive"
            />
            <hr style={styles.hr} />

            <div style={styles.errorCode}>
              {this.state.loginError ? <p>{t(this.state.loginError)}</p> : null}
              {this.state.incorrectPassword ? (
                <a
                  style={styles.forgotPassword}
                  href="/accounts/password-reset/"
                  onClick={this.onForgotPasswordClick}
                >
                  {t('forgot_your_password')}
                </a>
              ) : null}
            </div>

            <div style={{ textAlign: 'center', marginTop: 40 }}>
              <Checkbox
                style={{ marginTop: 3 }}
                onChange={this.onInputChange}
                label={t('i_am_using_a_shared_computer')}
                name="publicComputer"
              />
              <span style={styles.checkboxLabel}>{t('i_am_using_a_shared_computer')}</span>
              <Info style={{ marginLeft: 3 }} content={t('you_will_be_logged_out')} />
            </div>

            <SubmitButton
              style={{ ...styles.signInButton, backgroundColor: primary }}
              text={t('log_in')}
              loading={this.state.loading}
            />

            <div style={styles.signupText}>{t('or')}</div>
          </Form>
          <SocialLoginButtons subdomain={this.props.subdomain} />
          <a
            key="forgotPasswordLink"
            style={styles.bottomLink}
            href="/accounts/password-reset/"
            onClick={this.onForgotPasswordClick}
          >
            {t('forgot_your_password')}
          </a>
        </div>
      </div>
    )
  }
}

function SSOLogin({ url, companyName, primaryColor }) {
  let fullUrl = url

  // Add a return url to the SSO url for various identity providers if needed
  const next = qs('next')
  if (next) {
    const returnUrl = encodeURI(`${getOrigin()}${next}`)

    if (url.includes('adfs')) fullUrl += `&ReturnUrl=${returnUrl}`
    else if (url.includes('oak.com')) fullUrl += `?returnUrl=${returnUrl}`
    else if (url.includes('okta.com')) fullUrl += `?fromURI=${returnUrl}`
  }

  return (
    <a
      style={{ ...styles.signInButton, backgroundColor: primaryColor }}
      href={fullUrl}
      target="_blank" // eslint-disable-line react/jsx-no-target-blank
    >
      {t('login_via_company', { company: companyName })}
    </a>
  )
}

class LoginPageContainer extends React.Component {
  static data = {
    company: $y.getData(LoginPage, 'company', { required: false }),
  }

  render() {
    const sub = getSubdomain()
    return (
      <React.Fragment>
        {sub ? (
          <LoadingContainer
            spinnerProps={{
              containerStyle: {
                backgroundColor: Style.vars.deprecatedColors.mediumGrey,
              },
            }}
            loadingProps={this.props.company ? [this.props.company] : []}
            shouldRenderNoData={(companies) =>
              companies.length > 0 && shouldRenderNoData(companies)
            }
            createComponent={() => <LoginPage {...this.props} subdomain={sub} />}
          />
        ) : (
          <CreateCompanyConnectionMutationContainer>
            {(createCompanyConnection) => (
              <LoginPage {...this.props} createCompanyConnection={createCompanyConnection} />
            )}
          </CreateCompanyConnectionMutationContainer>
        )}
      </React.Fragment>
    )
  }
}

const PageInner = Marty.createContainer(LoginPageContainer, {
  listenTo: [PublicCompaniesState.Store],

  fetch: {
    companies() {
      const sub = getSubdomain()
      if (!sub) {
        return Im.freeze([])
      }
      return PublicCompaniesState.Store.getItems({
        fields: $y.getFields(LoginPageContainer, 'company'),
        subdomain__iexact: sub,
      })
    },
  },

  done(results) {
    const company = _.first(results.companies)
    return <LoginPageContainer {...this.props} {...results} company={company} />
  },

  pending() {
    return containerUtils.defaultPending(this, LoginPageContainer)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, LoginPageContainer, errors)
  },
})

export class Page extends React.Component {
  static contextTypes = {
    location: PropTypes.object.isRequired,
  }

  render() {
    return (
      <PublicStyling>
        <PageInner />
      </PublicStyling>
    )
  }
}

const styles = {
  container: {
    background: Style.vars.deprecatedColors.white,
    color: Style.vars.deprecatedColors.softText,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    paddingBottom: 30,
  },
  checkboxLabel: {
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    marginLeft: 10,
  },
  signInForm: {
    margin: '15px 0px',
    width: 300,
  },
  signInButton: {
    display: 'block',
    lineHeight: '50px',
    height: 50,
    width: 300,
    marginTop: 40,
    color: Style.vars.deprecatedColors.white,
    borderRadius: 3,
    fontWeight: 'bold',
    padding: 0,
    textAlign: 'center',
  },
  signupText: {
    marginTop: 25,
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    textAlign: 'center',
    fontSize: '1.2em',
  },
  logo: {
    height: 150,
    width: 220,
    marginTop: 40,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  input: {
    background: 'none',
    backgroundColor: Style.vars.deprecatedColors.white,
    borderStyle: 'none',
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    marginTop: 20,
  },
  hr: {
    marginTop: '-20px',
    borderTop: `1px solid ${Style.vars.deprecatedColors.xDarkGrey}`,
  },
  bottomLink: {
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    margin: 10,
    display: 'block',
    textDecoration: 'underline',
    cursor: 'pointer',
    ':hover': { textDecoration: 'none' },
  },
  errorCode: {
    color: Style.vars.deprecatedColors.errorRed,
    textAlign: 'center',
  },
  forgotPassword: {
    color: Style.vars.deprecatedColors.errorRed,
    cursor: 'pointer',
    fontWeight: 'bold',
  },
}
