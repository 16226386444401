import { ChannelAbout } from 'components/common/channel-about'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import { getPaginationProps } from 'shared-js/state/graphql'
import { ApolloLoadingContainer } from 'shared-js/components/common/loading'
import { PublicPlanCard } from 'components/common/plan-card'
import CoverImage from 'components/training/channel-content/cover-image'
import PlanList from './plan-list'
import PageModals from './page-modals'

const GET_PUBLIC_CHANNEL = gql`
  query Channel($channelID: ID!) {
    PublicTrainingUnit__Item(id: $channelID) {
      id
      name
      displayName
      description
      logo
      coverImage
      video
      learnItems
      isSubscribedToByCurrentCompany
      currentCompanyHasExistingRequest
      company {
        id
        name
        companyLogo
        coverImage
      }
    }

    PublicTrainingPlanTrainingUnit__List(orderBy: ["order"], first: 24, trainingUnit: $channelID) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          trainingPlan {
            ...PublicPlanCard_plan
          }
        }
      }
    }
    ${PublicPlanCard.fragment}
  }
`

@Radium
export class PublicPage extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static propTypes = {
    channel: PropTypes.object,
    channelId: PropTypes.number,
    onPlanCardClick: PropTypes.func,
    updateParentModal: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedPlan: null,
    }
  }

  openTrainingPlanModal = (plan) => {
    this.setState({ selectedPlan: plan })
    this.viewTrainingPlanModal.show()
  }

  updateParentModal = () => {
    // Refresh parent modal so it's scrollable once video is closed. This is
    // a hack that's needed because if there's a modal on top of another modal,
    // the 1st modal will lose ability to scroll after the 2nd modal is closed.
    // Bumping the modal's key fixes this problem.
    if (this.props.updateParentModal) {
      this.props.updateParentModal()
    }
  }

  render() {
    const { channelId, onPlanCardClick } = this.props

    const channelID = channelId || this.context.route.params.channelId
    const variables = {
      channelID,
    }

    return (
      <React.Fragment>
        <Query query={GET_PUBLIC_CHANNEL} variables={variables}>
          {(apolloProps) => (
            <ApolloLoadingContainer {...apolloProps}>
              {(data) => {
                const paginationProps = getPaginationProps({
                  apolloProps,
                  listFieldName: 'PublicTrainingPlanTrainingUnit__List',
                })
                return data.PublicTrainingUnit__Item ? (
                  <React.Fragment>
                    <CoverImage
                      channel={data.PublicTrainingUnit__Item}
                      currentUser={this.context.currentUser}
                      isPublicPage
                    />
                    <ChannelAbout channel={data.PublicTrainingUnit__Item} />
                    <PlanList
                      channel={data.PublicTrainingUnit__Item}
                      onPlanCardClick={onPlanCardClick}
                      trainingPlanTrainingUnits={data.PublicTrainingPlanTrainingUnit__List.edges}
                      openTrainingPlanModal={this.openTrainingPlanModal}
                      paginationProps={paginationProps}
                    />
                    <PageModals
                      ref={(node) => (this.viewTrainingPlanModal = node)}
                      channel={data.PublicTrainingUnit__Item}
                      selectedPlan={this.state.selectedPlan}
                    />
                  </React.Fragment>
                ) : null
              }}
            </ApolloLoadingContainer>
          )}
        </Query>
      </React.Fragment>
    )
  }
}
