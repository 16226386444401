import { Box } from 'components/common/box'
import ChannelsState from 'state/channels'
import CompaniesState from 'state/companies'
import CompanySettingsState from 'state/companysettings'
import JoinCompanyRequestsState from 'state/join-company-requests'
import UsersState from 'state/users'
import _ from 'lodash'
import Style from 'style'
import { CompanySettingsTab } from 'components/settings/company-settings'
import { LoadingContainer, LoadingSpinner } from 'components/common/loading'
import { BasicButton } from 'components/common/buttons'
import { t } from 'i18n'
import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import SubscriptionsState from 'shared-js/state/subscriptions'
import containerUtils from 'utilities/containers'

const styles = {
  companyItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '60%',
    margin: '20px 0',
  },
  button: {
    textAlign: 'center',
    color: Style.vars.deprecatedColors.white,
    padding: '5px 10px',
  },
  acceptButton: {
    backgroundColor: Style.vars.deprecatedColors.primary,
  },
  denyButton: {
    backgroundColor: Style.vars.deprecatedColors.grey,
  },
}

class AcceptButton extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  acceptRequest = () => {
    this.props.clickButton()

    JoinCompanyRequestsState.ActionCreators.doDetailAction(this.props.request.id, 'accept')
      .then(() => {
        JoinCompanyRequestsState.Store.resetState()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <BasicButton
        style={{ ...styles.button, ...styles.acceptButton }}
        onClick={this.acceptRequest}
      >
        {t('accept')}
      </BasicButton>
    )
  }
}

class DenyButton extends React.Component {
  denyRequest = () => {
    this.props.clickButton()

    JoinCompanyRequestsState.ActionCreators.doDetailAction(this.props.request.id, 'deny')
      .then(() => {
        JoinCompanyRequestsState.Store.resetState()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <BasicButton style={{ ...styles.button, ...styles.denyButton }} onClick={this.denyRequest}>
        {t('deny')}
      </BasicButton>
    )
  }
}

class RequestItem extends React.Component {
  state = {
    clicked: false,
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  renderTeamNameAndCompany = () => (
    <span>
      <span>: </span>
      <span style={{ fontStyle: 'italic' }}>
        {this.props.request.team.name}
,
        {this.props.request.company.name}
      </span>
    </span>
  )

  clickButton = () => {
    this.setState(() => ({
      clicked: true,
    }))
  }

  render() {
    const isAdmin = this.context.currentUser.learner.is_company_admin
    const requester = this.props.request.requester
    return (
      <div>
        {this.state.clicked ? (
          <LoadingSpinner size="small" shadow />
        ) : (
          <div style={styles.companyItem}>
            <div>
              {requester.first_name}
              {' '}
              {requester.last_name}
              {isAdmin && this.renderTeamNameAndCompany()}
            </div>
            <div>
              <AcceptButton request={this.props.request} clickButton={this.clickButton} />
              <DenyButton request={this.props.request} clickButton={this.clickButton} />
            </div>
          </div>
        )}
      </div>
    )
  }
}

class JoinCompanyRequestsContainer extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  renderRequestItems = (requests) => requests.map((request, i) => <RequestItem key={i} request={request} />)

  render() {
    const requests = this.props.requests ? this.props.requests : []
    const requestCount = requests && _.size(requests)
    const requestInfo = this.context.currentUser.learner.is_company_admin
      ? 'requests_received_info_admin'
      : 'requests_received_info'
    return (
      <div>
        <h3>{requestCount === 0 ? t('no_requests_left') : t('requests_pending')}</h3>
        {this.renderRequestItems(requests)}
      </div>
    )
  }
}

export const Page = Marty.createContainer(JoinCompanyRequestsContainer, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [JoinCompanyRequestsState.Store],

  fetch: {
    requests() {
      const query = {
        closed_date__isnull: true,
        outcome__isnull: true,
        requester__company__isnull: true,
        company: this.props.params.companyId,
        fields: [
          'requester',
          'requester.first_name',
          'requester.last_name',
          'company.name',
          'team',
          'team.name',
        ],
      }
      return JoinCompanyRequestsState.Store.getItems(query)
    },
  },

  pending() {
    return containerUtils.defaultPending(this, JoinCompanyRequestsContainer)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, JoinCompanyRequestsContainer, errors)
  },
})
