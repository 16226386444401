import React, { useRef, useState } from 'react'
import { useWindowWidth } from 'shared-js/utilities/hooks'
import { isMobileWidth } from 'shared-js/utilities/browser'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import Style from 'shared-js/style'
import Icon from 'react-native-vector-icons/MaterialIcons'

type SearchContainerProps = {
  startColumn?: number
  width?: string
  onClick?(): void
  children: React.ReactNode | React.ReactNode[]
}

type SearchProps = {
  width?: string
  mobileStartColumn: number
  placeholder: string
  value?: string
  onChange(val: string): void
  mobileContainerStyle?: React.CSSProperties
  mobileSearchInputStyle?: React.CSSProperties
}

type SearchDesktopProps = SearchProps & {
  inputFieldRef: React.RefObject<HTMLInputElement>
  handleOnClick(): void
}

type MobileSearchButtonProps = SearchContainerProps & {
  searchIsExpanded: boolean
}

type MobileSearchContainerProps = {
  startColumn?: number
}

type MobileSearchInputContainerProps = {
  children: React.ReactNode
  style: React.CSSProperties
}

const Search = (props: SearchProps) => {
  const windowWidth = useWindowWidth()
  const inputField = useRef<HTMLInputElement>(null)

  const handleOnClick = () => {
    if (inputField.current) {
      const input = inputField!.current! as any
      input.focus()
    }
  }

  return isMobileWidth(windowWidth) ? (
    <SearchMobile {...props} />
  ) : (
    <SearchDesktop handleOnClick={handleOnClick} inputFieldRef={inputField} {...props} />
  )
}

const SearchDesktop = (props: SearchDesktopProps) => (
  <SearchDesktopContainer width={props.width} onClick={props.handleOnClick}>
    <StyledIcon name="search" size={20} />
    <InputContainer
      value={props.value}
      placeholder={props.placeholder ? props.placeholder : t('search')}
      ref={props.inputFieldRef}
      onChange={(event) => {
        props.onChange(event.target.value)
      }}
    />
  </SearchDesktopContainer>
)

const SearchMobile = (props: SearchProps) => {
  const [searchIsExpanded, toggleSearchIsExpanded] = useState(false)
  return (
    <>
      <MobileSearchContainer
        startColumn={props.mobileStartColumn}
        style={props.mobileContainerStyle ? props.mobileContainerStyle : {}}
      >
        <MobileSearchButton
          onClick={() => {
            toggleSearchIsExpanded(!searchIsExpanded)
          }}
          searchIsExpanded={searchIsExpanded}
        >
          <StyledIcon name="search" size={22} />
        </MobileSearchButton>
      </MobileSearchContainer>
      {searchIsExpanded && (
        <MobileSearchInputContainer
          style={props.mobileSearchInputStyle ? props.mobileSearchInputStyle : {}}
        >
          <InputContainer
            value={props.value}
            placeholder={props.placeholder ? props.placeholder : t('search')}
            onChange={(event) => {
              props.onChange(event.target.value)
            }}
          />
        </MobileSearchInputContainer>
      )}
    </>
  )
}

const SearchDesktopContainer = styled.div<SearchContainerProps>`
  height: 40px;
  padding: 8px 15px;
  background-color: ${Style.deprecatedColors.whiteThree};
  width: ${({ width }) => width};
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 1px ${Style.deprecatedColors.battleshipGrey65};
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const MobileSearchContainer = styled.div<MobileSearchContainerProps>`
  width: 100%;
  grid-column: ${(props) => props.startColumn} / span 1;
  display: flex;
  justify-content: flex-end;
`

const MobileSearchButton = styled.div<MobileSearchButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  box-shadow: ${(props) =>
    props.searchIsExpanded
      ? `inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);`
      : `
        0 1px 3px 0 rgba(0, 0, 0, 0.2), 
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 
        0 1px 1px 0 rgba(0, 0, 0, 0.14);
      `};
  background-color: ${(props) =>
    props.searchIsExpanded
      ? `${Style.deprecatedColors.veryLightPinkTwo}`
      : `${Style.deprecatedColors.whiteThree}`};
`

const MobileSearchInputContainer = styled((props: MobileSearchInputContainerProps) => (
  <SearchDesktopContainer {...props} />
))`
  width: 100%;
  margin-top: 15px;
  grid-column: 1 / span 6;
`

const StyledIcon = styled(Icon)`
  color: ${Style.deprecatedColors.battleshipGrey};
  margin-right: 10px;
  @media (max-width: 768px) {
    color: #616161;
    margin: 0px;
  }
`

const InputContainer = styled.input`
  color: ${Style.deprecatedColors.blackTwo};
  width: 100%;
  border: none;
`

export default Search
