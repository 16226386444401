import { BackButton } from 'components/common/buttons'
import Im from 'shared-js/immutable'
import PropTypes from 'prop-types'
import React from 'react'
import SingleActivityItem from 'shared-js/components/home/feed/single-activity-item'
import Style from 'shared-js/style'
import { t } from 'i18n'
import { withRedirectToFrontend2ByFeatureFlag } from '../common/redirect-to-frontend2'

class SingleActivityItemContainer extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
  }

  static childContextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  getChildContext() {
    return {
      currentUser: Im.freeze(this.context.currentUser),
    }
  }

  render() {
    return (
      <div style={styles.outerContainer}>
        <div style={styles.innerContainer}>
          <BackButton style={styles.backButton} routeName="home">
            {t('home')}
          </BackButton>
          <SingleActivityItem {...this.props} activityId={this.context.routeParams.activityId} />
        </div>
      </div>
    )
  }
}

const styles = {
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    backgroundColor: Style.vars.deprecatedColors.lightGrey,
  },
  innerContainer: {
    marginTop: 12,
    width: '100%',
    maxWidth: Style.vars.widths.contentFeedMaxWidth,
  },
  backButton: {
    marginBottom: 10,
  },
}

const Page = withRedirectToFrontend2ByFeatureFlag('frontend2_home_feed_item')(
  SingleActivityItemContainer
)

export default Page
