import React, { useContext, useState } from 'react'
import { ConnectedCompaniesQuery_PublicCompany__List_edges_node as PublicCompany } from './gql-types/ConnectedCompaniesQuery'

type HandleChannelSubscriptionsType = {
  companiesToSubscribe: (PublicCompany | null)[]
  companiesToUnsubscribe: (PublicCompany | null)[]
  toggleSubscription(company: PublicCompany, isCurrentlySubscribed: boolean): void
  resetSubscriptionChanges(): void
}

const HandleChannelSubscriptionsContext = React.createContext<HandleChannelSubscriptionsType>({
  companiesToSubscribe: [],
  companiesToUnsubscribe: [],
  toggleSubscription: () => {},
  resetSubscriptionChanges: () => {},
})

export const HandleChannelSubscriptionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [companiesToSubscribe, setCompaniesToSubscribe] = useState<(PublicCompany | null)[]>([])
  const [companiesToUnsubscribe, setCompaniesToUnsubscribe] = useState<(PublicCompany | null)[]>([])

  const toggleSubscription = (company: PublicCompany, isCurrentlySubscribed: boolean) => {
    if (companiesToSubscribe.includes(company)) {
      setCompaniesToSubscribe(companiesToSubscribe.filter((co) => co !== company))
    } else if (companiesToUnsubscribe.includes(company)) {
      setCompaniesToUnsubscribe(companiesToUnsubscribe.filter((co) => co !== company))
    } else if (isCurrentlySubscribed) {
      setCompaniesToUnsubscribe(companiesToUnsubscribe.concat(company))
    } else {
      setCompaniesToSubscribe(companiesToSubscribe.concat(company))
    }
  }

  const resetSubscriptionChanges = () => {
    setCompaniesToSubscribe([])
    setCompaniesToUnsubscribe([])
  }

  return (
    <HandleChannelSubscriptionsContext.Provider
      value={{
        companiesToSubscribe,
        companiesToUnsubscribe,
        toggleSubscription,
        resetSubscriptionChanges,
      }}
    >
      {children}
    </HandleChannelSubscriptionsContext.Provider>
  )
}

export const useHandleChannelSubscriptions = () => useContext(HandleChannelSubscriptionsContext)
