import React from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { t } from 'i18n'
import reactMixin from 'react-mixin'
import PropTypes from 'prop-types'
import Radium from 'radium'

import Style from 'style/index.js'

import { Dropdown } from 'components/common/dropdown.jsx'

class FilterSetMixin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentFilter: this.props.initial || this.props.filterNames[0],
    }
  }

  UNSAFE_componentWillUpdate = (nextProps) => {
    if (!_.isEqual(nextProps.filterNames, this.props.filterNames)) {
      this.setState({ currentFilter: nextProps.filterNames[0] })
    }
  }

  setFilter = (filterName) => {
    if (!filterName) return
    this.setState({ currentFilter: filterName })
    this.props.setFilter(filterName)
  }
}

@Radium
export class FilterItem extends React.Component {
  renderFilterName() {
    const { filterName } = this.props
    if (this.props.renderFilterName) {
      // Mainly used to allow translation of filter names
      return this.props.renderFilterName(filterName)
    }
    return filterName
  }

  render() {
    const { isActive, filterName, showIndicator, style } = this.props
    const props = {
      key: filterName,
      onClick: () => this.props.setFilter(filterName),
      style: Style.funcs.merge(
        Style.funcs.mergeIf(isActive, styles.button, styles.activeButton),
        style
      ),
    }
    return (
      <div {...props}>
        {t(this.renderFilterName())}
        {showIndicator && (
          <div className="ui orange empty circular mini label" style={styles.indicator} />
        )}
      </div>
    )
  }
}

const fsStyle = {
  container: {},
}

@Radium
@reactMixin.decorate(FilterSetMixin)
export class FilterSet extends FilterSetMixin {
  /*
    A set of adjacent buttons which are designed to be used
    as filters for collections of content.
  */
  static propTypes = {
    filterNames: PropTypes.array.isRequired,
    setFilter: PropTypes.func.isRequired,
    createButton: PropTypes.func,
  }

  getButtonEl = (filterName) => {
    const isActive = filterName === this.state.currentFilter
    const props = {
      key: filterName,
      setFilter: this.setFilter,
      showIndicator: this.props.indicators && this.props.indicators.includes(filterName),
      filterName,
      isActive,
    }
    if (this.props.createButton) {
      return this.props.createButton(props)
    }
    return <FilterItem {...props} />
  }

  render() {
    const cStyle = Style.funcs.merge(fsStyle.container, this.props.containerStyle)
    return <div style={cStyle}>{_.map(this.props.filterNames, this.getButtonEl)}</div>
  }
}

export class DropdownFilterSet extends React.Component {
  static propTypes = {
    styles: PropTypes.object,
    filterNames: PropTypes.array.isRequired,
    initial: PropTypes.string,
    onFilterChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    styles: { container: {}, icon: {} },
  }

  constructor(props) {
    super(props)
    this.state = {
      currentFilter: this.props.initial || this.props.filterNames[0],
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!_.isEqual(nextProps.filterNames, this.props.filterNames)) {
      this.setState({ currentFilter: nextProps.filterNames[0] })
    }
  }

  setFilter = (filterName) => {
    if (!filterName) return
    this.setState({ currentFilter: filterName })
    this.props.onFilterChange(filterName)
  }

  renderFilterItem = (filterName) => (
    <div key={filterName} data-value={filterName} className="item">
      {t(filterName)}
    </div>
  )

  render() {
    return (
      <Dropdown
        style={{ ...styles.dropdownFilterSet, ...this.props.styles.container }}
        className="ui dropdown top right"
        dropdownOpts={{ onChange: this.setFilter }}
      >
        <div style={{ display: 'flex' }}>
          <i className="filter icon" style={{ ...styles.icon, ...this.props.styles.icon }} />
          <span style={{ ...styles.text, ...this.props.styles.text }}>
            {t(this.state.currentFilter)}
          </span>
        </div>
        <div className="menu" style={styles.dropdown}>
          {_.map(this.props.filterNames, this.renderFilterItem)}
        </div>
      </Dropdown>
    )
  }
}

const styles = {
  button: {
    boxShadow: 'none',
    margin: '5px 5px 5px 0',
    padding: '9px 13px',
    display: 'inline-block',
    cursor: 'pointer',
    color: Style.vars.deprecatedColors.darkGrey,
    border: `1px solid ${Style.vars.deprecatedColors.darkGrey}`,
    borderRadius: 40,
    ...Style.funcs.makeTransitionAll(),
    ':hover': {
      color: Style.vars.deprecatedColors.primary,
      border: {
        toString() {
          return `1px solid ${Style.vars.deprecatedColors.primary}`
        },
      },
    },
  },
  indicator: {
    marginLeft: '1em',
  },
  get activeButton() {
    return this.button[':hover']
  },
  dropdownFilterSet: {
    margin: '0px 5px',
  },
  icon: {
    flex: '0 0 20px',
    fontSize: 16,
    marginRight: 5,
  },
  text: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dropdown: {
    left: 'inherit',
    right: 0,
  },
  filter: {},
}
