import Marty from 'marty'
import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import Style from 'style/index'
import { resolve } from 'utilities/deprecated-named-routes'
import { qs, getOrigin } from 'utilities/http'

import PublicLinkState from 'state/public-links'
import JoinCompanyRequestsState from 'shared-js/state/join-company-requests'
import { HoverableLink } from 'components/common/hover'
import { RETAILER, HOSPO_COMPANY } from 'core/constants'

const DEMO_ACCOUNT = 'demo'
const NEW_COMPANY_PAGE = 'new company page'
const INVITE_PAGE = 'invite page'

const styles = {
  primaryLink: {
    margin: '25px 0',
    textDecoration: 'underline',
    color: Style.vars.deprecatedColors.primary,
    textAlign: 'center',
    display: 'block',
    cursor: 'pointer',
    hover: {
      color: Style.vars.deprecatedColors.darkPrimary,
    },
  },
}

@Radium
export class PrimaryLink extends React.Component {
  render() {
    return (
      <HoverableLink
        {...this.props}
        hoverStyle={styles.primaryLink.hover}
        style={styles.primaryLink}
      >
        {this.props.children}
      </HoverableLink>
    )
  }
}

export const connectToSharelink = (link) => {
  let promise
  if (link) {
    promise = PublicLinkState.ActionCreators.doListAction('connect_to_sharelink', {
      link_name: link.name,
    })
  } else {
    promise = new Promise((res) => {
      // Instantly resolve promise in this case
      res()
    })
  }
  return promise
}

export const goToSharelinkChannelPage = (link) => {
  if (link && link.channels.length > 0) {
    const channel = link.channels[0]
    const firstChannelId = link.initial_channel ? link.initial_channel.id : channel.id
    window.location.href = `/views/channel-content/${firstChannelId}/?fromSharelink=true`
  } else {
    enterMyagi()
  }
}

export const goToRelevantNetworkSection = (companyType) => {
  if (companyType === RETAILER || companyType === HOSPO_COMPANY) {
    // the company is considered a dealer
    window.location.href = resolve('channel-directory')
  } else {
    window.location.href = resolve('your-network')
  }
}

export const nextSignupStep = (companyType, currentPage, hasCameFromAConnectionInvitation) => {
  if (hasCameFromAConnectionInvitation) {
    goToRelevantNetworkSection(companyType)
    return
  }
  if (companyType === RETAILER && currentPage === INVITE_PAGE) {
    window.location.href = resolve('brand-discovery')
  } else if (currentPage === NEW_COMPANY_PAGE) {
    window.location.href = resolve('signup-invite')
  } else {
    enterMyagi()
  }
}

export const enterMyagi = () => {
  window.location.href = `${getOrigin()}/views/home/`
}

export const requestToJoinCompany = (requestData, company, router) => {
  JoinCompanyRequestsState.ActionCreators.doListAction(
    'create_request_to_join_company',
    requestData
  )
    .then((res) => {
      const data = { company_url: company.url, user_id: requestData.userId }
      if (isDemoAccount(company)) {
        JoinCompanyRequestsState.ActionCreators.doListAction('send_demo_account_notification', data)
      } else if (isParentCompany(company) || noActiveUsers(company)) {
        JoinCompanyRequestsState.ActionCreators.doListAction('send_slack_notification', data)
      }
      if (res.body && res.body.allowedToJoin) {
        enterMyagi()
      } else {
        redirectToRequestSentPage(router, company.name)
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const isParentCompany = (company) => company.child_companies && company.child_companies.length > 0

const noActiveUsers = (company) => company.user_count === 0

const isDemoAccount = (company) =>
  company.subscription && company.subscription.account_status === DEMO_ACCOUNT

export const redirectToRequestSentPage = (router, companyName) => {
  router.push({
    pathname: '/signup/request-sent/',
    state: { companyName },
  })
}

export const formatUrl = (url) => {
  if (!url) {
    return null
  }
  let newUrl = url.replace(/(^\w+:|^)\/\//, '')
  if (!newUrl.startsWith('www.')) {
    newUrl = `www.${newUrl}`
  }
  return newUrl
}
