import React from 'react'
import Style from 'style'
import ConversationFetchHOC, { ConversationListHOC } from 'shared-js/components/comms'
import { default as WebInfiniteList } from 'shared-js/components/common/list/infinite'
import ConversationListItem, { ConversationNoData } from './conversations'

const ConversationList = ConversationListHOC(
  WebInfiniteList,
  ConversationListItem,
  ConversationNoData
)

function CommsRoot(props) {
  return (
    <div style={styles.pageContainer}>
      <div style={styles.conversationListContainer}>
        <ConversationList {...props} />
      </div>
      <div>
        <p>This is where the conversation detail view might go</p>
      </div>
    </div>
  )
}
export default ConversationFetchHOC(CommsRoot)

const styles = {
  pageContainer: {
    maxHeight: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  conversationListContainer: {
    overflowY: 'scroll',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '35%',
    backgroundColor: Style.vars.deprecatedColors.white,
  },
  contentContainer: {
    width: '100%',
    borderRightWidth: 1,
    borderColor: 'black',
  },
}
