import React, { useRef } from 'react'
import { t } from 'shared-js/i18n'
import { useCommonRouter } from 'shared-js/routing'
import Style from 'shared-js/style'
import styled from 'styled-components'
import Button from 'shared-js/components/common/buttons'
import { OutlinedButton } from 'ui-kit'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Modal } from 'components/common/modals'
import { Title } from '../common'
import { useBrandsFilter, useIndustryFilter } from '../context'
import { FilterByConnectionDesktop } from './filter-by-brand'
import { FilterByIndustryDesktop } from './filter-by-industry'
import MobileFilterModal from './mobile-filter-modal'

export const DesktopFilterSection = () => (
  <Container>
    <SectionTitle>{t('filter_by')}</SectionTitle>
    <FilterByConnectionDesktop />
    <FilterByIndustryDesktop />
    <ExpandNetworkPrompt />
  </Container>
)

export const MobileFilterSection = () => {
  const filterModal = useRef<Modal>(null)
  const { brandsFiltered } = useBrandsFilter()
  const { industriesFiltered } = useIndustryFilter()
  const totalEnabledFilters = brandsFiltered.length + industriesFiltered.length
  return (
    <>
      <OutlinedButton
        size="medium"
        startIcon={<FilterListIcon />}
        onClick={() => {
          if (filterModal?.current) {
            filterModal.current.show()
          }
        }}
      >
        {`${t('filter')} (${totalEnabledFilters})`}
      </OutlinedButton>
      <MobileFilterModal innerRef={filterModal} />
    </>
  )
}

const ExpandNetworkPrompt = () => {
  const { handlers } = useCommonRouter()

  return (
    <ExpandNetworkContainer>
      {t('want_to_see_more_training_content')}
      <Button
        color="primary"
        outline
        containerStyle={{ marginTop: 15 }}
        onPress={() => {
          handlers.goToFindConnections()
        }}
      >
        {t('expand_your_network')}
      </Button>
    </ExpandNetworkContainer>
  )
}

const Container = styled.div`
  flex: 1;
  margin-right: 10px;
  padding: 0px 15px;
`

const SectionTitle = styled(Title)`
  margin-bottom: 30px;
`

const ExpandNetworkContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  color: ${Style.colors.grey900};
  font-weight: bold;
  border-top: 1px solid ${Style.colors.grey200};
`
