import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import Style from 'style/index'
import { qs, getOrigin } from 'utilities/http'
import { getSubdomain } from 'utilities/generic'

import $y from 'utilities/yaler'

import PageState from 'state/company-signup'

import { PublicStyling } from 'components/common/public-styling'
import { PrimaryLink } from '../../common'
import { NewCompanyForm } from './new-company-form'

const LOGIN_PATH = '/accounts/login/'
const COMPANY_LIST_PATH = '/signup/company/'
const MIN_SEARCH_LENGTH = 3

@Radium
export class CompanySignupPage extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
  }

  static propTypes = $y.propTypesFromData(CompanySignupPage, {
    isFetching: PropTypes.bool,
  })

  constructor() {
    super()
    this.state = {
      createError: null,
      loading: false,
      logoURL: null,
      logoValid: false,
      companyType: null,
    }
  }

  onSubmitAndValid = (data) => {
    if (this.props.isFetching) return
    this.setState({ submissionData: data })
    _.defer(this.createCompany)
  }

  getLoginURL() {
    const parts = location.hostname && location.hostname.split('.')
    // if parts.length === 1, there is no subdomain.
    if (!parts || parts.length === 1) return getOrigin() + LOGIN_PATH
    if (parts[0] !== 'myagi' && parts[0] !== 'staging-web') parts.shift()

    let subdomain = getSubdomain()
    subdomain = subdomain ? `${subdomain}.` : ''
    const upperLevelDomain = parts.join('.')
    const port = location.port ? `:${location.port}` : ''
    const loginURL = `${
      window.location.protocol
    }//${subdomain}${upperLevelDomain}${port}${LOGIN_PATH}`
    return loginURL
  }

  backToCompanyList = () => {
    PageState.Store.resetState()
    const companyListPath =
      this.context.location.state && this.context.location.state.link
        ? COMPANY_LIST_PATH + this.context.location.state.link
        : COMPANY_LIST_PATH
    const hasACompanyInvitation = Boolean(qs('c'))
    if (hasACompanyInvitation) {
      // a user can't use a sharelink and a connection invitation at the same time
      this.context.router.push({
        pathname: COMPANY_LIST_PATH + window.location.search,
        state: this.context.location.state,
      })
    } else {
      this.context.router.push({
        pathname: companyListPath,
        state: this.context.location.state,
      })
    }
  }

  render() {
    const shareLink = this.context.location.state && this.context.location.state.link
    const loginURL = this.getLoginURL()
    return (
      <div>
        <div style={styles.formTitle}>{`${t('add_company')}.`}</div>
        <PrimaryLink onClick={this.backToCompanyList} className="backToCompanyListLink">
          <i className="angle left icon" style={{ textDecoration: 'none' }} />
          {t('return_to_company_list')}
        </PrimaryLink>
        <div style={styles.signupFormContainer}>
          <NewCompanyForm {...this.props} />
        </div>
      </div>
    )
  }
}

export class Page extends React.Component {
  static contextTypes = {
    location: PropTypes.object.isRequired,
  }

  render() {
    return (
      <PublicStyling>
        <CompanySignupPage />
      </PublicStyling>
    )
  }
}

const styles = {
  formTitle: {
    fontSize: 30,
    textAlign: 'center',
    padding: '0 2.5rem',
    fontWeight: 300,
    [Style.vars.media.mobile]: {
      marginTop: 100,
    },
  },
  signupFormContainer: {
    maxWidth: 550,
    margin: '10px auto auto auto',
    borderRadius: 5,
  },
  signupForm: {
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 10,
    marginBottom: 17,
  },
  input: {
    marginTop: 0,
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    innerContainer: {
      flexDirection: 'column',
    },
  },
  errorCode: {
    color: Style.vars.deprecatedColors.white,
    marginBottom: 10,
    textAlign: 'center',
  },
  clearbitImage: {
    marginTop: 20,
  },
  dropdown: {
    container: {
      marginBottom: 20,
    },
  },
  errorMsg: {
    color: Style.vars.deprecatedColors.errorRed,
  },
  formLabel: {
    color: '#333',
    fontWeight: 'normal',
    fontSize: 16,
    marginBottom: 10,
    marginTop: 50,
  },
  stdSpacing: { marginTop: 20 },
}
