import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import Button from 'shared-js/components/common/buttons'
import { CreateChannelModal } from '../channels-tab/common/create-channel-modal'
import { CreatePlanModal } from '../plans-tab/create-plan-modal'

export class CreateChannelButton extends React.Component {
  static propsTypes = {
    currentUser: PropTypes.object.isRequired,
    onChannelCreated: PropTypes.func,
    // Plus all standard button props
  }

  constructor(props) {
    super(props)
  }

  onPress = (event) => {
    this.modal.show()
    if (this.props.onPress) this.props.onPress(event)
  }

  render() {
    return (
      <React.Fragment>
        <Button {...this.props} onPress={this.onPress}>
          {this.props.children || (
            <span>
              <i className="icon plus" />
              {' '}
              {t('create_channel')}
            </span>
          )}
        </Button>
        <CreateChannelModal
          ref={(el) => (this.modal = el)}
          currentUser={this.props.currentUser}
          onChannelCreated={this.props.onChannelCreated}
        />
      </React.Fragment>
    )
  }
}

export class CreatePlanButton extends React.Component {
  static propsTypes = {
    currentUser: PropTypes.object.isRequired,
    onPlanCreated: PropTypes.func,
    buttonComponent: PropTypes.element,
    // Plus all standard button props
  }

  constructor(props) {
    super(props)
  }

  onPress = (event) => {
    this.modal.show()
    if (this.props.onPress) this.props.onPress(event)
  }

  render() {
    return (
      <React.Fragment>
        <Button {...this.props} onPress={this.onPress}>
          {this.props.children || (
            <span>
              <i className="icon plus" />
              {' '}
              {t('create_plan')}
            </span>
          )}
        </Button>
        <CreatePlanModal
          ref={(el) => (this.modal = el)}
          currentUser={this.props.currentUser}
          onPlanCreated={this.props.onPlanCreated}
        />
      </React.Fragment>
    )
  }
}
