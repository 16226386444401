import { FieldHeader, Form } from 'components/common/form-deprecated'
import { getOrigin, qs } from 'utilities/http'

import { CreateTeamModal } from 'components/common/modals/create-team-modal'
import Im from 'shared-js/immutable'
import Marty from 'marty'
import PageState from 'shared-js/state/company-signup'
import PropTypes from 'prop-types'
import PublicCompaniesState from 'shared-js/state/public-companies'
import PublicLinkState from 'shared-js/state/public-links'
import { PublicTeamSearchableSelect } from 'components/common/form-deprecated/select/team-searchable-select'
import Radium from 'radium'
import React from 'react'
import Style from 'style/index'
import TeamsState from 'shared-js/state/teams'
import UsersState from 'shared-js/state/users'
import containerUtils from 'utilities/containers'
import PublicTeamsState from 'state/public-teams'
import _ from 'lodash'
import reactMixin from 'react-mixin'
import { resolve } from 'utilities/deprecated-named-routes'
import { t } from 'i18n'
import { Formik, Validation, getPropsFromFormik } from 'shared-js/components/common/form'
import { View } from 'react-native'
import * as Inputs from 'shared-js/components/common/inputs'
import { Button } from 'ui-kit'

import { ANALYTICS_EVENTS } from 'core/constants'
import { CompanyItem } from './company-item'
import {
  PrimaryLink,
  connectToSharelink,
  goToSharelinkChannelPage,
  enterMyagi,
  requestToJoinCompany,
} from '../../common'

const SKIP_CREATE_TEAM = 'skip create team'
const CREATE_TEAM = 'create a team'

const styles = {
  errorCode: {
    color: `${Style.vars.deprecatedColors.white}`,
    marginBottom: 10,
    textAlign: 'center',
  },
  teamInfo: {
    textAlign: 'center',
    color: `${Style.vars.deprecatedColors.xDarkGrey}`,
  },
  signupButtonInvalid: {
    background: `${Style.vars.deprecatedColors.fadedPrimary}`,
    cursor: 'not-allowed',
  },
  submitButton: {
    width: '55%',
    margin: 'auto',
  },
}

@Radium
export class JoinTeamFormInner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      newTeam: null,
    }
    this.requiresRequest =
      props.company && (!props.company.subdomain || !props.company.companysettings.public_access)
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  backToCompanyList = () => {
    PageState.Store.resetState()
    this.props.resetSelectedCompany()
  }

  checkSubmittedData = (values, actions) => {
    const company = this.props.company
    analytics.track(ANALYTICS_EVENTS.JOINED_COMPANY, {
      companyName: company.name,
      companyID: company.id,
      teamURL: values.teamURL,
    })
    const canCreateTeam = company.companysettings.allow_signups_to_create_teams
    if (values.teamURL === CREATE_TEAM && !this.state.newTeam && canCreateTeam) {
      this.createTeamContainer.createTeamModal.show()
    } else if (this.requiresRequest) {
      const requestData = {
        company: this.props.company.url,
        userId: this.props.userID,
        team: values.teamURL,
      }

      if (this.sharelinkUsed()) {
        requestData.sharelink_name = this.sharelinkUsed()
      }

      requestToJoinCompany(requestData, this.props.company, this.context.router)
    } else {
      this.joinCompany(values)
    }
  }

  sharelinkUsed = () => {
    if (this.context.location.state && this.context.location.state.link) {
      return this.context.location.state.link
    }
    return false
  }

  joinCompany = (data) => {
    this.setState({ loading: true })
    const companyData = {
      company: this.props.company.url,
    }
    if (data.teamURL && data.teamURL !== CREATE_TEAM) {
      companyData.team = data.teamURL
    }
    PublicCompaniesState.ActionCreators.doDetailAction(
      this.props.company.id,
      'add_to_company',
      companyData
    )
      .then((res) => {
        if (this.checkIfCreateTeam(companyData)) {
          const userID = this.props.userID
          const companyID = this.props.company.id
          this.createTeam(companyID, userID)
          return
        }
        connectToSharelink(this.props.link).then(() => {
          if (this.props.link) {
            goToSharelinkChannelPage(this.props.link)
          } else {
            enterMyagi()
          }
        })
      })
      .catch((err) => {
        this.setState({ loading: false })
        throw new Error(err)
      })
  }

  checkIfCreateTeam = (companyData) =>
    !companyData.team &&
    this.state.newTeam !== SKIP_CREATE_TEAM &&
    this.props.company.companysettings.allow_signups_to_create_teams

  createTeam = (companyID, userID) => {
    const newTeamData = {
      name: this.state.newTeam,
      company: `${getOrigin()}/api/v1/companies/${companyID}/`,
    }
    TeamsState.ActionCreators.create(newTeamData)
      .then((res) => {
        const newTeamUrl = res.body.url
        UsersState.ActionCreators.doDetailAction(userID, 'set_learner_group', {
          learner_group: newTeamUrl,
        })
          .then((res) => {
            connectToSharelink(this.props.link).then(() => {
              if (this.props.link) {
                goToSharelinkChannelPage(this.props.link)
              } else {
                enterMyagi()
              }
            })
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onCreateTeam = (data) => {
    this.setState({ loading: true, newTeam: data.name }, () => {
      this.teamForm.submitForm()
    })
  }

  exitCreateTeam = () => {
    this.setState({ newTeam: SKIP_CREATE_TEAM }, () => {
      this.teamForm.submitForm()
    })
  }

  renderCanCreateTeamInfo = () => <p style={styles.teamInfo}>{t('if_team_not_listed')}</p>

  getTeamOptions = (teams) =>
    teams.map((team) => ({
      value: team.url,
      label: team.location_address ? `${team.name} (${team.location_address})` : team.name,
    }))

  render() {
    const teamOptions = this.props.teams ? this.getTeamOptions(this.props.teams) : []

    if (!this.requiresRequest && this.props.company.companysettings.allow_signups_to_create_teams)
      teamOptions.unshift({ value: CREATE_TEAM, label: t('other') })

    return (
      <div style={{ width: '90%' }}>
        <CompanyItem
          company={this.props.company}
          pickedCompany
          backToCompanyList={this.backToCompanyList}
        />
        <PrimaryLink onClick={this.backToCompanyList} className="wrongCompanyLink">
          <i className="angle left icon" style={{ textDecoration: 'none' }} />
          {`${t('wrong_company')} ${t('return_to_company_list')}`}
        </PrimaryLink>
        <Formik
          ref={(el) => {
            this.teamForm = el
          }}
          validationSchema={Validation.createSchema({
            teamURL: Validation.text({ required: true }),
          })}
          onSubmit={(values, actions) => {
            this.checkSubmittedData(values, actions)
          }}
        >
          {(innerProps) => (
            <View style={styles.signupForm}>
              <FieldHeader style={{ margin: 0 }} required>
                {t('select_a_team')}
              </FieldHeader>
              <Inputs.DropdownInput
                name="team"
                options={teamOptions}
                placeholder={`${t('please_choose')}...`}
                {...getPropsFromFormik(innerProps, 'teamURL')}
              />
              <Button
                style={styles.submitButton}
                id="company-team-signup-submit-btn"
                isLoading={innerProps.isSubmitting}
                disabled={!innerProps.isValid}
                onClick={innerProps.handleSubmit}
              >
                {t('continue')}
              </Button>
            </View>
          )}
        </Formik>
        <CreateTeamModal
          ref={(x) => (this.createTeamContainer = x)}
          loading={this.state.loading}
          onSubmit={this.onCreateTeam}
          exitCreate={this.exitCreateTeam}
        />
      </div>
    )
  }
}

export const JoinTeamForm = Marty.createContainer(JoinTeamFormInner, {
  listenTo: [PublicTeamsState.Store],
  fetch: {
    teams() {
      if (!this.props.company) {
        return null
      }
      return PublicTeamsState.Store.getItems({
        limit: 0,
        ordering: 'name',
        fields: ['name', 'url', 'id', 'location_address'],
        company: this.props.company.id,
      })
    },
  },
  pending() {
    return containerUtils.defaultPending(this, JoinTeamFormInner)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, JoinTeamFormInner, errors)
  },
})
