import { CONTENT_SHARING, GatedFeatureBox } from 'components/common/gated-feature'
import { Info } from 'components/common/info.jsx'
import { t } from 'i18n'
import BLUR_IMAGE from 'img/sharelink-blur.jpg'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import { ConnectionsSection } from './connections'
import { SharelinkSection } from './sharelinks'
import { withRedirectToFrontend2ByFeatureFlag } from '../../common/redirect-to-frontend2'

const PORTRAIT_LAYOUT = '@media screen and (max-width: 950px)'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: -20,
    paddingBottom: 10,
    paddingTop: 50,
    [PORTRAIT_LAYOUT]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  section: {
    padding: '0 3px',
    width: '100%',
    maxWidth: 470,
  },
  connectionsSection: {
    [PORTRAIT_LAYOUT]: {
      marginTop: 40,
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: '200',
    marginBottom: 15,
  },
  tooltip: {
    tooltip: { left: -100, width: 350 },
  },
}

@Radium
class PageInner extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    featureFlags: PropTypes.objectOf(PropTypes.bool),
  }

  render() {
    if (!this.context.currentUser.company.subscription.shared_content_enabled) {
      return (
        <GatedFeatureBox
          backgroundImage={BLUR_IMAGE}
          hideContent
          headerText={t('upgrade_to_pro_share_content')}
          descriptionText={t('share_content_desc')}
          featureType={CONTENT_SHARING}
        />
      )
    }

    const connectionsTitle = t('connected_channels_titlecase')
    return (
      <div style={styles.container}>
        <div style={{ ...styles.section, ...styles.connectionsSection }}>
          <div style={styles.title}>{connectionsTitle}</div>
          <ConnectionsSection />
        </div>
        <div style={styles.section}>
          <div style={styles.title}>
            {t('sharelinks')}
            <Info
              content={t('sharelinks_info')}
              tooltipStyle={styles.tooltip}
              position="bottom"
              style={{ fontSize: 16, marginLeft: 5 }}
            />
          </div>
          <SharelinkSection />
        </div>
      </div>
    )
  }
}

export const Page = withRedirectToFrontend2ByFeatureFlag('frontend2_content_channel_sharing')(
  PageInner
)
