import React from 'react'
import cx from 'classnames'
import UsersState from 'state/users'
import PropTypes from 'prop-types'
import Style from 'style'
import { withApollo } from 'react-apollo'
import { MAIN_DOMAIN } from 'core/constants'

const styles = {
  changeCompanyBtn: {
    width: 210,
    background: Style.vars.deprecatedColors.navy,
    color: Style.vars.deprecatedColors.white,
    border: 'none',
    outline: 'none',
    borderRadius: 4,
    cursor: 'pointer',
  },

  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  heading: {
    display: 'inline-block',
  },
}

export class BasicDetailsInner extends React.Component {
  static data = {
    company: {
      required: true,
      fields: [
        'name',
        'user_count',
        'region_of_responsibility',
        'regions_of_responsibility.id',
        'regions_of_responsibility.name',
        'company_url',
        'subdomain',
        'office_location',
      ],
    },
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
  }

  changeCompany = () => {
    UsersState.ActionCreators.doDetailAction(
      this.context.currentUser.id,
      'change_user_to_new_company',
      {
        company_url: this.props.company.url,
      }
    )
      .then(() => {
        this.props.client.resetStore()

        const protocol = window.location.protocol
        const hostname = window.location.hostname
        const currentSubdomain = hostname.split('.')[0]

        const subdomain = this.props.company.subdomain
        const companyId = this.props.company.id

        if (process.env.NODE_ENV === 'production' && currentSubdomain !== 'staging') {
          window.location.href = `${protocol}//${subdomain}.${MAIN_DOMAIN}/views/myagi-staff/company/${companyId}/`
        } else {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    this.context.displayTempPositiveMessage({
      heading: 'Changing company...',
    })
  }

  render() {
    const btnClass = cx('button', 'blue')

    return (
      <React.Fragment>
        <div style={styles.topRow}>
          <h3 style={styles.heading}>Details</h3>
          <button className={btnClass} style={styles.changeCompanyBtn} onClick={this.changeCompany}>
            Put Me Into This Company
          </button>
        </div>
        <p>
          <b>Name:</b> 
{' '}
{this.props.company.name}
        </p>
        <p>
          <b>Region(s) of Responsibility:</b>
{' '}
          {this.props.company.regions_of_responsibility
            .map((region) => {
              return region.name
            })
            .join()}
        </p>
        <p>
          <b>Office Location:</b> 
{' '}
{this.props.company.office_location}
        </p>
        <p>
          <b>URL:</b> 
{' '}
{this.props.company.company_url}
        </p>
        {this.props.company.subdomain && (
          <p>
            <b>Myagi URL:</b> 
{' '}
{this.props.company.subdomain}
            .myagi.com
          </p>
        )}
        <p>
          <b>Num Users:</b> 
{' '}
{this.props.company.user_count}
        </p>
      </React.Fragment>
    )
  }
}

// use withApollo to get client to reset store on company change
export const BasicDetails = withApollo(BasicDetailsInner)
