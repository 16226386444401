import { Modal } from 'components/common/modals'
import { ANALYTICS_EVENTS } from 'core/constants'
import PropTypes from 'prop-types'
import React from 'react'
import $y from 'utilities/yaler.js'
import { TrainingPlanDetailsForm } from './plan-details'

export class EditPlanModal extends React.Component {
  static data = {
    channels: $y.getData(TrainingPlanDetailsForm, 'channels'),
    trainingPlan: $y.getData(TrainingPlanDetailsForm, 'trainingPlan', {
      required: true,
    }),
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
  }

  static propTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  show = () => {
    this.refs.modal.show()
    analytics.track(ANALYTICS_EVENTS.START_TRAINING_PLAN_EDITING)
  }

  onSubmit = () => {
    analytics.track(ANALYTICS_EVENTS.FINISH_TRAINING_PLAN_EDITING, {
      'Training Plan ID': this.props.trainingPlan.id,
    })
    this.context.displayTempPositiveMessage({ heading: 'Plan updated' })
    // Have received some sentry errors relating to modal not being defined.
    // No idea why, why have added this null reference check just in case.
    if (this.refs.modal) this.refs.modal.hide()
  }

  render() {
    return (
      <Modal ref="modal" header="Edit Plan" closeOnDimmerClick>
        <div className="content">
          <TrainingPlanDetailsForm
            onSubmit={this.onSubmit}
            channels={this.props.channels}
            currentUser={this.props.currentUser}
            trainingPlan={this.props.trainingPlan}
          />
        </div>
      </Modal>
    )
  }
}
