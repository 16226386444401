import React from 'react'
import styled from 'styled-components'
import Style from 'shared-js/style'

type ButtonProps = {
  onClick(): void,
  children: React.ReactNode,
}

export const Button = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px ${Style.deprecatedColors.battleshipGrey};
  height: 30px;
  margin: 'auto';
  font-weight: bold;
  line-height: 1.43;
  color: #646262;
  cursor: pointer;
`

type IconButtonProps = {
  onClick(): void,
  iconName: string,
}

export const IconButton = styled(({ iconName, ...passableProps }: IconButtonProps) => (
  <div {...passableProps}>{iconName}</div>
))`
  font-family: MaterialIcons;
  font-size: 20px;
  color: #646262;
  margin: auto;
  cursor: pointer;
`
