import React from 'react'
import styled from 'styled-components'

import { t } from 'shared-js/i18n'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import MYAGI_LOGO from 'img/logo-white.svg'

const PoweredBy = () => {
  const user = useCurrentUser()
  if (!user) return null

  let content = null

  if (user.isFake) {
    content = 'Fake user'
  } else if (user.company?.companysettings?.styleCustomizationEnabled) {
    content = (
      <PoweredByLink href="https://myagi.com" target="_blank" rel="noopener">
        {t('powered_by')} 
{' '}
<Logo src={MYAGI_LOGO} />
      </PoweredByLink>
    )
  }

  if (!content) return null

  return <Container>{content}</Container>
}

const Container = styled.div`
  z-index: 13;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-right-radius: 22px;
  padding: 10px 18px 10px 7px;
  background-color: black;
  opacity: 0.8;
  color: white;
  display: flex;
  align-items: center;
`

const PoweredByLink = styled.a`
  display: flex;
`

const Logo = styled.img`
  height: 22px;
  margin-left: 6px;
`

export default PoweredBy
