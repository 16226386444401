import React from 'react'
import Style from 'style'
import { t } from 'i18n'
import CompaniesState from 'state/companies'
import { CoverPhotoGuideModal } from 'components/common/cover-photo-guide-modal'

import { DeprecatedImageEdit } from 'components/common/form-deprecated'

const styles = {
  photoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  photo: {
    margin: '0 50px 30px 10px',
  },
  guide: {
    fontSize: '0.8rem',
    color: '#888',
    marginLeft: 10,
    cursor: 'pointer',
  },
}

export class CompanyImages extends React.Component {
  onImageSave = (image, field_name) => {
    if (!image) image = null
    const data = {}
    data[field_name] = image

    CompaniesState.ActionCreators.update(this.props.company.id, data)
      .then((res) => {
        this.props.onCompanyUpdate()
      })
      .catch((err) => {
        this.props.onCompanyUpdateFail(err.message)
      })
  }

  render() {
    const company = this.props.company
    return (
      <div style={styles.photoContainer}>
        <div style={styles.photo}>
          <h3>{t('company_logo')}</h3>
          <div style={styles.imgContainer}>
            <DeprecatedImageEdit
              image={company.company_logo}
              name="company_logo"
              onSave={(image) => this.onImageSave(image, 'company_logo')}
              editable
              width={200}
              height={100}
            />
          </div>
        </div>
        <div style={styles.photo}>
          <h3>
            {t('company_cover_image')}
            <span style={styles.guide} onClick={() => this.coverImageGuide.show()}>
              {t('cover_photo_guide')}
            </span>
          </h3>
          <div style={styles.imgContainer}>
            <DeprecatedImageEdit
              image={company.cover_image}
              name="cover_image"
              onSave={(image) => this.onImageSave(image, 'cover_image')}
              editable
              width={(2000 / 500) * 100}
              height={100}
              aspectRatio={4}
            />
          </div>
        </div>
        <CoverPhotoGuideModal ref={(el) => (this.coverImageGuide = el)} />
      </div>
    )
  }
}
