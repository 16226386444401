import { Panel } from 'components/common/box.jsx'
import { LoadingContainer } from 'components/common/loading'
import { RouterTabs } from 'components/common/router-tabs'
import { PlanCard } from 'components/content-management/plans-tab/plan-card'
import _ from 'lodash'
import { t } from 'i18n'
import Marty from 'marty'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import BadgesState from 'state/badges'
import ChannelsAccessCompaniesState from 'state/channel-access-company'
import ChannelSharesState from 'state/channel-shares'
import ChannelsState from 'state/channels'
import TrainingPlanTrainingUnitsState from 'state/training-plan-training-units'
import UsersState from 'state/users'
import Style from 'style/index.js'
import containerUtils from 'utilities/containers.js'
import $y from 'utilities/yaler'
import { ManagedHistoryTracker } from 'components/content-management/common/managed-history-tracker.tsx'
import { ButtonsRow } from './buttons-row'
import { ChannelPageCoverImage, GET_CHANNEL } from './cover-image'
import { DetailsTab } from './details'

const styles = {
  outerStyle: {
    padding: '0px 10px 0 10px',
  },
  innerStyle: {
    display: 'block',
  },
  tabsContainer: {
    padding: '5px 10px',
    background: Style.vars.deprecatedColors.white,
    marginLeft: '-10px',
    marginRight: '-10px',
  },
  defaultBackground: {
    backgroundColor: Style.vars.deprecatedColors.white,
  },
  topRow: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    justifyContent: 'space-between',
    padding: 10,
  },
}

@Radium
class ChannelPage extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      // Needed to avoid a flicker: When routes change
      // if the channel is internal, there won't be a company connection
      // so it returns an empty list. The state interprets that empty list
      // as a loading state which causes the flicker
      ownCompanyConnection: null,
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (!this.state.ownCompanyConnection && nextProps.ownCompanyConnection) {
      this.setState({ ownCompanyConnection: nextProps.ownCompanyConnection })
    }
  }

  getOwnCompanyConnection = () =>
    this.state.ownCompanyConnection && this.state.ownCompanyConnection.length
      ? _.first(this.state.ownCompanyConnection)
      : null

  getCompanyAccess = () =>
    this.props.companyAccess && this.props.companyAccess.length ? this.props.companyAccess[0] : null

  isUneditableChannel = () => {
    const { learner, company } = this.context.currentUser
    return (
      this.props.channel &&
      (!learner.can_manage_training_content || company.id != this.props.channel.company.id)
    )
  }

  routerTabs = () => {
    const learner = this.context.currentUser.learner
    const isUneditableChannel = this.isUneditableChannel()
    const urlBase = `/views/edit-content/channel/${this.props.channel.id}`
    return [
      { name: t('plans'), to: `${urlBase}/plans/` },

      ...(!isUneditableChannel
        ? [
            { name: t('details'), to: `${urlBase}/details/` },
            { name: t('company_connections'), to: `${urlBase}/connections/` },
          ]
        : []),
    ]
  }

  render() {
    const isUneditableChannel = this.isUneditableChannel()
    return (
      <div style={styles.defaultBackground}>
        {this.props.channel && (
          <ManagedHistoryTracker
            topics={['editPlanParentLocation']}
            type="channel"
            parameters={[this.props.channel.id]}
          />
        )}
        {this.props.channel && (
          <ChannelPageCoverImage
            channel={this.props.channel}
            currentUser={this.props.currentUser}
            isUneditableChannel={isUneditableChannel}
          />
        )}
        <div style={styles.topRow}>
          <div style={styles.tabsContainer}>
            {this.props.channel && this.routerTabs().length > 1 && (
              <RouterTabs tabs={this.routerTabs()} />
            )}
            <div style={Style.common.clearBoth} />
          </div>
          {this.props.channel &&
            this.props.companyAccess != undefined &&
            this.props.trainingPlanTrainingUnits && (
              <ButtonsRow
                isUneditableChannel={isUneditableChannel}
                channel={this.props.channel}
                trainingPlanTrainingUnits={this.props.trainingPlanTrainingUnits}
                ownCompanyConnection={this.getOwnCompanyConnection()}
                companyAccess={this.getCompanyAccess()}
              />
            )}
        </div>
        <LoadingContainer
          loadingProps={{
            channel: this.props.channel,
            trainingPlanTrainingUnits: this.props.trainingPlanTrainingUnits,
            ownCompanyConnection: this.state.ownCompanyConnection,
          }}
          createComponent={(props) => (
            <Panel outerStyle={styles.outerStyle} innerStyle={styles.innerStyle}>
              {React.cloneElement(this.props.children, {
                channel: this.props.channel,
                trainingPlanTrainingUnits: this.props.trainingPlanTrainingUnits,
                ownCompanyConnection: this.getOwnCompanyConnection(),
                isUneditableChannel,
              })}
            </Panel>
          )}
        />
      </div>
    )
  }
}

export const Page = Marty.createContainer(ChannelPage, {
  contextTypes: {
    routeParams: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  },
  listenTo: [
    ChannelsState.Store,
    UsersState.Store,
    BadgesState.Store,
    ChannelSharesState.Store,
    TrainingPlanTrainingUnitsState.Store,
    ChannelsAccessCompaniesState.Store,
  ],
  fetch: {
    channel() {
      return ChannelsState.Store.getItem(this.context.routeParams.channelId, {
        fields: [
          GET_CHANNEL.channel.fields,
          $y.getFields(ButtonsRow, 'channel'),
          $y.getFields(DetailsTab, 'channel'),
          'training_plans',
          'deactivated',
          'learn_items',
          'reached_users_count',
          'subscribed_companies_count',
          'training_plans_count',
          'active_and_published_training_plans_count',
          'require_sequential_completion',
          'id',
        ],
      })
    },
    ownCompanyConnection() {
      // There should only be one connection for each training_unit & company
      // combination, but we don't have the id yet.
      return ChannelSharesState.Store.getItems({
        training_unit: this.context.routeParams.channelId,
        company: this.context.currentUser.company.id,
        fields: ['id'],
      })
    },
    trainingPlanTrainingUnits() {
      return TrainingPlanTrainingUnitsState.Store.getItems(
        {
          training_unit: this.context.routeParams.channelId,
          training_plan__deactivated__isnull: true,
          fields: [
            'id',
            'url',
            'order',
            'training_plan.name',
            'training_plan.owner.id',
            'training_plan.owner.name',
            'training_plan.owner.url',
            'training_plan.description',
            'training_plan.thumbnail_url',
            'training_plan.modules.id',
            'training_plan.modules.url',
            'training_plan.modules.name',
            'training_plan.modules.description',
            'training_plan.modules.thumbnail_url',
            'training_plan.badges.id',
            'training_plan.badges.name',
            'training_plan.badges.badge_image',
            $y.getFields(PlanCard, 'trainingPlan', 'training_plan'),
          ],
          ordering: 'order,training_plan__name',
          limit: 200,
        },
        {
          // Wait for channel updates before refetching data
          dependantOn: [ChannelsState.Store, BadgesState.Store],
        }
      )
    },
    companyAccess() {
      return ChannelsAccessCompaniesState.Store.getItems({
        channel: this.context.routeParams.channelId,
        company: this.context.currentUser.company.id,
        fields: ['id'],
      })
    },
  },
  pending() {
    return containerUtils.defaultPending(this, ChannelPage)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, ChannelPage, errors)
  },
})
