import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import cx from 'classnames'
import t from 'shared-js/i18n'

import Style from 'style/index'

export class Form extends React.Component {
  static defaultProps = {
    loading: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      curIsValid: false,
      // Debounce as validating form is expensive
      debouncedUpdateIsValid: _.debounce(this.updateIsValid, 150),
      hasSubmitted: false,
    }
  }

  doSubmit() {
    // Basically this function is just an alias for onSubmit
    // which is consistent with a similar function in the Native App code base.
    this.onSubmit()
  }

  UNSAFE_componentWillMount() {
    this.inputRefs = []
  }

  UNSAFE_componentWillUpdate() {
    // Reset this everytime.
    this.inputRefs = []
  }

  componentDidMount() {
    this.updateIsValid()
    try {
      const firstInputField = this.refs[this.inputRefs[0]]
      firstInputField.focus()
    } catch (error) {
      // This will fail when the form has no inputs than can be focused
    }
  }

  _forEachInput = (func) => {
    let inputs = _.map(this.inputRefs, (ref) => this.refs[ref])
    inputs = _.filter(inputs, Boolean)
    _.each(inputs, func)
  }

  updateIsValid = () => {
    const isValid = this.isValid()
    if (isValid !== this.state.curIsValid) {
      this.setState({
        curIsValid: isValid,
      })
    }
  }

  isValid = () => {
    let isValid = true
    this._forEachInput((child) => {
      if (child.isValid && !child.isValid()) {
        isValid = false
      }
    })
    return isValid
  }

  onSubmit = (evt) => {
    // `evt` will not be sent when
    // using react native.
    if (evt) {
      evt.preventDefault()
      evt.stopPropagation()
    }
    this.setState({ hasSubmitted: true })
    if (!this.isValid()) return
    const namesAndValues = this.getNamesAndValues()
    this.props.onSubmitAndValid(namesAndValues)
  }

  getNamesAndValues = () => {
    const namesAndValues = {}
    this._forEachInput((child) => {
      if (child.getNameAndValue) {
        const nameAndVal = child.getNameAndValue()
        if (nameAndVal) _.extend(namesAndValues, nameAndVal)
      }
    })
    return namesAndValues
  }

  onInputBlur = () => {
    this.state.debouncedUpdateIsValid()
  }

  onInputChange = () => {
    this.state.debouncedUpdateIsValid()
  }

  onInputFocus = () => {
    this.state.debouncedUpdateIsValid()
  }

  doAll() {
    // Returns a func which executes all of the supplied
    // functions in order
    const funcs = arguments
    return function () {
      _.each(funcs, (func) => {
        if (func) func.apply(this, arguments)
      })
    }
  }

  traverseChildrenAndRegisterInputs = (children) => {
    // Traverse the children and children of children to find
    // all inputs by checking for a `name` prop on the element.
    const isValid = this.state.curIsValid
    if (typeof children !== 'object' || children === null) {
      return children
    }

    return React.Children.map(
      children,
      function(child) {
        if (!_.isObject(child) || _.isNull(child)) {
          return child
        }

        if (child && child.props && child.props.name) {
          const childProps = child.props || {}
          const childRef = child.ref || _.uniqueId('form-input-')
          this.inputRefs.push(childRef)
          const props = {
            formIsValid: isValid,
            onBlur: this.doAll(childProps.onBlur, this.onInputBlur),
            onChange: this.doAll(childProps.onChange, this.onInputChange),
            onFocus: this.doAll(childProps.onFocus, this.onInputFocus),
            hasSubmitted: this.state.hasSubmitted,
            ref: childRef,
          }
          return React.cloneElement(child, props, child.props && child.props.children)
        }
        if (!_.isString(child.type)) {
          // isString check makes sure this is a component function and not
          // a base DOM element (e.g. Div). Almost certainly this is the SubmitButton.
          return React.cloneElement(
            child,
            // This is purely passed along for the submit button, as it is not an input
            // but it does need to know whether the inputs in the form are valid or not
            {
              formIsValid: isValid,
              ref: child.ref,
              doValidation: this.updateIsValid,
            },
            this.traverseChildrenAndRegisterInputs(child.props && child.props.children)
          )
        }
        return React.cloneElement(
          child,
          // This is a standard element so don't pass along unnecessary props
          {
            ref: child.ref,
          },
          this.traverseChildrenAndRegisterInputs(child.props && child.props.children)
        )
      },
      this
    )
  }

  render() {
    const children = this.traverseChildrenAndRegisterInputs(this.props.children)
    const classes = cx('ui', { loading: this.props.loading }, 'form')
    return (
      <form ref="form" onSubmit={this.onSubmit} className={classes} style={this.props.style}>
        {children}
      </form>
    )
  }
}

const fhStyle = {
  explanation: {
    marginTop: '-10px',
  },
  container: {
    marginBottom: 10,
  },
  labelStyle: {
    fontSize: 14,
    color: Style.vars.deprecatedColors.xxDarkGrey,
    fontWeight: 600,
    marginBottom: 10,
  },
}

export const FieldHeader = (props) => (
  <div style={{ ...fhStyle.container, ...props.style }}>
    <h3 style={{ ...fhStyle.labelStyle, ...props.headerStyle }}>
      {props.children}
      {!props.required && <small>{t('optional')}</small>}
    </h3>
    {props.explanation && <p style={fhStyle.explanation}>{props.explanation}</p>}
  </div>
)
FieldHeader.propTypes = {
  style: PropTypes.object,
  headerStyle: PropTypes.object,
  required: PropTypes.bool,
  children: PropTypes.node,
  explanation: PropTypes.string,
}

export {
  DatetimeInput,
  TextInput,
  TextArea,
  PasswordInput,
  EmailInput,
  URLInput,
  HiddenTextInput,
  FileInput,
  NumberInput,
  RangeInput,
  KeyInput,
} from './input'
export { DeprecatedImageCropper, DeprecatedImageEdit } from './image'
export { SubmitButton } from './submit-button'
export { SearchableSelect } from './select'
export { InfiniteInputs } from './infinite-inputs'
export { ButtonToggle, SlideToggle, YesNoToggle } from './toggles'
export { Checkbox } from './checkbox'
