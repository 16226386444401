import React from 'react'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import { Query } from '@apollo/react-components'
import { getPaginationProps } from 'shared-js/state/graphql'
import gql from 'fraql'
import StyleSheet from 'shared-js/style/style-sheet'
import { withRedirectToFrontend2ByFeatureFlag } from 'components/common/redirect-to-frontend2'
import { FeedbackCard } from './feedback-card'
import { FeedbackList } from './feedback-list'

class FeedbackPageInner extends React.Component {
  /* FeedbackPage is used for feedback tab in content tab.
  renders all feedback relevent to a content creator.
  */

  static getFeedbackQuery = gql`
  query getModuleFeedback(
    $first:Int = 8,
    $cursor: String,
    $orderBy: [String] = "-created", 
    $includesComment: Boolean = true,
    $hasCompany: Boolean = true,  
    $previouslyResponded: Boolean,
    $notResponded: Boolean
    ) {
    ModuleFeedback__List(
      first:$first, after:$cursor, orderBy:$orderBy, 
      includesComment: $includesComment,
      hasCompany: $hasCompany,
      previouslyResponded: $previouslyResponded, 
      notResponded: $notResponded
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ${FeedbackCard.getFragment('feedback')}
        }
      }
    }
  }
  `

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  getFilters = (filter) => {
    const is_responded = filter === t('previously_responded')
    const is_withoutReply = filter === t('without_reply')
    const filters = {
      responded: is_responded,
      withoutReply: is_withoutReply,
    }
    return filters
  }

  getOrder = (isResponded) => (isResponded ? '-response_message_id' : '-created')

  render() {
    const filter = this.props.location.query.filter
    console.log(this.props.location.query)
    const { responded, withoutReply } = this.getFilters(filter)
    const queryVars = {
      notResponded: withoutReply,
      previouslyResponded: responded,
      orderBy: this.getOrder(responded),
    }

    return (
      <div>
        <Query query={FeedbackPageInner.getFeedbackQuery} variables={queryVars}>
          {(apolloProps) => {
            const paginationProps = getPaginationProps({
              apolloProps,
              listFieldName: 'ModuleFeedback__List',
            })
            const feedback =
              apolloProps.data && !apolloProps.data.error
                ? apolloProps.data.ModuleFeedback__List
                : {}

            return (
              <div style={styles.container}>
                <FeedbackList
                  feedback={feedback}
                  loadMore={paginationProps.loadMoreData}
                  moreDataAvailable={paginationProps.moreDataAvailable}
                  dataIsLoading={paginationProps.dataIsLoading}
                  currentUser={this.context.currentUser}
                />
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
    width: '100%',
    alignSelf: 'center',
    overflow: 'auto',
  },
})

export const FeedbackPage = withRedirectToFrontend2ByFeatureFlag(
  'frontend2_content_lesson_feedback'
)(FeedbackPageInner)
