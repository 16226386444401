import React, { useState } from 'react'
import styled from 'styled-components'
import { MultiSelect, Button } from 'ui-kit'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import { t } from 'shared-js/i18n'
import { accessMyagiNetworkImage } from './images'

export type CompanyType = {
  value: string
  label: string
}

export const RETAILER = 'retailer'
export const BRAND = 'brand'
export const DISTRIBUTOR = 'distributor'

const CompanyTypeStep = ({ setCompanyType }: { setCompanyType(val: CompanyType[]): void }) => {
  const currentUser = useCurrentUser()
  const [dropdownValue, setDropdownValue] = useState<(CompanyType | null)[]>([])

  const applyCompanyTypes = () => {
    if (dropdownValue.length) {
      setCompanyType(dropdownValue as CompanyType[])
    }
  }

  const handleOnInputChange = (event: any, value: (CompanyType | null)[]) => {
    setDropdownValue(value)
  }

  const ACTIVITY_TYPES = [
    { value: BRAND, label: t('i_am_a_brand_supplier') },
    { value: RETAILER, label: t('i_am_a_retailer_vendor') },
    { value: DISTRIBUTOR, label: t('i_am_a_distributor') },
  ]

  return (
    <Container>
      <Title>{t('join_the_myagi_network')}</Title>
      <Image />
      <Info>{t('join_the_myagi_network_info')}</Info>
      <InputTitle>
        {t('we_first_need_to_confirm_some_information', { companyName: currentUser.company.name })}
      </InputTitle>
      <MultiSelect
        onChange={handleOnInputChange}
        placeholder={t('what_describes_you_best')}
        optionKey="value"
        getOptionLabel={(option: any) => option.label}
        options={ACTIVITY_TYPES}
      />
      <ButtonContainer>
        <Button onClick={applyCompanyTypes} disabled={dropdownValue.length === 0}>
          {t('next')}
        </Button>
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 92vw;
  max-width: 450px;
  padding: 20px 24px 30px 24px;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey900};
  margin-bottom: 28px;
`
const Image = styled.div`
  height: 78px;
  width: 134px;
  margin: auto;
  margin-bottom: 16px;
  background-image: ${`url("${accessMyagiNetworkImage}")`};
  background-repeat: no-repeat;
  background-position: center;
`

const Info = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey700};
  line-height: 1.71;
  margin-bottom: 16px;
`

const InputTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: var(--grey-900);
  margin-bottom: 16px;
`

const ButtonContainer = styled.div`
  margin-top: 44px;
  display: flex;
  justify-content: center;
`

export default CompanyTypeStep
