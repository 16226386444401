import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import _ from 'lodash'

import Style from 'style/index'

const cardStyleSheet = {
  width: '230px',
  textAlign: 'center',
  backgroundColor: Style.vars.deprecatedColors.white,
  borderTopLeftRadius: 6,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
}

export class Card extends React.Component {
  /*
    Wrapper for http://semantic-ui.com/views/card.html#card
  */
  static propTypes = {
    onClick: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      style: {
        card: _.extend(
          {},
          cardStyleSheet,
          { cursor: this.props.onClick ? 'pointer' : 'auto' },
          Style.funcs.makeTransition('background-color 150ms linear'),
          this.props.style
        ),
      },
    }
  }

  render() {
    return (
      <div className="card" onClick={this.props.onClick} style={this.state.style.card}>
        {this.props.children}
      </div>
    )
  }
}

const cardCollectionStyleSheet = {
  centered: {
    justifyContent: 'center',
  },
}

export class CardCollection extends React.Component {
  /*
    Wrapper for http://semantic-ui.com/views/card.html#cards.
    Takes a list of `entities`, then iterates over list and uses
    supplised `createCard` func to generate a card for each
    entity.
  */
  static propTypes = {
    entities: PropTypes.instanceOf(Im.List).isRequired,
    createCard: PropTypes.func.isRequired,
    centered: PropTypes.bool,
  }

  render() {
    const style = Style.funcs.mergeIf(this.props.centered, cardCollectionStyleSheet.centered)
    return (
      <div className="ui cards" style={Style.funcs.merge(style, this.props.style)}>
        {this.props.entities.map((entity) => this.props.createCard(entity))}
      </div>
    )
  }
}

export class CornerRemoveIcon extends React.Component {
  /*
    Wrapper for http://semantic-ui.com/elements/label.html#corner with
    remove icon. Often added to cards as delete buttons.
  */
  render() {
    return (
      <a className="ui bottom corner label" onClick={this.props.onClick}>
        <i className="remove icon" style={{ cursor: 'pointer' }} />
      </a>
    )
  }
}
