import React, { useEffect, useRef } from 'react'
import { Grid, GridCellRenderer, SectionRenderedParams } from 'react-virtualized'
import InfiniteWindowScroller, {
  InfiniteWindowScrollerChildProps,
} from './infinite-window-scroller'

type GetWidthOrHeightFunction = (props: any) => number

type WindowScrollerGridProps = {
  items: any[]
  totalCount: number
  loadingSize: number
  loadMoreData: (vars: any) => void
  dataIsLoading: boolean
  fetchMoreThreshold?: number
  numberOfColumns: number
  numberOfRows: number
  columnWidth: number | GetWidthOrHeightFunction
  rowHeight: number | GetWidthOrHeightFunction
  cellRenderer: GridCellRenderer
}

type AdditionGridContainerProps = {
  onSectionRendered(params: SectionRenderedParams): any
}

// Use the below functions within columnWidth, rowHeight and cellRenderer when needed
export const isFirstColumn = (index: number) => index === 0
// prettier-ignore
export const isLastColumn = (index: number, numberOfColumns: number) => (
  index === numberOfColumns - 1
)
export const isFirstRow = (index: number) => index === 0
export const isLastRow = (index: number, numberOfRows: number) => index === numberOfRows - 1

export const WindowScrollerGrid = (props: WindowScrollerGridProps) => (
  <InfiniteWindowScroller
    items={props.items}
    totalCount={props.totalCount}
    loadingSize={props.loadingSize}
    loadMoreData={props.loadMoreData}
    dataIsLoading={props.dataIsLoading}
    fetchMoreThreshold={props.fetchMoreThreshold}
  >
    {(infiniteWindowScrollerProps) => {
      const onSectionRendered = ({
        columnStartIndex,
        columnStopIndex,
        rowStartIndex,
        rowStopIndex,
      }: SectionRenderedParams) => {
        const startIndex = rowStartIndex * props.numberOfColumns + columnStartIndex
        const stopIndex = rowStopIndex * props.numberOfColumns + columnStopIndex

        infiniteWindowScrollerProps.onRowsRendered({ startIndex, stopIndex })
      }
      return (
        <GridContainer
          onSectionRendered={onSectionRendered}
          {...infiniteWindowScrollerProps}
          {...props}
        />
      )
    }}
  </InfiniteWindowScroller>
)

const GridContainer = (
  props: InfiniteWindowScrollerChildProps & WindowScrollerGridProps & AdditionGridContainerProps
) => {
  const grid = useRef(null)

  useEffect(() => {
    // @ts-ignore - we've checked it exists
    if (grid.current) grid.current.recomputeGridSize()
    return () => {}
  }, [props.width, props.height])

  return (
    <Grid
      {...props}
      ref={grid}
      autoHeight
      autoContainerWidth // stops a vertical scrollbar for appearing
      columnCount={props.numberOfColumns}
      rowCount={props.numberOfRows}
      columnWidth={({ index }: { index: number }) =>
        typeof props.columnWidth === 'function'
          ? props.columnWidth({ index, height: props.height, width: props.width })
          : props.columnWidth
      }
      rowHeight={({ index }: { index: number }) =>
        typeof props.rowHeight === 'function'
          ? props.rowHeight({ index, height: props.height, width: props.width })
          : props.rowHeight
      }
      estimatedColumnSize={props.width / props.numberOfColumns}
      estimatedRowSize={props.height / props.numberOfRows}
      cellRenderer={props.cellRenderer}
    />
  )
}
