import _ from 'lodash'
import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'
import Im from 'shared-js/immutable'
import { default as EnrollmentGroupsState, default as TeamsState } from 'state/teams'
import containerUtils from 'utilities/containers'
import { TabsMixin } from '../tabs'
import ListComponentState from './component-state'
import { EnrollmentGroupsList } from './enrollment-groups-list'
import { Search } from './search'
import { TeamsList } from './teams-list'

@reactMixin.decorate(TabsMixin)
export class ManyUsersSelectionInner extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    teams: TeamsState.Types.many,
    enrollmentGroups: EnrollmentGroupsState.Types.many,
  }

  getSearchComponent = () => this.refs.search

  getTabContentMap = () => {
    const learner = this.props.currentUser.learner
    const tabs = {
      teams: (
        <TeamsList
          currentUser={this.props.currentUser}
          teams={this.props.teams}
          enrolledInPlanId={this.props.enrolledInPlanId}
        />
      ),
      groups: (
        <EnrollmentGroupsList
          currentUser={this.props.currentUser}
          enrollmentGroups={this.props.enrollmentGroups}
          enrolledInPlanId={this.props.enrolledInPlanId}
        />
      ),
      search: (
        <Search
          currentUser={this.props.currentUser}
          fetchOpts={this.props.searchFilter}
          noDataText={this.props.noDataText}
          enrolledInPlanId={this.props.enrolledInPlanId}
          ref="search"
        />
      ),
    }
    if (this.props.hideEnrollmentGroups) {
      delete tabs.groups
    }
    if (learner.is_learner_group_admin && !learner.is_company_admin) {
      delete tabs.groups
      delete tabs.search
    }
    if (this.props.searchOnly) {
      delete tabs.teams
      delete tabs.groups
    }
    return tabs
  }

  render() {
    return (
      <div>
        {this.getTabs()}
        <div style={{ height: 20, clear: 'both' }} />
        {this.getTabContent()}
      </div>
    )
  }
}

export const ManyUsersSelection = Marty.createContainer(ManyUsersSelectionInner, {
  /*
    An interface for selecting many users grouped by teams. If `returnLearners` is True,
    then component will return a list of learner URLs instead of user URLs when getNameAndValue
    is called.

    If getTeamUsers prop is truthy, the users for the current user's team will
    be fetched rather than just a list of teams.
  */
  propTypes: {
    returnLearners: PropTypes.bool,
  },
  contextTypes: {
    currentUser: PropTypes.object,
    routeParams: PropTypes.object.isRequired,
  },
  getDefaultProps() {
    // This `name` value is not actually used. It just
    // signifies to the `Form` component that this is a form
    // input.
    return { name: '_' }
  },
  listenTo: [TeamsState.Store, ListComponentState.Store, EnrollmentGroupsState.Store],
  fetch: {
    teams() {
      const q = {
        limit: 0,
        ordering: 'name',
        fields: ['name', 'id', 'url', 'members', 'members.user'],
      }
      const learner = this.props.currentUser.learner
      if (!learner.is_company_admin) {
        if (learner.is_area_manager) {
          q.areas__managers = this.props.currentUser.id
        } else {
          q.has_user = this.props.currentUser.id
        }
      }
      if (this.props.enrolledInPlanId) {
        q.has_access_to_plan = this.props.enrolledInPlanId
      }
      return ListComponentState.Store.getTeams(q)
    },
    enrollmentGroups() {
      const q = {
        limit: 0,
        ordering: 'name',
        fields: ['name', 'id', 'url', 'members'],
      }
      if (this.props.enrolledInPlanId) {
        q.has_access_to_plan = this.props.enrolledInPlanId
      }
      const enrollmentGroups = ListComponentState.Store.getEnrollmentGroups(q)
      return enrollmentGroups
    },
  },
  UNSAFE_componentWillMount() {
    ListComponentState.Store.resetState(this.props.currentUser)
    if (this.props.onChange) {
      this._listener = ListComponentState.Store.addChangeListener(this.props.onChange)
    }
  },
  componentWillUnmount() {
    if (this._listener) {
      this._listener.dispose()
      this._listener = undefined
    }
  },
  isValid() {
    if (this.props.required) {
      const teams = ListComponentState.Store.getSelectedTeamURLs()
      const enrollmentGroups = ListComponentState.Store.getSelectedEnrollmentGroupURLs()
      const learners = ListComponentState.Store.getSelectedLearnerURLs()
      if (!teams.length && !learners.length && !enrollmentGroups.length) {
        return false
      }
    }
    return true
  },
  getNameAndValue() {
    let teams = ListComponentState.Store.getSelectedTeamURLs()
    const enrollmentGroups = ListComponentState.Store.getSelectedEnrollmentGroupURLs()
    let learners = ListComponentState.Store.getSelectedLearnerURLs()
    let users = ListComponentState.Store.getSelectedUserURLs()
    const usersObj = ListComponentState.Store.getSelectedUsers()
    if (teams && this.props.getTeamUsers) {
      const usersFromTeams = ListComponentState.Store.getUsersFromSelectedTeams()
      users = _.uniq(users.concat(usersFromTeams))
    }
    learners = _.uniq(learners)
    teams = _.uniq(teams)
    console.log('teams', teams)
    if (this.props.returnUsersObj) {
      return {
        users: usersObj,
      }
    }
    if (!this.props.returnLearners) {
      return {
        users,
        teams,
        enrollment_groups: enrollmentGroups,
      }
    }
    return {
      learners,
      teams,
      enrollment_groups: enrollmentGroups,
    }
  },
  pending() {
    return containerUtils.defaultPending(this, ManyUsersSelectionInner)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, ManyUsersSelectionInner, errors)
  },
})
