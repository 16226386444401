import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import moment from 'moment-timezone'
import { t } from 'i18n'
import gql from 'graphql-tag'
import Style from 'style'

import { Transition, TransitionGroup } from 'react-transition-group'
import { Info } from 'components/common/info'
import { ProgressBar } from 'components/common/progress-bar'
import { Link } from 'react-router'
import { NewIndicator } from 'shared-js/components/common/indicators'
import { Modal } from 'components/common/modals'

import PLACEHOLDER_IMAGE from 'img/placeholder.svg'

const WIDTH = 330
const IMG_HEIGHT = (9 / 16) * WIDTH // ratio should be 16:9 with width
const ICON_TOP = IMG_HEIGHT / 2

@Radium
export class TrainingPlanCardDetails extends React.Component {
  static data = {
    trainingPlan: {
      fragment: gql`
        fragment TrainingPlanDetails_plan on TrainingPlanType {
          id
          customThumbnailSmall
          thumbnailUrl
          isPublished
          userIsEnrolled
          nextDueDateForUser
          nextModuleIdForUser
          name
          progressForUser
          hasNewLessonsForCurrentUser @skip(if: $excludeHasNewLessons)
          owner {
            id
            companyLogo
            name
          }
          badges(orderBy: "id", first: 20, trainingUnit: $channelID, badgeActive: true) {
            edges {
              node {
                id
                name
                badgeImage
                trainingUnit {
                  id
                }
              }
            }
          }
          modules(orderBy: "id", isAttemptable: true, first: 20) {
            totalCount
            edges {
              node {
                id
                successfullyCompletedByCurrentUser
              }
            }
          }
        }
      `,
      fields: [
        'modules',
        'name',
        'id',
        'auto_enroll',
        'badges.name',
        'badges.id',
        'badges.badge_image',
        'badges.training_unit.id',
        'owner.id',
        'owner.name',
        'owner.company_logo',
        'thumbnail_url',
        'custom_thumbnail',
        'custom_thumbnail_small',
        'next_due_date_for_user',
        'next_incompleted_module_for_user',
        'progress_for_user',
      ],
    },
  }

  static contextTypes = {
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    featureFlags: PropTypes.objectOf(PropTypes.bool),
  }

  static defaultProps = {
    sizeMultiplier: 1.0,
  }

  getIcons() {
    const DAYS_BEFORE_COLOR_CHANGE = -7 // 7 days before due, icon goes red
    const icons = []
    const userIsEnrolled = this.isFromGraphQLQuery()
      ? this.props.trainingPlan.userIsEnrolled
      : this.props.trainingPlan.user_is_enrolled

    if (userIsEnrolled) {
      const nextDueDate = this.isFromGraphQLQuery()
        ? this.props.trainingPlan.nextDueDateForUser
        : this.props.trainingPlan.next_due_date_for_user
      if (nextDueDate) {
        let iconStyle = {}

        if (this.props.progress !== 100) {
          const daysBefore = moment().diff(moment(nextDueDate), 'days')
          if (daysBefore > DAYS_BEFORE_COLOR_CHANGE) {
            iconStyle = style.redIcon
          } else {
            iconStyle = style.blackIcon
          }
        } else {
          iconStyle = style.greenIcon
        }

        icons.push({
          info: `Due ${moment(nextDueDate).fromNow()}`,
          el: (
            <div>
              <i className="ui icon wait" style={iconStyle} />
            </div>
          ),
        })
      }
    }
    return icons
  }

  isFromGraphQLQuery = () => Boolean(this.props.trainingPlan.modules.edges)

  getModuleProgressFromGraphQL = () => {
    const totalModules = this.props.trainingPlan.modules.totalCount
    const { progressForUser } = this.props.trainingPlan
    const completedModulesCount = totalModules * progressForUser
    return { totalModules, completedModulesCount }
  }

  deprecatedGetModuleProgress = () => {
    const totalModules = this.props.trainingPlan.modules.length
    const { progress_for_user } = this.props.trainingPlan
    const completedModulesCount = totalModules * progress_for_user
    return { totalModules, completedModulesCount }
  }

  generateLink = () => {
    const { trainingPlan, linkTo, isPublicPage, goToChannelOnCompletion } = this.props
    const nextModule = this.isFromGraphQLQuery()
      ? trainingPlan.nextModuleIdForUser
      : trainingPlan.next_incompleted_module_for_user
    // To allow for redirections to the training plan edit page (for the search page)
    let link =
      linkTo || `/views/training_plans/${trainingPlan.id}/modules/${nextModule}/attempts/new/`
    if (goToChannelOnCompletion) {
      link = `${link}?goToChannelOnCompletion=true&ownerChannel=${this.props.ownerChannel}`
    }
    if (isPublicPage) link = null
    return link
  }

  showLockModal = () => {
    this.lockModal.show()
  }

  renderLockOverlay() {
    return (
      <div style={style.lockOverlay} onClick={this.showLockModal}>
        <i className="ui lock icon" style={style.lockIcon} />
        <Modal
          basic
          message
          header={t('plan_locked')}
          content={t('complete_previous_plans')}
          ref={(el) => (this.lockModal = el)}
        />
      </div>
    )
  }

  renderBadgeImage = (badge) => (
    <Info
      key={badge.id}
      content={t('this_plan_contributes_to_earning_the', {
        badgeName: badge.name,
      })}
    >
      <div
        style={{
          ...style.badgeImage,
          backgroundImage: `url(${badge.badgeImage})`,
        }}
      />
    </Info>
  )

  getCustomThumbnailFromGraphQL = () =>
    this.props.trainingPlan.customThumbnailSmall
      ? this.props.trainingPlan.customThumbnailSmall
      : this.props.trainingPlan.thumbnailUrl

  deprecatedCustomThumbnail = () =>
    this.props.trainingPlan.custom_thumbnail_small
      ? this.props.trainingPlan.custom_thumbnail_small
      : this.props.trainingPlan.thumbnail_url

  processBadgeItem = (badge, trainingUnit) => {
    if (trainingUnit.id == (this.context.router && this.context.router.params.channelId)) {
      return this.renderBadgeImage(badge)
    }
  }

  renderBadges = () =>
    this.props.trainingPlan.badges.edges.map((badge) =>
      this.processBadgeItem(badge.node, badge.node.trainingUnit)
    )

  deprecatedRenderBadges = () =>
    this.props.trainingPlan.badges.map((badge) => this.processBadgeItem(badge, badge.training_unit))

  render() {
    const { trainingPlan, sizeMultiplier } = this.props
    const containerHover = Radium.getState(this.state, 'container', ':hover')
    const baseContainerStyle = {
      ...style.container,
      ...(this.props.containerStyle || {}),
    }
    const customThumbnail = this.isFromGraphQLQuery()
      ? this.getCustomThumbnailFromGraphQL()
      : this.deprecatedCustomThumbnail()

    const moduleProgress = this.isFromGraphQLQuery()
      ? this.getModuleProgressFromGraphQL()
      : this.deprecatedGetModuleProgress()

    const completedModulesCount = !this.props.hideProgress
      ? moduleProgress.completedModulesCount
      : 0
    let connectedStatus = t('connect_to_access')
    if (this.props.channelRequested) {
      connectedStatus = t('you_have_requested_access', {
        companyName: this.props.trainingPlan.owner.name,
      })
    }
    if (this.props.channelConnected) {
      connectedStatus = t('you_are_already_connected')
    }

    const currentUser = this.props.currentUser || this.context.currentUser

    const onPlanClick = () => {
      if (!this.props.lock) {
        this.props.onPlanCardClick && this.props.onPlanCardClick()
        const link = this.generateLink()
        this.context.router.push(link)
      } else {
        this.lockModal.show()
      }
    }

    return (
      <div
        className={this.props.className}
        style={Style.funcs.mergeIf(sizeMultiplier, baseContainerStyle, {
          width: style.container.width * sizeMultiplier,
        })}
        onClick={onPlanClick}
      >
        {this.context.featureFlags &&
          this.context.featureFlags.new_content_indicator &&
          this.props.trainingPlan.hasNewLessonsForCurrentUser && (
            <NewIndicator containerStyle={{}} />
          )}
        {this.props.lock && this.renderLockOverlay()}
        {!this.props.trainingPlan.isPublished && (
          <div style={style.unpublishedContainer}>
            <div style={style.unpublished}>{t('unpublished')}</div>
          </div>
        )}
        <div
          key="container"
          style={Style.funcs.mergeIf(
            sizeMultiplier,
            {
              ...style.img,
              backgroundImage: `url(${customThumbnail || PLACEHOLDER_IMAGE})`,
              backgroundColor: '#fbfbfb',
            },
            { height: style.img.height * sizeMultiplier }
          )}
        >
          {this.props.hoverIcon ? (
            <div
              style={{
                ...style.hoverIconContainer,
                width: style.hoverIconContainer.width * sizeMultiplier,
              }}
            >
              <TransitionGroup>
                {containerHover && (
                  <Transition timeout={{ enter: 0, exit: 200 }}>
                    {(state) => (
                      <i
                        style={{
                          ...style.hoverIcon,
                          top: style.hoverIcon.top * sizeMultiplier,
                          ...transitionStyles[state],
                        }}
                        className={`ui icon ${this.props.hoverIcon}`}
                      />
                    )}
                  </Transition>
                )}
              </TransitionGroup>
            </div>
          ) : null}
          {!this.props.hideBadges && (
            <div style={style.badgeContainer}>
              {this.isFromGraphQLQuery() ? this.renderBadges() : this.deprecatedRenderBadges()}
            </div>
          )}
        </div>

        {this.props.isPublicPage ? (
          <div key="connectOverlay" style={style.connectOverlay}>
            <div
              onClick={this.props.showTrainingPlanDetails}
              key={`connect-text${this.props.trainingPlan.id}`}
              style={
                !(this.props.channelConnected || this.props.channelRequested)
                  ? style.connectText
                  : style.connectedText
              }
            >
              {connectedStatus}
            </div>
          </div>
        ) : null}

        {!this.props.isPublicPage && !this.props.hideProgress ? (
          <ProgressBar progress={completedModulesCount} total={moduleProgress.totalModules} />
        ) : null}

        <div style={style.companyNameRow}>
          <div style={style.companyNameContainer}>
            <img style={style.companyLogo} src={trainingPlan.owner.companyLogo} />
            <div style={style.companyName}>{trainingPlan.owner.name}</div>
          </div>
          <div style={style.iconsList}>
            {this.getIcons().map((icon) => (
              <Info content={icon.info} key={icon.info} position="top">
                <div style={style.icon} className="due-clock">
                  {icon.el}
                </div>
              </Info>
            ))}
          </div>
        </div>
        <Link to={this.generateLink()} style={style.trainingNameLink}>
          <div
            style={{
              ...style.trainingPlanName,
              ...(this.props.nameStyle || {}),
              ...(containerHover ? style.trainingPlanNameHover : {}),
            }}
          >
            {trainingPlan.name}
          </div>
        </Link>
      </div>
    )
  }
}

const style = {
  container: {
    width: WIDTH,
    margin: '12px 12px 25px 12px',
    display: 'flex',
    flexDirection: 'column',
    color: Style.vars.deprecatedColors.black,
    position: 'relative',
    '@media screen and (max-width: 400px)': {
      width: window.innerWidth * 0.9,
      marginBottom: 20,
    },
  },
  connectOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: WIDTH,
    height: IMG_HEIGHT,
    backgroundColor: 'black',
    // marginTop: -IMG_HEIGHT,
    position: 'absolute',
    // zIndex: 9999,
    color: 'white',
    transition: 'all .2s ease-in-out',
    opacity: 0,
    ':hover': {
      opacity: 1,
    },
  },
  connectText: {
    fontSize: 18,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  connectedText: {
    fontSize: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  img: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fbfbfb',
    display: 'flex',
    alignItems: 'flex-end',
    height: IMG_HEIGHT,
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    ':hover': {},
  },
  hoverIconContainer: {
    width: WIDTH,
    height: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
  },
  hoverIcon: {
    top: ICON_TOP,
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: Style.vars.deprecatedColors.primaryFontColor,
    zIndex: '3',
    backgroundColor: Style.vars.deprecatedColors.primary,

    transition: 'all .2s ease-in-out',
  },
  companyNameRow: {
    marginTop: 8,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  companyNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  companyLogo: {
    maxWidth: 26,
    maxHeight: 20,
    marginRight: 5,
  },
  companyName: {
    fontSize: 12,
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#888',
  },
  iconsList: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: 5,
  },
  greenIcon: {
    color: Style.vars.deprecatedColors.oliveGreen,
  },
  redIcon: {
    color: Style.vars.deprecatedColors.red,
  },
  blackIcon: {
    color: '#666',
  },
  greyIcon: {
    color: '#bbb',
  },
  trainingNameLink: {
    color: 'black',
  },
  trainingPlanName: {
    margin: '5px 15px 5px 0',
    fontSize: 14,
    lineHeight: '20px',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  trainingPlanNameHover: {
    textDecoration: 'underline',
  },
  badgeImage: {
    margin: 7,
    height: 50,
    width: 50,
    borderRadius: 3,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#f7f7f7',
    border: '3px solid #f7f7f7',
    boxShadow: 'rgba(0, 0, 0, 0.4) 0 0 12px',
  },
  badgeContainer: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    alignItems: 'flex-end',
    width: '100%',
    minHeight: 34,
  },
  unpublishedContainer: {
    height: 0,
  },
  unpublished: {
    position: 'relative',
    top: 0,
    display: 'inline-block',
    padding: '6px 20px 7px 10px',
    backgroundColor: 'rgba(236, 21, 21, 0.72)',
    color: '#fff',
    borderBottomRightRadius: 27,
  },
  lockOverlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundImage: 'linear-gradient(#000a, #000a, #0009, #0000)',
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  lockIcon: {
    color: 'red',
    fontSize: 48,
    pointerEvents: 'none',
  },
}

const transitionStyles = {
  entering: {
    transform: 'scale(0.7)',
    opacity: 0,
  },
  entered: {
    transform: 'scale(1)',
    opacity: 1,
  },
  exiting: {
    transform: 'scale(0.7)',
    opacity: 0,
  },
}
