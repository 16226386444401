import { Form, SubmitButton, TextInput } from 'components/common/form-deprecated'
import { BRAND, CONTENT_SELLER, RETAILER } from 'core/constants'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import ChannelsState from 'state/channels'

export class ChannelDetailsForm extends React.Component {
  static propTypes = {
    submitText: PropTypes.string,
    onChannelSaved: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  onSubmitAndValid = (data) => {
    const { company } = this.props.currentUser
    const { subscription } = company
    // If we decide to change this setting to false by default, ensure that
    // accounts with user_permissions_enabled = False still have it set to true.
    data.order = 0
    data.company = company.url

    // Default to public in all circumstances. Brands can subsequently
    // decide to share their channels once they are properly set up.
    data.public = false
    data.request_to_access = true

    const companyRegion = company.region_of_responsibility
    if (companyRegion && company.company_type !== CONTENT_SELLER) {
      data.regions = [companyRegion]
    }

    this.setState({ loading: true })
    ChannelsState.ActionCreators.create(data).then((res) => {
      this.props.onChannelSaved(Im.freeze(res.body))
    })
  }

  render() {
    return (
      <Form onSubmitAndValid={this.onSubmitAndValid}>
        <h3>{t('name')}</h3>
        <TextInput name="name" required fadeInitial={false} />
        <SubmitButton text={t('submit')} loading={this.state.loading} />
      </Form>
    )
  }
}
