import config from 'core/config'

const MAPS_API = `https://maps.googleapis.com/maps/api/js?key=${
  config.GOOGLE_MAPS_API_KEY
}&libraries=places`

export function setupMaps() {
  // Create script
  const script = document.createElement('script')
  script.src = MAPS_API
  // Inject script
  document.head.appendChild(script)
}
