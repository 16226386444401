import { JQueryComponentMixin } from 'components/common/jquery-component-mixin.jsx'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import reactMixin from 'react-mixin'
import $ from 'vendor/jquery/semantic'

@reactMixin.decorate(JQueryComponentMixin)
export class Dropdown extends React.Component {
  /*
    Wrapper for the http://semantic-ui.com/modules/dropdown.html
    module.

    TODO - SearchableSelect component should be moved to here.
  */

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.options, this.props.options) ||
      !_.isEqual(prevProps.children, this.props.children)
    ) {
      this.refresh()
    }
  }

  onClick(evt) {
    if (!this.props.stopPropagation) return
    evt.stopPropagation()
  }

  getDropdownEl() {
    // eslint-disable-next-line react/no-find-dom-node
    return $(ReactDOM.findDOMNode(this)).find('.dropdown')
  }

  manipulateDOMWithJQuery() {
    this.$el = this.getDropdownEl().dropdown(this.props.dropdownOpts)
    if (this.props.disabled) {
      // eslint-disable-next-line react/no-find-dom-node
      $(ReactDOM.findDOMNode(this))
        .find('.ui.dropdown')
        .addClass('disabled')
    } else {
      this.$el.on('click', (event) => this.onClick(event))
    }
  }

  toggle(evt) {
    this.$el.dropdown('toggle')
  }

  show(evt) {
    this.$el.dropdown('show')
  }

  renderJQueryControlledContent() {
    return (
      <div className={this.props.className} style={this.props.style}>
        {this.props.children}
      </div>
    )
  }
}
