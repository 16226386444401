import React from 'react'
import { useWindowWidth } from 'shared-js/utilities/hooks'
import { isMobileWidth } from 'shared-js/utilities/browser'
import styled from 'styled-components'
import { PaginationProps } from 'shared-js/state/graphql/pagination'
import { GridCellProps } from 'react-virtualized'
import { WindowScrollerGrid, getFetchableCount } from 'components/common/virtualized-components'
import { DiscoverableContentQuery_PublicTrainingUnit__List_edges as Channel_edges } from '../gql-types/DiscoverableContentQuery'
import {
  ChannelCard,
  LoadingChannelCard,
  BOTTOM_MARGIN,
  LEFT_MARGIN,
  CHANNEL_TITLE_HEIGHT,
} from './channel-card'

type ChannelListProps = {
  pagination: PaginationProps
  channels: (Channel_edges | null)[]
  totalCount: number
  loadingSize: number
}

const ChannelList = ({ pagination, channels, totalCount, loadingSize }: ChannelListProps) => {
  const totalFetchableItems = getFetchableCount({
    itemsLength: channels.length,
    totalCount,
    loadingSize,
  })
  const windowWidth = useWindowWidth()
  const numberOfColumns = isMobileWidth(windowWidth) ? 2 : 4
  const numberOfRows = Math.ceil(totalFetchableItems / numberOfColumns)

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
    const index = columnIndex + rowIndex * numberOfColumns

    if (channels && channels[index]) {
      const channel = (channels as Channel_edges[])[columnIndex + rowIndex * numberOfColumns].node!
      return (
        <div key={key} style={style}>
          <ChannelCard
            channel={channel}
            width={style.width ? (style.width as number) - LEFT_MARGIN : 0}
            height={style.height ? (style.height as number) - BOTTOM_MARGIN : 0}
          />
        </div>
      )
    }

    if (index >= totalCount) return null

    return (
      <LoadingChannelCard
        key={key}
        style={style}
        width={style.width ? (style.width as number) - LEFT_MARGIN : 0}
        height={style.height ? (style.height as number) - BOTTOM_MARGIN : 0}
      />
    )
  }

  const getColumnWidth = ({ width }: { width: number }) => width / numberOfColumns

  const getRowHeight = ({ width }: { width: number }) => {
    const columnWidth = getColumnWidth({ width })
    const cardWidth = columnWidth - LEFT_MARGIN
    return cardWidth + CHANNEL_TITLE_HEIGHT + BOTTOM_MARGIN
  }

  return (
    <Container>
      <WindowScrollerGrid
        items={channels}
        totalCount={totalCount}
        loadingSize={loadingSize}
        loadMoreData={pagination.loadMoreData}
        dataIsLoading={pagination.dataIsLoading}
        numberOfColumns={numberOfColumns}
        numberOfRows={numberOfRows}
        columnWidth={getColumnWidth}
        rowHeight={getRowHeight}
        cellRenderer={cellRenderer}
      />
    </Container>
  )
}

const Container = styled.div`
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-left: -10px;
  }
`

export default ChannelList
