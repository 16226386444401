import React from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import _ from 'lodash'

import { t } from 'shared-js/i18n'
import { CHANNEL_CARD_WIDTH } from 'components/common/channel-card/base/card'
import {
  ChannelCardLearner,
  ChannelCardLearnerLoading,
} from 'components/common/channel-card/learner'
import { ChannelTabListQuery_TrainingUnit__List as ChannelsList } from './gql-types/ChannelTabListQuery'

import { Title, TitleLoading } from './common'

type Props = {
  channels: ChannelsList['edges'],
}

export const ChannelCardList = ({ channels }: Props) => {
  const due = channels.filter((i) => i!.node!.earliestIncompleteDueDateForUser)
  const noDueDate = channels.filter((i) => !i!.node!.earliestIncompleteDueDateForUser)
  return (
    <React.Fragment>
      {due.length > 0 && (
        <ChannelCardContainer>
          <Title text={`${'⏰'} ${t('whats_due')}`} />
          {due.map((item) => (
            <ChannelCardLearner key={item!.node!.id!} channel={item!.node!} />
          ))}
        </ChannelCardContainer>
      )}
      {due.length > 0 && noDueDate.length > 0 && <Spacer />}
      {noDueDate.length > 0 && (
        <ChannelCardContainer>
          <Title text={`${'🚀'} ${t('explore')}`} />
          {noDueDate.map((item) => (
            <ChannelCardLearner key={item!.node!.id!} channel={item!.node!} />
          ))}
        </ChannelCardContainer>
      )}
    </React.Fragment>
  )
}

ChannelCardList.fragment = gql`
  fragment ChannelCardList_channel on TrainingUnitType {
    id
    earliestIncompleteDueDateForUser
    ...ChannelCardLearner_channel
  }
  ${ChannelCardLearner.fragment}
`

export const ChannelCardListLoading = ({ showTitle }: { showTitle: boolean }) => (
  <React.Fragment>
    {!showTitle && <Spacer />}
    <ChannelCardContainer>
      {showTitle && <TitleLoading />}
      {_.map(_.range(0, 12), (num) => (
        <ChannelCardLearnerLoading key={num} />
      ))}
    </ChannelCardContainer>
  </React.Fragment>
)

export const ChannelCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, ${CHANNEL_CARD_WIDTH}px);
  grid-gap: 33px;
  width: 100%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE11: just yolo wrap it, styled components/css-grid has little support for IE */
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
  }
`

const Spacer = styled.div`
  height: 33px;
`
