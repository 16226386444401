import { Panel } from 'components/common/box'
import { PrimaryButton } from 'components/common/buttons'
import { InfiniteScroll } from 'components/common/infinite-scroll'
import { LoadingContainer } from 'components/common/loading'
import { Modal } from 'components/common/modals'
import { remoteSearchMixinFactory } from 'components/common/search'
import { ChannelCardList } from 'components/discover/channel-discovery/channel-cards/page'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import reactMixin from 'react-mixin'
import { Link } from 'react-router'
import ChannelShareRequestsState from 'state/channel-share-requests'
import createPaginatedStateContainer from 'state/pagination'
import PublicChannelState from 'state/public-channels'
import TagsState from 'state/tags'
import Style from 'style'
import containerUtils from 'utilities/containers'
import { resolve } from 'utilities/deprecated-named-routes'
import $y from 'utilities/yaler'
import PageState from './page-state'
import { withRedirectToFrontend2ByFeatureFlag } from '../../../common/redirect-to-frontend2'

const ALL = 'all'
const CONTENT_PATHNAME = '/views/content/discover/'

const COLUMN_PANELS = '@media screen and (max-width: 500px)'

const styles = {
  pageContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  panelInner: {
    flexDirection: 'row',
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tagItem: {
    position: 'relative',
    padding: '3px 7px',
    borderRadius: 3,
    marginBottom: 1,
    cursor: 'pointer',
    backgroundColor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#0000',
    borderBottomColor: '#eee',
    transition: 'all 0.2s',
    ':hover': {
      transform: 'scale(1.04)',
    },
  },
  clearTagsContainer: {
    width: '100%',
    float: 'left',
  },
  selectedTagItem: {
    backgroundColor: Style.vars.deprecatedColors.green,
    color: 'white',
    borderColor: '#0000',
    borderBottomColor: '#0000',
  },
  clearFilterItem: {
    backgroundColor: Style.vars.deprecatedColors.grey,
    border: `1px solid ${Style.vars.deprecatedColors.grey}`,
    color: Style.vars.deprecatedColors.white,
    marginBottom: 10,
  },
  searchContainer: {
    margin: '20px 10% 70px',
    textAlign: 'center',
  },
  search: {
    container: {
      maxWidth: 800,
      margin: '0 auto',
    },
    border: 'none',
    borderBottom: '1px solid #CCCCCC',
    borderRadius: 0,
    height: 50,
    fontSize: 20,
  },
  finishedBtnContainer: {
    width: '100%',
    height: 60,
  },
  btnStyle: {
    marginLeft: 10,
    float: 'right',
    marginBottom: 10,
    marginTop: 20,
    right: 15,
  },
  channelContent: {
    width: '100%',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 160,
    marginLeft: 15,
    [COLUMN_PANELS]: {
      width: 100,
      marginLeft: 0,
    },
  },
}

export function getConnectionStatusText({ channel, newFollow = false, detailedView }) {
  // Channel is not re-fetched after creating a connection or request,
  // so change connectionStatusText once a user requests or connections
  // to a channel.

  const requestToAccess = channel.request_to_access
  const subscribed = channel.is_subscribed_to_by_current_company
  const requested = channel.existing_request_for_current_company

  let text = t('connect')

  if ((requested && !subscribed) || (newFollow && requestToAccess)) {
    text = t('requested')
  } else if (subscribed || (newFollow && !requestToAccess)) {
    text = t('connected')
  }

  return text
}

@Radium
class TagItem extends React.Component {
  onTagClick = () => {
    if (this.props.isLoading()) return
    this.props.onClick(this.props.tag.id)
  }

  render() {
    let tagStyle = this.props.active
      ? {
          ...styles.tagItem,
          ...styles.selectedTagItem,
        }
      : styles.tagItem
    // Prevent user from clicking on a bunch of tags while data is still loading.
    if (this.props.isLoading()) {
      tagStyle = Style.funcs.merge(tagStyle, { cursor: 'default' })
    }

    return (
      <div key={this.props.tag.id} style={tagStyle} onClick={this.onTagClick}>
        {t(this.props.tag.name.toLowerCase().replace(/\s/g, '_'))}
      </div>
    )
  }
}

@Radium
class ChannelFilters extends React.Component {
  static data = {
    tags: {
      many: true,
      required: false,
      fields: ['name', 'url', 'id', 'num_public_channels'],
    },
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { categories } = this.context.location.query
    if (categories) {
      categories.split(',').forEach((c) => {
        PageState.ActionCreators.toggleTagActive(parseInt(c))
      })
    }
  }

  onTagClick = (tagId) => {
    PageState.ActionCreators.toggleTagActive(tagId)
    this.context.router.push({
      pathname: CONTENT_PATHNAME,
      query: {
        ...this.context.location.query,
        categories: PageState.Store.getTagsFilter().join(','),
      },
    })
  }

  isTagActive = (tag) => PageState.Store.isTagActive(tag.id)

  clearTagFilters = () => {
    PageState.ActionCreators.clearTagFilters()
    const newQuery = { ...this.context.location.query }
    delete newQuery.categories
    this.context.router.push({
      pathname: CONTENT_PATHNAME,
      query: {
        ...newQuery,
      },
    })
  }

  render() {
    const tagItems = this.props.tags.map((tag) => (
      <TagItem
        tag={tag}
        key={tag.id}
        onClick={this.onTagClick}
        active={this.isTagActive(tag)}
        isLoading={this.props.isLoading}
      />
    ))
    return (
      <div style={styles.tagsContainer}>
        {PageState.Store.getTagsFilter().length > 0 && (
          <div style={styles.clearTagsContainer}>
            <div
              onClick={this.clearTagFilters}
              style={{ ...styles.tagItem, ...styles.clearFilterItem }}
            >
              {t('clear_filters')}
            </div>
          </div>
        )}
        {tagItems}
      </div>
    )
  }
}

@Radium
class ChannelFiltersContainer extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  showModal = () => {
    this.modal.show()
  }

  render() {
    return (
      <div
        style={{
          ...styles.filtersContainer,
          marginTop: this.props.contentManagement ? -10 : 20,
        }}
      >
        <h5>{t('categories')}</h5>
        <LoadingContainer
          ref="tagLoadingContainer"
          loadingProps={{ tags: this.props.tags }}
          noDataText={t('')}
          createComponent={() => <ChannelFilters {...this.props} />}
        />
      </div>
    )
  }
}

@Radium
@reactMixin.decorate(
  remoteSearchMixinFactory(
    PageState.ActionCreators.setPublicChannelSearch.bind(PageState.ActionCreators)
  )
)
class ChannelDirectoryPage extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      renderKey: 0,
      numChannelsConnected: 0,
    }
  }

  incrementNumChannelsConnected = () => {
    this.setState({
      numChannelsConnected: this.state.numChannelsConnected + 1,
    })
  }

  render() {
    return (
      <Panel innerStyle={styles.panelInner}>
        <ChannelFiltersContainer {...this.props} />

        <div style={styles.pageContent}>
          <div style={styles.channelContent}>
            {this.props.contentManagement === false ? (
              <Link to={resolve('training')}>
                <div style={styles.finishedBtnContainer}>
                  <button
                    key={this.state.renderKey}
                    ref={(finishedSelectingButton) =>
                      (this.finishedSelectingButton = finishedSelectingButton)
                    }
                    style={styles.btnStyle}
                    className="ui right labeled icon green button"
                    onClick={this.props.onFinishedSelectingClick}
                  >
                    <i className="right arrow icon" />
                    Finished
                  </button>
                </div>
              </Link>
            ) : null}

            <div style={styles.searchContainer}>
              {this.getSearchInput({
                style: styles.search,
                initialValue: `${t('find_content_share_content')}...`,
              })}
            </div>

            <LoadingContainer
              ref="channelLoadingContainer"
              loadingProps={{
                channels: this.props.channels,
              }}
              noDataText={`${t('no_channels_available')}. ${t('try_choosing_another_filter')}`}
              createComponent={() => (
                <InfiniteScroll
                  loadMore={this.props.loadMore}
                  moreAvailable={this.props.moreAvailable}
                  isLoading={this.props.isLoading}
                >
                  <ChannelCardList
                    {...this.props}
                    incrementNumChannelsConnected={this.incrementNumChannelsConnected}
                  />
                </InfiniteScroll>
              )}
            />
          </div>
        </div>
      </Panel>
    )
  }
}

const PageInner = createPaginatedStateContainer(ChannelDirectoryPage, {
  contextTypes: {
    currentUser: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  },

  listenTo: [
    PublicChannelState.Store,
    PageState.Store,
    ChannelShareRequestsState.Store,
    TagsState.Store,
  ],

  fetch: {
    tags() {
      const { filter } = this.context.location.query
      const opts = _.extend({
        limit: 0,
        fields: $y.getFields(ChannelFilters, 'tags'),
        includes_region: this.context.currentUser.company.office_location,
        has_public_channels: filter || ALL,
        ordering: 'name',
      })
      return TagsState.Store.getItems(opts)
    },
  },

  paginate: {
    store: PublicChannelState.Store,
    propName: 'channels',
    dependantOn: [TagsState.Store],

    getQuery() {
      const query = {
        subscribable_by: this.context.currentUser.company.id,
        // Demo account should see non-paid companies, as this makes it easier to set up
        // fake channels to connect to.
        company__subscription__discoverable: true,
        company__deactivated__isnull: true,
        limit: 30,
        has_content: true,
        includes_region: this.context.currentUser.company.office_location,
        fields: $y.getFields(ChannelCardList, 'channels'),
        ordering: 'name',
      }
      if (this.context.currentUser.learner.is_demo_account) {
        delete query.company__subscription__discoverable
      }
      const search = PageState.Store.getPublicChannelSearch()
      if (search) {
        query.search = search
        delete query.order_as_suggestions_for_company
        query.ordering = '-search_rank'
      }
      const tags = PageState.Store.getTagsFilter()
      if (tags.length) {
        query.tags = tags
      }
      return query
    },
  },

  UNSAFE_componentWillMount() {
    PageState.Store.resetState()
  },

  pending() {
    return containerUtils.defaultPending(this, ChannelDirectoryPage)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, ChannelDirectoryPage, errors)
  },
})

export const Page = withRedirectToFrontend2ByFeatureFlag('frontend2_content_discover')(PageInner)
