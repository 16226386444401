import React, { useState } from 'react'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { Chip } from 'ui-kit'
import { DropdownInput } from 'shared-js/components/common/inputs'
import { useIndustries } from './industries-provider'
import { RelevantIndustryTagsQuery_Tag__List_edges as IndustryTags } from './gql-types/RelevantIndustryTagsQuery'

type MobileFilterProps = {
  updateIndustriesFilter(industryID: string): void
  industryIDsFiltered: string[]
}

const FilterByIndustryMobile = ({
  updateIndustriesFilter,
  industryIDsFiltered,
}: MobileFilterProps) => {
  const { apolloProps } = useIndustries()
  const [industryFilterIsExpanded, toggleIndustryFilterIsExpanded] = useState(false)
  // we want errors in the industry list to fail silently
  if (apolloProps.error) return null

  const hasFetchedIndustries = apolloProps.data?.Tag__List
  const hasIndustries = Boolean(apolloProps.data?.Tag__List?.totalCount)

  if (hasFetchedIndustries) {
    if (!hasIndustries) return null

    const sectionTitle = industryIDsFiltered.length
      ? `${t('industry')} (${industryIDsFiltered.length})`
      : t('industry')
    return (
      <Container>
        <TitleContainer onClick={() => toggleIndustryFilterIsExpanded(!industryFilterIsExpanded)}>
          <SectionTitle>{sectionTitle}</SectionTitle>
          {industryFilterIsExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
        </TitleContainer>
        {industryFilterIsExpanded && (
          <FilterDropdown
            industries={apolloProps?.data?.Tag__List?.edges || []}
            updateIndustriesFilter={updateIndustriesFilter}
            industryIDsFiltered={industryIDsFiltered}
          />
        )}
      </Container>
    )
  }

  // do not show a loading spinner as the company may not have any industries to show
  return null
}

const FilterDropdown = ({
  industries,
  updateIndustriesFilter,
  industryIDsFiltered,
}: { industries: (IndustryTags | null)[] } & MobileFilterProps) => {
  const renderFilteredIndustry = (filteredIndustryID: string) => {
    const filteredIndustry = industries.find(
      (industry) => industry?.node?.id === filteredIndustryID
    )

    return (
      <ChipContainer>
        <Chip
          key={filteredIndustry?.node?.id}
          label={filteredIndustry?.node?.name}
          clickable
          onClick={() => {
            if (filteredIndustry?.node) updateIndustriesFilter(filteredIndustry.node.id)
          }}
          onDelete={() => {
            if (filteredIndustry?.node) updateIndustriesFilter(filteredIndustry.node.id)
          }}
        />
      </ChipContainer>
    )
  }

  const handleDropdownChange = (industryID: string) => {
    const industryTag = industries.find((industry) => industry?.node?.id === industryID)
    if (industryTag?.node) updateIndustriesFilter(industryTag.node.id)
  }

  const options = industries.map((industryTag) => {
    return {
      label: industryTag?.node?.name,
      value: industryTag?.node?.id,
    }
  })

  return (
    <FilterContainer>
      <ChipsContainer>
        {industryIDsFiltered.map((industryID) => {
          if (industryID) return renderFilteredIndustry(industryID)
          return null
        })}
      </ChipsContainer>
      <DropdownContainer>
        <DropdownInput
          placeholder={t('select_an_industry')}
          options={options}
          onChange={handleDropdownChange}
          value=""
        />
      </DropdownContainer>
    </FilterContainer>
  )
}

const Container = styled.div`
  padding-top: 20px;
  border-top: solid 1px rgba(220, 220, 220, 0.5);
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const SectionTitle = styled.div`
  text-transform: uppercase;
`

const FilterContainer = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
`

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ChipContainer = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
`

// a bit hacky but unfortunately there's a built-in margin in our common component :(
const DropdownContainer = styled.div`
  margin-top: -10px;
  margin-bottom: -20px;
`

export default FilterByIndustryMobile
