import { FieldHeader } from 'components/common/form-deprecated'
import { ANALYTICS_EVENTS } from 'core/constants'
import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Clipboard from 'react-clipboard.js'
import { View } from 'react-native'
import {
  FieldArray,
  Form,
  Formik,
  getPropsFromFormik,
  SubmitButton,
} from 'shared-js/components/common/form'
import * as Inputs from 'shared-js/components/common/inputs'
import { ClipboardField } from 'shared-js/components/common/clipboard'
import { validateEmail } from 'shared-js/utilities/validators'
import InvitationsState from 'state/invitations'
import Style from 'style/index'
import { getOrigin } from 'utilities/http'
import { nextSignupStep } from '../../common'
import { renderWelcomeMessage } from '../common'

const INVITE_PAGE = 'invite page'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sendTitle: {
    textAlign: 'center',
    fontSize: 18,
    margin: '0px 0px 20px 0px',
  },
  copyIcon: {
    fontSize: 16,
    color: Style.vars.deprecatedColors.primary,
  },
  form: {
    width: '100%',
    textAlign: 'center',
  },
  emailInput: {
    maxWidth: '70%',
    margin: 'auto',
  },
  emailInputContainer: {
    width: '100%',
  },
  errorMsg: {
    textAlign: 'center',
    color: Style.vars.deprecatedColors.errorRed,
  },
  submitButton: {
    marginTop: 0,
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  submitButtonInvalid: {
    background: Style.vars.deprecatedColors.fadedPrimary,
    cursor: 'not-allowed',
  },
  skip: {
    textAlign: 'center',
    color: Style.vars.deprecatedColors.xDarkGrey,
  },
  skipInfo: {
    margin: '0px 0px 5px 0px',
    width: '75%',
  },
  skipLink: {
    cursor: 'pointer',
    color: 'rgb(75, 75, 75)',
  },
}

export class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      linkCopied: false,
      emailInputCount: 3,
    }
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  onEmailInputFocus = (props) => {
    const realEmailsGiven = this.getRealEmails(props.values.emails)
    const numEmailsGiven = realEmailsGiven.length
    if (numEmailsGiven + 1 === this.state.emailInputCount) {
      this.increaseEmailInputCount()
    }
  }

  getRealEmails = (arr) => {
    const realEmails = _.filter(
      arr,
      (emailObj) => !!emailObj && emailObj.email !== '' && validateEmail(emailObj.email)
    )
    return realEmails
  }

  increaseEmailInputCount = () => {
    this.setState({ emailInputCount: this.state.emailInputCount + 1 })
  }

  onCopyLink = (formikProps) => {
    this.setState({ linkCopied: true })
    analytics.track(ANALYTICS_EVENTS.COPIED_INVITE_LINK, {
      companyName: this.props.currentUser.company.name,
      user: this.props.currentUser.email,
    })
    formikProps.setValues({ emails: [{ email: '' }] })
    formikProps.validateForm()
  }

  handleSubmit = (values, actions) => {
    let realEmailsGiven = null
    if (values && values.emails) {
      realEmailsGiven = this.getRealEmails(values.emails)
    }
    if (realEmailsGiven && realEmailsGiven.length > 0) {
      this.createInvitations(realEmailsGiven)
    }
    const companyType = this.props.currentUser && this.props.currentUser.company.company_type
    nextSignupStep(companyType, INVITE_PAGE)
  }

  createInvitations = (emails) => {
    const inviteData = {
      company: this.props.currentUser.company.url,
      inviter: this.props.currentUser.url,
    }
    analytics.track(ANALYTICS_EVENTS.SENT_INVITES, {
      numInvites: emails.length,
    })
    emails.forEach((emailObj) => {
      const data = _.merge({}, inviteData, {
        registered_emails: emailObj.email,
      })
      InvitationsState.ActionCreators.create(data).catch((err) => {
        console.log(err)
      })
    })
  }

  skip = () => {
    analytics.track(ANALYTICS_EVENTS.SKIPPED_INVITE)
    const companyType = this.props.currentUser && this.props.currentUser.company.company_type
    nextSignupStep(companyType, INVITE_PAGE)
  }

  validate = (values) => {
    const errors = {}
    const emailErrors = []
    values.emails.forEach((emailObj, idx) => {
      if (!emailObj) return
      const { email } = emailObj
      if (email && !validateEmail(email)) {
        emailErrors[idx] = t('please_enter_a_valid_email_address')
      }
    })
    const realEmailsGiven = this.getRealEmails(values.emails)
    if (emailErrors.length > 0) {
      errors.emails = emailErrors
    } else if (!realEmailsGiven.length && !this.state.linkCopied) {
      // If link not copied and no emails then user should not be able
      // to press submit
      errors.linkNotCopied = true
    }
    return errors
  }

  getInputNameForIndex(i) {
    return `emails[${i}]email`
  }

  render() {
    const invitationID =
      this.props.currentUser &&
      this.props.currentUser.company &&
      this.props.currentUser.company.standard_invitation_id
    const invitationLink = invitationID && `${getOrigin()}/signup/user/?sid=${invitationID}`
    const inputCount = _.range(0, this.state.emailInputCount)
    return (
      <div style={styles.container}>
        {this.props.currentUser && renderWelcomeMessage(this.props.currentUser)}
        <div style={styles.sendTitle}>{t('send_invitations')}</div>
        <Formik
          validate={this.validate}
          initialValues={{ emails: [] }}
          onSubmit={(values, actions) => {
            this.handleSubmit(values, actions)
          }}
        >
          {(innerProps) => (
            <Form style={styles.form}>
              <FieldHeader required>{t('share_link_with_team')}</FieldHeader>
              <ClipboardField
                textToCopy={invitationLink}
                containerStyle={{ width: '70%', marginBottom: 20 }}
                onSuccess={() => this.onCopyLink(innerProps)}
              />
              <FieldHeader style={{ margin: 0 }} required>
                {`${t('or')} ${t('invite_by_email')}`}
              </FieldHeader>
              <FieldArray
                name="emails"
                render={(arrayHelpers) => (
                  <View style={{ marginBottom: 10 }}>
                    {inputCount.map((index, i) => {
                      const name = this.getInputNameForIndex(i)
                      const error =
                        innerProps.errors && innerProps.errors.emails && innerProps.errors.emails[i]
                      const touched =
                        innerProps.touched &&
                        innerProps.touched.emails &&
                        innerProps.touched.emails[i]
                      return (
                        <View key={i}>
                          <Inputs.EmailInput
                            style={styles.emailInput}
                            containerStyle={styles.inputContainer}
                            errorMessageStyle={styles.errorMsg}
                            placeholder="email@website.com"
                            placeholderColor={Style.vars.deprecatedColors.grey}
                            onFocus={() => this.onEmailInputFocus(innerProps)}
                            {...getPropsFromFormik(innerProps, name)}
                            error={error}
                            touched={touched}
                          />
                        </View>
                      )
                    })}
                  </View>
                )}
              />
              <SubmitButton
                containerStyle={styles.submitButton}
                testID="invitation-submit-btn"
                loading={innerProps.isSubmitting}
                isValid={innerProps.isValid}
                onPress={innerProps.handleSubmit}
              >
                {t('continue')}
              </SubmitButton>
            </Form>
          )}
        </Formik>
        <p style={{ ...styles.skip, ...styles.skipInfo }}>{t('invite_team_late_info')}</p>
        <a style={{ ...styles.skip, ...styles.skipLink }} onClick={this.skip}>
          {t('skip_for_now')}
          <i style={{ margin: 0 }} className="angle right icon" />
        </a>
      </div>
    )
  }
}
