import React from 'react'
import moment from 'moment'
import { CompanyAvatar } from 'shared-js/components/common/avatar'
import { Query } from '@apollo/react-components'
import gql from 'fraql'
import { UserRemovedText } from './index'

const NORMAL_WEIGHT = 500
const LIGHT_WEIGHT = 200
// all other text sizes will be em ratios of this parent size
const PARENT_TEXT_SIZE = '8px'

export class ReplyMessageCard extends React.Component {
  static getReplyMessage = gql`
    query getReplyMessage($feedbackId: ID!) {
      ModuleFeedback__Item(id: $feedbackId) {
        id
        responseMessage {
          id
          created
          body
          author {
            id
            firstName
            lastName
            profilePhoto
            company {
              id
              name
              companyLogo
            }
          }
        }
      }
    }
  `

  renderReplyMessageBody(body) {
    const bodyWithLines = body.split('\n')
    return bodyWithLines.map((line, index) => <p key={Math.random() * index}>{line}</p>)
  }

  renderReplyMessage(replyMessage) {
    if (!replyMessage) {
      return null
    }
    const { author, body, created } = replyMessage
    const { company } = author
    const messageCreated = moment(created).format('MMM D')

    return (
      <div style={styles.container}>
        <div style={styles.headerRow}>
          <div style={styles.subject}>
            {company ? (
              <CompanyAvatar company={company} canNavigateToProfile={false} isRound />
            ) : (
              <UserRemovedText />
            )}
            <div style={styles.subejectText}>
              <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{company.name}</span>
              <span
                style={{
                  fontSize: '10px',
                  fontWeight: LIGHT_WEIGHT,
                  color: '#aaaaaa',
                }}
              >
                {messageCreated}
              </span>
            </div>
          </div>
        </div>
        <div style={styles.body}>
          <div style={styles.comment}>{this.renderReplyMessageBody(body)}</div>
        </div>
      </div>
    )
  }

  render() {
    const queryVars = {
      feedbackId: this.props.feedbackId,
    }
    return (
      <Query
        query={ReplyMessageCard.getReplyMessage}
        variables={queryVars}
        fetchPolicy="network-only"
      >
        {(apolloProps) => {
          if (apolloProps.loading) {
            return null
          }
          const responseMessage = apolloProps.data
            ? apolloProps.data.ModuleFeedback__Item.responseMessage
            : {}
          return this.renderReplyMessage(responseMessage)
        }}
      </Query>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    width: '90%',
    background: 'white',
    border: '1px solid rgb(229,229,229)',
    margin: '10px 0px',
    borderRadius: '2px',
    padding: '8px 20px',
    fontSize: PARENT_TEXT_SIZE,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '5px',
  },
  subject: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: '5px',
  },
  subejectText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    marginLeft: '10px',
    fontFamily: 'Open Sans,  sans-serif',
    width: '100%',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  comment: {
    fontFamily: 'Open Sans,  sans-serif',
    fontSize: '1.5em',
    fontWeight: NORMAL_WEIGHT,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    marginTop: '10px',
    marginLeft: '50px',
    marginRight: '10%',
    marginBottom: '10px',
  },
}
