import { SecondaryButton } from 'components/common/buttons'
import { GatedFeatureModal, GROUPS_AND_AREAS } from 'components/common/gated-feature'
import { SetDueDateForPlanModal } from 'components/enrollments/due-date-modal'
import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import Style from 'style'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: '200',
    fontSize: '1.6rem',
    marginBottom: 10,
  },
  listTitle: {
    fontSize: '1.1rem',
    marginTop: 18,
    marginBottom: 3,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
  },
  selectionContainer: {
    borderLeft: '5px solid #eee',
    paddingLeft: 15,
    transition: 'all 0.5s ease',
    ':hover': {
      borderLeft: `5px solid ${Style.vars.deprecatedColors.blue}`,
    },
  },
  listItem: {
    display: 'inline-block',
    backgroundColor: Style.vars.deprecatedColors.blue,
    color: '#fff',
    padding: '4px 8px',
    borderRadius: 4,
    margin: '4px 8px 4px 0px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    ':hover': {
      backgroundColor: Style.vars.deprecatedColors.primary,
      color: Style.vars.deprecatedColors.primaryFontColor,
    },
  },
  manualEnrollBtn: {
    width: 150,
    marginLeft: 0,
  },
  manageEnrollBtn: {
    width: '100%',
    marginLeft: 0,
  },
  break: {
    marginTop: 40,
    marginBottom: 40,
  },
  tooltip: {
    tooltip: { left: -100, width: 350 },
    content: { fontSize: 14, whiteSpace: 'wrap', fontWeight: 'normal' },
  },
}

@Radium
export class DueDateSection extends React.Component {
  static propTypes = {
    plan: PropTypes.object.isRequired,
  }

  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  }

  static data = {
    plan: {
      fields: ['name', 'num_enrolled_users', 'num_enrolled_users_in_own_company'],
    },
  }

  onManualEnrollClick = () => {
    if (this.context.currentUser.company.subscription.groups_and_areas_enabled) {
      this.manualEnrollModal.show()
    } else {
      this.dueDatesGatedModal.show()
    }
  }

  render() {
    return (
      <div>
        <div>
          <SecondaryButton
            style={styles.manualEnrollBtn}
            onClick={() => this.onManualEnrollClick()}
          >
            <i className="ui icon user" /> {t('set_due_dates')}
          </SecondaryButton>
        </div>
        <SetDueDateForPlanModal
          ref={(m) => (this.manualEnrollModal = m)}
          currentUser={this.context.currentUser}
          selectedTrainingPlans={[this.props.plan]}
        />
        <GatedFeatureModal
          ref={(m) => (this.dueDatesGatedModal = m)}
          headerText={t('upgrade_to_pro_due_dates')}
          descriptionText={t('all_user_admin_access')}
          featureType={GROUPS_AND_AREAS}
        />
      </div>
    )
  }
}
