import React from 'react'
import { Modal } from 'components/common/modals'
import { t } from 'i18n'

import { getDisplayNameFunction, EVERYONE, CUSTOM } from './constants'

const styles = {
  objectRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}
export class ConfirmChangeModal extends React.Component {
  show = () => {
    this.modal.show()
  }

  render = () => {
    const newSelectedIds = Object.keys(this.props.selectionChanges.newSelected)
    const newDeselectedIds = Object.keys(this.props.selectionChanges.newDeselected)
    const displayFunction = getDisplayNameFunction(this.props.tab)
    return (
      <Modal
        ref={(c) => (this.modal = c)}
        header={t('are_you_sure')}
        onConfirm={this.props.onConfirm}
        basic
        content={(
          <React.Fragment>
            {this.props.companyAccessValueChanged && (
              <React.Fragment>
                {this.props.companyAccessValue == EVERYONE ? (
                  <div>{t('you_are_enrolling_everyone_in_your_company')}</div>
                ) : (
                  <div>{t('you_are_turning_off_company_wide_enrollment')}</div>
                )}
                <br />
              </React.Fragment>
            )}
            {newSelectedIds.length > 0 && (
              <div>
                {t('you_are_adding_the_following_enrollments')}
                {newSelectedIds.map((id) => {
                  const obj = this.props.selectionChanges.newSelected[id]
                  return (
                    <div key={id} style={styles.objectRow}>
                      <div>- </div>
                      {displayFunction(obj)}
                    </div>
                  )
                })}
              </div>
            )}
            <br />
            {newDeselectedIds.length > 0 && (
              <div>
                {t('you_are_removing_the_following_enrollments')}

                {newDeselectedIds.map((id) => {
                  const obj = this.props.selectionChanges.newDeselected[id]
                  return (
                    <div key={id} style={styles.objectRow}>
                      <div>- </div>
                      {displayFunction(obj)}
                    </div>
                  )
                })}
                <br />
              </div>
            )}
          </React.Fragment>
        )}
      />
    )
  }
}
