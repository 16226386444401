import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import Style from 'shared-js/style'

import { useCurrentUser } from 'shared-js/utilities/hooks'
import { Title, TitleLoading } from '../common'
import { Plans, PlansLoading } from './plans'
import { PlanQuery, PlanQueryVariables } from './gql-types/PlanQuery'

export const PLANS_QUERY = gql`
  query PlanQuery($userId: ID!) {
    TrainingPlan__List(
      first: 5
      isAttemptable: true
      hasEnrollmentsForUser: $userId
      orderByRecentlyViewedForUser: $userId
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...Plans_plan
        }
      }
    }
  }
  ${Plans.fragment}
`

const Loading = () => (
  <RightPanel>
    <TitleContainer>
      <TitleLoading />
    </TitleContainer>
    <PlansLoading />
  </RightPanel>
)

export const RecentlyViewedDesktop = () => {
  const currentUser = useCurrentUser()
  const apolloProps = useQuery<PlanQuery, PlanQueryVariables>(PLANS_QUERY, {
    variables: { userId: currentUser.id },
  })

  if (apolloProps.loading) {
    return <Loading />
  }

  const edges =
    apolloProps.data &&
    apolloProps.data.TrainingPlan__List &&
    apolloProps.data.TrainingPlan__List.edges

  return (
    <RightPanel>
      <TitleContainer>
        <Title text={`${'📚'} ${t('recently_started')}`} />
      </TitleContainer>
      {edges && <Plans plans={edges!} />}
    </RightPanel>
  )
}

const TitleContainer = styled.div`
  width: 330px; /* hack to align title with plan card */
  margin-bottom: 10px;
`

const RightPanel = styled.div`
  padding: 20px 70px;
  border-top: 1px solid ${Style.colors.grey100};
  flex-grow: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1200px) {
    display: none;
  }
`
