import { Button, Icon, Modal as SemanticModal } from 'semantic-ui-react'
import { AppContextProviders } from 'shared-js/context/app-context-providers'
import { FeatureFlagContext, CommonRouterContext } from 'shared-js/context/context'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { t } from 'i18n'
import { ModalContent, ModalHeader } from './utils'

/*
Wrapper around Semantic's react implementation of modals.
https://react.semantic-ui.com/modules/modal
Example usage:

...
showModal = () => {
  this.m.show();
}

render() {
  return (
    <div>
      <Modal ref={m => (this.m = m)} header={'Here is a cool modal'} size={'large'}>
        <div>This is shown as modal content</div>
      </Modal>
      <Button onClick={this.showModal} />
    </div>
  )
}

*/
export class Modal extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static propTypes = {
    showOnInit: PropTypes.bool,
    size: PropTypes.oneOf(['fullscreen', 'large', 'mini', 'small', 'tiny', undefined]),
    onOpen: PropTypes.func,
    onHidden: PropTypes.func,
    onConfirm: PropTypes.func,
    basic: PropTypes.bool, // Full screen, simple text
    closeOnDimmerClick: PropTypes.bool,
    header: PropTypes.node,
    noConfirm: PropTypes.bool, // If truthy don't provide buttons for confirmation
    message: PropTypes.bool, // If truthy, show confirmation or yes / no buttons
    leftText: PropTypes.string,
    rightText: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      modalOpen: props.showOnInit,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showOnInit !== this.props.showOnInit) {
      this.setState({ modalOpen: nextProps.showOnInit })
    }
  }

  show = () => {
    if (!this.state.modalOpen) this.setState({ modalOpen: true })
  }

  hide = (evt) => {
    if (this.state.modalOpen) this.setState({ modalOpen: false })
    if (this.props.onHidden) {
      this.props.onHidden(evt)
    }
  }

  onConfirm = () => {
    if (this.props.onConfirm) this.props.onConfirm()
    this.hide()
  }

  onOpen = () => {
    if (this.props.onOpen) this.props.onOpen()
  }

  isOpen = () => this.state.modalOpen

  getModalContentsComponent = () => this.content

  render() {
    return (
      <AppContextConsumer>
        {({ featureFlags, commonRouter, currentUser }) => (
          <SemanticModal
            size={this.props.size ? this.props.size : 'small'}
            open={this.state.modalOpen}
            onClose={this.hide}
            onOpen={this.onOpen}
            basic={this.props.basic}
            dimmer="blurring"
            closeIcon={this.props.closeIcon !== undefined ? this.props.closeIcon : true}
            closeOnDimmerClick={this.props.closeOnDimmerClick}
            closeOnEscape={this.props.closeOnEscape}
            style={this.props.style}
          >
            <AppContextProviders
              currentUser={currentUser}
              commonRouterOverride={commonRouter}
              featureFlags={featureFlags}
            >
              {this.props.header ? (
                <ModalHeader ref={(header) => (this.header = header)} {...this.props} />
              ) : null}

              <ModalContent ref={(content) => (this.content = content)} {...this.props} />

              {this.props.basic && !this.props.noConfirm ? (
                <SemanticModal.Actions>
                  {this.props.message ? (
                    <Button
                      ref={(confirmBtn) => (this.confirmBtn = confirmBtn)}
                      color="green"
                      onClick={this.onConfirm}
                      inverted
                    >
                      <Icon name="checkmark" /> 
{' '}
{this.props.rightText || t('okay')}
                    </Button>
                  ) : (
                    <div>
                      <Button
                        ref={(noBtn) => (this.noBtn = noBtn)}
                        basic
                        color="red"
                        onClick={this.hide}
                        inverted
                      >
                        <Icon name="remove" /> 
{' '}
{this.props.leftText || t('no')}
                      </Button>
                      <Button
                        ref={(yesBtn) => (this.yesBtn = yesBtn)}
                        color="green"
                        onClick={this.onConfirm}
                        inverted
                      >
                        <Icon name="checkmark" /> 
{' '}
{this.props.rightText || t('yes')}
                      </Button>
                    </div>
                  )}
                </SemanticModal.Actions>
              ) : null}
            </AppContextProviders>
          </SemanticModal>
        )}
      </AppContextConsumer>
    )
  }
}

export class ConfirmDeleteModal extends React.Component {
  show() {
    this.modal.show()
  }

  render() {
    return (
      <Modal
        ref={(e) => (this.modal = e)}
        basic
        header={t('are_you_sure_you_want_to_delete')}
        closeIcon={null}
        onConfirm={this.props.onConfirm}
      />
    )
  }
}

export class MessageModal extends React.Component {
  show() {
    this.modal.show()
  }

  render() {
    return (
      <Modal
        ref={(e) => (this.modal = e)}
        basic
        header={this.props.header}
        closeIcon={null}
        message
      >
        {this.props.children}
      </Modal>
    )
  }
}

function AppContextConsumer({ children }) {
  const featureFlags = useContext(FeatureFlagContext)
  const commonRouter = useContext(CommonRouterContext)
  const currentUser = useCurrentUser()
  return children({ featureFlags, commonRouter, currentUser })
}
AppContextConsumer.propTypes = {
  children: PropTypes.func.isRequired,
}
