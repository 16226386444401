/* eslint-disable react/jsx-no-literals */
import PropTypes from 'prop-types'
import React from 'react'
import UsersState from 'state/users'

import { Modal } from 'components/common/modals/index'
import { AsyncSearchableSelect } from 'components/common/form-deprecated/select'
import AdminTeamsState from 'state/admin-teams'

class TeamSearchableSelect extends React.Component {
  getNameAndValue() {
    return this.refs.searchableSelection.getNameAndValue()
  }

  fetch = (search) => {
    if (!search) return null
    return AdminTeamsState.Store.getItems({
      limit: 10,
      search,
      company: this.props.companyId,
      ordering: '-search_rank',
      fields: ['name', 'url', 'id', 'company'],
    })
  }

  makeOption = (team) => ({
    value: team.url,
    label: team.name,
  })

  isValid() {
    return this.refs.searchableSelection.isValid()
  }

  render() {
    return (
      <AsyncSearchableSelect
        placeholder="Search for a team..."
        {...this.props}
        fetch={this.fetch}
        makeOption={this.makeOption}
        name={this.props.name || 'teamURL'}
        ref="searchableSelection"
        required
      />
    )
  }
}

export class ChangeTeam extends React.Component {
  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      teamURL: null,
      isLoading: false,
    }
  }

  onTeamChange = (val) => {
    this.setState({ teamURL: val })
  }

  changeTeam = () => {
    this.setState({
      isLoading: true,
    })
    UsersState.ActionCreators.doDetailAction(this.props.user.id, 'set_learner_group', {
      learner_group: this.state.teamURL,
    })
      .then((res) => {
        this.setState({ isLoading: false })
        this.modal.hide()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <div>
        <button type="button" className="ui green button" onClick={() => this.modal.show()}>
          Change Team
        </button>
        <Modal
          ref={(el) => (this.modal = el)}
          style={{ minHeight: 450 }}
          header={`Move ${this.props.user.full_name} to a new team`}
          closeOnDimmerClick={false}
        >
          <div className="content">
            <TeamSearchableSelect
              name="teamURL"
              companyId={this.props.companyId}
              onChange={this.onTeamChange}
            />
            <button
              type="button"
              className="ui blue button"
              onClick={this.changeTeam}
              disabled={!this.state.teamURL}
            >
              {`Yes, move ${this.props.user.full_name}`}
              {this.state.isLoading && <div className="ui active small inline loader" />}
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}
