import React from 'react'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import { useWindowWidth } from 'shared-js/utilities/hooks'
import { Button } from 'ui-kit'
import { getListSummary } from 'utilities'
import { useHandleChannelSubscriptions } from './context'
import { ConnectedCompaniesQuery_PublicCompany__List_edges_node as PublicCompany } from './gql-types/ConnectedCompaniesQuery'

const SMALL_PHONE_WIDTH = 350

type ActionItemsProps = {
  isSavingChanges: boolean
  saveChanges(): void
  currentlyHasSubscribedCompanies: boolean
}

const ActionItems = ({
  isSavingChanges,
  saveChanges,
  currentlyHasSubscribedCompanies,
}: ActionItemsProps) => {
  const { companiesToSubscribe, companiesToUnsubscribe } = useHandleChannelSubscriptions()
  const hasMadeChanges = Boolean(companiesToSubscribe.length || companiesToUnsubscribe.length)

  const getCompanyName = (item: PublicCompany) => item.name
  const getRemainderDefinition = (numRemainingItems: number) =>
    `${t('and')} ${t(
      'other_companies',
      { companiesCount: numRemainingItems },
      { pluralize: numRemainingItems }
    )}`

  const windowWidth = useWindowWidth()
  let saveButtonText = currentlyHasSubscribedCompanies ? t('save_changes') : t('share_titlecase')
  if (windowWidth < SMALL_PHONE_WIDTH && currentlyHasSubscribedCompanies) {
    saveButtonText = t('save')
  }

  return (
    <CTAContainer>
      <MessagesContainer>
        {companiesToSubscribe.length > 0 && (
          <>
            {`${t('selected_titlecase')} ${getListSummary(
              [...companiesToSubscribe],
              2,
              getRemainderDefinition,
              getCompanyName
            )}`}
          </>
        )}
        {companiesToUnsubscribe.length > 0 && (
          <WarningContainer>
            {`${t('revoking_access_for')} ${getListSummary(
              [...companiesToUnsubscribe],
              2,
              getRemainderDefinition,
              getCompanyName
            )}. ${t('removing_a_company_from_this_channel_will_delete_its_content')}`}
          </WarningContainer>
        )}
      </MessagesContainer>
      <ButtonContainer>
        <Button onClick={saveChanges} disabled={!hasMadeChanges} isLoading={isSavingChanges}>
          {saveButtonText}
        </Button>
      </ButtonContainer>
    </CTAContainer>
  )
}

const CTAContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px 24px;
  @media (max-width: ${SMALL_PHONE_WIDTH}px) {
    padding: 4px;
  }
`

const MessagesContainer = styled.div`
  @media (max-width: 420px) {
    display: none;
  }
`

const WarningContainer = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.red900};
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 8px;
`

const ButtonContainer = styled.div`
  margin-top: 24px;
  & > button {
    margin: 0 4px;
  }
  @media (max-width: 420px) {
    margin-top: 12px;
  }
`

export default ActionItems
