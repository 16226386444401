const PRODUCTION = {
  SENTRY_DSN: 'https://7bb5c11d73a14506a9f237749ed8adb9@o14176.ingest.sentry.io/1250206',
  OAUTH_CLIENT_ID: '729689349142-1koo5fi96ofprifealm8nqo8kn8ofn6q.apps.googleusercontent.com',
  PUSHER_KEY: '8f18a9833f619a1dba92',
  WISTIA_TOKEN: 'e39edac6b979f0eb9683a2b432944eb2694fc97971ffc7928a4b25d56484d246',
  WISTIA_PROJECT_ID: 'ranmmjh0tv',
  EMBEDLY_API_TOKEN: '67031635e1e84ea6a8df7f86c0c3d84b',
  LAUNCH_DARKLY_KEY: '5aa19bd729d0ad20060bbc41',
  FILE_STACK_KEY: 'A0LWpzw2T9mgHmtfq30Zjz',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAvM_ApffLaMqQGq5czeoPQ5mHuh0U2qi8',
}

const DEVELOPMENT: typeof PRODUCTION = {
  SENTRY_DSN: 'https://7bb5c11d73a14506a9f237749ed8adb9@o14176.ingest.sentry.io/1250206',
  OAUTH_CLIENT_ID: '729689349142-8poe1a38vh2mkpg3otk4g7nm1adt6ndg.apps.googleusercontent.com',
  PUSHER_KEY: 'e1eafedbf59c49f198cb',
  WISTIA_TOKEN: 'e39edac6b979f0eb9683a2b432944eb2694fc97971ffc7928a4b25d56484d246',
  WISTIA_PROJECT_ID: 'akyg3y0ngt',
  EMBEDLY_API_TOKEN: '67031635e1e84ea6a8df7f86c0c3d84b',
  LAUNCH_DARKLY_KEY: '5aa19bd729d0ad20060bbc40',
  FILE_STACK_KEY: 'A0LWpzw2T9mgHmtfq30Zjz',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAvM_ApffLaMqQGq5czeoPQ5mHuh0U2qi8',
}

const CONFIG = process.env.NODE_ENV === 'production' ? PRODUCTION : DEVELOPMENT

export default CONFIG
