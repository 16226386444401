import React from 'react'

import PublicCompaniesState from 'state/public-companies'
import CompaniesState from 'state/companies'
import { AsyncSearchableSelect } from 'components/common/form-deprecated/select'
import { t } from 'i18n'

export class CompanySearchableSelect extends React.Component {
  /*
    An `AsyncSearchableSelect` component for company
    URLs. Should be used whenever a company needs
    to be selected on a form.
  */
  getNameAndValue() {
    return this.refs.searchableSelection.getNameAndValue()
  }

  makeOption = (co) => {
    // display company id for staff (internal) users for easier ID
    let label = co.name
    if (this.props.internalUse) {
      label = `${co.name} (${co.id.toString()}) - ${co.office_location} - ${co.company_url ||
        'No URL'} - ${co.user_count} users`
    }
    return {
      value: co.url,
      label,
    }
  }

  fetch = (search) => {
    if (!search) return null
    let State = PublicCompaniesState
    if (this.props.fetchPrivateCompanies) {
      State = CompaniesState
    }
    return State.Store.getItems({
      limit: 20,
      search,
      ordering: '-search_rank',
      deactivated__isnull: true,
      fields: [
        'name',
        'url',
        'id',
        'search_rank',
        'company_url',
        'user_count',
        'region_of_responsibility',
        'office_location',
      ],
    })
  }

  render() {
    return (
      <AsyncSearchableSelect
        initialValue={t('search_for_a_entity', { entity: t('company') })}
        {...this.props}
        fetch={this.fetch}
        makeOption={this.makeOption}
        name={this.props.name || 'companyURL'}
        ref="searchableSelection"
        required
      />
    )
  }
}
