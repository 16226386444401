import React from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import { LogoName_channel } from './gql-types/LogoName_channel'

type Props = {
  channel: LogoName_channel,
  isAdmin?: boolean,
}

// NOTE:  Using this component for both learner/admin cards means the same amount of text is
// always displayed, so users don't get frustrated.
// the prop `isAdmin` isn't named well but is used to determine wether to show that channel.name (if
// true) or channel.displayName
export const LogoName = ({ channel, isAdmin }: Props) => {
  const currentUser = useCurrentUser()
  // Can't use === as the old API and GraphQL have different ID types
  const companyOwnsChannel = currentUser && channel.company.id == currentUser.company.id

  const displayName = channel.displayName || channel.name
  const channelName = companyOwnsChannel && isAdmin ? channel.name : displayName

  return (
    <Container>
      <LogoContainer>
        <Logo src={channel.viableLogo || ''} />
      </LogoContainer>

      <ChannelName>{channelName}</ChannelName>
    </Container>
  )
}

LogoName.fragment = gql`
  fragment LogoName_channel on TrainingUnitType {
    name
    displayName
    viableLogo
    company {
      id
    }
  }
`

LogoName.deprecatedFields = ['name', 'display_name', 'viable_logo', 'company.id']

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogoContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  height: 75px;
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  max-width: 75px;
  max-height: 75px;
  border-radius: 5px;
`

const ChannelName = styled.div`
  font-size: 1.4rem;
  margin: 0px 7px;
  line-height: 1.7rem;
  font-weight: bold;
  text-align: center;
  color: white;
  flex: 1;
  max-height: 72px; /* don't show more than 3 lines of text */
  overflow: hidden;
  text-overflow: ellipsis;
`
