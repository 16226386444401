import React from 'react'
import Style from 'shared-js/style'
import { t } from 'shared-js/i18n'
import styled from 'styled-components'
import { SlideToggleInput } from 'shared-js/components/common/inputs'
import { SectionTitle, SectionTitleIcon, SectionTitleContainer } from './common'

type ShareWithMyNetworkSectionProps = {
  isDiscoverable: boolean
  setIsDiscoverable(value: boolean): void
}

type InfoProps = {
  isActive: boolean
}

const ShareWithMyNetworkSection = ({
  isDiscoverable,
  setIsDiscoverable,
}: ShareWithMyNetworkSectionProps) => (
  <SectionContainer>
    <SectionTitleContainer>
      <SectionTitleIcon iconName="people" />
      <SectionTitle>{t('share_with_my_network')}</SectionTitle>
    </SectionTitleContainer>
    <Container>
      <Info isActive={isDiscoverable}>
        {t('all_of_your_connections_will_be_able_to_add_this_channel')}
      </Info>
      <SlideToggleInput value={isDiscoverable} onChange={(value) => setIsDiscoverable(value)} />
    </Container>
  </SectionContainer>
)

const Info = styled.div<InfoProps>`
  color: ${(props) => (props.isActive ? Style.colors.grey800 : Style.colors.grey600)};
  margin-right: 10px;
  @media (max-width: 768px) {
    max-width: 75%;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
`

const SectionContainer = styled.div`
  padding-bottom: 24px;
  border-bottom: solid 1px rgba(220, 220, 220, 0.5);
`

export default ShareWithMyNetworkSection
