import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import PropTypes from 'prop-types'
import { ApolloProvider } from 'react-apollo'
import { configureForLocale, getNavigatorLocale } from 'i18n'
import { qs } from 'utilities/http'
import { hidePreload } from 'shared-js/utilities/generic'
import { AppContextProviders } from 'shared-js/context/app-context-providers'
import { makeCommonRouterHandlers, CommonRouterActionCreator } from 'shared-js/routing'
import Fallback from 'components/common/error-fallback'

import { getApolloClient } from '../app'
import { ConnectionInvitationProvider } from './context'

function getLocaleFromURL() {
  return qs('locale')
}

export class App extends React.Component {
  // React-Router passes location
  // as a prop because this is the top
  // level component. We want to pass it along
  // as context.
  static childContextTypes = {
    location: PropTypes.object,
    router: PropTypes.object,
    routeParams: PropTypes.object,
  }

  getChildContext() {
    return { location: this.props.location, routeParams: this.props.params }
  }

  componentDidMount() {
    hidePreload()
  }

  render() {
    const locale = getLocaleFromURL() || getNavigatorLocale()
    configureForLocale(locale)
    const apolloClient = getApolloClient()
    const commonRouter = new CommonRouterActionCreator(
      makeCommonRouterHandlers(this.props.router),
      this.props.router.location,
      this.props.routeParams
    )
    return (
      <ErrorBoundary FallbackComponent={Fallback}>
        <ApolloProvider client={apolloClient}>
          <AppContextProviders router={commonRouter} currentUser={null}>
            <ConnectionInvitationProvider>
              <div style={{ height: '100%' }}>{this.props.children}</div>
            </ConnectionInvitationProvider>
          </AppContextProviders>
        </ApolloProvider>
      </ErrorBoundary>
    )
  }
}
