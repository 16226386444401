import React from 'react'

import Style from 'shared-js/style'

import chopsticksImg from 'img/chopsticks-upright.svg'

import Button from 'shared-js/components/common/buttons'

const containerStyl = {
  boxSizing: 'border-box',
  backgroundColor: 'white',
  color: Style.vars.deprecatedColors.textBlack,
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  overflowY: 'auto',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
}

const titleStyl = {
  textAlign: 'center',
  color: Style.vars.deprecatedColors.xDarkGrey,
  padding: 40,
}

const imgStyle = {
  backgroundImage: `url(${chopsticksImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  alignSelf: 'center',
  height: 200,
  width: 200,
  marginTop: 50,
}

const btnStyle = {
  alignSelf: 'center',
  marginBottom: 20,
}

const detailsBtn = {
  color: Style.vars.deprecatedColors.darkGrey,
  cursor: 'pointer',
  alignSelf: 'center',
}

const preSty = {
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  padding: '0 6px',
}

export default class Fallback extends React.Component {
  constructor() {
    super()
    this.state = {
      showDetails: false,
    }
  }

  toggleDetails = () => {
    this.setState({ showDetails: !this.state.showDetails })
  }

  requestHelp = () => {
    if (window.Intercom) {
      window.Intercom('showNewMessage', "Help! I'm seeing an error page.")
    }
  }

  renderDetails() {
    const { error, componentStack } = this.props
    return (
      <div>
        <hr />
        <p>Error Message:</p>
        <pre style={{ ...preSty, color: '#ff0404' }}>{error.message}</pre>
        <hr />
        <p>Stack:</p>
        <pre style={{ ...preSty, color: '#ff0404' }}>{error.stack}</pre>
        <hr />
        <p>Component Stack:</p>
        <pre style={{ ...preSty, color: '#f3d429' }}>{componentStack}</pre>
        <hr />
      </div>
    )
  }

  render() {
    return (
      <div style={containerStyl}>
        <div style={imgStyle} />
        <h2 style={titleStyl}>Oh no! Something went wrong.</h2>
        <Button color="primary" containerStyle={btnStyle} onPress={this.requestHelp}>
          Call for help
        </Button>
        {this.props.error &&
          <p style={detailsBtn} onClick={this.toggleDetails}>
            {!this.state.showDetails ? 'Show Details' : 'Hide Details'}
          </p>
        }
        {this.state.showDetails && this.renderDetails()}
      </div>
    )
  }
}
