import { qs } from 'utilities/http'
import { isAndroid, isIPhone } from 'shared-js/utilities/browser'

const IOS_APP_DOWNLOAD_LINK = 'https://itunes.apple.com/app/myagi/id1093253823'
const ANDROID_APP_DOWNLOAD_LINK =
  'intent://myagi.com/download/#Intent;package=com.myagi;scheme=https;end;'

const APP_OPEN_LINK_BASE = 'myagi://'

export default function goToApp(): void {
  /* If in browser, this will go straight to the app download page.
  If on iOS or Android, will try open the app directly or will navigate
  straight to the app store so the user can download it */
  if (!isIPhone() && !isAndroid()) {
    window.open('https://myagi.com/download/', '_blank')
    return
  }

  if (isAndroid()) {
    window.location.href = ANDROID_APP_DOWNLOAD_LINK
    return
  }

  // Try to open the app, but if that doesn't work, open the app store.
  setTimeout(() => {
    window.location.href = IOS_APP_DOWNLOAD_LINK
  }, 25)

  // Use the `next` param as inAppRoute (will be set on login page).
  // Otherwise, use the current pathname.
  const inAppRoute = (qs('next') ?? window.location.pathname).replace(/^\//, '')
  window.location.href = APP_OPEN_LINK_BASE + inAppRoute
}
