import React from 'react'
import {
  isGoogleDoc,
  isPrezi,
  isTypeform,
  isBookWhen,
  isGuru,
  isAdobeSpark,
} from 'shared-js/utilities/url-type-checking'
import EmbedlyCard from './embedly-card'

const RICH = 'rich'
const VIDEO = 'video'

export interface EmbedProps {
  page: {
    snippet_url: string
    snippet_type: string
    data: { html: string }
    url_from_embed_code: boolean
  }
}

/** Enbeds content in either an `EmbedlyCard` or `iframe`. See implementation for details. */
export function RichEmbed({ page }: EmbedProps) {
  const url = page.snippet_url
  const type = page.snippet_type
  const data = page.data
  const isFromEmbed = page.url_from_embed_code

  // We know that URLs from these services will look good in an iframe, so preference
  // is to render them in one. We also know that if the url originally came from an embed code,
  // then we can safely render it in an iframe.
  if (
    isFromEmbed ||
    isGoogleDoc(url) ||
    isPrezi(url) ||
    isBookWhen(url) ||
    isTypeform(url) ||
    isGuru(url) ||
    isAdobeSpark(url)
  ) {
    return (
      <iframe
        title="embedded-content"
        src={url}
        frameBorder="0"
        width="100%"
        height="100%"
        allowFullScreen
        // @ts-ignore
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      />
    )
  }

  if (type === RICH || type === VIDEO) {
    // SnippetPage types use embedly oembed API endpoint to fetch
    // the correct HTML for embedding videos and rich content on the page,
    // so we can set inner HTML here.
    return <div dangerouslySetInnerHTML={{ __html: data.html }} />
  }

  return <EmbedlyCard url={url} />
}
