import React, { useState } from 'react'
import { t } from 'shared-js/i18n'
import { useQuery } from '@apollo/react-hooks'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import gql from 'graphql-tag'
import { Button, TextButton } from 'ui-kit'
import { LoadingSpinner } from 'shared-js/components/common/loading'
import IndustryList from './industries-list'
import { Container, Title, ButtonContainer, CancelButtonContainer } from './common'
import { GetCurrentLevelTwoIndustries_Tag__List_edges as ActiveIndustryEdge } from './gql-types/GetCurrentLevelTwoIndustries'
import { GetLevelTwoIndustries_Tag__List_edges as IndustryEdge } from './gql-types/GetLevelTwoIndustries'

const MAX_FETCH = 100

type IndustriesStepProps = {
  firstLevelIndustries: string[]
  setIndustries(): void
  setSecondLevelIndustries(val: string[]): void
  resetForm(): void
}

type IndustriesInnerProps = {
  activeIndustries: string[]
  levelTwoIndustries: (IndustryEdge | null)[]
  setSecondLevelIndustries(val: string[]): void
  resetForm(): void
}

const GET_CURRENT_LEVEL_TWO_INDUSTRIES = gql`
  query GetCurrentLevelTwoIndustries(
    $companyID: ID!
    $loadingSize: Int!
    $firstLevelIndustries: [ID]!
  ) {
    Tag__List(
      first: $loadingSize
      orderBy: ["id"]
      company: $companyID
      category: "general"
      secondLevelTags: true
      inHierarchyWithTags: $firstLevelIndustries
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`

const GET_LEVEL_TWO_INDUSTRIES = gql`
  query GetLevelTwoIndustries($loadingSize: Int!, $firstLevelIndustries: [ID]!) {
    Tag__List(
      secondLevelTags: true
      inHierarchyWithTags: $firstLevelIndustries
      first: $loadingSize
      orderBy: ["name"]
    ) {
      edges {
        node {
          id
          name
        }
      }
      totalCount
    }
  }
`

const SecondLevelIndustries = ({
  firstLevelIndustries,
  setIndustries,
  ...otherProps
}: IndustriesStepProps) => {
  const currentUser = useCurrentUser()
  const currentLevelTwoIndustries = useQuery(GET_CURRENT_LEVEL_TWO_INDUSTRIES, {
    variables: {
      loadingSize: MAX_FETCH,
      companyID: currentUser.company.id,
      firstLevelIndustries,
    },
  })

  const levelTwoIndustries = useQuery(GET_LEVEL_TWO_INDUSTRIES, {
    variables: {
      loadingSize: MAX_FETCH,
      firstLevelIndustries,
    },
  })

  const hasFetchedData =
    !levelTwoIndustries.loading &&
    levelTwoIndustries.data?.Tag__List &&
    !currentLevelTwoIndustries.loading &&
    currentLevelTwoIndustries.data?.Tag__List

  if (hasFetchedData && levelTwoIndustries.data.Tag__List.totalCount === 0) {
    setIndustries()
  }

  const activeIndustries =
    hasFetchedData &&
    currentLevelTwoIndustries.data.Tag__List.edges
      .filter((industry: ActiveIndustryEdge) => industry?.node?.id)
      .map((industry: ActiveIndustryEdge) => industry!.node!.id)

  return hasFetchedData && levelTwoIndustries.data.Tag__List.totalCount ? (
    <SecondLevelIndustriesInner
      activeIndustries={activeIndustries}
      levelTwoIndustries={levelTwoIndustries.data.Tag__List.edges}
      {...otherProps}
    />
  ) : (
    <LoadingSpinner />
  )
}

const SecondLevelIndustriesInner = ({
  activeIndustries,
  levelTwoIndustries,
  setSecondLevelIndustries,
  resetForm,
}: IndustriesInnerProps) => {
  const [activeIndustriesIDs, setActiveIndustriesIDs] = useState<string[]>(activeIndustries)

  const toggleIndustry = (industryID: string, isActive: boolean) => {
    setActiveIndustriesIDs((prevIDs) =>
      isActive ? prevIDs.filter((id) => id !== industryID) : [...prevIDs, industryID]
    )
  }

  const setIndustries = () => setSecondLevelIndustries(activeIndustriesIDs)

  return (
    <>
      <Container>
        <Title>{t('you_can_further_refine_your_industry')}</Title>
      </Container>
      <Container>
        <IndustryList
          industries={levelTwoIndustries}
          activeIndustriesIDs={activeIndustriesIDs}
          toggleIndustry={toggleIndustry}
        />
      </Container>
      <ButtonContainer>
        <CancelButtonContainer>
          <TextButton onClick={resetForm}>{t('cancel')}</TextButton>
        </CancelButtonContainer>
        <Button onClick={setIndustries}>{t('next')}</Button>
      </ButtonContainer>
    </>
  )
}

export default SecondLevelIndustries
