import { Modal } from 'components/common/modals/index.jsx'
import { t } from 'i18n'
import _ from 'lodash'
import Marty from 'marty'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import { Link } from 'react-router'
import Im from 'shared-js/immutable'
import BadgesState from 'state/badges'
import ChannelsState from 'state/channels'
import containerUtils from 'utilities/containers.js'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  badgeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 20px 10px 0px',
  },
  image: {
    height: 110,
    marginRight: 10,
  },
  createBadge: {
    height: 110,
    width: 110,
    margin: '10px 0',
    cursor: 'pointer',
    borderRadius: 3,
    border: '1px solid #eee',
    // backgroundColor: '#eee',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.3s all',
    ':hover': {
      backgroundColor: '#eee',
    },
  },
  addPlus: {
    fontSize: '5rem',
    color: '#999',
  },
  addText: {
    textAlign: 'center',
    marginTop: 10,
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 600,
    marginBottom: 4,
    marginRight: 10,
  },
  plansContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    maxHeight: 90,
    maxWidth: 400,
    overflowY: 'auto',
  },
  plan: {
    backgroundColor: '#eee',
    borderRadius: 100,
    margin: '0px 6px 4px 0px',
    padding: '2px 10px',
  },
}

class BadgeItem extends React.Component {
  static propTypes = {
    badge: PropTypes.object.isRequired,
    channel: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    trainingPlanTrainingUnits: PropTypes.instanceOf(Im.List).isRequired,
  }

  onArchiveClick = (e) => {
    e.stopPropagation()
    this.refs.archiveModal.show()
  }

  doArchive = () => {
    BadgesState.ActionCreators.update(this.props.badge.id, {
      badge_active: false,
    })
  }

  editBadge = (e) => {
    e.stopPropagation()
    this.refs.editModal.show()
  }

  render() {
    const activePlans = _.filter(this.props.badge.training_plans, (plan) => !plan.deactivated)

    return (
      <div style={styles.badgeContainer}>
        <Link
          style={styles.badge}
          to={`${this.props.location.pathname}badge-modal/${this.props.badge.id}/`}
        >
          <img src={this.props.badge.badge_image} style={styles.image} />
          <div>
            <div style={styles.titleContainer}>
              <div style={styles.title}>{this.props.badge.name}</div>
            </div>
            <div style={styles.plansContainer}>
              {activePlans.map((tp) => (
                <div style={styles.plan} key={tp.id}>
                  {tp.name}
                </div>
              ))}
            </div>
          </div>
        </Link>
        <div onClick={this.onArchiveClick}>
          <i className="ui icon remove" />
        </div>
        <Modal
          content={t('badge_archive_info')}
          onConfirm={this.doArchive}
          ref="archiveModal"
          basic
        />
      </div>
    )
  }
}

@Radium
class BadgesInner extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  static defaultProps = {
    badges: [],
  }

  showCreateBadgeModal = () => {
    if (_.size(this.props.trainingPlanTrainingUnits) > 0) {
      this.context.router.push(`${this.props.location.pathname}badge-modal/`)
    } else {
      this.refs.noTrainingPlansModal.show()
    }
  }

  render() {
    return (
      <div>
        {this.props.badges && this.props.trainingPlanTrainingUnits && (
          <div style={styles.container}>
            {this.props.badges.map((b) => (
              <BadgeItem
                key={b.id}
                location={this.props.location}
                badge={b}
                channel={this.props.channel}
                trainingPlanTrainingUnits={this.props.trainingPlanTrainingUnits}
                currentUser={this.props.currentUser}
              />
            ))}
            <div onClick={this.showCreateBadgeModal} style={styles.createBadge}>
              <div style={styles.addPlus}>+</div>
              <div style={styles.addText}>{t('add_badge')}</div>
            </div>
          </div>
        )}
        <Modal
          ref="noTrainingPlansModal"
          header={t('no_plans')}
          content={t('must_add_plans_to_channel_before_creating_badge')}
          message
          basic
        />
      </div>
    )
  }
}

export const Badges = Marty.createContainer(BadgesInner, {
  contextTypes: {
    routeParams: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  },
  listenTo: [BadgesState.Store, ChannelsState.Store],
  fetch: {
    badges() {
      return BadgesState.Store.getItems(
        {
          training_unit: this.props.channel.id,
          badge_active: true,
          limit: 0, // no limit
          fields: [
            'name',
            'description',
            'badge_image',
            'badge_active',
            'discount_code',
            'discount_url',
            'unique_codes',
            'used_unique_codes',
            'new_training_plans.name',
            'new_training_plans.url',
            'new_training_plans.deactivated',
          ],
        },
        {
          dependantOn: ChannelsState.Store,
        }
      )
    },
  },
  pending() {
    return containerUtils.defaultPending(this, BadgesInner)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, BadgesInner, errors)
  },
})
