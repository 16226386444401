import React from 'react'
import { Table, TableHeaderRowRenderer } from 'react-virtualized'
import InfiniteWindowScroller from './infinite-window-scroller'
import { getFetchableCount } from './common'

type WindowScrollerTableProps = {
  items: any[],
  totalCount: number,
  loadingSize: number,
  loadMoreData: (vars: any) => void,
  dataIsLoading: boolean,
  headerHeight: number,
  headerRowRenderer: TableHeaderRowRenderer,
  rowHeight: number,
  customStyling: React.CSSProperties,
  rowStyle: React.CSSProperties,
  rowGetter({ index }: { index: number }): any,
  children: (obj: { tableWidth: number }) => React.ReactNode,
  fetchMoreThreshold?: number,
}

export const WindowScrollerTable = (props: WindowScrollerTableProps) => (
  <InfiniteWindowScroller
    items={props.items}
    totalCount={props.totalCount}
    loadingSize={props.loadingSize}
    loadMoreData={props.loadMoreData}
    dataIsLoading={props.dataIsLoading}
    fetchMoreThreshold={props.fetchMoreThreshold}
  >
    {(infiniteWindowScrollerProps) => (
      <Table
        style={props.customStyling}
        autoHeight
        rowCount={getFetchableCount({
          itemsLength: props.items.length,
          totalCount: props.totalCount,
          loadingSize: props.loadingSize,
        })}
        headerHeight={props.headerHeight}
        headerRowRenderer={props.headerRowRenderer}
        rowHeight={props.rowHeight}
        rowStyle={props.rowStyle}
        rowGetter={props.rowGetter}
        {...infiniteWindowScrollerProps}
        {...props}
      >
        {/* we have to pull out the array of columns otherwise react-virtualized will error out
        because the Table component only accepts an array of Column components as its child */}
        {props.children({ tableWidth: infiniteWindowScrollerProps.width })}
      </Table>
    )}
  </InfiniteWindowScroller>
)
