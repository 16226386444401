import React from 'react'
import _ from 'lodash'
import gql from 'fraql'
import { graphql } from 'react-apollo'
import Dropzone from 'react-dropzone'
import { FETCH_REPORT } from './page'

const styles = {
  dropZone: {
    border: '2px dashed black',
    padding: 20,
    width: 300,
    height: 80,
  },
}

@graphql(gql`
  mutation CreateNetworkReport($companyId: ID!, $inputFile: Upload!) {
    NetworkReport__Create(input: { company: $companyId, inputFile: $inputFile }) {
      result {
        id
        status
        inputFile
        outputFile
        company {
          id
          name
          regionOfResponsibility
        }
      }
    }
  }
`)
export class CSVUploader extends React.PureComponent {
  onDrop = (file) => {
    const { companyId, mutate } = this.props
    mutate({
      variables: {
        companyId,
        inputFile: file,
      },
      refetchQueries: [
        {
          query: FETCH_REPORT,
          variables: {
            orderBy: '-id',
            companyId,
          },
        },
      ],
    })
  }

  render() {
    return (
      <Dropzone onDrop={(acceptedFiles) => this.onDrop(acceptedFiles[0])}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()} style={styles.dropZone}>
            <input {...getInputProps()} />
            <p>
              {isDragActive
                ? `Drop files here...`
                : `Drop your CSV file here, or click to select files to upload.`}
            </p>
          </div>
        )}
      </Dropzone>
    )
  }
}
