import { SubmitButton } from 'components/common/form-deprecated'
import { Image } from 'components/common/image'
import { ListItemCollection } from 'components/common/list-items'
import { t } from 'i18n'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import InvitationsState from 'state/invitations.js'
import $y from 'shared-js/utilities/yaler'
import { formatUrl } from '../../../common'

const styles = {
  container: {
    margin: '25px auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    cursor: 'pointer',
    position: 'relative',
    ':hover': {
      boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.3)',
    },
  },
  acceptButtonContainer: {
    position: 'absolute',
    right: 10,
  },
  acceptButton: {
    margin: 0,
    padding: '8px 25px',
    height: 'auto',
    width: 'auto',
  },
  logo: {
    height: 40,
    minWidth: 40,
    width: 'auto',
    float: 'left',
    marginRight: 20,
    backgroundPosition: 'left',
  },
}

@Radium
export class InviteListItem extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  static data = {
    inviteListItem: {
      required: true,
      fields: [
        'id',
        'invite_id',
        'expires',
        'invite_type',
        'company.company_logo',
        'company.name',
        'company.region_of_responsibility',
        'company.company_url',
      ],
    },
  }

  static propTypes = $y.propTypesFromData(InviteListItem, {
    acceptInvite: PropTypes.func,
    key: PropTypes.number,
  })

  acceptInvite = () => {
    this.setState({ loading: true })
    const invite = this.props.inviteListItem
    /*
     * TODO: HANDLE CLICK "processing" + success / error state
     * + ensure response fits INVITATION_RESPONSE requirements
     */

    InvitationsState.ActionCreators.doListAction('accept_invite', {
      invite_id: invite.invite_id,
      invite_type: invite.invite_type,
    }).then((res) => {
      window.location.href = '/views/home/'
    })
  }

  render() {
    const companyUrl = formatUrl(this.props.inviteListItem.company.company_url)
    return (
      <div style={styles.listItemContainer} onClick={this.acceptInvite}>
        <Image src={this.props.inviteListItem.company.company_logo} style={styles.logo} />
        <div style={{ verticalAlign: 'middle' }}>
          <div style={{ float: 'left' }}>
            {this.props.inviteListItem.company.name}
            <p style={{ fontStyle: 'italic', marginTop: 5 }}>
              {this.props.inviteListItem.company.region_of_responsibility}
              {' '}
              {companyUrl && ` - ${companyUrl}`}
            </p>
          </div>
        </div>
        <div style={styles.acceptButtonContainer}>
          <SubmitButton
            style={styles.acceptButton}
            formIsValid
            onClick={this.acceptInvite}
            loading={this.state.loading}
            text="Accept"
          />
        </div>
      </div>
    )
  }
}

class InviteList extends React.Component {
  static data = {
    invitations: {
      many: true,
      required: true,
      fields: $y.getFields(InviteListItem, 'inviteListItem'),
    },
  }

  static propTypes = $y.propTypesFromData(InviteList, {
    getEntities: PropTypes.array,
  })

  createListItem = (item) => <InviteListItem key={item.invite_id} inviteListItem={item} />

  render() {
    return (
      <div style={styles.container}>
        <div>{t('you_have_invites')}</div>
        <ListItemCollection
          style={{ width: '75%' }}
          createListItem={this.createListItem}
          entities={this.props.invitations}
        />
      </div>
    )
  }
}

export class InvitesPending extends React.Component {
  static data = {
    invitations: $y.getData(InviteList, 'invitations', {
      required: false,
    }),
  }

  static propTypes = $y.propTypesFromData(InvitesPending)

  render() {
    return this.props.invitations && this.props.invitations.length > 0 ? (
      <InviteList {...this.props} />
    ) : null
  }
}
