import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'immutable'

import Style from 'style'
import SubscriptionsState from 'state/subscriptions'
import CompaniesState from 'state/companies'

import containerUtils from 'utilities/containers'
import $y from 'shared-js/utilities/yaler'
import { LoadingSpinner } from 'components/common/loading'

import { SelectParentCompany, SelectParentCompanyInner } from './sections/parent-company'
import { SelectDistributors } from './sections/distributors'
import { EditSubscription } from './sections/subscription'
import { DeactivateCompany } from './sections/deactivate-company'
import { Company } from './sections/company'
import { BasicDetails } from './sections/basic-details'
import {Notifications} from "./sections/notifications";

const style = {
  panel: {
    backgroundColor: '#f6f6f6',
    padding: 20,
    marginTop: 20,
  },
}

class CompanyManagementPage extends React.Component {
  render() {
    return (
      <div>
        <div style={style.panel}>
          <BasicDetails company={this.props.company} />
        </div>
        <div style={style.panel}>
          <EditSubscription company={this.props.company} />
        </div>
        <div style={style.panel}>
          <Company company={this.props.company} />
        </div>
        <div style={style.panel}>
          <Notifications company={this.props.company} />
        </div>
        <div style={style.panel}>
          <SelectParentCompany company={this.props.company} />
        </div>
        <div style={style.panel}>
          <SelectDistributors company={this.props.company} />
        </div>
        <div style={style.panel}>
          <DeactivateCompany company={this.props.company} />
        </div>
      </div>
    )
  }
}

export const Page = Marty.createContainer(CompanyManagementPage, {
  contextTypes: {
    currentUser: PropTypes.instanceOf(Im.Map).isRequired,
  },

  listenTo: [CompaniesState.Store, SubscriptionsState.Store],

  fetch: {
    company() {
      const companyId = this.props.params.companyId
      if (companyId) {
        return CompaniesState.Store.getItem(
          companyId,
          {
            fields: [
              $y.getFields(BasicDetails, 'company'),
              $y.getFields(SelectDistributors, 'company'),
              $y.getFields(EditSubscription, 'subscription', 'subscription'),
              $y.getFields(SelectParentCompanyInner, 'company'),
              $y.getFields(Company, 'company'),
            ],
          },
          { dependantOn: SubscriptionsState.Store }
        )
      }
    },
  },

  pending() {
    return <LoadingSpinner />
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, CompanyManagementPage, errors)
  },
})
