import MUITable from '@material-ui/core/Table'
import MUITableBody from '@material-ui/core/TableBody'
import MUITableCell from '@material-ui/core/TableCell'
import MUITableFooter from '@material-ui/core/TableFooter'
import MUITableHead from '@material-ui/core/TablePagination'
import MUITablePagination from '@material-ui/core/TableRow'
import MUITableRow from '@material-ui/core/TableRow'
import MUITableSortLabel from '@material-ui/core/TableSortLabel'

export const Table = MUITable
export const TableBody = MUITableBody
export const TableCell = MUITableCell
export const TableFooter = MUITableFooter
export const TableHead = MUITableHead
export const TablePagination = MUITablePagination
export const TableRow = MUITableRow
export const TableSortLabel = MUITableSortLabel
