import React, { useContext, FunctionComponent } from 'react'
import { ManagedHistoryContext } from 'shared-js/context/context'
import { ManagedHistoryContextType, TrackedTopicsType } from 'shared-js/context/types'

type ManagedHistoryTrackerProps = {
  topics: Array<keyof TrackedTopicsType>
  type: string
  parameters: Array<string>
}

type TopicsToValuesPairer = (
  item: keyof TrackedTopicsType
) => [keyof TrackedTopicsType, { type: string; parameters: Array<string> }]

const allRelevantTopicsInStoreHaveCurrentValue = (
  relevantTopics: Array<keyof TrackedTopicsType>,
  value: { type: string; parameters: Array<string> },
  store: TrackedTopicsType
): boolean => {
  return relevantTopics
    .map((item) => {
      return (
        store[item].type === value.type &&
        store[item].parameters.every((element, index) => element === value.parameters[index])
      )
    })
    .every((item) => item)
}

export const ManagedHistoryTracker: FunctionComponent<ManagedHistoryTrackerProps> = ({
  topics,
  type,
  parameters,
  children,
}) => {
  const { trackedTopics, updateTopicsHistory } = useContext<ManagedHistoryContextType>(
    ManagedHistoryContext
  )
  const currentValue: { type: string; parameters: Array<string> } = { type, parameters }
  const updateRequired = !allRelevantTopicsInStoreHaveCurrentValue(
    topics,
    currentValue,
    trackedTopics
  )

  if (updateRequired) {
    const coupleTopicsAndValue: TopicsToValuesPairer = (item) => [item, currentValue]
    const historyUpdate = Object.assign(
      {},
      ...Array.from(topics.map(coupleTopicsAndValue), ([key, value]) => ({ [key]: value }))
    )
    const updatedTopics = {
      ...trackedTopics,
      ...historyUpdate,
    }
    updateTopicsHistory(updatedTopics)
  }
  return <>{children}</>
}
