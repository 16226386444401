import React from 'react'
import { t } from 'shared-js/i18n'

export const renderWelcomeMessage = (currentUser: any) => (
  // @ts-ignore
  <p style={styles.welcomeMsg}>
    {`${t('welcome', { name: currentUser && currentUser.first_name })}.`}
  </p>
)

const styles = {
  welcomeMsg: {
    marginBottom: 30,
    fontWeight: 300,
    fontSize: 30,
    maxWidth: 450,
    textAlign: 'center',
    lineHeight: 1,
  },
}
