import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { t } from 'i18n'
import { useQuery } from '@apollo/react-hooks'
import { useToastMessage, useCurrentUser } from 'shared-js/utilities/hooks'
import { Modal } from 'components/common/modals'
import {
  FieldHeader,
  Formik,
  getPropsFromFormik,
  SubmitButton,
  Validation,
} from 'shared-js/components/common/form'
import { TextInput, EmailInput, SlideToggleInput } from 'shared-js/components/common/inputs'
import { SearchableMultiSelect } from 'components/common/form-deprecated/select'
import gql from 'graphql-tag'
import { LoadingSpinner } from 'components/common/loading'
import UsersState from 'shared-js/state/users'
import EnrollmentGroupsState from 'state/enrollment-groups'
import { TeamAsyncSelect } from 'components/common/select'

const GET_USER_QUERY = gql`
  query GetUserToEdit($id: ID!, $skipGroups: Boolean!, $skipTeams: Boolean!) {
    user: User__Item(id: $id) {
      id
      firstName
      lastName
      email
      isActive
      learner {
        id
        isCompanyAdmin
        isLearnerGroupAdmin
      }
      company {
        id
        subscription {
          id
          userPermissionsEnabled
        }
      }
      groups: enrollmentGroups(orderBy: ["name"], first: 10) @skip(if: $skipGroups) {
        edges {
          node {
            id
            name
          }
        }
      }
      team @skip(if: $skipTeams) {
        id
        name
      }
    }

    EnrollmentGroup__List(orderBy: ["name"], first: 50) @skip(if: $skipGroups) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const EditUserModal = forwardRef((props, ref) => {
  const toast = useToastMessage()
  const modalRef = useRef()

  useImperativeHandle(ref, () => ({
    show: () => modalRef.current.show(),
    hide: () => modalRef.current.hide(),
  }))

  const variables = {
    id: props.user.id,
    skipGroups: props.hideGroupsField,
    skipTeams: props.hideTeamField,
  }
  const { data, loading, error, refetch } = useQuery(GET_USER_QUERY, { variables })

  if (data && data.user) data.user.groups = flattenList(data.user.groups)
  const availableGroups = data ? flattenList(data.EnrollmentGroup__List) : []

  const onSubmitAndValid = ({ groups, ...formData }) => {
    // TODO: Update the user graphene schema & mutation-serializer to can handle this.
    if (!data.user) return
    const userId = data.user.id

    const updates = []

    // User update
    updates.push(
      UsersState.ActionCreators.update(
        userId,
        {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          is_active: formData.isActive,
        },
        {
          fields: ['id', 'url'],
        }
      )
    )

    // Team update
    if (!props.hideTeamField) {
      updates.push(
        UsersState.ActionCreators.doDetailAction(userId, 'set_learner_group', {
          learner_group: formData.team ? formData.team.id : null,
          use_ids_not_urls: true,
        })
      )
    }

    // Enrolment Groups update
    if (!props.hideGroupsField) {
      // There is a bizarre issue where accessing data.user.groups causes an update which adds
      // an extra group to the form. Likely caused by apollo adding a getter method to the array
      // which performs some action. To prevent this we can just copy the array first.
      const prevGroups = [...data.user.groups].map((group) => group.id)
      const updateUserIsMemberOfGroup = (toAddOrRemove) => (groupId) =>
        updates.push(
          EnrollmentGroupsState.ActionCreators.doDetailAction(
            groupId,
            'update_members',
            { [toAddOrRemove]: [userId], use_ids_not_urls: true },
            { query: { fields: ['id', 'url'] }, updateOptimistically: false }
          )
        )
      _.difference(groups, prevGroups).forEach(updateUserIsMemberOfGroup('to_add'))
      _.difference(prevGroups, groups).forEach(updateUserIsMemberOfGroup('to_remove'))
    }

    // Team Manager update
    const updateUserIsTeamManager = (method) =>
      updates.push(
        UsersState.ActionCreators.doDetailAction(
          userId,
          method,
          { name: 'team_managers' },
          { query: { fields: ['*'] } }
        )
      )
    const wasTeamManager = data.user.learner.isLearnerGroupAdmin
    if (!wasTeamManager && formData.isTeamManager) updateUserIsTeamManager('add_to_group')
    if (wasTeamManager && !formData.isTeamManager) updateUserIsTeamManager('remove_from_group')

    modalRef.current.hide()
    Promise.all(updates)
      .then(() => {
        toast.showPositive(t('changes_saved'))
        if (props.onFinishUpdate) props.onFinishUpdate()
      })
      .catch((err) => {
        toast.showNegative(t('error'), t('please_try_again'))
        console.error('Error updating the user:', err)
      })
      .finally(() => {
        UsersState.ActionCreators.resetLocalData()
        refetch()
      })
  }
  const user = data && data.user ? data.user : undefined
  return (
    <Modal
      ref={modalRef}
      header={t('edit_details_for_user', {
        firstName: user ? user.firstName : props.user.first_name,
      })}
    >
      <div className="content">
        {loading || error ? (
          <LoadingSpinner />
        ) : (
          <EditUserForm
            user={user}
            teamIsEditable={!props.hideTeamField}
            groups={availableGroups}
            groupsIsEditable={!props.hideGroupsField}
            onSubmit={onSubmitAndValid}
          />
        )}
      </div>
    </Modal>
  )
})

EditUserModal.propTypes = {
  user: PropTypes.object.isRequired,
  hideTeamField: PropTypes.bool,
  hideGroupsField: PropTypes.bool,
  onFinishUpdate: PropTypes.func,
}
EditUserModal.defaultProps = {
  hideTeamField: false,
  hideGroupsField: false,
}

EditUserModal.data = {
  user: {
    required: false,
    fields: ['first_name'],
  },
  teams: {
    required: true,
    many: true,
    fields: ['name', 'id', 'url'],
  },
}

function EditUserForm(props) {
  const currentUser = useCurrentUser()

  const canEditEmail =
    props.user.company &&
    props.user.company.subscription &&
    props.user.company.subscription.userPermissionsEnabled &&
    currentUser &&
    currentUser.learner.isCompanyAdmin

  const initialTeamSelection = props.user.team ? props.user.team : null

  const groupOptions = (props.groups || []).map((group) => ({
    value: group.id,
    label: group.name,
  }))
  const initialGroupSelections = (props.user.groups || []).map((group) => group.id)

  return (
    <Formik
      initialValues={{
        firstName: props.user ? props.user.firstName : '',
        lastName: props.user ? props.user.lastName : '',
        email: props.user ? props.user.email : '',
        team: initialTeamSelection,
        groups: initialGroupSelections,
        isActive: props.user ? props.user.isActive : false,
        isTeamManager: props.user ? props.user.learner.isLearnerGroupAdmin : false,
      }}
      enableReinitialize
      validationSchema={Validation.createSchema({
        firstName: Validation.text({ required: true }),
        lastName: Validation.text({ required: true }),
        email: Validation.email({ required: true }),
        isActive: Validation.boolean({ required: true }),
        isTeamManager: Validation.boolean({ required: true }),
        groups: Validation.array({ required: false }),
      })}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => (
        <div>
          <FieldHeader>{t('first_name')}</FieldHeader>
          <TextInput {...getPropsFromFormik(formikProps, 'firstName')} />

          <FieldHeader required>{t('last_name')}</FieldHeader>
          <TextInput {...getPropsFromFormik(formikProps, 'lastName')} />

          {canEditEmail && (
            <React.Fragment>
              <FieldHeader required>{t('email')}</FieldHeader>
              <EmailInput
                {...getPropsFromFormik(formikProps, 'email')}
                className="fs-sensitive"
                autoComplete="no-autofill"
              />
            </React.Fragment>
          )}

          {props.teamIsEditable && (
            <React.Fragment>
              <FieldHeader required>{t('team')}</FieldHeader>
              <div style={styles.asyncSearchContainer}>
                <TeamAsyncSelect
                  team={initialTeamSelection}
                  {...getPropsFromFormik(formikProps, 'team')}
                />
              </div>
            </React.Fragment>
          )}

          {props.groupsIsEditable && (
            <React.Fragment>
              <FieldHeader>{t('groups')}</FieldHeader>
              <SearchableMultiSelect
                options={groupOptions}
                initialSelections={initialGroupSelections}
                fluid
                style={styles.dropdownField}
                {...getPropsFromFormik(formikProps, 'groups')}
              />
            </React.Fragment>
          )}

          <div style={styles.togglesContainer}>
            {currentUser.learner.isCompanyAdmin && (
              <div style={styles.toggleItem}>
                <FieldHeader>{t('active')}</FieldHeader>
                <SlideToggleInput {...getPropsFromFormik(formikProps, 'isActive')} />
              </div>
            )}

            {(currentUser.learner.isCompanyAdmin || !props.user.learner.isLearnerGroupAdmin) && (
              <div style={styles.toggleItem}>
                <FieldHeader>{t('team_manager')}</FieldHeader>
                <SlideToggleInput {...getPropsFromFormik(formikProps, 'isTeamManager')} />
              </div>
            )}
          </div>

          <SubmitButton
            loading={props.updating}
            isValid={formikProps.isValid}
            onPress={formikProps.handleSubmit}
          >
            {t('save_user')}
          </SubmitButton>
        </div>
      )}
    </Formik>
  )
}

EditUserForm.defaultProps = {
  user: {},
}

const styles = {
  editIcon: {
    float: 'right',
  },
  asyncSearchContainer: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  togglesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: '10px 0',
  },
  toggleItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
    marginRight: 15,
  },
  dropdownField: {
    container: {
      marginTop: 10,
      marginBottom: 20,
    },
  },
}

function flattenList(graphList) {
  if (!graphList || !graphList.edges) return graphList
  return graphList.edges.map((item) => {
    if (!item || !item.node) return item
    return item.node
  })
}
