import React from 'react'
import { t } from 'i18n'
import {
  RegionDropdownSelect,
  RegionMultiSelect,
} from 'components/common/form-deprecated/select/region-select'
import { GENERAL_REGIONS } from 'components/common/form-deprecated/select/regions'
import { DescriptionBox } from '../../common'
import { MultiRegionAsyncSelect } from '../../../common/select'

const styles = {
  actionCol: {
    paddingTop: 50,
    paddingLeft: 50,
  },
}

export class CompanyRegionFields extends React.Component {
  render() {
    const company = this.props.company
    const officeLocation = company.office_location

    return (
      <div>
        <div className="ui grid stackable">
          <div className="column nine wide">
            <DescriptionBox title={t('office_location')} info={t('country_info_office')} />
          </div>

          <div className="column seven wide" style={styles.actionCol}>
            <div style={{ maxWidth: 400 }}>
              <RegionDropdownSelect
                name="office_location"
                exclude={GENERAL_REGIONS}
                required
                style={styles.dropdown}
                initialSelection={officeLocation}
                onChange={(val) => this.props.onOfficeLocationChange(val)}
              />
            </div>
          </div>
        </div>

        <div className="ui grid stackable">
          <div className="column nine wide">
            <DescriptionBox title={t('regions_of_responsibility')} info={t('regions_info')} />
          </div>

          <div className="column seven wide" style={styles.actionCol}>
            <div style={{ maxWidth: 400 }}>
              <MultiRegionAsyncSelect company={company} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
