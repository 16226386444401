import { PrimaryButton } from 'components/common/buttons'
import { FieldHeader } from 'components/common/form-deprecated'
import { LoadingSpinner } from 'components/common/loading'
import { t } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import ChannelsState from 'state/channels'
import { CompanySelect } from '../company-select'
import { PlansSelector } from '../plans-selector'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    margin: '0px 0px 20px',
  },
  submitBtn: {
    marginLeft: 0,
    marginTop: 5,
  },
}

@Radium
export class CopyChannelForm extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    displayTempPositiveMessage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      newSelectedPlanIds: null,
      selectedCompanyIds: [],
    }
  }

  onSubmit = () => {
    const planIds = this.state.newSelectedPlanIds
    const copyPromises = this.state.selectedCompanyIds.map((coId) => {
      const params = {
        company_id: coId,
      }
      if (planIds) params.plan_ids = planIds
      return ChannelsState.ActionCreators.doDetailAction(this.props.channel.id, 'copy_channel', {
        company_id: coId,
        plan_ids: planIds,
      })
    })
    Promise.all(copyPromises)
    this.context.displayTempPositiveMessage({
      heading: t('copy_started'),
    })
    this.props.onSubmit()
  }

  onSelectionChange = (plansSelection, plans) => {
    const allPlanIds = plans.map((plan) => plan.id)
    const newDeselectedPlanIds = Object.keys(plansSelection.newDeselected).map((key) => parseInt(key))
    const newSelectedPlanIds = allPlanIds.filter((id) => !newDeselectedPlanIds.includes(id))
    this.setState({
      newSelectedPlanIds,
    })
  }

  onCompanySelectChange = (companies) => {
    const selectedCompanyIds = Object.keys(companies)
    this.setState({
      selectedCompanyIds,
    })
  }

  render() {
    return (
      <div className="content" style={styles.container}>
        <div style={styles.section}>
          <FieldHeader
            required
            headerStyle={styles.title}
            explanation={t('select_copy_to_children_explanation')}
          >
            {t('companies')}
          </FieldHeader>
          <CompanySelect
            ref={(c) => (this.companySelect = c)}
            privateCompanies
            onChange={this.onCompanySelectChange}
          />
        </div>
        <FieldHeader
          required
          headerStyle={styles.title}
          explanation={t('select_plans_explanation')}
        >
          {t('plans')}
        </FieldHeader>
        <PlansSelector
          channelId={this.props.channel.id}
          onSelectionChange={this.onSelectionChange}
        />
        {this.state.saving ? (
          <LoadingSpinner />
        ) : (
          <PrimaryButton
            style={styles.submitBtn}
            onClick={this.onSubmit}
            disabled={!this.state.selectedCompanyIds.length}
          >
            {t('submit')}
          </PrimaryButton>
        )}
      </div>
    )
  }
}
