import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import gql from 'graphql-tag'
import {
  RelevantIndustryTagsQuery as Query,
  RelevantIndustryTagsQueryVariables as Variables,
  RelevantIndustryTagsQuery,
} from './gql-types/RelevantIndustryTagsQuery'

export const LOADING_SIZE = 15
export const EMPTY_SEARCH_INPUT = ''

type ApolloPropsType = {
  loading: boolean
  data: RelevantIndustryTagsQuery | undefined
  error?: any
  [key: string]: any
}

type IndustriesContextValues = {
  apolloProps: ApolloPropsType
  searchTerm: string
  setSearchTerm(term: string): void
}

export const RELEVANT_INDUSTRY_TAGS = gql`
  query RelevantIndustryTagsQuery(
    $cursor: String
    $loadingSize: Int!
    $companyID: ID!
    $searchTerm: String
    $orderBy: [String]
  ) {
    Tag__List(
      networkTags: $companyID
      category: "general"
      search: $searchTerm
      first: $loadingSize
      orderBy: $orderBy
      after: $cursor
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
      totalCount
    }
  }
`

const IndustriesContext = React.createContext<IndustriesContextValues>({
  apolloProps: { loading: true, data: undefined },
  searchTerm: '',
  setSearchTerm: () => {},
})

export const IndustriesProvider = ({ children }: { children: React.ReactElement }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const currentUser = useCurrentUser()
  const queryVariables = {
    loadingSize: LOADING_SIZE,
    companyID: currentUser.company.id,
    searchTerm,
    orderBy: searchTerm === EMPTY_SEARCH_INPUT ? ['name'] : ['-search_rank'],
  }
  const apolloProps = useQuery<Query, Variables>(RELEVANT_INDUSTRY_TAGS, {
    variables: queryVariables,
  })

  return (
    <IndustriesContext.Provider
      value={{
        apolloProps,
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </IndustriesContext.Provider>
  )
}

export const useIndustries = () => useContext(IndustriesContext)
