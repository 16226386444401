import Marty from 'marty'
import React from 'react'
import Radium from 'radium'
import _ from 'lodash'
import Style from 'style'
import { t, getCurrentLocale } from 'i18n'

import { Form, TextInput, SubmitButton } from 'components/common/form-deprecated'
import { Modal } from 'components/common/modals'
import { HoverableLink } from 'components/common/hover'

const styles = {
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    [Style.vars.media.mobile]: {
      padding: '1rem',
    },
  },
  input: {
    marginTop: 10,
    background: 'none',
    borderStyle: 'none',
    color: Style.vars.deprecatedColors.xxxDarkGrey,
  },
  errorMsg: {
    color: Style.vars.deprecatedColors.errorRed,
  },
  hr: {
    marginTop: '-20px',
    marginBottom: 20,
    border: `1px solid ${Style.vars.deprecatedColors.mediumGrey}`,
  },
  submitButton: {
    background: Style.vars.deprecatedColors.primary,
    borderRadius: 0,
    width: '100%',
    margin: 0,
    color: Style.vars.deprecatedColors.white,
    // purposely erroring out padding as padding: 0 does not work and component
    // has default padding
    padding: 'auto',
    container: {
      width: '50%',
      margin: 0,
      borderRadius: 0,
      height: '2.5em',
      display: 'inline-block',
      [Style.vars.media.mobile]: {
        width: '80%',
      },
    },
  },
  submitButtonJoinLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submitButtonInvalid: {
    cursor: 'not-allowed',
  },
  joinLaterLink: {
    cursor: 'pointer',
    color: Style.vars.deprecatedColors.textBlack,
    marginTop: 20,
  },
  linkHover: {
    color: Style.vars.deprecatedColors.primary,
  },
}

@Radium
export class CreateTeamModal extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  onSubmitAndValid = (data) => {
    this.setState({ loading: true }, () => {
      this.props.onSubmit(data)
    })
  }

  render() {
    return (
      <Modal
        ref={(x) => (this.createTeamModal = x)}
        header={this.props.header ? this.props.header : t('add_team')}
      >
        <div style={styles.modalContent} className={this.props.className}>
          <Form
            onSubmitAndValid={this.onSubmitAndValid}
            className={this.props.className}
            ref={(x) => (this.createTeamForm = x)}
          >
            <TextInput
              className={this.props.className}
              name="name"
              placeholder={this.props.placeholder ? this.props.placeholder : t('please_input_team')}
              placeholderColor="faded"
              style={styles.input}
              onChange={this.onInputChange}
              errorMsgStyle={styles.errorMsg}
              required
            />
            <hr style={styles.hr} />
            <div style={styles.submitButtonJoinLinkContainer}>
              <SubmitButton
                style={styles.submitButton}
                invalidStyle={styles.submitButtonInvalid}
                text={t('add')}
                loading={this.props.loading ? this.props.loading : this.state.loading}
                className="btn"
              />
            </div>
            {this.props.exitCreate ? (
              <HoverableLink
                style={styles.joinLaterLink}
                hoverStyle={styles.linkHover}
                onClick={this.props.exitCreate}
              >
                <span style={{ textDecoration: 'underline' }}>{t('join_a_team_later')}</span>
                <i className="angle right icon" />
              </HoverableLink>
            ) : null}
          </Form>
        </div>
      </Modal>
    )
  }
}
