import React from 'react'
import Radium from 'radium'

import { SecondaryButton } from 'components/common/buttons'

import { Info } from 'components/common/info'
import { t } from 'i18n'

export { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'

const styles = {
  infoBtn: {
    marginRight: -2,
    marginLeft: 2,
    color: '#666',
  },
  secondaryBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltip: { tooltip: { left: -50, width: 200 } },
}

@Radium
export class ReorderButton extends React.Component {
  render() {
    return (
      <div style={this.props.containerStyle}>
        <SecondaryButton onClick={this.props.toggleReorder} style={styles.secondaryBtn}>
          {this.props.reorderEnabled ? t('finish_reorder') : t('reorder')}
          <Info
            content={t('reorder_desc', { entity: t(this.props.entity) })}
            style={styles.infoBtn}
            position="bottom"
            tooltipStyles={styles.tooltip}
          />
        </SecondaryButton>
      </div>
    )
  }
}

export const orderingStyles = {
  highlight: {
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 10px 25px',
    cursor: 'move',
    borderRadius: 6,
    transform: 'scale(1.03)',
  },
  moveable: {
    cursor: 'move',
  },
}
