import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import Style from 'style'
import { t } from 'i18n'

import messagesImg from 'img/messages.png'

const styles = {
  sliderContainer: {
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    textAlign: 'center',
    width: '100%',
    paddingBottom: 30,
    [Style.vars.media.mobile]: {
      marginTop: 0,
    },
  },
  infoImg: {
    height: 110,
    backgroundColor: 'none',
    margin: '0 auto 25px auto',
    [Style.vars.media.mobile]: {
      marginTop: '100px !important',
    },
  },
  content: {
    fontSize: 15,
    lineHeight: 1.5,
    textAlign: 'left',
  },
  title: {
    color: 'rgba(230, 131, 68, 1)',
    marginLeft: -20,
  },
  descText: {
    color: '#637280',
  },
  listItem: {
    marginBottom: 20,
    color: 'rgba(230, 131, 68, 1)',
  },
}

@Radium
export class SignUpInfo extends React.Component {
  render() {
    return (
      <div style={styles.sliderContainer}>
        <img src={messagesImg} style={styles.infoImg} />
        <ul style={styles.content}>
          <span style={styles.title}>{t('with_myagi_you_can')}</span>
          <li style={{ ...styles.listItem, marginTop: 20 }}>
            <span style={styles.descText}>{t('educate_your_team')}</span>
          </li>
          <li style={styles.listItem}>
            <span style={styles.descText}>{t('connect_to_key_partners')}</span>
          </li>
          <li style={styles.listItem}>
            <span style={styles.descText}>{t('keep_your_whole_team')}</span>
          </li>
        </ul>
      </div>
    )
  }
}
