import Marty from 'marty'
import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import Im from 'shared-js/immutable'
import _ from 'lodash'
import moment from 'moment-timezone'
import { resolve } from 'utilities/deprecated-named-routes'
import { Transition, TransitionGroup } from 'react-transition-group'

import Style from 'style'
import containerUtils from 'utilities/containers'
import $y from 'utilities/yaler'
import { t } from 'i18n'
import { PrimaryButton } from 'components/common/buttons'
import { EntitySelect } from 'components/common/card-searchable-select/entity-select'

import ModulesState from 'state/modules'
import createPaginatedStateContainer from 'state/pagination'
import PageState from '../page-state'

const WIDTH = 220
const IMG_HEIGHT = (9 / 16) * WIDTH // ratio should be 16:9 with width

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: WIDTH,
    margin: 16,
  },
  img: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#111',
    height: IMG_HEIGHT,
    cursor: 'pointer',
    ':hover': {},
  },
  tickContainer: {
    width: WIDTH,
    height: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tick: {
    top: 64,
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: Style.vars.deprecatedColors.primaryFontColor,
    zIndex: '3',
    backgroundColor: Style.vars.deprecatedColors.primary,
    transition: 'all .2s ease-in-out',
  },
  whiteTick: {
    color: Style.vars.deprecatedColors.primary,
    backgroundColor: 'white',
    border: `2px solid ${Style.vars.deprecatedColors.primary}`,
  },
  name: {
    fontSize: '1.2rem',
  },
  lastUpdated: {
    fontSize: '12px',
    margin: '8px 0',
    color: Style.vars.deprecatedColors.xxDarkGrey,
  },
}

const transitionStyles = {
  entering: {
    transform: 'scale(0.7)',
    opacity: 0,
  },
  entered: {
    transform: 'scale(1)',
    opacity: 1,
  },
  exiting: {
    transform: 'scale(0.7)',
    opacity: 0,
  },
}

@Radium
export class ModuleCard extends React.Component {
  static data = {
    module: {
      required: true,
      fields: [
        'name',
        'id',
        'thumbnail_url',
        'training_plans',
        'description',
        'url',
        'deactivated',
        'search_rank',
        'modified',
      ],
    },
  }

  onCardClick = () => {
    this.props.onCardClick(this.props.entity)
  }

  render() {
    let selected = false
    if (this.props.selectedEntities) {
      selected = _.includes(this.props.selectedEntities, this.props.entity.url)
    }

    return (
      <div style={styles.container}>
        <div
          onClick={this.onCardClick}
          ref="img"
          className="thumbnail"
          style={{
            ...styles.img,
            backgroundImage: `url(${this.props.entity.thumbnail_url})`,
          }}
        >
          <div key="play" style={styles.tickContainer}>
            <TransitionGroup>
              {Radium.getState(this.state, 'img', ':hover') && (
                <Transition timeout={{ enter: 0, exit: 200 }}>
                  {(state) => (
                    <i
                      style={{
                        ...styles.tick,
                        ...styles.whiteTick,
                        ...transitionStyles[state],
                      }}
                      className="ui icon checkmark"
                    />
                  )}
                </Transition>
              )}
            </TransitionGroup>
          </div>

          <div key="select" style={styles.tickContainer}>
            <TransitionGroup>
              {selected && (
                <Transition timeout={{ enter: 0, exit: 200 }}>
                  {(state) => (
                    <i
                      transitionStyles={transitionStyles}
                      style={{ ...styles.tick, ...transitionStyles[state] }}
                      className="ui icon checkmark"
                    />
                  )}
                </Transition>
              )}
            </TransitionGroup>
          </div>
        </div>
        <div style={styles.lastUpdated}>
          {`${t('last_updated')}: ${moment(this.props.entity.modified).format('DD MMM YYYY')}`}
        </div>
        <div onClick={this.attemptModule} style={styles.name}>
          {this.props.entity.name}
        </div>
      </div>
    )
  }
}

export const ModuleCardSelect = createPaginatedStateContainer(EntitySelect, {
  listenTo: [ModulesState.Store, PageState.Store],

  contextTypes: {
    router: PropTypes.object.isRequired,
  },

  propTypes: {
    currentUser: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      innerComponent: null,
    }
  },

  isValid() {
    const ic = PageState.Store.getEntitySelectComponent()
    if (!ic) return true
    return ic.isValid()
  },

  getSelectedEntitiesArray() {
    return PageState.Store.getSelectedEntities()
  },

  getNameAndValue() {
    const ic = PageState.Store.getEntitySelectComponent()
    return ic.getNameAndValue()
  },

  onPrimaryButtonClick(data) {
    // Hide lesson select modal, otherwise an overlay will be present.
    if (this.props.hide) this.props.hide()
    this.context.router.push(
      resolve('create-module', {
        currentUser: this.props.currentUser,
      })
    )
  },

  createPrimaryButton() {
    // If showCreateLesson is true, there will be a button to optionally
    // create a new lesson instead of selecting an existing one. This elliminates
    // the need for a select existing / create new dropdown option. Used in
    // content management views.
    if (!this.props.showCreateLesson) return null
    return (
      <PrimaryButton onClick={this.onPrimaryButtonClick} style={{}}>
        Create new lesson
      </PrimaryButton>
    )
  },

  paginate: {
    store: ModulesState.Store,
    propName: 'entities',
    limit: 12,
    getQuery() {
      let query = {
        ordering: 'name,-modified',
        deactivated__isnull: true,
        fields: $y.getFields(ModuleCard, 'module'),
      }
      const search = PageState.Store.getEntitySearch()
      if (search) {
        query.search = search
        query.ordering = '-search_rank'
      }
      if (!this.props.currentUser.learner.can_view_all_training_content) {
        // if learner cannot view all training content (setting on comany, or
        // user is admin of any type), then limit module search to modules they
        // can attempt.
        query = _.extend({ viewable_by_user: this.props.currentUser.id }, query)
      }
      if (this.props.fetchOpts) {
        _.extend(query, this.props.fetchOpts)
      }
      return query
    },
  },
  done(results) {
    return (
      <EntitySelect
        registerComponent={this.registerComponent}
        {...this.props}
        {...results}
        entityName="lesson"
        cardComponent={ModuleCard}
        createPrimaryButton={this.createPrimaryButton}
      />
    )
  },
  pending() {
    return containerUtils.defaultPending(this, EntitySelect)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, EntitySelect, errors)
  },
})
