import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { variant } from '@xstyled/system'
import MUIButton, { ButtonProps } from '@material-ui/core/Button'
import MUIIconButton from '@material-ui/core/IconButton'
import { CircularProgress, makeStyles, Theme } from '@material-ui/core'

const sizeVariants = variant({
  default: 'medium',
  prop: 'size',
  variants: {
    medium: css`
      font-size: 14px !important;
      padding: 0px 24px 0px 24px !important;
      height: 40px !important;
      min-width: 40px !important;
      border-radius: 20px !important;
    `,
    small: css`
      font-size: 14px !important;
      padding: 0px 16px 0px 16px !important;
      height: 32px !important;
      min-width: 32px !important;
      border-radius: 26px !important;
    `,
    large: css`
      font-size: 16px !important;
      padding: 0px 20px 0px 20px !important;
      height: 48px !important;
      min-width: 48px !important;
      border-radius: 26px !important;
    `,
  },
})

const iconVariants = variant({
  default: 'false',
  prop: 'iconOnly',
  variants: {
    true: css`
      padding: 0px !important;
    `,
    false: css``,
  },
})

const loadingClasses = makeStyles(() => ({
  wrapper: {
    margin: 1,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  spanHidden: {
    opacity: 0.0,
  },
  spanUnHidden: {
    opacity: 1.0,
  },
}))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledButton = styled(({ iconOnly, ...props }) => <MUIButton {...props} />)`
  text-transform: none !important;
  ${sizeVariants}
  ${iconVariants}
`
/**
 * CustomButtonProps
 * This adds `isLoading` behavior to the button state
 * as well as an iconOnly state to make the buttons circular
 */
type BaseButtonProps = ButtonProps & {
  isLoading?: boolean
  iconOnly?: boolean
  theme?: Theme
}

/**
 * We are omitting, `variant` and `color`
 * this is to make sure that when we want to make
 * a button there is a strong use case and we properly think
 * about an implementation and make it widely avaliable to all
 */
export type CustomButtonProps = Omit<BaseButtonProps, 'variant' | 'color'>

/**
 * Icon Sizes in pixels as per the designs
 */
const iconSizes = {
  large: 24,
  medium: 16,
  small: 16,
}

/**
 * This button adds the ability to add a loading state to a button
 * This is not built into Material UI so we've had to extend it
 * To use simply set the `isLoading` prop to a boolean
 */
const ButtonBase = ({ isLoading, ...props }: BaseButtonProps) => {
  const classes = loadingClasses()
  const progressColor = props.variant === 'contained' ? 'white' : props?.theme?.palette.primary.main
  const size: number = iconSizes[props.size ?? 'medium']
  const spanClass = React.useMemo(() => (isLoading ? classes.spanHidden : classes.spanUnHidden), [
    classes.spanHidden,
    classes.spanUnHidden,
    isLoading,
  ])

  return (
    <StyledButton {...props}>
      <span className={spanClass} style={{ lineHeight: 0 }}>
        {props.children}
      </span>
      {isLoading && (
        <CircularProgress
          size={size}
          className={classes.buttonProgress}
          style={{ color: progressColor, marginTop: -(size / 2), marginLeft: -(size / 2) }}
        />
      )}
    </StyledButton>
  )
}

export const Button = (props: CustomButtonProps) => {
  return <ButtonBase {...props} variant="contained" color="primary" />
}

export const OutlinedButton = (props: CustomButtonProps) => {
  return <ButtonBase {...props} variant="outlined" color="primary" />
}

export const TextButton = (props: CustomButtonProps) => {
  return <ButtonBase {...props} variant="text" color="primary" />
}

export const DangerButton = (props: CustomButtonProps) => {
  return <ButtonBase {...props} variant="contained" color="secondary" />
}

export const IconButton = MUIIconButton
