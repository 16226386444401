import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { LoadingSpinner } from 'components/common/loading'
import gql from 'graphql-tag'
import Style from 'shared-js/style'
import Icon from 'react-native-vector-icons/MaterialIcons'
import styled, { css } from 'styled-components'
import { t } from 'shared-js/i18n'
import {
  ExternalPlansForChannelQuery_PublicTrainingPlanTrainingUnit__List_edges as TrainingPlanTrainingUnit_edge,
  ExternalPlansForChannelQuery as Query,
  ExternalPlansForChannelQueryVariables as Variables,
  ExternalPlansForChannelQuery_PublicTrainingPlanTrainingUnit__List_edges_node_trainingPlan as TrainingPlan,
} from './gql-types/ExternalPlansForChannelQuery'

const LOADING_SIZE = 100

type PlansListProps = {
  channelID: string
}

type PlanImageProps = {
  imageSrc: string | null
}

const EXTERNAL_PLANS_FOR_CHANNEL = gql`
  query ExternalPlansForChannelQuery($channelID: ID!, $cursor: String, $loadingSize: Int!) {
    PublicTrainingPlanTrainingUnit__List(
      after: $cursor
      first: $loadingSize
      trainingUnit: $channelID
      trainingPlanDeactivated: false
      trainingPlanIsPublished: true
      trainingPlanHasContent: true
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          trainingPlan {
            id
            name
            thumbnailUrl
            numAttemptableLessons
          }
        }
      }
      totalCount
    }
  }
`

const PlansList = ({ channelID }: PlansListProps) => {
  const queryVariables = {
    loadingSize: LOADING_SIZE,
    channelID,
  }

  const apolloProps = useQuery<Query, Variables>(EXTERNAL_PLANS_FOR_CHANNEL, {
    variables: queryVariables,
  })

  if (apolloProps.error) return null

  if (apolloProps.data) {
    return (
      <Container>
        <PlanListContainer>
          {apolloProps.data?.PublicTrainingPlanTrainingUnit__List?.edges.map(
            (trainingPlanTrainingUnit: TrainingPlanTrainingUnit_edge | null) =>
              trainingPlanTrainingUnit &&
              trainingPlanTrainingUnit.node?.trainingPlan && (
                <Plan
                  plan={trainingPlanTrainingUnit.node?.trainingPlan}
                  key={trainingPlanTrainingUnit.node?.id}
                />
              )
          )}
        </PlanListContainer>
      </Container>
    )
  }

  if (apolloProps.loading) return <LoadingSpinner />

  return null
}

const Plan = ({ plan }: { plan: TrainingPlan }) => {
  const [coverImage, setCoverImage] = useState(plan.thumbnailUrl)

  const setSrcToNull = () => {
    setCoverImage(null)
  }

  return (
    <PlanCard>
      {coverImage ? (
        <PlanImage src={coverImage || ''} onError={setSrcToNull} />
      ) : (
        <PlaceholderPlanImageContainer>
          <PlaceholderPlanImageInner>
            <StyledPlanIcon />
          </PlaceholderPlanImageInner>
        </PlaceholderPlanImageContainer>
      )}
      <PlanInfoContainer>
        <PlanName>{plan.name}</PlanName>
        <LessonsCount>
          {`${plan.numAttemptableLessons} ${t(
            'lessons',
            {},
            {
              pluralize: plan.numAttemptableLessons,
            }
          )}`}
        </LessonsCount>
      </PlanInfoContainer>
    </PlanCard>
  )
}

const Container = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;
  max-height: 50vh;
  overflow-y: auto;
`

const PlanListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: minmax(0, 1fr);
  }
`

const PlanCard = styled.div`
  border-radius: 4px;
  border: solid 1px ${Style.colors.grey400};
  padding: 16px;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`

const planImageMixin = css`
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 4px;
  margin-right: 16px;
`

const PlanImage = styled.img`
  ${planImageMixin}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const PlaceholderPlanImageContainer = styled.div`
  ${planImageMixin}
  background-color: ${Style.colors.indigo700};
  display: flex;
  align-items: center;
  justify-content: center;
`

const PlaceholderPlanImageInner = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: ${Style.colors.indigo600};
  display: flex;
  align-items: center;
  justify-content: center;
`

const PlanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const PlanName = styled.div`
  color: ${Style.colors.grey900};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const LessonsCount = styled.div`
  font-size: 12px;
  margin-top: 8px;
`

const StyledPlanIcon = styled(Icon).attrs({ name: 'list', size: 20 })`
  color: ${Style.colors.white};
`

export default PlansList
