import LDClient from 'ldclient-js'
import Marty from 'marty'
import { browserHistory } from 'react-router'
import { getCSRFToken } from 'utilities/http'
import * as Sentry from '@sentry/react'
import conf from './config'
import app from './application'

export const sentry = Sentry

export function getAPIBaseURL() {
  const base = process.env.API_BASE_URL || ''
  return `${base}/api/v1/`
}

export function getMarty() {
  return Marty
}

export function getHistory() {
  return browserHistory
}

export function getApplication() {
  return app
}

export function getWriteAuthHeader() {
  const token = getCSRFToken()
  return {
    'X-CSRFToken': token,
    CSRFToken: token,
  }
}

export function getReadAuthHeader() {
  return null
}

export const makeEntitiesFullyImmutable = false

// Intercom will instead be registered by Segment
export const intercom = null

export function getAnalytics() {
  // This must be a function which is called as needed, because window.analytics can actually change
  // as integrations are loaded.
  return window.analytics
}

export function getDataLayer() {
  // This must be a function which is called as needed, because window.dataLayer can actually change
  // as integrations are loaded.
  return window.dataLayer
}

export const platform = 'web'

export function setupLaunchDarkly(data) {
  return LDClient.initialize(conf.LAUNCH_DARKLY_KEY, {
    key: data.id,
    email: data.email,
    custom: {
      is_internal_user: data.is_internal_user,
      is_staff: data.is_staff,
      company_id: data.company ? data.company.id : null,
    },
  })
}
