import React, { useState } from 'react'
import styled from 'styled-components'
import FirstLevelIndustries from './first-level-industries'
import SecondLevelIndustries from './second-level-industries'
import ThirdLevelIndustries from './third-level-industries'

type IndustryStepProps = {
  setIndustries(val: string[]): void
  resetForm(): void
}

const IndustryStep = ({ setIndustries, resetForm }: IndustryStepProps) => {
  const [firstLevelIndustries, setFirstLevelIndustries] = useState<string[]>()
  const [secondLevelIndustries, setSecondLevelIndustries] = useState<string[]>()

  const setSelectedIndustries = (thirdLevelIndustries?: string[]) => {
    if (firstLevelIndustries) {
      const industries = firstLevelIndustries
      // a quick fix because there's a few places that depends on firstLevelIndustries and
      // secondLevelIndustries being undefined
      if (secondLevelIndustries) {
        industries.concat(secondLevelIndustries)
      }
      if (thirdLevelIndustries) {
        industries.concat(thirdLevelIndustries)
      }
      setIndustries(industries)
    }
  }

  if (secondLevelIndustries) {
    return (
      <Container>
        <ThirdLevelIndustries
          setIndustries={setSelectedIndustries}
          setSelectedIndustries={setSelectedIndustries}
          secondLevelIndustries={secondLevelIndustries}
          resetForm={resetForm}
        />
      </Container>
    )
  }
  if (firstLevelIndustries) {
    return (
      <Container>
        <SecondLevelIndustries
          setIndustries={setSelectedIndustries}
          setSecondLevelIndustries={setSecondLevelIndustries}
          firstLevelIndustries={firstLevelIndustries}
          resetForm={resetForm}
        />
      </Container>
    )
  }
  return (
    <Container>
      <FirstLevelIndustries
        setFirstLevelIndustries={setFirstLevelIndustries}
        resetForm={resetForm}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 92vw;
  max-width: 550px;
  min-height: 488px;
  padding-top: 24px;
  padding-bottom: 100px;
  position: relative;
  @media (max-width: 768px) {
    padding-bottom: 80px;
    min-height: auto;
  }
`

export default IndustryStep
