import React from 'react'
import styled from 'styled-components'
import Style from 'shared-js/style'
import { Placeholder } from 'shared-js/components/common/placeholder'
import { Checkbox } from 'ui-kit'
import { useHandleChannelSubscriptions } from './context'
import { ConnectedCompaniesQuery_PublicCompany__List_edges_node as PublicCompany } from './gql-types/ConnectedCompaniesQuery'

type UniversalCompanyRowProps = {
  key: string
  style: React.CSSProperties
}

type CompanyRowProps = UniversalCompanyRowProps & {
  company: PublicCompany
}

export const CompanyRow = ({ key, style, company }: CompanyRowProps) => {
  const {
    companiesToSubscribe,
    companiesToUnsubscribe,
    toggleSubscription,
  } = useHandleChannelSubscriptions()

  const handleOnToggle = () => {
    toggleSubscription(company, Boolean(company.isSubscribedToChannel))
  }

  const isChecked =
    (company.isSubscribedToChannel || companiesToSubscribe.includes(company)) &&
    !companiesToUnsubscribe.includes(company)

  return (
    <Row key={key} style={style}>
      <CheckBoxContainer>
        <Checkbox checked={isChecked} color="primary" onChange={handleOnToggle} />
      </CheckBoxContainer>
      <CompanyName>{company.name}</CompanyName>
    </Row>
  )
}

export const LoadingCompanyRow = ({ key, style }: UniversalCompanyRowProps) => (
  <Row key={key} style={style}>
    <CheckBoxContainer>
      <Placeholder.Box
        height={18}
        width={18}
        radius={2}
        animate="fade"
        color={Style.colors.grey200}
      />
    </CheckBoxContainer>
    <Placeholder.Box
      height={18}
      width={80}
      radius={2}
      animate="fade"
      color={Style.colors.grey200}
    />
  </Row>
)

const CompanyName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Row = styled.div`
  border-top: solid 1px rgba(220, 220, 220, 0.5);
  display: flex;
  align-items: center;
`

const CheckBoxContainer = styled.div`
  margin-right: 24px;
  margin-left: 8px;
  display: flex;
`
