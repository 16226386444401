import Marty from 'marty'
import app from 'core/application'

const autoDispatch = Marty.autoDispatch

const Constants = Marty.createConstants(['COMPANY_SET_SEARCH'])

class CompanyActionCreators extends Marty.ActionCreators {
  setSearch = (str) => this.dispatch(Constants.COMPANY_SET_SEARCH, str)
}

class CompanyStore extends Marty.Store {
  constructor(opts) {
    super(opts)
    this.handlers = {
      onSetSearch: Constants.COMPANY_SET_SEARCH,
    }
    this.resetState()
  }

  onSetSearch(str) {
    this.state.search = str
    this.hasChanged()
  }

  getSearch() {
    return this.state.search
  }

  resetState() {
    this.state = {
      search: '',
    }
  }
}

app.register('CompanyStore', CompanyStore)
app.register('CompanyActionCreators', CompanyActionCreators)

export default {
  Store: app.CompanyStore,
  ActionCreators: app.CompanyActionCreators,
}
