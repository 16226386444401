import React, { useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { DEALER } from 'core/constants'
import { ConnectionInvitationContext } from 'components/public-app/context'
import { ConnectionInvitationQuery_PublicCompany__List_edges_node } from 'components/public-app/gql-types/ConnectionInvitationQuery'

const CONNECTION_INVITATION = 'connection invitation'

const CREATE_ACTIVE_CONNECTION = gql`
  mutation CreateActiveCompanyConnection($dealer: ID!, $supplier: ID!, $createdByCompany: ID!) {
    CompanyConnection__Create(
      input: {
        dealer: $dealer
        supplier: $supplier
        createdByCompany: $createdByCompany
        status: "approved"
      }
    ) {
      ok
      result {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

const SET_REFFERAL_METHOD = gql`
  mutation SetReferralMethod($companyID: ID!, $referredBy: [Int]!, $referralMethod: String!) {
    Company__Update(
      input: { id: $companyID, referredBy: $referredBy, referralMethod: $referralMethod }
    ) {
      ok
      result {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

type MutationType = (invitationReceiverID: string) => void
type InvitationSenderType = ConnectionInvitationQuery_PublicCompany__List_edges_node | null

/**
 * Temporary solution while most of the sign up flow is still using REST, Marty and class components
 */
const CreateCompanyConnectionMutationContainer = ({
  children,
}: {
  children(createCompanyConnection: MutationType, setReferralMethod: MutationType): React.Component
}) => {
  const [createCompanyConnectionMutation] = useMutation(CREATE_ACTIVE_CONNECTION)
  const [setReferralMethodMutation] = useMutation(SET_REFFERAL_METHOD)
  const invitationSender: InvitationSenderType = useContext(ConnectionInvitationContext)

  const createCompanyConnection = (invitationReceiverID: string) => {
    if (!invitationSender) return
    const invitationSenderIsADealer = invitationSender!.productSegmentType === DEALER
    const variables = invitationSenderIsADealer
      ? {
          dealer: invitationSender!.id,
          supplier: invitationReceiverID,
          createdByCompany: invitationSender!.id,
        }
      : {
          dealer: invitationReceiverID,
          supplier: invitationSender!.id,
          createdByCompany: invitationSender!.id,
        }
    createCompanyConnectionMutation({ variables })
  }

  const setReferralMethod = (invitationReceiverID: string) => {
    if (!invitationSender) return
    // an annoying quick fix while our ManyToManyIDField still takes an Int
    const referredBy = [parseInt(invitationSender!.id)]
    const variables = {
      companyID: invitationReceiverID,
      referredBy,
      referralMethod: CONNECTION_INVITATION,
    }

    setReferralMethodMutation({ variables })
  }

  return children(createCompanyConnection, setReferralMethod)
}

export default CreateCompanyConnectionMutationContainer
