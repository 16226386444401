import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Style from 'shared-js/style'
import Radium, { Style as AddCSS } from 'radium'
import { t } from 'i18n'

@Radium
export class TextInput extends React.Component {
  static propTypes = {
    styles: PropTypes.object,
    focusStyle: PropTypes.object,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    placeholderColor: PropTypes.string,
    validator: PropTypes.func,
    validationErrorMessage: PropTypes.string,
    errorMessage: PropTypes.string, // Input will be set to invalid while this is set
    value: PropTypes.string,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
    onInvalid: PropTypes.func,
    onDone: PropTypes.func,
    max: PropTypes.number,
    min: PropTypes.number,
    maxLength: PropTypes.number,
    autoComplete: PropTypes.bool,
    autoFocus: PropTypes.bool,
    icon: PropTypes.string,
    iconPlacement: PropTypes.oneOf(['left', 'right']),
    actionComponent: PropTypes.element,
    actionComponentPlacement: PropTypes.oneOf(['left', 'right']),
    disabled: PropTypes.bool,
    multiline: PropTypes.bool,
  }

  static defaultProps = {
    styles: { container: {}, inputContiner: {}, input: {} },
    showError: true,
    iconPlacement: 'left',
    actionComponentPlacement: 'right',
    validationErrorMessage: 'This value is not valid.',
  }

  constructor(props) {
    super(props)
    this.state = {
      value: '',
      type: 'text',
      valid: true,
      dirty: false,
      touched: false,
    }
  }

  componentDidMount() {
    if (this.props.value) this.setState({ value: this.props.value })
  }

  componentDidUpdate(prevProps) {
    // Warning: Input is a hybrid controlled and uncontrolled element.
    // If the prop.value is changed externally, internal value is updated.
    if (prevProps.value !== this.props.value) {
      this.validate(this.props.value)
      this.setState({ value: this.props.value })
    }
  }

  isValid() {
    return this.state.valid
  }

  isInvalid() {
    return !this.state.valid
  }

  isDirty() {
    return this.state.dirty
  }

  // Has changed.
  isPristine() {
    return !this.state.dirty
  }

  // Has not changed yet.
  isTouched() {
    return this.state.touched
  }

  // Has changed and lost focus.
  isUntouched() {
    return !this.state.touched
  } // Has not lost focus yet.

  focus = () => {
    ReactDOM.findDOMNode(this.refs.input).focus()
  }

  reset = () => {
    this.setState({
      value: this.props.value,
      valid: true,
      dirty: false,
      touched: false,
    })
  }

  validate = (value = this.state.value) => {
    if (!this.props.validator) return
    const valid = Boolean(String(value).match(this.props.validator))
    this.setState({ valid })
    if (this.props.onInvalid && !valid) this.props.onInvalid(event)
  }

  onChange = (event) => {
    this.validate()
    this.setState({ value: event.target.value, dirty: true })
    if (this.props.onChange) this.props.onChange(event)
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') this.onDone(event)
    if (this.props.onKeyPress) this.props.onKeyPress(event)
  }

  onDone = (event) => {
    // Fired when the enter key is pressd or on blur
    if (this.state.dirty) this.setState({ touched: true })
    if (this.props.onDone) this.props.onDone(event)
  }

  onBlur = (event) => {
    if (this.props.onBlur) this.props.onBlur(event)
    this.onDone(event)
  }

  renderIcon = (icon = this.props.icon) => {
    if (icon) {
      return (
        <i
          className={`icon ${this.props.icon}`}
          style={{ ...styles.icon, ...this.props.styles.icon }}
        />
      )
    }
  }

  renderLabel = (label = this.props.label) => {
    if (label) {
      return (
        <label style={{ ...styles.label, ...this.props.styles.label }}>{this.props.label}</label>
      )
    }
  }

  render() {
    const showError = Boolean(this.isInvalid() || this.props.errorMessage)
    const errorMessage = this.props.errorMessage || this.props.validationErrorMessage

    return (
      <div style={{ ...styles.container, ...this.props.styles.container }}>
        <AddCSS
          scopeSelector=".inputPlaceholder"
          rules={{
            '::-webkit-input-placeholder': {
              color: this.props.placeholderColor || Style.vars.deprecatedColors.grey,
            },
          }}
        />
        {this.renderLabel()}
        <div
          style={{
            ...styles.inputContainer,
            ...this.props.styles.inputContainer,
          }}
          onClick={() => this.focus()}
        >
          {this.props.actionComponentPlacement === 'left' && this.props.actionComponent}
          {this.props.iconPlacement === 'left' && this.renderIcon()}
          {!this.props.multiline ? (
            <input
              ref="input"
              size="1"
              style={{ ...styles.input, ...this.props.styles.input }}
              className="inputPlaceholder" // For placeholder color
              type={this.props.type}
              placeholder={this.props.placeholder}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.props.onFocus}
              onKeyPress={this.onKeyPress}
              value={this.state.value}
              max={this.props.max}
              min={this.props.min}
              maxLength={this.props.maxLength}
              autoComplete={this.props.autoComplete}
              autoFocus={this.props.autoFocus}
              disabled={this.props.disabled}
            />
          ) : (
            <textarea
              ref="input"
              style={styles.textarea}
              className="inputPlaceholder" // For placeholder color
              placeholder={this.props.placeholder}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.props.onFocus}
              onKeyDown={this.props.onKeyDown}
              value={this.state.value}
              max={this.props.max}
              min={this.props.min}
              maxLength={this.props.maxLength}
              autoComplete={this.props.autoComplete}
              autoFocus={this.props.autoFocus}
              disabled={this.props.disabled}
            />
          )}
          {this.props.actionComponentPlacement === 'right' && this.props.actionComponent}
          {this.props.iconPlacement === 'right' && this.renderIcon()}
        </div>
        {showError && (
          <div style={{ ...styles.error, ...this.props.styles.error }}>{errorMessage}</div>
        )}
      </div>
    )
  }
}

const styles = {
  container: {},
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  icon: {
    flex: '0 1 auto',
    color: Style.vars.deprecatedColors.grey,
  },
  input: {
    flex: 1,
    minWidth: 0,
    borderWidth: 0,
    padding: '8px 5px 8px 5px',
  },
  textarea: {
    flex: 1,
    minWidth: 0,
    borderWidth: 0,
    padding: '8px 5px 8px 5px',
  },
  label: {
    display: 'block',
    fontWeight: 'normal',
    marginBottom: 8,
  },
  error: {
    color: Style.vars.deprecatedColors.errorRed,
  },
}

export class TextArea extends React.Component {
  render() {
    return <TextInput {...this.props} multiline />
  }
}

export class EmailInput extends React.Component {
  static defaultProps = {
    validator: '', // TODO Email regex
  }

  render() {
    return <TextInput {...this.props} type="email" />
  }
}

export class SearchInput extends React.Component {
  render() {
    return <TextInput {...this.props} type="text" icon="search" placeholder={t('search')} />
  }
}

export class URLInput extends React.Component {
  static defaultProps = {
    validator: '', // TODO URL regex
  }

  render() {
    return <TextInput {...this.props} type="url" />
  }
}

export class DateTimeInput extends React.Component {
  static defaultProps = {
    validator: '', // TODO Datetime regex
  }

  render() {
    return <TextInput {...this.props} type="url" />
  }
}

export class PasswordInput extends React.Component {
  render() {
    return <TextInput {...this.props} type="password" />
  }
}

export class NumberInput extends React.Component {
  static defaultProps = {
    validator: '', // TODO Datetime regex
  }

  render() {
    return <TextInput {...this.props} type="number" />
  }
}
