import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

export const getCompaniesTree = (companies) => {
  // Construct a hierarchical tree of companies & child companies given
  // a set of companies with parent company IDs that correspond to company IDs.
  const tree = []
  if (companies) {
    const cos = []
    companies.map((company) => {
      cos.push(_.cloneDeep(company))
    })

    const dataMap = cos.reduce((map, node) => {
      map[node.id] = node
      return map
    }, {})

    cos.forEach((node) => {
      // add to parent
      const parentId = node.parent_company ? node.parent_company.id : undefined
      const parent = dataMap[parentId]
      if (parent) {
        // create children array if it doesn't exist
        (parent.children || (parent.children = []))
          // add node to children array
          .push(node)
      } else {
        // parent is null or missing
        tree.push(node)
      }
    })
  }
  return tree
}

export const getCompanyDepth = (companies, company, depth = 0) => {
  // Calculate how deep in the tree a given company is. This is used to render
  // a company based on how deep it's nested.
  const tree = getCompaniesTree(companies)
  const index = _.findIndex(tree, { id: company.id })
  if (index > -1) {
    return depth
  }
  const hasParent = company.parent_company
  if (hasParent) {
    const parent = companies.find((co) => co.id === company.parent_company.id)
    depth += 1
    return getCompanyDepth(companies, parent, depth)
  }
}

export class CompanyHierarchyTree extends React.Component {
  // Render a set of companies based on their parent_company.id
  static data = {
    companies: {
      fields: ['id', 'parent_company.id', 'name'],
    },
  }

  static propTypes = {
    createCompanyItem: PropTypes.func.isRequired,
  }

  renderTree = (tree) => {
    let allItems = []
    if (tree.children || tree.length) {
      allItems = tree.map((company) => {
        if (company.children) {
          return [
            this.props.createCompanyItem(this.props.companies.find((co) => co.id === company.id)),
          ].concat(this.renderTree(company.children))
        }
        return this.props.createCompanyItem(this.props.companies.find((co) => co.id === company.id))
      })
    }
    return allItems
  }

  render() {
    const tree = getCompaniesTree(this.props.companies)
    return this.renderTree(tree)
  }
}
