import React from 'react'
import styled from 'styled-components'
import { TextField } from 'ui-kit'
import { NetworkStatus } from 'apollo-client'
import { t } from 'shared-js/i18n'
import { LoadingSpinner } from 'shared-js/components/common/loading'
import { GetChannelInfoQuery_TrainingUnit__Item as Channel } from '../gql-types/GetChannelInfoQuery'
import ActionItems from './cta-footer'
import { SectionTitle, SectionTitleIcon } from '../common'
import CompaniesList, { FetchedConnectedCompaniesApolloProps } from './company-list'

const SMALL_PHONE_WIDTH = 350

type ManageSubscriptionsProps = {
  channel: Channel
  isLoading: boolean
  saveChanges(): void
  apolloProps: FetchedConnectedCompaniesApolloProps
  setSearchTerm(val: string): void
}

const ManageSubscriptions = ({
  channel,
  isLoading,
  saveChanges,
  apolloProps,
  setSearchTerm,
}: ManageSubscriptionsProps) => {
  const isUpdatingData =
    apolloProps.networkStatus === NetworkStatus.setVariables && apolloProps.loading

  return (
    <Container>
      <TitleContainer>
        <SectionTitleIcon iconName="location-city" />
        <SectionTitle>{t('share_with_specific_companies')}</SectionTitle>
      </TitleContainer>
      <SearchContainer>
        <TextField
          label={t('search')}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
      </SearchContainer>
      {!isUpdatingData ? (
        <Companies apolloProps={apolloProps} />
      ) : (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )}
      <Separator />
      <ActionItems
        isSavingChanges={isLoading}
        saveChanges={saveChanges}
        currentlyHasSubscribedCompanies={Boolean(channel.subscribedCompaniesCount)}
      />
    </Container>
  )
}

const Companies = ({ apolloProps }: { apolloProps: FetchedConnectedCompaniesApolloProps }) => {
  const hasNoSearchResults = apolloProps.data.PublicCompany__List?.totalCount === 0

  return hasNoSearchResults ? (
    <NoResultsContainer>{t('no_results_found')}</NoResultsContainer>
  ) : (
    <CompaniesList apolloProps={apolloProps} />
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  @media (max-width: ${SMALL_PHONE_WIDTH}px) {
    display: none;
  }
`

const SearchContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 8px;
  background-color: rgba(0, 0, 0, 0.5);
`

const NoResultsContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 16px 0px;
`

export default ManageSubscriptions
