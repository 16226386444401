type GetFetchableCountProps = {
  itemsLength: number,
  totalCount: number,
  loadingSize: number,
}

/**
 * The user should only be able to scroll to one page more than the fetched results.
 * This also fixes a bug where the users scrolls too fast for enough fetches to be triggered
 */
export const getFetchableCount = ({
  itemsLength,
  totalCount,
  loadingSize,
}: GetFetchableCountProps) => {
  let totalFetchable = 0
  if (itemsLength > 0) {
    totalFetchable = itemsLength + loadingSize
  }

  if (itemsLength === totalCount) {
    totalFetchable = totalCount
  }

  return totalFetchable
}
