import { AsyncSearchableSelect } from 'components/common/form-deprecated/select'
import React from 'react'

import UsersState from 'state/users'

export class UserSearchableSelect extends React.Component {
  constructor(props) {
    super(props)
    this.selectRef = React.createRef()
  }

  getNameAndValue = () => (this.selectRef.current ? this.selectRef.current.getNameAndValue() : null)

  makeOption = (user) => ({
    value: user.url,
    label: `${user.full_name} - ${user.email} - ${user.id}`,
  })

  fetch = (search) => {
    if (!search) return null
    return UsersState.Store.getItems({
      limit: 20,
      search,
      ordering: '-search_rank',
      fields: ['first_name', 'last_name', 'url', 'id', 'email', 'search_rank'],
    })
  }

  render() {
    return (
      <AsyncSearchableSelect
        {...this.props}
        placeholder={this.props.placeholder || 'Search for a User...'}
        fetch={this.fetch}
        makeOption={this.makeOption}
        name={this.props.name || 'userURL'}
        ref={this.selectRef}
        required
        className="fs-sensitive"
      />
    )
  }
}
