import React from 'react'
import styled from 'styled-components'
import { FormikValues } from 'formik'
import { useCurrentUser } from 'shared-js/utilities/hooks'
import { toValidURL } from 'shared-js/utilities/value-formatters'
import { t } from 'shared-js/i18n'
import { Formik, Validation } from 'shared-js/components/common/form'
import { Button, TextButton } from 'ui-kit'
import { RETAILER, BRAND } from '../company-type-step'
import Fields from './fields'
import { useUpdateCompanyMutation } from '../update-company-mutation'

type DropdownValue = {
  value: string
  label: string
}

export type FormInputValues = {
  website: string
  size: DropdownValue
  regionsOfOperation: DropdownValue[]
}

type CompanyInfoFormProps = {
  isABrand: boolean
  companyTypeIDs: number[]
  industryIDs: string[]
  onCompletedForm(): void
  resetForm(): void
}

const CompanyInfoForm = ({
  isABrand,
  companyTypeIDs,
  industryIDs,
  onCompletedForm,
  resetForm,
}: CompanyInfoFormProps) => {
  const validationSchema = Validation.createSchema({
    website: Validation.url({ required: true }),
    size: Validation.text({ required: true }),
    regionsOfOperation: Validation.array({ typeof: Validation.text(), required: true }),
  })

  const currentUser = useCurrentUser()
  const updateCompany = useUpdateCompanyMutation(isABrand, onCompletedForm)

  const handleUpdateCompany = (variables: FormikValues) => {
    const regions = variables.regionsOfOperation.map((region: DropdownValue) =>
      parseInt(region.value)
    )
    const industries = industryIDs.map((id: string) => parseInt(id))
    const newCompanyType = isABrand ? BRAND : RETAILER
    const website = toValidURL(variables.website)
    const mutationVariables = {
      companyID: currentUser.company.id,
      regions,
      industries,
      companyTypes: companyTypeIDs,
      companyType: newCompanyType,
      website,
      size: variables.size.value,
    }

    updateCompany({ variables: mutationVariables })
  }

  return (
    <Formik validationSchema={validationSchema} onSubmit={handleUpdateCompany} initialValues={{}}>
      {(innerProps) => (
        <>
          <Fields formikProps={innerProps} isABrand={isABrand} />
          <ButtonContainer>
            <CancelButtonContainer>
              <TextButton onClick={resetForm}>{t('cancel')}</TextButton>
            </CancelButtonContainer>
            <Button
              type="submit"
              // a quick fix for now since the click event handler overrides the form's onSubmit
              // @ts-ignore
              onClick={innerProps.handleSubmit}
              disabled={!innerProps.isValid}
            >
              {t('submit')}
            </Button>
          </ButtonContainer>
        </>
      )}
    </Formik>
  )
}

const ButtonContainer = styled.div`
  margin-top: 44px;
  display: flex;
  justify-content: flex-end;
  padding-right: 28px;
`

export const CancelButtonContainer = styled.div`
  padding-right: 16px;
`

export default CompanyInfoForm
