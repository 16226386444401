import PropTypes from 'prop-types'
import React from 'react'
import Home from 'shared-js/components/home'
import Im from 'shared-js/immutable'
import { withRedirectToFrontend2ByFeatureFlag } from '../common/redirect-to-frontend2'

class HomeContainer extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  static childContextTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  getChildContext() {
    return {
      currentUser: Im.freeze(this.context.currentUser),
    }
  }

  render() {
    return <Home {...this.props} />
  }
}

const Page = withRedirectToFrontend2ByFeatureFlag('frontend2_home')(HomeContainer)

export default Page
