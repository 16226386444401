import $y from 'utilities/yaler'
import CompaniesState from 'state/companies'
import CompanySettingsState from 'state/companysettings'
import { GeneralTabsContent } from 'components/settings/company-settings/general'
import { LoadingContainer } from 'components/common/loading'
import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import containerUtils from 'utilities/containers'

export function CompanySettingsTab({ company, companySettings, currentUser }) {
  return (
    <LoadingContainer
      loadingProps={[company, companySettings]}
      createComponent={() => (
        <GeneralTabsContent
          companySettings={companySettings[0]}
          currentUser={currentUser}
          company={company}
        />
      )}
    />
  )
}
CompanySettingsTab.propTypes = {
  currentUser: PropTypes.object.isRequired,
}

export const CompanySettingsTabContent = Marty.createContainer(CompanySettingsTab, {
  listenTo: [CompanySettingsState.Store, CompaniesState.Store],

  fetch: {
    companySettings() {
      const company = this.props.currentUser.company.id
      return CompanySettingsState.Store.getItems({
        company,
        fields: $y.getFields(GeneralTabsContent, 'companySettings'),
      })
    },
    company() {
      const company = this.props.currentUser.company.id
      return CompaniesState.Store.getItem(company, {
        fields: [$y.getFields(GeneralTabsContent, 'company')],
      })
    },
  },

  pending() {
    return containerUtils.defaultPending(this, CompanySettingsTab)
  },

  failed(errors) {
    return containerUtils.defaultFailed(this, CompanySettingsTab, errors)
  },
})
