import { DropdownSelect, SearchableMultiSelect } from 'components/common/form-deprecated/select'

import Im from 'shared-js/immutable'
import { REGION_OPTIONS } from 'components/common/form-deprecated/select/regions'
import React from 'react'
import _ from 'lodash'
import { t } from 'i18n'

export const sortTranslatedRegions = (regions) => regions.sort((a, b) => {
  const labelA = t(a.label)
  const labelB = t(b.label)
  if (labelA < labelB) return -1
  if (labelA > labelB) return 1
  return 0
})

const getInitialValue = (initial, options) => {
  const initialExistsInOptions = _.findIndex(options, (option) => option.value === initial) === -1
  if (initial) {
    if (initialExistsInOptions) {
      initial = undefined
    }
  }
  return initial
}

const translateLabels = (regions) => Im.produce(regions, (newRegions) => {
  newRegions.forEach((region) => (region.label = t(region.label)))
})

export class RegionDropdownSelect extends React.Component {
  isValid() {
    if (!this.dropdown) return true
    return this.dropdown.isValid()
  }

  getNameAndValue() {
    if (!this.dropdown) return {}
    return this.dropdown.getNameAndValue()
  }

  render() {
    const sortedRegions = sortTranslatedRegions(REGION_OPTIONS)
    const initial = getInitialValue(this.props.initialSelection, sortedRegions)
    // props.exclude can include a list of region names, for example, filter
    // out non-country regions like Africa or The Nordics
    const exclude = this.props.exclude ? this.props.exclude : []
    let options = Im.splice(sortedRegions)
    options = _.filter(options, (option) => exclude.indexOf(option.value) === -1)
    options = translateLabels(options)
    return (
      <DropdownSelect
        ref={(el) => (this.dropdown = el)}
        options={options}
        {...this.props}
        initialSelection={initial}
      />
    )
  }
}

export class RegionMultiSelect extends React.Component {
  getNameAndValue() {
    const nameAndVal = this.refs.searchableSelection.getNameAndValue()
    return nameAndVal
  }

  getInitialSelections() {
    if (this.props.initialSelections && this.props.initialSelections.length) {
      return this.props.initialSelections
    }
    return []
  }

  onChange = (regions) => {
    if (this.props.onChange) this.props.onChange(regions)
  }

  render() {
    const sortedRegions = sortTranslatedRegions(REGION_OPTIONS)
    const initial = getInitialValue(this.props.initialSelection, sortedRegions)
    const opts = translateLabels(sortedRegions)
    return (
      <SearchableMultiSelect
        noSelectionText={t('select_channel_regions')}
        {...this.props}
        initialSelections={this.getInitialSelections()}
        options={opts}
        name={this.props.name}
        ref="searchableSelection"
        style={{ container: { marginBottom: 10 } }}
        onChange={this.onChange}
        required
      />
    )
  }
}
