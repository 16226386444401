import React from 'react'
import { List, ListRowRenderer, InfiniteLoader, AutoSizer } from 'react-virtualized'
import InfiniteWindowScroller from './infinite-window-scroller'
import { getFetchableCount } from './common'

type WindowScrollerListProps = {
  items: any[],
  totalCount: number,
  loadingSize: number,
  loadMoreData: (vars: any) => void,
  dataIsLoading: boolean,
  rowRenderer: ListRowRenderer,
  rowHeight: number,
  customListStyle?: string,
  fetchMoreThreshold?: number,
  customStyling?: React.CSSProperties,
}

type ContainedListProps = {
  items: any[],
  totalCount: number,
  loadingSize: number,
  loadMoreData: (vars: any) => void,
  dataIsLoading: boolean,
  maxNumRowsShownAtATime: number,
  rowRenderer: ListRowRenderer,
  rowHeight: number,
  fetchMoreThreshold?: number,
}

export const WindowScrollerList = (props: WindowScrollerListProps) => (
  <InfiniteWindowScroller
    items={props.items}
    totalCount={props.totalCount}
    loadingSize={props.loadingSize}
    loadMoreData={props.loadMoreData}
    dataIsLoading={props.dataIsLoading}
    fetchMoreThreshold={props.fetchMoreThreshold}
  >
    {(infiniteWindowScrollerProps) => (
      <List
        style={props.customStyling}
        autoHeight
        rowCount={getFetchableCount({
          itemsLength: props.items.length,
          totalCount: props.totalCount,
          loadingSize: props.loadingSize,
        })}
        rowHeight={props.rowHeight}
        rowRenderer={props.rowRenderer}
        {...infiniteWindowScrollerProps}
        {...props}
      />
    )}
  </InfiniteWindowScroller>
)

export const ContainedList = (props: ContainedListProps) => {
  const isRowLoaded = ({ index }: { index: number }): boolean => !!props.items[index]
  const loadMore = () => {
    props.loadMoreData(null)
    // prettier-ignore
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => (props.dataIsLoading
        ? reject(new Error('Fetch has not finished'))
        : resolve()), 1000)
    })
    return promise.then().catch(() => {})
  }

  const listHeight =
    props.totalCount < props.maxNumRowsShownAtATime
      ? props.totalCount * props.rowHeight
      : props.maxNumRowsShownAtATime * props.rowHeight

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMore}
      rowCount={getFetchableCount({
        itemsLength: props.items.length,
        totalCount: props.totalCount,
        loadingSize: props.loadingSize,
      })}
      threshold={props.fetchMoreThreshold}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              ref={registerChild}
              height={listHeight}
              onRowsRendered={onRowsRendered}
              rowCount={getFetchableCount({
                itemsLength: props.items.length,
                totalCount: props.totalCount,
                loadingSize: props.loadingSize,
              })}
              rowHeight={props.rowHeight}
              rowRenderer={props.rowRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  )
}
