import axios from 'axios'
import { t } from 'i18n'
import React, { useRef, useState } from 'react'
import Cropper from 'react-image-crop'
import Button from 'shared-js/components/common/buttons'
import styled from 'styled-components'
import PhoneSimulator from './phone-simulator'

function MobileCropper({ coverImageUrl, channelId }) {
  const cropperWrapperRef = useRef(null)
  const aspect = 259 / 180
  const [crop, setCrop] = useState({ aspect })
  const [loading, setLoading] = useState(false)
  const [simultatorCrop, setSimulatorCrop] = useState({ aspect })
  const [resultCrop, setResultCrop] = useState({ aspect })
  const handleDragEnd = () => setSimulatorCrop(crop)

  const saveCrop = () => {
    if (
      resultCrop.x === undefined ||
      resultCrop.y === undefined ||
      resultCrop.height === undefined ||
      resultCrop.width === undefined
    ) {
      return
    }

    setLoading(true)

    // todo: THIS ENDPOINT MUST DEPEND ON ENV. CHANGE ASAP.
    axios
      .post('https://api.live.myagi.services/graphql', {
        query: `
        mutation SaveCrop($input: CropChannelImageInput!) {
          cropChannelImage(input: $input)
        }
      `,
        variables: {
          input: {
            channelId,
            x: Math.round(resultCrop.x),
            y: Math.round(resultCrop.y),
            width: Math.round(resultCrop.width),
            height: Math.round(resultCrop.height),
          },
        },
      })
      .finally(() => setLoading(false))
  }

  const { current } = cropperWrapperRef
  return (
    <Container>
      <div>
        <div ref={cropperWrapperRef}>
          <Cropper
            src={coverImageUrl}
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onDragEnd={handleDragEnd}
          />
        </div>
        <Button onPress={saveCrop} disabled={loading}>
          {loading ? t('loading_with_dots') : t('save')}
        </Button>
      </div>

      <PhoneSimulator
        crop={simultatorCrop}
        cropperWidth={current ? current.clientWidth : 0}
        cropperHeight={current ? current.clientHeight : 0}
        updateResult={setResultCrop}
        width={300}
        coverImageUrl={coverImageUrl}
      />
    </Container>
  )
}

export default MobileCropper

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
