import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import Im from 'shared-js/immutable'
import _ from 'lodash'
import cx from 'classnames'

import Style from 'style/index'

import { t } from 'i18n'
import $y from 'utilities/yaler'
import containerUtils from 'utilities/containers'

import TeamsState from 'state/teams'
import PublicTeamsState from 'state/public-teams'

import { AsyncSearchableSelect, SearchableSelect } from 'components/common/form-deprecated/select'

export class TeamSearchableSelect extends React.Component {
  /*
    `AsyncSearchableSelect` which is set up to
    search for teams (i.e. learner groups) within a given company.
  */

  static propTypes = {
    company: PropTypes.object.isRequired,
  }

  getNameAndValue() {
    return this.refs.searchableSelection.getNameAndValue()
  }

  fetch = (search) => {
    if (!search) return null
    return TeamsState.Store.getItems({
      limit: 20,
      name__icontains: search,
      company: this.props.company.id,
      ordering: 'name',
      fields: ['name', 'url', 'id', 'location_address'],
    })
  }

  makeOption = (team) => ({
    value: team.url,
    label: team.location_address ? `${team.name} (${team.location_address})` : team.name,
  })

  isValid() {
    return this.refs.searchableSelection.isValid()
  }

  render() {
    return (
      <AsyncSearchableSelect
        placeholder={t('search_for_a_entity', { entity: t('team') })}
        {...this.props}
        fetch={this.fetch}
        makeOption={this.makeOption}
        name={this.props.name || 'teamURL'}
        ref="searchableSelection"
        required
      />
    )
  }
}

class PublicTeamSearchableSelectInner extends React.Component {
  /*
    `SearchableSelect` which is set up to
    search for public teams within a given company
  */

  static data = {
    teams: {
      many: true,
      required: false,
      fields: ['name', 'url', 'id', 'location_address'],
    },
  }

  static propTypes = $y.propTypesFromData(PublicTeamSearchableSelectInner, {
    company: PropTypes.object,
  })

  static defaultProps = {
    required: true,
  }

  makeOption = (team) => ({
    value: team.url,
    label: team.location_address ? `${team.name} (${team.location_address})` : team.name,
  })

  getNameAndValue() {
    return this.refs.searchableSelection.getNameAndValue()
  }

  isValid() {
    return this.refs.searchableSelection.isValid()
  }

  render() {
    const teamOptions = this.props.teams ? this.props.teams.map(this.makeOption) : []

    if (this.props.extraOption) teamOptions.unshift(this.props.extraOption)

    const placeholder = this.props.placeholder ? this.props.placeholder : `${t('select_a_team')}...`

    return (
      <SearchableSelect
        noSelectionText={placeholder}
        {...this.props}
        options={teamOptions}
        name={this.props.name || 'teamURL'}
        ref="searchableSelection"
        style={{ container: { marginBottom: 10 } }}
        required={this.props.required}
      />
    )
  }
}

export const PublicTeamSearchableSelect = Marty.createContainer(PublicTeamSearchableSelectInner, {
  listenTo: [PublicTeamsState.Store],
  fetch: {
    teams() {
      return PublicTeamsState.Store.getItems({
        limit: 0,
        ordering: 'name',
        fields: $y.getFields(PublicTeamSearchableSelectInner, 'teams'),
        company: this.props.company.id,
      })
    },
  },
  getNameAndValue() {
    const inner = this.getInnerComponent()
    if (!inner) return {}
    return inner.getNameAndValue()
  },
  isValid() {
    const inner = this.getInnerComponent()
    if (!inner) return !this.props.required
    return inner.isValid()
  },
  pending() {
    return containerUtils.defaultPending(this, PublicTeamSearchableSelectInner)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, PublicTeamSearchableSelectInner, errors)
  },
})
