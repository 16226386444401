import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import { CoverPhotoGuideModal } from 'components/common/cover-photo-guide-modal'
import { EditImageModal, useImageMutation } from 'components/common/image'
import { useHover } from 'shared-js/utilities/hooks'
import gql from 'graphql-tag'

export function EditCoverImage({ channel, onChange }) {
  const channelMutation = useImageMutation(UPDATE_COVER_IMAGE)
  const editModalRef = useRef()
  const [isHovered, bindIsHovered] = useHover()

  const uploadCoverImage = useCallback(
    (coverImage) => {
      const variables = { channelId: channel.id, coverImage }
      channelMutation({ variables }).then((value) => {
        if (onChange) onChange(value)
      })
    },
    [channel.id, channelMutation, onChange]
  )

  return (
    <div
      style={{ ...styles.container, ...(isHovered && styles.containerHover) }}
      onClick={() => editModalRef.current.show()}
      {...bindIsHovered}
    >
      <div style={styles.editButton}>
        <i className="photo icon" />
      </div>

      <EditImageModal
        ref={editModalRef}
        modalSize="large"
        modalHeader={`${t('cover_image')} (${t('optional')})`}
        name="cover_image"
        aspectRatio={4 / 1}
        cropperWidth={1200}
        initialValue={channel.cover_image}
        renderCropTip={() => <CoverImageGuide />}
        onSubmit={uploadCoverImage}
        channel={channel}
      />
    </div>
  )
}
EditCoverImage.propTypes = {
  channel: PropTypes.object.isRequired,
}

function CoverImageGuide(props) {
  const modalRef = useRef()
  return (
    <div>
      <a style={styles.imageGuideLink} onClick={() => modalRef.current.show()}>
        {t('cover_photo_guide')}
      </a>
      <CoverPhotoGuideModal ref={modalRef} />
    </div>
  )
}

const UPDATE_COVER_IMAGE = gql`
  mutation UpdateChannel($channelId: ID!, $coverImage: Upload!) {
    TrainingUnit__Update(input: { id: $channelId, coverImage: $coverImage }) {
      result {
        id
        coverImage
      }
      errors {
        messages
      }
    }
  }
`

const styles = {
  container: {
    transition: 'all 0.3s ease',
    zIndex: 10,
    cursor: 'pointer',
    opacity: 0.5,
  },
  containerHover: {
    opacity: 1,
  },
  editButton: {
    zIndex: 11,
    color: 'white',
    opacity: 1,
    left: 3,
    position: 'relative',
    fontSize: '2rem',
    lineHeight: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 42,
    paddingLeft: 3,
    height: 36,
    borderRadius: 5,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  imageGuideLink: {
    cursor: 'pointer',
  },
}
