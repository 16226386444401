import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'
import { EditImageModal, useImageMutation } from 'components/common/image'
import { useHover } from 'shared-js/utilities/hooks'
import gql from 'graphql-tag'

export function Logo({ channel, isEditable, onChange }) {
  const channelLogoMutation = useImageMutation(UPDATE_CHANNEL_LOGO)
  const editModalRef = useRef()
  const [isHovered, bindIsHovered] = useHover()

  // TODO: When graphQL is used to get the channel, this line will break.
  const currentLogo = channel.logo || channel.company.company_logo

  // The padding is rendered before the image is loaded and looks trash
  // so only show when image is loaded
  const [loaded, setLoaded] = useState(!currentLogo)

  const uploadLogo = useCallback(
    (logo) => {
      const variables = { channelId: channel.id, logo }
      channelLogoMutation({ variables }).then(onChange)
    },
    [channel.id, channelLogoMutation, onChange]
  )

  return (
    <div style={{ ...styles.container, visibility: loaded ? 'visible' : 'hidden' }}>
      {currentLogo ? (
        <img src={currentLogo} style={styles.logo} onLoad={() => setLoaded(true)} />
      ) : (
        isEditable && <div style={styles.noImage} />
      )}
      {isEditable && (
        <div
          style={{
            ...styles.editOverlay,
            opacity: isHovered || !currentLogo ? 1 : 0,
          }}
          onClick={() => editModalRef.current.show()}
          {...bindIsHovered}
        >
          <div style={styles.editIcon}>
            <i className="photo icon" />
          </div>
        </div>
      )}
      <EditImageModal
        ref={editModalRef}
        modalHeader={`${t('logo')} (${t('optional')})`}
        name="logo"
        cropperWidth={300}
        initialValue={channel.logo}
        onSubmit={uploadLogo}
      />
    </div>
  )
}
Logo.propTypes = {
  channel: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
}
Logo.defaultProps = {
  isEditable: false,
  onChange: () => {},
}

const UPDATE_CHANNEL_LOGO = gql`
  mutation UpdateChannel($channelId: ID!, $logo: Upload) {
    TrainingUnit__Update(input: { id: $channelId, logo: $logo }) {
      result {
        id
        logo
      }
      errors {
        messages
      }
    }
  }
`

const styles = {
  container: {
    display: 'inline-block',
    position: 'relative',
    border: '5px solid rgba(0, 0, 0, 0.6)',
    borderRadius: 5,
    overflow: 'hidden',
    transition: 'all 300ms ease',
  },
  logo: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    maxWidth: 350,
    maxHeight: 150,
    verticalAlign: 'middle',
    transition: 'all 300ms ease',
  },
  noImage: {
    width: 90,
    height: 90,
  },
  editOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
    zIndex: 2,
    transition: 'all 300ms ease',
    cursor: 'pointer',
  },
  editIcon: {
    color: '#FFF',
    fontSize: '2rem',
    lineHeight: '2rem',
  },
  editModal: {
    display: 'flex',
    justifyContent: 'center',
  },
}
