import { Info } from 'components/common/info.jsx'
import React from 'react'
import { t } from 'i18n'
import Style from 'style'
import { resolve } from 'utilities/deprecated-named-routes'
import { Link } from 'react-router'
import { SecondaryButton } from 'components/common/buttons'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontWeight: '200',
    fontSize: '1.6rem',
    marginBottom: '20px',
  },
}

export const ViewEnrollments = (props) => (
  <div style={styles.container}>
    <div style={styles.title}>{t('current_enrollments')}</div>
    <SecondaryButton
      style={{ marginLeft: 0 }}
      to={resolve('training-plan-enrollments', {
        planId: props.plan.id,
      })}
    >
      <i className="ui icon unhide" />
      {` ${t('views', {}, { pluralize: 1 })}`}
    </SecondaryButton>
  </div>
)
