import React from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { t } from 'shared-js/i18n'
import { useQuery } from '@apollo/react-hooks'
import { LoadingSpinner } from 'shared-js/components/common/loading'
import moment from 'moment-timezone'
import { LinkUsedByCompanies, LinkUsedByCompaniesVariables } from './gql-types/LinkUsedByCompanies'

const LINK_USED_BY_COMPANY_QUERY = gql`
  query LinkUsedByCompanies($cursor: String, $sharelink: ID!) {
    LinkUsedByCompany__List(after: $cursor, first: 100, link: $sharelink, orderBy: ["-id"]) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          created
          company {
            id
            name
          }
        }
      }
    }
  }
`

type Props = {
  sharelink: {
    id: string,
  },
}

const SharelinkListModal = ({ sharelink }: Props) => {
  const { loading, data, error } = useQuery<LinkUsedByCompanies, LinkUsedByCompaniesVariables>(
    LINK_USED_BY_COMPANY_QUERY,
    { variables: { sharelink: sharelink.id, cursor: null } }
  )

  const edges = data && data.LinkUsedByCompany__List && data.LinkUsedByCompany__List.edges

  return (
    <React.Fragment>
      {error && <Item>{t('error')}</Item>}
      {edges && edges.length === 0 && <Item>{t('this_link_has_not_been_used_yet')}</Item>}
      {edges &&
        edges.map((usedLink) => (
          <Item key={usedLink!.node!.id}>
            <div>{usedLink!.node!.company.name}</div>
            <div>{moment(usedLink!.node!.created).format('MMMM Do YYYY, h:mm a')}</div>
          </Item>
        ))}
      {loading && <LoadingSpinner />}
    </React.Fragment>
  )
}

const Item = styled.div`
  margin: 10px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
`

export default SharelinkListModal
