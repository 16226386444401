import gql from 'graphql-tag'

// Had to separate these two mutations out because of the one differing field (numEmployeesEstRange
// and numRetailersEstRange), having an optional variable and field throws a GraphQL error

export const UPDATE_RETAILER_DETAILS = gql`
  mutation UpdateCompanyDetails(
    $companyID: ID!
    $companyTypes: [Int]!
    $companyType: String!
    $regions: [Int]!
    $industries: [Int]!
    $website: String!
    $size: String!
  ) {
    Company__Update(
      input: {
        id: $companyID
        regionsOfResponsibility: $regions
        tags: $industries
        companyTypes: $companyTypes
        companyType: $companyType
        companyUrl: $website
        numEmployeesEstRange: $size
        hasUnlockedNetworkFeature: true
      }
    ) {
      ok
      result {
        id
        companyType
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_BRAND_DETAILS = gql`
  mutation UpdateCompanyDetails(
    $companyID: ID!
    $companyTypes: [Int]!
    $companyType: String!
    $regions: [Int]!
    $industries: [Int]!
    $website: String!
    $size: String!
  ) {
    Company__Update(
      input: {
        id: $companyID
        regionsOfResponsibility: $regions
        tags: $industries
        companyTypes: $companyTypes
        companyType: $companyType
        companyUrl: $website
        numRetailersEstRange: $size
        hasUnlockedNetworkFeature: true
      }
    ) {
      ok
      result {
        id
        companyType
      }
      errors {
        field
        messages
      }
    }
  }
`
