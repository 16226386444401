import Im from 'shared-js/immutable'
import Marty from 'marty'
import app from 'core/application'

const Constants = Marty.createConstants([
  'ENTITY_LIST_SET_ENTITY_SEARCH',
  'TOGGLE_ENTITY_SELECTION',
  'ENTITY_SELECT_SET_INNER_COMPONENT',
])

const EntityListActionCreators = Marty.createActionCreators({
  id: 'EntityListActionCreators',
  setEntitySearch(str) {
    this.dispatch(Constants.ENTITY_LIST_SET_ENTITY_SEARCH, str)
  },
  toggleEntitySelection(entity) {
    this.dispatch(Constants.TOGGLE_ENTITY_SELECTION, entity)
  },
  setEntitySelectComponent(component) {
    this.dispatch(Constants.ENTITY_SELECT_SET_INNER_COMPONENT, component)
  },
})

const EntityListStore = Marty.createStore({
  id: 'EntityListStore',
  handlers: {
    onSetEntitySearch: Constants.ENTITY_LIST_SET_ENTITY_SEARCH,
    onToggleEntitySelection: Constants.TOGGLE_ENTITY_SELECTION,
    onSetEntitySelectComponent: Constants.ENTITY_SELECT_SET_INNER_COMPONENT,
  },
  getInitialState() {
    return {
      search: '',
      selectedEntities: Im.freeze({}),
      entitySelectComponent: null,
    }
  },

  onSetEntitySelectComponent(component) {
    if (component) this.state.entitySelectComponent = component
  },

  onToggleEntitySelection(entity) {
    if (this.state.selectedEntities[entity.id]) {
      this.state.selectedEntities = Im.delete(this.state.selectedEntities, entity.id)
    } else {
      this.state.selectedEntities = Im.set(this.state.selectedEntities, entity.id, entity)
    }
    this.hasChanged()
  },

  getSelectedEntities() {
    return this.state.selectedEntities
  },

  getEntitySelectComponent() {
    return this.state.entitySelectComponent
  },

  onSetEntitySearch(str) {
    this.state.search = str
    this.hasChanged()
  },

  getEntitySearch() {
    return this.state.search
  },
  resetState() {
    this.state = this.getInitialState()
  },
})

app.register('EntityListStore', EntityListStore)
app.register('EntityListActionCreators', EntityListActionCreators)

export default {
  Constants,
  ActionCreators: app.EntityListActionCreators,
  Store: app.EntityListStore,
}
